import React, { useReducer, useContext } from "react";
import axios from "axios";
import CustomerContext from "./CustomerContext";
import CustomerReducer from "./CustomerReducer";
import {
  CUSTOMER_LOADING,
  CUSTOMER_LOADED,
  CUSTOMER_ERROR,
  CUSTOMER_RESET,
  SET_CURRENT_CUSTOMER,
  SET_USER_LIST,
  UPDATE_CUSTOMER,
  CUSTOMER_COMMENTS_LOADED,
  CUSTOMER_CATEGORIES_LOADED,
  INITIATE_CLIENT_FILING_MASTER,
  INITIATE_POST_CLIENT_FILING_MASTER,
} from "../../data/constants";
// import { env } from "process";
import AuthContext from "../auth/AuthContext";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
// const API_HOST = "";
const CustomerState = (props) => {
  const initialState = {
    error: null,
    customers: null,
    changedCustomers: [],
    customerLoading: false,
    customerLoaded: false,
    selectedCustomer: null,
    customerComments: [],
    cusotmerCategories: [],
    // localStorage.getItem("selectedCustomer") !== null
    //   ? JSON.parse(localStorage.getItem("selectedCustomer"))
    //   : null,
    savedCriteria: null,
    UserList: [],
  };
  const [state, dispatch] = useReducer(CustomerReducer, initialState);
  const authContext = useContext(AuthContext);
  const { authUser } = authContext;

  //Reset Context State
  const resetCustomerState = () => {
    dispatch({
      type: CUSTOMER_RESET,
      payload: { ...initialState },
    });
  };

  //select a customer for processing
  const setCurrentCustomer = (customer) => {
    dispatch({
      type: SET_CURRENT_CUSTOMER,
      payload: customer,
    });
  };

  //Search All customers
  const getCustomers = async (formData) => {
    // const savedCriteria = { ...formData };
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "Access-Control-Allow-Credentials": "true",
    //   },
    // };
    // if (formData) {
    //   formData.action = "CUSTOMER_MANAGEMENT";
    //   formData.function = "get_customers";
    //   formData.version = "1";
    // }
    console.log("FETCHING Customers ");
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: CUSTOMER_LOADING,
        });
        const res = await axios.get(`${API_BASE_URL}/Customer/CustomerList`);
        console.log("Customers ", res);
        dispatch({
          type: CUSTOMER_LOADED,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Creat a customer
  const createCustomer = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: CUSTOMER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/Customer/CreateCustomer`,
          formData
        );
        console.log("Customers ", res);
        dispatch({
          type: UPDATE_CUSTOMER,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Update a customer
  const updateCustomer = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: CUSTOMER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/Customer/UpdateCustomer`,
          formData
        );
        console.log("Customers ", res);
        dispatch({
          type: UPDATE_CUSTOMER,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  // const updateCustomerPostAward = async (formData, token) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       console.log("Payload", JSON.stringify(formData));
  //       dispatch({
  //         type: CUSTOMER_LOADING,
  //       });
  //       const res = await axios.put(
  //         `${API_BASE_URL}/Customer/UpdateCustomerPostAward`,
  //         formData
  //       );
  //       console.log("Customers ", res);
  //       dispatch({
  //         type: UPDATE_CUSTOMER,
  //         payload: res.data,
  //       });
  //       resolve(res.data);
  //     } catch (err) {
  //       dispatch({
  //         type: CUSTOMER_ERROR,
  //         payload: err,
  //       });
  //       reject(err);
  //     }
  //   });
  // };

  //Creat a customer
  const deleteCustomer = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: CUSTOMER_LOADING,
        });
        const res = await axios.delete(
          `${API_BASE_URL}/Customer/CustomerDelete/${formData.customerId}`,
          formData
        );
        console.log("Customers ", res);
        dispatch({
          type: UPDATE_CUSTOMER,
          payload: null,
        });
        // await getCustomers();
        resolve(true);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(false);
      }
    });
  };

  //Create Filing master Businesss Categories
  const createCustomerBusinessCategories = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        // dispatch({
        //   type: CUSTOMER_LOADING,
        // });
        const res = await axios.post(
          `${API_BASE_URL}/Customer/CreateCustomerBusinessCategory`,
          formData
        );
        console.log("Customer Business Categories", res);
        // await getCustomerBusinessCategories(formData[0].customerId);
        // dispatch({
        //   type: UPDATE_FILING_MASTER_CATEGORY,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const createCustomerProgramNames = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        // dispatch({
        //   type: CUSTOMER_LOADING,
        // });
        const res = await axios.post(
          `${API_BASE_URL}/Application/CreateCustomerProgram`,
          formData
        );
        console.log("Customer Business Categories", res);
        // await getCustomerBusinessCategories(formData[0].customerId);
        // dispatch({
        //   type: UPDATE_FILING_MASTER_CATEGORY,
        //   payload: res.data,
        // });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Delete Filing master Businesss Categories
  const deleteCustomerBusinessCategories = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        // dispatch({
        //   type: CUSTOMER_LOADING,
        // });
        const res = await axios.delete(
          `${API_BASE_URL}/Customer/CustomerBusinessCategory/${formData}`
        );
        console.log("Delete Customer Business Categories", res);
        // await getCustomerBusinessCategories(formData[0].customerId);
        // dispatch({
        //   type: UPDATE_FILING_MASTER_CATEGORY,
        //   payload: res.data,
        // });
        resolve(res);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getCustomerBusinessCategories = async (formData) => {
    console.log("FETCHING categories ");
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: CUSTOMER_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/Customer/CustomerBusinessCategoryList${formData}`
        );
        console.log("Categories ", res);
        const _data = res.data;
        // const _data = res.data.filter(
        //   (item) => item.customerId == formData.customerId
        // );

        dispatch({
          type: CUSTOMER_CATEGORIES_LOADED,
          payload: _data,
        });
        resolve(_data);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getCustomerCommentAttachments = async (data, customerId) => {
    console.log("attachres", data, customerId);
    const promises = data.map(async (item) => {
      const res1 = await axios.get(
        `${API_BASE_URL}/Customer/ListCustomerCommentsAttachments/${customerId}?CommentsID=${item.commentsId}`
      );
      const { attachmentPath, attachmentId } =
        res1.data.data && res1.data.data[0] ? res1.data.data[0] : {};
      console.log("attachres", item.commentsId, res1.data.data, attachmentPath);
      return {
        ...item,
        attachmentPath: attachmentPath,
        attachmentId: attachmentId,
      };
    });
    // data = res.data.map(async item=>{
    //   console.log("attachres",res1.data)
    //   if(res1.data && res1.data.length){
    //     return {...item,attachmentPath:res1.data[0].attachmentPath}
    //   }
    //   return item
    // })
    return await Promise.all(promises);
  };

  const getCustomerComments = async (formData) => {
    console.log("FETCHING comments ");
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: CUSTOMER_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/Customer/CustomerComments${formData}`
        );

        console.log("Customer_Comments ", res);
        if (res.data && res.data.length) {
          res.data = await getCustomerCommentAttachments(res.data, formData);
        }
        console.log("Customer_Comments_with attachments ", res);

        dispatch({
          type: CUSTOMER_COMMENTS_LOADED,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const addCustomerComment = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: CUSTOMER_LOADING,
        });
        let res = null;
        // const payload = {
        //   customerId: formData.cust.customerId,
        //   commentsText: formData.approvalComment,
        // };
        res = await axios.post(
          `${API_BASE_URL}/Customer/CreateCustomerComments`,
          formData
        );

        console.log("CustomerComment ", res);
        console.log(`Customer comment has been added: ${res.customerId}`);
        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        resolve(res.data);
        await getCustomerComments(formData.cust.customerId);
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Initiate Workflow upon cleint creation or category change
  const initiateClientFilingWorkflow = async (formData, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("formData", formData);
        console.log("payload", payload);
        dispatch({
          type: CUSTOMER_LOADING,
        });

        const res = await axios.put(
          `${API_BASE_URL}/Customer/CustomerFilingMasterDraftWorkflowGenerator${formData}?UserId=${authUser.userId}`,
          payload
        );
        console.log("ClientFilingMastersDraft ", res);
        //TO-DO: pass approver id

        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: INITIATE_CLIENT_FILING_MASTER,
            payload: res.data.data,
          });
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };


  const initiatePostClientFilingWorkflow = async (customerId, userId, subscriptionOperation) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("Payload", customerId, userId, subscriptionOperation);

        dispatch({
          type: CUSTOMER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/Customer/PostawardCustomerFilingMasterDraftWorkflowGenerator/${customerId}?UserId=${userId}&SubcriptionOperation=${subscriptionOperation}`,

        );
        console.log("PostawardCustomerFilingMasterDraft ", res);
        //TO-DO: pass approver id

        if (res && res.data && res.data.status === "Success") {
          // dispatch({
          //   type: INITIATE_POST_CLIENT_FILING_MASTER,
          //   payload: res.data.data,
          // });
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CUSTOMER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

    //set UserList 
    const setUserList = (data) => {
      dispatch({
        type: SET_USER_LIST,
        payload: data,
      });
    };

  return (
    <CustomerContext.Provider
      value={{
        ...state,
        resetCustomerState,
        setCurrentCustomer,
        // clearCurrentCustomer,
        getCustomers,
        createCustomer,
        createCustomerBusinessCategories,
        createCustomerProgramNames,
        deleteCustomerBusinessCategories,
        updateCustomer,
       // updateCustomerPostAward,
        deleteCustomer,
        getCustomerComments,
        addCustomerComment,
        getCustomerBusinessCategories,
        initiateClientFilingWorkflow,
        initiatePostClientFilingWorkflow,
        // loadCustomer,
        // customerDispatch: dispatch,
        // removeCustomers,
        setUserList
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
};

export default CustomerState;
