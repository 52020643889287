import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import DataTableBase from "../../common/DataTableBase";
// import PageNavbar from "./PageNavbar";
import PostAwardApproveToolbox from "./PostAwardApproveToolbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import SlidingDrawer from "../../common/SlidingDrawer";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import _ from "lodash";
import { useLocation } from "react-router-dom";
// import filingMasters from "../../data/filingMasters.json";
// import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import ClientFilingMasterContext from "../../../context/client-filing-master/ClientFilingMasterContext";
import PostAwardApproveClientFilingMaster from "./PostAwardApproveClientFilingMaster";
import ClientFilingMasterWorkflowComments from "./../../client-filing-master/ClientFilingMasterWorkflowComments";
import ReactLoading from "react-loading";
import AuthContext from "../../../context/auth/AuthContext";
import { Stack } from "@mui/material";
import axios from "axios";
import { DateTime } from "luxon";
import IconButton from "@mui/material/IconButton";
import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
import ClientFilingMasterReferenceDocuments from "../..client/../../client-filing-master/ClientFilingMasterReferenceDocuments";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { OverlayPanel } from "primereact/overlaypanel";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/material";
import states from "../../../data/StateLookup.json";
import { Dropdown } from "primereact/dropdown";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PostAwardClientFilingMasterApprovalList = () => {
  const [selectedFiling, setSelectedFiling] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [data, setData] = useState([]);
  const [masters, setMasters] = useState([]);
  const [masterCatFilter, setMasterCatFilter] = useState([]);
  const [postawardfederalArray, setPostAwardFederalArray] = useState([]);
  const [postawardstateArray, setPostAwardStateArray] = useState([]);
  const [federalFilterSelectedValues, setFederalFilterSelectedValues] =
    React.useState({
      federalCategories: [],
    });
  const [attachments, setAttachments] = React.useState([]);
  const [showAttachments, setShowAttachments] = useState(false);
  const [federalCategoriesLookup, setFederalCategoriesLookup] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [comments, setComments] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [postawardagencyArray, setPostAwardAgencyArray] = useState([]);
  const [postawardtypeArray, setPostAwardTypeArray] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    approverName:  {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    customerName:  {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    filingName:  {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // filingDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingFrequency:  {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // dueDayofFrequency: { value: null, matchMode: FilterMatchMode.IN },
    stateInfo:  {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    stateList:  {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },

    ruleInfo:  {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    required:  {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    awardTypeName:   {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    awardingAgencyName:   {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    federalCategories:   {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    stateCategories:   {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    // jsidept: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jsicontactName:  {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // jsicontactEmail: { value: null, matchMode: FilterMatchMode.CONTAINS },
    juristiction:   {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
  });
  const [stateArray, setStateArray] = useState([]);

  const authContext = useContext(AuthContext);
  const { roleType, authId, authUser, toLocalDateTime } = authContext;

  const getFilingCategories = (filingMaster) => {
    return _.map(filingMaster.businessCategory, "businessCategoryName").join(
      ", "
    );
  };

  // const filingMasterContext = useContext(FilingMasterContext);
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const [viewAll, setViewAll] = useState(true);
  // const {
  // getClientFilingMastersApprovalList,
  // ClientFilingMasterApprovals,
  // ClientFilingMasterLoaded,
  // getClientFilingMasterWorkflowComments,
  // } = filingMasterContext;

  const {
    getClientFilingMastersApprovalList,
    setClientFilingMastersApprovalList,
    ClientFilingMasterApprovals,
    ClientFilingMasterLoaded,
    getClientFilingMasterWorkflowComments,
  } = clientFilingMasterContext;

  // const data = ClientFilingMasterApprovals;
  // const [visibleApps, setVisibleApps] = useState(props.projects);
  const [showFilter, setShowFilter] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [jurisdiction, setJurisdiction] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [filingPeriodTable, setFilingPeriodTable] = useState([]);
  const [categoryList, setCategoryList] = useState([])
  const [isClearIcon, setIsClearIcon] = useState(false);

  const [lazyLoading, setLazyLoading] = useState(false);
  const [refreshRecordList, setRefreshRecordList] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(null);
  const [recordsInView, setRecordsInView] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterChange, setIsFilterChange] = useState([]);
  const [sortingData, setSortingData] = useState({
    sortBy: "",
    sortOrder: "",
  });

  const displayFilter = () => {
    console.log("Toggling Show Filter", showFilter);
    setShowFilter(() => true);
  };
  const closeFilter = () => {
    console.log("Toggling Show Filter", showFilter);
    setShowFilter(() => false);
  };
  const handleViewAllClick = () => {
    setViewAll(true);
  };

  const handlePaginationClick = () => {
    setViewAll(false);
  };
  const postawardFederalRowFilterTemplate = (options) => {

    if (!masterCatFilter.federalCategories && options?.filterModel?.value) {
      // console.log("requiredRowFilterTemplate filterModel",options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter.federalCategories || []}
        options={postawardfederalArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardFederalRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, federalCategories: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
      ) : (
        <Dropdown
          value={masterCatFilter.federalCategories || []}
          options={postawardfederalArray}
          itemTemplate={jusrisdictionItemTemplate}
          onChange={(e) => {
            // console.log("##federalFilterElement#:", options, e, data);
            setMasterCatFilter((prev) => {
              return { ...prev, federalCategories: e.value };
            });
            let values = [];
            if (options.filterModel.matchMode === "notContains") {
              values.push(e.value);
            } else if (options.filterModel.matchMode === "equals") {
              values.push(e.value);
            }
            options.filterCallback(values, options.index);
          }}
          placeholder="Any"
          className="p-column-filter"
          style={{ minWidth: "14rem", maxWidth: "14rem" }}
        />
      );
  };

  const postawardStateRowFilterTemplate = (options) => {
    
    if (!masterCatFilter.stateCategories && options?.filterModel?.value) {
      // console.log("requiredRowFilterTemplate filterModel",options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter.stateCategories || []}
        options={postawardstateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardStateRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, stateCategories: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
      ) : (
        <Dropdown
          value={masterCatFilter.stateCategories || []}
          options={postawardstateArray}
          itemTemplate={jusrisdictionItemTemplate}
          onChange={(e) => {
            // console.log("##federalFilterElement#:", options, e, data);
            setMasterCatFilter((prev) => {
              return { ...prev, stateCategories: e.value };
            });
            let values = [];
            if (options.filterModel.matchMode === "notContains") {
              values.push(e.value);
            } else if (options.filterModel.matchMode === "equals") {
              values.push(e.value);
            }
            options.filterCallback(values, options.index);
          }}
          placeholder="Any"
          className="p-column-filter"
          style={{ minWidth: "14rem", maxWidth: "14rem" }}
        />
      );
  };

  const postawardTypeRowFilterTemplate = (options) => {
    if (!masterCatFilter.awardTypeName && options?.filterModel?.value) {
      // console.log("requiredRowFilterTemplate filterModel",options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter.awardTypeName || []}
        options={postawardtypeArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardAgencyRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, awardTypeName: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
      ) : (
        <Dropdown
          value={masterCatFilter.awardTypeName || []}
          options={postawardtypeArray}
          itemTemplate={jusrisdictionItemTemplate}
          onChange={(e) => {
            // console.log("##federalFilterElement#:", options, e, data);
            setMasterCatFilter((prev) => {
              return { ...prev, awardTypeName: e.value };
            });
            let values = [];
            if (options.filterModel.matchMode === "notContains") {
              values.push(e.value);
            } else if (options.filterModel.matchMode === "equals") {
              values.push(e.value);
            }
            options.filterCallback(values, options.index);
          }}
          placeholder="Any"
          className="p-column-filter"
          style={{ minWidth: "14rem", maxWidth: "14rem" }}
        />
      );
  };

  const postawardAgencyRowFilterTemplate = (options) => {

    if (!masterCatFilter.awardAgencyName && options?.filterModel?.value) {
      // console.log("requiredRowFilterTemplate filterModel",options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter.awardAgencyName || []}
        options={postawardagencyArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          console.log(" postawardAgencyRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, awardAgencyName: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
      ) : (
        <Dropdown
          value={masterCatFilter.awardAgencyName || []}
          options={postawardagencyArray}
          itemTemplate={jusrisdictionItemTemplate}
          onChange={(e) => {
            // console.log("##federalFilterElement#:", options, e, data);
            setMasterCatFilter((prev) => {
              return { ...prev, awardAgencyName: e.value };
            });
            let values = [];
            if (options.filterModel.matchMode === "notContains") {
              values.push(e.value);
            } else if (options.filterModel.matchMode === "equals") {
              values.push(e.value);
            }
            options.filterCallback(values, options.index);
          }}
          placeholder="Any"
          className="p-column-filter"
          style={{ minWidth: "14rem", maxWidth: "14rem" }}
        />
      );
  };

  // const location = useLocation()
  // const { selectRows } = location.state;
  // console.log("received props", selectRows);

  const approveDraft = () => {
    console.log("Approving Draft", selectedFiling);
    if (selectedFiling && selectedFiling.length !== 0) {
      setShowFilter(() => true);
    } else {
      setAlertMessage("Please Select a Filing Master Record");
      setShowAlert(true);
    }
  };
  const fetchFilingMasterAttachments = async (filingId) => {
    console.log("fetchFilingMasterAttachments props", filingId);
    try {
      const attachmentList = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachments/${filingId}`
      );
      setAttachments(() => attachmentList.data.data);
      console.log("fetchFilingMasterAttachments res", attachmentList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  const closeAttachments = () => {
    console.log("Toggling Show Filter", showAttachments);
    setShowAttachments(() => false);
  };
  const displayAttachments = async (rowData) => {
    console.log("##selectedFiling", selectedFiling[0]);
    console.log("##RowData", rowData);
    console.log("LengthSelectedFiling", selectedFiling.length);
    if (rowData && rowData.filingId) {
      console.log(`##RowData-FilingId :${rowData.filingId}`);
      fetchFilingMasterAttachments(rowData.filingId);
      setShowAttachments(() => true);
      setSelectedFiling(() => []);
    } else if (
      selectedFiling &&
      selectedFiling.length === 1 &&
      selectedFiling[0].filingId
    ) {
      console.log(`Fetching Attachment for :${selectedFiling[0].filingId}`);
      fetchFilingMasterAttachments(selectedFiling[0].filingId);
      setShowAttachments(() => true);
      setSelectedFiling(() => []);
    } else if (selectedFiling.length > 1) {
      setAlertMessage("Please Select a single Filing Master Record");
      setShowAlert(true);
    } else {
      setAlertMessage("Please Select a Filing Master Record");
      setShowAlert(true);
    }
  };

  const fetchFilingMasterComments = async (filingId) => {
    console.log(
      "@@Fetch FilingMasters1:",
      getClientFilingMasterWorkflowComments
    );
    if (getClientFilingMasterWorkflowComments) {
      console.log("@@Fetch FilingMasters2:");
      try {
        const commentList = await getClientFilingMasterWorkflowComments(
          filingId
        );
        setComments(() => commentList);
        console.log("@@Fetch FilingMasters2 comments:", commentList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const addComment = (rowData) => {
    console.log("Display Comments", selectedFiling);
    console.log("Display Comments rowData", rowData);
    if (rowData && rowData.workflowId) {
      console.log(`Fetching Commnet for :${rowData.workflowId}`);
      console.log(`Fetching Commnet for rowData :${rowData}`);
      fetchFilingMasterComments(rowData.workflowId);
      setShowComments(() => true);
      setSelectedFiling([]);
      setSelectedRowData(rowData);
    } else if (
      selectedFiling &&
      selectedFiling.length === 1 &&
      selectedFiling[0].workflowId
    ) {
      console.log(`Fetching Commnet for :${selectedFiling[0].workflowId}`);
      setSelectedRowData(null);
      fetchFilingMasterComments(selectedFiling[0].workflowId);
      setShowComments(() => true);
    } else {
      setAlertMessage("Please select a single Record");
      setShowAlert(true);
    }
  };

  const getStateName = (stateCode) => {
    const state = states.find((s) => s.code === stateCode);
    return state ? state.state : stateCode;
  };

  const closeComments = () => {
    console.log("Toggling Show Filter", showComments);
    setShowComments(() => false);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const jusrisdictionItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <span>{option}</span>
      </div>
    );
  };

  const jusrisdictionArray = ["Federal", "State", "Both"];

  const jurisdictionRowFilterTemplate = (options) => {
    console.log("##Options:", options);
    if (!federalFilterSelectedValues?.jurisdiction && options?.filterModel?.value) {
      // console.log(" optionsfilterModel", options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }
    return (
      <Dropdown
        value={federalFilterSelectedValues.jurisdiction || []}
        options={jusrisdictionArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##EOptions:", e);
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, jurisdiction: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const mandatoryArray = ["True", "False"];

  const mandatoryRowFilterTemplate = (options) => {
    // console.log("requiredRowFilterTemplate:", options,masterCatFilter);
    if (!federalFilterSelectedValues.mandatory && options?.filterModel?.value) {
      // console.log("requiredRowFilterTemplate filterModel",options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <Dropdown
        value={federalFilterSelectedValues.mandatory || []}
        options={mandatoryArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, mandatory: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const jurisdictionawardTypeRowFilterTemplate = (options) => {
    console.log("##Options:", options);
    return (
      <MultiSelect
        value={options.value}
        options={postawardtypeArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          console.log("##EOptions:", e);
          options.filterApplyCallback(e.value);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };
  const jurisdictionawardagencyRowFilterTemplate = (options) => {
    console.log("##Options:", options);
    return (
      <MultiSelect
        value={options.value}
        options={postawardagencyArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          console.log("##EOptions:", e);
          options.filterApplyCallback(e.value);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const fetchFilingMasters = async () => {
    console.log("@@Fetch FilingMasters1:", getClientFilingMastersApprovalList);
    setIsLoading(true)
    if (getClientFilingMastersApprovalList) {
      console.log("@@Fetch FilingMasters2:");
      try {
        let formData;
        if (
          roleType.includes("Admin-PostAward")
        ) {
          formData = {
            recordType: "PostAward",
          }
        }
        else {

          formData = {
            approverId: authUser.userId,
            recordType: "PostAward"
          };
        }

        const list = await getClientFilingMastersApprovalList(formData);
        console.log("@@FilingMasters:", list);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const onRowSelect = (e) => {
    console.log("@@RowSelect:", e);
  };

  useEffect(() => {

    if (ClientFilingMasterLoaded) {
      // console.log("Show Table Data", ClientFilingMasterApprovals);
      // let fedLookup = [];
      // let awardAgencyLookup = [];
      // let awardTypeLookup = [];
      // let federalProgramNameLookup = [];
      // let stateProgramNameLookup = [];
      // const updatedClientFilingMasterApprovals =
      //   ClientFilingMasterApprovals.filter(
      //     (item) => item.recordType === "PostAward"
      //   );
      // const updList = updatedClientFilingMasterApprovals
      //   .filter((item) => item.workflowStatus === "Pending")
      //   .map((item) => {
      //     console.log(`filtered data ...`, item);
      //     const _stateList = getStateName(item.stateInfo);
      //     const programName =
      //       item.programInfo && item.programInfo.length > 0
      //         ? item.programInfo[0].programName
      //         : "";
      //     const awardTypeName =
      //       item.awardType && item.awardType.length > 0
      //         ? item.awardType[0].awardTypeName
      //         : "";
      //     const awardingAgencyName =
      //       item.awardingAgency && item.awardingAgency.length > 0
      //         ? item.awardingAgency[0].awardingAgencyName
      //         : "";

      //     // setPostAwardTypeArray([...postawardtypeArray, awardTypeName])

      //     console.log(
      //       `postawardagencyArray.includes`,
      //       postawardagencyArray.includes(awardingAgencyName)
      //     );
      //     if (
      //       !awardAgencyLookup.includes(awardingAgencyName) &&
      //       awardingAgencyName !== ""
      //     ) {
      //       console.log(
      //         `!postawardagencyArray.includes`,
      //         !postawardagencyArray.includes(awardingAgencyName)
      //       );
      //       awardAgencyLookup.push(awardingAgencyName);
      //     }
      //     console.log(`postawardagencyArray`, postawardagencyArray);
      //     if (
      //       !awardTypeLookup.includes(awardTypeName) &&
      //       awardTypeName !== ""
      //     ) {
      //       awardTypeLookup.push(awardTypeName);
      //     }
      //     if (item.juristiction?.includes("Federal")) {
      //       // const categories = _.map(
      //       //   item.businessCategory,
      //       //   "businessCategoryName"
      //       // ).join(", ");
      //       // console.log(`filingMasters ${item.filingMasters} cat...`, categories);
      //       fedLookup.push(...programName);
      //       if (
      //         !federalProgramNameLookup.includes(programName) &&
      //         programName !== ""
      //       ) {
      //         // console.log(`!postawardagencyArray.includes`, !postawardagencyArray.includes(awardingAgencyName));
      //         federalProgramNameLookup.push(programName);
      //       }
      //       // setPostAwardFederalArray([...postawardfederalArray,programName]);
      //       return {
      //         ...item,
      //         federalCategories: programName,
      //         stateList: _stateList,
      //       };
      //     } else if (item.juristiction?.includes("Both")) {
      //       // const categories = _.map(
      //       //   item.businessCategory,
      //       //   "businessCategoryName"
      //       // ).join(", ");
      //       // console.log(`filingMasters ${item.filingMasters} cat...`, categories);
      //       fedLookup.push(...programName);
      //       if (
      //         !federalProgramNameLookup.includes(programName) &&
      //         programName !== ""
      //       ) {
      //         // console.log(`!postawardagencyArray.includes`, !postawardagencyArray.includes(awardingAgencyName));
      //         federalProgramNameLookup.push(programName);
      //       }
      //       // setPostAwardFederalArray([...postawardfederalArray,programName]);
      //       if (
      //         !stateProgramNameLookup.includes(programName) &&
      //         programName !== ""
      //       ) {
      //         stateProgramNameLookup.push(programName);
      //       }
      //       return {
      //         ...item,
      //         federalCategories: programName,
      //         stateCategories: programName,
      //         stateList: _stateList,
      //       };
      //     } else {
      //       // console.log(
      //       //   "stateCategories: props",
      //       //   item.stateInfo,
      //       //   item.businessCategory
      //       // );
      //       // const categoriesState = item.businessCategory.filter((ele) =>
      //       //   ele.state ? ele.state === item.stateInfo : ele
      //       // );
      //       // console.log(
      //       //   "stateCategories: filter",
      //       //   item.filingName,
      //       // //   categoriesState

      //       // );
      //       // const categories = categoriesState
      //       //   .map((ele) => ele.businessCategoryName)
      //       //   .join(", ");

      //       // console.log(`filingMasters ${item.filingMasters} cat...`, categories);
      //       // setPostAwardStateArray([...postawardstateArray,programName]);
      //       if (
      //         !stateProgramNameLookup.includes(programName) &&
      //         programName !== ""
      //       ) {
      //         // console.log(`!postawardagencyArray.includes`, !postawardagencyArray.includes(awardingAgencyName));
      //         stateProgramNameLookup.push(programName);
      //       }
      //       return {
      //         ...item,
      //         stateCategories: programName,
      //         stateList: _stateList,
      //         awardTypeName,
      //         awardingAgencyName,
      //       };
      //     }
      //   });
      // const _jusrisdictions = updList.map((item) =>
      //   item.juristiction ? item.juristiction : ""
      // );
      // const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
      // console.log("@@Unique Jurisdiction:", _uniqJurisdictions);
      // setJurisdiction(_uniqJurisdictions);
      // setData(() => updList);
      // setFederalCategoriesLookup(Array.from(new Set(fedLookup)));
      // setPostAwardAgencyArray(awardAgencyLookup);
      // setPostAwardTypeArray(awardTypeLookup);
      // setPostAwardFederalArray(federalProgramNameLookup);
      // setPostAwardStateArray(stateProgramNameLookup);
      // setMasters(updList);
      // setIsLoading(false)
    }

    //eslint-disable-next-line
  }, [ClientFilingMasterLoaded]);

  useEffect(() => {
    let list = states.map((ele) => ele.state);
    setStateArray(list);
    // fetchFilingMasters();
    setRecordsInView(0);
    setIsLoading(true);
    setData([]);
    setIsFilterChange([]);
    setSortingData({ sortBy: "", sortOrder: "" });
    setFederalFilterSelectedValues([]);
    setRefreshRecordList(true);

    //eslint-disable-next-line
  }, []);

  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  useEffect(() => {
    console.log("state", selectedRows);
  }, [selectedRows]);

  const search = useRef();

  const applyGlobalFilter = () => {
    console.log("applyGlobalFilter", search.current.value, isClearIcon)
    let _filters = { ...filters };
    if (isClearIcon) {
      search.current.value = "";
      _filters["global"].value = "";
      setIsClearIcon(false);
      onFilterChange({ filters });
    } else {
      _filters["global"].value = search.current.value;
      setIsClearIcon(true);
      onFilterChange({ filters });
    }
    setFilters(_filters);
  };

  const renderHeader = () => {
    return (
      <Stack
        direction={{ sm: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Typography
          variant="h6"
          noWrap
          component="a"
          sx={{
            fontWeight: 500,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          POST AWARD CLIENT FILING MASTER APPROVALS ({totalRecords})
        </Typography>

        <div>
          <InputText
            ref={search}
            inputRef={search}
            placeholder="Search by Filing Name..."
            style={{ fontSize: "14px" }}
            size="31"
          />
          <PrimeButton
              icon={isClearIcon ? "pi pi-times" : "pi pi-search"}
              onClick={(e) => applyGlobalFilter(e)}
            />          
        </div>
      </Stack>
    );
  };

  const header = renderHeader();
  const federalFilterElement = (options, field) => {
    console.log("##Options1234:", JSON.parse(JSON.stringify(data)));
    let optionsList = [];
    data
      .map((item) => item[field])
      .filter(Boolean)
      .map((item) => {
        if (item.includes(",")) {
          // console.log("spliited_item", item);
          item.split(",").map((subItem) => {
            optionsList.push(subItem.trim());
          });
        } else {
          optionsList.push(item.trim());
        }
      });
    optionsList = optionsList.filter((item, i) => {
      return i === optionsList.lastIndexOf(item);
    });

    return (
      <MultiSelect
        value={options.value ? federalFilterSelectedValues[field] : []}
        options={optionsList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];
          data.map((item) => {
            if (e.value.some((category) => item[field]?.includes(category))) {
              values.push(item[field]);
            }
          });
          values = values.filter((item, i) => {
            return i === values.lastIndexOf(item);
          });
          options.filterApplyCallback(values);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const stateRowFilterTemplate = (options) => {
    console.log("##Options:", options);
    return (
      <MultiSelect
        value={federalFilterSelectedValues.stateList || []}
        options={stateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          console.log("##EOptions:", e);
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, stateList: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    );
  };
  const displayTimeStamp = (rowData) => {
    return rowData.recentDate ? toLocalDateTime(rowData.recentDate) : ""
  }

  // const CellListDisplayFederal = (rowData, field) => {
  //   const op = useRef(null);

  //   let displayFederal = Array.isArray(rowData[field])
  //     ? rowData[field].join(", ")
  //     : rowData[field];

  //   console.log("CellListDisplayFederal", field, rowData.filingName, rowData);

  //   const categories = rowData[field]
  //     ? field === "federalCategories"
  //       ? rowData.businessCategory.map((ele) => ele.businessCategoryName)
  //       : rowData.businessCategory
  //           .filter((ele) => ele.state === rowData.stateInfo)
  //           .map((ele) => ele.businessCategoryName)
  //     : [];

  //   let name =
  //     field === "federalCategories"
  //       ? "Who Must File Federal"
  //       : "Who Must File in State";

  //   return (
  //     <div className="tableBtn" id={`${field}+${rowData.fileTrackingId}`}>
  //       <Button onClick={(e) => op.current.toggle(e)} text>
  //         <div
  //           style={{
  //             width: "12rem",
  //             overflow: "hidden",
  //             textOverflow: "ellipsis",
  //             color: "black",
  //           }}
  //         >
  //           {displayFederal}
  //         </div>
  //       </Button>
  //       <OverlayPanel ref={op} showCloseIcon style={{ padding: "0rem 1rem" }}>
  //         {name}
  //         <ScrollPanel style={{ maxWidth: "25rem", maxHeight: "20rem" }}>
  //           <ul>
  //             {categories.map((ele) => (
  //               <li>{ele}</li>
  //             ))}
  //           </ul>
  //         </ScrollPanel>
  //       </OverlayPanel>
  //     </div>
  //   );
  // };

  const cellListDisplayName = (rowData) => {
    return (
      <LightTooltip title={rowData.filingName}>
        <div
          style={{
            width: "12rem",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.filingName}
        </div>
      </LightTooltip>
    );
  };
  const cellDisplayClientName = (rowData) => {
    return (
      <LightTooltip title={rowData.customerName}>
        <div
          style={{
            width: "12rem",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.customerName}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayRuleInfo = (rowData) => {
    return (
      <LightTooltip title={rowData.ruleInfo}>
        <div
          style={{
            width: "12rem",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.ruleInfo}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayNotes = (rowData) => {
    const notesUrl = rowData.notes;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (notesUrl) {
      const formattedText = notesUrl.replace(
        urlRegex,
        '<a style="color: #0000FF;" href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
      );
      return (
        <LightTooltip
          title={
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: formattedText }} />
            </React.Fragment>
          }
        >
          <div
            style={{
              width: "18rem",
              margin: "0 1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </LightTooltip>
      );
    }
    return notesUrl;
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F1F2F6",
      color: "black",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  const CustomCommentButton = ({ rowData }) => {
    // console.log("rowComments", rowData.hasComments);
    // console.log("rowCustomerId", rowData.customerId);

    return (
      // <ToolTip
      //   title={
      //     rowData.hasComments ? "Comments available" : "Comments unavailable"
      //   }
      // >
      //   <span>
      <IconButton
        color="primary"
        disabled={!rowData.hasComments}
        onClick={() => addComment(rowData)}
      >
        <InsertCommentRoundedIcon />
      </IconButton>
      //   </span>
      // </ToolTip>
    );
  };

  const FilingAttachmentButton = ({ rowData }) => {
    return (
      <IconButton color="primary" disabled={!rowData.hasReference}>
        <SnippetFolderIcon onClick={() => displayAttachments(rowData)} />
      </IconButton>
    );
  };

  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const closeAlert = () => {
    setShowAlert(false);
    // fetchFilingMasters();
  };

  const updateSelectedWorkflow = (workflow) => {
    if (workflow) setSelectedFiling([workflow]);
    else setSelectedFiling([]);
  };

  const rowExpansionTemplate = (data) => {
    console.log("rowExpansionTemplate", data, data.filingPeriods);
    if (data.filingPeriods.length > 0) {
      let tableData = data.filingPeriods.map((ele) => {
        let startDate = new Date(ele.startDate);
        let endDate = new Date(ele.endDate);
        const toLocalDateString = date => date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

        let fp = `${toLocalDateString(startDate)} - ${toLocalDateString(endDate)}`;
        let dueDate = ele.dueDate !== null ? toLocalDateString(new Date(ele.dueDate)) : null;
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
          dueDate: dueDate,
        };
      });
      console.log("rowExpansionTemplate", tableData);
      return (
        <div className="filingPeriodTable p-3" style={{ width: "20vw" }}>
          {/* <h5>Filing Period for {data.filingName}</h5> */}
          <DataTable value={tableData}>
            <Column field="filingPeriod" header="Filing Period"></Column>
            {/* <Column field="dueDate" header="DueDate"></Column> */}
          </DataTable>
        </div>
      );
    } else {
      return (
        <div className="filingPeriodTable p-3">
          <h5>No Filing Period for {data.filingName}</h5>
        </div>
      );
    }
  };

  const rowExpand = (event) => {
    console.log("rowExpand", event, expandedRows);
    // const newKeys =
    //   expandedRows !== null && expandedRows !== undefined
    //     ? Object.keys(event.data).filter(
    //         (key) => !expandedRows.hasOwnProperty(key)
    //       )[0]
    //     : Object.keys(event.data)[0];
    // let newExpanded = {};
    // newExpanded[newKeys] = event.data[newKeys];
    // console.log("rowExpand", newKeys, newExpanded);
    setExpandedRows(event.data);
  };

  const onRowExpand = (event) => {
    console.log("rowExpand-->", event);
    getFilingPeriod(event.data);
  };

  const getFilingPeriod = async (data) => {
    console.log("@@Fetch getFilingPeriod", data?.filingId);
    try {
      const filingPeriod = await axios.get(
        `${API_BASE_URL}/FilingMaster/ListFilingMasterDraftPeriods/${data?.filingId}`
      );
      console.log("getFilingPeriod res", filingPeriod.data.data);
      let tableData = filingPeriod.data.data.map((ele) => {
        let fp = `${toLocalTime(ele.startDate)} - ${toLocalTime(ele.endDate)}`;
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
          dueDate: toLocalTime(ele.dueDate),
          initialDueDate: toLocalTime(ele.dueDate),
        };
      });
      console.log("getFilingPeriod tableData", tableData);
      setFilingPeriodTable(tableData);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yy");
      console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const dt = useRef(null);
  const pageSize = 100;

  // lazy load function
  const loadDataLazy = async (event) => {
    console.log("loadDataLazy props", recordsInView, event);

    //Virtual scroll callback
    const vScroll = dt.current.getVirtualScroller()

    let range = vScroll.getRenderedRange();
    let { first, last } = range.viewport;
    console.log("loadDataLazy vScroll", vScroll, range.viewport);

    let firstVal = recordsInView * pageSize;
    let tableList = data;
    console.log(
      "loadDataLazy totalRecords",
      firstVal,
      totalRecords,
      lazyLoading,
      tableList.length
    );

    console.log(
      "loadDataLazy",
      firstVal < totalRecords,
      first > tableList.length - 10,
      !lazyLoading
    );

    if (
      firstVal < totalRecords &&
      first > tableList.length - 10 &&
      !lazyLoading
    ) {
      //scroll down
      // setIsLoading(true);
      setLazyLoading(true);
      let lastVal = firstVal + pageSize;
      console.log("loadDataLazy d firstVal", firstVal, lastVal);

      //get next data
      let records = await loadRecordsList(firstVal, lastVal, "down");
      console.log("loadDataLazy d record", records);
      if (records) {
        console.log("loadDataLazy d scrollInView", recordsInView * 100);

        //scroll to next records
        vScroll.scrollInView(recordsInView * 100);
        setRecordsInView((old) => old + 1);
        setLazyLoading(false);
        setIsLoading(false);
      }
    } else if (last < 10 && recordsInView > 1 && !lazyLoading) {
      // scroll up
      let firstVal = (recordsInView - 3) * pageSize;
      let lastVal = firstVal + pageSize;
      if (firstVal >= 0) {
        // setIsLoading(true);
        setLazyLoading(true);
        // console.log("loadDataLazy u firstVal", firstVal, lastVal);

        //get prev data
        let records = await loadRecordsList(firstVal, lastVal, "up");
        // console.log("loadDataLazy u record", records);
        if (records) {
          // console.log("loadDataLazy u scrollInView", (recordsInView - 3) * 100);

          //scroll to prev records
          vScroll.scrollToIndex(15);
          setRecordsInView((old) => old - 1);
          setLazyLoading(false);
          setIsLoading(false);
        }
      }
    }
  };

  const loadRecordsList = async (first, last, direction) => {
    // console.log("loadRecordsList props", first, last, direction);
    setIsLoading(true);
    let url = `${API_BASE_URL}/Customer/CustomerFilingMasterWorkflowListbyPagination?StartRow=${first + 1}&EndRow=${last}&RecordType=PostAward`

    // console.log("loadRecordsList fliter/sort", isFilterChange, sortingData);
    if (isFilterChange !== undefined && isFilterChange.length > 0) {
      // url filter parameters
      isFilterChange.forEach((ele) => {
        url = `${url}&${ele.name}=${ele.val}${ele.searchType ? `&${ele.searchType}=${ele.searchTypeVal}` : ``
          }`;
      });
    }

    if (
      sortingData &&
      typeof sortingData === "object" &&
      sortingData.sortBy !== "" &&
      sortingData.sortOrder !== ""
    ) {
      // url sort parameters
      let sortParameter = getSortData(sortingData.sortBy);
      console.log("getSortData", sortParameter);
      let order = sortingData.sortOrder === 1 ? "Ascending" : "Descending";

      url = `${url}&${sortParameter.SortByParameter}=${sortParameter.SortByVal}&${sortParameter.SortOrderParameter}=${order}`;
    }

    // console.log("loadRecordsList url", url);
    try {
      //get data
      const res = await axios.get(url);
      console.log("loadRecordsList res", res.data, res.data.data.items);

      if (res.data) {
        setIsLoading(true);
        let ogList = data.map((ele) => ele);
        await modifyList(res.data.data.items, direction, ogList);
        setTotalRecords(res.data.data.totalItems);

        return res.data.data.items;
      } else {
        return false;
      }
    } catch (err) {
      // console.log("loadRecordsList err", err);
    }
  };

  //maping records for lazy load
  const modifyList = (list, direction, ogList) => {
    console.log("modifyList", list, direction);
    if (list.length > 0) {
      //list has data
      let fedLookup = [];
      let awardAgencyLookup = [];
      let awardTypeLookup = [];
      let federalProgramNameLookup = [];
      let stateProgramNameLookup = [];
      const updList = list
        .map((item) => {
          console.log(`filtered data ...`, item);
          const _stateList = getStateName(item.stateInfo);
          const programName =
            item.programInfo && item.programInfo.length > 0
              ? item.programInfo[0].programName
              : "";
          const awardTypeName =
            item.awardType && item.awardType.length > 0
              ? item.awardType[0].awardTypeName
              : "";
          const awardingAgencyName =
            item.awardingAgency && item.awardingAgency.length > 0
              ? item.awardingAgency[0].awardingAgencyName
              : "";

          // setPostAwardTypeArray([...postawardtypeArray, awardTypeName])

          console.log(
            `postawardagencyArray.includes`,
            postawardagencyArray.includes(awardingAgencyName)
          );
          if (
            !awardAgencyLookup.includes(awardingAgencyName) &&
            awardingAgencyName !== ""
          ) {
            console.log(
              `!postawardagencyArray.includes`,
              !postawardagencyArray.includes(awardingAgencyName)
            );
            awardAgencyLookup.push(awardingAgencyName);
          }
          console.log(`postawardagencyArray`, postawardagencyArray);
          if (
            !awardTypeLookup.includes(awardTypeName) &&
            awardTypeName !== ""
          ) {
            awardTypeLookup.push(awardTypeName);
          }
          if (item.juristiction?.includes("Federal")) {
            fedLookup.push(...programName);
            if (
              !federalProgramNameLookup.includes(programName) &&
              programName !== ""
            ) {
              // console.log(`!postawardagencyArray.includes`, !postawardagencyArray.includes(awardingAgencyName));
              federalProgramNameLookup.push(programName);
            }
            // setPostAwardFederalArray([...postawardfederalArray,programName]);
            return {
              ...item,
              federalCategories: programName,
              stateList: _stateList,
              awardTypeName,
              awardingAgencyName,
            };
          } else if (item.juristiction?.includes("Both")) {
            fedLookup.push(...programName);
            if (
              !federalProgramNameLookup.includes(programName) &&
              programName !== ""
            ) {
              // console.log(`!postawardagencyArray.includes`, !postawardagencyArray.includes(awardingAgencyName));
              federalProgramNameLookup.push(programName);
            }
            // setPostAwardFederalArray([...postawardfederalArray,programName]);
            if (
              !stateProgramNameLookup.includes(programName) &&
              programName !== ""
            ) {
              stateProgramNameLookup.push(programName);
            }
            return {
              ...item,
              federalCategories: programName,
              stateCategories: programName,
              stateList: _stateList,
              awardTypeName,
              awardingAgencyName,
            };
          } else {
            if (
              !stateProgramNameLookup.includes(programName) &&
              programName !== ""
            ) {
              // console.log(`!postawardagencyArray.includes`, !postawardagencyArray.includes(awardingAgencyName));
              stateProgramNameLookup.push(programName);
            }
            return {
              ...item,
              stateCategories: programName,
              stateList: _stateList,
              awardTypeName,
              awardingAgencyName,
            };
          }
        });

      const _jusrisdictions = updList.map((item) =>
        item.juristiction ? item.juristiction : ""
      );
      const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
      console.log("@@Unique Jurisdiction:", _uniqJurisdictions);
      setJurisdiction(_uniqJurisdictions);
      setFederalCategoriesLookup(Array.from(new Set(fedLookup)));
      setPostAwardAgencyArray(awardAgencyLookup);
      setPostAwardTypeArray(awardTypeLookup);
      setPostAwardFederalArray(federalProgramNameLookup);
      setPostAwardStateArray(stateProgramNameLookup);

      let tableList = data;
      console.log("modifyList updCustomers", updList, tableList);

      let updFilingMastersList;
      if (direction === "down") {
        //scroll down
        if (tableList.length >= 200) {
          //list > 200

          //slice 2 half of list
          let unloadList = ogList.slice(
            ogList.length - pageSize,
            ogList.length
          );
          // console.log("modifyList dw unloadList", unloadList);

          //add new data
          updFilingMastersList = [...unloadList, ...updList];
        } else {
          //list < 200

          // add new data to list
          updFilingMastersList = [...ogList, ...updList];
        }
      } else if (direction === "up") {
        //scroll up
        if (tableList.length >= 200) {
          //list > 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updList, ...unloadList];
        } else {
          //list < 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updList, ...unloadList];
        }
      }

      setData(() => updFilingMastersList);
      setMasters(updFilingMastersList);
      setClientFilingMastersApprovalList(updFilingMastersList)
      setRefreshRecordList(false);
      setLazyLoading(false);
      setIsLoading(false);
      return updFilingMastersList;
    } else {
      //list empty
      // console.log("modifyList empty",list)
      setRefreshRecordList(false);
      setLazyLoading(false);
      setIsLoading(false);
      setData([])
      setClientFilingMastersApprovalList([])
      return [];
    }
  };

  const onFilterChange = async (e) => {
    console.log("onFilterChange props", e.filters);
    let filterChange = [];

    if (e.filters.global.value !== "" && e.filters.global.value !== null) {
      //global filter
      filterChange.push({
        name: `SearchByFilingName`,
        val: e.filters.global.value,
        searchType: `FilingNameSearchType`,
        searchTypeVal: getSearchType("contains"),
      });
    } else {
      //map filter event
      Object.keys(e.filters).forEach((col) => {
        // console.log("onFilterChange col", col, e.filters[col]);
        if (
          e.filters[col].constraints &&
          e.filters[col].constraints[0].value !== null
        ) {
          if (col === "stateList") {
            Array.isArray(e.filters[col].constraints[0].value)
              ? e.filters[col].constraints[0].value.map((ele, i) => {
                let changeItem = {
                  name: `SearchByStateInfo`,
                  val: getStateCode(ele),
                };
                if (i + 1 === e.filters[col].constraints[0].value.length) {
                  changeItem.searchType = `StateInfoSearchType`;
                  changeItem.searchTypeVal = getSearchType(
                    e.filters[col].constraints[0].matchMode
                  );
                }
                filterChange.push(changeItem);
              })
              : filterChange.push({
                name: `SearchByStateInfo`,
                val: getStateCode(e.filters[col].constraints[0].value),
                searchType: `StateInfoSearchType`,
                searchTypeVal: getSearchType(
                  e.filters[col].constraints[0].matchMode
                ),
              });
          } else if (col === "jsicontactName") {
            filterChange.push({
              name: `SearchByJSIStaff`,
              val: e.filters[col].constraints[0].value,
              searchType: `JSIStaffSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "customerName") {
            filterChange.push({
              name: `SearchByClientName`,
              val: e.filters[col].constraints[0].value,
              searchType: `ClientNameSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "juristiction") {
            filterChange.push({
              name: `SearchByJurisdiction`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "required") {
            filterChange.push({
              name: `SearchByRequired`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "awardTypeName") {
            filterChange.push({
              name: `SearchByAwardType`,
              val: e.filters[col].constraints[0].value,
              searchType: `AwardTypeSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "awardingAgencyName") {
            filterChange.push({
              name: `SearchByAwardAgency`,
              val: e.filters[col].constraints[0].value,
              searchType: `AwardAgencySearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "federalCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `WhoMustFileFederal`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileFederalSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "stateCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              let changeItem = {
                name: `WhoMustFileInState`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileInStateSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else {
            filterChange.push({
              name: `SearchBy${col}`,
              val: e.filters[col].constraints[0].value,
              searchType: `${col}SearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          }
        }
      });
    }
    console.log("onFilterChange filterChange", filterChange);

    //set filter
    handleSetFilterVariable(filterChange);
  };

  const formatFilterDate = (date) => {
    // console.log("formatFilterDate", date);
    let getDate = new Date(date);
    let formatDate = `${getDate.getFullYear()}-${getDate.getMonth() + 1
      }-${getDate.getDate()}`;
    // console.log("formatFilterDate formatDate", getDate.getFullYear(), formatDate);
    return formatDate;
  };

  const getStateCode = (stateName) => {
    // console.log("getStateCode", stateName, states);
    const state = states.find((s) => s.state === stateName);
    return state ? state.code : stateName;
  };

  const matchModeVal = [
    { name: "contains", val: "Contains" },
    { name: "in", val: "Contains" },
    { name: "notContains", val: "NotContains" },
    { name: "equals", val: "EqualTo" },
    { name: "dateIs", val: "DateIs" },
    { name: "dateIsNot", val: "DateIsNot" },
    { name: "dateBefore", val: "DateIsBefore" },
    { name: "dateAfter", val: "DateIsAfter" },
  ];

  const getSearchType = (matchMode) => {
    // console.log("getSearchType props", matchMode);
    return matchModeVal.find((ele) => ele.name === matchMode).val;
  };

  //set filter
  const handleSetFilterVariable = (filterChange) => {
    console.log("handleSetFilterVariable props", filterChange);
    setIsFilterChange(() => filterChange);
    setRecordsInView(0);
    setIsLoading(true);
    setData([]);
  };

  const handleSort = async (e) => {
    console.log("handleSort props", e, e.sortField);
    if (e.sortField !== "") {
      setSortingData(() => ({
        sortBy: e.sortField,
        sortOrder: e.sortOrder,
      }));
      setRecordsInView(0);
      setIsLoading(true);
      setData([]);
    }
  };

  const getSortData = (val) => {
    console.log("getSortData props", val);
    if (val === "federalCategories" || val === "stateCategories") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "BusinessCategory",
        SortOrderParameter: "SortOrder",
      };
    } else if (val === "awardTypeName") {
      return {
        SortByParameter: "SortByAwardType",
        SortByVal: "AwardType",
        SortOrderParameter: "SortOrderAwardType"
      }
    } else if (val === "awardingAgencyName") {
      return {
        SortByParameter: "SortByAwardingAgency",
        SortByVal: "AwardingAgency",
        SortOrderParameter: "SortOrderAwardingAgency"
      }
    } else {
      return {
        SortByParameter: "SortBy",
        SortByVal: val.charAt(0).toUpperCase() + val.slice(1),
        SortOrderParameter: "SortOrder",
      };
    }
  };

  useEffect(() => {
    // console.log("filter/sort change", isFilterChange, sortingData, lazyLoading );
    //on filter/sort change
    if (!lazyLoading && !refreshRecordList) {
      setLazyLoading(true);
      const sortAndFilterApiCall = async () => {
        let records = await loadRecordsList(0, 100, "down");
        setRecordsInView(1);
        console.log("recordsCheck", records);
        setIsLoading(false);
      };
      sortAndFilterApiCall();
    }
  }, [isFilterChange, sortingData]);

  const handleRefreshList = () => {
    //Virtual scroll callback
    const vScroll = dt.current.getVirtualScroller()
    let range = vScroll.getRenderedRange();
    console.log("refreshTable vScroll", vScroll, range);

    let pageInView
    let noOfPages
      if (data.length > 100) {
        pageInView = recordsInView - 2
        noOfPages = 2
      } else {
        pageInView = recordsInView - 1
        noOfPages = 1
      }
    let clientSelected = selectedFiling
    setRecordsInView(pageInView)
    setRefreshDetails({
      viewport: range.viewport,
      pageInView,
      noOfPages,
      clientSelected
    })

    // fetchFilingMasters();
    setIsLoading(true);
    setData([]);
    setIsFilterChange([]);
    setSortingData({ sortBy: "", sortOrder: "" });
    setFederalFilterSelectedValues([]);
    setRefreshRecordList(true);
  }

  const refreshRecords = async () => {
    console.log("refreshRecords");

   //Virtual scroll callback
    const vScroll = dt.current.getVirtualScroller()
    let range = vScroll.getRenderedRange();
    console.log("refreshRecords vScroll", vScroll, range);

    let records
    console.log("refreshRecords refreshDetails", refreshDetails);
    if (refreshDetails?.pageInView || refreshDetails?.noOfPages) {
      let first = refreshDetails.pageInView * pageSize
      records = await loadRecordsList(first, first + refreshDetails?.noOfPages * pageSize, "down");
    } else {
      records = await loadRecordsList(0, pageSize, "down");
    }

    if (records) {
      console.log("refreshRecords refreshDetails", refreshDetails);
      if (refreshDetails?.viewport && refreshDetails?.noOfPages) {
        setRecordsInView((old) => old + refreshDetails?.noOfPages);
        // setRefreshDetails(null)
      } else {
        setRecordsInView((old) => old + 1);
      }
      setLazyLoading(false)
    }
  };

  useEffect(() => {
    console.log("refreshRecordList,", refreshRecordList, !lazyLoading);
    if (refreshRecordList && !lazyLoading) {
      setLazyLoading(true);
      refreshRecords();
    }
  }, [refreshRecordList]);

  useEffect(() => {
    console.log("Total Records updated:", totalRecords);
  }, [totalRecords]);

  useEffect(() => {
    console.log("recordsInView,", recordsInView);
  }, [recordsInView]);

  useEffect(() => {
    console.log("pendingTableData,", data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    data.length,
    JSON.stringify(data),
  ]);

  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log("lazyLoading", lazyLoading);
  }, [lazyLoading]);

  return (
    <Fragment>
      <Grid sx={{ height: "100%", width: "93%" }}>
        {/* <Grid sm={23} sx={{ height: "10vh" }}>
    <PageNavbar pageTitle={"FILING MASTER LIST"} />
    </Grid> */}
        <PostAwardApproveClientFilingMaster
          show={showFilter}
          dockAt="right"
          drafts={selectedFiling}
          handleClose={closeFilter}
          updateSelectedWorkflow={updateSelectedWorkflow}
          refreshList={handleRefreshList}
        />
        <Grid sx={{ height: "100%" }}>
          <div
            className="card"
            style={{
              maxWidth: "95vw",
              padding: "1rem",
              margin: "1rem",
              background: "#FFF",
              borderRadius: "2%",
            }}
          >
            <DataTable
              ref={dt}
              scrollable
              resizableColumns
              showGridlines
              emptyMessage="No records found"
              value={ClientFilingMasterApprovals}
              className="frozen-client-approval-pa"
              selection={selectedFiling}
              onSelectionChange={(e) => setSelectedFiling(e.value)}
              dataKey="workflowId"
              style={{ minHeight: "50vh" }}
              onRowSelect={onRowSelect}
              onRowToggle={(e) => rowExpand(e)}
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={expandedRows}
              loading={isLoading}
              // rows={viewAll ? data.length : 10}
              // rowsPerPageOptions={[5, 10, 25, 50]}
              // paginator={!viewAll}
              filters={filters}
              filterMode="multiple"
              globalFilterFields={["customerName", "filingName"]}
              header={header}
              virtualScrollerOptions={{
                onScroll: loadDataLazy,
                itemSize: 70,
                delay: 0,
                scrollHeight: "550px",
                appendOnly: true,
              }}
              scrollHeight="550px"
              onFilter={(e) => onFilterChange(e)}
              sortField={sortingData.sortBy}
              sortOrder={sortingData.sortOrder}
              onSort={(e) => handleSort(e)}
            >
              <Column expander={true} style={{ width: "5rem" }} />
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              >
                Select
              </Column>
              <Column
                body={(rowData) => (
                  <>
                    <Stack direction="row">
                      <CustomCommentButton rowData={rowData} />
                      <FilingAttachmentButton rowData={rowData} />
                    </Stack>
                  </>
                )}
                header="Indicators"
                style={{ textAlign: "center" }}
              ></Column>
              <Column
                field="approverName"
                header="Approver Name"
                sortable
                filter
                filterField="approverName"
                showAddButton={false}
                showFilterOperator={false}
                frozen
                className="filingNameColumn-pa"
                filterPlaceholder="Search by Approver Name"
                style={{ minWidth: "15rem" }}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
              ></Column>
              <Column
                field="customerName"
                header="Client Name"
                sortable
                filter
                filterField="customerName"
                showAddButton={false}
                showFilterOperator={false}
                frozen
                filterPlaceholder="Search by Client Name"
                body={(rowData) => cellDisplayClientName(rowData)}
                style={{ minWidth: "15rem" }}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
              ></Column>
              <Column
                field="recentDate"
                header="Time Stamp"
                sortable
                style={{ minWidth: "15rem" }}
                frozen
                body={displayTimeStamp}
              ></Column>
              <Column
                field="filingName"
                header="Filing Name"
                sortable
                filter
                filterField="filingName"
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder="Search by Filing Name"
                style={{ minWidth: "12rem", maxWidth: "25rem" }}
                body={(rowData) => cellListDisplayName(rowData)}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
              ></Column>
              {/* <Column
                      field='filingDescription'
                      header='Filing Description'
                      sortable
                      filter
                      filterPlaceholder='Search by Description'
                      style={{ minWidth: "15rem" }}
                    ></Column> */}
              <Column
                field="filingFrequency"
                header="Filing Frequency"
                filter
                filterField="filingFrequency"
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder="Search by Frequency"
                style={{ minWidth: "15rem" }}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
              ></Column>
              {/* <Column
                      field='dueDayofFrequency'
                      header='Due Date Offset'
                      filter
                      filterPlaceholder='Search by Frequency'
                      style={{ minWidth: "15rem" }}
                    ></Column> */}
              <Column
                field="stateList"
                header="State"
                filter
                filterField="stateList"
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder="Search by State"
                showFilterMatchModes={false}
                filterElement={stateRowFilterTemplate}
                onFilterClear={() => setFederalFilterSelectedValues([])}
                style={{ minWidth: "13rem" }}
                className="column-small"
                onFilterMatchModeChange={() =>
                  setFederalFilterSelectedValues([])
                }
              ></Column>
              <Column
                field="ruleInfo"
                header="Rule Info"
                filter
                filterField="ruleInfo"
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder="Search by Rule Info"
                style={{ minWidth: "15rem", maxWidth: "15rem" }}
                body={(rowData) => cellListDisplayRuleInfo(rowData)}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
              ></Column>
              <Column
                field="required"
                header="Mandatory"
                filter
                filterField="required"
                showAddButton={false}
                showFilterOperator={false}
                showFilterMatchModes={false}
                filterElement={mandatoryRowFilterTemplate}
                onFilterClear={() => setFederalFilterSelectedValues([])}
                filterPlaceholder="Search by Required"
                style={{ minWidth: "13rem" }}
                className="column-small"
              ></Column>
              <Column
                field="federalCategories"
                header="Who Must File Federal"
                // sortable
                filter
                filterField="federalCategories"
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterElement={postawardFederalRowFilterTemplate}
                // filterElement={(options) =>
                //   federalFilterElement(options, "federalCategories")
                // }
                // body={(rowData) =>
                //   CellListDisplayFederal(rowData, "federalCategories")
                // }
                onFilterMatchModeChange={() => setMasterCatFilter([])}
                onFilterClear={() => setMasterCatFilter([])}
                filterPlaceholder="Search by Category"
                style={{ minWidth: "19rem", maxWidth: "19rem" }}

              // showClearButton={true}
              ></Column>
              <Column
                field="stateCategories"
                header="Who Must File in State"
                // sortable
                filter
                filterField="stateCategories"
                // filterElement={(options) =>
                //   federalFilterElement(options, "stateCategories")
                // }
                // body={(rowData) =>
                //   CellListDisplayFederal(rowData, "stateCategories")
                // }
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterElement={postawardStateRowFilterTemplate}
                // filterElement={(options) =>
                //   federalFilterElement(options, "federalCategories")
                // }
                // body={(rowData) =>
                //   CellListDisplayFederal(rowData, "federalCategories")
                // }
                onFilterMatchModeChange={() => setMasterCatFilter([])}
                onFilterClear={() => setMasterCatFilter([])}
                filterPlaceholder="Search by Category"
                style={{ minWidth: "19rem", maxWidth: "19rem" }}

              // showClearButton={true}
              ></Column>
              {/* <Column
                      field='jsidept'
                      header='JSI Dept'
                      filter
                      filterPlaceholder='Search by dept'
                      style={{ minWidth: "15rem" }}
                    ></Column> */}
              <Column
                field="awardTypeName"
                header="Award Type"
                sortable
                filter
                filterField="awardTypeName"
                showAddButton={false}
                showFilterOperator={false}
                // showFilterMenu={false}
                // filterMenuStyle={{ width: "14rem" }}
                // style={{ minWidth: "14rem" }}
                // showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterElement={postawardTypeRowFilterTemplate}
                onFilterMatchModeChange={() => setMasterCatFilter([])}
                onFilterClear={() => setMasterCatFilter([])}
                filterPlaceholder="Search by Award Type"
                style={{ minWidth: "19rem", maxWidth: "19rem" }}
              //showClearButton={false}
              ></Column>
              <Column
                field="awardingAgencyName"
                header="Award Agency"
                sortable
                filter
                filterField="awardingAgencyName"
                showAddButton={false}
                showFilterOperator={false}
                // showFilterMenu={false}
                // filterMenuStyle={{ width: "14rem" }}
                // style={{ minWidth: "14rem" }}
                filterElement={postawardAgencyRowFilterTemplate}
                onFilterMatchModeChange={() => setMasterCatFilter([])}
                onFilterClear={() => setMasterCatFilter([])}
                filterPlaceholder="Search by Award Agency"
                style={{ minWidth: "19rem", maxWidth: "19rem" }}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
              ></Column>
              <Column
                field="jsicontactName"
                header="JSI Staff"
                filter
                filterField="jsicontactName"
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder="Search by JSI Staff"
                style={{ minWidth: "15rem" }}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
              ></Column>
              {/* <Column
                      field='jsicontactEmail'
                      header='JSI Contact Email'
                      filter
                      filterPlaceholder='Search by contactEmail'
                      style={{ minWidth: "15rem" }}
                    ></Column> */}
              <Column
                field="juristiction"
                header="Jurisdiction"
                sortable
                filterField="juristiction"
                showAddButton={false}
                showFilterOperator={false}
                showFilterMatchModes={false}
                style={{ minWidth: "14rem" }}
                filter
                filterElement={jurisdictionRowFilterTemplate}
                onFilterMatchModeChange={() => setFederalFilterSelectedValues([])}
                onFilterClear={() => setFederalFilterSelectedValues([])}
              ></Column>
              <Column
                field="notes"
                header="Notes"
                style={{ minWidth: "21rem", maxWidth: "21rem" }}
                body={(rowData) => cellListDisplayNotes(rowData)}
              ></Column>
            </DataTable>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          height: "100%",
          width: "5%",
        }}
      >
        <div
          style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}
        >
          <Item
            sx={{
              height: "100%",
            }}
          >
            <PostAwardApproveToolbox
              isLoading={isLoading}
              addComment={addComment}
              approveDraft={approveDraft}
              displayAttachments={displayAttachments}
            />
          </Item>
        </div>
      </Grid>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {
        <ClientFilingMasterWorkflowComments
          show={showComments}
          dockAt="right"
          workflow={selectedFiling[0]}
          rowData={selectedRowData}
          comments={comments}
          handleClose={closeComments}
          refreshList={handleRefreshList}
        />
      }
      {
        <ClientFilingMasterReferenceDocuments
          show={showAttachments}
          dockAt="right"
          filing={selectedFiling[0]}
          attachments={attachments}
          handleClose={closeAttachments}
        />
      }
    </Fragment>
  );
};

export default PostAwardClientFilingMasterApprovalList;
