import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AlertColor, Divider, FormHelperText, Icon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MuiButton from "@mui/material/Button";
import { Button } from "primereact/button";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/material";
import Switch from "@mui/material/Switch";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Dialog } from "primereact/dialog";
import AuthContext from "../../../context/auth/AuthContext";
import FilingMasterContext from "../../../context/filing-master/FilingMasterContext";
import { BusinessCategory, FilingMaster } from "../../../types/FilingMaster";
import states from "../../../data/StateLookup.json";
import { juristictionLookup, deptLookup } from "../../../data/constants";
import MultiSelect from "../../common/MultiSelect";
import axios from "axios";
import { filingFrequencyLookup } from "../../../data/constants";
import { useFormik } from "formik";
import * as yup from "yup";
import FileDownload from "js-file-download";
import { DateTime } from "luxon";
import { FilingMasterAttachment } from "../../../types/FilingMaster";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import Loader from "../../common/Loader";
import { Calendar } from "primereact/calendar";
import UploadedFilesTable from "../../filing-master/UploadedFilesTable";
import { useLocation } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// import CustomerContext from "../../../context/customer/CustomerContext";

import CustomerContext from "../../../context/customer/CustomerContext";

const theme = createTheme();
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function PostAwardMasterForm() {
  const filingMasterContext = useContext(FilingMasterContext);
  const {
    selectedFilingMaster,
    setCurrentFilingMaster,
    getBusinessCategories,
    getProgramName,
    createDraftFilingMaster,
    createClientDraftFilingMaster,
    GeneratePostAwardMasterWorkflowForClient,
    createPostAwardWorkflow,
    getAwardingAgencies,
  } = filingMasterContext;
  const customerContext = useContext(CustomerContext);
  const { selectedCustomer } = customerContext;
  const authContext = useContext(AuthContext);
  const {
    authUser,
    getCustomerNotifications,
    getFilingNotifications,
    getCustomerNotificationsWorkFlow,
    setNotificationCount,
  } = authContext;
  const location = useLocation();
  const selectedAffectedCompanies = location?.state?.selectedAffectedCompanies ? location.state.selectedAffectedCompanies : [] // Newly adding companies through add form
  const selectedAffectedCompaniesEdit = location?.state?.selectedAffectedCompaniesEdit ? location.state.selectedAffectedCompaniesEdit : []
  const newlyAddingAffectedCompanies = selectedAffectedCompaniesEdit ? selectedAffectedCompaniesEdit.filter((item: any) => {
    return !selectedAffectedCompanies.includes(item);
  }) : []; //Newly adding affected companies through edit form 
  const customerNames = location?.state?.customerNames ? location.state.customerNames : []
  const [customerList, setCustomerList] = useState<any[]>([])
  const [alertMessage, setAlertMessage] = useState<string | JSX.Element>("");
  const [switchChecked, setSwitchChecked] = useState(false);
  const [categories, setCategories] = useState<BusinessCategory[]>([]);
  const [awardingAgencies, setAwardingAgencies] = useState<any[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [initCategories, setInitCategories] = useState<string[]>([]);
  const [filingPeriod, setFilingPeriod] = useState<any[]>([]);
  const [filingPeriodVal, setFilingPeriodVal] = useState<any>("");
  const [isInitalFilingVal, setIsInitalFilingVal] = useState(false);
  const [filingPeriodValError, setFilingPeriodValError] = useState<string>();
  const [filingPeriodTable, setFilingPeriodTable] = useState<any[]>([]);
  const [filingPeriodTableError, setFilingPeriodTableError] =
    useState<string>();
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [draftSubmitted, setDraftSubmitted] = useState(false);
  const [isEqual, setIsEqual] = useState(true);
  const [isAttachmentChanges, setIsAttachmentChanges] = useState(false);
  const [isFilesAdded, setIsFileAdded] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [files, setFiles] = useState<

    {
      name: string;
      attachmentId: number;
      attachmentPath: string;
    }[]
  >([]);
  const [formTitle, setFormTitle] = useState<any>(
    "ADD POST AWARD MASTER RECORD"
  );
  const [remainingChars, setRemainingChars] = useState(2000);
  const [whereToFileRemainingChars, setWherToRemainingChars] = useState(500);
  const [draftId, setDraftId] = useState<any>();
  const [isCategoryChanged, setIsCategoryChanged] = useState(false);
  const [actionLabel, setActionLabel] = useState<string>("ADD RECORD");
  const [filingAttachment, setFilingAttachment] = useState<Object[]>([]);
  const [attachmentError, setAttachmentError] = useState(false);
  const [attachments, setAttachments] = React.useState<
    FilingMasterAttachment[]
  >([]);
  const [attachmentChange, setAttachmentChange] = React.useState<
    FilingMasterAttachment[]
  >([]);
  const [customerNotificationCount, setCustomerNotificationCount] =
    React.useState(0);
  const [filingNotificationCount, setFilingNotificationCount] =
    React.useState(0);
  const [customerNotificationsWorkFlow, setCustomerNotificationsWorkFlow] =
    React.useState(0);
  const [programList, setProgramList] = useState<any>([]);
  const [awardTypeList, setAwardTypeList] = useState<any>([]);
  const fontStyle = {
    fontWeight: "regular",
    fontSize: "13px",

    color: "#424242",
  };
  console.log("selectedAffectedCompanies", selectedAffectedCompanies, "selectedAffectedCompaniesEdit", selectedAffectedCompaniesEdit);
  const initForm: FilingMaster = {
    filingId: 0,
    filingName: "",
    filingDescription: "",
    filingFrequency: "",
    dueDayofFrequency: 0,
    stateInfo: "",
    awardType: "",
    awardingAgency: "",
    programName: "",
    ruleInfo: "",
    juristiction: "",
    jsidept: "",
    jsicontactName: "",
    jsicontactEmail: "",
    jsicontactNumber: "",
    required: true,
    notes: "",
    whereToFile: "",
    changesInprogress: true,
    businessCategory: [],
    filingPeriod: undefined,
  };

  const validationSchema = yup.object({
    filingName: yup
      .string()
      .min(2, "Name is too Short!")
      .max(250, "Name is too Long!")
      .required("Filing Name is required"),
    filingFrequency: yup.string().required("Filing Frequency is required"),
    juristiction: yup.string().required("Jurisdiction is required"),
    programName: yup.string().required("Program Name is required"),
    jsicontactEmail: yup.string().email("Enter a valid email"),
    stateInfo: yup
      .string()
      .when("juristiction", (juristiction: string[], schema) => {
        // console.log("Validation", juristiction);
        if (juristiction[0] === "State") {
          return schema.required("State is required");
        }
        return schema;
      }),
  });

  const [isFinalSubmit, setIsFinalSubmit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [deleteAttachment, setDeleteAttachment] = useState<any>({
    attachmentId: "",
    attachmentPath: "",
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [filingMaster, setFilingMaster] = useState<FilingMaster>(initForm);
  const navigate = useNavigate();

  const backToHome = () => {
    setRemainingChars(2000);
    setWherToRemainingChars(500);
    if (selectedFilingMaster && selectedFilingMaster.hasOwnProperty("from")) {
      navigate(
        `/client-filing-tracker-list/${selectedFilingMaster.customerId}`
      );
    } else {
      navigate("/postaward-filing-master-list");
    }
  };

  const fetchNotificationCount = async () => {
    // console.log("CustomerNotification", getCustomerNotifications);
    // console.log("filingNotifications", getFilingNotifications);

    if (
      getCustomerNotifications &&
      getFilingNotifications &&
      getCustomerNotificationsWorkFlow
    ) {
      // console.log("@@Fetch Customers2:");
      try {
        let custList = await getCustomerNotifications();
        custList = custList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        // console.log("@@customerNotifications:", custList);
        // setTotalNotificationCount((old) => old + custList.length);

        setCustomerNotificationCount(custList.length);
        let filingList = await getFilingNotifications();
        filingList = filingList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        // console.log("@@filingNotifications:", filingList);
        // setTotalNotificationCount((old) => old + filingList.length);
        setFilingNotificationCount(filingList.length);
        let custListWorkflow = await getCustomerNotificationsWorkFlow();
        custListWorkflow = custListWorkflow
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");
        // console.log("@@filingNotifications:", custListWorkflow);

        setCustomerNotificationsWorkFlow(custListWorkflow.length);
        // setTotalNotificationCount(
        //   (old) =>
        //     custList?.length + filingList?.length + custListWorkflow?.length
        // );
        const count =
          custList?.length + filingList?.length + custListWorkflow?.length;
        if (setNotificationCount) {
          setNotificationCount(count);
        }
      } catch (error) {
        // console.log("@@Error:", error);
      }
    }
  };

  const submitForm = async () =>
  // event: React.MouseEvent<HTMLElement>
  {
    try {
      // console.log(
      //   "submitForm filingPeriodTable",
      //   selectedCategories,
      //   filingPeriodVal,
      //   filingPeriodTable
      // );
      if (createClientDraftFilingMaster) {
        // if (
        //   !selectedCategories.length &&
        //   !(
        //     selectedFilingMaster &&
        //     selectedFilingMaster.hasOwnProperty("from")
        //   )
        // ) {
        //   return null;
        // }
        if (filingPeriodVal === "") {
          setFilingPeriodValError("Filing Period is required");
          return null;
        } else {
          setFilingPeriodValError("");
        }
        // if (filingPeriodTable.length) {
        //   console.log("submitForm filingPeriodTable", filingPeriodTable);
        //   console.log("FilingFrequencyVal", formik.values.filingFrequency);
        //   const noDueDate = filingPeriodTable.every((obj) =>
        //     obj.hasOwnProperty("dueDate")
        //   );
        //   console.log("submitForm noDueDate", noDueDate);
        //   if (!noDueDate && formik.values.filingFrequency !== "Conditional") {
        //     setFilingPeriodTableError(
        //       "Due Dates for all Filing Period is required"
        //     );
        //     return null;
        //   }
        // }
        let getDraft = await getDraftId();
        // console.log("submitForm filingPeriodTable", getDraft);
        const _filing: any = {
          ...formik.values,
          stateInfo:
            formik.values.stateInfo === "" ? null : formik.values.stateInfo,
          required: filingMaster.required,
        };
        if (filingMaster.businessCategory)
          delete filingMaster.businessCategory;
        _filing.createDate = new Date();
        _filing.createUser = authUser?.userId + "";
        _filing.status = "Pending";
        if (selectedFilingMaster && selectedFilingMaster !== null) {
          if (
            selectedFilingMaster &&
            selectedFilingMaster.hasOwnProperty("from") &&
            selectedFilingMaster.from === "Edit client filing"
          ) {
            _filing.draftId = getDraft;
            _filing.businessOperation = "edit";
            _filing.filingId = selectedFilingMaster.filingId;
          } else if (
            selectedFilingMaster &&
            selectedFilingMaster.hasOwnProperty("from") &&
            selectedFilingMaster.from === "Add client filing"
          ) {
            _filing.draftId = getDraft;
            _filing.businessOperation = "add";
          } else {
            _filing.businessOperation = "edit";
            _filing.draftId = getDraft;
            _filing.filingId = selectedFilingMaster.filingId;
          }
        } else {
          _filing.draftId = getDraft;
          _filing.businessOperation = "add";
        }
        if (
          selectedFilingMaster &&
          selectedFilingMaster.hasOwnProperty("from")
        ) {
          _filing.IsFiling = true;
        } else {
          _filing.IsFiling = false;
        }
        setIsLoading(true);
        _filing.recordType = "PostAward";
        _filing.programId =
          formik.values.programName === "" ? null : formik.values.programName;
        _filing.awardTypeId =
          formik.values.awardType === "" ? null : formik.values.awardType;
        _filing.awardingAgencyId =
          formik.values.awardingAgency === ""
            ? null
            : formik.values.awardingAgency;
        const draftUpdatePayload = [_filing]
        const res = await createClientDraftFilingMaster(draftUpdatePayload);
        // console.log(
        //   `Submitting Add FilingMaster: [${JSON.stringify(filingMaster)}]`
        // );

        if (res) {
          // console.log("Final Cats:", selectedCategories);
          // console.log("Final Cats:", formik.values);
          // let payload = null;
          // if (_filing.businessOperation === "add") {
          //   payload = selectedCategories.map((item) => ({
          //     draftId: getDraft,
          //     businessCategoryId: item,
          //     businessOperation: "add",
          //     state:
          //       formik.values.stateInfo === ""
          //         ? null
          //         : formik.values.stateInfo,
          //   }));
          // } else if (_filing.businessOperation === "edit") {
          //   let _delCats = _.difference(initCategories, selectedCategories);
          //   let _addCats = _.difference(selectedCategories, initCategories);
          //   const _delCatsPL = _delCats.map((item) => ({
          //     draftId: getDraft,
          //     businessCategoryId: item,
          //     businessOperation: "delete",
          //     state:
          //       formik.values.stateInfo === ""
          //         ? null
          //         : formik.values.stateInfo,
          //   }));
          //   const _addCatsPL = _addCats.map((item) => ({
          //     draftId: getDraft,
          //     businessCategoryId: item,
          //     businessOperation: "add",
          //     state:
          //       formik.values.stateInfo === ""
          //         ? null
          //         : formik.values.stateInfo,
          //   }));
          //   payload = [..._addCatsPL, ..._delCatsPL];
          // //   console.log("_delCats payload", payload);
          // }

          setFilingMaster({
            ...filingMaster,
            draftId: getDraft ? getDraft : 0,
            filingId: res.filingId ? res.filingId : 0,
          });

          // let res1 = null;

          // if (createFilingMasterDraftCategories) {
          //   res1 = await createFilingMasterDraftCategories(payload);
          // }

          // if (res1) {
          let wfPayload: object | any[];
          // console.log("submitForm Filing", _filing, "selectedAffectedCompanies", selectedAffectedCompanies, "formik.values", formik.values, "selectedFilingMaster", selectedFilingMaster, "newlyAddingAffectedCompanies", newlyAddingAffectedCompanies);

          if (selectedFilingMaster &&
            selectedFilingMaster.hasOwnProperty("from") &&
            (selectedFilingMaster.from === "Add client filing" || selectedFilingMaster.from === "Edit client filing")) {
            if (selectedFilingMaster.from === "Add client filing") {
              // const custList = selectedFilingMaster ? [Number(selectedFilingMaster.customerId), ...selectedAffectedCompanies, ...newlyAddingAffectedCompanies] : []
              let custList = [];

              if (selectedCustomer?.bothParentAndChild) {
                custList = selectedFilingMaster ?
                  [...selectedAffectedCompanies, ...newlyAddingAffectedCompanies] :
                  [];
              } else {
                custList = selectedFilingMaster ?
                  [Number(selectedFilingMaster.customerId)] :
                  [];
              }
              // console.log("submitForm", "custList", custList);
              wfPayload = custList.map((item) => ({
                draftId: getDraft ? getDraft : 0,
                customerId: item,
                WorkflowInitiatorId: authUser?.userId + '',
                createDate: new Date(),
                createUser: authUser?.userId + '',
              }))
            } else {
              const customerIds = (customerList as { customerId: number | any }[]).map(custId => Number(custId.customerId));//Extracting customerIds alone from customerList
              let custList = []
              if (selectedCustomer?.bothParentAndChild) {
                custList = selectedFilingMaster ?
                  [...customerIds] :
                  [];
              } else {
                custList = selectedFilingMaster ?
                  [Number(selectedFilingMaster.customerId)] :
                  [];
              }
              wfPayload = custList.map((item) => ({
                draftId: getDraft ? getDraft : 0,
                customerId: item,
                WorkflowInitiatorId: authUser?.userId + '',
                createDate: new Date(),
                createUser: authUser?.userId + '',
              }))
            }
          } else {
            wfPayload = [{
              draftId: getDraft ? getDraft : 0,
              WorkflowInitiatorId: authUser?.userId + '',
              createDate: new Date(),
              createUser: authUser?.userId + '',
              customerId: null
              // currentApproverId: 1,
            }]
          }

          // console.log("Create Draft Due Dates", filingPeriodTable);
          let res4 = await createDraftDueDates(getDraft);
          // console.log("Create Draft Due Dates res", res4);

          let res3 = null;
          if (GeneratePostAwardMasterWorkflowForClient) {
            res3 = await GeneratePostAwardMasterWorkflowForClient(wfPayload);
          }
          // console.log("createPostAwardWorkflow", res3);
          if (res3) {
            localStorage.removeItem("DraftId");
            onFinalSubmit();
            setFilingPeriodTableError(() => "");
            setDraftSubmitted(true);
            setRemainingChars(2000);
            setWherToRemainingChars(500);

            if (!(selectedFilingMaster &&
              selectedFilingMaster.hasOwnProperty('from') &&
              (selectedFilingMaster.from === 'Edit client filing' || selectedFilingMaster.from === 'Add client filing'))) {
              await fetchNotificationCount();
            }

            setIsLoading(false);

            return;
          }
        }
      } else {
        setAlertMessage(
          `Unexpected Error occured while submitting Filing Master`
        );
        setShowAlert(true);
        setRemainingChars(2000);
        setWherToRemainingChars(500);
      }
      // }
    } catch (error: any) {
      // console.log(
      //   `Error code:${error?.errorCode}, Error Message: ${error?.errorMessage}`
      // );
      if (error?.errorMessage === "Duplicate Filing found in filingmaster") {
        localStorage.removeItem("DraftId");
        getDraftId();
      }
      setAlertMessage(`${error?.errorMessage}`);
      setShowAlert(true);
    }
    setIsLoading(false);
  };

  const closeAlert = () => {
    setShowAlert(false);
    if (isFinalSubmit) {
      if (selectedFilingMaster?.from === "Add client filing" || selectedFilingMaster?.from === "Edit client filing") {
        navigate(
          `/client-filing-tracker-list/${selectedFilingMaster.customerId}`
        );
      } else {
        navigate("/postaward-filing-master-list");
      }
    }
  };

  const handleCategoryChanges = (
    values: string[],
    keys: any,
    action: string
  ) => {
    // console.log("@@Changed BusinessCategories:", values.length);
    setSelectedCategories((old) => values);
    // formik.validateField("businessCategoryId");
    // formik.validate();
    // if (values.length > 0) setCategoriesErrorText("");
    // else setCategoriesErrorText("Business Category is required");
    // validateCategories(formik.values);
    setIsCategoryChanged(() => true);
    // validateCategories(values, true);
  };

  const fetchFilingMasterAttachments = async (filingId: any) => {
    // console.log("fetchFilingMasterAttachments props", filingId);
    try {
      const attachmentList = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachments/${filingId}`
      );
      setAttachments(() => attachmentList.data.data);
      setAttachmentChange(() => attachmentList.data.data);
      // console.log("fetchFilingMasterAttachments res", attachmentList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const fetchBusinessCategories = async () => {
    // console.log("@@Fetch BusinessCategories:", getBusinessCategories);
    if (getBusinessCategories) {
      // console.log("@@Fetch BusinessCategories:");
      try {
        let catList = await getBusinessCategories();
        catList.sort((a, b) => (a.businessCategoryName ?? '').localeCompare(b.businessCategoryName ?? ''));
        // console.log("@@BusinessCategories:", catList);
        setCategories(() => catList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const fetchAwardingAgencies = async () => {
    // console.log("@@Fetch AwardingAgencies:", getAwardingAgencies);
    if (getAwardingAgencies) {
      // console.log("@@Fetch AwardingAgencies:");
      try {
        const catList = await getAwardingAgencies();
        // console.log("@@AwardingAgencies:", catList);
        setAwardingAgencies(() => catList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const downloadAttachment = async (attachment: any) => {
    // console.log("downloadAttachment", attachment);
    await axios
      .get(
        `${API_BASE_URL}/Application/DownloadBlob?FileUrl=${attachment.attachmentPath}`
      )
      .then((res) => {
        // console.log("downloadAttachment res", res.data);
        if (res) {
        }
      });
  };
  // console.log("seletedFilingMaster #Check", selectedFilingMaster);
  const getFileAttachment = async () => {
    // console.log("getFileAttachment-->", selectedFilingMaster);
    await axios
      .get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachmentsGetById/${selectedFilingMaster?.filingId}`
      )
      .then((res) => {
        // console.log("getFileAttachment res", res.data.data);
        if (res) {
          setFilingAttachment(res.data.data);
          // console.log("getFileAttachment filingAttachment", filingAttachment);
          setAttachments(() => res.data.data);
        }
      });
  };

  const getFilename = function (str: string) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };

  const downloadFile = (attachmentPath: any, attachmentId: any) => {
    // console.log("Downloading File from:", attachmentId);
    const fileName = getFilename(attachmentPath);
    // console.log("Downloading File:", fileName);

    let filingId = filingMaster?.filingId
      ? filingMaster?.filingId + ""
      : selectedFilingMaster?.filingId
        ? selectedFilingMaster?.filingId + ""
        : "0";
    axios({
      url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&FilingId=${filingId}`,
      method: "POST",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  };

  const deleteFileAttachment = async () => {
    // console.log("deleteFileAttachment", deleteAttachment);
    const removeExisting = attachments.findIndex(
      (item) => item.attachmentId === deleteAttachment.attachmentId
    );
    let filingId = filingMaster?.filingId
      ? filingMaster?.filingId + ""
      : selectedFilingMaster?.filingId
        ? selectedFilingMaster?.filingId + ""
        : "0";
    let getDraft = await getDraftId();
    // console.log("deleteFileAttachment removeExisting", removeExisting);
    if (removeExisting >= 0) {
      await axios
        .post(
          `${API_BASE_URL}/Application/DeleteAttachment?filingId=${filingId}&AttachmentId=${deleteAttachment.attachmentId}&DraftId=${getDraft}`
        )
        .then((res) => {
          // console.log("deleteFileAttachment removeExisting res", res);
          if (res) {
            // console.log("Exisitng deleteFileAttachment", removeExisting);
            const tmpFiles = _.cloneDeep(attachments);
            // console.log("Post deleteFileAttachment", removeExisting);
            tmpFiles.splice(removeExisting, 1);
            // console.log("Post deleteFileAttachment", tmpFiles);
            setAttachments(tmpFiles);
          }
        });
    } else {
      await axios
        .post(
          `${API_BASE_URL}/Application/DeleteAttachment?filingId=${filingId}&AttachmentId=${deleteAttachment.attachmentId}&DraftId=${getDraft}`
        )
        .then((res) => {
          // console.log("deleteFileAttachment res", res);
          if (res) {
            const tmpFiles = _.cloneDeep(files);
            const removeFile = tmpFiles.findIndex(
              (item) => item.attachmentId === deleteAttachment.attachmentId
            );
            // console.log("Post deleteFileAttachment", removeFile);
            tmpFiles.splice(removeFile, 1);
            // console.log("Post deleteFileAttachment", tmpFiles);
            setFiles(tmpFiles);
          }
        });
    }
    setShowDeleteAlert(false);
  };

  const deleteAttachmentBlob = async () => {
    // console.log("deleteAttachmentBlob", deleteAttachment);
    if (deleteAttachment.type === "attachment") {
      deleteExistingAttachment();
    } else {
      await axios
        .delete(
          `${API_BASE_URL}/Application/DeleteFileurl?url=${deleteAttachment?.attachmentPath}`
        )
        .then((res) => {
          // console.log("deleteAttachmentBlob res", res.data);
          if (res) {
            deleteFileAttachment();
          }
        });
    }
  };

  const deleteExistingAttachment = async () => {
    // console.log("deleteExistingAttachment", filingMaster, deleteAttachment);
    let payload = {
      draftId: filingMaster?.draftId ? filingMaster?.draftId + "" : "0",
      businessOperation: "delete",
      attachmentPath: deleteAttachment?.attachmentPath,
    };
    const res = await axios.post(
      `${API_BASE_URL}/FilingMaster/CreateFilingMasterDraftAttachments`,
      payload
    );
    // console.log("deleteExistingAttachment res", res);
    if (res.data) {
      setShowDeleteAlert(false);
      return res.data;
    }
  };

  const handleAttachmentDelete = async (
    attachmentId: any,
    attachmentPath: any,
    type: any
  ) => {
    // console.log(
    //   "handleAttachmentDelete",
    //   type,
    //   attachmentId,
    //   attachmentPath,
    //   attachments
    // );
    setDeleteAttachment({
      attachmentId: attachmentId,
      attachmentPath: attachmentPath,
      type: type,
    });
    setShowDeleteAlert(true);
  };

  const closeDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  const footerContentDelete = (
    <div>
      <Button
        label='No'
        icon='pi pi-check'
        onClick={() => closeDeleteAlert()}
        autoFocus
      />
      <Button
        style={{ backgroundColor: "0b3edf" }}
        label='Yes'
        icon='pi pi-check'
        onClick={() => deleteFileAttachment()}
        autoFocus
      />
    </div>
  );

  const fetchProgramName = async () => {
    // console.log("@@Fetch ProgramNames:", getProgramName);
    if (getProgramName) {
      // console.log("@@Fetch ProgramNames:");
      try {
        let progList = await getProgramName();
        progList = progList.sort((a, b) => (a.programName ?? "").localeCompare(b.programName ?? ""))

        // console.log("@@ProgramNames:", progList);
        setProgramList(() => progList);
      } catch (error) {
        // console.log("@@Error:", error);
      }
    }
  };

  useEffect(() => {
    // toggleDrawer(props.dockAt, props.show);
    fetchBusinessCategories();
    fetchFilingPeriod();
    fetchProgramName();
    fetchAwardingAgencies();
    setAwardTypeList([
      { id: 1, name: "Direct Federal Award" },
      { id: 2, name: "Direct State Award" },
      { id: 3, name: "Pass Through Award" },
    ]);
    //eslint-disable-next-line
    // console.log("&filingMasters-->", selectedFilingMaster);

    if (selectedFilingMaster !== null) {
      if (
        selectedFilingMaster &&
        selectedFilingMaster.recordType &&
        selectedFilingMaster.recordType === "TrackNow"
      ) {
        // console.log("setFormTitleAdd")
        if (setCurrentFilingMaster) setCurrentFilingMaster(null);
      } else if (selectedFilingMaster && !selectedFilingMaster.filingName) {
        // console.log("setFormTitle Client")
        // const newFiling = {
        //   client: selectedFilingMaster && selectedFilingMaster.client,
        //   programName: "",
        //   customerId: selectedFilingMaster && selectedFilingMaster.customerId

        // };
        // formik.setValues(newFiling);
        setFormTitle(selectedFilingMaster?.from?.toUpperCase());
      } else {
        // getFileAttachment();
        // console.log("setFormTitleEdit", selectedFilingMaster)
        getFilingPeriod();
        selectedFilingMaster && selectedFilingMaster?.from === "Edit client filing"
          ? setFormTitle("EDIT CLIENT FILING")
          : setFormTitle('EDIT  POST AWARD FILING MASTER RECORD');
        setActionLabel("SAVE");
        let requiredField: boolean | undefined = false
        if (selectedFilingMaster) {
          if ('filingRequired' in selectedFilingMaster) {
            requiredField = selectedFilingMaster.filingRequired !== undefined ? !!selectedFilingMaster.filingRequired : false;
          } else {
            requiredField = selectedFilingMaster.required !== undefined ? !!selectedFilingMaster.required : false;
          }
        }
        const newFiling = {
          filingName:
            selectedFilingMaster && selectedFilingMaster.filingName
              ? selectedFilingMaster.filingName
              : "",
          filingFrequency:
            selectedFilingMaster && selectedFilingMaster.filingFrequency
              ? selectedFilingMaster.filingFrequency
              : "",
          dueDayofFrequency:
            selectedFilingMaster && selectedFilingMaster.dueDayofFrequency
              ? selectedFilingMaster.dueDayofFrequency
              : 0,
          stateInfo:
            selectedFilingMaster && selectedFilingMaster.stateInfo
              ? selectedFilingMaster.stateInfo
              : "",
          ruleInfo:
            selectedFilingMaster && selectedFilingMaster.ruleInfo
              ? selectedFilingMaster.ruleInfo
              : "",
          juristiction:
            selectedFilingMaster && selectedFilingMaster.juristiction
              ? selectedFilingMaster.juristiction
              : "",
          awardType:
            selectedFilingMaster &&
              selectedFilingMaster.awardType &&
              selectedFilingMaster.awardType.length > 0 &&
              selectedFilingMaster.awardType[0].awardTypeId
              ? selectedFilingMaster.awardType[0].awardTypeId
              : "",
          awardingAgency:
            selectedFilingMaster &&
              selectedFilingMaster.awardingAgency &&
              selectedFilingMaster.awardingAgency.length > 0 &&
              selectedFilingMaster.awardingAgency[0].awardingAgencyId
              ? selectedFilingMaster.awardingAgency[0].awardingAgencyId
              : "",
          programName:
            selectedFilingMaster && selectedFilingMaster.client
              ? selectedFilingMaster && selectedFilingMaster.programInfo &&
                selectedFilingMaster.programInfo.length > 0 &&
                selectedFilingMaster.programInfo[0].programId
                ? selectedFilingMaster.programInfo[0].programId + ""
                : ""
              : selectedFilingMaster && selectedFilingMaster.programId
                ? selectedFilingMaster.programId + ""
                : ""
          ,


          // programName:
          // selectedFilingMaster &&
          // selectedFilingMaster.programInfo &&
          // selectedFilingMaster.programInfo.length > 0 &&
          // selectedFilingMaster.programInfo[0].programId
          //   ? selectedFilingMaster.programInfo[0].programId + ""
          //   : "",

          jsicontactName:
            selectedFilingMaster && selectedFilingMaster.jsicontactName
              ? selectedFilingMaster.jsicontactName
              : "",
          required: requiredField,
          notes:
            selectedFilingMaster && selectedFilingMaster.notes
              ? selectedFilingMaster.notes
              : "",
          whereToFile:
            selectedFilingMaster && selectedFilingMaster.whereToFile
              ? selectedFilingMaster.whereToFile
              : "",
          changesInprogress:
            selectedFilingMaster && selectedFilingMaster.changesInprogress
              ? selectedFilingMaster.changesInprogress
              : true,
          businessCatergoryId: 0,
          // client: selectedFilingMaster && selectedFilingMaster.client,
        };
        // if(  selectedFilingMaster && selectedFilingMaster.client){
        //   newFiling.programName =
        // }
        setSwitchChecked(newFiling.required);
        // console.log(
        //   "Filing Master Bus Cats:",
        //   selectedFilingMaster?.businessCategory
        // );
        if (
          selectedFilingMaster?.businessCategory &&
          selectedFilingMaster?.businessCategory.length > 0
        ) {
          const _selectedCategoryIds =
            selectedFilingMaster?.businessCategory.map(
              (item) => item.businessCategoryId + ""
            );
          // console.log("Selected Cat IDs:", _selectedCategoryIds);
          setInitCategories(() => _selectedCategoryIds);
          setSelectedCategories((old) => _selectedCategoryIds);
        }
        fetchFilingMasterAttachments(selectedFilingMaster?.filingId);
        // console.log("filingMasters-->", newFiling);
        formik.setValues(newFiling);
        //validateCategories(selectedCategories, true);
      }
    }
    return () => {
      if (setCurrentFilingMaster) setCurrentFilingMaster(null);
    };
  }, []);

  useEffect(() => {
    // toggleDrawer(props.dockAt, props.show);
    // console.log("SWTICH::", switchChecked ? "ON" : "OFF");
    setFilingMaster((filingMaster) => ({
      ...filingMaster,
      required: switchChecked,
    }));
    //eslint-disable-next-line
  }, [switchChecked]);

  useEffect(() => {
    // toggleDrawer(props.dockAt, props.show);
    // if (selectedCategories && selectedCategories.length > 0)
    //  validateCategories(selectedCategories, true);
    //eslint-disable-next-line
  }, [selectedCategories]);

  // console.log("test_selectedCategories", selectedCategories);

  const footerContent = (
    <div>
      <Button
        label='OK'
        icon='pi pi-check'
        onClick={() => closeAlert()}
        autoFocus
        style={{ backgroundColor: "0b3edf" }}
      />
    </div>
  );

  // const validateCategories = (values: any, isDirect?: boolean) => {
  //   let error: any = {};
  //   // console.log("IsChanged", isCategoryChanged);
  //   // console.log("selectMster", selectedFilingMaster);
  //   // console.log("CheckVals", values);
  //   // console.log("CheckStateVals", selectedCategories);
  //   // console.log("IsValidating", formik.isValidating);
  //   // console.log("IsSubmitting", formik.isSubmitting);
  //   if (
  //     !(selectedFilingMaster && selectedFilingMaster.hasOwnProperty("from"))
  //   ) {
  //     if (isCategoryChanged || selectedFilingMaster || isDirect) {
  //       if (isDirect) {
  //         if (values.length <= 0) {
  //           setCategoriesErrorText("Business Category is required");
  //           error.businessCategoryId = "please select a category";
  //         } else {
  //           setCategoriesErrorText("");
  //         }
  //       } else {
  //         if (selectedCategories.length <= 0) {
  //           setCategoriesErrorText("Business Category is required");
  //           error.businessCategoryId = "please select a category";
  //         } else {
  //           setCategoriesErrorText("");
  //         }
  //       }
  //     }
  //   } else {
  //     setCategoriesErrorText("");
  //   }

  //   return error;
  // };

  const formik = useFormik({
    initialValues: initForm,
    validationSchema: validationSchema,
    // validate: validateCategories,
    validateOnBlur: true,
    validateOnMount: false,

    onSubmit: (values) => {
      submitForm();
    },
  });

  const createDraftDueDates = async (draftId: any) => {
    // console.log("@@Fetch createDraftDueDates", filingPeriodTable, draftId);
    let payload: any[] = [];
    if (
      selectedFilingMaster === null ||
      (selectedFilingMaster &&
        selectedFilingMaster.hasOwnProperty("from") &&
        selectedFilingMaster.from === "Add client filing")
    ) {
      payload = filingPeriodTable.map((ele) => {
        let d = new Date(ele.dueDate);
        let date = new Date(
          Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
        );
        // let customerId =
        //   selectedFilingMaster &&
        //     selectedFilingMaster.hasOwnProperty("customerId")
        //     ? selectedFilingMaster.customerId
        //     : null;
        // console.log("@@Fetch createDraftDueDates date", d, date, customerId);
        return {
          draftId,
          startDate: ele.startDate,
          endDate: ele.endDate,
          dueDate: new Date(
            Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
          ),
          businessOperation: "add",
          // customerId,
        };
      });
    } else {
      filingPeriodTable.forEach((ele) => {
        // console.log(
        //   "@@Fetch createDraftDueDates ele",
        //   ele,
        //   draftId,
        //   ele.dueDate !== ele.initialDueDate
        // );
        let d = new Date(ele.dueDate);
        // let customerId =
        //   selectedFilingMaster &&
        //     selectedFilingMaster.hasOwnProperty("customerId")
        //     ? selectedFilingMaster.customerId
        //     : null;
        // console.log("@@Fetch createDraftDueDates date", d, customerId);
        if (ele.dueDate !== ele.initialDueDate) {
          payload.push({
            draftId,
            startDate: ele.startDate,
            endDate: ele.endDate,
            dueDate: new Date(
              Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
            ),
            businessOperation: "edit",
            // customerId,
          });
        }
      });
    }
    // console.log("@@Fetch createDraftDueDates payload", payload);
    try {
      const filingPeriodList = await axios.post(
        `${API_BASE_URL}/FilingMaster/CreateFilingMasterDraftPeriods`,
        payload
      );
      // console.log("createDraftDueDates res", filingPeriodList.data);
      // setFilingPeriod(() => [filingPeriodList.data, filingPeriodList.data + 1]);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };


  const fetchFilingPeriod = async () => {
    // console.log("@@Fetch fetchFilingPeriod");
    try {
      const filingPeriodList = await axios.get(
        `${API_BASE_URL}/Customer/GetConfigValue?ConfigItem=CurrentYear`
      );
      // console.log("fetchFilingPeriod res", filingPeriodList.data);
      setFilingPeriod(() => [filingPeriodList.data, filingPeriodList.data + 1]);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const getFilingPeriodTable = async (
    filingFrequency: any,
    filingPeriod: any
  ) => {
    // console.log("@@Fetch getFilingPeriodTable", filingFrequency, filingPeriod);
    try {
      const filingPeriodData = await axios.get(
        `${API_BASE_URL}/Application/FilingFrequencyGenerator?FilingFrequency=${filingFrequency}&Year=${filingPeriod}`
      );
      // console.log("getFilingPeriodTable res", filingPeriodData.data.data);
      let tableData = filingPeriodData.data.data.map((ele: any) => {
        let fp = `${toLocalTime(ele.startDate)} - ${toLocalTime(ele.endDate)}`;
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
        };
      });
      // console.log("getFilingPeriodTable tableData", tableData);
      setFilingPeriodTable(tableData);
    } catch (error) {
      // console.log("@@Error:", error);
    }
  };


  const getFilingPeriod = async () => {
    // console.log("@@Fetch getFilingPeriod", selectedFilingMaster?.filingId);
    // console.log("@@Fetch selectedFilingMaster", selectedFilingMaster);
    try {
      const filingPeriodCurrent = await axios.get(
        `${API_BASE_URL}/Customer/GetConfigValue?ConfigItem=CurrentYear`
      );
      const currentYear = filingPeriodCurrent.data
      // console.log("filingPeriodCurrent", filingPeriodCurrent, "currentYear", currentYear);

      const filingPeriod = await axios.get(
        `${API_BASE_URL}/FilingMaster/ListFilingMasterPeriods/${selectedFilingMaster?.filingId}`
      );
      // console.log("getFilingPeriod res", filingPeriod.data.data);
      let tableData = filingPeriod.data.data.map((ele: any) => {
        let fp = `${toLocalTime(ele.startDate)} - ${toLocalTime(ele.endDate)}`;
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
          dueDate: toLocalTime(ele.dueDate),
          initialDueDate: toLocalTime(ele.dueDate),
        };
      });
      // console.log("getFilingPeriod tableData", tableData);
      setFilingPeriodTable(tableData);
      // const d = new Date(filingPeriod.data.data[0]?.startDate);
      // let year = d.getFullYear();
      const latestYear: any = filingPeriod.data.data.reduce((latest: any, ele: any) => {
        const year: any = new Date(ele.startDate).getFullYear();
        return year > latest ? year : latest;
      }, 0);
      setIsInitalFilingVal(true);
      // console.log("getFilingPeriod", filingPeriod.data.data[0]?.startDate);
      const finalYear = latestYear ? latestYear : currentYear;
      // console.log("finalYear", finalYear, "latestYear", latestYear);
      setFilingPeriodVal(finalYear);
    } catch (error) {
      // console.log("@@Error:", error);
    }
  };
  useEffect(() => {
    // console.log(
    //   "getFilingPeriodTable",
    //   formik.values.filingFrequency,
    //   filingPeriodVal
    // );
    if (formik.values.filingFrequency !== "" && filingPeriodVal !== "") {
      if (!isInitalFilingVal) {
        getFilingPeriodTable(formik.values.filingFrequency, filingPeriodVal);
      } else {
        setIsInitalFilingVal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.filingFrequency, filingPeriodVal]);

  const cellEditor = (options: any) => {
    let date = options.value ? new Date(options.value) : new Date();
    // console.log("cellEditor", date, options);
    return (
      <Calendar
        value={date}
        onChange={(e) => {
          //changeFilingPeriodTable()
          options.editorCallback(e.target.value);
        }}
        dateFormat='mm/dd/yy'
        placeholder='mm/dd/yyyy'
        mask='99/99/9999'
        readOnlyInput
      />
    );
  };

  const onCellEditComplete = (e: any) => {
    let { rowData, newValue, field, originalEvent: event, rowIndex } = e;
    event.preventDefault();
    // console.log("onCellEditComplete", rowData, field, newValue, e);
    // console.log("onCellEditComplete", typeof newValue);

    if (newValue && typeof newValue === "object") {
      //rowData[field] = newValue;
      // console.log(
      //   "onCellEditComplete",
      //   newValue && newValue.toJSON().slice(0, 10)
      // );
      let newTableData = filingPeriodTable.map((ele, index) => {
        if (index === rowIndex) {
          ele[field] = toLocalTime(newValue.toJSON());
        }
        return ele;
      });
      // console.log("onCellEditComplete", newTableData);
      setFilingPeriodTable(newTableData);
    } else event.preventDefault();
  };

  const onFileChange = async (event: any) => {
    const file = event.target.files[0];
    // setFiles([...files,file])
    setSelectedFile(file);
  };

  const updateFilePath = async (
    attachmentId: any,
    filePath: any,
    getDraft: any
  ) => {
    // console.log("updateFilePath", attachmentId, filePath);
    // let customerId =
    //   selectedFilingMaster && selectedFilingMaster.hasOwnProperty("customerId")
    //     ? selectedFilingMaster.customerId
    //     : null;
    let payload = {
      attachmentId: attachmentId,
      attachmentPath: decodeURI(filePath),
      businessOperation: "add",
      updatedDate: new Date(),
      updatedUser: authUser?.userId + "",
      filingId: filingMaster?.filingId ? filingMaster?.filingId + "" : "0",
      draftId: getDraft ? getDraft + "" : "0",
      // customerId,
    };

    const res = await axios.put(
      `${API_BASE_URL}/FilingMaster/UpdateFilingMasterDraftAttachments/${attachmentId}`,
      payload
    );
    // console.log("updateFilePath res", res);
    if (res.data) {
    }
  };

  const fileUploadUtil = async (attachmentId: any, getDraft: any) => {
    // console.log("fileUploadUtil params", attachmentId, getDraft, filingMaster);
    const formData = new FormData();
    formData.append("Files", selectedFile);
    formData.append("draftId", getDraft ? getDraft + "" : "0");
    formData.append(
      "filingId",
      filingMaster?.filingId ? filingMaster?.filingId + "" : "0"
    );

    const res = await axios.post(
      `${API_BASE_URL}/Application/FilingmasterUpload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log("fileUploadUtil res", res);

    if (res.data) {
      // console.log("fileUploadUtil res header", res.headers, selectedFile);
      await updateFilePath(attachmentId, res.headers["x-file-url"], getDraft);
      setFiles([
        ...files,
        {
          name: selectedFile.name,
          attachmentId: attachmentId,
          attachmentPath: res.headers["x-file-url"],
        },
      ]);
    }
  };

  const getAttachmentId = async (getDraft: any) => {
    // console.log("getAttachmentIt", filingMaster, draftId, getDraft);
    let payload = {
      draftId: getDraft ? getDraft + "" : "0",
      businessOperation: "add",
    };

    const res = await axios.post(
      `${API_BASE_URL}/FilingMaster/CreateFilingMasterDraftAttachments`,
      payload
    );
    // console.log("getAttachmentIt res", res);
    if (res.data) {
      return res.data.data.attachmentId;
    }
  };

  const uploadandUpdateAttachment = async (getDraft: any) => {
    // console.log(
    //   "uploadandUpdateAttachment",
    //   selectedFilingMaster,
    //   filingMaster,
    //   draftId
    // );
    setIsAttachmentLoading(true)
    try {
      const formData = new FormData();
      formData.append("Files", selectedFile);
      formData.append("draftId", getDraft ? getDraft + "" : "0");
      formData.append(
        "filingId",
        filingMaster?.filingId
          ? filingMaster?.filingId + ""
          : selectedFilingMaster?.filingId
            ? selectedFilingMaster?.filingId + ""
            : "0"
      );
      formData.append("createUser", authUser?.userId + "");
      const res = await axios.post(
        `${API_BASE_URL}/Application/UploadandUpdateAttachment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(
      //   "uploadandUpdateAttachment res",
      //   res,
      //   res.headers["x-file-url"]
      // );
      if (res.data) {
        // console.log("uploadandUpdateAttachment file", res.headers["x-file-url"]);
        setFiles([
          ...files,
          {
            name: selectedFile.name,
            attachmentId: res.data.data[0].attachmentId,
            attachmentPath: res.headers["x-file-url"],
          },
        ]);
      }
    } finally {
      setIsAttachmentLoading(false)

    }
  };

  // console.log("uploadandUpdateAttachment file", files);

  const onFileUpload = async (event: any) => {
    if (!selectedFile) {
      setAttachmentError(true);
    } else {
      //TO_DO
      //check if file is selected
      //call upload

      let getDraft = await getDraftId();
      uploadandUpdateAttachment(getDraft);
      // let attachmentId = await getAttachmentId(getDraft);
      // if (attachmentId) {
      //   setAttachmentError(false);
      // }
      // await fileUploadUtil(attachmentId, getDraft);
      const form = document.getElementById("uploadForm") as HTMLFormElement;
      form.reset();
      setSelectedFile("");
    }

  };
  // console.log("selectedFiles2", selectedFile);

  const getDraftId = async () => {
    let draftExists = localStorage.getItem("DraftId");
    // console.log("getDraftId ", draftExists);
    if (draftExists) {
      setDraftId(draftExists);
      return draftExists;
    } else {
      try {
        const res = await axios.get(
          `${API_BASE_URL}/FilingMaster/CreateFilingMasterDraftId?RecordType=PostAward&UserId=${authUser?.userId}`
        );
        // console.log("getDraftId res", res.data.data[0]);
        localStorage.setItem("DraftId", res.data.data[0].draftId);
        setDraftId(res.data.data[0].draftId);
        return res.data.data[0].draftId;
      } catch (error) {
        // console.log("@@Error getDraftId :", error);
      }
    }
  };
  const geClientDraftId = async () => {
    console.log("geClientDraftId selectedAfftCom", selectedAffectedCompanies);

    const payloadDraftIds = selectedAffectedCompanies.map((item: any) => {
      return {
        customerId: item,
        userId: authUser?.userId,
        recordType: "PostAward",
      }
    })

    if (newlyAddingAffectedCompanies.length > 0) {
      // Payload Newly adding companies through edit form 

      const payloadDraftIdsNewlyAdding = newlyAddingAffectedCompanies.map((item: any) => {
        return {
          customerId: item,
          userId: authUser?.userId,
          recordType: "PostAward",
        }
      })
      payloadDraftIds.push(...payloadDraftIdsNewlyAdding)
      console.log("geClientDraftId PAYLOAD", "payloadDraftIds", payloadDraftIds);

    }
    console.log("geClientDraftId PAYLOAD", "payloadDraftIds", payloadDraftIds);

    try {
      const res = await axios.post(
        `${API_BASE_URL}/FilingMaster/ClientCreateFilingMasterDraftId`,
        payloadDraftIds
      );
      console.log("geClientDraftId RES", res);

      return res.data.data
    } catch (error) {
      console.error("geClientDraftId error", error);

    }
  };

  const onFinalSubmit = () => {
    if (selectedFilingMaster?.from === "Add client filing") {
      setAlertMessage(
        <Typography>
          Client specific filing <strong>{formik.values.filingName}</strong> is successfully created
        </Typography>
      );
      setIsFinalSubmit(true);
      setShowAlert(true);
      setAttachmentError(false);
    }
    else if (selectedFilingMaster?.from === "Edit client filing") {
      setAlertMessage(
        <Typography>
          Client specific filing <strong>{formik.values.filingName}</strong> is successfully updated
        </Typography>
      );
      setIsFinalSubmit(true);
      setShowAlert(true);
      setAttachmentError(false);
    }
    else {
      setAlertMessage(
        <Typography>
          Successfully submitted <strong>{formik.values.filingName}</strong> workflow for approval
        </Typography>
      );
      setIsFinalSubmit(true);
      setShowAlert(true);
      setAttachmentError(false);
      // backToHome();
    }

    return;
  };

  // useEffect(() => {
  //   if (formik.values.juristiction === "Federal") {
  //     formik.setFieldValue("stateInfo", "");
  //     setAwardTypeList([
  //       { id: 1, name: "Direct Federal Award" },
  //       { id: 3, name: "Pass Through Award" },
  //     ]);
  //   } else if (formik.values.juristiction === "State") {
  //     setAwardTypeList([{ id: 2, name: "Direct State Award" }]);
  //   }else if (formik.values.juristiction === "Both") {
  //     setAwardTypeList([
  //       { id: 1, name: "Direct Federal Award" },
  //       { id: 2, name: "Direct State Award" },
  //       { id: 3, name: "Pass Through Award" },
  //     ]);
  //   }
  // }, [formik.values.juristiction, formik.setFieldValue]);

  const toLocalTime = (date: Date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yy");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const toLocalTime2 = (date: Date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd/mm/yyyy");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  // let awardAgencyList = [
  //   { id: 1, name: "Agency 1" },
  //   { id: 2, name: "Agency 2" },
  //   { id: 3, name: "Agency 3" },
  // ];

  const handleSubmitCustom = (
    e?: React.FormEvent<HTMLFormElement> | undefined
  ) => {
    // console.log("@@@ Before Submit:");
    setIsCategoryChanged(true);
    //validateCategories(selectedCategories, true);
    formik.handleSubmit(e);
  };
  useEffect(() => {
    if (authUser && authUser?.userId) {
      // fetchNotificationCount();
    }
  }, [authUser]);
  useEffect(() => {
    if (formTitle === "EDIT POST AWARD MASTER RECORD" || actionLabel === "SAVE") {
      if (selectedFilingMaster) {
        let isEqual = true;
        let isAttachmentChanges = false;
        let isFilesAdded = false;

        type FilingType = {
          filingName: string | undefined;
          ruleInfo: string | null | undefined;
          awardType: number;
          awardingAgency: number;
          programId: number | undefined;
          jsicontactName: string | undefined;
          whereToFile: string | undefined;
          notes: string | undefined;
          required: boolean | undefined;
        };
        let requiredField: boolean | undefined = false
        if (selectedFilingMaster) {
          if ('filingRequired' in selectedFilingMaster) {
            requiredField = selectedFilingMaster.filingRequired !== undefined ? !!selectedFilingMaster.filingRequired : false;
          } else {
            requiredField = selectedFilingMaster.required !== undefined ? !!selectedFilingMaster.required : false;
          }
        }
        const selectedFiling: FilingType = {
          filingName: selectedFilingMaster.filingName ? selectedFilingMaster.filingName : "",
          ruleInfo: selectedFilingMaster.ruleInfo
            ? selectedFilingMaster.ruleInfo
            : '',
          awardType: selectedFilingMaster.awardType[0]?.awardTypeId ? selectedFilingMaster.awardType[0].awardTypeId : "",
          awardingAgency: selectedFilingMaster.awardingAgency[0]?.awardingAgencyId ? selectedFilingMaster.awardingAgency[0].awardingAgencyId : "",
          programId: selectedFilingMaster.programId ? selectedFilingMaster.programId : "",
          jsicontactName: selectedFilingMaster.jsicontactName ? selectedFilingMaster.jsicontactName : "",
          whereToFile: selectedFilingMaster.whereToFile ? selectedFilingMaster.whereToFile : "",
          notes: selectedFilingMaster.notes ? selectedFilingMaster.notes : "",
          required: requiredField,
        };

        const formValues: FilingType = {
          filingName: formik.values?.filingName,
          ruleInfo: formik.values?.ruleInfo,
          awardType: formik.values?.awardType,
          awardingAgency: formik.values?.awardingAgency,
          programId: parseInt(formik.values?.programName || '0', 10),
          jsicontactName: formik.values?.jsicontactName,
          whereToFile: formik.values?.whereToFile,
          notes: formik.values?.notes,
          required: switchChecked
        };

        for (const key in selectedFiling) {
          if (selectedFiling[key as keyof FilingType] !== formValues[key as keyof FilingType]) {
            isEqual = false;
            break;
          }
        }

        for (const attachment of attachmentChange) {
          const isAttachmentPresent = attachments.some(
            (existingAttachment) => existingAttachment.attachmentId === attachment.attachmentId
          );
          if (!isAttachmentPresent) {
            isAttachmentChanges = true;
            break;
          }
        }
        if (files.length > 0) {
          isFilesAdded = true
        }
        setIsAttachmentChanges(isAttachmentChanges)
        setIsFileAdded(isFilesAdded)
        setIsEqual(isEqual)
        // console.log("selectedFiling", selectedFiling, "formValues", formValues, "isEqual", isEqual, "isAttachmentChanges", isAttachmentChanges, "isFilesAdded", isFilesAdded);
        // console.log("isEqual", isEqual);
        // console.log("ACTIONLABLECHECK", actionLabel);

      }
    }
  }, [formik.values, switchChecked, attachments, files]);


  // console.log("checkSelFilingMaster", selectedFilingMaster, "formik.values", formik.values);
  // console.log("attacjmentsCheck", attachments, "deleteattachments", deleteAttachment, "files", files);
  // console.log("actionLableCheck", actionLabel, "formTitle", formTitle, "isAttachmentChanges", isAttachmentChanges, "isFilesAdded", isFilesAdded);
  const checkDisabled = formTitle === "EDIT POST AWARD MASTER RECORD" ? (isEqual && isFilesAdded && isAttachmentChanges && !draftSubmitted) : draftSubmitted;
  // console.log("actionLableCheck", actionLabel, "isAttachmentChanges", isAttachmentChanges, "isFilesAdded", isFilesAdded, "isEqual", isEqual, "checkDisabled", checkDisabled);
  useEffect(() => {
    // if (formik && formik.values && formik.values.client) {
    // console.log("formik values", formik.values, "customerNames", customerNames, "filingMaster", filingMaster, "selectedAffectedCompanies", selectedAffectedCompanies);
    if (selectedFilingMaster &&
      selectedFilingMaster.hasOwnProperty("from") &&
      (selectedFilingMaster.from === "Add client filing" || selectedFilingMaster.from === 'Edit client filing')) {
      // const combainedCustomers: string[] = [{ customerId: formik.values.customerId, customerName: formik.values.client }, ...customerNames]
      // console.log(
      //   "combainedCustomers", combainedCustomers
      // );
      setCustomerList(customerNames)
    }
    //  else {
    //   setCustomerList(customerNames)
    // }
    // }
  }, [selectedFilingMaster])

  const handleClick = () => {
    setOpen(!open);
  };
  
  return (
    <ThemeProvider theme={theme}>
      {/* {showAlert && (
        <Alert message={alertMessage?.message} type={alertMessage?.type} />
      )} */}
      <Dialog
        header='Info'
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className='m-0'>{alertMessage}</p>
      </Dialog>
      <Dialog
        header='Delete Attachment'
        visible={showDeleteAlert}
        style={{ width: "30vw" }}
        onHide={() => closeDeleteAlert()}
        footer={footerContentDelete}
      >
        <p className='m-0'> Do you want to delete attachment?</p>
      </Dialog>
      <CssBaseline />
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        spacing={2}
        style={{ margin: "1rem", width: "99%" }}
      >
        <AppBar
          position='relative'
          color='inherit'
          sx={{ border: "none", width: "99%" }}
        >
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={2}
          >
            <IconButton
              className='nav-bar__logo__img'
              sx={{ display: { xs: "none", md: "flex" } }}
              onClick={backToHome}
            >
              <Box sx={{ m: 2 }}>
                <ArrowBackIosNewIcon fontSize='medium' sx={{ mt: 1 }} />
              </Box>
            </IconButton>
            <Box sx={{ m: 2 }}>
              <Typography variant='h6' color='inherit' noWrap>
                {formTitle}
              </Typography>
            </Box>
          </Stack>
        </AppBar>
        <Container component='main' sx={{ mb: 4, width: "99%" }}>
          <React.Fragment>
            {isLoading ? (
              <Loader />
            ) : (
              <form onSubmit={handleSubmitCustom} noValidate>
                <Grid container spacing={3} sx={{ mt: 3 }}>
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Filing Name</Typography>
                    <TextField
                      required
                      id='filingName'
                      name='filingName'
                      label='Enter Name'
                      placeholder='Max-Length 250 characters'
                      fullWidth
                      autoComplete='filingName'
                      variant='filled'
                      value={formik.values.filingName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.filingName &&
                        Boolean(formik.errors.filingName)
                      }
                      helperText={
                        formik.touched.filingName && formik.errors.filingName
                      }
                    />
                  </Grid>
                  {/* <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}> Filing Description</Typography>
                  <TextField
                    required
                    id="filingDescription"
                    name="filingDescription"
                    label="Filing Description"
                    fullWidth
                    variant="filled"
                    value={formik.values.filingDescription}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.filingDescription &&
                      Boolean(formik.errors.filingDescription)
                    }
                    helperText={
                      formik.touched.filingDescription &&
                      formik.errors.filingDescription
                    }
                  />
                </Grid> */}
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Filing Frequency</Typography>
                    <FormControl fullWidth variant='filled' required>
                      <InputLabel id='freq-label'>Filing Frequency</InputLabel>
                      <Select
                        labelId='freq-label'
                        id='filingFrequency'
                        name='filingFrequency'
                        disabled={
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            !selectedFilingMaster.hasOwnProperty("from")) ||
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            selectedFilingMaster.from === "Edit client filing")
                        }
                        value={formik.values.filingFrequency}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.filingFrequency &&
                          Boolean(formik.errors.filingFrequency)
                        }
                      >
                        {filingFrequencyLookup.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {formik.touched.filingFrequency &&
                          formik.errors.filingFrequency}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}>
                    {" "}
                    Due Date Offset (In Days)
                  </Typography>
                  <TextField
                    id='dueDayofFrequency'
                    name='dueDayofFrequency'
                    label='Due Date Offset'
                    fullWidth
                    autoComplete='DueDateOffset'
                    variant='filled'
                    type='number'
                    value={formik.values.dueDayofFrequency}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dueDayofFrequency &&
                      Boolean(formik.errors.dueDayofFrequency)
                    }
                  />
                </Grid> */}
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Rule Info</Typography>
                    <TextField
                      id='ruleInfo'
                      name='ruleInfo'
                      label='Enter Rule Info'
                      fullWidth
                      autoComplete='ruleInfo'
                      variant='filled'
                      value={formik.values.ruleInfo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.ruleInfo &&
                        Boolean(formik.errors.ruleInfo)
                      }
                    />
                  </Grid>

                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Jurisdiction</Typography>
                    <FormControl fullWidth variant='filled' required>
                      <InputLabel id='jurisdiction-label'>
                        Select Jurisdiction
                      </InputLabel>
                      <Select
                        labelId='jurisdiction-label'
                        id='juristiction'
                        disabled={
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            !selectedFilingMaster.hasOwnProperty("from")) ||
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            selectedFilingMaster.from === "Edit client filing")
                        }
                        name='juristiction'
                        label='Jurisdiction'
                        value={formik.values.juristiction}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.juristiction &&
                          Boolean(formik.errors.juristiction)
                        }
                      >
                        {juristictionLookup.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem key="Both" value="Both">
                          Both
                        </MenuItem>
                      </Select>
                      <FormHelperText error={true}>
                        {formik.touched.juristiction &&
                          formik.errors.juristiction}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> State</Typography>
                    <FormControl fullWidth variant='filled'>
                      <InputLabel id='state-label'>State</InputLabel>
                      <Select
                        labelId='state-label'
                        id='stateInfo'
                        name='stateInfo'
                        value={formik.values.stateInfo}
                        onChange={formik.handleChange}
                        disabled={
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            !selectedFilingMaster.hasOwnProperty("from")) ||
                          formik.values.juristiction === "Federal"
                        }
                        error={
                          formik.touched.stateInfo &&
                          Boolean(formik.errors.stateInfo)
                        }
                      >
                        {states.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.state}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {formik.touched.stateInfo && formik.errors.stateInfo}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}>
                      Business Categories
                      {selectedFilingMaster && selectedFilingMaster.client
                        ? ""
                        : "*"}
                    </Typography>
                    <FormControl fullWidth variant="filled" required>
                      <MultiSelect
                        label="Filing Categories"
                        inputs={categories.map((item) => ({
                          name: item.businessCategoryName
                            ? item.businessCategoryName
                            : "",
                          value: item.businessCategoryId
                            ? item.businessCategoryId + ""
                            : "",
                        }))}
                        values={selectedCategories}
                        initValues={
                          selectedCategories
                            ? selectedCategories
                            : initCategories
                        }
                        updateSelection={handleCategoryChanges}
                        required={true}
                        helperText={categoriesErrorText}
                      />
                      <FormHelperText
                        error={Boolean(
                          formik.touched.businessCategory &&
                            formik.errors.businessCategory
                        )}
                      >
                        {formik.touched.businessCategory &&
                          formik.errors.businessCategory}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}>Award Type</Typography>
                    <FormControl fullWidth variant='filled'>
                      <InputLabel id='awardType-label'>
                        Select Award Type
                      </InputLabel>
                      <Select
                        labelId='awardType-label'
                        id='awardType'
                        name='awardType'
                        value={formik.values.awardType}
                        onChange={formik.handleChange}
                      >
                        {awardTypeList.map((item: any) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}>Awarding Agency</Typography>
                    <FormControl fullWidth variant='filled'>
                      <InputLabel id='awardAgency-label'>
                        Select Awarding Agency
                      </InputLabel>
                      <Select
                        labelId='awardAgency-label'
                        id='awardingAgency'
                        name='awardingAgency'
                        value={formik.values.awardingAgency}
                        onChange={formik.handleChange}
                      >
                        {awardingAgencies.map((item: any) => (
                          <MenuItem
                            key={item.awardingAgencyId}
                            value={item.awardingAgencyId}
                          >
                            {item.awardingAgencyName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Program Name</Typography>
                    <FormControl fullWidth variant='filled' required>
                      <InputLabel id='programName-label'>
                        Select Program Name
                      </InputLabel>
                      <Select
                        labelId='programName-label'
                        id='programName'
                        name='programName'
                        label='programName'
                        value={formik.values.programName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.programName &&
                          Boolean(formik.errors.programName)
                        }
                      >
                        {programList.map((item: any) => (
                          <MenuItem key={item.programId} value={item.programId}>
                            {item.programName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {formik.touched.programName &&
                          formik.errors.programName}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6} lg={4}>
                    <FormControl fullWidth variant='filled'>
                      <Typography sx={fontStyle}> Filing Mandatory?</Typography>
                      <Switch
                        id='required'
                        name='required'
                        checked={switchChecked}
                        onChange={() => {
                          setSwitchChecked(!switchChecked);
                        }}
                      // onChange={handleManadatoryChange}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid xs={12}>
                  <h4>JSI CONTACT PERSON'S DETAILS</h4>
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}> JSI Dept</Typography>
                  <FormControl fullWidth variant="filled">
                    <InputLabel id="dept-label">Select JSI Dept</InputLabel>
                    <Select
                      labelId="dept-label"
                      id="jsidept"
                      name="jsidept"
                      value={formik.values.jsidept}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.jsidept && Boolean(formik.errors.jsidept)
                      }
                    >
                      {deptLookup.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> JSI Staff</Typography>
                    <TextField
                      id='jsicontactName'
                      name='jsicontactName'
                      label='Enter JSI Staff Name'
                      fullWidth
                      autoComplete='jsicontactName'
                      variant='filled'
                      value={formik.values.jsicontactName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.jsicontactName &&
                        Boolean(formik.errors.jsicontactName)
                      }
                    />
                  </Grid>
                  {/* <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}> JSI Contact Email</Typography>
                  <TextField
                    id="jsicontactEmail"
                    name="jsicontactEmail"
                    label="Enter JSI Contact Email"
                    fullWidth
                    autoComplete="jsicontactEmail"
                    variant="filled"
                    value={formik.values.jsicontactEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.jsicontactEmail &&
                      Boolean(formik.errors.jsicontactEmail)
                    }
                    helperText={
                      formik.touched.jsicontactEmail &&
                      formik.errors.jsicontactEmail
                    }
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}> JSI Contact No</Typography>
                  <TextField
                    id="jsicontactNumber"
                    name="jsicontactNumber"
                    label="Enter JSI Contact No"
                    fullWidth
                    autoComplete="jsicontactNumber"
                    variant="filled"
                    value={formik.values.jsicontactNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.jsicontactNumber &&
                      Boolean(formik.errors.jsicontactNumber)
                    }
                  />
                </Grid> */}
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Filing Period</Typography>
                    <FormControl fullWidth variant='filled' required>
                      <InputLabel id='filingPeriod-label'>
                        Select Filing Period
                      </InputLabel>
                      <Select
                        labelId='filingPeriod-label'
                        id='filingPeriod'
                        name='filingPeriod'
                        label='filingPeriod'
                        value={filingPeriodVal}
                        onChange={(e) => setFilingPeriodVal(e.target.value)}
                        disabled={
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            !selectedFilingMaster.hasOwnProperty("from")) ||
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            selectedFilingMaster.from === "Edit client filing")
                        }
                      >
                        {filingPeriod.map((item, index) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "small",
                          letterSpacing: "0.03em",
                          color: "#d32f2f",
                          display: filingPeriodValError ? "block" : "none",
                          margin: "3px 0 3px 14px",
                        }}
                      >
                        {filingPeriodValError}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={12} lg={12}>
                    <Typography sx={fontStyle}> Where to file</Typography>
                    <TextField
                      id='whereToFile'
                      name='whereToFile'
                      label='Where to file'
                      placeholder='Max-Length 500 characters'
                      fullWidth
                      multiline
                      minRows={2}
                      variant='filled'
                      autoComplete='whereToFile'
                      value={formik.values.whereToFile}
                      onChange={(e) => {
                        if (e.target.value.length <= 500) {
                          formik.handleChange(e);
                          setWherToRemainingChars(500 - e.target.value.length);
                        }
                      }}
                      error={
                        formik.touched.whereToFile &&
                        Boolean(formik.errors.whereToFile)
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "small",
                        color:
                          whereToFileRemainingChars === 0 ? "red" : "inherit",
                        display:
                          whereToFileRemainingChars === 500 ? "none" : "block",
                      }}
                    >
                      {whereToFileRemainingChars} Characters are left
                    </Typography>
                  </Grid>
                  {selectedCustomer?.parentCustomer === null && !selectedCustomer?.isOnlyParentFile && (
                    <Grid xs={12} sm={6} lg={4}>
                      {/* <Typography sx={fontStyle}> Client Name</Typography>
                      <TextField
                        id='client'
                        name='Client'
                        label='Client Name'
                        fullWidth
                        variant='filled'
                        value={formik.values.client}
                        onChange={formik.handleChange}
                        disabled
                      /> */}
                      <List >
                        <ListItemButton sx={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} onClick={handleClick}>
                          <ListItemText primary="Affected Companies" />


                          {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding sx={{ minHeight: "fit-content", maxHeight: "300px", overflow: 'auto' }}>
                            {customerList && customerList.map((item: any, index: any) => (
                              <ListItemButton key={index} sx={{ pl: 4 }}>
                                <ListItemText>{item.customerName}</ListItemText>
                              </ListItemButton>
                            ))}
                          </List>
                        </Collapse>
                      </List>
                    </Grid>
                  )}
                  <Grid xs={12} sm={12} lg={12}>
                    <Typography sx={fontStyle}> Notes</Typography>
                    <TextField
                      id='notes'
                      name='notes'
                      label='Notes'
                      placeholder='Max-Length 2000 characters'
                      multiline
                      fullWidth
                      minRows={3}
                      variant='filled'
                      value={formik.values.notes}
                      onChange={(e) => {
                        if (e.target.value.length <= 2000) {
                          formik.handleChange(e);
                          setRemainingChars(2000 - e.target.value.length);
                        }
                      }}
                      error={
                        formik.touched.notes && Boolean(formik.errors.notes)
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "small",
                        color: remainingChars === 0 ? "red" : "inherit",
                        display: remainingChars === 2000 ? "none" : "block",
                      }}
                    >
                      {remainingChars} Characters are left
                    </Typography>
                  </Grid>
                  {/* <Grid xs={12} sm={12} lg={12} className="filingPeriodTable">
                    <DataTable
                      value={filingPeriodTable}
                      editMode="cell"
                      tableStyle={{ height: "max-content" }}
                    >
                      <Column
                        field="filingPeriod"
                        header="Filing Period"
                      ></Column>
                      <Column
                        field="dueDate"
                        header="DueDate"
                        style={{ cursor: "pointer" }}
                        editor={(options) => cellEditor(options)}
                        onCellEditComplete={onCellEditComplete}
                      ></Column>
                    </DataTable>
                    <Typography
                      sx={{
                        fontSize: "0.75rem",
                        fontWeight: "small",
                        letterSpacing: "0.03em",
                        color: "#d32f2f",
                        display: filingPeriodTableError ? "block" : "none",
                        margin: "3px 0 3px 14px",
                      }}
                    >
                      {filingPeriodTableError}
                    </Typography>
                  </Grid> */}
                  <Grid
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <MuiButton
                      variant="text"
                      style={{ color: "blue" }}
                      sx={{ margin: 1, padding: 1 }}
                      onClick={backToHome}
                    >
                      CANCEL
                    </MuiButton>
                    <MuiButton
                      variant="contained"
                      // color="primary"
                      type="submit"
                      // disabled={!formIsValid}
                      sx={{ margin: 1, padding: 1, backgroundColor: "0b3edf" }}
                      disabled={draftSubmitted}
                    >
                      {actionLabel}
                    </MuiButton> */}
                    {/* <LoadingButton
                    variant="contained"
                    style={{ backgroundColor: "blue", width: "100px" }}
                    // onClick={submitForm}
                    loading={filingMasterLoading}
                    // disabled={!formIsValid}
                    sx={{ margin: 1, padding: 1 }}
                    loadingIndicator={
                      <CircularProgress color="inherit" size={16} />
                    }
                  >
                    ADD
                  </LoadingButton> */}
                  </Grid>
                  <Grid xs={12}>
                    <h4>Reference Documents</h4>
                  </Grid>
                  <Grid xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "40%",
                      }}
                    >
                      <div>
                        <form
                          method='post'
                          id='uploadForm'
                          onChange={(event) => onFileChange(event)}
                        >
                          <div className='field'>
                            <label className='file-upload'>
                              {/* <span>
                            <strong>Add File</strong>
                          </span> */}
                              {/* <input
                              type="file"
                              name="file"
                              id="inFile"
                              disabled={!draftSubmitted}
                            /> */}
                              <TextField
                                type='file'
                                name='file'
                                id='inFile'
                                //  disabled={!draftSubmitted}
                                InputProps={{ disableUnderline: true }}
                                helperText={
                                  attachmentError
                                    ? "No attachments attached"
                                    : ""
                                }
                                error={attachmentError}
                              />
                            </label>
                          </div>
                        </form>
                      </div>
                      <span
                        className='file-upload-status'
                        style={{ opacity: 1 }}
                        id='upload-status'
                      ></span>
                      <div id='fileInfo'></div>
                      <MuiButton
                        variant='contained'
                        color='primary'
                        // disabled={!formIsValid}
                        sx={{
                          margin: 1,
                          padding: 1,
                          alignSelf: "center",
                          backgroundColor: "#0b3edf",
                        }}
                        onClick={onFileUpload}
                      //disabled={!draftSubmitted}
                      >
                        Attach File
                      </MuiButton>
                    </div>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignContent: "center",
                      alignItems: "center",
                      width: "40%",
                    }}
                  >
                    <List style={{ width: "100%" }}>
                      {attachments &&
                        attachments
                          .slice()
                          .reverse()
                          .map((item, index) => (
                            <Fragment key={index}>
                              <ListItem
                                disablePadding
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ListItemIcon>
                                    <Link>
                                      <AttachFileIcon />
                                    </Link>
                                  </ListItemIcon>
                                  <ListItemButton
                                    onClick={() =>
                                      downloadFile(
                                        item.attachmentPath
                                          ? item.attachmentPath
                                          : "",
                                        item.attachmentId
                                      )
                                    }
                                  >
                                    <ListItemText
                                      primary={
                                        item &&
                                        item.attachmentPath &&
                                        getFilename(
                                          item.attachmentPath
                                            ? item.attachmentPath
                                            : ""
                                        )
                                      }
                                    />
                                  </ListItemButton>
                                </div>
                                <IconButton>
                                  <DeleteIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleAttachmentDelete(
                                        item.attachmentId,
                                        item.attachmentPath,
                                        "attachment"
                                      );
                                    }}
                                  />
                                </IconButton>
                              </ListItem>
                            </Fragment>
                          ))}
                    </List>
                  </Grid>
                  <Grid xs={10}>
                    <div style={{ marginLeft: "60px" }}>
                      {isAttachmentLoading ? <CircularProgress /> : null}
                    </div>
                  </Grid>
                  <Grid
                    xs={10}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {files.length > 0 && (
                      <div style={{ width: "50%" }}>
                        <UploadedFilesTable
                          files={files}
                          handleFileAttachmentDelete={(
                            attachmentId: any,
                            attachmentPath: any
                          ) =>
                            handleAttachmentDelete(
                              attachmentId,
                              attachmentPath,
                              "uploaded"
                            )
                          }
                        />
                      </div>
                    )}
                  </Grid>

                  <Grid
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MuiButton
                      variant='text'
                      style={{ color: "blue" }}
                      sx={{ margin: 1, padding: 1 }}
                      onClick={backToHome}
                    >
                      CANCEL
                    </MuiButton>
                    <MuiButton
                      variant='contained'
                      // color="primary"
                      type='submit'
                      // disabled={!formIsValid}
                      sx={{ margin: 1, padding: 1, backgroundColor: "0b3edf" }}
                      disabled={
                        formTitle === "EDIT POST AWARD MASTER RECORD" || actionLabel === "SAVE"
                          ? isEqual &&
                          !isFilesAdded &&
                          !isAttachmentChanges &&
                          !draftSubmitted &&
                          newlyAddingAffectedCompanies.length === 0
                          : draftSubmitted
                      }                    >
                      {/* {actionLabel} */}
                      SUBMIT & RETURN
                    </MuiButton>
                  </Grid>
                </Grid>
              </form>
            )}
            <style>
              {`.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        cursor : pointer;
        {
          
      `}
            </style>
          </React.Fragment>
        </Container>
      </Stack>
    </ThemeProvider>
  );
}
