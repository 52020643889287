import { format } from 'date-fns';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertColor, Divider, FormHelperText, Icon } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MuiButton from '@mui/material/Button';
import { Button } from 'primereact/button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Stack } from '@mui/material';
import Switch from '@mui/material/Switch';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Dialog } from 'primereact/dialog';
// import AlertDialog from "../common/AlertDialog";
// import Alert from "../common/Alert";
import AuthContext from '../../context/auth/AuthContext';
import FilingMasterContext from '../../context/filing-master/FilingMasterContext';
import { BusinessCategory, FilingMaster } from '../../types/FilingMaster';
import states from '../../data/StateLookup.json';
import { juristictionLookup, deptLookup } from '../../data/constants';
import MultiSelect from '../common/MultiSelect';
import axios from 'axios';
import { filingFrequencyLookup } from '../../data/constants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import UploadedFilesTable from './UploadedFilesTable';
import { FilingMasterAttachment } from '../../types/FilingMaster';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import Loader from '../common/Loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { useLocation } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CustomerContext from "../../context/customer/CustomerContext";




// const categories = [
//   { id: 1, category: "Telecom" },
//   { id: 2, category: "Brodband" },
//   { id: 3, category: "Wireless" },
//   { id: 4, category: "Mobile" },
// ];

const theme = createTheme();
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function FilingMasterForm() {
  const filingMasterContext = useContext(FilingMasterContext);
  const {
    selectedFilingMaster,
    setCurrentFilingMaster,
    getBusinessCategories,
    createFilingMasterDraftCategories,
    createDraftFilingMaster,
    createClientDraftFilingMaster,
    createFilingMasterWorkflow,
    createClientFilingMasterWorkflow,
  } = filingMasterContext;
  const customerContext = useContext(CustomerContext);
  const { selectedCustomer } = customerContext;
  const authContext = useContext(AuthContext);
  const {
    authUser,
    getCustomerNotifications,
    getFilingNotifications,
    getCustomerNotificationsWorkFlow,
    setNotificationCount,
    notificationCount,
  } = authContext;
  const location = useLocation();
  const selectedAffectedCompanies = location?.state?.selectedAffectedCompanies ? location.state.selectedAffectedCompanies : [] // Newly adding companies through add form

  const selectedAffectedCompaniesEdit = location?.state?.selectedAffectedCompaniesEdit ? location.state.selectedAffectedCompaniesEdit : []
  const newlyAddingAffectedCompanies = selectedAffectedCompaniesEdit ? selectedAffectedCompaniesEdit.filter((item: any) => {
    return !selectedAffectedCompanies.includes(item);
  }) : []; //Newly adding affected companies through edit form 
  const customerNames = location?.state?.customerNames ? location.state.customerNames : []


  const [alertMessage, setAlertMessage] = useState<string | JSX.Element>('');
  const [switchChecked, setSwitchChecked] = useState(false);
  const [categories, setCategories] = useState<BusinessCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [initCategories, setInitCategories] = useState<string[]>([]);
  const [filingPeriod, setFilingPeriod] = useState<any[]>([]);
  const [filingPeriodVal, setFilingPeriodVal] = useState<any>('');
  const [isInitalFilingVal, setIsInitalFilingVal] = useState(false);
  const [filingPeriodValError, setFilingPeriodValError] = useState<string>();
  const [filingPeriodTable, setFilingPeriodTable] = useState<any[]>([]);
  const [filingPeriodTableChanges, setFilingPeriodTableChanges] = useState<
    any[]
  >([]);
  const [isFfilingPeriodTableChanges, setIsFilingPeriodTableChanges] =
    useState(false);
  const [filingPeriodTableError, setFilingPeriodTableError] =
    useState<string>();
  const [dateValue, setDateValue] = useState();
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [draftSubmitted, setDraftSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = useState<
    {
      name: string;
      attachmentId: number;
      attachmentPath: string;
    }[]
  >([]);
  const [formTitle, setFormTitle] = useState<any>('ADD FILING MASTER RECORD');
  const [remainingChars, setRemainingChars] = useState(2000);
  const [whereToFileRemainingChars, setWherToRemainingChars] = useState(500);
  const [categoriesErrorText, setCategoriesErrorText] = useState<string>('');
  const [draftId, setDraftId] = useState<any>();
  const [isCategoryChanged, setIsCategoryChanged] = useState(false);
  const [actionLabel, setActionLabel] = useState<string>('ADD RECORD');
  const [filingAttachment, setFilingAttachment] = useState<Object[]>([]);
  const [attachmentError, setAttachmentError] = useState(false);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [attachments, setAttachments] = React.useState<
    FilingMasterAttachment[]
  >([]);
  const [attachmentChange, setAttachmentChange] = React.useState<
    FilingMasterAttachment[]
  >([]);
  const [isEqual, setIsEqual] = useState(true);
  const [isAttachmentChanges, setIsAttachmentChanges] = useState(false);
  const [isFilesAdded, setIsFileAdded] = useState(false);
  const [customerNotificationCount, setCustomerNotificationCount] =
    React.useState(0);
  const [filingNotificationCount, setFilingNotificationCount] =
    React.useState(0);
  const [customerNotificationsWorkFlow, setCustomerNotificationsWorkFlow] =
    React.useState(0);
  const fontStyle = {
    fontWeight: 'regular',
    fontSize: '13px',

    color: '#424242',
  };
  console.log("selectedAffectedCompanies", selectedAffectedCompanies, "selectedAffectedCompaniesEdit", selectedAffectedCompaniesEdit, "customerNames", customerNames);

  const initForm: FilingMaster = {
    // filingId: 0,
    filingName: '',
    filingDescription: '',
    filingFrequency: '',
    dueDayofFrequency: 0,
    stateInfo: '',
    ruleInfo: '',
    juristiction: '',
    jsidept: '',
    jsicontactName: '',
    jsicontactEmail: '',
    jsicontactNumber: '',
    required: true,
    notes: '',
    whereToFile: '',
    changesInprogress: true,
    businessCategory: [],
    filingPeriods: undefined,
  };

  const validationSchema = yup.object({
    filingName: yup
      .string()
      .min(2, 'Name is too Short!')
      .max(250, 'Name is too Long!')
      .required('Filing Name is required'),
    // filingDescription: yup.string().required("Filing Description is required"),
    filingFrequency: yup.string().required('Filing Frequency is required'),
    juristiction: yup.string().required('Jurisdiction is required'),
    jsicontactEmail: yup.string().email('Enter a valid email'),
    stateInfo: yup
      .string()
      .when('juristiction', (juristiction: string[], schema) => {
        // console.log('Validation', juristiction);
        if (juristiction[0] === 'State') {
          return schema.required('State is required');
        }
        return schema;
      }),
    // businessCategory: yup
    //   .array()
    //   .min(1, "Business category is required")
    //   .required("Business category is required"),
  });
  const [isFinalSubmit, setIsFinalSubmit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [deleteAttachment, setDeleteAttachment] = useState<any>({
    attachmentId: '',
    attachmentPath: '',
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [filingMaster, setFilingMaster] = useState<FilingMaster>(initForm);
  const navigate = useNavigate();

  const backToHome = () => {
    setRemainingChars(2000);
    setWherToRemainingChars(500);
    if (selectedFilingMaster && selectedFilingMaster.hasOwnProperty('from')) {
      navigate(
        `/client-filing-tracker-list/${selectedFilingMaster.customerId}`
      );
    } else {
      navigate('/filing-master-list');
    }
  };

  const fetchNotificationCount = async () => {
    // console.log('CustomerNotification', getCustomerNotifications);
    // console.log('filingNotifications', getFilingNotifications);

    if (
      getCustomerNotifications &&
      getFilingNotifications &&
      getCustomerNotificationsWorkFlow
    ) {
      try {
        let custList = await getCustomerNotifications();
        custList = custList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === 'Notification');

        // console.log('@@customerNotifications:', custList);
        // setTotalNotificationCount((old) => old + custList.length);

        setCustomerNotificationCount(custList.length);
        let filingList = await getFilingNotifications();
        filingList = filingList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === 'Notification');

        // console.log('@@filingNotifications:', filingList);
        // setTotalNotificationCount((old) => old + filingList.length);
        setFilingNotificationCount(filingList.length);
        let custListWorkflow = await getCustomerNotificationsWorkFlow();
        custListWorkflow = custListWorkflow
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === 'Notification');
        // console.log('@@filingNotifications:', custListWorkflow);

        setCustomerNotificationsWorkFlow(custListWorkflow.length);
        // setTotalNotificationCount(
        //   (old) =>
        //     custList?.length + filingList?.length + custListWorkflow?.length
        // );
        const count =
          custList?.length + filingList?.length + custListWorkflow?.length;
        if (setNotificationCount) {
          setNotificationCount(count);
        }
      } catch (error) {
        console.log('@@Error:', error);
      }
    }
  };
  console.log("customerList", customerList);

  const submitForm = async () =>
  // event: React.MouseEvent<HTMLElement>
  {
    console.log("submitForm triggered");
    console.log("submitForm selectedFilingMaster", selectedFilingMaster);

    try {
      if (createClientDraftFilingMaster) {
        if (
          !selectedCategories.length &&
          !(
            selectedFilingMaster &&
            selectedFilingMaster.hasOwnProperty('from')
          )
        ) {
          return null;
        }
        if (filingPeriodVal === '') {
          setFilingPeriodValError('Filing Period is required');
          return null;
        } else {
          setFilingPeriodValError('');
        }
        if (filingPeriodTable.length) {
          // console.log('submitForm filingPeriodTable', filingPeriodTable);
          // console.log('FilingFrequencyVal', formik.values.filingFrequency);
          const noDueDate = filingPeriodTable.every((obj) =>
            obj.hasOwnProperty('dueDate')
          );
          // console.log('submitForm noDueDate', noDueDate);
          if (!noDueDate && formik.values.filingFrequency !== 'Conditional') {
            setFilingPeriodTableError(
              'Due Dates for all Filing Period is required'
            );
            return null;
          }
        }
        let getDraft = await getDraftId();

        // console.log('submitForm filingPeriodTable', getDraft);
        const _filing: any = {
          ...formik.values,
          stateInfo:
            formik.values.stateInfo === '' ? null : formik.values.stateInfo,
          required: filingMaster.required,
        };
        if (filingMaster.businessCategory)
          delete filingMaster.businessCategory;
        _filing.createDate = new Date();
        _filing.createUser = authUser?.userId + '';
        _filing.status = 'Pending';
        if (selectedFilingMaster && selectedFilingMaster !== null) {
          if (
            selectedFilingMaster &&
            selectedFilingMaster.hasOwnProperty('from') &&
            selectedFilingMaster.from === 'Edit client filing'
          ) {
            _filing.draftId = getDraft;
            _filing.businessOperation = 'edit';
            _filing.filingId = selectedFilingMaster.filingId;
          } else if (
            selectedFilingMaster &&
            selectedFilingMaster.hasOwnProperty('from') &&
            selectedFilingMaster.from === 'Add client filing'
          ) {
            _filing.draftId = getDraft;
            _filing.businessOperation = 'add';
          } else {
            _filing.businessOperation = 'edit';
            _filing.draftId = getDraft;
            _filing.filingId = selectedFilingMaster.filingId;
          }
        } else {
          _filing.draftId = getDraft;
          _filing.businessOperation = 'add';
        }
        if (
          selectedFilingMaster &&
          selectedFilingMaster.hasOwnProperty('from')
        ) {
          _filing.IsFiling = true;
        } else {
          _filing.IsFiling = false;
        }
        setIsLoading(true);
        _filing.recordType = 'TrackNow';
        const draftPayload = [_filing]
        console.log("submitForm", "draftPayload", draftPayload);

        const res = await createClientDraftFilingMaster(draftPayload);
        // console.log(
        //   `Submitting Add FilingMaster: [${JSON.stringify(filingMaster)}]`
        // );

        if (res) {
          // console.log('Final Cats:', selectedCategories);
          // console.log('Final Cats:', formik.values);
          let payload = null;
          if (_filing.businessOperation === 'add') {
            payload = selectedCategories.map((item) => ({
              draftId: getDraft,
              businessCategoryId: item,
              businessOperation: 'add',
              state:
                formik.values.stateInfo === ''
                  ? null
                  : formik.values.stateInfo,
            }));
          } else if (_filing.businessOperation === 'edit') {
            let _delCats = _.difference(initCategories, selectedCategories);
            let _addCats = _.difference(selectedCategories, initCategories);
            const _delCatsPL = _delCats.map((item) => ({
              draftId: getDraft,
              businessCategoryId: item,
              businessOperation: 'delete',
              state:
                formik.values.stateInfo === ''
                  ? null
                  : formik.values.stateInfo,
            }));
            const _addCatsPL = _addCats.map((item) => ({
              draftId: getDraft,
              businessCategoryId: item,
              businessOperation: 'add',
              state:
                formik.values.stateInfo === ''
                  ? null
                  : formik.values.stateInfo,
            }));
            payload = [..._addCatsPL, ..._delCatsPL];
            // console.log('_delCats payload', payload);
          }

          setFilingMaster({
            ...filingMaster,
            draftId: getDraft ? getDraft : 0,
            filingId: res.filingId ? res.filingId : 0,
          });

          let res1 = null;

          if (createFilingMasterDraftCategories) {
            res1 = await createFilingMasterDraftCategories(payload);
          }

          if (res1) {
            let wfPayload: object | any[];
            // console.log("submitForm Filing", _filing, "selectedAffectedCompanies", selectedAffectedCompanies, "formik.values", formik.values, "selectedFilingMaster", selectedFilingMaster, "newlyAddingAffectedCompanies", newlyAddingAffectedCompanies);
            if (selectedFilingMaster &&
              selectedFilingMaster.hasOwnProperty("from") &&
              (selectedFilingMaster.from === "Add client filing" || selectedFilingMaster.from === "Edit client filing")) {
              if (selectedFilingMaster.from === "Add client filing") {
                // const customerIds = (customerList as { customerId: string }[]).map(custId => Number(custId.customerId));//Extracting customerIds alone from customerList

                // const custList = selectedFilingMaster ? [...customerIds, ...newlyAddingAffectedCompanies] : []
                console.log("submitForm", "selectedCustomer", selectedCustomer);
                let custList = [];

                if (selectedCustomer?.bothParentAndChild) {
                  custList = selectedFilingMaster ?
                    [...selectedAffectedCompanies, ...newlyAddingAffectedCompanies] :
                    [];
                } else {
                  custList = selectedFilingMaster ?
                    [Number(selectedFilingMaster.customerId)] :
                    [];
                }
                console.log("submitForm", "custList", custList);
                wfPayload = custList.map((item) => ({
                  draftId: getDraft ? getDraft : 0,
                  customerId: item,
                  WorkflowInitiatorId: authUser?.userId + '',
                  createDate: new Date(),
                  createUser: authUser?.userId + '',
                }))
              } else {
                const customerIds = customerList.map(cust => cust.customerId)
                let custList = []
                if (selectedCustomer?.bothParentAndChild) {
                  custList = selectedFilingMaster ?
                    [...customerIds] :
                    [];
                } else {
                  custList = selectedFilingMaster ?
                    [Number(selectedFilingMaster.customerId)] :
                    [];
                }
                // console.log("submitForm", "custList", custList, "customerIds", customerIds);
                //const custList = selectedFilingMaster ? [Number(selectedFilingMaster.customerId), ...selectedAffectedCompanies, ...newlyAddingAffectedCompanies] : []
                // console.log("submitForm", "custList", custList);
                console.log("customerList", customerList, "selectedFilingMaster", selectedFilingMaster, "newlyAddingAffectedCompanies", newlyAddingAffectedCompanies, "selectedAffectedCompaniesEdit", selectedAffectedCompaniesEdit);
                console.log("customerIds", customerIds, "custList", custList);

                wfPayload = custList.map((item) => ({
                  draftId: getDraft ? getDraft : 0,
                  customerId: item,
                  WorkflowInitiatorId: authUser?.userId + '',
                  createDate: new Date(),
                  createUser: authUser?.userId + '',
                }))
              }
            } else {
              wfPayload = [{
                draftId: getDraft ? getDraft : 0,
                WorkflowInitiatorId: authUser?.userId + '',
                createDate: new Date(),
                createUser: authUser?.userId + '',
                customerId: null
                // currentApproverId: 1,
              }]
            }

            if (filingPeriodTable.length > 0) {
              // console.log('Create Draft Due Dates', filingPeriodTable);
              let res4 = await createDraftDueDates(getDraft);
              // console.log('Create Draft Due Dates res', res4);
            }

            let res3 = null;
            if (createClientFilingMasterWorkflow) {
              res3 = await createClientFilingMasterWorkflow(wfPayload);
            }
            // console.log('CreateFilingMasterWorkflow', res3);
            if (res3) {
              localStorage.removeItem('DraftId');
              onFinalSubmit();
              setFilingPeriodTableError(() => '');
              setDraftSubmitted(true);
              setRemainingChars(2000);
              setWherToRemainingChars(500);
              if (!(selectedFilingMaster &&
                selectedFilingMaster.hasOwnProperty('from') &&
                (selectedFilingMaster.from === 'Edit client filing' || selectedFilingMaster.from === 'Add client filing'))) {
                await fetchNotificationCount();
              }
              setIsLoading(false);

              return;
            }
          }
        } else {
          setAlertMessage(
            `Unexpected Error occured while submitting Filing Master`
          );
          setShowAlert(true);
          setRemainingChars(2000);
          setWherToRemainingChars(500);
        }
      }
    } catch (error: any) {
      console.log(
        `Error code:${error?.errorCode}, Error Message: ${error?.errorMessage}`
      );
      if (error?.errorMessage === 'Duplicate Filing found in filingmaster') {
        localStorage.removeItem('DraftId');
        getDraftId();
      }
      setAlertMessage(`${error?.errorMessage}`);
      setShowAlert(true);
    }
    setIsLoading(false);
  };


  const closeAlert = () => {
    setShowAlert(false);
    if (isFinalSubmit) {
      if (selectedFilingMaster?.from === "Add client filing" || selectedFilingMaster?.from === "Edit client filing") {
        navigate(
          `/client-filing-tracker-list/${selectedFilingMaster.customerId}`
        );
      } else {
        navigate('/filing-master-list');
      }
    }
  };

  const handleCategoryChanges = (
    values: string[],
    keys: any,
    action: string
  ) => {
    // console.log('@@Changed BusinessCategories:', values.length);
    setSelectedCategories((old) => values);
    // formik.validateField("businessCategoryId");
    // formik.validate();
    // if (values.length > 0) setCategoriesErrorText("");
    // else setCategoriesErrorText("Business Category is required");
    // validateCategories(formik.values);
    setIsCategoryChanged(() => true);
    validateCategories(values, true);
  };

  const fetchFilingMasterAttachments = async (filingId: any) => {
    // console.log('fetchFilingMasterAttachments props', filingId);
    try {
      const attachmentList = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachments/${filingId}`
      );
      setAttachments(() => attachmentList.data.data);
      setAttachmentChange(() => attachmentList.data.data);
      // console.log('fetchFilingMasterAttachments res', attachmentList);
    } catch (error) {
      console.log('@@Error:', error);
    }
  };

  const fetchBusinessCategories = async () => {
    // console.log('@@Fetch BusinessCategories:', getBusinessCategories);
    if (getBusinessCategories) {
      try {
        let catList = await getBusinessCategories();
        catList.sort((a, b) => (a.businessCategoryName ?? '').localeCompare(b.businessCategoryName ?? ''));

        // console.log('@@BusinessCategories:', catList);
        setCategories(() => catList);
      } catch (error) {
        console.log('@@Error:', error);
      }
    }
  };

  const downloadAttachment = async (attachment: any) => {
    // console.log('downloadAttachment', attachment);
    await axios
      .get(
        `${API_BASE_URL}/Application/DownloadBlob?FileUrl=${attachment.attachmentPath}`
      )
      .then((res) => {
        // console.log('downloadAttachment res', res.data);
        if (res) {
        }
      });
  };

  const getFileAttachment = async () => {
    // console.log('getFileAttachment-->', selectedFilingMaster);
    await axios
      .get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachmentsGetById/${selectedFilingMaster?.filingId}`
      )
      .then((res) => {
        // console.log('getFileAttachment res', res.data.data);
        if (res) {
          setFilingAttachment(res.data.data);
          // console.log('getFileAttachment filingAttachment', filingAttachment);
          setAttachments(() => res.data.data);
        }
      });
  };

  const getFilename = function (str: string) {
    return str ? str.replace(/^.*[\\\/]/, '') : '';
  };

  const downloadFile = (attachmentPath: any, attachmentId: any) => {
    // console.log('Downloading File from:', attachmentId);
    const fileName = getFilename(attachmentPath);
    // console.log('Downloading File:', fileName);

    let filingId = filingMaster?.filingId
      ? filingMaster?.filingId + ''
      : selectedFilingMaster?.filingId
        ? selectedFilingMaster?.filingId + ''
        : '0';
    axios({
      url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&FilingId=${filingId}`,
      method: 'POST',
      responseType: 'blob', // Important
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  };

  const deleteFileAttachment = async () => {
    console.log('deleteFileAttachment', deleteAttachment);
    const removeExisting = attachments.findIndex(
      (item) => item.attachmentId === deleteAttachment.attachmentId
    );
    let filingId = filingMaster?.filingId
      ? filingMaster?.filingId + ''
      : selectedFilingMaster?.filingId
        ? selectedFilingMaster?.filingId + ''
        : '0';
    let getDraft = await getDraftId();
    // console.log('deleteFileAttachment removeExisting', removeExisting);
    if (removeExisting >= 0) {
      await axios
        .post(
          `${API_BASE_URL}/Application/DeleteAttachment?filingId=${filingId}&AttachmentId=${deleteAttachment.attachmentId}&DraftId=${deleteAttachment.draftId}`
        )
        .then((res) => {
          // console.log('deleteFileAttachment removeExisting res', res);
          if (res) {
            // console.log('Exisitng deleteFileAttachment', removeExisting);
            const tmpFiles = _.cloneDeep(attachments);
            // console.log('Post deleteFileAttachment', removeExisting);
            tmpFiles.splice(removeExisting, 1);
            // console.log('Post deleteFileAttachment', tmpFiles);
            setAttachments(tmpFiles);
          }
        });
    } else {
      await axios
        .post(
          `${API_BASE_URL}/Application/DeleteAttachment?filingId=${filingId}&AttachmentId=${deleteAttachment.attachmentId}&DraftId=${deleteAttachment.draftId}`
        )
        .then((res) => {
          // console.log('deleteFileAttachment res', res);
          if (res) {
            const tmpFiles = _.cloneDeep(files);
            const removeFile = tmpFiles.findIndex(
              (item) => item.attachmentId === deleteAttachment.attachmentId
            );
            // console.log('Post deleteFileAttachment', removeFile);
            tmpFiles.splice(removeFile, 1);
            // console.log('Post deleteFileAttachment', tmpFiles);
            setFiles(tmpFiles);
          }
        });
    }
    setShowDeleteAlert(false);
  };

  const deleteAttachmentBlob = async () => {
    // console.log('deleteAttachmentBlob', deleteAttachment);
    if (deleteAttachment.type === 'attachment') {
      deleteExistingAttachment();
    } else {
      await axios
        .delete(
          `${API_BASE_URL}/Application/DeleteFileurl?url=${deleteAttachment?.attachmentPath}`
        )
        .then((res) => {
          // console.log('deleteAttachmentBlob res', res.data);
          if (res) {
            deleteFileAttachment();
          }
        });
    }
  };

  const deleteExistingAttachment = async () => {
    // console.log('deleteExistingAttachment', filingMaster, deleteAttachment);
    let payload = {
      draftId: filingMaster?.draftId ? filingMaster?.draftId + '' : '0',
      businessOperation: 'delete',
      attachmentPath: deleteAttachment?.attachmentPath,
    };
    const res = await axios.post(
      `${API_BASE_URL}/FilingMaster/CreateFilingMasterDraftAttachments`,
      payload
    );
    // console.log('deleteExistingAttachment res', res);
    if (res.data) {
      setShowDeleteAlert(false);
      return res.data;
    }
  };

  const handleAttachmentDelete = async (
    attachmentId: any,
    attachmentPath: any,
    draftId: any,
    type: any
  ) => {
    // console.log(
    //   'handleAttachmentDelete',
    //   type,
    //   attachmentId,
    //   attachmentPath,
    //   attachments
    // );
    setDeleteAttachment({
      attachmentId: attachmentId,
      attachmentPath: attachmentPath,
      draftId: draftId,
      type: type,
    });
    setShowDeleteAlert(true);
  };

  const closeDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  const footerContentDelete = (
    <div>
      <Button
        label='No'
        icon='pi pi-check'
        onClick={() => closeDeleteAlert()}
        autoFocus
      />
      <Button
        style={{ backgroundColor: '0b3edf' }}
        label='Yes'
        icon='pi pi-check'
        onClick={() => deleteFileAttachment()}
        autoFocus
      />
    </div>
  );

  useEffect(() => {
    // toggleDrawer(props.dockAt, props.show);
    fetchBusinessCategories();
    fetchFilingPeriod();
    //eslint-disable-next-line
    // console.log('&filingMasters-->', selectedFilingMaster);
    if (selectedFilingMaster !== null) {
      if (
        selectedFilingMaster &&
        selectedFilingMaster.recordType &&
        selectedFilingMaster.recordType === 'PostAward'
      ) {
        if (setCurrentFilingMaster) setCurrentFilingMaster(null);
      } else if (selectedFilingMaster && !selectedFilingMaster.filingName) {
        // const newFiling = {
        //   client: selectedFilingMaster && selectedFilingMaster.client,
        //   customerId: selectedFilingMaster && selectedFilingMaster.customerId
        // };
        // formik.setValues(newFiling);
        // console.log("Title check selectedFilingMaster", selectedFilingMaster);

        setFormTitle(selectedFilingMaster?.from?.toUpperCase());
      } else {
        // getFileAttachment();
        console.log("selectedFilingMaster", selectedFilingMaster, " edit");

        getFilingPeriod();
        selectedFilingMaster && selectedFilingMaster?.from === "Edit client filing"
          ? setFormTitle("EDIT CLIENT FILING")
          : setFormTitle('EDIT FILING MASTER RECORD');
        setActionLabel('SAVE');
        let requiredField: boolean | undefined = false
        if (selectedFilingMaster) {
          if ('filingRequired' in selectedFilingMaster) {
            requiredField = selectedFilingMaster.filingRequired !== undefined ? !!selectedFilingMaster.filingRequired : false;
          } else {
            requiredField = selectedFilingMaster.required !== undefined ? !!selectedFilingMaster.required : false;
          }
        }
        const newFiling = {
          filingName:
            selectedFilingMaster && selectedFilingMaster.filingName
              ? selectedFilingMaster.filingName
              : '',
          // filingDescription:
          //   selectedFilingMaster && selectedFilingMaster.filingDescription
          //     ? selectedFilingMaster.filingDescription
          //     : "",
          filingFrequency:
            selectedFilingMaster && selectedFilingMaster.filingFrequency
              ? selectedFilingMaster.filingFrequency
              : '',
          dueDayofFrequency:
            selectedFilingMaster && selectedFilingMaster.dueDayofFrequency
              ? selectedFilingMaster.dueDayofFrequency
              : 0,
          stateInfo:
            selectedFilingMaster && selectedFilingMaster.stateInfo
              ? selectedFilingMaster.stateInfo
              : '',
          ruleInfo:
            selectedFilingMaster && selectedFilingMaster.ruleInfo
              ? selectedFilingMaster.ruleInfo
              : '',
          juristiction:
            selectedFilingMaster && selectedFilingMaster.juristiction
              ? selectedFilingMaster.juristiction
              : '',
          // jsidept:
          //   selectedFilingMaster && selectedFilingMaster.jsidept
          //     ? selectedFilingMaster.jsidept
          //     : "",
          jsicontactName:
            selectedFilingMaster && selectedFilingMaster.jsicontactName
              ? selectedFilingMaster.jsicontactName
              : '',
          // jsicontactEmail:
          //   selectedFilingMaster && selectedFilingMaster.jsicontactEmail
          //     ? selectedFilingMaster.jsicontactEmail
          //     : "",
          // jsicontactNumber:
          //   selectedFilingMaster && selectedFilingMaster.jsicontactNumber
          //     ? selectedFilingMaster.jsicontactNumber
          //     : "",
          required: requiredField,
          notes:
            selectedFilingMaster && selectedFilingMaster.notes
              ? selectedFilingMaster.notes
              : '',
          whereToFile:
            selectedFilingMaster && selectedFilingMaster.whereToFile
              ? selectedFilingMaster.whereToFile
              : '',
          changesInprogress:
            selectedFilingMaster && selectedFilingMaster.changesInprogress
              ? selectedFilingMaster.changesInprogress
              : true,
          businessCatergoryId: 0,
          // client: selectedFilingMaster && selectedFilingMaster.client,
        };
        setSwitchChecked(newFiling.required);
        // console.log(
        //   'Filing Master Bus Cats:',
        //   selectedFilingMaster?.businessCategory
        // );
        if (
          selectedFilingMaster?.businessCategory &&
          selectedFilingMaster?.businessCategory.length > 0
        ) {
          const _selectedCategoryIds =
            selectedFilingMaster?.businessCategory.map(
              (item) => item.businessCategoryId + ''
            );
          // console.log('Selected Cat IDs:', _selectedCategoryIds);
          setInitCategories(() => _selectedCategoryIds);
          setSelectedCategories((old) => _selectedCategoryIds);
        }
        fetchFilingMasterAttachments(selectedFilingMaster?.filingId);
        formik.setValues(newFiling);
        validateCategories(selectedCategories, true);
      }
    }
    return () => {
      if (setCurrentFilingMaster) setCurrentFilingMaster(null);
    };
  }, []);

  // useEffect(() => {
  //   if (selectedFilingMaster?.client) {
  //     // console.log("selectedFilingMaster check", selectedFilingMaster);
  //   }
  // }, [selectedFilingMaster?.client])


  useEffect(() => {
    // toggleDrawer(props.dockAt, props.show);
    console.log('SWITCH::', switchChecked ? 'ON' : 'OFF');
    setFilingMaster((filingMaster) => ({
      ...filingMaster,
      required: switchChecked,
    }));
    //eslint-disable-next-line
  }, [switchChecked]);

  useEffect(() => {
    // toggleDrawer(props.dockAt, props.show);
    if (selectedCategories && selectedCategories.length > 0)
      validateCategories(selectedCategories, true);
    //eslint-disable-next-line
  }, [selectedCategories]);



  const footerContent = (
    <div>
      <Button
        label='OK'
        icon='pi pi-check'
        onClick={() => closeAlert()}
        autoFocus
        style={{ backgroundColor: '0b3edf' }}
      />
    </div>
  );

  const validateCategories = (values: any, isDirect?: boolean) => {
    let error: any = {};

    if (
      !(selectedFilingMaster && selectedFilingMaster.hasOwnProperty('from'))
    ) {
      if (isCategoryChanged || selectedFilingMaster || isDirect) {
        if (isDirect) {
          if (values.length <= 0) {
            setCategoriesErrorText('Business Category is required');
            error.businessCategoryId = 'please select a category';
          } else {
            setCategoriesErrorText('');
          }
        } else {
          if (selectedCategories.length <= 0) {
            setCategoriesErrorText('Business Category is required');
            error.businessCategoryId = 'please select a category';
          } else {
            setCategoriesErrorText('');
          }
        }
      }
    } else {
      setCategoriesErrorText('');
    }

    return error;
  };

  const formik = useFormik({
    initialValues: initForm,
    validationSchema: validationSchema,
    validate: validateCategories,
    validateOnBlur: true,
    validateOnMount: false,

    onSubmit: (values) => {
      submitForm();
    },
  });

  const createDraftDueDates = async (draftId: any) => {
    let payload: any[] = [];
    if (
      selectedFilingMaster === null ||
      (selectedFilingMaster &&
        selectedFilingMaster.hasOwnProperty('from') &&
        selectedFilingMaster.from === 'Add client filing')
    ) {
      payload = filingPeriodTable.map((ele) => {
        let d = new Date(ele.dueDate);
        let date = new Date(
          Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
        );
        // let customerId =
        //   selectedFilingMaster &&
        //     selectedFilingMaster.hasOwnProperty('customerId')
        //     ? selectedFilingMaster.customerId
        //     : null;
        // console.log('@@Fetch createDraftDueDates date', d, date, customerId);
        return {
          draftId,
          startDate: ele.startDate,
          endDate: ele.endDate,
          dueDate: new Date(
            Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
          ),
          businessOperation: 'add',
          // customerId,
        };
      });
    } else {
      filingPeriodTable.forEach((ele) => {
        // console.log(
        //   '@@Fetch createDraftDueDates ele',
        //   ele,
        //   draftId,
        //   ele.dueDate !== ele.initialDueDate
        // );
        let d = new Date(ele.dueDate);
        // let customerId =
        //   selectedFilingMaster &&
        //     selectedFilingMaster.hasOwnProperty('customerId')
        //     ? selectedFilingMaster.customerId
        //     : null;
        // console.log('@@Fetch createDraftDueDates date', d, customerId);
        if (ele.dueDate !== ele.initialDueDate) {
          payload.push({
            draftId,
            startDate: ele.startDate,
            endDate: ele.endDate,
            dueDate: new Date(
              Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
            ),
            businessOperation: 'edit',
            // customerId,
          });
        }
      });
    }
    // console.log('@@Fetch createDraftDueDates payload', payload);
    try {
      const filingPeriodList = await axios.post(
        `${API_BASE_URL}/FilingMaster/CreateFilingMasterDraftPeriods`,
        payload
      );
      // console.log('createDraftDueDates res', filingPeriodList.data);
      // setFilingPeriod(() => [filingPeriodList.data, filingPeriodList.data + 1]);
    } catch (error) {
      console.log('@@Error:', error);
    }
  };

  const fetchFilingPeriod = async () => {
    // console.log('@@Fetch fetchFilingPeriod');
    try {
      const filingPeriodList = await axios.get(
        `${API_BASE_URL}/Customer/GetConfigValue?ConfigItem=CurrentYear`
      );
      // console.log('fetchFilingPeriod res', filingPeriodList.data);
      setFilingPeriod(() => [filingPeriodList.data, filingPeriodList.data + 1]);
    } catch (error) {
      console.log('@@Error:', error);
    }
  };

  const getFilingPeriodTable = async (
    filingFrequency: any,
    filingPeriod: any
  ) => {
    // console.log('@@Fetch getFilingPeriodTable', filingFrequency, filingPeriod);
    try {
      const filingPeriodData = await axios.get(
        `${API_BASE_URL}/Application/FilingFrequencyGenerator?FilingFrequency=${filingFrequency}&Year=${filingPeriod}`
      );
      // console.log('getFilingPeriodTable res', filingPeriodData.data.data);
      let tableData = filingPeriodData.data.data.map((ele: any) => {
        let fp = `${format(new Date(ele.startDate), 'MM/dd/yyyy')} - ${format(
          new Date(ele.endDate),
          'MM/dd/yyyy'
        )}`;
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
        };
      });
      // console.log('getFilingPeriodTable tableData', tableData);
      setFilingPeriodTable(tableData);
    } catch (error) {
      console.log('@@Error:', error);
    }
  };

  const getFilingPeriod = async () => {
    // console.log('@@Fetch getFilingPeriod', selectedFilingMaster?.filingId);
    // console.log('@@Fetch selectedFilingMaster', selectedFilingMaster);
    try {
      const filingPeriodCurrent = await axios.get(
        `${API_BASE_URL}/Customer/GetConfigValue?ConfigItem=CurrentYear`
      );
      const currentYear = filingPeriodCurrent.data;
      // console.log(
      //   'filingPeriodCurrent',
      //   filingPeriodCurrent,
      //   'currentYear',
      //   currentYear
      // );

      const filingPeriodRes = await axios.get(
        `${API_BASE_URL}/FilingMaster/ListFilingMasterPeriods/${selectedFilingMaster?.filingId}`
      );
      // console.log('getFilingPeriod res', filingPeriodRes.data.data);
      let tableData = filingPeriodRes.data.data.map((ele: any) => {
        let fp = `${toLocalTime(ele.startDate)} - ${toLocalTime(ele.endDate)}`;
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
          dueDate: toLocalTime(ele.dueDate),
          initialDueDate: toLocalTime(ele.dueDate),
        };
      });
      let tableData2 = filingPeriodRes.data.data.map((ele: any) => {
        return {
          dueDate: toLocalTime(ele.dueDate),
        };
      });
      // console.log('getFilingPeriod tableData', tableData);
      setFilingPeriodTableChanges(tableData2);

      // const d = new Date(filingPeriod.data.data[0]?.startDate);
      // let year = d.getFullYear();
      const latestYear: any = filingPeriodRes.data.data.reduce(
        (latest: any, ele: any) => {
          const year: any = new Date(ele.startDate).getFullYear();
          return year > latest ? year : latest;
        },
        0
      );
      setIsInitalFilingVal(true);
      // console.log('getFilingPeriod', filingPeriodRes.data.data[0]?.startDate);
      const finalYear = latestYear ? latestYear : currentYear;
      // console.log('finalYear', finalYear, 'latestYear', latestYear);
      tableData = tableData.filter((record: { startDate: string | number | Date; }) => {
        const recordYear = new Date(record.startDate).getFullYear();
        return recordYear === finalYear;
      });
      setFilingPeriodTable(tableData);
      setFilingPeriodVal(finalYear);
    } catch (error) {
      console.log('@@Error:', error);
    }
  };

  useEffect(() => {
    // console.log(
    //   'getFilingPeriodTable',
    //   formik.values.filingFrequency,
    //   filingPeriodVal
    // );
    if (formik.values.filingFrequency !== '' && filingPeriodVal !== '') {
      if (!isInitalFilingVal) {
        getFilingPeriodTable(formik.values.filingFrequency, filingPeriodVal);
      } else {
        setIsInitalFilingVal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.filingFrequency, filingPeriodVal]);

  const cellEditor = (options: any) => {
    let date = options.value ? new Date(options.value) : new Date();
    // console.log('cellEditor', date, options);
    return (
      <Calendar
        value={date}
        onChange={(e) => {
          //changeFilingPeriodTable()
          options.editorCallback(e.target.value);
        }}
        dateFormat='mm/dd/yy'
        placeholder='mm/dd/yyyy'
        mask='99/99/9999'
        readOnlyInput
      />
    );
  };

  const onCellEditComplete = (e: any) => {
    let { rowData, newValue, field, originalEvent: event, rowIndex } = e;
    event.preventDefault();
    // console.log('onCellEditComplete', rowData, field, newValue, e);
    // console.log('onCellEditComplete', typeof newValue);

    if (newValue && typeof newValue === 'object') {
      //rowData[field] = newValue;
      // console.log(
      //   'onCellEditComplete',
      //   newValue && newValue.toJSON().slice(0, 10)
      // );
      let newTableData = filingPeriodTable.map((ele, index) => {
        if (index === rowIndex) {
          ele[field] = toLocalTime(newValue.toJSON());
        }
        return ele;
      });
      // console.log('onCellEditComplete', newTableData);
      setFilingPeriodTable(newTableData);
    } else event.preventDefault();
  };

  const onFileChange = async (event: any) => {
    const file = event.target.files[0];
    // setFiles([...files,file])
    setSelectedFile(file);
  };

  const updateFilePath = async (
    attachmentId: any,
    filePath: any,
    getDraft: any
  ) => {
    // console.log('updateFilePath', attachmentId, filePath);
    // let customerId =
    //   selectedFilingMaster && selectedFilingMaster.hasOwnProperty('customerId')
    //     ? selectedFilingMaster.customerId
    //     : null;
    let payload = {
      attachmentId: attachmentId,
      attachmentPath: decodeURI(filePath),
      businessOperation: 'add',
      updatedDate: new Date(),
      updatedUser: authUser?.userId + '',
      filingId: filingMaster?.filingId ? filingMaster?.filingId + '' : '0',
      draftId: getDraft ? getDraft + '' : '0',
      //customerId,
    };

    const res = await axios.put(
      `${API_BASE_URL}/FilingMaster/UpdateFilingMasterDraftAttachments/${attachmentId}`,
      payload
    );
    // console.log('updateFilePath res', res);
    if (res.data) {
    }
  };

  const fileUploadUtil = async (attachmentId: any, getDraft: any) => {
    // console.log('fileUploadUtil params', attachmentId, getDraft, filingMaster);
    const formData = new FormData();
    formData.append('Files', selectedFile);
    formData.append('draftId', getDraft ? getDraft + '' : '0');
    formData.append(
      'filingId',
      filingMaster?.filingId ? filingMaster?.filingId + '' : '0'
    );

    const res = await axios.post(
      `${API_BASE_URL}/Application/FilingmasterUpload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    // console.log('fileUploadUtil res', res);

    if (res.data) {
      // console.log('fileUploadUtil res header', res.headers, selectedFile);
      await updateFilePath(attachmentId, res.headers['x-file-url'], getDraft);
      setFiles([
        ...files,
        {
          name: selectedFile.name,
          attachmentId: attachmentId,
          attachmentPath: res.headers['x-file-url'],
        },
      ]);
    }
  };

  const getAttachmentId = async (getDraft: any) => {
    // console.log('getAttachmentIt', filingMaster, draftId, getDraft);
    let payload = {
      draftId: getDraft ? getDraft + '' : '0',
      businessOperation: 'add',
    };

    const res = await axios.post(
      `${API_BASE_URL}/FilingMaster/CreateFilingMasterDraftAttachments`,
      payload
    );
    // console.log('getAttachmentIt res', res);
    if (res.data) {
      return res.data.data.attachmentId;
    }
  };

  const uploadandUpdateAttachment = async (getDraft: any) => {
    setIsAttachmentLoading(true)
    try {
      // console.log(
      //   'uploadandUpdateAttachment',
      //   selectedFilingMaster,
      //   filingMaster,
      //   draftId
      // );
      const formData = new FormData();
      formData.append('Files', selectedFile);
      formData.append('draftId', getDraft ? getDraft + '' : '0');
      formData.append(
        'filingId',
        filingMaster?.filingId
          ? filingMaster?.filingId + ''
          : selectedFilingMaster?.filingId
            ? selectedFilingMaster?.filingId + ''
            : '0'
      );
      formData.append('createUser', authUser?.userId + '');
      const res = await axios.post(
        `${API_BASE_URL}/Application/UploadandUpdateAttachment`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      // console.log('uploadandUpdateAttachment res', res);
      if (res.data) {
        setFiles([
          ...files,
          {
            name: selectedFile.name,
            attachmentId: res.data.data[0].attachmentId,
            attachmentPath: res.headers['x-file-url'],
          },
        ]);
      }
    } finally {
      setIsAttachmentLoading(false)
    }
  };

  const onFileUpload = async (event: any) => {
    if (!selectedFile) {
      setAttachmentError(true);
    } else {
      //TO_DO
      //check if file is selected
      //call upload
      // try {
      let getDraft = await getDraftId();
      uploadandUpdateAttachment(getDraft);
      // let attachmentId = await getAttachmentId(getDraft);
      // if (attachmentId) {
      //   setAttachmentError(false);
      // }
      // await fileUploadUtil(attachmentId, getDraft);
      const form = document.getElementById('uploadForm') as HTMLFormElement;
      form.reset();
      setSelectedFile('');
    }
    //  finally {
    //   setIsAttachmentLoading(false);
    // }
    //}
  };

  const getDraftId = async () => {
    let draftExists = localStorage.getItem('DraftId');
    // console.log('getDraftId ', draftExists);
    if (draftExists) {
      setDraftId(draftExists);
      return draftExists;
    } else {
      try {
        const res = await axios.get(
          `${API_BASE_URL}/FilingMaster/CreateFilingMasterDraftId?RecordType=TrackNow&UserId=${authUser?.userId}`
        );
        // console.log('getDraftId res', res.data.data[0]);
        localStorage.setItem('DraftId', res.data.data[0].draftId);
        setDraftId(res.data.data[0].draftId);
        return res.data.data[0].draftId;
      } catch (error) {
        console.log('@@Error getDraftId :', error);
      }
    }
  };
  const geClientDraftId = async () => {
    console.log("geClientDraftId selectedAfftCom", selectedAffectedCompanies);
    //Payload Newling adding compoanies through add form 
    const payloadDraftIds = selectedAffectedCompanies.map((item: any) => {
      return {
        customerId: item,
        userId: authUser?.userId,
        recordType: "TrackNow",
      }
    })

    if (newlyAddingAffectedCompanies.length > 0) {
      // Payload Newly adding companies through edit form 

      const payloadDraftIdsNewlyAdding = newlyAddingAffectedCompanies.map((item: any) => {
        return {
          customerId: item,
          userId: authUser?.userId,
          recordType: "TrackNow",
        }
      })
      payloadDraftIds.push(...payloadDraftIdsNewlyAdding)
      console.log("geClientDraftId PAYLOAD", "payloadDraftIds", payloadDraftIds);

    }
    console.log("geClientDraftId PAYLOAD", "payloadDraftIds", payloadDraftIds);

    try {
      const res = await axios.post(
        `${API_BASE_URL}/FilingMaster/ClientCreateFilingMasterDraftId`,
        payloadDraftIds
      );
      console.log("geClientDraftId RES", res);

      return res.data.data
    } catch (error) {
      console.error("geClientDraftId error", error);

    }
  };



  const onFinalSubmit = () => {
    if (selectedFilingMaster?.from === "Add client filing") {
      setAlertMessage(
        <Typography>
          Client specific filing <strong>{formik.values.filingName} </strong> is successfully created
        </Typography>
      );
      setIsFinalSubmit(true);
      setShowAlert(true);
      setAttachmentError(false);
    } else if (selectedFilingMaster?.from === "Edit client filing") {
      setAlertMessage(
        <Typography>
          Client specific filing <strong>{formik.values.filingName} </strong> is successfully updated
        </Typography>
      );
      setIsFinalSubmit(true);
      setShowAlert(true);
      setAttachmentError(false);
    }
    else {
      setAlertMessage(
        <Typography>
          Successfully submitted <strong>{formik.values.filingName} </strong> workflow for approval
        </Typography>
      );
      setIsFinalSubmit(true);
      setShowAlert(true);
      setAttachmentError(false);
      // backToHome();
    }

    return;
  };

  useEffect(() => {
    if (formik.values.juristiction === 'Federal') {
      formik.setFieldValue('stateInfo', '');
    }
  }, [formik.values.juristiction, formik.setFieldValue]);

  const toLocalTime = (date: Date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("MM/dd/yyyy");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const toLocalTime2 = (date: Date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat('dd-MMM-yyyy hh:mm:ss'),
        'dd-MMM-yyyy hh:mm:ss',
        { zone: 'UTC' }
      )
        .toLocal()
        .toFormat('dd/mm/yyyy');
      // console.log('@@@ LocalllTime:', _date);

      return _date;
    } else return '';
  };

  const handleSubmitCustom = (
    e?: React.FormEvent<HTMLFormElement> | undefined
  ) => {
    // console.log('@@@ Before Submit:');
    setIsCategoryChanged(true);
    validateCategories(selectedCategories, true);
    formik.handleSubmit(e);
  };
  useEffect(() => {
    if (authUser && authUser?.userId) {
      // fetchNotificationCount()
    };
  }, [authUser]);
  useEffect(() => {
    if (formTitle === 'EDIT FILING MASTER RECORD' || actionLabel === 'SAVE') {
      try {
        if (selectedFilingMaster) {
          let isEqual = true;
          let isAttachmentChanges = false;
          let isFilesAdded = false;
          let isTableChange = false;

          type FilingType = {
            filingName: string | undefined;
            ruleInfo: string | null | undefined;
            jsicontactName: string | undefined;
            whereToFile: string | undefined;
            notes: string | undefined;
            required: boolean | undefined;
            businessCategory: number[] | undefined;
          };
          let requiredField: boolean | undefined = false
          if (selectedFilingMaster) {
            if ('filingRequired' in selectedFilingMaster) {
              requiredField = selectedFilingMaster.filingRequired !== undefined ? !!selectedFilingMaster.filingRequired : false;
            } else {
              requiredField = selectedFilingMaster.required !== undefined ? !!selectedFilingMaster.required : false;
            }
          }
          const selectedFiling: FilingType = {
            filingName: selectedFilingMaster.filingName
              ? selectedFilingMaster.filingName
              : '',
            ruleInfo: selectedFilingMaster.ruleInfo
              ? selectedFilingMaster.ruleInfo
              : '',
            jsicontactName: selectedFilingMaster.jsicontactName
              ? selectedFilingMaster.jsicontactName
              : '',
            whereToFile: selectedFilingMaster.whereToFile
              ? selectedFilingMaster.whereToFile
              : '',
            notes: selectedFilingMaster.notes ? selectedFilingMaster.notes : '',
            required: requiredField,
            businessCategory: (selectedFilingMaster?.businessCategory ?? [])
              .map((category) => category.businessCategoryId)
              .filter((id) => id !== undefined) as number[],
          };

          const formValues: FilingType = {
            filingName: formik.values?.filingName,
            ruleInfo: formik.values?.ruleInfo,
            jsicontactName: formik.values?.jsicontactName,
            whereToFile: formik.values?.whereToFile,
            notes: formik.values?.notes,
            required: switchChecked,
            businessCategory: selectedCategories.map((category) =>
              Number(category)
            ),
          };
          //Field check
          for (const key in selectedFiling) {
            // console.log('keycheck', key);
            if (key === 'businessCategory') {
              const areBusinessCategoriesEqual =
                JSON.stringify(selectedFiling.businessCategory) ===
                JSON.stringify(formValues.businessCategory);
              isEqual = areBusinessCategoriesEqual;
              // console.log(
              //   'areBusinessCategoriesEqual',
              //   areBusinessCategoriesEqual,
              //   'isEqual',
              //   isEqual
              // );
            } else {
              // console.log('keycheck2', key);
              if (
                selectedFiling[key as keyof FilingType] !==
                formValues[key as keyof FilingType]
              ) {
                isEqual = false;
                break;
              }
            }
          }
          //Existing Attachment check
          for (const attachment of attachmentChange) {
            const isAttachmentPresent = attachments.some(
              (existingAttachment) =>
                existingAttachment.attachmentId === attachment.attachmentId
            );
            if (!isAttachmentPresent) {
              isAttachmentChanges = true;
              break;
            }
          }

          //New Files check
          if (files.length > 0) {
            isFilesAdded = true;
          }
          //DueDate check
          for (const periodChange of filingPeriodTableChanges) {
            let periodMatch = false;

            for (const period of filingPeriodTable) {
              if (period.dueDate === periodChange.dueDate) {
                periodMatch = true;
                break;
              }
            }

            if (!periodMatch) {
              isTableChange = true;
              break;
            }
          }
          // console.log(
          //   'selectedFiling',
          //   selectedFiling,
          //   'formValues',
          //   formValues,
          //   'equal',
          //   isEqual,
          //   'isFileAdded',
          //   isFilesAdded,
          //   'isAttachmentChanges',
          //   isAttachmentChanges
          // );
          // console.log(
          //   'equalCheck-IsEqual',
          //   isEqual,
          //   'isFileAdded',
          //   isFilesAdded,
          //   'isAttachmentChanges',
          //   isAttachmentChanges,
          //   'isTableChange',
          //   isTableChange
          // );
          // console.log(
          //   'filingTab1',
          //   filingPeriodTable,
          //   'filingTab2',
          //   filingPeriodTableChanges,
          //   'isTableChange',
          //   isTableChange
          // );

          setIsAttachmentChanges(isAttachmentChanges);
          setIsFileAdded(isFilesAdded);
          setIsEqual(isEqual);
          setIsFilingPeriodTableChanges(isTableChange);
          // console.log('isEqual', isEqual);
        }
      } catch (error) {
        console.error('Checkerr', error);
      }
    }
  }, [
    formik.values,
    switchChecked,
    attachments,
    files,
    selectedCategories,
    filingPeriodTable,
  ]);

  console.log("selectedFilingMaster", selectedFilingMaster, "formik.values", formik.values, "switchChecked", switchChecked);

  useEffect(() => {

    console.log("formik values", formik.values, "customerNames", customerNames, "filingMaster", filingMaster, "selectedAffectedCompanies", selectedAffectedCompanies, "selectedFilingMaster", selectedFilingMaster);
    if (selectedFilingMaster &&
      selectedFilingMaster.hasOwnProperty("from") &&
      (selectedFilingMaster.from === "Add client filing" || selectedFilingMaster.from === "Edit client filing")) {
      //  const combainedCustomers: string[] = [{ customerId: formik.values.customerId, customerName: formik.values.client }, ...customerNames]
      // console.log(
      //   "combainedCustomers", combainedCustomers
      // );
      setCustomerList(customerNames)
    }
  }, [selectedFilingMaster])

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* {showAlert && (
        <Alert message={alertMessage?.message} type={alertMessage?.type} />
      )} */}
      <Dialog
        header='Info'
        visible={showAlert}
        style={{ width: '30vw' }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className='m-0'>{alertMessage}</p>
      </Dialog>
      <Dialog
        header='Delete Attachment'
        visible={showDeleteAlert}
        style={{ width: '30vw' }}
        onHide={() => closeDeleteAlert()}
        footer={footerContentDelete}
      >
        <p className='m-0'> Do you want to delete attachment?</p>
      </Dialog>
      <CssBaseline />
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        spacing={2}
        style={{ margin: '1rem', width: '99%' }}
      >
        <AppBar
          position='relative'
          color='inherit'
          sx={{ border: 'none', width: '99%' }}
        >
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={2}
          >
            <IconButton
              className='nav-bar__logo__img'
              sx={{ display: { xs: 'none', md: 'flex' } }}
              onClick={backToHome}
            >
              <Box sx={{ m: 2 }}>
                <ArrowBackIosNewIcon fontSize='medium' sx={{ mt: 1 }} />
              </Box>
            </IconButton>
            <Box sx={{ m: 2 }}>
              <Typography variant='h6' color='inherit' noWrap>
                {formTitle}
              </Typography>
            </Box>
          </Stack>
        </AppBar>
        <Container component='main' sx={{ mb: 4, width: '99%' }}>
          <React.Fragment>
            {isLoading ? (
              <Loader />
            ) : (
              <form onSubmit={handleSubmitCustom} noValidate>
                <Grid container spacing={3} sx={{ mt: 3 }}>
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Filing Name</Typography>
                    <TextField
                      required
                      id='filingName'
                      name='filingName'
                      label='Enter Name'
                      placeholder='Max-Length 250 characters'
                      fullWidth
                      autoComplete='filingName'
                      variant='filled'
                      value={formik.values.filingName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.filingName &&
                        Boolean(formik.errors.filingName)
                      }
                      helperText={
                        formik.touched.filingName && formik.errors.filingName
                      }
                    />
                  </Grid>
                  {/* <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}> Filing Description</Typography>
                  <TextField
                    required
                    id="filingDescription"
                    name="filingDescription"
                    label="Filing Description"
                    fullWidth
                    variant="filled"
                    value={formik.values.filingDescription}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.filingDescription &&
                      Boolean(formik.errors.filingDescription)
                    }
                    helperText={
                      formik.touched.filingDescription &&
                      formik.errors.filingDescription
                    }
                  />
                </Grid> */}
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Filing Frequency</Typography>
                    <FormControl fullWidth variant='filled' required>
                      <InputLabel id='freq-label'>Filing Frequency</InputLabel>
                      <Select
                        labelId='freq-label'
                        id='filingFrequency'
                        name='filingFrequency'
                        disabled={
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            !selectedFilingMaster.hasOwnProperty('from')) ||
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            selectedFilingMaster.from === 'Edit client filing')
                        }
                        value={formik.values.filingFrequency}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.filingFrequency &&
                          Boolean(formik.errors.filingFrequency)
                        }
                      >
                        {filingFrequencyLookup.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {formik.touched.filingFrequency &&
                          formik.errors.filingFrequency}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}>
                    {" "}
                    Due Date Offset (In Days)
                  </Typography>
                  <TextField
                    id='dueDayofFrequency'
                    name='dueDayofFrequency'
                    label='Due Date Offset'
                    fullWidth
                    autoComplete='DueDateOffset'
                    variant='filled'
                    type='number'
                    value={formik.values.dueDayofFrequency}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dueDayofFrequency &&
                      Boolean(formik.errors.dueDayofFrequency)
                    }
                  />
                </Grid> */}
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Rule Info</Typography>
                    <TextField
                      id='ruleInfo'
                      name='ruleInfo'
                      label='Enter Rule Info'
                      fullWidth
                      autoComplete='ruleInfo'
                      variant='filled'
                      value={formik.values.ruleInfo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.ruleInfo &&
                        Boolean(formik.errors.ruleInfo)
                      }
                    />
                  </Grid>

                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Jurisdiction</Typography>
                    <FormControl fullWidth variant='filled' required>
                      <InputLabel id='jurisdiction-label'>
                        Select Jurisdiction
                      </InputLabel>
                      <Select
                        labelId='jurisdiction-label'
                        id='juristiction'
                        disabled={
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            !selectedFilingMaster.hasOwnProperty('from')) ||
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            selectedFilingMaster.from === 'Edit client filing')
                        }
                        name='juristiction'
                        label='Jurisdiction'
                        value={formik.values.juristiction}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.juristiction &&
                          Boolean(formik.errors.juristiction)
                        }
                      >
                        {juristictionLookup.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {formik.touched.juristiction &&
                          formik.errors.juristiction}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> State</Typography>
                    <FormControl fullWidth variant='filled'>
                      <InputLabel id='state-label'>State</InputLabel>
                      <Select
                        labelId='state-label'
                        id='stateInfo'
                        name='stateInfo'
                        value={formik.values.stateInfo}
                        onChange={formik.handleChange}
                        disabled={
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            !selectedFilingMaster.hasOwnProperty('from')) ||
                          formik.values.juristiction === 'Federal'
                        }
                        error={
                          formik.touched.stateInfo &&
                          Boolean(formik.errors.stateInfo)
                        }
                      >
                        {states.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.state}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {formik.touched.stateInfo && formik.errors.stateInfo}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}>
                      Business Categories
                      {selectedFilingMaster
                        ? ''
                        : '*'}
                    </Typography>
                    <FormControl fullWidth variant='filled' required>
                      {/* <InputLabel id='jurisdiction-label'>
                      Business categories
                    </InputLabel> */}
                      <MultiSelect
                        label='Filing Categories'
                        inputs={categories.map((item) => ({
                          name: item.businessCategoryName
                            ? item.businessCategoryName
                            : '',
                          value: item.businessCategoryId
                            ? item.businessCategoryId + ''
                            : '',
                        }))}
                        values={selectedCategories}
                        initValues={
                          selectedCategories
                            ? selectedCategories
                            : initCategories
                        }
                        updateSelection={handleCategoryChanges}
                        required={true}
                        helperText={categoriesErrorText}
                      />
                      <FormHelperText
                        error={Boolean(
                          formik.touched.businessCategory &&
                          formik.errors.businessCategory
                        )}
                      >
                        {formik.touched.businessCategory &&
                          formik.errors.businessCategory}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={6} lg={4}>
                    <FormControl fullWidth variant='filled'>
                      <Typography sx={fontStyle}> Filing Mandatory?</Typography>
                      <Switch
                        id='required'
                        name='required'
                        checked={switchChecked}
                        onChange={() => {
                          setSwitchChecked(!switchChecked);
                        }}
                      // onChange={handleManadatoryChange}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid xs={12}>
                  <h4>JSI CONTACT PERSON'S DETAILS</h4>
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}> JSI Dept</Typography>
                  <FormControl fullWidth variant="filled">
                    <InputLabel id="dept-label">Select JSI Dept</InputLabel>
                    <Select
                      labelId="dept-label"
                      id="jsidept"
                      name="jsidept"
                      value={formik.values.jsidept}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.jsidept && Boolean(formik.errors.jsidept)
                      }
                    >
                      {deptLookup.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> JSI Staff</Typography>
                    <TextField
                      id='jsicontactName'
                      name='jsicontactName'
                      label='Enter JSI Staff Name'
                      fullWidth
                      autoComplete='jsicontactName'
                      variant='filled'
                      value={formik.values.jsicontactName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.jsicontactName &&
                        Boolean(formik.errors.jsicontactName)
                      }
                    />
                  </Grid>
                  {/* <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}> JSI Contact Email</Typography>
                  <TextField
                    id="jsicontactEmail"
                    name="jsicontactEmail"
                    label="Enter JSI Contact Email"
                    fullWidth
                    autoComplete="jsicontactEmail"
                    variant="filled"
                    value={formik.values.jsicontactEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.jsicontactEmail &&
                      Boolean(formik.errors.jsicontactEmail)
                    }
                    helperText={
                      formik.touched.jsicontactEmail &&
                      formik.errors.jsicontactEmail
                    }
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <Typography sx={fontStyle}> JSI Contact No</Typography>
                  <TextField
                    id="jsicontactNumber"
                    name="jsicontactNumber"
                    label="Enter JSI Contact No"
                    fullWidth
                    autoComplete="jsicontactNumber"
                    variant="filled"
                    value={formik.values.jsicontactNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.jsicontactNumber &&
                      Boolean(formik.errors.jsicontactNumber)
                    }
                  />
                </Grid> */}
                  <Grid xs={12} sm={6} lg={4}>
                    <Typography sx={fontStyle}> Filing Period</Typography>
                    <FormControl fullWidth variant='filled' required>
                      <InputLabel id='filingPeriod-label'>
                        Select Filing Period
                      </InputLabel>
                      <Select
                        labelId='filingPeriod-label'
                        id='filingPeriod'
                        name='filingPeriod'
                        label='filingPeriod'
                        value={filingPeriodVal}
                        onChange={(e) => setFilingPeriodVal(e.target.value)}
                        disabled={
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            !selectedFilingMaster.hasOwnProperty('from')) ||
                          (selectedFilingMaster &&
                            selectedFilingMaster !== null &&
                            selectedFilingMaster.from === 'Edit client filing')
                        }
                      >
                        {filingPeriod.map((item, index) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography
                        sx={{
                          fontSize: '0.75rem',
                          fontWeight: 'small',
                          letterSpacing: '0.03em',
                          color: '#d32f2f',
                          display: filingPeriodValError ? 'block' : 'none',
                          margin: '3px 0 3px 14px',
                        }}
                      >
                        {filingPeriodValError}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} sm={12} lg={12}>
                    <Typography sx={fontStyle}> Where to file</Typography>
                    <TextField
                      id='whereToFile'
                      name='whereToFile'
                      label='Where to file'
                      placeholder='Max-Length 500 characters'
                      fullWidth
                      multiline
                      minRows={2}
                      variant='filled'
                      autoComplete='whereToFile'
                      value={formik.values.whereToFile}
                      onChange={(e) => {
                        if (e.target.value.length <= 500) {
                          formik.handleChange(e);
                          setWherToRemainingChars(500 - e.target.value.length);
                        }
                      }}
                      error={
                        formik.touched.whereToFile &&
                        Boolean(formik.errors.whereToFile)
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: '11px',
                        fontWeight: 'small',
                        color:
                          whereToFileRemainingChars === 0 ? 'red' : 'inherit',
                        display:
                          whereToFileRemainingChars === 500 ? 'none' : 'block',
                      }}
                    >
                      {whereToFileRemainingChars} Characters are left
                    </Typography>
                  </Grid>
                  {selectedCustomer?.parentCustomer === null && !selectedCustomer?.isOnlyParentFile && (
                    <Grid xs={12} sm={6} lg={4}>
                      {/* <Typography sx={fontStyle}> Client Name</Typography>
                      <TextField
                        id='client'
                        name='Client'
                        label='Client Name'
                        fullWidth
                        variant='filled'
                        value={formik.values.client}
                        onChange={formik.handleChange}
                        disabled
                      /> */}
                      <List >
                        <ListItemButton sx={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} onClick={handleClick}>
                          <ListItemText primary="Affected Companies" />


                          {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding sx={{ minHeight: "fit-content", maxHeight: "300px", overflow: 'auto' }}>
                            {customerList && customerList.map((item: any, index: any) => (
                              <ListItemButton key={index} sx={{ pl: 4 }}>
                                <ListItemText>{item.customerName}</ListItemText>
                              </ListItemButton>
                            ))}
                          </List>
                        </Collapse>
                      </List>

                    </Grid>
                  )}
                  <Grid xs={12} sm={12} lg={12}>
                    <Typography sx={fontStyle}> Notes</Typography>
                    <TextField
                      id='notes'
                      name='notes'
                      label='Notes'
                      placeholder='Max-Length 2000 characters'
                      multiline
                      fullWidth
                      minRows={3}
                      variant='filled'
                      value={formik.values.notes}
                      onChange={(e) => {
                        if (e.target.value.length <= 2000) {
                          formik.handleChange(e);
                          setRemainingChars(2000 - e.target.value.length);
                        }
                      }}
                      error={
                        formik.touched.notes && Boolean(formik.errors.notes)
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: '11px',
                        fontWeight: 'small',
                        color: remainingChars === 0 ? 'red' : 'inherit',
                        display: remainingChars === 2000 ? 'none' : 'block',
                      }}
                    >
                      {remainingChars} Characters are left
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} lg={12} className='filingPeriodTable'>
                    <DataTable
                      value={filingPeriodTable}
                      editMode='cell'
                      tableStyle={{ height: 'max-content' }}
                    >
                      <Column
                        field='filingPeriod'
                        header='Filing Period'
                      ></Column>
                      <Column
                        field='dueDate'
                        header='DueDate'
                        style={{ cursor: 'pointer' }}
                        editor={(options) => cellEditor(options)}
                        onCellEditComplete={onCellEditComplete}
                      ></Column>
                    </DataTable>
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                        fontWeight: 'small',
                        letterSpacing: '0.03em',
                        color: '#d32f2f',
                        display: filingPeriodTableError ? 'block' : 'none',
                        margin: '3px 0 3px 14px',
                      }}
                    >
                      {filingPeriodTableError}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {/* <MuiButton
                      variant="text"
                      style={{ color: "blue" }}
                      sx={{ margin: 1, padding: 1 }}
                      onClick={backToHome}
                    >
                      CANCEL
                    </MuiButton>
                    <MuiButton
                      variant="contained"
                      // color="primary"
                      type="submit"
                      // disabled={!formIsValid}
                      sx={{ margin: 1, padding: 1, backgroundColor: "0b3edf" }}
                      disabled={draftSubmitted}
                    >
                      {actionLabel}
                    </MuiButton> */}
                    {/* <LoadingButton
                    variant="contained"
                    style={{ backgroundColor: "blue", width: "100px" }}
                    // onClick={submitForm}
                    loading={filingMasterLoading}
                    // disabled={!formIsValid}
                    sx={{ margin: 1, padding: 1 }}
                    loadingIndicator={
                      <CircularProgress color="inherit" size={16} />
                    }
                  >
                    ADD
                  </LoadingButton> */}
                  </Grid>
                  <Grid xs={12}>
                    <h4>Reference Documents</h4>
                  </Grid>
                  <Grid xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '40%',
                      }}
                    >
                      <div>
                        <form
                          method='post'
                          id='uploadForm'
                          onChange={(event) => onFileChange(event)}
                        >
                          <div className='field'>
                            <label className='file-upload'>
                              {/* <span>
                            <strong>Add File</strong>
                          </span> */}
                              {/* <input
                              type="file"
                              name="file"
                              id="inFile"
                              disabled={!draftSubmitted}
                            /> */}
                              <TextField
                                type='file'
                                name='file'
                                id='inFile'
                                //  disabled={!draftSubmitted}
                                InputProps={{ disableUnderline: true }}
                                helperText={
                                  attachmentError
                                    ? 'No attachments attached'
                                    : ''
                                }
                                error={attachmentError}
                              />
                            </label>
                          </div>
                        </form>
                      </div>
                      <span
                        className='file-upload-status'
                        style={{ opacity: 1 }}
                        id='upload-status'
                      ></span>
                      <div id='fileInfo'></div>
                      <MuiButton
                        variant='contained'
                        color='primary'
                        // disabled={!formIsValid}
                        sx={{
                          margin: 1,
                          padding: 1,
                          alignSelf: 'center',
                          backgroundColor: '#0b3edf',
                        }}
                        onClick={onFileUpload}
                      //disabled={!draftSubmitted}
                      >
                        Attach File
                      </MuiButton>
                    </div>
                  </Grid>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignContent: 'center',
                      alignItems: 'center',
                      width: '40%',
                    }}
                  >
                    <List style={{ width: '100%' }}>
                      {attachments &&
                        attachments
                          .slice()
                          .reverse()
                          .map((item, index) => (
                            <Fragment key={index}>
                              <ListItem
                                disablePadding
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <ListItemIcon>
                                    <Link>
                                      <AttachFileIcon />
                                    </Link>
                                  </ListItemIcon>
                                  <ListItemButton
                                    onClick={() =>
                                      downloadFile(
                                        item.attachmentPath
                                          ? item.attachmentPath
                                          : '',
                                        item.attachmentId
                                      )
                                    }
                                  >
                                    <ListItemText
                                      primary={
                                        item &&
                                        item.attachmentPath &&
                                        getFilename(
                                          item.attachmentPath
                                            ? item.attachmentPath
                                            : ''
                                        )
                                      }
                                    />
                                  </ListItemButton>
                                </div>
                                <IconButton>
                                  <DeleteIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      handleAttachmentDelete(
                                        item.attachmentId,
                                        item.attachmentPath,
                                        item.draftId,
                                        'attachment'
                                      );
                                    }}
                                  />
                                </IconButton>
                              </ListItem>
                            </Fragment>
                          ))}
                    </List>
                  </Grid>
                  <Grid xs={10}>
                    <div style={{ marginLeft: '60px' }}>
                      {isAttachmentLoading ? <CircularProgress /> : null}
                    </div>
                  </Grid>
                  <Grid
                    xs={10}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {files.length > 0 && (
                      <div style={{ width: '50%' }}>
                        <UploadedFilesTable
                          files={files}
                          handleFileAttachmentDelete={(
                            attachmentId: any,
                            attachmentPath: any
                          ) =>
                            handleAttachmentDelete(
                              attachmentId,
                              attachmentPath,
                              draftId,
                              'uploaded'
                            )
                          }
                        />
                      </div>
                    )}
                  </Grid>

                  <Grid
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <MuiButton
                      variant='text'
                      style={{ color: 'blue' }}
                      sx={{ margin: 1, padding: 1 }}
                      onClick={backToHome}
                    >
                      CANCEL
                    </MuiButton>
                    <MuiButton
                      variant='contained'
                      // color="primary"
                      type='submit'
                      // disabled={!formIsValid}
                      sx={{ margin: 1, padding: 1, backgroundColor: '0b3edf' }}
                      disabled={
                        formTitle === 'EDIT FILING MASTER RECORD' ||
                          actionLabel === 'SAVE'
                          ? isEqual &&
                          !isFilesAdded &&
                          !isAttachmentChanges &&
                          !draftSubmitted &&
                          !isFfilingPeriodTableChanges &&
                          newlyAddingAffectedCompanies.length === 0
                          : draftSubmitted
                      }
                    >
                      {/* {actionLabel} */}
                      SUBMIT & RETURN
                    </MuiButton>
                  </Grid>
                </Grid>
              </form>
            )}
            <style>
              {`.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        cursor : pointer;
        {

      `}
            </style>
          </React.Fragment>
        </Container>
      </Stack>
    </ThemeProvider >
  );
}
