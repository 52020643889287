import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { Dialog } from "primereact/dialog";
import SearchIcon from "@mui/icons-material/Search";
import { Button as PrimeButton } from "primereact/button";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import useDialog from "../common/UseDialog";
import Loader from "../common/Loader";
import { CircularProgress } from "@mui/material";

const ProgramNamesList = (props) => {
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert1, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

  const postAwardProgram = useContext(FilingMasterContext);
  const {
    getPostProgram,
    deletePostProgram,
    filingMasterLoading,
    postProgram,
  } = postAwardProgram;
  useEffect(() => {
    if (props.isTableLoading) {
      console.log("props-loading", props.isTableLoading);
    }
  }, [props.isTableLoading]);

  const handleSubmit = () => {
    console.log("Submitting Delete....", selectedRowForDelete);
    deleteCategory();
  };

  const closeConfirm = () => {
    console.log("Cancelling....");
    setSelectedRowForDelete(null);
  };
  const
    { AlertDialog:
      ConfirmDialog,
      showDialog,
      closeDialog
    } = useDialog({
      onSubmit: handleSubmit,
      onClose: closeConfirm,
    });

  const { AlertDialog, showDialog: showAlert } = useDialog({});

  const fetchPostProgram = async () => {
    setIsLoading(true);
    console.log("@@Fetch PostProgram:", getPostProgram);
    try {
      if (getPostProgram) {
        console.log("@@Fetch PostProgram:");
        try {
          const catList = await getPostProgram();
          console.log("@@PostProgram:", catList);
          // setRows(catList);
        } catch (error) {
          console.log("@@Error:", error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPostProgram();
  }, []);



  useEffect(() => {
    if (postProgram) {
      console.log("postProgram#", postProgram);
      setRows(postProgram);
    }
  }, [postProgram]);

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    props.selectRowForEdit(selectedRow);
  };

  const handleDelete = async () => {
    console.log("SHOW DIALOG", selectedRow);
    setSelectedRowForDelete(selectedRow);
    showDialog(
      "Confirmation",
      <Typography>
       Are you sure to delete the Program Name: {" "}
      <Typography fontWeight="bold" component="span">
      {selectedRow.programName}?
      </Typography> {" "}
      </Typography>,
      "confirm"
    );
    handleMenuClose();
  };

  const deleteCategory = async () => {
    console.log("SHOW DIALOG2", selectedRow);
    console.log("selectedRowForDelete3", selectedRowForDelete);

    setIsLoading(true);
    props.formReset();
    try {
      const res = await deletePostProgram(selectedRowForDelete);
      console.log("res", res, "sel", selectedRowForDelete);
      setAlertMessage(
        <Typography>
        Successfully deleted the Program Name: {" "}
        <Typography fontWeight="bold" component="span">
        {selectedRowForDelete.programName}
        </Typography>
        </Typography>
      );
      setShowAlert(true);
      closeDialog();
    }
    catch (error) {
      showAlert("Error1", `${error?.errorMessage}`, "error");
      console.log("error", error?.errorMessage);

      closeDialog();
    }
    finally {
      setIsLoading(false);
    }
    setSelectedRowForDelete(null);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );
  const closeAlert = () => {
    setShowAlert(false);
  };

  const filteredRows = rows.filter((row) => {
    return (
      row.programName
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      row.programId.toString().includes(searchText)
    );
  });

  return (
    <>
      <Dialog
        header="Info"
        visible={showAlert1}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <TextField
        label="Search"
        value={searchText}
        onChange={handleSearch}
        fullWidth
        margin="normal"
        sx={{
          width: "60vw",
          marginLeft: "1.2rem",
          background: "rgba(0, 0, 0, 0.06)",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Paper
        sx={{
          width: "60vw",
          marginLeft: "1.2rem",
          height: "60vh",
          overflowY: "auto",
        }}
      >
        <TableContainer sx={{ m: "1.2rem", width: "90%" }}>
          <Table stickyHeader style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: "large", fontWeight: "550" }}
                >
                  Program Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: "large", fontWeight: "550" }}
                >
                  Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading || props.isTableLoading ? (
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell
                    colSpan={2}
                    style={{ textAlign: "center", borderBottom: "none" }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                filteredRows.map((row) => (
                  <TableRow
                    key={row.programId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.programName}
                    </TableCell>
                    <TableCell
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      {row.programDescription}
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuClick(event, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      <ConfirmDialog />
      <AlertDialog />
    </>
  );
};

export default ProgramNamesList;
