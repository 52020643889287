import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
// import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// import MuiButton from "@mui/material/Button";
// import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ShortTextIcon from "@mui/icons-material/ShortText";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AuthContext from "../../context/auth/AuthContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
// import { format } from "date-fns";
import Alert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";
//import IconButton from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Tooltip,
} from "@mui/material";
// import { DateTime } from "luxon";
// import Collapse from "@mui/material/Collapse";
// import Input from "@mui/material/Input";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Card,
  // CardActions,
  CardContent,
  // CardMedia,
  Grid,
  Stack,
  // TextField,
  Typography,
} from "@mui/material";
import { FilingMaster, FilingMasterComment } from "../../types/FilingMaster";
// import axios from "axios";
// import { Customer } from "../../types/customer";
// import { log } from "console";
// const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
type Anchor = "top" | "left" | "bottom" | "right";

export default function AssociatedClient(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  draft?: FilingMaster;
  clients: any[];
}) {
  const filingMasterContext = useContext(FilingMasterContext);
  const { getFilingMasterComments, addFilingMasterComment } =
    filingMasterContext;
  const formRef = useRef<HTMLFormElement>(null);
  const [data, setData] = React.useState<any[]>([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const authContext = useContext(AuthContext);
  const { authUser } = authContext;
  const [approvalComment, setApprovalComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [remainingChars, setRemainingChars] = useState(256);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
    //  navigate("/filing-master-list");
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      // console.log(`Drawer Mouse Key Event...`);
    };

  useEffect(() => {
    setLoading(true);

    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      // console.log(
      //   `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      // );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
    }
    setApprovalComment((old) => "");

    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    setLoading(true);
    if (props.clients) {
      try {
        setData(() => props.clients);
      } finally {
        setLoading(false);
      }
    }

    //eslint-disable-next-line
  }, [props.clients]);

  const handleCancel = async (anchor: any) => {
    setState({ ...state, [anchor]: false });
    setData(() => []);
    props.handleClose();
  };

  const list = (anchor: string) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          // onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent>
              <Stack direction="column" spacing={2}>
                <div
                  style={{
                    width: "30vw",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem",
                  }}
                >
                  Associated Clients
                  <IconButton>
                    <CloseIcon onClick={() => handleCancel(anchor)} />
                  </IconButton>
                </div>
              </Stack>
              <CardContent>
                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <Divider />

              {data.length === 0 ? (
                loading ? (
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                      }}
                    >
                      <CircularProgress size={40} />
                    </div>
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography variant="body1" color="textSecondary">
                      No associated clients
                    </Typography>
                  </CardContent>
                )
              ) : (
                <TableContainer
                  sx={{
                    maxHeight: 240,
                    marginTop: "10px",
                    overflow: "auto",
                    width: "93%",
                  }}
                >
                  <Container>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              borderBottom: "none",
                              textAlign: "left",
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#424242",
                            }}
                          >
                            Client name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((customer, index) => (
                          <TableRow
                            key={customer.customerId}
                            hover
                            role="checkbox"
                            sx={{ cursor: "pointer" }}
                            tabIndex={-1}
                          >
                            <TableCell
                              style={{
                                borderBottom: "none",
                                textAlign: "left",
                                fontSize: "12px",
                                color: "#424242",
                                fontWeight: "normal",

                                opacity: 1,
                              }}
                            >
                              {customer.customerName}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Container>
                </TableContainer>
              )}
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
          // <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
