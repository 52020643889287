import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useMsal,
  useIsAuthenticated,
  useAccount,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { callMsGraph } from "./../../graph";
import { loginRequest } from "./../../authConfig";
import { InteractionStatus } from "@azure/msal-browser";
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import JSI from "./JSI.png";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import personIcon from "./person_outline.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Button as PrimeButton } from "primereact/button";
import { Link } from "react-router-dom";
import MainMenu from "./MainMenu";
import AuthContext from "../../context/auth/AuthContext";
import { Dialog } from "primereact/dialog";
import { IUser } from "../../types/Auth";
import { Badge, Stack } from "@mui/material";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";

const pages = ["Notifications", "Logout"];
interface UserInfo {
  userPrincipalName: string;
}
function NavBar() {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const authContext = useContext(AuthContext);
  // const { inProgress } = useMsal();
  // const isAuthenticated = useIsAuthenticated();
  const {
    setAuthorized,
    authUser,
    setAuthStatus,
    setAuthUser,
    getUserRoles,
    setPermission,
    //isAuthenticated,
    setNotificationCount,
    notificationCount,
    getCustomerNotifications,
    getFilingNotifications,
    getCustomerNotificationsWorkFlow,
    isPortalAuthenticated,
    portalUser,
    logout,
  } = authContext;
  const navigate = useNavigate();
  // const { instance, accounts } = useMsal();
  //const [graphData, setGraphData] = useState<UserInfo | null>(null);

  const handleLogout = (e?: any) => {
    if (e) e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios.get("/logout", config).then((response) => {
      // console.log("LOGGED OUT", response);
      if (logout) {
        logout();
        navigate("/");
      }
    });
    localStorage.removeItem("isAuthenticated");
    // logout();
  };

  const handleLogin = (e: any) => {
    window.location.href = "/login";
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
    // axios.get("/logout", config).then((response) => {
    //   console.log("APPS", response);
    //   history.push("/");
    // });
    // login();
  };

  const fetchUserRole = async () => {
    if (getUserRoles) await getUserRoles();
  };

  useEffect(() => {
    // console.log("Init Base URL:", process.env.REACT_APP_TRKNW_API_BASE_URL);
    fetchUserRole();
  }, []);

  useEffect(() => {
    if (authUser && authUser.loginId) {
      setName(authUser.loginId);
      // console.log("USER ACCOUNT2:", authUser);
      // getUserRole(response1);
    } else {
      setName("");
    }
  }, [authUser]);

  const getUserRole = async (userRole: any) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
      // console.log("API_BASE_URL", API_BASE_URL);
      let res = await axios.get(`${API_BASE_URL}/Application/UserRole`);
      // console.log("getUserRole", res.data);
      let roleType = res.data.filter(findRole).map((ele: any) => ele.roleName);
      //let roleType = "ClientAdmin";
      let authUser = res.data.filter(findRole)[0] as IUser;
      let userId = authUser?.userId;
      // console.log("getUserRole", roleType);
      if (roleType && roleType.length > 0) {
        // console.log("getUserRole", userRole, setAuthStatus);
        if (
          userRole?.email &&
          setAuthStatus &&
          setAuthorized &&
          setAuthUser &&
          setPermission
        ) {
          // setAuthStatus(true, "jeevithas@infinite.com", roleType, userId);
          setAuthStatus(true, userRole.email, roleType, userId);
          let permissionRoles = res.data.filter(findRole);
          setPermission(permissionRoles);
          authUser.clients = res.data
            .filter((ele: any) => findRole(ele) && ele.customerId !== null)
            .map((ele: any) => ele.customerId);
          // console.log("getUserRole authUser", authUser);
          setAuthUser(authUser);
          setAuthorized(true);
          // if (roleType.length === 1 && roleType[0] === "JSISME") {
          //   navigate("/filing-master-list");
          // } else if (
          //   roleType.length === 1 &&
          //   roleType[0] === "JSISME-PostAward"
          // ) {
          //   navigate("/postaward-filing-master-list");
          // }
        }
      } else {
        setAlertMessage(
          "User does not have access to the Application. Please contact JSI TrackNow facilitator. "
        );
        setShowAlert(true);
        setAuthUser && setAuthUser(null);
        // instance.logoutRedirect({
        //   postLogoutRedirectUri: "/",
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const findRole = (ele: any) => {
    // console.log(
    //   "ele.loginId:",
    //   ele.loginId,
    //   "account?.username:",
    //   account?.username
    // );
    return ele.loginId.toLowerCase() === portalUser?.email?.toLowerCase();
  };

  const closeAlert = () => {
    setShowAlert(false);
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  useEffect(() => {
    if (isPortalAuthenticated) {
      // console.log("USER AUTH ACCOUNT:", portalUser);
      getUserRole(portalUser);
    }
  }, [isPortalAuthenticated]);
  // const authContext = useContext<IAuthContext>(AuthContext);
  // let { user } = authContext;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [displayMenu, setDisplayMenu] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [customerNotificationCount, setCustomerNotificationCount] =
    React.useState(0);
  const [filingNotificationCount, setFilingNotificationCount] =
    React.useState(0);
  const [customerNotificationsWorkFlow, setCustomerNotificationsWorkFlow] =
    React.useState(0);
  const [totalNotificationCount, setTotalNotificationCount] = React.useState(0);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    // console.log("Open Main Menu?");
    setAnchorElNav(event.currentTarget);
    setDisplayMenu(true);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleLogout = async () => {
  //   // await signOut();
  //   setAuthUser && setAuthUser(null);
  //   instance.logoutRedirect({
  //     postLogoutRedirectUri: "/",
  //   });
  // };
  // const handleLogin = async () => {
  //   instance.loginRedirect(loginRequest).catch((e) => {
  //     console.log("LOGIN Error", e);
  //   });
  // };

  const handleMenuClose = () => {
    setDisplayMenu(false);
    //generate Token
  };

  const openMainMenu = () => {
    // console.log("Clik Open Main menu1");
    if (isPortalAuthenticated) setDisplayMenu((old) => !old);
    //generate Token
  };
  const footerContent = (
    <div>
      <PrimeButton label='OK' onClick={() => closeAlert()} autoFocus />
    </div>
  );

  const fetchNotifications = async () => {
    // console.log("CustomerNotification", getCustomerNotifications);
    // console.log("filingNotifications", getFilingNotifications);
    if (
      getCustomerNotifications &&
      getFilingNotifications &&
      getCustomerNotificationsWorkFlow
    ) {
      // console.log("@@Fetch Customers2:");
      try {
        let custList = await getCustomerNotifications();
        custList = custList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        // console.log("@@customerNotifications:", custList);
        // setTotalNotificationCount((old) => old + custList.length);

        setCustomerNotificationCount(custList.length);
        let filingList = await getFilingNotifications();
        filingList = filingList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        // console.log("@@filingNotifications:", filingList);
        // setTotalNotificationCount((old) => old + filingList.length);
        setFilingNotificationCount(filingList.length);
        let custListWorkflow = await getCustomerNotificationsWorkFlow();
        custListWorkflow = custListWorkflow
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");
        // console.log("@@filingNotifications:", custListWorkflow);

        setCustomerNotificationsWorkFlow(custListWorkflow.length);
        setTotalNotificationCount(
          custList?.length + filingList?.length + custListWorkflow?.length
        );
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const handleNavigate = () => {
    navigate("/Notification");
  };

  useEffect(() => {
    setTotalNotificationCount(
      (prevTotal) => prevTotal + (notificationCount - prevTotal)
    );
  }, [notificationCount]);

  useEffect(() => {
    if (authUser && authUser?.userId) fetchNotifications();
  }, [authUser]);
  const supportEmail = "mailto:Jsi-Velocity_support@jsitel.com";

  return (
    <AppBar sx={{ backgroundColor: "#0b3edf" }} position='static'>
      <Container maxWidth='xl'>
        <Dialog
          header='Error'
          visible={showAlert}
          style={{ width: "30vw" }}
          onHide={() => closeAlert()}
          footer={footerContent}
        >
          <p className='m-0'>{alertMessage}</p>
        </Dialog>
        <Toolbar disableGutters>
          <IconButton
            ref={anchorRef}
            className='nav-bar__logo__img'
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            onClick={openMainMenu}
          >
            <Box
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
              }}
            >
              {" "}
              <MenuIcon
                sx={{
                  mr: 2,
                  color: "white",
                }}
              />
            </Box>
            <Link id='HomeLink' to='/'>
              <img src={JSI} alt='Logo' />{" "}
            </Link>
          </IconButton>

          <Typography
            variant='h6'
            noWrap
            component='a'
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              flexGrow: 1,
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            TRACK NOW
          </Typography>

          <IconButton sx={{ display: { xs: "flex", md: "none" }, mr: 1, p: 0 }}>
            <Box
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
              }}
            >
              {" "}
              <MenuIcon
                sx={{
                  mr: 2,
                  color: "white",
                }}
              />
            </Box>
            <Link id='mbHomeLink' to='/'>
              <Avatar alt='Logo' src={JSI} />
            </Link>
          </IconButton>

          {/* <Button> */}
          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          >
            <Link id='Home' to='/'>
              <Typography
                variant='h6'
                noWrap
                component='a'
                sx={{
                  fontWeight: 700,
                  color: "white",
                  textDecoration: "none",
                }}
              >
                TRACK NOW
              </Typography>
            </Link>
          </Box>
          {/* </Button> */}

          <Box sx={{ flexGrow: 0, p: 0, ml: 2 }}></Box>

          {portalUser && isPortalAuthenticated ? (
            <Fragment>
              <Box
                sx={{
                  flexGrow: 0,
                  p: 0,
                  ml: 2,
                  display: { xs: "none", md: "flex" },
                }}
              >
                <MenuItem className='nav-bar__menu__item nav-bar__menu__group'>
                  <div>
                    <img
                      src={personIcon}
                      alt='user'
                      className='nav-bar__menu__icon'
                    />
                  </div>
                  <div>
                    {`${portalUser.firstname} ${portalUser.lastname}`}
                    <div className='nav-bar__menu__dropdown2__toggle__sub-title'>
                      {/* {user?.role} */}
                    </div>
                  </div>
                </MenuItem>
              </Box>
              <Box sx={{ display: { xs: "none", md: "flex" }, p: 0, my: 1 }}>
                <MenuItem>
                  <Badge badgeContent={totalNotificationCount}>
                    <NotificationsIcon onClick={handleNavigate} />
                  </Badge>
                </MenuItem>
              </Box>
            </Fragment>
          ) : (
            <div className='nav-bar__popover'>
              <h5 className='nav-bar__menu__text__title'>
                Please login to access TrackNow application
              </h5>
              {/* <h5 className="nav-bar__menu__text__desc">
                If you need access to the applications, please{" "}
                <a href="#">contact us</a>
              </h5> */}
            </div>
          )}
          <Box>
            <Button
              href={supportEmail}
              variant='outlined'
              endIcon={<ContactSupportOutlinedIcon />}
              sx={{
                color: "white", // Text color
                borderColor: "white",
                ml: 2, // Outline color
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "white",
                  color: "black",
                },
              }}
            >
              Support
            </Button>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexGrow: 0,
              p: 0,
              ml: 2,
            }}
          >
            {portalUser && isPortalAuthenticated && (
              <MenuItem onClick={handleLogout}>
                <LogoutIcon />
              </MenuItem>
            )}
            {!isPortalAuthenticated && (
              <MenuItem onClick={handleLogin}>
                <LoginIcon />
              </MenuItem>
            )}
            {/* {isAuthenticated ? (
              <MenuItem onClick={handleLogout}>
                <LogoutIcon />
              </MenuItem>
            ) : (
              <MenuItem onClick={handleLogin}>
                <LoginIcon />
              </MenuItem>
            )} */}
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            {/* <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton> */}
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuItem className='nav-bar__menu__item nav-bar__menu__group'>
                <div>
                  <img
                    src={personIcon}
                    alt='user'
                    className='nav-bar__menu__icon'
                  />
                </div>
                <div>
                  <div> {name}</div>
                  <div className='nav-bar__menu__dropdown2__toggle__sub-title'>
                    {/* {user?.role} */}
                  </div>
                </div>
              </MenuItem>
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign='center'>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
        <MainMenu
          show={displayMenu}
          handleClose={handleMenuClose}
          anchRef={anchorRef}
          handleLogout={handleLogout}
        />
      </Container>
    </AppBar>
  );
}
export default NavBar;
