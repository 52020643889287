import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DateTime } from "luxon";
import FileDownload from "js-file-download";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import AuthContext from "../../context/auth/AuthContext";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";



const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function FilingMasterApprovalHistory(props) {
  const [selectedFile, setSelectedFile] = useState("");

  const [data, setData] = React.useState([]);
  const [commentData, setCommentData] = React.useState([]);
  const [value, setValue] = React.useState("1");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const { userRoles, toLocalDateTime } = authContext;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    console.log(`Drawer Mouse Key Event...`);
  };

  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");
      console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const toLocalDate = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy "),
        "dd-MMM-yyyy ",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy ");
      console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
    }
  }, [props.show]);
  useEffect(() => {
    if (props.commentActivityHistory) {
      setCommentData(() => props.commentActivityHistory);
    }

  }, [props.commentActivityHistory])


  const isArrayDiff = (oldArray, newArray) => {
    return !_.isEqual(oldArray, newArray);
  };
  const trackNowList = [
    {
      "TrackNowLicenseId": 1,
      "TrackNowLicenseName": "Essential"
    },

    {
      "TrackNowLicenseId": 2,
      "TrackNowLicenseName": "plus"
    },


    {
      "TrackNowLicenseId": 3,
      "TrackNowLicenseName": "Premium"
    }
  ];
  const getTracknowLisenceName = (oldLisenceId, newLisenceId) => {
    console.log("getTracknowisenceName ", oldLisenceId, newLisenceId, trackNowList)

    const oldLisenceName = oldLisenceId === null ? "" : trackNowList.find(item => item.TrackNowLicenseId === oldLisenceId);
    const newLisenceName = newLisenceId === null ? "" : trackNowList.find(item => item.TrackNowLicenseId === newLisenceId);
    console.log("getTracknowLisenceName ", oldLisenceName, newLisenceName);


    return {
      field: "trackNowLisence",
      old: oldLisenceName.TrackNowLicenseName,
      new: newLisenceName.TrackNowLicenseName
    }
  }
  const postAwardList = [
    {
      "PostAwardLicenseId": 1,
      "PostAwardLicenseName": "Essential"
    },
    {
      "PostAwardLicenseId": 2,
      "PostAwardLicenseName": "Premium"
    },

    {

      "PostAwardLicenseId": 3,

      "PostAwardLicenseName": "Platinum"

    }

  ]

  const getPostAwardLisenceName = (oldLisenceId, newLisenceId) => {
    console.log("getPostAwardLisenceName ", oldLisenceId, newLisenceId, postAwardList)

    const oldLisenceName = oldLisenceId === null ? "" : postAwardList.find(item => item.PostAwardLicenseId === oldLisenceId);
    const newLisenceName = newLisenceId === null ? "" : postAwardList.find(item => item.PostAwardLicenseId === newLisenceId);
    console.log("getPostAwardLisenceName ", oldLisenceName, newLisenceName);


    return {
      field: "postAwardLisence",
      old: oldLisenceName.PostAwardLicenseName,
      new: newLisenceName.PostAwardLicenseName
    }
  }

  useEffect(() => {
    if (props.activityHistory) {

      let activity = props.activityHistory.map((ele) => {
        let changed = [];
        if (ele.process === "Modified") {
          console.log("ele", ele);
          let keys = Object.keys(ele);
          keys.forEach((key) => {
            const capitalized = key.charAt(0).toUpperCase() + key.slice(1);
            console.log("key", key, ele[key], ele[`new${capitalized}`]);
            if (key === "postAwardLicenseId") {
              if (ele[key] !== ele[`new${capitalized}`]) {

                let postlisenceChange = getPostAwardLisenceName(ele[key], ele[`new${capitalized}`])
                changed.push(postlisenceChange);

              }
            }
            else if (key === "tracknowLicenseId") {
              if (ele[key] !== ele[`new${capitalized}`]) {


                let tracklisenceChange = getTracknowLisenceName(ele[key], ele[`new${capitalized}`])
                changed.push(tracklisenceChange);
              }
            }

            else if (
              ele[`new${capitalized}`] !== undefined &&
              ele[key] !== ele[`new${capitalized}`] &&
              key !== "businessCategory"
            ) {
              changed.push({
                field: key,
                old: ele[key],
                new: ele[`new${capitalized}`],
              });
            }
          });
          if (
            ele.businessCategory.length > 0 &&
            ele.newBusinessCategory.length > 0
          ) {
            let stateBC = ele.businessCategory.filter(
              (ele) => ele.state !== null
            );
            let newStateBC = ele.newBusinessCategory.filter(
              (ele) => ele.state !== null
            );
            console.log("Modified stateBC", stateBC, newStateBC);
            let changeStateBC = isArrayDiff(stateBC, newStateBC);
            if (changeStateBC) {
              changed.push({
                field: "State Business Category",
                old: stateBC.map((ele) => ele.businessCategoryName),
                new: newStateBC.map((ele) => ele.businessCategoryName),
              });
            }
            let federalBC = ele.businessCategory.filter(
              (ele) => ele.state === null
            );
            let newFederalBC = ele.newBusinessCategory.filter(
              (ele) => ele.state === null
            );
            console.log("Modified federalBC", federalBC, newFederalBC);
            let changeFederalBC = isArrayDiff(federalBC, newFederalBC);
            if (changeFederalBC) {
              changed.push({
                field: "Federal Business Category",
                old: federalBC.map((ele) => ele.businessCategoryName),
                new: newFederalBC.map((ele) => ele.businessCategoryName),
              });
            }
          }
        }

        if (changed.length > 0) {
          ele.changedData = changed;
          console.log("changedData", ele.changedData);
        }
        return ele;
      });
      console.log("activity", activity);
      setData(() => props.activityHistory);
    }

    //eslint-disable-next-line
  }, [props.activityHistory]);

  const getUserName = (userId) => {
    console.log("USER ROLES LKP:", userRoles, userId);
    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]
        ?.userName
      : "";
    return user;
  };

  const ListElement = (item, index) => {
    //const [showChanges, setShowChanges] = useState(false);
    console.log("ListElement", item, index);
    return (
      <Fragment key={index}>
        <Stack direction="row" justifyContent="space-between">
          <ListItem
            disablePadding
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {item.updateuser
              ? getUserName(item.updateuser)
              : getUserName(item.createuser)}
          </ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            <ListItemText
              secondary={
                item && item.updatedate
                  ? `${toLocalDateTime && toLocalDateTime(item.updatedate)}`
                  : `${toLocalDateTime && toLocalDateTime(item.createdate)}`
              }
            />
          </ListItem>
        </Stack>
        <ListItem disablePadding>
          <ListItemText
            primary={
              item && item.process && item.process !== null ? item.process : ""
            }
          />
        </ListItem>
        {item.changedData && item.changedData.length > 0 && (
          <TableContainer>
            <Table
              sx={{ minWidth: 250, border: "1px solid black" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Old value</TableCell>
                  <TableCell>New value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.changedData
                  .filter(
                    (ele) =>
                      ![
                        "filingDescription",
                        "dueDayofFrequency",
                        "jsidept",
                        "jsicontactEmail",
                      ].some((value) => value.includes(ele.field))
                  )
                  .map((ele) => (
                    <TableRow>
                      <TableCell>{ele.field}</TableCell>
                      <TableCell>{String(ele.old)}</TableCell>
                      <TableCell>{String(ele.new)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Divider sx={{ borderBottomWidth: 3 }} />
      </Fragment>
    );
  };


  const CommentListElement = (item, index) => {
    return (
      <Fragment key={index}>
        <Stack direction="row" justifyContent="space-between">
          <ListItem
            disablePadding
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {item.updateUser !== null
              ? getUserName(item.updateUser)
              : getUserName(item.createUser)}
          </ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            <ListItemText
              secondary={
                item && item.updateDate !== null
                  ? toLocalDateTime && `${toLocalDateTime(item.updateDate)}`
                  : toLocalDateTime && `${toLocalDateTime(item.createDate)}`
              }
            />
          </ListItem>
        </Stack>
        <ListItem disablePadding>
          <ListItemText
            primary={
              item && item.process && item.process !== null ? item.process : ""
            }
          />
        </ListItem>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 250 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Old value</TableCell>
                <TableCell>New value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{item.previousCommentsText}</TableCell>
                <TableCell>{item.commentsText}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  };
  const list = (anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div
                  style={{
                    width: "30vw",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem",
                  }}
                >
                  ACTIVITY HISTORY
                  <IconButton>
                    <CloseIcon onClick={() => handleCancel(anchor)} />
                  </IconButton>
                </div>
              </Stack>
              <CardContent>
                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Client profile" value="1" />
                    <Tab label="Comments" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {data &&
                    data
                      .slice()
                      .reverse()
                      .map((item, index) => ListElement(item, index))}
                </TabPanel>
                <TabPanel value="2">
                  {commentData &&
                    commentData
                      .slice()
                      // .reverse()
                      .map((item, index) => CommentListElement(item, index))}
                </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );

  const handleCancel = async (anchor) => {
    setSelectedFile(() => null);
    setShowSuccess(false);
    setShowError(false);
    setData(() => []);

    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
