import React, { useReducer } from "react";
import axios from "axios";
import ClientFilingMasterContext from "./ClientFilingMasterContext";
import ClientFilingMasterReducer from "./ClientFilingMasterReducer";
import {
  CLIENT_FILING_MASTER_LOADING,
  POST_AWARD_CLIENT_FILING_MASTER_LOADING,
  CLIENT_FILING_MASTER_LOADED,
  CLIENT_FILING_MASTER_ERROR,
  CLIENT_FILING_MASTER_RESET,
  SET_CURRENT_CLIENT_FILING_MASTER,
  UPDATE_CLIENT_FILING_MASTER,
  CLIENT_FILING_MASTER_ADD_LOADED,
  ADD_CLIENT_FILING_MASTER,
  APPROVE_CLIENT_FILING_MASTER,
  APPROVE_POST_AWARD_CLIENT_FILING_MASTER,
  REJECT_CLIENT_FILING_MASTER,
  REJECT_POST_AWARD_CLIENT_FILING_MASTER,
  CLIENT_FILING_MASTER_APPROVALS,
  CLIENT_MASTER_WORKFLOW_COMMENTS_LOADED,
  CLIENT_FILING_TRACKER_LOADED,
  CLIENT_FILING_TRACKER_HISTORY_LOADED,
  SET_CURRENT_CLIENT_FILING_TRACKER,
  CLIENT_TRACKER_COMMENTS_LOADED,
  FETCH_CONFIGURATIONS,
  CLIENT_FILING_TRACKER_ATTCHMENTS_LOADED,
  CLIENT_MASTER_COMMENTS_ATTACHHMENTS_LOADED,
  CLIENT_FILING_TRACKER_REFRESH_PAGE
} from "../../data/constants";
// import { env } from "process";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
// const API_HOST = "";
const ClientFilingMasterState = (props) => {
  const initialState = {
    error: null,
    ClientFilingMasters: null,
    changedClientFilingMasters: [],
    ClientFilingMastersAdd: [],
    ClientFilingMasterApprovals: [],
    ClientFilingMasterWorkflowComments: [],
    ClientFilingTrackerComments: [],
    ClientFilingMasterLoading: false,
    ClientFilingMasterLoaded: false,
    ClientFilingTrackers: [],
    ClientFilingTrackerAttachments: [],
    clientFilingMaterCommentsAttachments: [],
    selectedClientFilingTracker: localStorage.getItem(
      "selectedClientFilingTracker"
    )
      ? JSON.parse(localStorage.getItem("selectedClientFilingTracker"))
      : null,
    selectedClientFilingMaster: localStorage.getItem(
      "selectedClientFilingMaster"
    )
      ? JSON.parse(localStorage.getItem("selectedClientFilingMaster"))
      : null,
    savedCriteria: null,
    remiderConfig: null,
    refreshPageDetails: {}
  };
  const [state, dispatch] = useReducer(ClientFilingMasterReducer, initialState);

  const fetchConfigurations = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `${API_BASE_URL}/Application/AppConfigurationList`
        );

        if (res && res.data) {
          dispatch({
            type: FETCH_CONFIGURATIONS,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const createReminderConfiguration = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        data.forEach(async (item) => {
          const res = await axios.post(
            `${API_BASE_URL}/Application/AppConfigurationCreate`,
            [item]
          );

        });

        await fetchConfigurations();
        resolve(true);
      } catch (error) {
        reject(false);
        console.log(error);
      }
    });
  };

  const updateReminderConfiguration = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        data.forEach(async (item) => {
          const res = await axios.put(
            `${API_BASE_URL}/Application/AppConfigurationupdate`,
            item
          );

        });

        await fetchConfigurations();

        resolve(true);
      } catch (error) {
        reject(false);
        console.log(error);
      }
    });
  };

  //Reset Context State
  const resetClientFilingMasterState = () => {
    dispatch({
      type: CLIENT_FILING_MASTER_RESET,
      payload: { ...initialState },
    });
  };

  //select a clientClientFilingMaster for processing
  const setCurrentClientFilingMaster = (clientClientFilingMaster) => {
    dispatch({
      type: SET_CURRENT_CLIENT_FILING_MASTER,
      payload: clientClientFilingMaster,
    });
  };

  //Search All clientClientFilingMasters
  const getClientFilingMasters = async (formData) => {
    // const savedCriteria = { ...formData };
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "Access-Control-Allow-Credentials": "true",
    //   },
    // };
    // if (formData) {
    //   formData.action = "CLIENT_FILING_MASTER_MANAGEMENT";
    //   formData.function = "get_clientClientFilingMasters";
    //   formData.version = "1";
    // }
    dispatch({
      type: CLIENT_FILING_MASTER_LOADING,
    });
    return new Promise(async (resolve, reject) => {
      try {
        let API_REQ_URL = "";
        if (formData?.customerId) {
          API_REQ_URL = `${API_BASE_URL}/Customer/CustomerFilingMasterList?CustomerId=${formData.customerId}`;

          const res = await axios.get(API_REQ_URL);
          dispatch({
            type: CLIENT_FILING_MASTER_LOADED,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: CLIENT_FILING_MASTER_LOADED,
            payload: [],
          });
          resolve([]);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Creat a clientClientFilingMaster
  const createClientFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/Customer/CustomerFilingMaster`,
          formData
        );
        dispatch({
          type: UPDATE_CLIENT_FILING_MASTER,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Add a clientFiling to the workflow
  const addClientFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/Customer/CustomerFilingMasterDraft?LoggedInUser=${formData[0].createUser}`,
          formData
        );
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: ADD_CLIENT_FILING_MASTER,
            payload: res.data.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        // dispatch({
        //   type: CLIENT_FILING_MASTER_ERROR,
        //   payload: err,
        // });
        reject(err);
      }
    });
  };

  const addPostClientFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.post(
          `${API_BASE_URL}/Customer/CreateCustomerFilingMasterDraftPostAward?LoggedInUser=${formData[0].createUser}`,
          formData
        );
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: ADD_CLIENT_FILING_MASTER,
            payload: res.data.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        // dispatch({
        //   type: CLIENT_FILING_MASTER_ERROR,
        //   payload: err,
        // });
        reject(err);
      }
    });
  };

  //approve ClientFilingMaster
  const approveClientFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/Customer/CustomerFilingMasterApprove${formData?.draft?.workflowId}?Userid=${formData.Userid}&DraftId=${formData.draft.draftId}`
        );
        dispatch({
          type: APPROVE_CLIENT_FILING_MASTER,
          payload: res.data,
        });
        //TO-DO: pass approver id
        await getClientFilingMastersApprovalList();
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Bulk approve ClientFilingMaster
  const approveBulkClientFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        const payload = formData.map((item) => ({
          draftId: item.draftId,
          userid: item.userid,
          workFlowId: item.workFlowId,
          notes: item.notes,
        }));
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/Customer/CustomerFilingMasterBulkApprove`,
          payload
        );
        //TO-DO: pass approver id

        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: APPROVE_CLIENT_FILING_MASTER,
            payload: res.data,
          });
          if (
            formData[0].role === "Admin" ||
            formData[0].role === "Admin-PostAward"
          ) {
            await getClientFilingMastersApprovalList({recordType:"TrackNow"});
          } else {
            await getClientFilingMastersApprovalList({
              approverId: formData[0].userid,
              recordType: "TrackNow"
            });
          }
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Bulk approve PostAwardClientFilingMaster
  const approveBulkPostAwardClientFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        const payload = formData.map((item) => ({
          draftId: item.draftId,
          userid: item.userid,
          workFlowId: item.workFlowId,
          notes: item.notes,
        }));
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/Customer/CustomerFilingMasterPostAwardBulkApprove`,
          payload
        );
        //TO-DO: pass approver id

        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: APPROVE_POST_AWARD_CLIENT_FILING_MASTER,
            payload: res.data.data,
          });
          // let payload;
          // if (
          //   formData[0].role === "Admin" ||
          //   formData[0].role === "Admin-PostAward"
          // ) {
          //   payload = { approverId: formData[0].userid,
          //     recordType : "PostAward"  };
          // }
          // else{
          //   payload = {
          //     recordType : "PostAward",
          //   }
          // }
          if (
            formData[0].role === "Admin" ||
            formData[0].role === "Admin-PostAward"
          ) {
            await getClientFilingMastersApprovalList({ recordType: "PostAward" });
          } else {
            await getClientFilingMastersApprovalList({
              approverId: formData[0].userid,
              recordType: "PostAward"
            });
          }
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //reject ClientFilingMaster
  const rejectClientFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/Customer/CustomerFilingMasterReject${formData?.draft?.workflowId}?${formData.Userid}`
        );
        dispatch({
          type: REJECT_CLIENT_FILING_MASTER,
          payload: res.data,
        });
        //TO-DO: pass approver id
        await getClientFilingMastersApprovalList();
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Bulk reject ClientFilingMaster
  const rejectBulkClientFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        const payload = formData.map((item) => ({
          draftId: item.draftId,
          userid: item.userid,
          workFlowId: item.workFlowId,
          notes: item.notes,
        }));
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/Customer/CustomerFilingMasterBulkReject`,
          payload
        );
        //TO-DO: pass approver id

        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: REJECT_CLIENT_FILING_MASTER,
            payload: res.data, 
          });
          if (
            formData[0].role === "Admin" ||
            formData[0].role === "Admin-PostAward"
          ) {
            await getClientFilingMastersApprovalList({recordType:"TrackNow"});
          } else {
            await getClientFilingMastersApprovalList({
              approverId: formData[0].userid,
              recordType:"TrackNow"
            });
          }
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  //Bulk reject ClientFilingMaster
  const rejectBulkPostAwardClientFilingMaster = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        const payload = formData.map((item) => ({
          draftId: item.draftId,
          userid: item.userid,
          workFlowId: item.workFlowId,
          notes: item.notes,
        }));
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.put(
          `${API_BASE_URL}/Customer/CustomerFilingMasterPostAwardBulkReject`,
          payload
        );
        //TO-DO: pass approver id

        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: REJECT_POST_AWARD_CLIENT_FILING_MASTER,
            payload: res.data.data,
          });
          if (
            formData[0].role === "Admin" ||
            formData[0].role === "Admin-PostAward"
          ) {
            await getClientFilingMastersApprovalList({ recordType: "PostAward" });
          } else {
            await getClientFilingMastersApprovalList({
              approverId: formData[0].userid,
              recordType: "PostAward"
            });
          }
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getClientFilingMastersForAdd = async (formData, filingType) => {
    // const savedCriteria = { ...formData };
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "Access-Control-Allow-Credentials": "true",
    //   },
    // };
    // if (formData) {
    //   formData.action = "CLIENT_FILING_MASTER_MANAGEMENT";
    //   formData.function = "get_clientClientFilingMasters";
    //   formData.version = "1";
    // }
    return new Promise(async (resolve, reject) => {
      try {
        let API_REQ_URL = "";
        if (formData?.customerId) {
          API_REQ_URL = `${API_BASE_URL}/Customer/BusinessBasedFilingMasterList?customerId=${formData.customerId}`;
          if(filingType){
            API_REQ_URL = `${API_REQ_URL}&FilingType=${filingType}`
          }
          dispatch({
            type: CLIENT_FILING_MASTER_LOADING,
          });
          const res = await axios.get(API_REQ_URL);
          dispatch({
            type: CLIENT_FILING_MASTER_ADD_LOADED,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: CLIENT_FILING_MASTER_ADD_LOADED,
            payload: [],
          });
          resolve([]);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getPostClientFilingMastersForAdd = async (formData , filingType) => {
    return new Promise(async (resolve, reject) => {
      try {
        let API_REQ_URL = "";
        if (formData?.customerId) {
          API_REQ_URL = `${API_BASE_URL}/Customer/PostawardBusinessBasedFilingMasterList?customerId=${formData.customerId}`;
          if(filingType){
            API_REQ_URL = `${API_REQ_URL}&FilingType=${filingType}`
          }
          dispatch({
            type: CLIENT_FILING_MASTER_LOADING,
          });
          const res = await axios.get(API_REQ_URL);
          dispatch({
            type: CLIENT_FILING_MASTER_ADD_LOADED,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: CLIENT_FILING_MASTER_ADD_LOADED,
            payload: [],
          });
          resolve([]);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const setClientFilingMastersApprovalList = (filingMaster) => {
    dispatch({
      type: CLIENT_FILING_MASTER_APPROVALS,
      payload: filingMaster,
    });
  };

  const getClientFilingMastersApprovalList = async (formData) => {
    // const savedCriteria = { ...formData };
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "Access-Control-Allow-Credentials": "true",
    //   },
    // };
    // if (formData) {
    //   formData.action = "CLIENT_FILING_MASTER_MANAGEMENT";
    //   formData.function = "get_clientClientFilingMasters";
    //   formData.version = "1";
    // }
    return new Promise(async (resolve, reject) => {
      try {
        let API_REQ_URL = "";
        if (formData?.approverId) {
          API_REQ_URL = `${API_BASE_URL}/Customer/CustomerFilingMasterWorkflowList?ApproverId=${formData.approverId}&RecordType=${formData.recordType}`;
        } else {
          API_REQ_URL = `${API_BASE_URL}/Customer/CustomerFilingMasterWorkflowList?RecordType=${formData.recordType}`;
        }
        dispatch({
          type: CLIENT_FILING_MASTER_LOADING,
        });
        const res = await axios.get(API_REQ_URL);
        let payload;
        if (formData?.approverId) {
          payload = res.data.data;
        } else {
          payload = res.data.data;
        }
        if (res && res.data) {
          dispatch({
            type: CLIENT_FILING_MASTER_APPROVALS,
            payload,
          });
          resolve(payload);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getClientFilingMasterWorkflowComments = async (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        const res = await axios.get(
          `${API_BASE_URL}/Customer/CustomerFilingWorkflowComments${formData}`
        );
        dispatch({
          type: CLIENT_MASTER_WORKFLOW_COMMENTS_LOADED,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Add comment to  Client Filing Master Approval
  const addClientFilingMasterWorkflowComment = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        let res = null;
        // const payload = {
        //   filingId: formData.draft.filingId,
        //   commentsText: formData.approvalComment,
        // };
        res = await axios.post(
          `${API_BASE_URL}/Customer/CreateCustomerFilingWorkflowComments`,
          formData
        );

        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        await getClientFilingMasterWorkflowComments(formData.workflowId);
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getClientFilingTrackerComments = async (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        const res = await axios.get(
          `${API_BASE_URL}/Customer/CustomerFilingTrackingComments${formData}`
        );
        dispatch({
          type: CLIENT_TRACKER_COMMENTS_LOADED,
          payload: res.data,
        });
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //Add comment to  Client Filing Master Approval
  const addClientFilingTrackerComment = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        let res = null;
        // const payload = {
        //   filingId: formData.draft.filingId,
        //   commentsText: formData.approvalComment,
        // };
        res = await axios.post(
          `${API_BASE_URL}/Customer/CreateParentChildCustomerFilingTrackingComments`,
          formData
        );

        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        //await getClientFilingTrackerComments(formData.fileTrackingId);
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //get ClientFilingTrackers
  const clearClientFilingTrackers = () => {
    dispatch({
      type: CLIENT_FILING_TRACKER_LOADED,
      payload: [],
    });
  };

  //set ClientFilingTrackers
    const setClientFilingTrackersHistory = (data) => {
      dispatch({
        type: CLIENT_FILING_TRACKER_HISTORY_LOADED,
        payload: data,
      });
    };

  //set ClientFilingTrackers
  const setClientFilingTrackers = (data) => {
    dispatch({
      type: CLIENT_FILING_TRACKER_LOADED,
      payload: data,
    });
  };

  //get ClientFilingTrackers
  const getClientFilingTrackers = async (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        let API_REQ_URL = "";
        if (formData?.customerId) {
          API_REQ_URL = `${API_BASE_URL}/Customer/CustomerFileTrackingbyCustomerId?CustomerId=${formData.customerId}`;

          dispatch({
            type: CLIENT_FILING_MASTER_LOADING,
          });
          const res = await axios.get(API_REQ_URL);
          if (res && res.data && res.data.status === "Success") {
            dispatch({
              type: CLIENT_FILING_TRACKER_LOADED,
              payload: formData,
            });
            resolve(res.data.data);
          } else {
            reject(res.data);
          }
        } else {
          dispatch({
            type: CLIENT_FILING_MASTER_LOADED,
            payload: [],
          });
          resolve([]);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  //select a clientClientFilingMaster for processing
  const setCurrentClientFilingTracker = (clientFilingTracker) => {
    dispatch({
      type: SET_CURRENT_CLIENT_FILING_TRACKER,
      payload: clientFilingTracker,
    });
  };
  const getCustomerFilingComments = async (formData) => {
    //console.log("FETCHING Approval Comments ");
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        const res = await axios.get(
          `${API_BASE_URL}/Customer/CustomerFilingCommentsByCustomerIdFilingId${formData.filingId}?customerId=${formData.customerId}`
        );

        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: CLIENT_MASTER_WORKFLOW_COMMENTS_LOADED,
            payload: res.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  const getCustomerFilingCommentsAttachments = async (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `${API_BASE_URL}/Customer/ListCustomerFilingCommentsAttachments/${formData.customerId}/${formData.filingId}`
        );

        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: CLIENT_MASTER_WORKFLOW_COMMENTS_LOADED,
            payload: res.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  const AddCreateCustomerFilingComments = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        let res = null;
        // const payload = {
        //   filingId: formData.draft.filingId,
        //   commentsText: formData.approvalComment,
        // };
        res = await axios.post(
          `${API_BASE_URL}/Customer/CreateCustomerFilingComments`,
          formData
        );

        // dispatch({
        //   type: FILING_MASTER_ADD_COMMENT,
        //   payload: res.data,
        // });
        // await getCustomerFilingComments(formData);
        resolve(res.data);
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const updateClientFilingMasterWorkflow = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(
          `${API_BASE_URL}/Customer/CustomerFilingMasterWorkflowupdate/${data.workflowId}`,
          data
        );
        resolve(true);
      } catch (err) {
        reject(false);
      }
    });
  };

  const changeClientFilingMasterApprover = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(
          `${API_BASE_URL}/Customer/CustomerFilingMasterChangeApprover`,
          data
        );
        if (res && res.data && res.data.status === "Success") {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        reject(false);
      }
    });
  };

  const changeFilingApprover = async (data) => {
    return new Promise(async (resolve, reject) => {
      let RequestURL = `${API_BASE_URL}/Application/ChangeApprover?OldApproverID=${data.oldApproverId}&NewApproverID=${data.newApproverId}`;
      if (data.state) {
        RequestURL = RequestURL + `&State=${data.state}`;
      }
      if (data.customerId) {
        RequestURL = RequestURL + `&CustomerID=${data.customerId}`;
      }
      try {
        const res = await axios.put(RequestURL);
        if (res && res.data && res.data.status === "Success") {
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        reject(false);
      }
    });
  };

  const getClientFilingTrackerAttachments = async (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch({
        //   type: FILING_MASTER_LOADING,
        // });
        const res = await axios.get(
          `${API_BASE_URL}/Customer/ViewCustomerFilingTrackingAttachments/${formData}`
        );

        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: CLIENT_FILING_TRACKER_ATTCHMENTS_LOADED,
            payload: res.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const getCustomerMasterWorkflowCommentsAttachments = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(
          `${API_BASE_URL}/Customer/CustomerFilingWorkflowCommentsAttachmentsList`
        );


        if (res && res.data) {
          dispatch({
            type: CLIENT_MASTER_COMMENTS_ATTACHHMENTS_LOADED,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: CLIENT_FILING_MASTER_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

  const setRefreshPageDetails = (payload) => {
    dispatch({
      type: CLIENT_FILING_TRACKER_REFRESH_PAGE,
      payload: payload,
    });
  }

  return (
    <ClientFilingMasterContext.Provider
      value={{
        ...state,
        resetClientFilingMasterState,
        setCurrentClientFilingMaster,
        // clearCurrentClientFilingMaster,
        getClientFilingMasters,
        createClientFilingMaster,
        getClientFilingMastersForAdd,
        getPostClientFilingMastersForAdd,
        addClientFilingMaster,
        addPostClientFilingMaster,
        approveClientFilingMaster,
        approveBulkClientFilingMaster,
        approveBulkPostAwardClientFilingMaster,
        rejectClientFilingMaster,
        rejectBulkClientFilingMaster,
        rejectBulkPostAwardClientFilingMaster,
        setClientFilingMastersApprovalList,
        getClientFilingMastersApprovalList,
        addClientFilingMasterWorkflowComment,
        getClientFilingMasterWorkflowComments,
        addClientFilingTrackerComment,
        getClientFilingTrackerComments,
        setClientFilingTrackers,
        setClientFilingTrackersHistory,
        getClientFilingTrackers,
        setCurrentClientFilingTracker,
        AddCreateCustomerFilingComments,
        getCustomerFilingComments,
        getCustomerFilingCommentsAttachments,
        getClientFilingTrackerAttachments,
        // updateClientFilingMaster,
        // loadClientFilingMaster,
        // clientClientFilingMasterDispatch: dispatch,
        // removeClientFilingMasters,
        fetchConfigurations,
        updateReminderConfiguration,
        createReminderConfiguration,
        updateClientFilingMasterWorkflow,
        getCustomerMasterWorkflowCommentsAttachments,
        clearClientFilingTrackers,
        changeClientFilingMasterApprover,
        changeFilingApprover,
        setRefreshPageDetails,
      }}
    >
      {props.children}
    </ClientFilingMasterContext.Provider>
  );
};

export default ClientFilingMasterState;
