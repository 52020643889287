import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Anchor } from "@mui/icons-material";
import AuthContext from "../../context/auth/AuthContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Link,
  ListItemIcon,
  ListItemButton,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Avatar,
  Chip,
} from "@mui/material";
import { DateTime } from "luxon";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DraftFilingMaster } from "../../types/FilingMaster";
import states from "../../data/StateLookup.json";
import axios from "axios";
import { lastIndexOf } from "lodash";
import FileDownload from "js-file-download";
import Loader from "../common/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";

type Anchor = "top" | "left" | "bottom" | "right";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function PostAwardApprovalListApprove(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  draft?: DraftFilingMaster;
  drafts?: DraftFilingMaster[];
  updateSelectedWorkflow?: (workflow: any) => void;
  refreshList: () => void;
}) {
  const filingMasterContext = useContext(FilingMasterContext);
  const {
    approveBulkPostAwardWorkflow,
    rejectBulkPostAwardWorkflow,
    getApproverList,
  } = filingMasterContext;
  const authContext = useContext(AuthContext);
  const {
    roleType,
    authUser,
    userRoles,
    getCustomerNotifications,
    getFilingNotifications,
    getCustomerNotificationsWorkFlow,
    setNotificationCount,
    toLocalDateTime,
  } = authContext;
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [filingsNames, setFilingsNames] = useState<(string | undefined)[] | []>(
    []
  );
  const [approvalComment, setApprovalComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [approvers, setApprovers] = useState<any[]>([]);
  // const [selectedApprover, setSelectedApprover] = useState<string>("");
  const [attachmentList, setAttachmentList] = useState<any>();
  const [showApproverAlert, setShowApproverAlert] = useState(false);
  const [customerNotificationCount, setCustomerNotificationCount] =
    React.useState(0);
  const [filingNotificationCount, setFilingNotificationCount] =
    React.useState(0);
  const [customerNotificationsWorkFlow, setCustomerNotificationsWorkFlow] =
    React.useState(0);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        // console.log(`Drawer Mouse Key Event...`);
        // if (
        //   event.type === "keydown" &&
        //   ((event as React.KeyboardEvent).key === "Tab" ||
        //     (event as React.KeyboardEvent).key === "Shift")
        // ) {
        //   return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };
  const closeAlert = () => {
    setShowAlert(false);
    // navigate("/filing-master-approval-list");
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const listApprovers = async () => {
    try {
      const { drafts } = props;
      if (!getApproverList || !drafts || drafts.length === 0) {
        return;
      }
      const data = await getApproverList();
      // console.log("Unfiltered Approvers", data);
      let filteredApprovers = [];
      if (drafts[0].juristiction === "State") {
        filteredApprovers = data.filter((approver: any) => {
          // const stateInfo = states.find(
          //   (i) => i.code === draft.stateInfo
          // )?.state;
          return (
            approver.juristiction === drafts[0].juristiction &&
            approver.state === drafts[0].stateInfo &&
            approver.customerId === null
          );
        });
      } else {
        filteredApprovers = data.filter((approver: any) => {
          return (
            approver.juristiction === drafts[0].juristiction &&
            approver.customerId === null
          );
        });
      }
      // console.log("Filtered Approvers", filteredApprovers);
      setApprovers(filteredApprovers);
    } catch (err) {
      console.error(err);
    }
  };

  const getFilename = function (str: string) {
    // console.log("getFilename", str);
    return str ? str.substring(str.lastIndexOf("/") + 1, str.length) : "";
  };

  const downloadFile = (
    attachmentPath: any,
    attachmentId: any,
    draftId: any
  ) => {
    // console.log(
    //   "Downloading File from:",
    //   attachmentId,
    //   attachmentPath,
    //   draftId
    // );
    // const fileName = getFilename(attachmentPath);
    // console.log("Downloading File:", fileName);
    if (props.drafts && props.drafts.length > 0) {
      let filingId = props?.drafts.filter((ele) => ele.draftId === draftId)[0]
        .filingId;
      axios({
        url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&FilingId=${filingId}&DraftId=${draftId}`,
        method: "POST",
        responseType: "blob", // Important
      }).then((response) => {
        FileDownload(response.data, attachmentPath);
      });
    }
  };

  const getAttachments = async () => {
    // console.log("getAttachments", props?.draft?.draftId);
    if (props.drafts) {
      for (const draft of props.drafts) {
        const draftId: number = draft.draftId ? draft.draftId : 0;
        try {
          const res = await axios.get(
            `${API_BASE_URL}/FilingMaster/FilingMasterDraftAttachments/${draftId}`
          );
          // console.log("getAttachments res", res.data.data);
          if (res.data) {
            setAttachmentList((prevState: any) => ({
              ...prevState,
              [draftId]: res.data.data,
            }));
          }
        } catch (error) {
          // Handle the error appropriately
          console.error(error);
        }
      }
    }
    // console.log("ATTS:", attachmentList);
  };

  useEffect(() => {
    if (showApproverAlert) {
      const timer = setTimeout(() => {
        setShowApproverAlert(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showApproverAlert]);

  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      // console.log(
      //   `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      // );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
      getAttachments();
    }
    listApprovers();
    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    // if(props.draft && props.draft.createDate!==null)
    //eslint-disable-next-line
  }, [props.draft]);

  const ListElement = (item: any, index: any) => {
    // console.log("ListElement", item, index);
    return (
      <Fragment key={index}>
        <ListItem disablePadding>
          <ListItemText
            primary={
              item && item.process && item.process !== null ? item.process : ""
            }
          />
        </ListItem>
        {item.changedData && item.changedData.length > 0 && (
          <TableContainer>
            <Table
              sx={{ minWidth: 250, border: "1px solid black" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Old value</TableCell>
                  <TableCell>New value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.changedData
                  .filter(
                    (ele: any) =>
                      ![
                        "filingDescription",
                        "dueDayofFrequency",
                        "jsidept",
                        "jsicontactEmail",
                      ].some((value) => value.includes(ele.field))
                  )
                  .map((ele: any) =>
                    ele.field === "Attachments" ? (
                      <TableRow>
                        <TableCell>{ele.field}</TableCell>
                        <TableCell>
                          {ele.old.map((attach: any) => (
                            <ListItem disablePadding>
                              <Link>
                                <AttachFileIcon />
                              </Link>
                              <ListItemButton
                                onClick={() =>
                                  downloadFile(
                                    attach.attachmentPath,
                                    attach.attachmentID,
                                    ele.draftId
                                  )
                                }
                              >
                                <ListItemText primary={attach.attachmentPath} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </TableCell>
                        <TableCell>
                          {ele.new.map((attach: any) => (
                            <ListItem disablePadding>
                              <Link>
                                <AttachFileIcon />
                              </Link>
                              <ListItemButton
                                onClick={() =>
                                  downloadFile(
                                    attach.attachmentPath,
                                    attach.attachmentID,
                                    ele.draftId
                                  )
                                }
                              >
                                <ListItemText primary={attach.attachmentPath} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </TableCell>
                      </TableRow>
                    )
                      //  : ele.field === "Due Date" ? (
                      //   <TableRow>
                      //     <TableCell>{ele.field}</TableCell>
                      //     <TableCell>
                      //       {ele.old.map((date: any) => (
                      //         <p>{date}</p>
                      //       ))}
                      //     </TableCell>
                      //     <TableCell>
                      //       {ele.new.map((date: any) => (
                      //         <p>{date}</p>
                      //       ))}
                      //     </TableCell>
                      //   </TableRow>
                      // )
                      : (
                        <TableRow>
                          <TableCell>{ele.field}</TableCell>
                          <TableCell>{String(ele.old)}</TableCell>
                          <TableCell>{String(ele.new)}</TableCell>
                        </TableRow>
                      )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Stack direction="row">
          <ListItem disablePadding>Approved Status</ListItem>
          <ListItem sx={{ textAlign: "right" }}>{item.responseStatus}</ListItem>
        </Stack>
      </Fragment>
    );
  };

  // useEffect(() => {
  //   const defaultApprover = approvers.find((approver) => approver.isdefault);
  //   if (defaultApprover) {
  //     setSelectedApprover(defaultApprover.approverId);
  //   }
  // }, [approvers]);

  useEffect(() => {
    // console.log("State");
    // setSelectedApprover("");
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      // console.log(
      //   `Toogle Drawer UED: DockAt:${props.dockAt}, Show: ${props.show}`
      // );
      // toggleDrawer(props.dockAt, props.show);
      setState({ ...state, [props.dockAt]: props.show });
    }
    setApprovalComment((old) => "");
    // listApprovers()
    //eslint-disable-next-line
  }, []);
  const toLocalTime = (date: Date): string => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");
      // console.log("@@@ LocalllTime:", _date);

      // console.log("Props", props?.draft);
      return _date;
    } else return "";
  };

  const getUserName = (userId: any) => {
    // console.log("getUserName", userId, userRoles);
    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]
        ?.userName
      : "";
    // console.log("getUserName", user);
    return user;
  };

  const list = (anchor: Anchor) => (
    <Grid container>
      <Stack
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
          minWidth: "35vw",
          display: "flex",
          alignContent: "center",
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography gutterBottom variant="h5" component="div">
                  Approval
                </Typography>
                <IconButton>
                  <CloseIcon onClick={() => handleClose(anchor)} />
                </IconButton>
              </Stack>
              {/* <Typography
              variant="h6"
              color="text.secondary"
              sx={{ mt: "0.5rem" }}
            >
              Filing Master Draft Submitted (
              {props.draft?.businessOperation?.toUpperCase()}):{" "}
              {props.draft?.filingName}
            </Typography> */}
              {/* <Typography variant="subtitle2" color="text.secondary">
              {props.draft &&
              props.draft.createDate &&
              props.draft.createDate !== null
                ? // ? format(props.draft.createDate, "dd-MMM-yyyy HH:MI:SS")
                  `${toLocalTime(props.draft.createDate)}`
                : ""}
            </Typography> */}
            </CardContent>

            <Box sx={{ mx: 2 }}>
              <Typography gutterBottom variant="h5" component="div">
                Approve Workflow
              </Typography>

              <List>
                {props.drafts &&
                  props.drafts.map((item: any, index: number) => (
                    <Fragment key={index}>
                      {index !== 0 && <Divider />}
                      <Stack direction="row" justifyContent="space-between">
                        <ListItem
                          disablePadding
                          style={{ fontWeight: "bold", fontSize: "16px" }}
                        >
                          {getUserName(item.createUser)}
                        </ListItem>
                        <ListItem >
                          <ListItemText
                            secondary={
                              item &&
                              item.createDate &&
                              toLocalDateTime &&
                              `${toLocalDateTime(item.createDate)}`
                            }
                          />
                        </ListItem>
                      </Stack>
                      <Stack direction="row">
                        <ListItem disablePadding>Filing Master Draft</ListItem>
                        <ListItem >
                          {item.filingName}
                        </ListItem>
                      </Stack>
                      <Stack direction="row">
                        <ListItem disablePadding>Submission Type</ListItem>
                        <ListItem sx={{ textAlign: "right" }}>
                          {item.businessOperation?.toUpperCase()}
                        </ListItem>
                      </Stack>
                      <Stack direction="row">
                        <ListItem disablePadding>Current Approver</ListItem>
                        <ListItem sx={{ textAlign: "right" }}>
                          {item.approverName}
                        </ListItem>
                      </Stack>
                      {item.modified &&
                        item.modified
                          .slice()
                          .reverse()
                          .map((item: any, index: any) =>
                            ListElement(item, index)
                          )}
                      {/* {attachmentList &&
                        attachmentList[item.draftId] &&
                        attachmentList[item.draftId].length > 0 && (
                          <Typography
                            variant="h6"
                            color="text.secondary"
                            sx={{ mt: "0.5rem" }}
                          >
                            Attachments:
                          </Typography>
                        )} */}
                      {/* <List>
                        {attachmentList &&
                          attachmentList[item.draftId] &&
                          attachmentList[item.draftId].map(
                            (item: any, index: number) => (
                              <Fragment key={index}>
                                <ListItem>
                                  <Link>
                                    <AttachFileIcon />
                                  </Link>
                                  <ListItemButton
                                    onClick={() =>
                                      downloadFile(
                                        item,
                                        item.attachmentPath,
                                        item.attachmentId
                                      )
                                    }
                                  >
                                    <ListItemText
                                      primary={
                                        item &&
                                        item.attachmentPath &&
                                        getFilename(item.attachmentPath)
                                      }
                                    />
                                  </ListItemButton>
                                </ListItem>
                              </Fragment>
                            )
                          )}
                      </List> */}
                    </Fragment>
                  ))}
              </List>
              <TextField
                id="notes"
                name="notes"
                label="Notes"
                multiline
                fullWidth
                minRows={3}
                variant="filled"
                placeholder={"Enter Comments..."}
                value={approvalComment}
                onChange={onCommentChange}
                sx={{ marginTop: 1 }}
              />
            </Box>
            <CardActions
              sx={{
                mt: "3rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <MuiButton
                color="primary"
                sx={{ margin: 1, padding: 1 }}
                onClick={() => handleClose(anchor)}
              >
                Cancel
              </MuiButton>
              <MuiButton
                variant="contained"
                color="secondary"
                sx={{ margin: 1, padding: 1 }}
                onClick={() => handleCancel(anchor, false)}
              >
                Reject
              </MuiButton>
              <MuiButton
                variant="contained"
                type="submit"
                sx={{ margin: 1, padding: 1 }}
                onClick={() => handleOk(anchor, true)}
              >
                Approve
              </MuiButton>
            </CardActions>
          </Card>
      </Stack>
    </Grid>
  );

  const onCommentChange = async (e: React.ChangeEvent<any>) => {
    setApprovalComment((old) => e.target.value);
    // await hasTokenExpired();
    // let value = "";
    // if (e.target.name === "groupName") {
    //   getGroupId();
    // }
    // // console.log(`Setting [${e.target.name}]: ${e.target.value}`);
    // setFilingMaster((master) => ({
    //   ...master,
    //   [e.target.name]: e.target.value,
    // }));
    // // console.log(filingMaster);
  };
  const fetchNotificationCount = async () => {
    // console.log("CustomerNotification", getCustomerNotifications);
    // console.log("filingNotifications", getFilingNotifications);

    if (
      getCustomerNotifications &&
      getFilingNotifications &&
      getCustomerNotificationsWorkFlow
    ) {
      // console.log("@@Fetch Customers2:");
      try {
        let custList = await getCustomerNotifications();
        custList = custList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        // console.log("@@customerNotifications:", custList);
        // setTotalNotificationCount((old) => old + custList.length);

        setCustomerNotificationCount(custList.length);
        let filingList = await getFilingNotifications();
        filingList = filingList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        // console.log("@@filingNotifications:", filingList);
        // setTotalNotificationCount((old) => old + filingList.length);
        setFilingNotificationCount(filingList.length);
        let custListWorkflow = await getCustomerNotificationsWorkFlow();
        custListWorkflow = custListWorkflow
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");
        // console.log("@@filingNotifications:", custListWorkflow);

        setCustomerNotificationsWorkFlow(custListWorkflow.length);
        // setTotalNotificationCount(
        //   (old) =>
        //     custList?.length + filingList?.length + custListWorkflow?.length
        // );
        const count =
          custList?.length + filingList?.length + custListWorkflow?.length;
        if (setNotificationCount) {
          setNotificationCount(count);
        }
      } catch (error) {
        // console.log("@@Error:", error);
      }
    }
  };

  const handleOk = async (anchor: Anchor, approvalStatus?: boolean) => {
    // navigate("/filing-master-list");
    // console.log("Approving Draft:", props?.draft);
    // console.log(
    //   `Approve:${props?.draft?.workflowId}, Action: ${approvalStatus} Comment ${approvalComment}`
    // );

    try {
      if (props.drafts) {
          setIsLoading(true);
        const bulkPayload = props.drafts.map((draft) => {
          return {
            notes: approvalComment,
            role: roleType.includes("Admin-PostAward")
              ? "Admin-PostAward"
              : authUser?.roleName,
            userid: authUser?.userId + "",
            workFlowId: draft?.workflowId,
            draftId: draft?.draftId,
          };
        });

        if (approveBulkPostAwardWorkflow) {
          const res = await approveBulkPostAwardWorkflow(bulkPayload);

          if (res) {
            // console.log(`Workflow has been approved: ${res.filingName}`);
            props.refreshList()
            if (props.drafts && Array.isArray(props.drafts)) {
              setFilingsNames(props.drafts.map((item) => item.filingName));
            }
            setAlertMessage("Successfully approved the workflow(s) for");
            //  setAlertMessage("Successfully approved the workflow(s)");
            setShowAlert(true);
            setApprovalComment((old) => "");
            await fetchNotificationCount();
            props.updateSelectedWorkflow &&
              props.updateSelectedWorkflow(undefined);
            setIsLoading(false);
            setState({ ...state, [anchor]: false });
            props.handleClose();
            return;
          }
        }
      }
    } catch (error) {
      if (props.drafts && Array.isArray(props.drafts)) {
        setFilingsNames(props.drafts.map((item) => item.filingName));
      }
      setAlertMessage("Unexpected Error occured during Workflow approval for");
      setShowAlert(true);
      setApprovalComment((old) => "");
    }
    setIsLoading(false);
  };

  const handleClose = async (anchor: Anchor) => {
    setState({ ...state, [anchor]: false });
    props.handleClose();
    // setSelectedApprover("w");
    setApprovers([]);
    setApprovalComment((old) => "");
  };

  const handleCancel = async (anchor: Anchor, approvalStatus?: boolean) => {
    // navigate("/filing-master-list");
    // console.log("Approving Draft:", props?.draft);
    // console.log(
    //   `Approve:${props?.draft?.workflowId}, Action: ${approvalStatus} Comment ${approvalComment}`
    // );
    try {
      if (props.drafts) {
        setIsLoading(true);
        const bulkPayload = props.drafts.map((draft) => {
          return {
            notes: approvalComment,
            role: roleType.includes("Admin-PostAward")
              ? "Admin-PostAward"
              : authUser?.roleName,
            userid: authUser?.userId + "",
            workFlowId: draft?.workflowId,
            draftId: draft?.draftId,
          };
        });
        if (rejectBulkPostAwardWorkflow) {
          const res = await rejectBulkPostAwardWorkflow(bulkPayload);
          if (res) {
            // console.log(`Workflow has been rejected: ${res.filingName}`);
            props.refreshList()
            if (props.drafts && Array.isArray(props.drafts)) {
              setFilingsNames(props.drafts.map((item) => item.filingName));
            }
            setAlertMessage(`Workflow(s) has been rejected for `);
            //  setAlertMessage("Workflow has been rejected");
            setShowAlert(true);
            setApprovalComment((old) => "");
            await fetchNotificationCount();
            props.updateSelectedWorkflow &&
              props.updateSelectedWorkflow(undefined);
              setIsLoading(false);
              setState({ ...state, [anchor]: false });
              props.handleClose();
            return;
          }
        }
      }
    } catch (error) {
      setAlertMessage("Unexpected Error occured during Workflow rejection");
      setShowAlert(true);
      setApprovalComment((old) => "");
    }
  };

  useEffect(() => {
    if (authUser && authUser?.userId) fetchNotificationCount();
  }, [authUser]);

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <MuiButton onClick={toggleDrawer(anchor, true)}>{anchor}</MuiButton> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
        {filingsNames.length > 0 && (
          <ul>
            {Array.from(new Set(filingsNames)).map((name, index) => (
              <li key={index}><strong>{name}</strong></li>
            ))}
          </ul>
        )}
      </Dialog>
      <style>{`
    .p-dialog .p-dialog-content {
      padding-bottom: 1px;
    }
    .p-dialog .p-dialog-header {
      padding-bottom: 0px;
    }
  `}</style>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
          {isLoading && (
          <Loader />
          )}
        </React.Fragment>
      )}
    </div>
  );
}
