import React, { useState, useEffect, useContext, Fragment } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Paper,
  Tab,
  Tabs,
  DialogTitle,
  DialogActions,
  Autocomplete,
  TextField,
  FormHelperText,
} from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Typography from "@mui/material/Typography";
import { FilterMatchMode } from "primereact/api";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
// import PageNavbar from "./componentPageNavbar";

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DirectionsIcon from "@mui/icons-material/Directions";
import TurnRightIcon from "@mui/icons-material/TurnRight";
import SouthIcon from "@mui/icons-material/South";
import MenuItem from "@mui/material/MenuItem";
import MultiSelect from "../common/MultiSelect";
import states from "../../data/StateLookup.json";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { borderLeft } from "@mui/system";
// import DeleteIcon from  "@mui/icons-material"
// import { Checkbox } from 'primereact/checkbox';
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import useDialog from "../common/UseDialog";
import PostAwardApproverTable from "./PostAwardApproverTable";
import AuthContext from "../../context/auth/AuthContext";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import PostAwardApprovalConfigToolbox from "./PostAwardApproverConfigToolbox";
import ApprovalConfigHistory from "../customer/ApprovalConfigHistory";
// import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import _ from "lodash";
import Loader from "../common/Loader";
import { CircularProgress } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function PostAwardApproverConfiguration() {
  const [name, setName] = React.useState("");
  const [state, setState] = React.useState("");
  const [selectCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerObj, setSelectCustomerObj] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [approverList, setApproverList] = useState([]);
  const [clientApproverList, setClientApproverList] = useState([]);
  const [approverTableData, setApproverTableData] = useState([]);
  const [approverMasterTableData, setApproverMasterTableData] = useState([]);
  const [value, setValue] = React.useState("");
  const [selectedRadio, setSelectedRadio] = React.useState("");
  const [selectedApproverData, setSelectedApproverData] = React.useState([]);
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [selectReset, setSelectReset] = useState(false);
  const [approverId, setApproverId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [tabValue, setTabValue] = useState("1");
  // const [sendapproverList,setSendApproverList]=useState([])
  const [masterApproverList, setMasterApproverList] = React.useState("");
  const [masterApproverId, setMasterApproverId] = useState(null);
  const [masterSelectedRadio, setMasterSelectedRadio] = React.useState("");
  const [masterSelectedState, setMasterSelectedState] = useState([]);
  const [masterSelectedApproverData, setMasterSelectedApproverData] =
    React.useState([]);
  const [activityHistory, setActivityHistory] = React.useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [deleteData, SetDeleteData] = React.useState(null);
  const [isDefaultData, setIsDefaultData] = React.useState(null);
  const [openChangeDefault, setOpenChangeDefault] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [selectedTableRow, setSelectedTableRow] = React.useState("");
  const [selectedMasterTableRow, setSelectedMasterTableRow] =
    React.useState("");
  const [errors, setErrors] = useState({});
  const [masterErrors, setMasterErrors] = useState({});
  const authContext = useContext(AuthContext);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  // const clientFilingContext = useContext(ClientFilingMasterContext);
  const { authUser } = authContext;
  // const { changeFilingApprover } = clientFilingContext;
  //Get Customer List
  const getCustomerList = async () => {
    try {
      let res = await axios.get(`${API_BASE_URL}/Customer/CustomerList`);
      setCustomerList(res.data);
      getApproverList(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLists();
  }, []);

  const getLists = async () => {
    await getCustomerList();
    await getUserList();
  };

  //Get User List
  const getUserList = async () => {
    try {
      let res = await axios.get(`${API_BASE_URL}/Application/UserRole`);
      console.log("data#", res.data);
      const filteredApprovalList = res.data.filter(
        (user) =>
          user.roleName === "Admin-PostAward" ||
          user.roleName === "JSISME-PostAward" ||
          user.roleName === "JSIPOC-PostAward"
      );
      console.log("filteredApprovalList", filteredApprovalList);
      setApproverList(filteredApprovalList);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("approvalList$", approverList);
  //Get Approver List
  const getApproverList = async (clientList) => {
    setIsLoadingTable(true);
    try {
      let res = await axios.get(`${API_BASE_URL}/Application/ListApprovers`);
      let res1 = res.data.data.filter((ele) => ele.filingType === "PostAwardClientFiling" );
      let res2 = res.data.data.filter((ele) => ele.filingType === "PostAwardMasterFiling" );

      console.log("ApproverList res", res.data.data);
      let appoverTable = res1.filter(
        (ele) =>
          ele.customerId !== null && ele.filingType === "PostAwardClientFiling"
      );
      console.log(
        "&&appoverTable PostAwardClientFiling",
        appoverTable,
        clientList
      );
      appoverTable = appoverTable.map((approver) => {
        console.log("-->appoverTable", approver);
        let client = clientList.filter(
          (ele) => ele.customerId === approver.customerId
        )[0];
        approver.client = client ? client.customerName : "";
        return approver;
      });
      const groupByClient = appoverTable.reduce((group, product) => {
        const { client } = product;
        group[client] = group[client] ?? [];
        group[client].push(product);
        return group;
      }, {});

      console.log("appoverTable--->>", appoverTable, groupByClient);

      for (const client in groupByClient) {
        console.log(`${client}: ${groupByClient[client]}`);
        let groupByState = groupByClient[client].reduce((states, ele) => {
          const { state, juristiction } = ele;
          if (juristiction === "Federal") {
            states["Federal"] = states["Federal"] ?? [];
            states["Federal"].push(ele);
          } else {
            states[state] = states[state] ?? [];
            states[state].push(ele);
          }
          return states;
        }, {});
        console.log("appoverTable--->> groupByState", groupByState);
        groupByClient[client] = { ...groupByState };
      }
      Object.entries(groupByClient).forEach(([clientName, value]) => {
        console.log("sendMasterApprover clients", clientName, value);
        Object.entries(value).forEach((stateName) => {
          console.log("sendMasterApprover state", stateName[1]);
          const hasDefault = stateName[1].some((ele) => ele.isdefault);
          if (!hasDefault) {
            stateName[1][0].isdefault = true;
            groupByClient[clientName][stateName[0]] = stateName[1];
            // updateApprover([stateName[1]]);
          }
        });
      });
      console.log("&&appoverTable--->>", appoverTable, groupByClient);
      setApproverTableData(groupByClient);

      //master table data
      let masterApproverTable = res2
        .filter(
          (ele) =>
            ele.customerId === null &&
            ele.filingType === "PostAwardMasterFiling"
        )
        .reduce((states, ele) => {
          const { state, juristiction } = ele;

          if (juristiction === "Federal") {
            states["Federal"] = states["Federal"] ?? [];
            states["Federal"].push(ele);
          } else {
            states[state] = states[state] ?? [];
            states[state].push(ele);
          }
          return states;
        }, {});
      console.log(
        "&&approverTable--->>PostAwardMasterFiling ",
        masterApproverTable
      );

      Object.entries(masterApproverTable).forEach(([i, states]) => {
        console.log("sendMasterApprover states", i, states);
        const hasDefault = states.some((ele) => ele.isdefault);
        if (!hasDefault) {
          states[0].isdefault = true;
          masterApproverTable[i] = states;
          updateMasterApprover([states[0]]);
        }
      });
      console.log("&&masterApproverTable--->>", masterApproverTable);
      setApproverMasterTableData(masterApproverTable);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingTable(false);
    }
  };

  const validate = () => {
    let errors = {};
    if (!name) {
      errors.name = "Client Name is required";
    }
    if (!value) {
      errors.value = "Approver Name is required";
    }
    if (!selectedRadio) {
      errors.radio = "Please select Jurisdiction";
    }
    if (selectedRadio === "State" && selectedState.length === 0) {
      errors.stateInfo = "Please select Jurisdiction State";
    }
    console.log("validate", errors);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFocus = (name) => {
    setErrors({ ...errors, [name]: undefined });
  };

  const validateMaster = () => {
    let errors = {};
    console.log("validateMaster", masterApproverList);
    if (!masterApproverList) {
      errors.masterApproverList = "Approver Name is required";
    }
    if (!masterSelectedRadio) {
      errors.masterRadio = "Please select Jurisdiction";
    }
    if (masterSelectedRadio === "State" && masterSelectedState.length === 0) {
      errors.masterSelectedState = "Please select Jurisdiction State";
    }

    console.log("validate Master", errors);
    setMasterErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleMasterFocus = (name) => {
    setMasterErrors({ ...errors, [name]: undefined });
  };

  const [filters, setFilters] = useState({
    client: { value: null, matchMode: FilterMatchMode.CONTAINS },
    approverName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    juristiction: { value: null, matchMode: FilterMatchMode.CONTAINS },
    state: { value: null, matchMode: FilterMatchMode.CONTAINS },
    isdefault: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // const updateApprover= async (data) => {
  //   console.log("sendMasterApprover", data);

  //   Object.entries(data).forEach(([i, iStates]) => {
  //     console.log("sendMasterApprover ele", iStates);

  //     const states = iStates.filter((item) => {
  //       console.log(
  //         "Type ele",
  //         typeof item.approverId,
  //         typeof isDefaultData.oldApproverId,
  //         isDefaultData.oldApproverId,
  //         isDefaultData.approverId
  //       );
  //       return (
  //         item.customerId === isDefaultData.customerId &&
  //         item.state === isDefaultData.stateName &&
  //         (item.approverId === isDefaultData.approverId ||
  //           item.approverId === isDefaultData.oldApproverId)
  //       );
  //     });
  //     console.log("sendMasterApprover ele states", states);
  //     states.forEach(async (ele) => {
  //       let postObj = {
  //         id: ele.id,
  //         approverId: ele.approverId,
  //         approverName: ele.approverName,
  //         customerId: ele.customerId,
  //         juristiction: ele.juristiction,
  //         state: ele.state,
  //         isdefault: ele.isdefault,
  //         approverGroupId: 1,
  //         filingType: "ClientFiling",
  //         // createDate: 2023-03-28T03:53:25.589Z,
  //         createUser: "string",
  //         // updateDate: 2023-03-28T03:53:25.589Z,
  //         updateUser: "string",
  //       };
  //       console.log("send data is passing", postObj);

  //       await axios
  //         .put(`${API_BASE_URL}/Application/UpdateApprovers`, postObj)
  //         .then(async (res) => {
  //           console.log("send data res", res);
  //           if (res.data.errorMessage) {
  //             // showAlert("Error", res.data.errorMessage, "error");
  //             setAlertMessage(res.data.errorMessage);
  //             setShowAlert(true);
  //             handleReset();
  //           } else {
  //             if (ele.approverId === isDefaultData.approverId) {
  //               try {
  //                 // const reassignPayload = {
  //                 //   oldApproverId: isDefaultData.oldApproverId,
  //                 //   newApproverId: ele.approverId,
  //                 //   state: ele.state,
  //                 //   customerId: ele.customerId,
  //                 // };
  //                 // console.log("Reassign Payload", reassignPayload);
  //                 // const res = await changeFilingApprover(reassignPayload);
  //                 // console.log("ChangeApprover Res", res);
  //                 setAlertMessage("Successfully updated default Approver");
  //                 setShowAlert(true);
  //                 handleReset();
  //                 getCustomerList();
  //                 setIsDefaultData(null);
  //               } catch (error) {
  //                 setAlertMessage(
  //                   "Unexpected Error Occured while reassiging the Workflow(s)"
  //                 );
  //                 setShowAlert(true);
  //                 handleReset();
  //                 setIsDefaultData(null);
  //               }
  //             } else {
  //               setAlertMessage("Successfully updated the Approver(s)");
  //               setShowAlert(true);
  //               handleReset();
  //               getCustomerList();
  //               setIsDefaultData(null);
  //             }
  //           }
  //         });
  //     });
  //   });
  // };
  const updateApprover = async (data) => {
    setIsLoadingTable(true);
    console.log("sendMasterApprover", data);
    Object.entries(data).forEach(([i, iStates]) => {
      console.log("sendMasterApprover ele", iStates);

      const states = iStates.filter((item) => {
        console.log(
          "Type ele",
          typeof item.approverId,
          typeof isDefaultData.oldApproverId,
          isDefaultData.oldApproverId,
          isDefaultData.approverId
        );
        return (
          item.customerId === isDefaultData.customerId &&
          item.state === isDefaultData.stateName &&
          (item.approverId === isDefaultData.approverId ||
            item.approverId === isDefaultData.oldApproverId)
        );
      });
      console.log("sendMasterApprover ele states", states);
      states.forEach(async (ele) => {
        let postObj = {
          id: ele.id,
          approverId: ele.approverId,
          approverName: ele.approverName,
          customerId: ele.customerId,
          juristiction: ele.juristiction,
          state: ele.state,
          isdefault: ele.isdefault,
          approverGroupId: 1,
          filingType: "PostAwardClientFiling",
          // createDate: 2023-03-28T03:53:25.589Z,
          createUser: ele.createUser,
          // updateDate: 2023-03-28T03:53:25.589Z,
          updateUser: authUser.userId + "",
        };
        console.log("send data is passing", postObj);

        await axios
          .put(`${API_BASE_URL}/Application/UpdateApprovers?Id=${postObj.id}`, postObj)
          .then(async (res) => {
            console.log("send data res", res);
            if (res.data.errorMessage) {
              // showAlert("Error", res.data.errorMessage, "error");
              setIsLoadingTable(false);
              setAlertMessage(res.data.errorMessage);
              setShowAlert(true);
              handleReset();
            } else {
              if (ele.approverId === isDefaultData.approverId) {
                try {
                  // const reassignPayload = {
                  //   oldApproverId: isDefaultData.oldApproverId,
                  //   newApproverId: ele.approverId,
                  //   state: ele.state,
                  //   customerId: ele.customerId,
                  // };
                  // console.log("Reassign Payload", reassignPayload);
                  // const res = await changeFilingApprover(reassignPayload);
                  // console.log("ChangeApprover Res", res);
                  setIsLoadingTable(false);
                  setAlertMessage("Successfully updated default Approver");
                  setShowAlert(true);
                  handleReset();
                  getCustomerList();
                  setIsDefaultData(null);
                } catch (error) {
                  setIsLoadingTable(false);

                  setAlertMessage(
                    "Unexpected Error Occured while reassiging the Workflow(s)"
                  );
                  setShowAlert(true);
                  handleReset();
                  setIsDefaultData(null);
                }
              } else {
                setIsLoadingTable(false);
                setAlertMessage("Successfully updated the Approver(s)");
                setShowAlert(true);
                handleReset();
                getCustomerList();
                setIsDefaultData(null);
              }
            }
          });
      });
    });
  };
  const updateMasterApprover = async (data) => {
    setIsLoadingTable(true);
    console.log("sendMasterApprover", data);
    const processData = data.filter(
      (item) =>
        item.state === isDefaultData.stateName &&
        (item.approverId === isDefaultData.approverId ||
          item.approverId === isDefaultData.oldApproverId)
    );
    processData.forEach(async (ele) => {
      let postObj = {
        id: ele.id,
        approverId: ele.approverId,
        approverName: ele.approverName,
        customerId: ele.customerId,
        juristiction: ele.juristiction,
        state: ele.state,
        isdefault: ele.isdefault,
        approverGroupId: 1,
        filingType: "PostAwardMasterFiling",
        // createDate: 2023-03-28T03:53:25.589Z,
        createUser: ele.createUser,
        // updateDate: 2023-03-28T03:53:25.589Z,
        updateUser: authUser.userId + "",
      };
      console.log("send data is passing", postObj);
      await axios
        .put(`${API_BASE_URL}/Application/UpdateApprovers?Id=${postObj.id}`, postObj)
        .then(async (res) => {
          console.log("send data res", res);
          if (res.data.errorMessage) {
            setIsLoadingTable(false);
            setAlertMessage(res.data.errorMessage);
            setShowAlert(true);
            handleReset();
            setIsDefaultData(null);
          } else {
            // showAlert("Success", "Successfully added Approver", "success");
            if (ele.approverId === isDefaultData.approverId) {
              try {
                // const reassignPayload = {
                //   oldApproverId: isDefaultData.oldApproverId,
                //   newApproverId: ele.approverId,
                //   state: ele.state,
                // };
                // console.log("Reassign Payload", reassignPayload);
                // const res = await changeFilingApprover(reassignPayload);
                // console.log("ChangeApprover Res", res);
                setIsLoadingTable(false);
                setAlertMessage("Successfully updated default Approver");
                setShowAlert(true);
                handleReset();
                getCustomerList();
                setIsDefaultData(null);
              } catch (error) {
                setIsLoadingTable(false);
                setAlertMessage(
                  "Unexpected Error Occured while reassiging the Workflow(s)"
                );
                setShowAlert(true);
                handleReset();
                setIsDefaultData(null);
              }
            }
          }
        });
    });
  };

  //on Submit
  const sendApprover = async (data) => {
    setIsLoadingTable(true);
    console.log("ClientFiling##", isLoadingTable);
    try {
      console.log("sendMasterApprover", data);
      let postObj = [];
      // Object.entries(data).forEach(([i, states]) => {
      //   console.log("sendMasterApprover ele", states);
      data.forEach((ele) => {
        postObj.push({
          approverId: ele.approverId,
          approverName: ele.approverName,
          customerId: ele.customerId,
          juristiction: ele.juristiction,
          state: ele.state,
          isdefault: ele.isdefault,
          approverGroupId: 1,
          filingType: "PostAwardClientFiling",
          createDate: new Date(),
          createUser: authUser.userId + "",
          // updateDate: 2023-03-28T03:53:25.589Z,
          // updateUser: authUser.userId + "",
        });
      });
      // });
      console.log("send data is passing", postObj);
      await axios
        .post(`${API_BASE_URL}/Application/CreateApprovers`, postObj)
        .then((res) => {
          console.log("send data res", res);
          if (res.data.errorMessage) {
            setAlertMessage(res.data.errorMessage);
            setShowAlert(true);
            handleReset();
          } else {
            // closeDialog();
            // showAlert("Success", "Successfully added Approver**", "success");
            console.log("SHOW ALERT");
            setAlertMessage("Successfully added Approver");
            setShowAlert(true);
            handleReset();
            getCustomerList();
          }
        })
        .catch((error) => {
          console.log("Error:", error);
          console.log("Error:", error.response?.data);
          // showAlert("Error", error.response?.data, "error");
          setAlertMessage(error.response?.data);
          setShowAlert(true);
          // closeDialog();
          handleReset();
        });
    } finally {
      setIsLoadingTable(false);
    }
  };
  const sendMasterApprover = async (data) => {
    setIsLoadingTable(true);
    console.log("masterFiling##", isLoadingTable);

    console.log("sendMasterApprover", data);

    let postObj = [];
    try {
      data.forEach((ele) => {
        postObj.push({
          approverId: ele.approverId,
          approverName: ele.approverName,
          customerId: ele.customerId,
          juristiction: ele.juristiction,
          state: ele.state,
          isdefault: ele.isdefault,
          filingType: "PostAwardMasterFiling",
          approverGroupId: 1,
          createDate: new Date(),
          createUser: authUser.userId + "",
          // updateDate: 2023-03-28T03:53:25.589Z,
          // updateUser: authUser.userId + "",
        });
      });
      console.log("sendMasterApprover payload", postObj);
      await axios
        .post(`${API_BASE_URL}/Application/CreateApprovers`, postObj)
        .then((res) => {
          console.log("send data res", res);
          if (res.data.errorMessage) {
            // showAlert("Error", res.data.errorMessage, "error");
            // closeDialog();
            setAlertMessage(res.data.errorMessage);
            setShowAlert(true);
            handleMasterReset();
          } else {
            // closeDialog();
            // showAlert(
            //   "Success",
            //   "------------Successfully added Approver",
            //   "success"
            // );
            console.log("SHOW ALERT2");
            setAlertMessage("Successfully added Approver");
            setShowAlert(true);
            handleMasterReset();
            getCustomerList();
          }
        })
        .catch((error) => {
          console.log("Error:", error);
          // showAlert("Error", error.response?.data, "error");
          // closeDialog();
          setAlertMessage(error.response?.data);
          setShowAlert(true);
          handleMasterReset();
        });
    } finally {
      setIsLoadingTable(false);
    }
  };

  useEffect(() => {
    console.log("selectedApproverData", selectedApproverData);
  }, [selectedApproverData]);

  //Collect Approver Data
  const collectApproverData = () => {
    let isSelectedState =
      selectedRadio === "State" ? selectedState.length > 0 : true;
    console.log(
      "collectApproverData",
      value,
      name,
      selectedRadio,
      isSelectedState
    );
    const isValid = validate();
    if (isValid) {
      if (
        name !== "" &&
        value !== "" &&
        selectedRadio !== "" &&
        isSelectedState
      ) {
        let selectedApprover;
        selectedApprover = JSON.parse(JSON.stringify(approverTableData));
        selectedApprover[name] = selectedApprover[name] ?? [];

        let payload = [];
        let showAlert = false;
        if (selectedRadio === "Federal") {
          selectedApprover[name]["Federal"] =
            selectedApprover[name]["Federal"] ?? [];
          if (
            selectedApprover[name]["Federal"].some(
              (approver) => approver.approverId === approverId
            )
          ) {
            setAlertMessage(`Approver already exists for Federal`);
            setShowAlert(true);
            handleReset();
          } else {
            payload.push({
              customerName: name,
              approverName: value,
              juristiction: "Federal",
              state: null,
              customerId: customerId,
              approverId: approverId,
              isdefault: selectedApprover[name]["Federal"].length <= 0,
            });
          }
        } else {
          selectedState.forEach((ele) => {
            selectedApprover[name][ele] = selectedApprover[name][ele] ?? [];
            let approverExists = selectedApprover[name][ele].some(
              (approver) => approver.approverId === approverId
            );
            console.log("approver exist", approverExists);
            if (approverExists) {
              setAlertMessage(`Approver already exists for ${ele}`);
              setShowAlert(true);
              handleReset();
            } else {
              payload.push({
                customerName: name,
                approverName: value,
                juristiction: "State",
                state: ele,
                customerId: customerId,
                approverId: approverId,
                isdefault: selectedApprover[name][ele].length <= 0,
              });
            }
          });
        }
        // if (showAlert) {
        //   if (selectedRadio === "Federal") {
        //     setAlertMessage("Approver already exists!");
        //   }
        //   else {
        //     setAlertMessage(`Approver already exists for ${showAlert}` );
        //   }
        //   setShowAlert(true);
        //   handleReset();

        // } else {
        // process payload here
        if (payload.length > 0) {
          sendApprover(payload).then(() => {
            console.log(
              "selectedApprover isClient ->",
              selectedApprover,
              approverTableData
            );

            // setShowAlert(true);
            // handleReset();
            // getCustomerList();
          });
        }
        // setAlertMessage("Successfully added Approver")

        // }

        // if (selectedApproverData.length > 0) {
        //   selectedApprover = [...selectedApproverData];
        //   let isClient = selectedApprover.filter((ele) => ele.client === name);
        //   console.log("selectedApprover isClient", isClient);
        //   if (isClient.length > 0) {
        //     if (selectedRadio === "Federal") {
        //       let isFederal = isClient[0].states.filter(
        //         (ele) => ele.state === "Federal"
        //       );
        //       console.log("selectedApprover isFederal", isFederal);
        //       if (isFederal.length > 0) {
        //         let isApprover = isFederal[0].approvers.filter(
        //           (ele) => ele.ApproverName === value
        //         );
        //         console.log(
        //           "selectedApprover isApprover",
        //           isApprover,
        //           isApprover.length <= 0
        //         );
        //         if (isApprover.length <= 0) {
        //           let newApprover = {
        //             ApproverName: value,
        //             id: approverId,
        //             isDefault: false,
        //           };
        //           isFederal[0].approvers.push(newApprover);
        //         }
        //       } else {
        //         let newFederal = {
        //           state: "Federal",
        //           approvers: [
        //             { ApproverName: value, id: approverId, isDefault: true },
        //           ],
        //         };

        //         isClient[0].states.push(newFederal);
        //       }
        //     } else {
        //       selectedState.forEach((state) => {
        //         let isState = isClient[0].states.filter(
        //           (ele) => ele.state === state
        //         );
        //         console.log("selectedApprover isState", isState);
        //         if (isState.length > 0) {
        //           let isApprover = isState[0].approvers.filter(
        //             (ele) => ele.ApproverName === value
        //           );
        //           console.log(
        //             "selectedApprover isApprover",
        //             isApprover,
        //             isApprover.length <= 0
        //           );
        //           if (isApprover.length <= 0) {
        //             let newApprover = {
        //               ApproverName: value,
        //               id: approverId,
        //               isDefault: false,
        //             };
        //             isState[0].approvers.push(newApprover);
        //           }
        //         } else {
        //           let newState = {
        //             state: state,
        //             approvers: [
        //               { ApproverName: value, id: approverId, isDefault: true },
        //             ],
        //           };
        //           isClient[0].states.push(newState);
        //           console.log(
        //             "selectedApprover newState",
        //             newState,
        //             selectedApprover
        //           );
        //         }
        //       });
        //     }
        //   } else {
        //     let newClient = {
        //       client: name,
        //       customerId: customerId,
        //       states:
        //         selectedRadio === "Federal"
        //           ? [
        //               {
        //                 state: "Federal",
        //                 approvers: [
        //                   {
        //                     ApproverName: value,
        //                     id: approverId,
        //                     isDefault: true,
        //                   },
        //                 ],
        //               },
        //             ]
        //           : selectedState.map((ele) => ({
        //               state: ele,
        //               approvers: [
        //                 { ApproverName: value, id: approverId, isDefault: true },
        //               ],
        //             })),
        //     };
        //     selectedApprover.push(newClient);
        //     console.log(
        //       "selectedApprover newClient",
        //       newClient,
        //       selectedApprover
        //     );
        //   }
        // } else {
        //   selectedApprover = [
        //     {
        //       client: name,
        //       customerId: customerId,
        //       states:
        //         selectedRadio === "Federal"
        //           ? [
        //               {
        //                 state: "Federal",
        //                 approvers: [
        //                   {
        //                     ApproverName: value,
        //                     id: approverId,
        //                     isDefault: true,
        //                   },
        //                 ],
        //               },
        //             ]
        //           : selectedState.map((ele) => ({
        //               state: ele,
        //               approvers: [
        //                 { ApproverName: value, id: approverId, isDefault: true },
        //               ],
        //             })),
        //     },
        //   ];
        // }
        // setSelectedApproverData(selectedApprover);
        // console.log("selectedApprover array ->", selectedApprover);
      }
    }
  };
  const collectMasterApproverData = () => {
    console.log(
      "collectMasterApproverData",
      masterSelectedApproverData,
      masterApproverList,
      masterSelectedRadio,
      masterSelectedState
    );

    const isValid = validateMaster();
    console.log("Valid", isValid);
    if (isValid) {
      let isSelectedState =
        masterSelectedRadio === "State" ? masterSelectedState.length > 0 : true;
      if (
        masterApproverList !== "" &&
        masterSelectedRadio !== "" &&
        masterSelectedRadio !== " " &&
        masterSelectedState
      ) {
        let selectedApprover;
        selectedApprover = JSON.parse(JSON.stringify(approverMasterTableData));

        let payload = [];
        let showAlert = false;
        if (masterSelectedRadio === "Federal") {
          selectedApprover["Federal"] = selectedApprover["Federal"] ?? [];
          if (
            selectedApprover["Federal"].some(
              (approver) => approver.approverId === masterApproverId
            )
          ) {
            setAlertMessage(`Approver already exists for Federal`);
            setShowAlert(true);
            handleMasterReset();
          } else {
            payload.push({
              approverName: masterApproverList,
              juristiction: "Federal",
              state: null,
              approverId: masterApproverId,
              isdefault: selectedApprover["Federal"].length <= 0,
            });
          }
        } else {
          masterSelectedState.forEach((ele) => {
            selectedApprover[ele] = selectedApprover[ele] ?? [];
            let approverExists = selectedApprover[ele].some(
              (approver) => approver.approverId === masterApproverId
            );
            console.log("approver exist", approverExists);
            if (approverExists) {
              setAlertMessage(`Approver already exists for ${ele}`);
              setShowAlert(true);
              handleMasterReset();
            } else {
              payload.push({
                approverName: masterApproverList,
                juristiction: "State",
                state: ele,
                approverId: masterApproverId,
                isdefault: selectedApprover[ele].length <= 0,
              });
            }
          });
        }
        // if (showAlert) {
        //   if (selectedRadio === "Federal") {
        //     setAlertMessage("Approver already exists!");
        //   }
        //   else {
        //     setAlertMessage(`Approver already exists for ${showAlert}` );
        //   }
        //   setShowAlert(true);
        //   handleReset();
        // }
        if (payload.length > 0) {
          sendMasterApprover(payload).then(() => {
            console.log(
              "selectedApprover isClient ->",
              selectedApprover,
              approverTableData
            );
            //  setAlertMessage("Successfully added Approver");
            //  setShowAlert();
            // setAlertMessage("Successfully added Approver");
            // setShowAlert(true);
            // handleMasterReset();
          });
        }
        //   });
        // if (masterSelectedApproverData.length > 0) {

        //   if (masterSelectedRadio === "Federal") {
        //     let isFederal = selectedApprover[0].states.filter(
        //       (ele) => ele.state === "Federal"
        //     );
        //     console.log("selectedApprover isFederal", isFederal);
        //     if (isFederal.length > 0) {
        //       let isApprover = isFederal[0].approvers.filter(
        //         (ele) => ele.ApproverName === masterApproverList
        //       );
        //       console.log(
        //         "selectedApprover isApprover",
        //         isApprover,
        //         isApprover.length <= 0
        //       );
        //       if (isApprover.length <= 0) {
        //         let newApprover = {
        //           ApproverName: masterApproverList,
        //           id: masterApproverId,
        //           isDefault: false,
        //         };
        //         isFederal[0].approvers.push(newApprover);
        //       }
        //     } else {
        //       let newFederal = {
        //         state: "Federal",
        //         approvers: [
        //           {
        //             ApproverName: masterApproverList,
        //             id: masterApproverId,
        //             isDefault: true,
        //           },
        //         ],
        //       };
        //       selectedApprover[0].states.push(newFederal);
        //     }
        //   } else {
        //     masterSelectedState.forEach((state) => {
        //       let isState = selectedApprover[0].states.filter(
        //         (ele) => ele.state === state
        //       );
        //       console.log("selectedApprover isState", isState);
        //       if (isState.length > 0) {
        //         let isApprover = isState[0].approvers.filter(
        //           (ele) => ele.ApproverName === masterApproverList
        //         );
        //         console.log(
        //           "selectedApprover isApprover",
        //           isApprover,
        //           isApprover.length <= 0
        //         );
        //         if (isApprover.length <= 0) {
        //           let newApprover = {
        //             ApproverName: masterApproverList,
        //             id: masterApproverId,
        //             isDefault: false,
        //           };
        //           isState[0].approvers.push(newApprover);
        //         }
        //       } else {
        //         let newState =
        //           masterSelectedRadio === "Federal"
        //             ? {
        //                 state: "Federal",
        //                 approvers: [
        //                   {
        //                     ApproverName: masterApproverList,
        //                     id: masterApproverId,
        //                     isDefault: true,
        //                   },
        //                 ],
        //               }
        //             : {
        //                 state: state,
        //                 approvers: [
        //                   {
        //                     ApproverName: masterApproverList,
        //                     id: masterApproverId,
        //                     isDefault: true,
        //                   },
        //                 ],
        //               };
        //         selectedApprover[0].states.push(newState);
        //         console.log(
        //           "selectedApprover newState",
        //           newState,
        //           selectedApprover
        //         );
        //       }
        //     });
        //   }
        // } else {
        //   selectedApprover = [
        //     {
        //       states:
        //         masterSelectedRadio === "Federal"
        //           ? [
        //               {
        //                 state: "Federal",
        //                 approvers: [
        //                   {
        //                     ApproverName: masterApproverList,
        //                     id: masterApproverId,
        //                     isDefault: true,
        //                   },
        //                 ],
        //               },
        //             ]
        //           : masterSelectedState.map((ele) => ({
        //               state: ele,
        //               approvers: [
        //                 {
        //                   ApproverName: masterApproverList,
        //                   id: masterApproverId,
        //                   isDefault: true,
        //                 },
        //               ],
        //             })),
        //     },
        //   ];
        // }
        // setMasterSelectedApproverData(selectedApprover);
        // console.log("array", selectedApprover);
      }
    }
  };

  //Handle Reset
  const handleReset = () => {
    setName("");
    setValue("");
    setSelectedState([]);
    setSelectedApproverData([]);
  };
  const handleMasterReset = (type) => {
    setMasterApproverList("");
    setMasterSelectedState([]);
    setMasterSelectedApproverData([]);
  };

  //Handle Name Change
  const nameChange = (event, value) => {
    console.log("nameChange value", value);
    setName(value);
    if (value) {
      const customer = customerList.find((obj) => obj.customerName === value);
      setCustomerId(customer.customerId);
      console.log("nameChange approverList", approverList);
      let clientApprovers = approverList.filter(
        (ele) =>
          ele.roleName === "Admin-PostAward" ||
          ((ele.roleName === "JSISME-PostAward" ||
            ele.roleName === "JSIPOC-PostAward") &&
            ele.customerId === customer.customerId)
      );
      console.log("nameChange clientApprovers", clientApprovers);
      setClientApproverList(clientApprovers);
    } else {
      setCustomerId(null);
    }
  };

  //Handle Approver List Change
  const handlenameChange = (event, value) => {
    console.log("value", value);
    setValue(value);
    if (value) {
      const approver = approverList.find((obj) => obj.userName === value);
      setApproverId(approver.userId);
    } else {
      setApproverId(null);
    }
  };

  const handleMasterApproverList = (event) => {
    setMasterApproverList(event.target.value);
    approverList.forEach((obj) => {
      if (obj.userName == event.target.value) {
        setMasterApproverId(obj.userId);
      }
    });
    console.log(masterApproverId);
  };

  //Handle Radio Change
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
    console.log("val", selectedRadio);
  };
  const handleMasterRadioChange = (event) => {
    setMasterSelectedRadio(event.target.value);
    if (event.target.value === "Federal") {
      setMasterSelectedState([]);
    }
    console.log("val", masterSelectedRadio);
  };

  //Handle Tab Change
  const handleTabChange = (event, newValue) => {
    console.log("handleTabChange", event, newValue);
    setTabValue(newValue);
    setSelectedRecord([])
  };

  //Handle Delete
  const deleteTableFunc = async () => {
    setIsLoadingTable(true);
    console.log("handleDelete", deleteData, approverTableData);
    console.log("deleteClientFiling", deleteData);
    // let newTableData = { ...approverTableData };
    // let stateName =
    //   deleteData.stateName === null ? "Federal" : deleteData.stateName;
    // let findState = newTableData[deleteData.client][stateName].filter(
    //   (state) => {
    //     console.log("handleDelete removeApprover", state);
    //     return state.id !== deleteData.approverId;
    //   }
    // );

    // console.log("handleDelete removeApprover payload------>", findState[0]);
    try {
      await axios
        .delete(
          `${API_BASE_URL}/Application/DeleteApprovers/${deleteData.approverId}`
        )
        .then(async (res) => {
          console.log("handleDelete", res);
          if (res.data.errorMessage) {
            // showAlert("Error", res.data.errorMessage, "error");
            // closeDialog();
            setAlertMessage(res.data.errorMessage);
            setShowAlert(true);
            handleReset();
          } else {
            // if (findState.length > 0 && deleteData.isdefault) {
            //   setIsDefaultData({
            //     defaultfor: "client",
            //     event: "",
            //     client: deleteData.client,
            //     stateName: deleteData.stateName,
            //     approverId: findState[0].id,
            //   });
            //   await isDefaultTableChange();
            // }
            // showAlert("Success", "Successfully deleted Approver", "success");
            // closeDialog();
            setAlertMessage("Successfully deleted Approver");
            setShowAlert(true);
            handleReset();
            getCustomerList();
          }
        });
      // await sendApprover();
      // await setApproverTableData(newTableData);
      SetDeleteData(null);
    } finally {
      setIsLoadingTable(false);
    }
  };

  const masterDeleteFunc = async () => {
    setIsLoadingTable(true);
    console.log("handleDelete", deleteData.stateName, deleteData.approverId);
    console.log(
      "deleteDataMasterFiling",
      deleteData.stateName,
      deleteData.approverId
    );

    try {
      await axios
        .delete(
          `${API_BASE_URL}/Application/DeleteApprovers/${deleteData.approverId}`
        )
        .then((res) => {
          console.log("handleDelete", res);
          if (res) {
            // let newTableData = { ...approverTableData };
            // let stateName =
            //   deleteData.stateName === null ? "Federal" : deleteData.stateName;
            // let findState = newTableData[deleteData.client][stateName].filter(
            //   (state) => {
            //     console.log("handleDelete removeApprover", state);
            //     return state.id !== deleteData.approverId;
            //   }
            // );
            setAlertMessage("Successfully deleted Approver");
            setShowAlert(true);
            handleReset();
            getCustomerList();
          } else {
            // showAlert("Error", "Unexpected Error", "error");
            // closeDialog();
            setAlertMessage("Unexpected Error");
            setShowAlert(true);
            handleReset();
          }
        });
      // let findState = masterSelectedApproverData.filter((ele) => {
      //   ele.states = ele.states.filter((state) => {
      //     if (state.state === deleteData.stateName) {
      //       state.approvers = state.approvers.filter(
      //         (ele) => ele.id !== deleteData.approverId
      //       );
      //     }
      //     console.log("handleDelete", state);
      //     if (state.approvers.length > 0) {
      //       state.approvers = state.approvers.map((ele, i) => {
      //         ele.isDefault = i === 0 ? true : false;
      //         return ele;
      //       });
      //       return state;
      //     }
      //   });
      //   console.log("handleDelete", ele);
      //   if (ele.states.length > 0) {
      //     return ele;
      //   }
      // });
      // console.log("handleDelete removeApprover", findState);
      // await setMasterSelectedApproverData(findState);
      SetDeleteData(null);
    } finally {
      setIsLoadingTable(false);
    }
  };

  //Handle State Change
  const stateChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedState(typeof value === "string" ? value.split(",") : value);
  };
  const masterStateChange = (event) => {
    const {
      target: { value },
    } = event;
    setMasterSelectedState(
      typeof value === "string" ? value.split(",") : value
    );
  };

  //isDefaut Change
  // const isDefaultMasterChange = (event, stateName, approverId) => {
  //   console.log(
  //     "isDefaultMasterChange",
  //     masterSelectedApproverData,
  //     event.target.value,
  //     stateName,
  //     approverId
  //   );
  //   let findState = masterSelectedApproverData.filter((ele) => {
  //     ele.states = ele.states.filter((state) => {
  //       if (state.state === stateName) {
  //         state.approvers = state.approvers.map((ele) => {
  //           if (ele.id === approverId) {
  //             ele.isDefault = true;
  //           } else {
  //             ele.isDefault = false;
  //           }
  //           return ele;
  //         });
  //       }
  //       console.log("handleDelete", state);
  //       if (state.approvers.length > 0) {
  //         return state;
  //       }
  //     });
  //     console.log("handleDelete", ele);
  //     if (ele.states.length > 0) {
  //       return ele;
  //     }
  //   });
  //   console.log("handleDelete isDefaultChange", findState);
  //   setMasterSelectedApproverData(findState);
  // };

  // const isDefaultChange = (event, client, stateName, approverId) => {
  //   console.log(
  //     "isDefaultChange",
  //     selectedApproverData,
  //     event.target.value,
  //     client,
  //     stateName,
  //     approverId
  //   );
  //   let findClient = selectedApproverData.filter((ele) => {
  //     if (ele.client === client) {
  //       ele.states = ele.states.filter((state) => {
  //         if (state.state === stateName) {
  //           state.approvers = state.approvers.map((ele) => {
  //             if (ele.id === approverId) {
  //               ele.isDefault = true;
  //             } else {
  //               ele.isDefault = false;
  //             }
  //             return ele;
  //           });
  //         }
  //         console.log("handleDelete", state);
  //         if (state.approvers.length > 0) {
  //           return state;
  //         }
  //       });
  //     }
  //     console.log("handleDelete", ele);
  //     if (ele.states.length > 0) {
  //       return ele;
  //     }
  //   });
  //   console.log("handleDelete isDefaultChange", findClient);
  //   setSelectedApproverData(findClient);
  // };

  const getCurrentApprover = (client, stateName) => {
    let state = stateName === null ? "Federal" : stateName;
    let CurrApprover;
    if (client) {
      let newTableData = { ...approverTableData };
      CurrApprover = newTableData[client][state].filter((item) => {
        console.log("Find Current Approver", item);
        return item.isdefault;
      });
    } else {
      let newTableData = { ...approverMasterTableData };
      CurrApprover = newTableData[state].filter((item) => {
        console.log("Find Current Approver", item);
        return item.isdefault;
      });
    }
    console.log("Find Rec for :", client, stateName);
    console.log("Find in :", approverTableData, typeof approverTableData);
    console.log("Found Approver:", CurrApprover);
    return CurrApprover[0]?.approverId;
  };

  const isDefaultDialog = (
    event,
    client,
    stateName,
    approverId,
    customerId
  ) => {
    console.log("change Default e: ", event);
    const oldApproverId = getCurrentApprover(client, stateName);
    setIsDefaultData({
      defaultfor: "client",
      event,
      client,
      stateName,
      approverId,
      oldApproverId,
      customerId,
    });
    showDialog(
      "Confirmation",
      "Are you sure you want to make this Approver default and reassign pending workflow(s)?",
      "confirm"
    );
  };

  const isDefaultMasterDialog = (event, stateName, approverId) => {
    const oldApproverId = getCurrentApprover(null, stateName);
    setIsDefaultData({
      defaultfor: "master",
      event,
      stateName,
      approverId,
      oldApproverId,
    });
    showDialog(
      "Confirmation",
      "Are you sure you want to make this Approver default and reassign pending workflow(s)?",
      "confirm"
    );
  };

  const isDefaultTableChange = () => {
    console.log(
      "isDefaultChange",
      selectedApproverData,
      isDefaultData.client,
      isDefaultData.stateName,
      isDefaultData.approverId,
      isDefaultData.oldApproverId,
      isDefaultData.customerId
    );
    console.log("isDefaultChange1", selectedApproverData);

    let newTableData = JSON.parse(JSON.stringify(approverTableData));
    let isState =
      isDefaultData.stateName === null ? "Federal" : isDefaultData.stateName;
    let findState = newTableData[isDefaultData.client][isState].map((state) => {
      console.log(
        "handleDelete isDefaultChange",
        state.id,
        isDefaultData.approverId
      );
      if (state.approverId === isDefaultData.approverId) {
        state.isdefault = true;
      } else {
        state.isdefault = false;
      }
      return state;
    });
    console.log("handleDelete isDefaultChange", findState, newTableData);
    newTableData[isDefaultData.client][isState] = findState;
    // setIsDefaultData(null);
    // SetDeleteData({
    //   changeApproverFor: "client",
    //   approverData: newTableData[isDefaultData.client],
    // });
    // showDialog;
    updateApprover(newTableData[isDefaultData.client]);
    // let findClient = selectedApproverData.filter((ele) => {
    //   if (ele.client === client) {
    //     ele.states = ele.states.filter((state) => {
    //       if (state.state === stateName) {
    //         state.approvers = state.approvers.map((ele) => {
    //           if (ele.id === approverId) {
    //             ele.isDefault = true;
    //           } else {
    //             ele.isDefault = false;
    //           }
    //           return ele;
    //         });
    //       }
    //       console.log("handleDelete", state);
    //       if (state.approvers.length > 0) {
    //         return state;
    //       }
    //     });
    //   }
    //   console.log("handleDelete", ele);
    //   if (ele.states.length > 0) {
    //     return ele;
    //   }
    // });
    // console.log("handleDelete isDefaultChange", newTableData);
    // setApproverTableData(newTableData);
  };

  const isDefaultMasterTableChange = () => {
    console.log(
      "isDefaultChange",
      approverMasterTableData,
      isDefaultData.stateName,
      isDefaultData.approverId,
      isDefaultData.oldApproverId
    );
    console.log(
      "isDefaultChange2",
      approverMasterTableData,
      isDefaultData.stateName,
      isDefaultData.approverId,
      isDefaultData.oldApproverId
    );

    let newTableData = JSON.parse(JSON.stringify(approverMasterTableData));
    let isState =
      isDefaultData.stateName === null ? "Federal" : isDefaultData.stateName;
    let findState = newTableData[isState].map((state) => {
      console.log(
        "handleDelete isDefaultChange",
        state.id,
        isDefaultData.approverId
      );
      if (state.approverId === isDefaultData.approverId) {
        state.isdefault = true;
      } else {
        state.isdefault = false;
      }
      return state;
    });
    console.log("handleDelete isDefaultChange", findState, newTableData);
    newTableData[isState] = findState;
    // setIsDefaultData(null);
    updateMasterApprover(newTableData[isState]);
  };

  const defaultChange = () => {
    setOpenChangeDefault(true);
  };

  const handleSubmit = () => {
    if (deleteData?.deletefor === "client") {
      deleteTableFunc();
    } else if (deleteData?.deletefor === "master") {
      masterDeleteFunc();
    } else if (isDefaultData?.defaultfor === "client") {
      isDefaultTableChange();
    } else if (isDefaultData?.defaultfor === "master") {
      isDefaultMasterTableChange();
    }
  };

  const closeConfirm = () => {
    // navigate("/");
  };

  //Handle Delete
  const handleDelete = (
    client,
    stateName,
    approverId,
    isdefault,
    approverName
  ) => {
    console.log("handleDelete", client, stateName, approverTableData);
    let state = stateName === null ? "Federal" : stateName;
    console.log(
      "handleDelete",
      Object.keys(approverTableData[client]).length,
      Object.keys(approverTableData[client][state]).length
    );
    let onlyApprover =
      Object.keys(approverTableData[client]).length > 1
        ? false
        : Object.keys(approverTableData[client][state]).length > 1
        ? false
        : true;
    console.log("handleDelete", onlyApprover);
    if (isdefault) {
      SetDeleteData(null);
      setAlertMessage(
        "Default approver cannot be deleted. Please assign/add another approver as default before delete ."
      );
      setShowAlert(true);
    } else {
      SetDeleteData({
        deletefor: "client",
        client,
        stateName,
        approverId,
        isdefault,
        approverName,
      });
      console.log(
        "handleDelete",
        client,
        stateName,
        approverId,
        isdefault,
        approverName
      );
      console.log("approverName$", approverName);
      showDialog(
        "Confirmation",
        <Typography>
        Are you sure to delete this Approver: {" "}
        <Typography fontWeight="bold" component="span">
        {approverName}?
        </Typography> {" "}
        </Typography>,
        "confirm"
      );
    }
  };

  const handleMasterDelete = (
    stateName,
    approverId,
    isdefault,
    approverName
  ) => {
    console.log("handleDelete", stateName, approverMasterTableData);
    let state = stateName === null ? "Federal" : stateName;
    console.log(
      "handleDelete",
      Object.keys(approverMasterTableData[state]).length
    );
    let onlyApprover =
      Object.keys(approverMasterTableData[state]).length > 1 ? false : true;
    console.log("handleDelete", onlyApprover);
    if (isdefault) {
      SetDeleteData(null);
      setAlertMessage(
        "Default approver cannot be deleted. Please assign/add another approver as default before delete ."
      );
      setShowAlert(true);
    } else {
      SetDeleteData({
        deletefor: "master",
        stateName,
        approverId,
        isdefault,
        approverName,
      });
      console.log(
        "handleDelete",
        stateName,
        approverId,
        isdefault,
        approverName
      );
      console.log("approverName$$", approverName);
      showDialog(
        "Confirmation",
        <Typography>
        Are you sure to delete this Approver: {" "}
        <Typography fontWeight="bold" component="span">
        {approverName}?
        </Typography> {" "}
        </Typography>,
        "confirm"
      );
    }
  };

  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: handleSubmit,
    onClose: closeConfirm,
  });

  const footerContent = (
    <div>
      <Button
        onClick={() => closeAlert()}
        autoFocus
        variant="contained"
        sx={{ backgroundColor: "#6366F1", color: "#ffffff" }}
        size="medium"
      >
        Ok
      </Button>
    </div>
  );

  const closeAlert = () => {
    setShowAlert(false);
    // navigate("/");
  };
  // const { AlertDialog, showDialog: showAlert } = useDialog({});

  const displayHistory = async () => {
    console.log("displayHistory", selectedRecord)
    if(selectedRecord.length === 1){
      try {
        const history = await axios.get(
          `${API_BASE_URL}/Application/ClientAndMasterApproversAudit?ID=${selectedRecord[0]}`
        );
        console.log(",displayHistory",history)
        setActivityHistory(() => history.data.data);
        setShowHistory(() => true);
      } catch (error) {
        console.log("@@Error:", error);
      }
    } else{
      setAlertMessage(`Please Select a single Approver`);
      setShowAlert(true);
    }
  }

  const closeHistory = () => {
    // console.log("Toggling Show Filter", showHistory);
    setShowHistory(() => false);
  };

  return (
    <Fragment>
      {/* <Dialog open={openChangeDefault}>
        <DialogTitle id='alert-dialog-title'>
          {"Do you want to change default approver??"}
        </DialogTitle>
        <DialogActions>
          <button
            style={{
              padding: "10px 20px",
              marginLeft: "60px",
              border: "none",
              color: "blue",
            }}
          >
            CANCEL
          </button>

          <button
            style={{
              padding: "8px 30px",
              marginLeft: "30px",
              backgroundColor: "blue",
              color: "white",
              border: "1px",
              borderRadius: "3px",
            }}
          >
            OK
          </button>
        </DialogActions>
      </Dialog> */}
      <Grid sx={{ height: "100%", width: "100%" , padding: "1rem"}}>
      <TabContext value={tabValue}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            margin: "0 2rem",
          }}
        >
          <h3
            style={{
              fontFamily: "sans-serif",
              fontWeight: 600,
              fontSize: "1rem",
              margin: 0,
            }}
          >
            POST AWARD APPROVER CONFIGURATION
          </h3>

          <TabList onChange={handleTabChange} style={{ justifyContent: "end" }}>
            <Tab label="CLIENT FILING" value="1" />
            <Tab label="MASTER FILING" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Grid>
              <Box
                sx={{
                  display: "flex",
                  "& > :not(style)": {
                    m: 1,
                    width: 368,
                    height: 368,
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    overflow: "auto",
                    border: 0,
                  },
                }}
              >
                <Paper variant="outlined">
                  <h5
                    style={{
                      marginLeft: "20px",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      textColor: "black",
                    }}
                  >
                    SELECT CLIENT
                  </h5>
                  <FormControl sx={{ m: 1, minWidth: 280, width: "10%" }}>
                    <Autocomplete
                      value={name}
                      onChange={nameChange}
                      options={customerList
                        .filter(customer => customer.postAwardLicenseId > 0)
                        .map(
                        (customer) => customer.customerName
                      )}
                      getOptionLabel={(customer) => customer}
                      renderInput={(params) => (
                        <TextField
                          required={true}
                          {...params}
                          label="Select Name"
                          style={{ width: 340, marginLeft: "3%" }}
                          onFocus={() => handleFocus("name")}
                        />
                      )}
                      // Custom render for options
                      renderOption={(props, option) => {
                        if (option.length > 35) {
                          return (
                            <Tooltip title={option} arrow>
                              <li {...props}>{option.substring(0, 35)}...</li>
                            </Tooltip>
                          );
                        }
                        return <li {...props}>{option}</li>;
                      }}
                    />
                    {errors.name && (
                      <FormHelperText error={errors.name ? true : false}>
                        {errors.name}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <h5
                    style={{
                      marginLeft: "20px",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      textColor: "black",
                    }}
                  >
                    APPROVER LISTS
                  </h5>
                  <FormControl sx={{ m: 1, minWidth: 280, width: "10%" }}>
                    <Autocomplete
                      value={value}
                      onChange={handlenameChange}
                      options={clientApproverList.map(
                        (approver) => approver.userName
                      )}
                      getOptionLabel={(approver) => approver}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={true}
                          label="Select Name"
                          // InputProps={{ ...params.InputProps, type: "search" }}
                          style={{ width: 340, marginLeft: "3%" }}
                          onFocus={() => handleFocus("value")}
                        />
                      )}
                    />
                    {errors.value && (
                      <FormHelperText error={errors.value ? true : false}>
                        {errors.value}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Paper>
              </Box>
            </Grid>

            <Grid>
              <Box
                sx={{
                  display: "flex",
                  "& > :not(style)": {
                    m: 1,
                    width: 368,
                    height: 368,
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    overflow: "auto",
                    marginLeft: "-8px",
                  },
                }}
              >
                <Paper variant="outlined">
                  <h5
                    style={{
                      marginLeft: "20px",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      textColor: "black",
                    }}
                  >
                    JURISDICTION *
                  </h5>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={selectedRadio}
                      name="radio-buttons-group"
                      required={true}
                    >
                      <FormControlLabel
                        value="State"
                        control={<Radio />}
                        label="State"
                        style={{ marginLeft: "10px" }}
                        onChange={handleRadioChange}
                        onFocus={() => handleFocus("radio")}
                      />
                      <FormControlLabel
                        value="Federal"
                        control={<Radio />}
                        label="Federal"
                        style={{ marginLeft: "10px" }}
                        onChange={handleRadioChange}
                        onFocus={() => handleFocus("radio")}
                      />
                    </RadioGroup>
                    {errors.radio && (
                      <FormHelperText error>{errors.radio}</FormHelperText>
                    )}
                  </FormControl>
                  {selectedRadio == "State" ? (
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="state-label">Select State</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedState}
                        onChange={stateChange}
                        required={true}
                        style={{
                          width: 340,
                          marginLeft: "3%",
                          backgroundColor: "white",
                        }}
                        renderValue={(selected) => selected.join(", ")}
                        onFocus={() => handleFocus("stateInfo")}
                      >
                        {states.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            <Checkbox
                              checked={selectedState.indexOf(item.code) > -1}
                            />
                            {item.state}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.stateInfo && (
                        <FormHelperText error>
                          {errors.stateInfo}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    ""
                  )}
                </Paper>
              </Box>
            </Grid>

            <Grid>
              <Box
                sx={{
                  display: "flex",
                  "& > :not(style)": {
                    m: 1,
                    width: 368,
                    height: 368,
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    overflow: "auto",
                    border: 0,
                  },
                }}
              ></Box>

              <br></br>

              <Grid
                style={{ display: "flex", justifyContent: "flex-end" }}
              ></Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              margin: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={collectApproverData}
              disabled={isLoadingTable}
            >
              Submit
            </Button>
          </Grid>
          <Grid sx={{ height: "90vh", width: "100%" }}>
            {isLoadingTable ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div>
                  <CircularProgress />
                </div>
              </Box>
            ) : (
              <PostAwardApproverTable
                tableData={approverTableData}
                handleDelete={handleDelete}
                isDefaultChange={isDefaultDialog}
                setSelectedRow={(row) => setSelectedTableRow(row)}
                selectedRow={selectedTableRow}
                setSelectedRecord={(records) => setSelectedRecord(records)}
              />
            )}
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Grid>
              <Box
                sx={{
                  display: "flex",
                  "& > :not(style)": {
                    m: 1,
                    width: 368,
                    height: 368,
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    overflow: "auto",
                    border: 0,
                  },
                }}
              >
                <Paper variant="outlined">
                  <h5
                    style={{
                      marginLeft: "20px",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      textColor: "black",
                    }}
                  >
                    APPROVER LISTS
                  </h5>
                  <FormControl sx={{ m: 1, minWidth: 280, width: "75%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Select Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={masterApproverList}
                      label="Select Name"
                      onChange={handleMasterApproverList}
                      style={{
                        width: 340,
                        marginLeft: "3%",
                        backgroundColor: "white",
                      }}
                      onFocus={() => handleMasterFocus("masterApproverList")}
                    >
                      {approverList.map((approver) => (
                        <MenuItem
                          key={approver.userId}
                          value={approver.userName}
                        >
                          {approver.userName}
                        </MenuItem>
                      ))}
                    </Select>
                    {masterErrors.masterApproverList && (
                      <FormHelperText error>
                        {masterErrors.masterApproverList}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Paper>
              </Box>
            </Grid>

            <Grid>
              <Box
                sx={{
                  display: "flex",
                  "& > :not(style)": {
                    m: 1,
                    width: 368,
                    height: 368,
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    overflow: "auto",
                    marginLeft: "-8px",
                  },
                }}
              >
                <Paper variant="outlined">
                  <h5
                    style={{
                      marginLeft: "20px",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      textColor: "black",
                    }}
                  >
                    JURISDICTION
                  </h5>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={masterSelectedRadio}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="State"
                        control={<Radio />}
                        label="State"
                        style={{ marginLeft: "10px" }}
                        onChange={handleMasterRadioChange}
                        onFocus={() => handleMasterFocus("masterRadio")}
                      />
                      <FormControlLabel
                        value="Federal"
                        control={<Radio />}
                        label="Federal"
                        style={{ marginLeft: "10px" }}
                        onChange={handleMasterRadioChange}
                        onFocus={() => handleMasterFocus("masterRadio")}
                      />
                    </RadioGroup>
                    {masterErrors.masterRadio && (
                      <FormHelperText error>
                        {masterErrors.masterRadio}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {masterSelectedRadio == "State" ? (
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="state-label">Select State</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={masterSelectedState}
                        onChange={masterStateChange}
                        style={{
                          width: 340,
                          marginLeft: "3%",
                          backgroundColor: "white",
                        }}
                        renderValue={(selected) => selected.join(", ")}
                        onFocus={() => handleMasterFocus("masterSelectedState")}
                      >
                        {states.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            <Checkbox
                              checked={
                                masterSelectedState.indexOf(item.code) > -1
                              }
                            />
                            {item.state}
                          </MenuItem>
                        ))}
                      </Select>
                      {masterErrors.masterSelectedState && (
                        <FormHelperText error>
                          {masterErrors.masterSelectedState}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    ""
                  )}
                </Paper>
              </Box>
            </Grid>

            <Grid>
              <br></br>
              <Grid style={{ display: "flex", justifyContent: "end" }}></Grid>
            </Grid>
          </Grid>
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              disabled={isLoadingTable}
              onClick={collectMasterApproverData}
            >
              SUBMIT
            </Button>
          </Grid>
          <Grid sx={{ height: "90vh", width: "100%" }}>
            {isLoadingTable ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div>
                  <CircularProgress />
                </div>
              </Box>
            ) : (
              <PostAwardApproverTable
                isMaster={true}
                tableData={approverMasterTableData}
                handleDelete={handleMasterDelete}
                isDefaultChange={isDefaultMasterDialog}
                setSelectedRow={(row) => setSelectedMasterTableRow(row)}
                selectedRow={selectedMasterTableRow}
                setSelectedRecord={(records) => setSelectedRecord(records)}
              />
            )}
          </Grid>
        </TabPanel>
      </TabContext>
      </Grid>
      {/* <Grid
        sx={{
          height: "100%",
          width: "5%",
        }}
      >
        <div
          style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}
        >
          <Item
            sx={{
              height: "100%",
            }}
          >
            <PostAwardApprovalConfigToolbox 
              displayHistory={displayHistory}
            />
          </Item>
        </div>
      </Grid> */}
      <ConfirmDialog />
      {/* {
        <ApprovalConfigHistory
          show={showHistory}
          dockAt="right"
          // tracker={selectedFiling[0]}
          activityHistory={activityHistory}
          handleClose={closeHistory}
        />
      } */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
    </Fragment>
  );
}
