import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AuthContext from "../../context/auth/AuthContext";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import CustomerContext from "../../context/customer/CustomerContext";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip, FormControl, InputLabel, MenuItem, Select, OutlinedInput, Checkbox } from "@mui/material";
import { DateTime } from "luxon";
import Alert from "@mui/material/Alert";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import FileDownload from "js-file-download";
import Collapse from "@mui/material/Collapse";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useDialog from "../common/UseDialog";

import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import {
  ClientFilingTrackerComment,
} from "../../types/ClientFilingMaster";

type Anchor = "top" | "left" | "bottom" | "right";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function ClientFilingTrackerComments(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  //TOCHECK
  handleRefreshTable: () => void;
  tracker?: any;
  comments?: ClientFilingTrackerComment[];
}) {
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const { getClientFilingTrackerComments, addClientFilingTrackerComment } =
    clientFilingMasterContext;
  const formRef = useRef<HTMLFormElement | null>(null);
  const [data, setData] = React.useState<any>({});
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const authContext = useContext(AuthContext);
  const { authUser, userRoles, toLocalDateTime, roleType } = authContext;
  const customerContext = useContext(CustomerContext);
  const { selectedCustomer } = customerContext;
  const [alertMessage, setAlertMessage] = useState("");
  const [approvalComment, setApprovalComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [remainingChars, setRemainingChars] = useState(256);
  const [selectedFile, setSelectedFile] = useState("");
  const [openAttachment, setOpenAttachment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [selectedChildCompany, setSelectedChildCompany] = useState<any[]>([]);
  const [selectedComment, setSelectedComment] = useState<any>({});
  const [actionType, setActionType] = useState<any>("Add")

  // const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  // function handleCloseClick() {

  //       setIsPopUpVisible(false);

  //     }

  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
    // navigate("/client-filing-master-approval-list");
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        // if (
        //   event.type === "keydown" &&
        //   ((event as React.KeyboardEvent).key === "Tab" ||
        //     (event as React.KeyboardEvent).key === "Shift")
        // ) {
        //   return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };
  const toLocalTime = (date: Date): string => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");

      return _date;
    } else return "";
  };
  useEffect(() => {
    setLoading(true);
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {

      // toggleDrawer(props.dockAt, props.show);
      if (props.show) {
        setSelectedChildCompany([])
        setState({ ...state, [props.dockAt]: props.show });
        getCustomerList();
        getChildRecords();
        setActionType("Add")
      }
    }
    setApprovalComment((old) => "");

    if (
      getClientFilingTrackerComments &&
      //TOCHECK
      props.tracker &&
      props.tracker.fileTrackingId
    ) {
      //   fetchFilingMasterComments(props.tracker.fileTrackingId);
    }
    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    const _data: ClientFilingTrackerComment[] = props.comments!;
    //TOCHECK

    if (
      props.comments && props?.tracker?.fileTrackingId && props?.tracker?.customerId
      //&& props.comments.length > 0
    ) {
      try {
        // setData(() => _data);
        fetchClientFilingTrackerComments(
          props.tracker.fileTrackingId,
          props.tracker.customerId
        )
      } finally {
        console.log("PROGRESS-BAR use effect  props.comments", props.comments);
        //setLoading(false);
      }
    }

    //eslint-disable-next-line
  }, [props.comments]);

  //   useEffect(() => {
  //     // if(props.workflow && props.workflow.createDate!==null)
  //     //eslint-disable-next-line
  //   }, [props.workflow]);

  //   const fetchFilingMasterComments = async (filingId: number) => {
  //     console.log("@@Fetch FilingMasters1:", getClientFilingTrackerComments);
  //     if (getClientFilingTrackerComments) {
  //       console.log("@@Fetch FilingMasters2:");
  //       try {
  //         const commentList = await getClientFilingTrackerComments(filingId);
  //         setData(() => commentList);
  //         console.log("@@Fetch FilingMasters2 comments:", commentList);
  //       } catch (error) {
  //         console.log("@@Error:", error);
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     console.log(`Fetching Commnet for 0:${props.workflow}`);
  //     if (
  //       typeof props.dockAt !== "undefined" &&
  //       typeof props.show !== "undefined"
  //     ) {
  //       console.log(
  //         `Toogle Drawer UED: DockAt:${props.dockAt}, Show: ${props.show}`
  //       );
  //       // toggleDrawer(props.dockAt, props.show);
  //       setState({ ...state, [props.dockAt]: props.show });
  //     }
  //     setApprovalComment((old) => "");

  //     if (
  //       getClientFilingTrackerComments &&
  //       props.workflow &&
  //       props.workflow?.fileTrackingId
  //     ) {
  //       console.log(`Fetching Commnet for :${props.workflow.fileTrackingId}`);
  //       fetchFilingMasterComments(props.workflow?.fileTrackingId);
  //     }
  //     //eslint-disable-next-line
  //   }, []);

  //TOCHECK
  const onFileChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const getUserName = (userId: string) => {
    // console.log("getUserName",userRoles , userId)
    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]?.userName
      : "";
    return user;
  };

  const list = (anchor: Anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "medium",
                        fontSize: "16px",
                        color: "#424242",
                      }}
                    >
                      COMMENTS
                    </Typography>
                    <IconButton>
                      <CloseIcon onClick={() => handleCancel(anchor)} />
                    </IconButton>
                  </Stack>
                </div>
                <div>
                  <Box sx={{ mx: 3 }}>
                    <TextField
                      id="notes"
                      name="notes"
                      label={
                        <span style={{ font: "italic normal normal" }}>
                          Add Comments
                        </span>
                      }
                      multiline
                      fullWidth
                      minRows={2}
                      variant="filled"
                      placeholder={"Enter Comments..."}
                      value={approvalComment}
                      onChange={(e) => {
                        if (e.target.value.length <= 256) {
                          onCommentChange(e);
                          setRemainingChars(256 - e.target.value.length);
                        }
                      }}
                    />

                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "small",
                        color: remainingChars === 0 ? "red" : "inherit",
                        display: remainingChars === 256 ? "none" : "block",
                      }}
                    >
                      {remainingChars} Characters are left
                    </Typography>
                  </Box>
                </div>
                {selectedCustomer && selectedCustomer.bothParentAndChild && (
                  <div style={{ margin: "1.5rem 1.5rem 0.5rem 1.5rem" }}>
                    <FormControl sx={{ minWidth: 350, maxWidth: "90%" }}>
                      <InputLabel id="demo-multiple-checkbox-label">Affected Companies</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedChildCompany}
                        onChange={(e) => setSelectedChildCompany(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => displaySelectedCompany(selected)}
                        disabled={actionType === "Edit"}
                      >
                        {companyList.map(({ name, code }) => (
                          <MenuItem key={name} value={code}>
                            <Checkbox checked={selectedChildCompany.indexOf(code) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div style={{ margin: "0 0 0 0.5rem", display: "block" }}>
                  <MuiButton
                    variant="text"
                    onClick={() => setOpenAttachment(!openAttachment)}
                    disabled={actionType === "Edit"}
                  >
                    <AttachFileIcon /> ATTACHMENT
                  </MuiButton>
                  {actionType === "Edit" && selectedComment
                    && selectedComment?.attachmentPath && selectedComment.attachmentPath !== "undefined" && (
                      <MuiButton
                        variant="text"
                        onClick={() =>
                          downloadFile(
                            selectedComment.attachmentPath,
                            selectedComment.attachmentId,
                            selectedComment.commentsId
                          )
                        }
                      >
                        {selectedComment?.attachmentPath && <AttachFileIcon />}
                        <Tooltip
                          title={getFilenameToolip(selectedComment.attachmentPath)}
                        >
                          <ListItemText
                            primary={
                              selectedComment &&
                              selectedComment?.attachmentPath &&
                              getFilename(selectedComment.attachmentPath)
                            }
                          />
                        </Tooltip>
                      </MuiButton>
                    )}
                  <Collapse orientation="horizontal" in={openAttachment}>
                    <form
                      method="post"
                      id="uploadForm"
                      onChange={(event) => onFileChange(event)}
                      style={{ margin: "1rem" }}
                      ref={formRef}
                    >
                      <label className="file-upload">
                        <Input
                          type="file"
                          name="file"
                          id="inFile"
                          color="primary"
                        />
                      </label>
                    </form>
                  </Collapse>
                </div>
                <div>
                  <CardActions
                    sx={{
                      // mt: "3rem",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      // sx={{ margin: 1, padding: 1 }}
                      onClick={() => handleCancel(anchor)}
                    >
                      Cancel
                    </MuiButton>
                    {actionType === "Add"
                      ? <MuiButton
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: "#0b3edf" }}
                        disabled={!(selectedCustomer && selectedCustomer.bothParentAndChild ? selectedChildCompany.length > 0 : true)}
                        // sx={{ margin: 1, padding: 1 }}
                        onClick={() => handleOk(anchor)}
                      >
                        Add
                      </MuiButton>
                      : <MuiButton
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: "#0b3edf" }}
                        disabled={selectedComment?.commentsText?.trim() === approvalComment?.trim()}
                        // sx={{ margin: 1, padding: 1 }}
                        onClick={() => editComment()}
                      >
                        Edit
                      </MuiButton>
                    }
                  </CardActions>
                </div>
              </Stack>
              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? <CircularProgress size={40} /> : null}

                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <Divider />

              {data && Object.keys(data).length === 0 ? (
                loading ? (
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                      }}
                    >
                      <CircularProgress size={40} />
                    </div>
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography variant="body1" color="textSecondary">
                      No comments
                    </Typography>
                  </CardContent>
                )
              ) : (
                <List>
                  {data &&
                    Object.keys(data).map((client) => (
                      <Fragment>
                        <p style={{ fontWeight: "bold", fontSize: "16px" }}>{getClientName(client)}</p>
                        {data[client]
                          .slice()
                          .sort((a: any, b: any) => {
                            if (a.updateDate && b.updateDate) {
                              const updateDateA =
                                typeof a.updateDate === "number"
                                  ? a.updateDate
                                  : new Date(a.updateDate).getTime();
                              const updateDateB =
                                typeof b.updateDate === "number"
                                  ? b.updateDate
                                  : new Date(b.updateDate).getTime();
                              return updateDateB - updateDateA;
                            } else if (a.createDate && b.createDate) {
                              const createDateA =
                                typeof a.createDate === "number"
                                  ? a.createDate
                                  : new Date(a.createDate).getTime();
                              const createDateB =
                                typeof b.createDate === "number"
                                  ? b.createDate
                                  : new Date(b.createDate).getTime();
                              return createDateB - createDateA;
                            }
                            return 0;
                          })
                          .map((item: any, index: number) => (
                            <Fragment key={index}>
                              <Stack direction="row" justifyContent="space-between">
                                <ListItem
                                  disablePadding
                                >
                                  {item.createUser && getUserName(item.createUser)}
                                </ListItem>
                                <ListItem sx={{ textAlign: "right" }}>
                                  <ListItemText
                                    // secondary={
                                    //   item &&
                                    //   item.createDate &&
                                    //   toLocalDateTime &&
                                    //   `${toLocalDateTime(item.createDate)}`

                                    secondary={
                                      item &&
                                        item.updateDate
                                        ? toLocalDateTime && `${toLocalDateTime(item.updateDate)}`
                                        : toLocalDateTime && `${toLocalDateTime(item.createDate)}`
                                    }

                                  />
                                </ListItem>
                              </Stack>

                              <ListItem disablePadding className="commentText" sx={{
                                fontWeight: "600", fontSize: "16px",
                                '&:hover': {
                                  backgroundColor: '#f0f0f0',
                                  cursor: "text"
                                },
                              }}>

                                <ListItemText
                                  sx={{ fontWeight: "600", fontSize: "16px" }}
                                  primary={
                                    item &&
                                      item.commentsText &&
                                      item.commentsText !== null
                                      ? item.commentsText
                                      : ""
                                  }
                                />
                                <IconButton
                                  color='primary'
                                  disabled={DisableEdit(item)}
                                >
                                  <EditIcon
                                    onClick={() => handleEditComment(item)}
                                    sx={{ cursor: "pointer" }}
                                  />
                                </IconButton>
                                <IconButton
                                  color='primary'
                                  disabled={DisableEdit(item)}
                                >
                                  <DeleteIcon
                                    onClick={() => handleDeleteComment(item)}
                                    sx={{ cursor: "pointer", fontWeight: "400", }}
                                  />
                                </IconButton>
                              </ListItem>
                              {item.attachmentPath &&
                                item.attachmentPath !== "undefined" && (
                                  <MuiButton
                                    variant="text"
                                    onClick={() =>
                                      downloadFile(
                                        item.attachmentPath,
                                        item.attachmentId,
                                        item.commentsId
                                      )
                                    }
                                  >
                                    {item.attachmentPath && <AttachFileIcon />}
                                    <Tooltip
                                      title={getFilenameToolip(item.attachmentPath)}
                                    >
                                      <ListItemText
                                        primary={
                                          item &&
                                          item.attachmentPath &&
                                          getFilename(item.attachmentPath)
                                        }
                                      />
                                    </Tooltip>
                                  </MuiButton>
                                )}
                            </Fragment>
                          ))
                        }
                      </Fragment>
                    ))}
                </List>
              )}
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );

  const onCommentChange = async (e: React.ChangeEvent<any>) => {
    setApprovalComment((old) => e.target.value);
    if (approvalComment) {
      setShowSuccess(false);
      setShowError(false);
    }
  };
  //TOCHECK
  const updateFilePath = async (
    commentsId: any,
    attachmentId: any,
    filePath: any
  ) => {
    let payload = {
      attachmentId: attachmentId,
      attachmentPath: decodeURI(filePath),
      createDate: new Date(),
      createUser: authUser?.userId + "",
      commentsId: commentsId,
      //TOCHECK
      // filingId: props.tracker.filingId ? parseInt(props.tracker.filingId) : 0,
      // customerId: props.tracker?.customerId
      //   ? parseInt(props.tracker?.customerId)
      //   : 0,
    };

    const res = await axios.put(
      `${API_BASE_URL}/Customer/CustomerFilingTrackingCommentsAttachmentsupdate/${attachmentId}`,
      payload
    );
    if (res.data) {
    }
  };

  const fileUploadUtil = async (attachmentId: any, commentsId: any) => {

    const formData = new FormData();
    formData.append("Files", selectedFile);
    //TOCHECK
    formData.append("customerId", props.tracker.customerId);
    formData.append("filingId", props.tracker.filingId);
    formData.append("FileTrackingId", props.tracker.fileTrackingId);
    formData.append("commentsId", commentsId);
    formData.append("attachmentId", attachmentId);

    const res = await axios.post(
      `${API_BASE_URL}/Application/CustomerFilingUpload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (res.data) {
      await updateFilePath(commentsId, attachmentId, res.headers["x-file-url"]);
      // setFiles([...files, selectedFile]);
    }
  };

  const getAttachmentId = async (commentsId: any) => {
    let payload = {
      commentsId: commentsId,
      //TOCHECK
      customerId: parseInt(props.tracker.customerId),
      filingId: parseInt(props.tracker.filingId),
    };

    const res = await axios.post(
      `${API_BASE_URL}/Customer/CreateCustomerFilingTrackingCommentsAttachments`,
      payload
    );
    if (res.data) {
      return res.data.attachmentId;
    }
  };

  const onFileUpload = async (commentsId: any) => {
    let attachmentId = await getAttachmentId(commentsId);
    await fileUploadUtil(attachmentId, commentsId);
    return;
  };

  const uploadandUpdateAttachment = async (approvalComment: any) => {
    const formData = new FormData();
    formData.append("Files", selectedFile);
    formData.append("Commenttext", approvalComment);
    formData.append("filingId", (props.tracker?.filingId)?.toString() ?? "0");
    formData.append("FileTrackingId", props.tracker.fileTrackingId);
    formData.append("createUser", authUser?.userId + "");

    let url = `${API_BASE_URL}/Application/UpdateChildCustomerFilingTrackingCommentsAttachments`;
    if (selectedCustomer && !selectedCustomer.bothParentAndChild) {
      url = `${url}?CustomerIds=${selectedCustomer.customerId}`
    } else if (selectedChildCompany.length > 0) {
      selectedChildCompany.forEach((ele, i) => {
        url = `${url}${i === 0 ? "?" : "&"}CustomerIds=${ele}`
      })
    }

    const res = await axios.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res) {
      return res
    }
  };

  // const getFilename = function (str: any) {
  //   return str ? str.replace(/^.*[\\\/]/, "") : "";
  // };
  const getFilenameToolip = function (str: any) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };
  const getFilename = function (str: any) {
    const MAX_FILENAME_LENGTH = 20;

    if (str) {
      let filename = str.replace(/^.*[\\\/]/, "");
      if (filename.length > MAX_FILENAME_LENGTH) {
        const halfLength = Math.floor((MAX_FILENAME_LENGTH - 3) / 2);
        const firstHalf = filename.substr(0, halfLength);
        const secondHalf = filename.substr(-halfLength);
        filename = `${firstHalf}...${secondHalf}`;
      }
      return filename;
    } else {
      return "";
    }
  };

  const downloadFile = (
    attachmentPath: any,
    attachmentId: any,
    commentsId: any
  ) => {
    const fileName = getFilename(attachmentPath);
    axios({
      url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&CustomerId=${props.tracker.customerId}&CommentsId=${commentsId}&FilingId=${props.tracker.filingId}&FileTrackingId=${props.tracker.fileTrackingId}`,
      method: "POST",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  };

  const DisableEdit = (item: any) => {
    if (roleType.includes('Admin')) {
      return false
    } else if (authUser?.userId && authUser?.userId === parseInt(item.createUser)) {
      return false
    } else {
      return true
    }
  }

  const handleEditComment = (item: any) => {
    // console.log("handleEditComment", item , props.tracker)
    setActionType("Edit")
    setSelectedComment(item)
    setApprovalComment(item.commentsText)
    let id = item.customerID ? item.customerID : item.customerId
    setSelectedChildCompany([id])
  }

  const editCommentAttachment = async () => {
    // console.log("editComment", selectedComment , props.tracker)
    try {
      let url = `${API_BASE_URL}/Application/EditCommentsandAttachmentBoth?CustomerId=${props.tracker.customerId}&FilingId=${props.tracker.filingId}&FileTrackingId=${selectedComment.fileTrackingId}&CommentsId=${selectedComment.commentsId}&AttachmentId=${selectedComment.attachmentId}`
      const formData = new FormData();
      formData.append("Commenttext", approvalComment);
      formData.append("Files", "");
      const res = await axios.post(
        url,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res) {
        fetchClientFilingTrackerComments(
          props.tracker.fileTrackingId,
          props.tracker.customerId
        );
        setAlertMessage("Successfully updated comment.");
        setShowError(false);
        setShowSuccess(true);
        closeDialog();
        setSelectedComment({})
        setApprovalComment("")
        setSelectedChildCompany([])
        setActionType("Add")
      }
    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      console.log("PROGRESS-BAR editCommentAttachment ", "loading", loading);

      setLoading(false);
    }
  }

  const editComment = async () => {
    // console.log("editComment", selectedComment , props.tracker)
    setLoading(true);
    // if (selectedComment.attachmentPath && selectedComment.attachmentPath !== "undefined") {
    //   editCommentAttachment();
    // } else {
    try {
      const updateDate = new Date();

      let payload = {
        commentsId: selectedComment.commentsId,
        fileTrackingId: selectedComment.fileTrackingId,
        commentsText: approvalComment,
        // updateDate,
        updateUser: authUser?.userId + ""

      }
      let res = await axios.put(`${API_BASE_URL}/Customer/UpdateCommentsOnly`, payload)
      // console.log("editComment res", res)
      if (res) {
        fetchClientFilingTrackerComments(
          props.tracker.fileTrackingId,
          props.tracker.customerId
        );
        setAlertMessage("Successfully updated comment.");
        setShowError(false);
        setShowSuccess(true);
        closeDialog();
        setSelectedComment({})
        formRef.current && formRef.current.reset();
        setApprovalComment("")
        setSelectedChildCompany([])
        setActionType("Add")
      }
    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      console.log("PROGRESS-BAR editComment ", "loading", loading);

      // setLoading(false);
    }
    // }
  }

  const handleDeleteComment = (item: any) => {
    setActionType("Add")
    setSelectedComment(item)
    showDialog(
      "Confirmation",
      <Typography>
        Are you sure you want to delete this comment?
      </Typography>,
      "confirm"
    );
  }

  const deleteComments = async () => {
    console.log("deleteComments", selectedComment, props.tracker)
    setLoading(true);
    try {
      const updateDate = new Date();
      let payload = {
        commentsId: selectedComment.commentsId,
        fileTrackingId: selectedComment.fileTrackingId,
        updateUser: authUser?.userId + "",
        updateDate,
        isDelete: true,
      }
      console.log("deleteComments", "payload", payload);

      let url
      let res
      if (selectedComment.attachmentPath && selectedComment.attachmentPath !== "undefined") {
        url = `${API_BASE_URL}/Application/DeleteCommentsandAttachment?CustomerId=${props.tracker.customerId}&FilingId=${props.tracker.filingId}&FileTrackingId=${selectedComment.fileTrackingId}&CommentsId=${selectedComment.commentsId}&AttachmentId=${selectedComment.attachmentId}`
        res = await axios.delete(url)
      } else {
        // url = `${API_BASE_URL}/Customer/DeleteCommentsOnly?commentID=${selectedComment.commentsId}&FiletrackingId=${selectedComment.fileTrackingId}`
        url = `${API_BASE_URL}/Customer/DeleteCommentsOnly`
        res = await axios.delete(url, { data: payload })
      }

      // let res = await axios.delete(url, { data: payload })
      console.log("deleteComments res", res)
      if (res) {

        let id = selectedComment.customerID ? selectedComment.customerID : selectedComment.customerId
        // console.log("deleteComments selectedComment", id , selectedCustomer ,data)
        // if(selectedCustomer && selectedCustomer.customerId !== undefined 
        //     && selectedCustomer.customerId === id
        //     && data && data.hasOwnProperty([selectedCustomer.customerId]) 
        //     && data[selectedCustomer.customerId].length === 1){              
        props.handleRefreshTable();
        // }

        fetchClientFilingTrackerComments(
          props.tracker.fileTrackingId,
          props.tracker.customerId
        );

        setAlertMessage("Successfully deleted comment.");
        setShowError(false);
        setShowSuccess(true);
        closeDialog();
        setSelectedComment({})
      }
    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      console.log("PROGRESS-BAR editComment ", "loading", loading);

      // setLoading(false);
    }
  }

  const closeConfirm = () => {
  };

  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: deleteComments,
    onClose: closeConfirm,
  });

  const getAttachment = async (
    customerId: any,
    fileTrackingId: number | undefined,
    commentsId: number | undefined
  ) => {
    const res = await axios.get(
      `${API_BASE_URL}/Customer/CustomerFilingTrackingCommentsAttachments?customerId=${customerId}&fileTrackingId=${fileTrackingId}&commentsId=${commentsId}`
    );

    if (res.data) {
      return {
        attachmentPath: res.data.data[0].attachmentPath,
        attachmentId: res.data.data[0].attachmentId,
      };
    }
  };

  const fetchClientFilingTrackerComments = async (
    fileTrackingId: any,
    customerId: any
  ) => {
    //setLoading(true);
    try {
      let url = `${API_BASE_URL}/Customer/ViewParentandchildCustomerCommentsandAttachments?fileTrackingIds=${fileTrackingId}&customerIds=${customerId}`
      let url2 = `${API_BASE_URL}/Customer/ViewChildCustomerFilingTrackingComments?fileTrackingIds=${fileTrackingId}`
      let childRecords = await getChildRecords()
      // console.log("fetchClientFilingTrackerComments childRecords", childRecords)
      if (selectedCustomer && selectedCustomer.bothParentAndChild && childRecords.length > 0) {
        childRecords.forEach((ele: any) => {
          url = `${url}&fileTrackingIds=${ele.fileTrackingId}&customerIds=${ele.customerId}`
          url2 = `${url2}&fileTrackingIds=${ele.fileTrackingId}`
        })
      }
      const res = await axios.get(url)
      const res2 = await axios.get(url2)
      console.log("fetchClientFilingTrackerComments res", res.data.data, res2)

      let combinedRes = [...res.data.data, ...res2.data]
      console.log("fetchClientFilingTrackerComments combinedRes", combinedRes)

      let formatComments: { [index: string]: any } = {}
      combinedRes.forEach((ele: any) => (
        formatComments.hasOwnProperty(ele.customerId) || formatComments.hasOwnProperty(ele.customerID)
          ? formatComments[ele?.customerId ? ele.customerId : ele.customerID].push(ele)
          : formatComments[ele?.customerId ? ele.customerId : ele.customerID] = [ele]
      ));
      console.log("fetchClientFilingTrackerComments formatComments", formatComments)

      setData(() => formatComments);

    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      console.log("PROGRESS-BAR use effect  fetchClientFilingTrackerComments", loading);
      setLoading(false);
    }
  };

  const getCustomerList = async () => {
    try {
      let res = await axios.get(`${API_BASE_URL}/Customer/CustomerList`);
      let _data;
      _data = res.data.map((ele: any) => ({
        code: ele.customerId,
        name: ele.customerName,
      }));
      setCustomerList(_data);
    } catch (error) {
      console.log(error);
    }
  };

  const getClientName = (clientId: any) => {
    // console.log("getClientName", clientId, customerList);
    let findName: { [index: string]: any } = customerList.filter((ele: any) => ele.code === parseInt(clientId));
    // console.log("getClientName", findName);
    return findName.length > 0 ? findName[0].name : "";
  };

  const displaySelectedCompany = (selected: any) => {
    // console.log("displaySelectedCompany props", selected )

    let companyNames = selected.map((ele: any) => {
      let company = companyList.find((item: any) => item.code === ele)
      return company?.name
    })
    // console.log("displaySelectedCompany props", companyNames )
    return companyNames.join(", ")
  }

  const getChildRecords = async () => {
    // console.log("getChildRecords props",props.tracker);
    let payload = [{
      customerId: props.tracker.customerId,
      filingId: props.tracker.filingId,
      startDate: props.tracker.filingPeriods.startdate,
    }]
    // console.log("getChildRecords payload",payload,);
    const res = await axios.post(
      `${API_BASE_URL}/Customer/CustomerandFilingListBasedOnParentdetails`,
      payload
    )
    // console.log("getChildRecords res",res.data.data);

    if (res.data.data) {
      const childRecords = res.data.data.filter((ele: any) => ele.customerId !== props.tracker.customerId)
      let childList = res.data.data.map((ele: any) => ({ name: ele.customerName, code: ele.customerId, fileTrackingId: ele.fileTrackingId }))
      // console.log("getChildRecords childRecords", childRecords , childList);
      setCompanyList(() => childList)
      return childRecords
    }
  }

  const handleOk = async (anchor: Anchor) => {
    setIsLoading(true);

    // navigate("/filing-master-list");
    setShowError(false);
    if (approvalComment.length === 0) {
      setAlertMessage("Please enter a comment.");
      setShowError(true);
      setIsLoading(false);
      setShowSuccess(false);
    } else {
      setShowSuccess(false);
      setShowError(false);
      try {
        const createDate = new Date();
        let fileTrackingIds = []
        // console.log("updateChildRecordComment selectedCustomer",selectedCustomer)
        if (selectedCustomer && !selectedCustomer.bothParentAndChild) {
          fileTrackingIds.push({ fileTrackingId: props.tracker?.fileTrackingId })
        } else if (selectedChildCompany.length > 0) {
          selectedChildCompany.forEach((company) => {
            let companyDetails = companyList.find((ele: any) => ele.code === company)
            // console.log("updateChildRecordComment companyDetails", companyDetails)
            fileTrackingIds.push({ fileTrackingId: companyDetails.fileTrackingId })
          })
        }
        const payload = {
          commentsText: approvalComment,
          //TOCHECK
          ///customerId:props.tracker?.fileTrackingId,
          fileTrackingIdsList: fileTrackingIds,
          // fileTrackingId: props.tracker?.fileTrackingId,
          createDate,
          createUser: authUser?.userId + "",
        };

        let res
        if (selectedFile !== "") {
          res = await uploadandUpdateAttachment(approvalComment);
        } else {
          if (addClientFilingTrackerComment)
            res = await addClientFilingTrackerComment(payload);
        }
        // console.log("updateChildRecordComment res",res)
        if (res) {
          //TOCHECK
          // if (data && !data.hasOwnProperty([selectedCustomer?.customerId])) {
          props.handleRefreshTable();
          // }

          fetchClientFilingTrackerComments(
            props.tracker.fileTrackingId,
            props.tracker.customerId
          );

          setState({ ...state, [anchor]: true });
          setAlertMessage("Comment has been added");
          setShowError(false);
          setShowSuccess(true);
          setApprovalComment(() => "");
          setSelectedFile("");
          setSelectedChildCompany([])
          setOpenAttachment(false);
          setSelectedComment({})
          formRef.current && formRef.current.reset();
          setRemainingChars(256);

          return;
        }
      } catch (error) {
        setAlertMessage("`Error while adding comment");
        setShowError(true);
        setApprovalComment(() => "");

        setRemainingChars(256);

        props.handleClose();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCancel = async (anchor: Anchor) => {
    setApprovalComment(() => "");
    setData(() => []);
    setShowSuccess(false);
    setShowError(false);
    setRemainingChars(256);
    setSelectedComment({})
    formRef.current && formRef.current.reset();
    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <ConfirmDialog />
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
            sx={{ zIndex: "11" }}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
