import { useEffect, useState } from "react";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";

const useDialog = (options) => {
  const [title, setTitle] = useState(options.title || "Information");
  const [message, setMessage] = useState(options.message || "Alert");
  const [type, setType] = useState(options.message || "info");
  // const [onSubmit, setSubmit] = useState(options.onSubmit);
  // const [onClose, setClose] = useState(options.onClose);
  //type?: "error" | "warning" | "info" | "success" | "confirm";
  const [show, setShow] = useState(false);

  //   handleClose: () => void;
  //   handleAction?: () => void;

  const footerContent = (
    <div>
      {type && type === "confirm" && (
        <PrimeButton
          label='Cancel'
          icon='pi pi-times'
          className='p-button-text'
          onClick={() => handleClose()}
        />
      )}
      <PrimeButton
        label='OK'
        icon='pi pi-check'
        onClick={() => handleSubmit()}
        autoFocus
      />
    </div>
  );

  const AlertDialog = (props) => {
    return (
      <Dialog
        header={title}
        visible={show}
        style={{ width: "30vw" }}
        onHide={() => handleClose()}
        footer={footerContent}
        baseZIndex={1000}
      >
        <p className='m-0'>{message}</p>
      </Dialog>
    );
  };

  useEffect(() => {
    if (options?.title) setTitle(options.title);
    if (options?.message) setMessage(options.message);
    if (options?.type) setType(options.type);
    // if (options?.onSubmit) setSubmit(options.onSubmit);
    // if (options?.onClose) setClose(options.onClose);
    //eslint-disable-next-line
  }, [options]);

  // useEffect(() => {
  //   console.log("usePageTitle");
  //   if (options?.appTitle) {
  //     if (options?.pageTitle !== "") {
  //       const titleArray = options.appTitle.split("|");
  //       setPageTitle(
  //         titleArray[0] + " | " + options?.pageTitle + " | " + titleArray[1]
  //       );
  //       console.log("titleArray", titleArray, pageTitle);
  //     } else {
  //       setPageTitle(options.appTitle);
  //     }
  //   }
  //   //eslint-disable-next-line
  // }, []);
  const showDialog = (pTitle, pMessage, pType) => {
    if (pTitle) setTitle(() => pTitle);
    if (pMessage) setMessage(() => pMessage);
    if (pType) setType(() => pType);
    setShow(() => true);
  };

  const handleSubmit = () => {
    setShow(() => false);
    console.log("Closing OK", options.onSubmit);
    if (options.onSubmit) {
      options.onSubmit();
    }
  };

  const handleClose = () => {
    setShow(() => false);
    if (options.onClose) {
      options.onClose();
    }
  };

  const closeDialog = () => {
    setShow(() => false);
  };

  return {
    AlertDialog,
    showDialog,
    closeDialog,
  };
};

export default useDialog;
