import React, { Fragment, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import "./BusinessCategory.css";
import BusinessCategoryList from "./BusinessCategoryList";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Stack } from "@mui/material";
import { Dialog } from "primereact/dialog";
import { Button as PrimeButton } from "primereact/button";
import { useFormik } from "formik";
import * as yup from "yup";
import AuthContext from "../../context/auth/AuthContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import useDialog from "../common/UseDialog";

function Item(props) {
  const { sx, ...other } = props;

  return (
    <Box
      sx={{
        // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        // border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default function BusinessCategory() {
  const [name, setName] = React.useState("");
  const [actionMode, setActionMode] = React.useState("ADD");
  const [description, setDescription] = React.useState("");
  const [id, setId] = React.useState("");
  const [isTableLoading, setIsTableLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const { authUserId } = authContext;
  const filingMasterContext = useContext(FilingMasterContext);
  const {
    getBusinessCategories,
    addBusinessCategory,
    updateBusinessCategory,
    filingMasterLoading,
    businessCategories,
  } = filingMasterContext;

  const setEditname = (val) => {
    setName(val.businessCategoryName);
    setDescription(val.businessCategoryDescription);
    setId(val.businessCategoryId);
  };
  const collectData = (from, val) => {
    if (from == "name") {
      setName(val);
    } else {
      setDescription(val);
    }
  };
  const [isUpdated, setIsUpdated] = React.useState(false);

  yup.addMethod(yup.string, "uniqueCategoryName", function (errorMessage) {
    return this.test(`test-unique-category`, errorMessage, function (value) {
      const { path, createError } = this;
      console.log("Chcking ID", formik.values.businessCategoryId);
      if (formik.values.businessCategoryId) return true;
      return (
        businessCategories.findIndex(
          (item) =>
            item.businessCategoryName.toLowerCase() === value.toLowerCase()
        ) < 0 || createError({ path, message: errorMessage })
      );
    });
  });

  const validationSchema = yup.object({
    businessCategoryName: yup
      .string()
      .min(2, "Name is too Short!")
      .max(100, "Name is too Long!")
      .uniqueCategoryName("Name must be unique")
      // .test("Unique", "Category Name must be unique", (values) => {
      //   console.log("Uniuq test", values);
      //   console.log("Categories", businessCategories);
      //   console.log(
      //     "Test criteria",
      //     businessCategories.findIndex(
      //       (item) =>
      //         item.businessCategoryName.toLowerCase() === values.toLowerCase()
      //     ) >= 0
      //   );

      //   return (
      //     businessCategories.findIndex(
      //       (item) =>
      //         item.businessCategoryName.toLowerCase() === values.toLowerCase()
      //     ) >= 0
      //   );
      // })
      .required("Category Name is required"),

    businessCategoryDescription: yup
      .string()
      .required("Description is required"),
  });

  const initForm = {
    businessCategoryId: 0,
    businessCategoryName: "",
    businessCategoryDescription: "",
    createdDate: null,
    createdUser: "",
    updateDate: null,
    updateUser: "",
  };
  const [category, setCategory] = React.useState(initForm);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [alert, setAlert] = useState({ title: "Information", type: "info" });

  const handleSubmit = () => {
    console.log("Submitting....");
  };

  const closeConfirm = () => {
    console.log("Cancelling....");
    // navigate("/");
  };

  const { AlertDialog, showDialog } = useDialog({
    onSubmit: handleSubmit,
    onClose: closeConfirm,
  });

  const formik = useFormik({
    initialValues: initForm,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      submitForm();
    },
  });

  const submitForm = async () => {
    setIsTableLoading(true);
    console.log("Submitting....");
    try {
      if (actionMode === "ADD") {
        let _cat = { ...formik.values };
        delete _cat.businessCategoryId;
        delete _cat.updateUser;
        delete _cat.updateDate;
        _cat.createdDate = new Date();
        _cat.createdUser = authUserId;

        const res = await addBusinessCategory([_cat]);
        if (res) {
          setAlertMessage(
            <Typography>
            Successfully Added Business Category: {" "}
            <Typography fontWeight="bold" component="span">
            {formik.values.businessCategoryName}
            </Typography> {" "}
            </Typography>
          );
          setShowAlert(true);
          formik.resetForm();
          // navigate("/");
        }
      } else if (actionMode === "EDIT") {
        console.log("Submitting Edit....");
        let _cat = { ...formik.values };
        _cat.updateDate = new Date();
        _cat.updateUser = authUserId;
        const res = await updateBusinessCategory(_cat);
        if (res) {
          setActionMode(() => "ADD");
          setAlertMessage(
            <Typography>
            Successfully Updated Business Category: {" "}
            <Typography fontWeight="bold" component="span">
            {formik.values.businessCategoryName}
            </Typography> {" "}
            </Typography>
          );
          setShowAlert(true);
          formik.resetForm();
          // navigate("/");
        }
      }
    } catch (error) {
      // setAlertMessage(`Unexpected Error Occured`);
      // setShowAlert(true);
      showDialog("Error", "Unexpected Error Occured", "error");
      console.log("Setting Alert to =>", `Unexpected Error Occured`);
      setAlert((old) => ({
        ...old,
        message: "Unexpected Error Occured",
      }));
      setShowConfirm(true);
    } finally {
      setIsTableLoading(false);
    }
  };

  // useEffect(() => {
  //   // toggleDrawer(props.dockAt, props.show);
  //   if (selectedCustomer !== null) {
  //     const _cust = {
  //       customerId:
  //         selectedCustomer && selectedCustomer.customerId
  //           ? selectedCustomer.customerId
  //           : 0,
  // formik.setValues(_cust);
  //     //eslint-disable-next-line
  //   }, []);

  // const updateData = () => {
  //   let data = {
  //     businessCategoryId: id,
  //     businessCategoryName: name,
  //     businessCategoryDescription: description,
  //     createdDate: "2023-03-17T06:21:07.265Z",
  //     createdUser: "string",
  //     updateDate: new Date(),
  //     updateUser: "string",
  //   };
  //   axios
  //     .put(
  //       "https://localhost:7005/api/Application/BusinessCategoryMasterUpdate" +
  //         id,
  //       data
  //     )
  //     .then((res) => {
  //       setIsUpdated(true);
  //     });
  // };

  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const closeAlert = () => {
    setShowAlert(false);
    // navigate("/");
  };

  const selectRowForEdit = (editRecord) => {
    console.log("ROW Selected for Edit:", editRecord);
    setActionMode(() => "EDIT");
    formik.setValues(editRecord);
  };
  const formReset = () => {
    formik.resetForm();
    setActionMode(() => "ADD");
  };
  return (
    <Grid container>
      <Stack>
        <Box sx={{ width: "99%" }}>
          <Item>
            <div style={{ display: "flex" }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <ArrowBackIosNewIcon
                  style={{
                    marginTop: "5px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography
                variant="h6"
                component="h2"
                style={{ color: "#424242" }}
              >
                BUSINESS CATEGORY
              </Typography>
            </div>
          </Item>
        </Box>
        <Box sx={{ width: "99%" }}>
          <Grid container>
            <Grid md={4}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Box sx={{ mx: "1.5rem", mt: "1.5rem" }}>
                  <Item>
                    <TextField
                      required
                      id="businessCategoryName"
                      name="businessCategoryName"
                      label=" Business Category Name "
                      placeholder="Max-Length 100 characters "
                      fullWidth
                      variant="filled"
                      value={formik.values.businessCategoryName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.businessCategoryName &&
                        Boolean(formik.errors.businessCategoryName)
                      }
                      helperText={
                        formik.touched.businessCategoryName &&
                        formik.errors.businessCategoryName
                      }
                    />
                  </Item>
                  <Item>
                    <TextField
                      required
                      id="businessCategoryDescription"
                      name="businessCategoryDescription"
                      label="Description"
                      placeholder="Enter Description"
                      fullWidth
                      multiline
                      minRows={3}
                      variant="filled"
                      value={formik.values.businessCategoryDescription}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.businessCategoryDescription &&
                        Boolean(formik.errors.businessCategoryDescription)
                      }
                      helperText={
                        formik.touched.businessCategoryDescription &&
                        formik.errors.businessCategoryDescription
                      }
                    />
                  </Item>
                  <Item>
                    <Button
                      type="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ backgroundColor: "#0b3edf" }}
                    >
                      {actionMode}
                    </Button>
                  </Item>
                </Box>
              </form>
            </Grid>
            <Grid md={8}>
              <Item>
                <BusinessCategoryList
                  selectRowForEdit={selectRowForEdit}
                  isTableLoading={isTableLoading}
                  formReset={formReset}
                  // setName={setEditname}

                  // isUpdated={isUpdated}
                />
              </Item>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          header="Info"
          visible={showAlert}
          // style={{ width: "30vw" }}
          onHide={() => closeAlert()}
          footer={footerContent}
        >
          <p className="m-0">{alertMessage}</p>
        </Dialog>
        <AlertDialog />
      </Stack>
    </Grid>
  );
}
