import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DateTime } from "luxon";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import AuthContext from "../../context/auth/AuthContext";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ToolTip from "../common/ToolTip";



const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function FilingMasterHistory(props) {
  const [selectedFile, setSelectedFile] = useState("");

  const [data, setData] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const [commentData, setCommentData] = React.useState([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [value, setValue] = React.useState("1");
  const authContext = useContext(AuthContext);

  const { userRoles, toLocalDateTime } = authContext;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
  };

  

  const ListItemWithTooltip = (item) => {
    const source = item && item.source ? item.source : '';

    // Check if source needs truncation
    const displayText = source.length > 20 ? `${source.substring(0, 20)}...` : source;
    console.log("ListItemWithTooltip source", source, "displayText", displayText, "Length", source.length);
    return (
      <Tooltip title={source.length > 20 ? source : ''}>
        <Typography>
          {displayText}
        </Typography>
      </Tooltip>
    );
  };
  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        // if (
        //   event.type === "keydown" &&
        //   ((event as React.KeyboardEvent).key === "Tab" ||
        //     (event as React.KeyboardEvent).key === "Shift")
        // ) {
        //   return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };

  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");

      return _date;
    } else return "";
  };

  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {

      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
    }

    // if (
    //   getClientFilingMasterWorkflowAttachments &&
    //   props.workflow &&
    //   props.workflow.workflowId
    // ) {
    //   console.log(`Fetching Commnet for :${props.workflow.workflowId}`);
    //   //   fetchFilingMasterAttachments(props.workflow.workflowId);
    // }
    //eslint-disable-next-line
  }, [props.show]);

  const getUserName = (userId) => {

    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]
        ?.userName
      : "";
    return user;
  };

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  useEffect(() => {
    if (props.activityHistory) {
      let activity = props.activityHistory.map((ele) => {
        let changed = [];
        if (
          ele.process === "Modified" ||
          ele.process === "Removed" ||
          ele.process === "Newly added"
        ) {
          if (ele.duedate !== ele.newDueDate) {
            changed.push({
              field: "Due Date",
              old: toLocalTime(ele.duedate).substring(0, 11),
              new: toLocalTime(ele.newDueDate).substring(0, 11),
            });
          }
          if (ele.status !== ele.newStatus) {
            changed.push({
              field: "Status",
              old: ele.status,
              new: ele.newStatus,
            });
          }
          if (!objectsEqual(ele.staffAssigned, ele.newStaffAssigned)) {
            const oldStaffAssigned = ele.staffAssigned.reduce(
              (acc, staff) => acc + (acc === "" ? "" : ", ") + staff.userName,
              ""
            );
            const newStaffAssigned = ele.newStaffAssigned.reduce(
              (acc, staff) => acc + (acc === "" ? "" : ", ") + staff.userName,
              ""
            );
            changed.push({
              field: "Staff Assigned",
              old: oldStaffAssigned,
              new: newStaffAssigned,
            });
          }
        }
        if (changed.length > 0) {
          ele.changedData = changed;
        }

        return ele;
      });

      setData(() => props.activityHistory);
    }
    //eslint-disable-next-line
  }, [props.activityHistory]);

  useEffect(() => {
    if (props.clientActivityHistory) {
      let activity = props.clientActivityHistory.map((ele) => {
        let changed = [];
        if (ele.process === "Modified" || ele.process === "Removed") {
          let keys = Object.keys(ele);
          keys.forEach((key) => {
            const capitalized = key.charAt(0).toUpperCase() + key.slice(1);
            if (
              ele[`new${capitalized}`] !== undefined &&
              ele[key] !== ele[`new${capitalized}`]
            ) {
              changed.push({
                field: key,
                old: ele[key],
                new: ele[`new${capitalized}`],
              });
            }
          });
        }
        if (changed.length > 0) {
          ele.changedData = changed;
        }
        return ele;
      });
      setClientData(() => props.clientActivityHistory);
    }
    //eslint-disable-next-line
  }, [props.clientActivityHistory]);

  useEffect(() => {
    if (props.commentActivityHistory) {
      setCommentData(() => props.commentActivityHistory);
    }

  }, [props.commentActivityHistory])
  useEffect(() => {
    // console.log("TAB TN Tab", props.isClientFilingTabLoading, "CF Tab", props.isClientFilingTabLoading, "CommentTab", props.isCommentsTabLoading);
    console.log("HISTORY selectedFiling", props.tracker);
  }, [props.isTrackNowTabLoading, props.isClientFilingTabLoading, props.isCommentsTabLoading])
  const getFilename = function (str) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };

  const ListElement = (item, index) => {
    //const [showChanges, setShowChanges] = useState(false);
    return (
      <Fragment key={index}>
        <Stack direction="row" justifyContent="space-between">
          <ListItem
            disablePadding
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {item.updateuser !== null
              ? getUserName(item.updateuser)
              : getUserName(item.createuser)}
          </ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            <ListItemText
              secondary={
                item && item.updateuser !== null
                  ? toLocalDateTime && `${toLocalDateTime(item.updatedate)}`
                  : toLocalDateTime && `${toLocalDateTime(item.createdate)}`
              }
            />
          </ListItem>
        </Stack>
        <ListItem disablePadding>

          <ListItemText
            primary={
              item && item.process && item.process !== null ? item.process : ""
            }
          />
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setShowChanges(!showChanges)}
          >
            {showChanges ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </ListItem>
        {item.changedData && item.changedData.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 250 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Old value</TableCell>
                  <TableCell>New value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.changedData.map((ele) => (
                  <TableRow>
                    <TableCell>{ele.field}</TableCell>
                    <TableCell>{ele.old}</TableCell>
                    <TableCell>{ele.new}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Fragment>
    );
  };

  const ClientListElement = (item, index) => {
    //const [showChanges, setShowChanges] = useState(false);
    return (
      <Fragment key={index}>
        <Stack direction="row" justifyContent="space-between">
          <ListItem
            disablePadding
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {item.requestBy}
          </ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            <ListItemText
              secondary={item && toLocalDateTime && `${toLocalDateTime(item.requestDate)}`}
            />
          </ListItem>
        </Stack>
        <ListItem disablePadding>
          <ListItemText
            primary={
              item && item.process && item.process !== null ? item.process : ""
            }
          />
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setShowChanges(!showChanges)}
          >
            {showChanges ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </ListItem>
        {item.changedData && item.changedData.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 250 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Old value</TableCell>
                  <TableCell>New value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.changedData.map((ele) => (
                  <TableRow>
                    <TableCell>{ele.field}</TableCell>
                    <TableCell>{ele.old}</TableCell>
                    <TableCell>{ele.new}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Stack direction="row" justifyContent="space-between">
          <ListItem
            disablePadding
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {item.responsedBy}
          </ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            <ListItemText
              secondary={
                item && item.responseDate && toLocalDateTime && `${toLocalDateTime(item.responseDate)}`
              }
            />
          </ListItem>
        </Stack>
        <Stack direction="row">
          <ListItem disablePadding>Approved Status</ListItem>
          <ListItem sx={{ textAlign: "right" }}>{item.responseStatus}</ListItem>
        </Stack>
        <Stack direction="row">
          <ListItem disablePadding>Comment:</ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            {item.approverComments}
          </ListItem>
        </Stack>
        <Divider sx={{ borderBottomWidth: 3 }} />
      </Fragment>
    );
  };

  const CommentListElement = (item, index) => {
    return (
      <Fragment key={index}>
        <Stack direction="row" justifyContent="space-between">
          <ListItem
            disablePadding
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {item.updateUser !== null
              ? getUserName(item.updateUser)
              : getUserName(item.createUser)}
          </ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            <ListItemText
              secondary={
                item && item.updateDate !== null
                  ? toLocalDateTime && `${toLocalDateTime(item.updateDate)}`
                  : toLocalDateTime && `${toLocalDateTime(item.createDate)}`
              }
            />
          </ListItem>
        </Stack>
        <ListItem disablePadding sx={{ justifyContent: "space-between" }}>
          {props.tracker.isUnderParent && (
            <ListItemText
              primary={
                ListItemWithTooltip(item)
              }

            />
          )}
          <ListItemText
            primary={
              item && item.process && item.process !== null ? item.process : ""
            }
          />
        </ListItem>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 250 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Old value</TableCell>
                <TableCell>New value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{item.previousCommentsText}</TableCell>
                <TableCell>{item.commentsText}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  };


  const list = (anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div
                  style={{
                    width: "30vw",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem",
                  }}
                >
                  ACTIVITY HISTORY
                  <IconButton>
                    <CloseIcon onClick={() => handleCancel(anchor)} />
                  </IconButton>
                </div>
              </Stack>
              <CardContent>
                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Track Now" value="1" />
                    <Tab label="Client Filing" value="2" />
                    <Tab label="Comments" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {props.isTrackNowTabLoading ? (
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "100px",
                        }}
                      >
                        <CircularProgress size={40} />
                      </div>
                    </CardContent>
                  ) : data?.length === 0 ? (
                    <CardContent>
                      <Typography variant="body1" color="textSecondary">
                        No activity history for tracknow filings
                      </Typography>
                    </CardContent>
                  ) : (
                    data &&
                    data
                      .slice()
                      // .reverse()
                      .map((item, index) => ListElement(item, index)))}

                </TabPanel>
                <TabPanel value="2">
                  {props.isClientFilingTabLoading ? (
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "100px",
                        }}
                      >
                        <CircularProgress size={40} />
                      </div>
                    </CardContent>
                  ) : clientData?.length === 0 ? (
                    <CardContent>
                      <Typography variant="body1" color="textSecondary">
                        No activity history for client filings
                      </Typography>
                    </CardContent>
                  ) : (
                    clientData &&
                    clientData
                      .slice()
                      // .reverse()
                      .map((item, index) => ClientListElement(item, index)))}
                </TabPanel>
                <TabPanel value="3">
                  {props.isCommentsTabLoading ? (
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "100px",
                        }}
                      >
                        <CircularProgress size={40} />
                      </div>
                    </CardContent>
                  ) : commentData?.length === 0 ? (
                    <CardContent>
                      <Typography variant="body1" color="textSecondary">
                        No activity history for comments
                      </Typography>
                    </CardContent>
                  ) : (
                    commentData &&
                    commentData
                      .slice()
                      // .reverse()
                      .map((item, index) => CommentListElement(item, index)))}
                </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );

  const handleCancel = async (anchor) => {
    setSelectedFile(() => null);
    setShowSuccess(false);
    setShowError(false);
    setState({ ...state, [anchor]: false });
    setData([]);
    setCommentData([]);
    setClientData([])
    props.setActivityHistory([])
    props.setClientActivityHistory([])
    props.setCommentActivityHistory([])
    props.setIsCfHistoryLoading(true)
    props.setIsCommentsHistoryLoading(true)
    props.setIsTnHistoryLoading(true)


    props.handleClose();
  };

  return (
    <div>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
