import React, { Fragment, useEffect, useState, useContext } from "react";
import { Card, CardContent, IconButton, Grid, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DateTime } from "luxon";
import FileDownload from "js-file-download";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import AuthContext from "../../context/auth/AuthContext";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function ClientFilingTrackerDocuments(props) {
  const [selectedFile, setSelectedFile] = useState("");

  const [data, setData] = React.useState([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);
  const { userRoles , toLocalDateTime } = authContext;

  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
    };
  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");

      return _date;
    } else return "";
  };
  useEffect(() => {
    setLoading(true);
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
 
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
    }
    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    setLoading(true);
    if (props.attachments) {
      try {
        setData(() => props.attachments);
      } finally {
        setLoading(false);
      }
    }

    //eslint-disable-next-line
  }, [props.attachments]);

  const getFilename = function (str) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };

  const getUserName = (userId) => {
    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]
          ?.userName
      : "";
    return user;
  };

  const downloadFile = (attachmentPath, attachmentId) => {
    const fileName = getFilename(attachmentPath);

    let filingId = props.tracker?.filingId ? props.tracker?.filingId + "" : "0";
    axios({
      url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&FilingId=${filingId}`,
      method: "POST",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  };

  const list = (anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div
                  style={{
                    width: "30vw",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem",
                  }}
                >
                  REFERENCE DOCUMENTS
                  <IconButton>
                    <CloseIcon onClick={() => handleCancel(anchor)} />
                  </IconButton>
                </div>
              </Stack>
              <CardContent>
                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <Divider />
              {data && data.length === 0 ? (
                loading ? (
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                      }}
                    >
                      <CircularProgress size={40} />
                    </div>
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography variant="body1" color="textSecondary">
                      No reference documents
                    </Typography>
                  </CardContent>
                )
              ) : (
                <List>
                  {data &&
                    data
                      .slice()
                      .reverse()
                      .map((item, index) => (
                        <Fragment key={index}>
                          <Stack direction="row" justifyContent="space-between">
                            <ListItem
                              disablePadding
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              {getUserName(item.createUser)}
                            </ListItem>
                            <ListItem sx={{ textAlign: "right" }}>
                              <ListItemText
                                secondary={
                                  item &&
                                  item.createDate &&
                                  toLocalDateTime &&
                                  `${toLocalDateTime(item.createDate)}`
                                }
                              />
                            </ListItem>
                          </Stack>

                          <ListItem disablePadding>
                            <ListItemIcon>
                              <Link>
                                <AttachFileIcon />
                              </Link>
                            </ListItemIcon>
                            <ListItemButton
                              onClick={() =>
                                downloadFile(
                                  item.attachmentPath,
                                  item.attachmentId
                                )
                              }
                            >
                              <ListItemText
                                primary={
                                  item &&
                                  item.attachmentPath &&
                                  getFilename(item.attachmentPath)
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        </Fragment>
                      ))}
                </List>
              )}
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );

  const handleCancel = async (anchor) => {
    setSelectedFile(() => null);
    setData(() => []);
    setShowSuccess(false);
    setShowError(false);

    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
