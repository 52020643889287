import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import axios from "axios";
import Collapse from "@mui/material/Collapse";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownload from "js-file-download";
import Input from "@mui/material/Input";
import ShortTextIcon from "@mui/icons-material/ShortText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AuthContext from "../../context/auth/AuthContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Toolbar, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import Alert from "@mui/material/Alert";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ClientFilingMaster,
  // ClientFilingMasterWorkflowComment,
  CreateCustomerFilingComment,
} from "../../types/ClientFilingMaster";

type Anchor = "top" | "left" | "bottom" | "right";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function ViewFilingComments(props: {
  clientMaster: any;
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  comments?: CreateCustomerFilingComment[];
}) {
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const {
    //getClientFilingMasterWorkflowComments,
    getCustomerFilingCommentsAttachments,
    AddCreateCustomerFilingComments,
  } = clientFilingMasterContext;
  const [data, setData] = React.useState<CreateCustomerFilingComment[]>([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const authContext = useContext(AuthContext);
  const { authUser, userRoles } = authContext;
  const [alertMessage, setAlertMessage] = useState("");
  const [approvalComment, setApprovalComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [remainingChars, setRemainingChars] = useState(256);
  const [selectedFile, setSelectedFile] = useState("");
  const [openAttachment, setOpenAttachment] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  // const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  // function handleCloseClick() {

  //       setIsPopUpVisible(false);

  //     }
  const [showComments, setShowComments] = useState(false);

  // const [selectedClientsState, setSelectedClientsState] = useState(
  //   props.selectedClients
  // );

  // useEffect(() => {
  //   setSelectedClientsState(props.selectedClients);
  //   console.log("receivedSelectedClient###", selectedClientsState);
  // }, [props.selectedClients]);
  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
    // navigate("/filing-master-approval-list");
  };
  const closeComments = () => {
    console.log("Toggling Show Filter", showComments);
    setShowComments(() => false);
  };
  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        console.log(`Drawer Mouse Key Event...`);
        // if (
        //   event.type === "keydown" &&
        //   ((event as React.KeyboardEvent).key === "Tab" ||
        //     (event as React.KeyboardEvent).key === "Shift")
        // ) {
        //   return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };
  const toLocalTime = (date: Date): string => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");
      console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };
  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
    }
    setApprovalComment((old) => "");

    if (
      getCustomerFilingCommentsAttachments &&
      props.clientMaster
      //&&
      //   props.workflow.workflowId
    ) {
      console.log(`Fetching Commnet for :${props.clientMaster}`);
      //   fetchFilingMasterComments(props.workflow.workflowId);
    }
    if (!props.show) {
      setData([]);
    }
    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    const _data: CreateCustomerFilingComment[] = props.comments!;
    if (props.comments && props.comments.length > 0) {
      setData(() => _data);
      console.log("Data##", _data);
      console.log("Data2", _data.slice());
    }

    //eslint-disable-next-line
  }, [props.comments]);

  //   useEffect(() => {
  //     // if(props.workflow && props.workflow.createDate!==null)
  //     //eslint-disable-next-line
  //   }, [props.workflow]);

  //   const fetchFilingMasterComments = async (filingId: number) => {
  //     console.log("@@Fetch FilingMasters1:", getFilingMasterWorkflowComments);
  //     if (getFilingMasterWorkflowComments) {
  //       console.log("@@Fetch FilingMasters2:");
  //       try {
  //         const commentList = await getFilingMasterWorkflowComments(filingId);
  //         setData(() => commentList);
  //         console.log("@@Fetch FilingMasters2 comments:", commentList);
  //       } catch (error) {
  //         console.log("@@Error:", error);
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     console.log(`Fetching Commnet for 0:${props.workflow}`);
  //     if (
  //       typeof props.dockAt !== "undefined" &&
  //       typeof props.show !== "undefined"
  //     ) {
  //       console.log(
  //         `Toogle Drawer UED: DockAt:${props.dockAt}, Show: ${props.show}`
  //       );
  //       // toggleDrawer(props.dockAt, props.show);
  //       setState({ ...state, [props.dockAt]: props.show });
  //     }
  //     setApprovalComment((old) => "");

  //     if (
  //       getFilingMasterWorkflowComments &&
  //       props.workflow &&
  //       props.workflow?.workflowId
  //     ) {
  //       console.log(`Fetching Commnet for :${props.workflow.workflowId}`);
  //       fetchFilingMasterComments(props.workflow?.workflowId);
  //     }
  //     //eslint-disable-next-line
  //   }, []);

  const onFileChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const getUserName = (userId: any) => {
    console.log("USER ROLES LKP:", userRoles);
    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]
        ?.userName
      : "";
    return user;
  };

  const list = (anchor: Anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "medium",
                        fontSize: "16px",
                        color: "#424242",
                      }}
                    >
                      COMMENTS
                    </Typography>
                    <IconButton>
                      <CloseIcon onClick={() => handleCancel(anchor)} />
                    </IconButton>
                  </Stack>
                </div>
                <div>
                  <Box sx={{ mx: 3 }}>
                    <TextField
                      id="notes"
                      name="notes"
                      label={
                        <span style={{ font: "italic normal normal" }}>
                          Add Comments
                        </span>
                      }
                      multiline
                      fullWidth
                      minRows={2}
                      variant="filled"
                      placeholder={"Enter Comments..."}
                      value={approvalComment}
                      onChange={(e) => {
                        if (e.target.value.length <= 256) {
                          onCommentChange(e);
                          setRemainingChars(256 - e.target.value.length);
                        }
                      }}
                    />

                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "small",
                        color: remainingChars === 0 ? "red" : "inherit",
                        display: remainingChars === 256 ? "none" : "block",
                      }}
                    >
                      {remainingChars} Characters are left
                    </Typography>
                  </Box>
                </div>
                <div>
                  <MuiButton
                    variant="text"
                    onClick={() => setOpenAttachment(!openAttachment)}
                  >
                    <AttachFileIcon /> ATTACHMENT
                  </MuiButton>
                  <Collapse orientation="horizontal" in={openAttachment}>
                    <form
                      method="post"
                      id="uploadForm"
                      onChange={(event) => onFileChange(event)}
                      style={{ margin: "1rem" }}
                    >
                      <label className="file-upload">
                        <Input
                          type="file"
                          name="file"
                          id="inFile"
                          color="primary"
                        />
                      </label>
                    </form>
                  </Collapse>
                </div>
                <div>
                  <CardActions
                    sx={{
                      // mt: "3rem",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      // sx={{ margin: 1, padding: 1 }}
                      onClick={() => handleCancel(anchor)}
                    >
                      Cancel
                    </MuiButton>
                    <MuiButton
                      variant="contained"
                      type="submit"
                      sx={{ backgroundColor: "#0b3edf" }}
                      // sx={{ margin: 1, padding: 1 }}
                      onClick={() => handleOk(anchor)}
                    >
                      Add
                    </MuiButton>
                  </CardActions>
                </div>
              </Stack>
              <CardContent>
                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <Divider />

              <List>
                {data &&
                  data
                    .slice()
                 //   .reverse()
                    .map((item, index: number) => (
                      <Fragment key={index}>
                        <Stack direction="row" justifyContent="space-between">
                          <ListItem
                            disablePadding
                            style={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            {getUserName(item.createUser)}
                          </ListItem>
                          <ListItem sx={{ textAlign: "right" }}>
                            <ListItemText
                              secondary={
                                item &&
                                item.createDate &&
                                `${toLocalTime(item.createDate)}`
                              }
                            />
                          </ListItem>
                        </Stack>

                        <ListItem
                          disablePadding
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                          }}
                        >
                          <ListItemText
                            sx={{
                              '&:hover': {
                                backgroundColor: '#f0f0f0',
                                cursor: "text"
                              },
                              width: "100%",
                            }}
                            primary={
                              item &&
                                item.commentsText &&
                                item.commentsText !== null
                                ? item.commentsText
                                : ""
                            }
                          />
                          {item.attachmentPath && (
                            <MuiButton
                              variant="text"
                              onClick={() => downloadFile(item.attachmentPath)}
                            >
                              <AttachFileIcon />
                              {/* <Tooltip
                                title={getFilenameToolip(item.attachmentPath)}
                              >
                                <Typography>
                                  {getFilename(item.attachmentPath)}
                                </Typography>
                              </Tooltip> */}
                              <Tooltip
                                title={getFilenameToolip(item.attachmentPath)}
                              >
                                <ListItemText
                                  primary={
                                    item &&
                                    item.attachmentPath &&
                                    getFilename(item.attachmentPath)
                                  }
                                />
                              </Tooltip>
                            </MuiButton>
                          )}
                        </ListItem>
                      </Fragment>
                    ))}
              </List>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );

  const onCommentChange = async (e: React.ChangeEvent<any>) => {
    setApprovalComment((old) => e.target.value);
    if (approvalComment) {
      setShowSuccess(false);
      setShowError(false);
    }
  };

  const updateFilePath = async (
    commentsId: any,
    attachmentId: any,
    filePath: any
  ) => {
    console.log("updateFilePath", attachmentId, filePath);
    let payload = {
      attachmentId: attachmentId,
      attachmentPath: decodeURI(filePath),
      createDate: new Date(),
      createUser: authUser?.userId + "",
      commentsId: commentsId,
      filingId: props.clientMaster.filingId
        ? parseInt(props.clientMaster.filingId)
        : 0,
      customerId: props.clientMaster?.customerId
        ? parseInt(props.clientMaster?.customerId)
        : 0,
    };

    const res = await axios.put(
      `${API_BASE_URL}/Customer/UpdateCustomerFilingCommentsAttachments/${attachmentId}`,
      payload
    );
    console.log("updateFilePath res", res);
    if (res.data) {
    }
  };

  const fileUploadUtil = async (attachmentId: any, commentsId: any) => {
    console.log(
      "fileUploadUtil params",
      attachmentId,
      commentsId,
      props.clientMaster
    );
    const formData = new FormData();
    formData.append("Files", selectedFile);
    formData.append("customerId", props.clientMaster.customerId);
    formData.append("filingId", props.clientMaster.filingId);
    formData.append("commentsId", commentsId);
    formData.append("attachmentId", attachmentId);

    const res = await axios.post(
      `${API_BASE_URL}/Application/CustomerFilingUpload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("fileUploadUtil res", res);

    if (res.data) {
      console.log("fileUploadUtil res header", res.headers);
      await updateFilePath(commentsId, attachmentId, res.headers["x-file-url"]);
      // setFiles([...files, selectedFile]);
    }
  };

  const getAttachmentId = async (commentsId: any) => {
    console.log("getAttachmentIt", props.clientMaster);
    let payload = {
      commentsId: commentsId,
      customerId: parseInt(props.clientMaster.customerId),
      filingId: parseInt(props.clientMaster.filingId),
    };

    const res = await axios.post(
      `${API_BASE_URL}/Customer/CreateCustomerFilingCommentsAttachments`,
      payload
    );
    console.log("getAttachmentIt res", res.data);
    if (res.data) {
      return res.data.attachmentId;
    }
  };

  const onFileUpload = async (commentsId: any) => {
    let attachmentId = await getAttachmentId(commentsId);
    await fileUploadUtil(attachmentId, commentsId);
    return;
  };

  const getFilenameToolip = function (str: any) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };
  const getFilename = function (str: any) {
    const MAX_FILENAME_LENGTH = 20;

    if (str) {
      let filename = str.replace(/^.*[\\\/]/, "");
      if (filename.length > MAX_FILENAME_LENGTH) {
        const halfLength = Math.floor((MAX_FILENAME_LENGTH - 3) / 2);
        const firstHalf = filename.substr(0, halfLength);
        const secondHalf = filename.substr(-halfLength);
        filename = `${firstHalf}...${secondHalf}`;
      }
      return filename;
    } else {
      return "";
    }
  };

  const downloadFile = (attachmentPath: any) => {
    console.log("Downloading File from:", attachmentPath);
    const fileName = getFilename(attachmentPath);
    console.log("Downloading File:", fileName);
    axios({
      url: `${API_BASE_URL}/Application/DownloadBlob?FileUrl=${attachmentPath}`,
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  };

  const handleOk = async (anchor: Anchor) => {
    // navigate("/filing-master-list");
    setShowError(false);
    console.log(`Add Comment:${approvalComment}`);
    if (approvalComment.length === 0) {
      setAlertMessage("Please enter a comment.");
      setShowError(true);
    } else {
      setShowSuccess(false);
      setShowError(false);
      console.log(`Add Comment:${approvalComment}`);
      try {
        const createDate = new Date();

        const payload = {
          commentsText: approvalComment,
          customerId: props.clientMaster.customerId,
          filingId: props.clientMaster.filingId,
          createDate,
          createUser: authUser?.userId + "",
        };
        console.log("ADDing", AddCreateCustomerFilingComments);

        if (AddCreateCustomerFilingComments) {
          console.log("Add Comment payload", payload);
          const res = await AddCreateCustomerFilingComments(payload);
          if (res) {
            console.log(`Comment has been added`, res);
            if (selectedFile) {
              await onFileUpload(res.commentsId);
            }
            if (getCustomerFilingCommentsAttachments) {
              const _catData = await getCustomerFilingCommentsAttachments(
                props.clientMaster
              );
              setData(() => _catData);
            }
            setState({ ...state, [anchor]: true });
            setAlertMessage("Comment has been added");
            setShowError(false);
            setShowSuccess(true);
            setRemainingChars(256);
            setApprovalComment(() => "");
            setSelectedFile("");
            setOpenAttachment(false);
            formRef.current && formRef.current.reset();
            return;
          }
        }
      } catch (error) {
        console.log(`Error while adding comment`, error);
        setAlertMessage("`Error while adding comment");
        setApprovalComment(() => "");
        setShowError(true);
        props.handleClose();
      }
    }
  };

  const handleCancel = async (anchor: Anchor) => {
    setApprovalComment(() => "");
    setShowSuccess(false);
    setShowError(false);
    setRemainingChars(256);
    setData([]);
    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
