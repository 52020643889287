import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ShortTextIcon from "@mui/icons-material/ShortText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AuthContext from "../../context/auth/AuthContext";
// import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";

import {
  ClientFilingMaster,
  ClientFilingMasterWorkflowComment,
} from "../../types/ClientFilingMaster";
import FileDownload from "js-file-download";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useDialog from "../common/UseDialog";

type Anchor = "top" | "left" | "bottom" | "right";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function ClientFilingMasterWorkflowComments(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  workflow?: ClientFilingMaster;
  rowData?: ClientFilingMaster;
  comments?: ClientFilingMasterWorkflowComment[];
  refreshList: () => void;
}) {
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const {
    getClientFilingMasterWorkflowComments,
    addClientFilingMasterWorkflowComment,
    getCustomerMasterWorkflowCommentsAttachments,
  } = clientFilingMasterContext;
  const [data, setData] = React.useState<ClientFilingMasterWorkflowComment[]>(
    []
  );
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const authContext = useContext(AuthContext);
  const { authUser, userRoles, toLocalDateTime, roleType } = authContext;
  const [alertMessage, setAlertMessage] = useState("");
  const [approvalComment, setApprovalComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [remainingChars, setRemainingChars] = useState(256);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedComment, setSelectedComment] = useState<any>({});
  const [actionType, setActionType] = useState<any>("Add")

  // const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  // function handleCloseClick() {

  //       setIsPopUpVisible(false);

  //     }
  const onFileChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
    // navigate("/client-filing-master-approval-list");
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  // const getFilename = function (str: any) {
  //   return str ? str.replace(/^.*[\\\/]/, "") : "";
  // };
  const getFilenameToolip = function (str: any) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };
  const getFilename = function (str: any) {
    const MAX_FILENAME_LENGTH = 20;

    if (str) {
      let filename = str ? str.replace(/^.*[\\\/]/, "") : "";
      if (filename.length > MAX_FILENAME_LENGTH) {
        const halfLength = Math.floor((MAX_FILENAME_LENGTH - 3) / 2);
        const firstHalf = filename.substr(0, halfLength);
        const secondHalf = filename.substr(-halfLength);
        filename = `${firstHalf}...${secondHalf}`;
      }
      return filename;
    } else {
      return "";
    }
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        console.log(`Drawer Mouse Key Event...`);
        // if (
        //   event.type === "keydown" &&
        //   ((event as React.KeyboardEvent).key === "Tab" ||
        //     (event as React.KeyboardEvent).key === "Shift")
        // ) {
        //   return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };
  const toLocalTime = (date: Date): string => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");
      console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };
  useEffect(() => {
    setLoading(true);
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) {
        setState({ ...state, [props.dockAt]: props.show });
        setActionType("Add")
      }
    }
    setApprovalComment((old) => "");

    if (
      (getClientFilingMasterWorkflowComments &&
        props.workflow &&
        props.workflow.workflowId) ||
      (getClientFilingMasterWorkflowComments &&
        props.rowData &&
        props.rowData.workflowId)
    ) {
      console.log(`Fetching Commnet for :${props.workflow?.workflowId}`);
      console.log(`Fetching Commnet for ROWdATA :${props.rowData?.workflowId}`);
      //   fetchFilingMasterComments(props.workflow.workflowId);
    }
    console.log("PROPS CHECK", "Workflow", props.workflow, "RowData", props.rowData);

    //eslint-disable-next-line
  }, [props.show]);

  const fetchCommentsAttachments = async (data: any = null) => {
    data = data ? data : props.comments;
    let attachments = await getCustomerMasterWorkflowCommentsAttachments?.();
    console.log("Fetched attachments", attachments);
    let comments =
      data &&
      data.map((comment: any) => {
        const matchingAttachment = attachments.find(
          (attachment: any) => attachment.commentsId === comment.commentsId
        );
        if (matchingAttachment) {
          return {
            ...comment,
            attachmentPath: matchingAttachment.attachmentPath,
            attachmentId: matchingAttachment.attachmentId,
          };
        } else {
          return comment;
        }
      });
    console.log("Attachments", comments);
    comments && setData(comments);
  };

  useEffect(() => {
    const _data: ClientFilingMasterWorkflowComment[] = props.comments!;
    setLoading(true);
    if (props.comments) {
      try {
        setData(() => _data);
      } finally {
        setLoading(false);
      }
    }

    fetchCommentsAttachments();
    //eslint-disable-next-line
  }, [props.comments]);

  //   useEffect(() => {
  //     // if(props.workflow && props.workflow.createDate!==null)
  //     //eslint-disable-next-line
  //   }, [props.workflow]);

  //   const fetchFilingMasterComments = async (filingId: number) => {
  //     console.log("@@Fetch FilingMasters1:", getClientFilingMasterWorkflowComments);
  //     if (getClientFilingMasterWorkflowComments) {
  //       console.log("@@Fetch FilingMasters2:");
  //       try {
  //         const commentList = await getClientFilingMasterWorkflowComments(filingId);
  //         setData(() => commentList);
  //         console.log("@@Fetch FilingMasters2 comments:", commentList);
  //       } catch (error) {
  //         console.log("@@Error:", error);
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     console.log(`Fetching Commnet for 0:${props.workflow}`);
  //     if (
  //       typeof props.dockAt !== "undefined" &&
  //       typeof props.show !== "undefined"
  //     ) {
  //       console.log(
  //         `Toogle Drawer UED: DockAt:${props.dockAt}, Show: ${props.show}`
  //       );
  //       // toggleDrawer(props.dockAt, props.show);
  //       setState({ ...state, [props.dockAt]: props.show });
  //     }
  //     setApprovalComment((old) => "");

  //     if (
  //       getClientFilingMasterWorkflowComments &&
  //       props.workflow &&
  //       props.workflow?.workflowId
  //     ) {
  //       console.log(`Fetching Commnet for :${props.workflow.workflowId}`);
  //       fetchFilingMasterComments(props.workflow?.workflowId);
  //     }
  //     //eslint-disable-next-line
  //   }, []);
  const getUserName = (userId: string) => {
    console.log("USER ROLES LKP:", userRoles);
    console.log("USER ROLES LKP2 :", userId);

    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]?.userName
      : "";
    return user;
  };
  const list = (anchor: Anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "medium",
                        fontSize: "16px",
                        color: "#424242",
                      }}
                    >
                      COMMENTS
                    </Typography>
                    <IconButton>
                      <CloseIcon onClick={() => handleCancel(anchor)} />
                    </IconButton>
                  </Stack>
                </div>
                <div>
                  <Box sx={{ mx: 3 }}>
                    <TextField
                      id="notes"
                      name="notes"
                      label={
                        <span style={{ font: "italic normal normal" }}>
                          Add Comments
                        </span>
                      }
                      multiline
                      fullWidth
                      minRows={2}
                      variant="filled"
                      placeholder={"Enter Comments..."}
                      value={approvalComment}
                      onChange={(e) => {
                        if (e.target.value.length <= 256) {
                          onCommentChange(e);
                          setRemainingChars(256 - e.target.value.length);
                        }
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "small",
                        color: remainingChars === 0 ? "red" : "inherit",
                        display: remainingChars === 256 ? "none" : "block",
                      }}
                    >
                      {remainingChars} Characters are left
                    </Typography>
                  </Box>
                </div>
                <div>
                  <MuiButton
                    variant="text"
                    onClick={() => setOpenAttachment(!openAttachment)}
                    disabled={actionType === "Edit"}
                  >
                    <AttachFileIcon /> ATTACHMENT
                  </MuiButton>
                  {actionType === "Edit" && selectedComment
                    && selectedComment.attachmentPath && selectedComment.attachmentPath !== "undefined" && (
                      <MuiButton
                        variant="text"
                        onClick={() =>
                          downloadFile(
                            selectedComment.attachmentPath,
                            selectedComment.attachmentId,
                            selectedComment.commentsId
                          )
                        }
                      >
                        {selectedComment.attachmentPath && <AttachFileIcon />}
                        <Tooltip
                          title={getFilenameToolip(selectedComment.attachmentPath)}
                        >
                          <ListItemText
                            primary={
                              selectedComment &&
                              selectedComment.attachmentPath &&
                              getFilename(selectedComment.attachmentPath)
                            }
                          />
                        </Tooltip>
                      </MuiButton>
                    )}
                  <Collapse orientation="horizontal" in={openAttachment}>
                    <form
                      method="post"
                      id="uploadForm"
                      onChange={(event) => onFileChange(event)}
                      style={{ margin: "1rem" }}
                    >
                      <label className="file-upload">
                        <Input
                          type="file"
                          name="file"
                          id="inFile"
                          color="primary"
                        />
                      </label>
                    </form>
                  </Collapse>
                </div>
                <div>
                  <CardActions
                    sx={{
                      // mt: "3rem",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      // sx={{ margin: 1, padding: 1 }}
                      onClick={() => handleCancel(anchor)}
                    >
                      Cancel
                    </MuiButton>
                    {actionType === "Add" ?
                      <MuiButton
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: "#0b3edf" }}
                        onClick={() => handleOk(anchor)}
                      >
                        Add
                      </MuiButton>
                      : <MuiButton
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: "#0b3edf" }}
                        disabled={selectedComment.commentsText.trim() === approvalComment.trim()}
                        // sx={{ margin: 1, padding: 1 }}
                        onClick={() => editComment()}
                      >
                        Edit
                      </MuiButton>}
                  </CardActions>
                </div>
              </Stack>
              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? <CircularProgress size={40} /> : null}

                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <Divider />
              {data && data.length === 0 ? (
                loading ? (
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                      }}
                    >
                      <CircularProgress size={40} />
                    </div>
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography variant="body1" color="textSecondary">
                      No comments
                    </Typography>
                  </CardContent>
                )
              ) : (
                <List>
                  {data &&
                    data
                      .slice()
                      // .reverse()
                      .map((item, index: number) => (
                        <Fragment key={index}>
                          <Stack direction="row" justifyContent="space-between">
                            <ListItem
                              disablePadding
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              {item.updateUser
                                ? getUserName(item.updateUser)
                                : item.createUser && getUserName(item.createUser)}                            </ListItem>
                            <ListItem sx={{ textAlign: "right" }}>
                              <ListItemText
                                secondary={
                                  item &&
                                    item.updateDate
                                    ? toLocalDateTime && `${toLocalDateTime(item.updateDate)}`
                                    : toLocalDateTime && `${toLocalDateTime(item.createDate)}`
                                }
                              />
                            </ListItem>
                          </Stack>

                          <ListItem
                            disablePadding
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

                              <ListItemText
                                sx={{
                                  '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                    cursor: "text"
                                  },
                                  width: "100%",
                                }}
                                primary={
                                  item &&
                                    item.commentsText &&
                                    item.commentsText !== null
                                    ? item.commentsText
                                    : ""
                                }
                              />
                              <IconButton
                                color='primary'
                                disabled={DisableEdit(item)}
                              >
                                <EditIcon
                                  onClick={() => handleEditComment(item)}
                                  sx={{ cursor: "pointer" }}
                                />
                              </IconButton>
                              <IconButton
                                color='primary'
                                disabled={DisableEdit(item)}
                              >
                                <DeleteIcon
                                  onClick={() => handleDeleteComment(item)}
                                  sx={{ cursor: "pointer", fontWeight: "400", }}
                                />
                              </IconButton>
                            </div>
                            <MuiButton
                              variant="text"
                              onClick={() =>
                                downloadFile(
                                  item.attachmentPath,
                                  item.attachmentId,
                                  item.commentsId
                                )
                              }
                            >
                              {
                                //@ts-ignore
                                item.attachmentPath && <AttachFileIcon />
                              }
                              <ListItemText
                                primary={
                                  item &&
                                  //@ts-ignore
                                  item.attachmentPath &&
                                  getFilename(item.attachmentPath)
                                }
                              />
                            </MuiButton>
                          </ListItem>
                        </Fragment>
                      ))}
                </List>
              )}
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );
  const DisableEdit = (item: any) => {
    if (roleType.includes('Admin')) {
      return false
    } else if (authUser?.userId && authUser?.userId === parseInt(item.createUser)) {
      return false
    } else {
      return true
    }
  }
  const handleEditComment = (item: any) => {
    console.log("handleEditComment", item)
    setActionType("Edit")
    setSelectedComment(item)
    setApprovalComment(item.commentsText)
  }
  const editComment = async () => {
    console.log("editComment", selectedComment, props)
    setLoading(true);
    if ((props.workflow && props.workflow.workflowId) || (props.rowData && props.rowData.workflowId)) {
      try {
        const updateDate = new Date();
        let payload = {
          commentsId: selectedComment.commentsId,
          // filingId: props?.workflow?.filingId,
          workflowId: props?.rowData?.workflowId || props?.workflow?.workflowId,
          commentsText: approvalComment,
          updateUser: authUser?.userId + "",
          updateDate

        }
        let res = await axios.put(`${API_BASE_URL}//Customer/CustomerFilingWorkflowCommentsUpdate`, payload)
        console.log("editComment res", res)
        if (res) {
          if (getClientFilingMasterWorkflowComments) {
            let id = props?.rowData?.workflowId || props?.workflow?.workflowId

            const _catData = await getClientFilingMasterWorkflowComments(
              id
            );
            setData(() => _catData);
            await fetchCommentsAttachments(_catData);
          }
          setAlertMessage("Successfully updated comment.");
          setShowError(false);
          setShowSuccess(true);
          closeDialog();
          setSelectedComment({})
          setApprovalComment("")
          setActionType("Add")
        }
      } catch (error) {
        console.log("@@Error:", error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleDeleteComment = (item: any) => {
    console.log("Delete  handleDeleteComment", "item", item);
    setActionType("Add")
    setSelectedComment(item)
    showDialog(
      "Confirmation",
      <Typography>
        Are you sure you want to delete this comment?
      </Typography>,
      "confirm"
    );
  }
  const deleteComments = async () => {
    console.log("Delete  deleteComments", "Workflow", props.workflow);
    setLoading(true);

    try {
      const updateDate = new Date();
      let payload = {
        commentsId: selectedComment.commentsId,
        workflowId: props?.rowData?.workflowId || props?.workflow?.workflowId,
        updateUser: authUser?.userId + "",
        updateDate,
        isDeleted: true,
      }

      let res = await axios.delete(`${API_BASE_URL}/Customer/CustomerFilingWorkflowCommentsDelete`, {
        data: payload
      });
      console.log("deleteComments res", res)
      if (res) {
        if (getClientFilingMasterWorkflowComments) {
          let id = props?.rowData?.workflowId || props?.workflow?.workflowId


          const _catData = await getClientFilingMasterWorkflowComments(
            id
          );
          setData(() => _catData);
          await fetchCommentsAttachments(_catData);
        }
        props.refreshList();
        setAlertMessage("Successfully deleted comment.");
        setShowError(false);
        setShowSuccess(true);
        closeDialog();
        setSelectedComment({})
      }
    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      setLoading(false);
    }
  }

  const closeConfirm = () => {
    setApprovalComment("")
  };

  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: deleteComments,
    onClose: closeConfirm,
  });
  const onCommentChange = async (e: React.ChangeEvent<any>) => {
    setApprovalComment((old) => e.target.value);
    if (approvalComment) {
      setShowSuccess(false);
      setShowError(false);
    }
  };

  const handleOk = async (anchor: Anchor) => {
    // navigate("/filing-master-list");
    setIsLoading(true);

    console.log(`Add Comment:${approvalComment}`);
    setShowError(false);
    if (approvalComment.length === 0) {
      setAlertMessage("Please enter a comment.");
      setShowError(true);
      setIsLoading(false);
      setShowSuccess(false);
    } else {
      setShowSuccess(false);
      setShowError(false);
      console.log(`Add Comment:${approvalComment}`);
      try {
        const createDate = new Date();

        const payload = {
          commentsText: approvalComment,
          workflowId: props.rowData?.workflowId || props.workflow?.workflowId,
          createDate,
          createUser: authUser?.userId + "",
        };
        if (addClientFilingMasterWorkflowComment) {
          console.log(`Add Comment payload:${payload}`);
          const res = await addClientFilingMasterWorkflowComment(payload);
          if (res) {
            console.log(`Comment has been added`);
            if (selectedFile) {
              await uploadandUpdateAttachment(res.data.commentsId);
            }
            if (data.length === 0) {
              props.refreshList();

            }

            if (getClientFilingMasterWorkflowComments) {
              const _catData = await getClientFilingMasterWorkflowComments(
                props.rowData?.workflowId || props.workflow?.workflowId
              );
              setData(() => _catData);
              await fetchCommentsAttachments(_catData);
            }
            setState({ ...state, [anchor]: true });
            setAlertMessage("Comment has been added");
            setShowError(false);
            setShowSuccess(true);
            setApprovalComment(() => "");
            setRemainingChars(256);
            setSelectedFile("");
            setOpenAttachment(false);
            setIsLoading(false);
            return;
          }
        }
      } catch (error) {
        console.log(`Error while adding comment`, error);
        setAlertMessage("`Error while adding comment");
        setShowError(true);
        setRemainingChars(256);
        setApprovalComment(() => "");

        props.handleClose();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCancel = async (anchor: Anchor) => {
    setApprovalComment(() => "");
    setShowSuccess(false);
    setShowError(false);
    setRemainingChars(256);
    setData(() => []);

    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  const getAttachmentId = async (commentsId: any) => {
    console.log("getAttachmentIt", props?.workflow);
    console.log("getAttachmentItRowDATA", props?.rowData);
    let payload = {
      commentsId: commentsId,
    };

    const res = await axios.post(
      `${API_BASE_URL}/Customer/CustomerFilingWorkflowCommentsAttachmentsCreate`,
      payload
    );
    console.log("getAttachmentIt res", res.data);
    if (res.data) {
      return res.data.attachmentId;
    }
  };

  const updateFilePath = async (
    commentsId: any,
    attachmentId: any,
    filePath: any
  ) => {
    console.log("updateFilePath", attachmentId, filePath);
    let payload = {
      attachmentId: attachmentId,
      attachmentPath: decodeURI(filePath),
      createDate: new Date(),
      createUser: authUser?.userId + "",
      commentsId: commentsId,
    };

    const res = await axios.put(
      `${API_BASE_URL}/Customer/CustomerFilingWorkflowCommentsAttachmentsupdate/${attachmentId}`,
      payload
    );
    console.log("updateFilePath res", res);
  };

  const onFileUpload = async (commentsId: any) => {
    let attachmentId = await getAttachmentId(commentsId);
    await fileUploadUtil(attachmentId, commentsId);
    return;
  };

  const uploadandUpdateAttachment = async (commentsId: any) => {
    console.log("uploadandUpdateAttachment", commentsId);
    const formData = new FormData();
    formData.append("Files", selectedFile);
    formData.append("commentsId", commentsId);
    formData.append(
      "WorkflowId",
      (props.rowData?.workflowId || props.workflow?.workflowId)?.toString() ??
      ""
    );
    formData.append(
      "CustomerId",
      (props.rowData?.customerId || props.workflow?.customerId)?.toString() ??
      ""
    );
    formData.append(
      "FilingId",
      (props.rowData?.filingId || props.workflow?.filingId)?.toString() ?? "0"
    );
    formData.append("createUser", authUser?.userId + "");
    const res = await axios.post(
      `${API_BASE_URL}/Application/UploadandUpdateAttachment`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("uploadandUpdateAttachment res", res);
    if (res.data) {
    }
  };

  const downloadFile = (
    attachmentPath: any,
    attachmentId: any,
    commentsId: any
  ) => {
    console.log("Downloading File from:", attachmentId);
    const fileName = getFilename(attachmentPath);
    console.log("Downloading File:", fileName);
    let filingId = props.rowData?.filingId || props.workflow?.filingId;
    let workflowId = props.rowData?.workflowId || props.workflow?.workflowId;
    let CustomerId = props.rowData?.customerId || props.workflow?.customerId;
    axios({
      url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&CommentsId=${commentsId}&FilingId=${filingId}&WorkflowId=${workflowId}&CustomerId=${CustomerId}`,
      method: "POST",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  };

  const fileUploadUtil = async (attachmentId: any, commentsId: any) => {
    console.log("fileUploadUtil params", attachmentId, commentsId);

    const formData = new FormData();
    formData.append("Files", selectedFile);
    formData.append("commentId", commentsId);
    formData.append("AttachmentId", attachmentId);
    formData.append(
      "WorkflowId",
      (props.rowData?.workflowId || props.workflow?.workflowId)?.toString() ??
      ""
    );
    formData.append(
      "DraftId",
      (props.rowData?.draftId || props.workflow?.draftId)?.toString() ?? ""
    );
    formData.append(
      "CustomerId",
      (props.rowData?.customerId || props.workflow?.customerId)?.toString() ??
      ""
    );
    formData.append(
      "FilingId",
      (props.rowData?.filingId || props.workflow?.filingId)?.toString() ?? ""
    );

    const res = await axios.post(
      `${API_BASE_URL}/Application/CustomerFilingUpload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("fileUploadUtil res", res);

    if (res.data) {
      console.log("fileUploadUtil res header", res.headers);
      await updateFilePath(commentsId, attachmentId, res.headers["x-file-url"]);
      // setFiles([...files, selectedFile]);
    }
  };

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <ConfirmDialog />
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
            sx={{ zIndex: "11" }}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}


