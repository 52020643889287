import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import ClientLookup from "../../customer/ClientLookup";
import AddFilingTable from "./AddFilingtable";
import ReactLoading from "react-loading";
import DeferredToolbox from "./DeferredToolbox";
import DeferredHistory from "./DeferredHistory";
import { Dialog } from "primereact/dialog";
import { Button as PrimeButton } from "primereact/button";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function AddFilingList(props) {
  const [customerId, setCustomerId] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [selectedMasters, setSelectedMasters] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  let { clientId } = useParams();

  const getCustomerId = (id) => {
    setCustomerId(id);
    setSelectedMasters(null)
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const fetchClientTrackingHistory = async (filingId) => {
    try {
      const history = await axios.get(
        `${API_BASE_URL}/Customer/DeferredFilingAudit?CustomerId=${customerId}&FilingId=${filingId}`
      );
      setActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const displayHistory = async () => {
    console.log("displayHistory", selectedMasters);
    // if (selectedMasters && selectedMasters.length > 1) {
    //   setAlertMessage("Please Select single Filing Tracker Record");
    //   setShowAlert(true);
    // } else 
    if(selectedMasters?.filingId) {
      fetchClientTrackingHistory(selectedMasters.filingId);
      setShowHistory(() => true);
    } else {
      setAlertMessage("Please Select a Filing Tracker Record");
      setShowAlert(true);
    }
  }

  const closeHistory = () => {
    setShowHistory(() => false);
  };
  
  React.useEffect(() => {
    console.log("props Test", props);
    setIsLoading(false);
  }, [props]);
  return (
    <Fragment>
      {isLoading ? (
        <ReactLoading
          style={{
            position: "absolute",
            width: "3rem",
            marginLeft: "36rem",
            marginTop: "10rem",
            color: "#0000FF",
          }}
          type="spin"
          color="blue"
          height={80}
        />
      ) : (
        <Fragment>
        <Grid style={{ height: "100%", width: "93%" }}>
          <Grid sm={24}>
            {/* <div className='card' style={{ height: "10vh", maxWidth: "100vw" }}> */}
            {!loadingFlag && (
              <ClientLookup
                setSelectedClientId={getCustomerId}
                parentRoute="add-filings"
              />
            )}
            {/* </div> */}
          </Grid>
          <Grid sm={24}>
            <div
              className="card"
              style={{
                width: "89vw",
                padding: "1rem",
                margin: "0 1rem",
                background: "#FFF",
                // borderRadius: "2%",
              }}
            >
              <AddFilingTable
                selectedClientId={customerId}
                setLoadingFlag={setLoadingFlag}
                changeSelectedMasters={(val) =>setSelectedMasters(val)}
              />
            </div>
          </Grid>
        </Grid>
        
          <Grid
            sx={{
              height: "100%",
              width: "5%",
            }}
          >
            <div
              style={{
                height: "100%",
                background: "#F1F2F6",
                marginTop: "1rem",
                borderRadius: "2%",
              }}
            >
              <Item
                sx={{
                  height: "100%",
                }}
              >
                <DeferredToolbox
                  isLoading={isLoading}
                  // draft={selectedmasters?.customer[0]?.customerId}
                  displayHistory={displayHistory}
                />
              </Item>
            </div>
          </Grid>

          <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
        </Fragment>
      )}
      {
        <DeferredHistory
          show={showHistory}
          dockAt="right"
          tracker={selectedMasters?.filingId && selectedMasters}
          activityHistory={activityHistory}
          handleClose={closeHistory}
        />
      }
    </Fragment>
  );
}

export default AddFilingList;
