import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ViewListIcon from "@mui/icons-material/ViewList";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ToolTip from "../common/ToolTip";
import usePermission from "../../usePermission";
import AttachFileIcon from "@mui/icons-material/AttachFile";

export default function Toolbox(props: {
  displayComments: () => void;
  displayClientFilingTracking: () => void;
  displayAddClientFiling: () => void;
  displayAttachments: () => void;
}) {
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);

  const showCusotmerForm = () => {
    navigate("/customer");
  };

  const displayClientFilingTracking = () => {
    props.displayClientFilingTracking();
  };

  const displayAddClientFiling = () => {
    props.displayAddClientFiling();
  };

  const displayComments = () => {
    props.displayComments();
  };

  const displayAttachments = () => {
    props.displayAttachments();
  };

  const closeAlert = () => {
    setShowAlert(() => false);
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <nav aria-label="main mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <FilterListIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayAddClientFiling}
                disabled={!usePermission("ClientFilingList", "addFiling")}
              >
                <ListItemIcon>
                  <ToolTip title="Add Deferred Filings">
                    <PersonAddAlt1Icon />
                  </ToolTip>
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayClientFilingTracking}
                disabled={
                  !usePermission("ClientFilingList", "viewFilingTracking")
                }
              >
                <ToolTip title="View Filing Tracking">
                  <ViewListIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayComments}
                disabled={!usePermission("ClientFilingList", "comments")}
              >
                <ToolTip title="Comments">
                  <CommentIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>{" "}
            <ListItem disablePadding>
              <ListItemButton onClick={displayAttachments}>
                <ToolTip title="Attachment">
                  <AttachFileIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </React.Fragment>
  );
}
