import { useContext, useEffect } from "react";
import axios from "axios";
import AuthContext from "./context/auth/AuthContext";

const Authentication = ({ children }) => {
  const authContext = useContext(AuthContext);
  const { token } = authContext;

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }, [token]);

  return children;
};

export default Authentication;
