import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

const ApproverTable = ({
  isMaster,
  tableData,
  isDefaultChange,
  handleDelete,
  setSelectedRow,
  selectedRow,
  setSelectedRecord
}) => {
  const [rows, setRows] = useState([]);
  const [approverSelected, setApproverSelected] = useState([])

  function createData(name, states) {
    // console.log(`ApproverTable--> createData`, name, states);
    return {
      name,
      history: states,
    };
  }

  function CollapseTableData(props) {
    console.log(`ApproverTable-->`, props.data);
    return (
      <Fragment>
        {Object.entries(props.data).map(([key, value]) =>
          value.map((ele) => (
            <TableRow key={ele.id}>
              <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={approverSelected.includes(ele.id)}
                        onClick={() => selectRecord(ele.id)}
                      />
               </TableCell>
              <TableCell>{ele.juristiction}</TableCell>
              <TableCell>{ele.state}</TableCell>
              <TableCell>
                <Checkbox
                  checked={ele.isdefault}
                  disabled={ele.isdefault}
                  onChange={(e) =>
                    isDefaultChange(
                      e,
                      ele.client,
                      ele.state,
                      ele.approverId,
                      ele.customerId
                    )
                  }
                />
              </TableCell>
              <TableCell>{ele.approverName}</TableCell>
              <TableCell>
                <IconButton>
                  <DeleteIcon
                    onClick={() => {
                      handleDelete(
                        ele.client,
                        ele.state,
                        ele.id,
                        ele.isdefault,
                        ele.approverName
                      );
                    }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        )}
      </Fragment>
    );
  }

  const selectRecord = (id) => {
    let newSelectedList
    if(approverSelected.includes(id)){
      newSelectedList = approverSelected.filter(item => item !== id)
    }else{
      newSelectedList = [...approverSelected , id]
    }
    console.log("setSelectedRecord",newSelectedList)
    setSelectedRecord(newSelectedList)
    setApproverSelected(newSelectedList)
  }

  function CollapseMasterTableData(props) {
    console.log(`ApproverTable-->`, props.data);
    return (
      <Fragment>
        {props.data.map((ele) => (
          <TableRow key={ele.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={approverSelected.includes(ele.id)}
                  onClick={() => selectRecord(ele.id)}
                        // inputProps={{
                        //   'aria-labelledby': labelId,
                        // }}
                />
              </TableCell>
            <TableCell>{ele.juristiction}</TableCell>
            <TableCell>{ele.state}</TableCell>
            <TableCell>
              <Checkbox
                checked={ele.isdefault}
                onChange={(e) => isDefaultChange(e, ele.state, ele.approverId)}
              />
            </TableCell>
            <TableCell>{ele.approverName}</TableCell>
            <TableCell>
              <IconButton>
                <DeleteIcon
                  onClick={() => {
                    handleDelete(
                      ele.state,
                      ele.id,
                      ele.isdefault,
                      ele.approverName
                    );
                  }}
                />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Fragment>
    );
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(
      selectedRow === row.name ? true : false
    );

    const selectRow = () => {
      console.log("selectRow", row);
      if (open === false) {
        setSelectedRow(row.name);
        setSelectedRecord([])
        setApproverSelected([])
      }
      setOpen(!open);
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => selectRow()}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              style={{ width: "100%" }}
            >
              <Box sx={{ margin: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Jurisdiction
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        State
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Default
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Approver Names
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isMaster ? (
                      <CollapseMasterTableData data={row.history} />
                    ) : (
                      <CollapseTableData data={row.history} />
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  useEffect(() => {
    let tableDataRows = [];
    console.log(`ApproverTable-->`, tableData);
    for (const client in tableData) {
      console.log(`ApproverTable--> ${client}: ${tableData[client]}`);
      tableDataRows.push(createData(client, tableData[client]));
    }
    setRows(tableDataRows);
  }, [tableData]);

  return (
    <Fragment>
      <TableContainer component={Paper} sx={{ maxHeight: "90vh" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ fontWeight: "bold" }} align="left">
                {isMaster ? "State/Jurisdiction" : "Client Name"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog> */}
    </Fragment>
  );
};

export default ApproverTable;
