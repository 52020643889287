import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ViewListIcon from "@mui/icons-material/ViewList";
import FilterListIcon from "@mui/icons-material/FilterList";
import PostAddIcon from "@mui/icons-material/PostAdd";
import HistoryIcon from "@mui/icons-material/History";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import ToolTip from "../../common/ToolTip";
import usePermission from "../../../usePermission"
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import DownloadIcon from "@mui/icons-material/Download";

export default function DeferredToolbox(props) {
  const navigate = useNavigate();

  const showClientProfileList = () => {
    navigate("/");
  };

  const displayClientFiling = () => {
    navigate("/client-filing-tracker-list");
  };

  const displayHistory = () => {
    props.displayHistory();
  };

  return (
    <React.Fragment>

      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <nav aria-label="main mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton disabled={props.isLoading}>
                <ListItemIcon>
                  <FilterListIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={showClientProfileList}
                disabled={ props.isLoading                }
              >
                <ToolTip title="Client Profile List">
                  <PostAddIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
          <ListItem disablePadding>
              <ListItemButton
                disabled={ props.isLoading                }
                onClick={displayClientFiling}
              >
                <ToolTip title="Client Tracker List">
                  <ViewListIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayHistory}
                disabled={ props.isLoading}
              >
                <ToolTip title="Activity History">
                  <HistoryIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </React.Fragment>
  );
}
