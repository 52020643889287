import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import ThemeProvider from "@mui/system/ThemeProvider";
import Grid from "@mui/material/Unstable_Grid2";
// import NavBar from "./NavBar";
import NavBar from "../components/layout/NavBar";
import theme from "../theme/theme";

type Props = {
  children?: React.ReactNode;
};

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='stretch'
          sx={{
            height: "100%",
            background: "#F1F2F6",
          }}
        >
          <Grid>
            <NavBar />
          </Grid>
          {children}
        </Grid>
      </ThemeProvider>
    </Fragment>
  );
};
