import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ViewListIcon from "@mui/icons-material/ViewList";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ToolTip from "./ToolTip";
import usePermission from "../../usePermission";
import HistoryIcon from "@mui/icons-material/History";
import { Typography } from "@mui/material";

export default function Toolbox(props: {
  isLoading: boolean;
  displayFilter: () => void;
  editClient: () => void;
  deleteClient: () => void;
  displayComments: () => void;
  displayClientFiling: () => void;
  displayClientCategories: () => void;
  displayAddClientFiling: () => void;
  displayHistory: () => void;
  selectedClients: any;
  postAward: any;
}) {
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = React.useState<string | JSX.Element>("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alert, setAlert] = React.useState("");
  const [showAlertMessage, setShowAlertMessage] = React.useState(false);
  const setDisplayFilter = () => {
    props.displayFilter();
  };

  const showCusotmerForm = () => {
    navigate("/customer");
  };

  const displayClientFiling = () => {
    props.displayClientFiling();
  };

  const displayAddClientFiling = () => {
    props.displayAddClientFiling();
  };

  const editClient = () => {
    props.editClient();
  };

  const deleteClient = () => {
    closeAlert();
    props.deleteClient();
  };

  const deleteClientPopup = () => {
    let usernameForComments = sessionStorage.getItem("usernameForComments");
    console.log("usernameForComments", usernameForComments);
    // props.deleteClient();
    if (usernameForComments) {
      setAlertMessage(
        <Typography>
        Are you sure you want to delete <strong>{usernameForComments}</strong>?
        </Typography>
      );
      setShowAlert(true);
    } else {
      setAlert("Please Select a Client Record");
      setShowAlertMessage(true);
    }
  };

  const displayComments = () => {
    props.displayComments();
  };

  const displayClientCategories = () => {
    props.displayClientCategories();
  };

  const displayHistory = () => {
    props.displayHistory();
  };

  const footerContent = (
    <div>
      <PrimeButton
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => closeAlert()}
      />
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => deleteClient()}
        autoFocus
      />
    </div>
  );

  const footerContent1 = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert1()}
        autoFocus
      />
    </div>
  );

  const closeAlert = () => {
    setShowAlert(() => false);
  };
  const closeAlert1 = () => {
    setShowAlertMessage(() => false);
  };
  return (
    <React.Fragment>
      <Dialog
        header="DELETE CLIENT"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Dialog
        header="DELETE CLIENT"
        visible={showAlertMessage}
        style={{ width: "30vw" }}
        onHide={() => closeAlert1()}
        footer={footerContent1}
      >
        <p className="m-0">{alert}</p>
      </Dialog>

      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <nav aria-label="main mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton disabled={props.isLoading}>
                <ListItemIcon>
                  <FilterListIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                disabled={
                  !usePermission(
                    "ClientProfile",
                    "addClient",
                    props.selectedClients
                  ) || props.isLoading
                }
                onClick={showCusotmerForm}
              >
                <ListItemIcon>
                  <ToolTip title="Add Client">
                    <PersonAddAlt1Icon />
                  </ToolTip>
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayAddClientFiling}
                disabled={
                  !usePermission(
                    "ClientProfile",
                    "addFiling",
                    props.selectedClients
                  ) || props.isLoading
                }
              >
                <ToolTip title="Deferred Filings">
                  <PostAddIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton
                disabled={
                  !usePermission("ClientProfile", "viewFiling") ||
                  props.isLoading
                }
                onClick={displayClientFiling}
              >
                <ToolTip title='View Filings'>
                  <ViewListIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton
                disabled={
                  !usePermission(
                    "ClientProfile",
                    "tracknowClientFiling",
                    props.selectedClients
                  ) || props.isLoading
                }
                onClick={displayClientFiling}
              >
                <ToolTip title="TrackNow Client Filings">
                  <ViewListIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                disabled={
                  !usePermission(
                    "ClientProfile",
                    "comments",
                    props.selectedClients
                  ) || props.isLoading
                }
                onClick={displayComments}
              >
                <ToolTip title="Comments">
                  <CommentIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                disabled={
                  !usePermission(
                    "ClientProfile",
                    "editClient",
                    props.selectedClients
                  ) || props.isLoading
                }
                onClick={editClient}
              >
                <ToolTip title="Edit Client Profile">
                  <EditIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                disabled={
                  !usePermission(
                    "ClientProfile",
                    "deleteClient",
                    props.selectedClients
                  ) || props.isLoading
                }
                onClick={deleteClientPopup}
              >
                <ToolTip title="Delete Client">
                  <DeleteIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>{" "}
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayHistory}
                disabled={
                  !usePermission(
                    "ClientProfile",
                    "deleteClient",
                    props.selectedClients
                  ) || props.isLoading
                }
              >
                <ToolTip title="Activity History">
                  <HistoryIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                disabled={
                  !usePermission(
                    "ClientProfile",
                    "viewBusinessClient",
                    props.selectedClients
                  ) || props.isLoading || props.postAward
                }
                onClick={displayClientCategories}
              >
                <ToolTip title="Business Categories">
                  <DomainAddIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </React.Fragment>
  );
}
