import React, { Fragment, useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ThemeProvider from "@mui/system/ThemeProvider";
import Grid from "@mui/material/Unstable_Grid2";
import NavBar from "./components/layout/NavBar";
import theme from "./theme/theme";
import "./App.scss";
import ClientProfiles from "./components/customer/ClientProfiles";
import CustomerForm from "./components/customer/CustomerForm";
import Loader from "./components/common/Loader";
import FilingMasterForm from "./components/filing-master/FilingMasterForm";
import FilingMasterList from "./components/filing-master/FilingMasterList";
import PostAwardFilingMasterList from "./components/PostAwards/PostAwardFilingMaster/PostAwardFilingMasterList";
import ApprovalList from "./components/filing-master/ApprovalList";
import ClientFilingMasterList from "./components/client-filing-master/ClientFilingMasterList";
import BusinessCategory from "./components/category/BusinessCategory";
import ProgramNames from "./components/PostAwards/ProgramNames";
import ApprovalConfig from "./components/customer/ApprovalConfig";
import PostAwardApproverConfiguration from "./components/PostAwards/PostAwardApproverConfiguration";
import PostAwardApprovalList from "./components/PostAwards/PostAwardApprovalList";
import PostAwardMasterForm from "./components/PostAwards/post-award-form/PostAwardMasterForm";
import AddFilingList from "./components/dev/addfiling/AddFilinglist";
import AuthContext from "./context/auth/AuthContext";
import ClientFilingMasterApprovalList from "./components/client-filing-master/ClientFilingMasterApprovalList";
import PostAwardClientFilingMasterApprovalList from "./components/PostAwards/PostAwardClientFilingMasterApprovall/PostAwardClientFilingMasterApprovalList";
import ClientFilingTrackerList from "./components/client-filing-tracker/ClientFilingTrackerList";
import { PageLayout } from "./ui-components/PageLayout";
import Reminder from "./components/client-filing-master/Reminder";
import Notification from "./components/dev/Notification";
import RollOverFilings from "./components/dev/RollOverFilings";
import PostAwardRollOverFilings from "./components/dev/PostAwardRollOverFilings";
import UserRoleSetup from "./components/client-filing-master/UserRoleSetup";
import ClientFilingMasterReferenceDocuments from "./components/client-filing-master/ClientFilingMasterReferenceDocuments";
import FilingMasterReferenceDocument from "./components/filing-master/FilingMasterReferenceDocument";
import FilingMasterApprovalHistory from "./components/filing-master/FilingMasterApprovalHistory";
import usePermission from "./usePermission";
import UnAuthorized from "./components/common/UnAuthorized";
import PageNotFound from "./components/common/PageNotFound";
import NotificationConfiguration from "./components/client-filing-master/NotificationConfiguration";
import AddFilingPostAwardlist from "./components/PostAward/AddFilingPostAwardlist";
function App() {
  const authContext = useContext(AuthContext);
  const { loadUser, portalUser, isPortalAuthenticated, isAuthenticated } =
    authContext;

  const [userChecked, setUserChecked] = useState(false);

  const fetchUserInfo = async () => {
    try {
      if (loadUser) await loadUser();
      // console.log("USER INFO LOADED", tmpUser);
      setUserChecked(true);
      // setAppUser(tmpUser);
    } catch (err) {
      // console.log("USER INFO NOT AVAILABLE");
      setUserChecked(false);
    }
  };

  useEffect(() => {
    if (!portalUser) {
      fetchUserInfo();
    }
  }, []);

  useEffect(() => {
    if (portalUser) {
      // console.log("USER LOADED", portalUser);
    }
  }, [portalUser]);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     console.log("User has access");
  //   } else {
  //     console.log("User does not have access");
  //   }

  //   return () => {
  //     setAuthorized && setAuthorized(false);
  //   };
  // }, [isAuthenticated]);

  return (
    <PageLayout>
      <Grid container justifyContent="center">
        <Pages isAuthorized={isPortalAuthenticated && isAuthenticated} />
      </Grid>
    </PageLayout>
  );
}
const IsDisabled = (menuName: any) => {
  // console.log("manimenu", menuName);

  return !usePermission(menuName, "viewPage");
};
function Pages(props: any) {
  const authContext = useContext(AuthContext);
  const { roleType } =
    authContext;
  const isAdmin = Array.isArray(roleType) ? roleType.includes("Admin") : false;
  const isAdminPostaward = Array.isArray(roleType) ? roleType.includes("Admin-PostAward") : false;
  const [isAddClientFiling, setIsAddClientFiling] = useState(false)
  // console.log("Permission-check", "ADMIN", isAdmin, "isAdminPostaward", isAdminPostaward);
  const handleAddClientSpecificFiling = (value: boolean) => {
    setIsAddClientFiling(value)
  };

  return (
    <Fragment>
      {props.isAuthorized && (
        <Grid
          container
          spacing={0}
          columns={24}
          sx={{
            height: "100%",
            width: "99%",
            background: "#F1F2F6",
          }}
        >
          <Routes>
            <Route
              path="/customer"
              element={
                IsDisabled("AddNewClient") ? <UnAuthorized /> : <CustomerForm />
                      
              }
            />
            <Route
              path="/filing-master-list"
              element={
                IsDisabled("MasterFilingList") ? (
                  <UnAuthorized />
                ) : (
                  <FilingMasterList />
                )
              }
            />
            <Route
              path="/postaward-filing-master-list"
              element={<PostAwardFilingMasterList />}
            />
            <Route
              path="/postaward-approval-list"
              element={<PostAwardApprovalList />}
            />
            <Route
              path="/postaward-master-form"
              element={<PostAwardMasterForm />}
            />
            <Route
              path="/filing-master"
              element={
                IsDisabled(isAddClientFiling ? "AddClientFiling" : "AddFilingMaster") ? (
                  <UnAuthorized />
                ) : (
                  <FilingMasterForm />
                )
              }
            />
            <Route
              path="/filing-master-approval-list"
              element={
                IsDisabled("FilingMasterApproval") ? (
                  <UnAuthorized />
                ) : (
                  <ApprovalList />
                )
              }
            />
            <Route
              path="/client-filing-master-approval-list"
              element={
                IsDisabled("ClientFilingMasterApproval") ? (
                  <UnAuthorized />
                ) : (
                  <ClientFilingMasterApprovalList />
                )
              }
            />
            <Route
              path="/PostAwardClientfilingMasterApprovalList"
              element={

                <PostAwardClientFilingMasterApprovalList />

              }
            />
            <Route
              path="/client-filing-master-list/:clientId?"
              element={
                IsDisabled("ClientFilingMasterApproval") ? (
                  <UnAuthorized />
                ) : (
                  <ClientFilingMasterList />
                )
              }
            />
            <Route
              path="/client-filing-tracker-list/:clientId?"
              element={
                IsDisabled("ViewClientFilingTracker") ? (
                  <UnAuthorized />
                ) : (
                  <ClientFilingTrackerList onAddClientSpecificFilingClick={(value: boolean) => handleAddClientSpecificFiling(value)} />)
              }
            />
            <Route
              path="/business-category"
              element={
                IsDisabled("BusinessCategory") ? (
                  <UnAuthorized />
                ) : (
                  <BusinessCategory />
                )
              }
            />
            <Route path="/ProgramNames" element={<ProgramNames />} />
            <Route
              path="/add-filings/:clientId?"
              element={
                IsDisabled("AddClientFiling") ? (
                  <UnAuthorized />
                ) : (
                  <AddFilingList />
                )
              }
            />
            <Route
              path="/add-filing-postaward/:clientId?"
              element={
                IsDisabled("PostAwardDefferedList") ? (
                  <UnAuthorized />
                ) : (
                  <AddFilingPostAwardlist />
                )
              }
            />
            <Route
              path="/ApprovalConfig"
              element={
                IsDisabled("ApprovalConfiguration") ? (
                  <UnAuthorized />
                ) : (
                  <ApprovalConfig />
                )
              }
            />
            <Route
              path="/PostAwardApproverConfiguration"
              element={<PostAwardApproverConfiguration />}
            />
            <Route path="/notification" element={<Notification />} />
            <Route
              path="/ClientFilingMasterReferenceDocuments"
              element={
                IsDisabled("ClientFilingMasterApproval") ? (
                  <UnAuthorized />
                ) : (
                  <ClientFilingMasterReferenceDocuments />
                )
              }
            />
            <Route
              path="/FilingMasterReferenceDocument"
              element={
                IsDisabled("FilingMasterApproval") ? (
                  <UnAuthorized />
                ) : (
                  <FilingMasterReferenceDocument />
                )
              }
            />
            <Route
              path="/FilingMasterApprovalHistory"
              element={
                IsDisabled("FilingMasterApproval") ? (
                  <UnAuthorized />
                ) : (
                  <FilingMasterApprovalHistory />
                )
              }
            />
            <Route
              path="/reminder"
              element={IsDisabled("setting") ? <UnAuthorized /> : <Reminder />}
            />{" "}
            <Route
              path="/notificationConfiguration"
              element={
                IsDisabled("setting") ? (
                  <UnAuthorized />
                ) : (
                  <NotificationConfiguration />
                )
              }
            />{" "}
            <Route
              path="/reminder"
              element={IsDisabled("setting") ? <UnAuthorized /> : <Reminder />}
            />{" "}
            <Route
              path="/user-role-setup"
              element={
                IsDisabled("userRole") ? <UnAuthorized /> : <UserRoleSetup />
              }
            />
            <Route
              path="/rollOverFilings"
              element={
                isAdmin || isAdminPostaward ? <RollOverFilings /> : <UnAuthorized />
              }
            />
            {/* <Route
              path="/postawardrollOverFilings"
              element={
                IsDisabled("rollOver") ? <UnAuthorized /> : <PostAwardRollOverFilings />
              }
            /> */}
            <Route
              path="/"
              element={
                IsDisabled("ClientProfile") ? (
                  <UnAuthorized />
                ) : (
                  <ClientProfiles />
                )
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Grid>
      )}
    </Fragment>
  );
}

export default App;
