import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function UploadedFilesTable(props: {
  files: Object[];
  handleFileAttachmentDelete: (attachmentId: any, attachmentPath: any) => void;
}) {
  console.log("UploadedFilesTable", props.files);
  return (
    <TableContainer>
      <Table style={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <strong>Uploaded Files</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.files.map((file: any, index: number) => (
            <TableRow key={index}>
              <TableCell>{file.name}</TableCell>
              {/* <TableCell>
                <Checkbox checked readOnly />
              </TableCell> */}
              <TableCell align="right">
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.handleFileAttachmentDelete(
                      file.attachmentId,
                      file.attachmentPath
                    );
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
