import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Input from "@mui/material/Input";
import { Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ShortTextIcon from "@mui/icons-material/ShortText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Anchor, Style } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AuthContext from "../../context/auth/AuthContext";
import CustomerContext from "../../context/customer/CustomerContext";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
// import { parseISO, format, utcToZonedTime } from "date-fns";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownload from "js-file-download";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useDialog from "../common/UseDialog";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Customer, CustomerComment } from "../../types/customer";
import { DateTime } from "luxon";
import axios from "axios";

type Anchor = "top" | "left" | "bottom" | "right";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function CustomerComments(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  cust?: Customer;
  comments?: CustomerComment[];
  refreshTable: () => void;
}) {
  const authContext = useContext(AuthContext);
  const { authUser, userRoles, toLocalDateTime, roleType } = authContext;
  const customerContext = useContext(CustomerContext);
  const { getCustomerComments, addCustomerComment } = customerContext;
  const [data, setData] = React.useState<CustomerComment[]>([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [approvalComment, setApprovalComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [remainingChars, setRemainingChars] = useState(256);
  const [openAttachment, setOpenAttachment] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedComment, setSelectedComment] = useState<any>({});
  const [actionType, setActionType] = useState<any>("Add")

  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
    // navigate("/");
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  // const getLocalTime = (time: string): string => {
  //   const parsedTime = parseISO(time);
  //   console.log(parsedTime); // 2019-10-25T08:10:00.000Z

  //   const formatInTimeZone = (date, fmt, tz) =>
  //     format(utcToZonedTime(date, tz), fmt, { timeZone: tz });

  //   const formattedTime = formatInTimeZone(
  //     parsedTime,
  //     "yyyy-MM-dd kk:mm:ss xxx",
  //     "UTC"
  //   );
  //   return formattedTime;
  // };
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        console.log(`Drawer Mouse Key Event...`);
        // if (
        //   event.type === "keydown" &&
        //   ((event as React.KeyboardEvent).key === "Tab" ||
        //     (event as React.KeyboardEvent).key === "Shift")
        // ) {
        //   return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };

  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) {
        setState({ ...state, [props.dockAt]: props.show });
        setActionType("Add")
      }
    }
    setApprovalComment((old) => "");

    if (getCustomerComments && props.cust && props.cust.customerId) {
      console.log(`Fetching Commnet for :${props.cust.customerId}`);
      fetchCustomerComments(props.cust.customerId);
    }
    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    const _data: CustomerComment[] = props.comments!;
    if (props.comments) {
      setData(() => _data);
    }

    //eslint-disable-next-line
  }, [props.comments]);

  useEffect(() => {
    // if(props.cust && props.cust.createDate!==null)
    //eslint-disable-next-line
  }, [props.cust]);

  const toLocalTime = (date: Date): string => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");
      console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };
  const onFileChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const fetchCustomerComments = async (customerId: number) => {
    setLoading(true);
    console.log("@@Fetch Customers1:", getCustomerComments);
    if (getCustomerComments) {
      console.log("@@Fetch Customers2:");
      try {
        const commentList = await getCustomerComments(customerId);
        setData(() => commentList);
        console.log("@@Fetch Customers2 comments:", commentList);
      } catch (error) {
        console.log("@@Error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log(`Fetching Commnet for 0:${props.cust}`);
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UED: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      // toggleDrawer(props.dockAt, props.show);
      setState({ ...state, [props.dockAt]: props.show });
    }
    setApprovalComment((old) => "");

    if (getCustomerComments && props.cust && props.cust.customerId) {
      console.log(`Fetching Commnet for :${props.cust.customerId}`);
      fetchCustomerComments(props.cust.customerId);
    }
    //eslint-disable-next-line
  }, []);
  const getFilename = function (str: any) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };
  const getFilenameToolip = function (str: any) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };
  const getUserName = (userId: string) => {
    console.log("USER ROLES LKP:", userRoles);
    console.log("USER ROLES LKP2 :", userId);

    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]?.userName
      : "";
    return user;
  };

  const list = (anchor: Anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "medium",
                        fontSize: "16px",
                        color: "#424242",
                      }}
                    >
                      COMMENTS
                    </Typography>
                    <IconButton>
                      <CloseIcon onClick={() => handleCancel(anchor)} />
                    </IconButton>
                  </Stack>
                </div>
                <div>
                  <Box sx={{ mx: 3 }}>
                    <TextField
                      id="notes"
                      name="notes"
                      label={
                        <span style={{ font: "italic normal normal" }}>
                          Add Comments
                        </span>
                      }
                      multiline
                      fullWidth
                      minRows={2}
                      variant="filled"
                      placeholder={"Enter Comments..."}
                      value={approvalComment}
                      onChange={(e) => {
                        if (e.target.value.length <= 256) {
                          onCommentChange(e);
                          setRemainingChars(256 - e.target.value.length);
                        }
                      }}
                    />

                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "small",
                        color: remainingChars === 0 ? "red" : "inherit",
                        display: remainingChars === 256 ? "none" : "block",
                      }}
                    >
                      {remainingChars} Characters are left
                    </Typography>
                  </Box>
                </div>
                <div>
                  <MuiButton
                    variant="text"
                    onClick={() => setOpenAttachment(!openAttachment)}
                    disabled={actionType === "Edit"}
                  >
                    <AttachFileIcon /> ATTACHMENT
                  </MuiButton>
                  {actionType === "Edit" && selectedComment
                    && selectedComment.attachmentPath && selectedComment.attachmentPath !== "undefined" && (
                      <MuiButton
                        variant="text"
                        onClick={() =>
                          downloadFile(
                            selectedComment.attachmentPath,
                            selectedComment.attachmentId,
                            selectedComment.commentsId
                          )
                        }
                      >
                        {selectedComment.attachmentPath && <AttachFileIcon />}
                        <Tooltip
                          title={getFilenameToolip(selectedComment.attachmentPath)}
                        >
                          <ListItemText
                            primary={
                              selectedComment &&
                              selectedComment.attachmentPath &&
                              getFilename(selectedComment.attachmentPath)
                            }
                          />
                        </Tooltip>
                      </MuiButton>
                    )}
                  <Collapse orientation="horizontal" in={openAttachment}>
                    <form
                      method="post"
                      id="uploadForm"
                      onChange={(event) => onFileChange(event)}
                      style={{ margin: "1rem" }}
                      ref={formRef}
                    >
                      <label className="file-upload">
                        <Input
                          type="file"
                          name="file"
                          id="inFile"
                          color="primary"
                          style={{ cursor: "pointer" }}
                        />
                      </label>
                    </form>
                  </Collapse>
                </div>
                <div>
                  <CardActions
                    sx={{
                      // mt: "3rem",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      // sx={{ margin: 1, padding: 1 }}
                      onClick={() => handleCancel(anchor)}
                    >
                      Cancel
                    </MuiButton>
                    {actionType === "Add" ?
                      <MuiButton
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: "#0b3edf" }}
                        // sx={{ margin: 1, padding: 1 }}
                        onClick={() => handleOk(anchor)}
                      >
                        Add
                      </MuiButton>
                      : <MuiButton
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: "#0b3edf" }}
                        disabled={selectedComment.commentsText.trim() === approvalComment.trim()}
                        // sx={{ margin: 1, padding: 1 }}
                        onClick={() => editComment()}
                      >
                        Edit
                      </MuiButton>}
                  </CardActions>
                </div>
              </Stack>

              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? <CircularProgress size={40} /> : null}
                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <Divider />

              <List>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100px",
                    }}
                  >
                    <CircularProgress size={40} />
                  </div>
                ) : data && data.length === 0 ? (
                  <Typography variant="body1" color="textSecondary">
                    No comments
                  </Typography>
                ) : (
                  data &&
                  data
                    .slice()
                   // .reverse()
                    .map((item, index: number) => (
                      <Fragment key={index}>
                        <Stack direction="row" justifyContent="space-between">
                          <ListItem
                            disablePadding
                            style={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            {item.updateUser
                              ? getUserName(item.updateUser)
                              : item.createUser && getUserName(item.createUser)}                          </ListItem>
                          <ListItem sx={{ textAlign: "right" }}>
                            <ListItemText
                              secondary={
                                item &&
                                  item.updateDate
                                  ? toLocalDateTime && `${toLocalDateTime(item.updateDate)}`
                                  : toLocalDateTime && `${toLocalDateTime(item.createDate)}`
                              }
                            />
                          </ListItem>
                        </Stack>

                        <ListItem
                          disablePadding
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <ListItemText
                              sx={{
                                '&:hover': {
                                  backgroundColor: '#f0f0f0',
                                  cursor: "text"
                                },
                                width: "100%",
                              }}
                              primary={
                                item &&
                                  item.commentsText &&
                                  item.commentsText !== null
                                  ? item.commentsText
                                  : ""
                              }
                            />
                            <IconButton
                              color='primary'
                              disabled={DisableEdit(item)}
                            >
                              <EditIcon
                                onClick={() => handleEditComment(item)}
                                sx={{ cursor: "pointer" }}
                              />
                            </IconButton>
                            <IconButton
                              color='primary'
                              disabled={DisableEdit(item)}
                            >
                              <DeleteIcon
                                onClick={() => handleDeleteComment(item)}
                                sx={{ cursor: "pointer", fontWeight: "400", }}
                              />
                            </IconButton>
                          </div>
                          <MuiButton
                            variant="text"
                            onClick={() =>
                              downloadFile(
                                item.attachmentPath,
                                item.attachmentId,
                                item.commentsId
                              )
                            }
                          >
                            {
                              //@ts-ignore
                              item.attachmentPath && <AttachFileIcon />
                            }
                            <Tooltip
                              title={getFilenameToolip(item.attachmentPath)}
                            >
                              <ListItemText
                                primary={
                                  item &&
                                  //@ts-ignore
                                  item.attachmentPath &&
                                  getFilename(item.attachmentPath)
                                }
                              />
                            </Tooltip>
                          </MuiButton>
                        </ListItem>
                      </Fragment>
                    ))
                )}
              </List>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <style>
        {`.css-1x51dt5-MuiInputBase-input-MuiInput-input{
        cursor : pointer;
        {

      `}
      </style>
    </Fragment>
  );
  const DisableEdit = (item: any) => {
    if (roleType.includes('Admin')) {
      return false
    } else if (authUser?.userId && authUser?.userId === parseInt(item.createUser)) {
      return false
    } else {
      return true
    }
  }
  const handleEditComment = (item: any) => {
    console.log("handleEditComment", item)
    setActionType("Edit")
    setSelectedComment(item)
    setApprovalComment(item.commentsText)
  }
  const editComment = async () => {
    console.log("editComment", selectedComment, props)
    setLoading(true);
    if (props.cust && props.cust.customerId) {
      try {
        const updateDate = new Date();
        let payload = {
          commentsId: selectedComment.commentsId,
          customerId: props.cust.customerId,
          // filingId: props.draft.filingId,
          commentsText: approvalComment,
          updateUser: authUser?.userId + "",
          updateDate
        }
        let res = await axios.put(`${API_BASE_URL}//Customer/UpdateCustomerComments`, payload)
        console.log("editComment res", res)
        if (res) {
          if (getCustomerComments) {
            const _catData = await getCustomerComments(
              props.cust?.customerId
            );
            setData(() => _catData);
            console.log("editComment", "uploadandUpdateAttachment", "_catData", _catData);

           // await uploadandUpdateAttachment(_catData);
          }
          setAlertMessage("Successfully updated comment.");
          setShowError(false);
          setShowSuccess(true);
          closeDialog();
          setSelectedComment({})
          setApprovalComment("")
          setActionType("Add")
        }
      } catch (error) {
        console.log("@@Error:", error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleDeleteComment = (item: any) => {
    setActionType("Add")
    setSelectedComment(item)
    showDialog(
      "Confirmation",
      <Typography>
        Are you sure you want to delete this comment?
      </Typography>,
      "confirm"
    );
  }
  const deleteComments = async () => {
    // console.log("deleteComments", selectedComment , props.tracker)
    setLoading(true);
    if (props.cust && props.cust.customerId) {
      try {
        const updateDate = new Date();
        let payload = {
          commentsId: selectedComment.commentsId,
          customerId: props.cust.customerId,
          // filingId: props.draft.filingId,
          isDeleted: true,
          updateUser: authUser?.userId + "",
          updateDate
        }

        let res = await axios.put(`${API_BASE_URL}/Customer/DeleteCustomerComments`, payload)
        console.log("deleteComments res", res)
        if (res) {
          if (getCustomerComments) {
            const _catData = await getCustomerComments(
              props.cust?.customerId
            );
            setData(() => _catData);
            console.log("deleteComments", "uploadandUpdateAttachment", "_catData", _catData);

           // await uploadandUpdateAttachment(_catData);
          }
          props.refreshTable();
          setAlertMessage("Successfully deleted comment.");
          setShowError(false);
          setShowSuccess(true);
          closeDialog();
          setSelectedComment({})
        }
      } catch (error) {
        console.log("@@Error:", error);
      } finally {
        setLoading(false);
      }
    }
  }

  const closeConfirm = () => {
    setApprovalComment("")
  };

  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: deleteComments,
    onClose: closeConfirm,
  });
  const onCommentChange = async (e: React.ChangeEvent<any>) => {
    setApprovalComment((old) => e.target.value);
    if (approvalComment) {
      setShowSuccess(false);
      setShowError(false);
    }
  };
  const getAttachmentId = async (commentsId: any) => {
    console.log("getAttachmentIt", props);
    let { cust = {} } = props;
    let { customerId = "" } = cust;
    let payload: any = {
      commentsId: commentsId,
      //TOCHECK
      customerId: customerId,
      // filingId: parseInt(props.tracker.filingId),
    };

    const res = await axios.post(
      `${API_BASE_URL}/Customer/CreateCustomerCommentsAttachments`,
      payload
    );
    console.log("getAttachmentIt res", res.data.data);
    if (res.data) {
      return res.data.data.attachmentID;
    }
  };
  const updateFilePath = async (
    commentsId: any,
    attachmentId: any,
    filePath: any
  ) => {
    console.log("updateFilePath", attachmentId, filePath);
    let payload = {
      attachmentId: attachmentId,
      attachmentPath: decodeURI(filePath),
      createDate: new Date(),
      createUser: authUser?.userId + "",
      commentsId: commentsId,
      //TOCHECK
      // filingId: props.tracker.filingId ? parseInt(props.tracker.filingId) : 0,
      // customerId: props.tracker?.customerId
      //   ? parseInt(props.tracker?.customerId)
      //   : 0,
    };

    const res = await axios.put(
      `${API_BASE_URL}/Customer/UpdateCustomerCommentsAttachments/${attachmentId}`,
      payload
    );
    console.log("updateFilePath res", res);
    if (res.data) {
    }
  };
  const fileUploadUtil = async (attachmentId: any, commentsId: any) => {
    console.log("fileUploadUtil params", attachmentId, commentsId,);
    let { cust } = props;
    let { customerId = "" } = cust || {};
    const formData = new FormData();
    formData.append("Files", selectedFile);
    //TOCHECK
    formData.append("customerId", "" + customerId);
    // formData.append("filingId", props.tracker.filingId);
    // formData.append("FileTrackingId", props.tracker.fileTrackingId);
    formData.append("commentsId", commentsId);
    formData.append("attachmentId", attachmentId);

    const res = await axios.post(
      `${API_BASE_URL}/Application/CustomerFilingUpload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("fileUploadUtil res", res);

    if (res.data) {
      console.log("fileUploadUtil res header", res.headers);
      await updateFilePath(commentsId, attachmentId, res.headers["x-file-url"]);
      // setFiles([...files, selectedFile]);
    }
  };
  const onFileUpload = async (commentsId: any) => {
    let attachmentId = await getAttachmentId(commentsId);
    await fileUploadUtil(attachmentId, commentsId);
    return;
  };

  const downloadFile = (
    attachmentPath: any,
    attachmentId: any,
    commentsId: any
  ) => {
    console.log("Downloading File from:", attachmentId);
    const fileName = getFilename(attachmentPath);
    console.log("Downloading File:", fileName);
    let { cust } = props;
    let { customerId = "" } = cust || {};
    axios({
      url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&CustomerId=${customerId}&CommentsId=${commentsId}`,
      method: "POST",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  };

  const uploadandUpdateAttachment = async (commentsId: any) => {
    console.log("uploadandUpdateAttachment", commentsId);
    let { cust } = props;
    let { customerId = "" } = cust || {};
    const formData = new FormData();
    formData.append("Files", selectedFile);
    formData.append("customerId", "" + customerId);
    formData.append("commentsId", commentsId);
    formData.append("createUser", authUser?.userId + "");
    const res = await axios.post(
      `${API_BASE_URL}/Application/UploadandUpdateAttachment`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("uploadandUpdateAttachment res", res);
    if (res.data) {
    }
  };

  const handleOk = async (anchor: Anchor) => {
    setIsLoading(true);
    setShowError(false);
    if (approvalComment.length === 0) {
      setAlertMessage("Please enter a comment.");
      setShowError(true);
      setIsLoading(false);
      setShowSuccess(false);
    } else {
      setShowSuccess(false);
      setShowError(false);

      console.log(`Add Comment:${approvalComment}`);

      try {
        // const now = new Date();
        const createDate = new Date();
        const payload = {
          customerId: props.cust?.customerId ? props.cust?.customerId : 0,
          commentsText: approvalComment,
          //createDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          createDate,
          createUser: authUser?.userId + "",
        };
        if (addCustomerComment) {
          const res = await addCustomerComment(payload);
          if (res) {
            console.log(`Comment has been added`);
            if (selectedFile) {
              console.log("handleOk", "uploadandUpdateAttachment", "res", res);

              await uploadandUpdateAttachment(res.data.commentsId);
            }
            props.refreshTable();
            if (getCustomerComments) {
              const _catData = await getCustomerComments(
                props.cust?.customerId
              );
              setData(() => _catData);
            }
            setApprovalComment(() => "");

            setState({ ...state, [anchor]: true });
            setAlertMessage("Comment has been added");
            setShowError(false);
            setShowSuccess(true);
            setRemainingChars(256);

            setSelectedFile("");
            setOpenAttachment(false);
            formRef.current && formRef.current.reset();
            return;
          }
        }
      } catch (error) {
        console.log(`Error while adding comment`, error);
        setAlertMessage("`Error while adding comment");
        setShowError(true);
        setApprovalComment(() => "");

        setRemainingChars(256);

        props.handleClose();
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleCancel = async (anchor: Anchor) => {
    setData(() => []);
    setShowSuccess(false);
    setShowError(false);
    setRemainingChars(256);
    setApprovalComment(() => "");
    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          {/* <Dialog
            header="Info"
            visible={showAlert}
            style={{ width: "30vw" }}
            onHide={() => closeAlert()}
            footer={footerContent}
          >
            <p className="m-0">{alertMessage}</p>
          </Dialog> */}
          <ConfirmDialog />
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
            sx={{ zIndex: "11" }}

          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
