import {
  FILING_MASTER_LOADING,
  FILING_MASTER_LOADED,
  FILING_MASTER_ERROR,
  FILING_MASTER_RESET,
  SET_CURRENT_FILING_MASTER,
  UPDATE_FILING_MASTER,
  CLEAR_CURRENT_FILING_MASTER,
  BUSINES_CATEGORY_LOADED,
  POST_PROGRAM_LOADING,
  POST_PROGRAM_ERROR,
  POST_PROGRAM_LOADED,
  FILING_MASTER_APPROVAL_LOADED,
  FILING_MASTER_APPROVAL_COMPLETED_LOADED,
  MASTER_COMMENTS_LOADED,
  MASTER_WORKFLOW_COMMENTS_LOADED,
  APPROVER_LIST_LOADED,
  MASTER_COMMENTS_ATTACHMENTS_LOADED,
  POSTAWARD_FILING_MASTER_LOADED
} from "../../data/constants";

//eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case FILING_MASTER_RESET:
      return {
        ...state,
        ...action.payload,
      };
    case FILING_MASTER_LOADING:
      return {
        ...state,
        filingMasterLoading: true,
        filingMasterLoaded: false,
        error: null,
      };
    case FILING_MASTER_LOADED:
      return {
        ...state,
        filingMasters: action.payload,
        filingMasterLoading: false,
        filingMasterLoaded: true,
        changedFilingMasters: [],
      };
    case POSTAWARD_FILING_MASTER_LOADED:
      return {
        ...state,
        postAwardFilingMasters: action.payload,
        filingMasterLoading: false,
        filingMasterLoaded: true,
       // changedFilingMasters: [],
      };
    case MASTER_COMMENTS_LOADED:
      return {
        ...state,
        filingMasterComments: action.payload,
        filingMasterLoading: false,
        filingMasterLoaded: true,
      };
    case MASTER_COMMENTS_ATTACHMENTS_LOADED:
      return {
        ...state,
        filingMasterCommentsAttachments: action.payload,
      };
    case MASTER_WORKFLOW_COMMENTS_LOADED:
      return {
        ...state,
        filingMasterWorkflowComments: action.payload,
        filingMasterLoading: false,
        filingMasterLoaded: true,
      };
    case POST_PROGRAM_LOADING:
      return {
        ...state,
        postProgramLoading: true,
        postProgramLoaded: false,
        error: null,
      };
    case BUSINES_CATEGORY_LOADED:
      return {
        ...state,
        businessCategories: action.payload,
        filingMasterLoading: false,
        filingMasterLoaded: true,
      };
    case POST_PROGRAM_LOADED:
      return {
        ...state,
        postProgram: action.payload,
        filingMasterLoading: false,
        filingMasterLoaded: true,
      };
    case FILING_MASTER_APPROVAL_LOADED:
      return {
        ...state,
        filingMasterApprovals: action.payload,
        filingMasterLoading: false,
        filingMasterLoaded: true,
      };
    case FILING_MASTER_APPROVAL_COMPLETED_LOADED:
        return {
          ...state,
          filingMasterApprovalsCompleted: action.payload,
          filingMasterLoading: false,
          filingMasterLoaded: true,
        };
    case UPDATE_FILING_MASTER:
      return {
        ...state,
        filingMasterLoading: false,
        filingMasterLoaded: true,
        selectedFilingMaster: action.payload,
      };
    case FILING_MASTER_ERROR:
      return {
        ...state,
        error: action.payload,
        filingMasterLoading: false,
        filingMasterLoaded: false,
      };
    case POST_PROGRAM_ERROR:
      return {
        ...state,
        error: action.payload,
        filingMasterLoading: false,
        postProgramLoaded: false,
      };
    case SET_CURRENT_FILING_MASTER:
      localStorage.setItem(
        "selectedFilingMaster",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        selectedFilingMaster: action.payload,
        filingMasterLoading: false,
        filingMasterLoaded: true,
      };
    case CLEAR_CURRENT_FILING_MASTER:
      localStorage.removeItem("selectedFilingMaster");
      return { ...state, selectedFilingMaster: null };
    case APPROVER_LIST_LOADED:
      return { ...state, approverList: action.payload };
    default:
      return state;
  }
};
