import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ApproveToolbox from "./ApproveToolbox";
import FilingMasterReferenceDocument from "./FilingMasterReferenceDocument";
import { DataTable } from "primereact/datatable";
import FilingMasterApprovalHistory from "./FilingMasterApprovalHistory";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import _ from "lodash";
import { useLocation } from "react-router-dom";
// import filingMasters from "../../data/filingMasters.json";
import AuthContext from "../../context/auth/AuthContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import ApproveFilingMaster from "./ApproveFilingMaster";
import FilingMasterWorkflowComments from "./ApprovalListComments";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ReactLoading from "react-loading";
import axios from "axios";
import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
import IconButton from "@mui/material/IconButton";
import { InputSwitch } from "primereact/inputswitch";
import { DateTime } from "luxon";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import { Stack } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { OverlayPanel } from "primereact/overlaypanel";
import { CircularProgress } from "@mui/material";
import states from "../../data/StateLookup.json";
import { Dropdown } from "primereact/dropdown";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const ApprovalList = () => {
  const [selectedFiling, setSelectedFiling] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [pendingTableData, setPendingTableData] = useState([]);
  const [completedTableData, setCompletedTableData] = useState([]);
  const [pendingMyRequests, setPendingMyRequests] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [comments, setComments] = React.useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [isClearIcon, setIsClearIcon] = useState(false);

  const [lazyLoading, setLazyLoading] = useState(false);
  const [refreshRecordList, setRefreshRecordList] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(null);
  const [recordsInView, setRecordsInView] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterChange, setIsFilterChange] = useState([]);
  const [sortingData, setSortingData] = useState({
    sortBy: "",
    sortOrder: "",
  });
  const [commentActivityHistory, setCommentActivityHistory] = React.useState([]);

  const [federalFilterSelectedValues, setFederalFilterSelectedValues] =
    React.useState({
      federalCategories: [],
    });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    approverName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    filingName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // filingDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingFrequency: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // dueDayofFrequency: { value: null, matchMode: FilterMatchMode.IN },
    stateInfo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    stateList: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    workflowStatus: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ruleInfo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    required: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    federalCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    stateCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    // jsidept: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jsicontactName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // jsicontactEmail: { value: null, matchMode: FilterMatchMode.CONTAINS },
    juristiction: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
  });
  const getFilingCategories = (filingMaster) => {
    return _.map(filingMaster.businessCategory, "businessCategoryName").join(
      ", "
    );
  };
  const filingMasterContext = useContext(FilingMasterContext);
  const {
    getFilingMasterApprovals,
    setFilingMasterApprovalsCompleted,
    filingMasterApprovalsCompleted,
    setFilingMasterApprovalsPending,
    filingMasterApprovals,
    filingMasterLoaded,
    getFilingMasterWorkflowComments,
  } = filingMasterContext;

  const authContext = useContext(AuthContext);
  const { roleType, authId, authUser, toLocalDateTime } = authContext;
  // const data = filingMasterApprovals;
  // const [visibleApps, setVisibleApps] = useState(props.projects);
  const [showFilter, setShowFilter] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [jurisdiction, setJurisdiction] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [viewAll, setViewAll] = useState(true);
  const [value, setValue] = React.useState("1");
  const [expandedRows, setExpandedRows] = useState(null);
  const [filingPeriodTable, setFilingPeriodTable] = useState([]);
  const [attachments, setAttachments] = React.useState([]);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [yearsList, setYearsList] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const { fetchConfigurations } = clientFilingMasterContext;

  const closeFilter = () => {
    // console.log("Toggling Show Filter", showFilter);
    setShowFilter(() => false);
  };

  function handleViewAllClick() {
    setViewAll(true);
  }

  function handlePaginationClick() {
    setViewAll(false);
  }

  const toLocalDate = (date) => {
    if (date) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      let stringDate = new Date(date).toLocaleDateString("en-US", options);

      const _date = DateTime.fromFormat(stringDate, "MMM dd, yyyy").toFormat(
        "dd-MMM-yyyy "
      );
      // // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const newBusinessCategory = (oldBC, newBC) => {
    let BC =
      oldBC.length > 0 ? oldBC.map((ele) => ele.businessCategoryName) : [];
    let changedBC = newBC.map((ele) => ele.businessCategoryName);
    // console.log("newBusinessCategory", BC, changedBC);
    return {
      field: "Business Category",
      old: BC.reduce((acc, item) => acc + (acc === "" ? "" : " , ") + item, ""),
      new: changedBC.reduce(
        (acc, item) => acc + (acc === "" ? "" : " , ") + item,
        ""
      ),
    };
  };

  const getModified = async () => {
    let modefiedSel = await Promise.all(
      selectedFiling.map(async (ele) => {
        let modified = await fetchFilingMasterPendingHistory(ele.draftId);
        // console.log("getModified modified", modified);
        if (modified.length > 0) {
          let activity = modified.map((ele) => {
            let changed = [];
            if (ele.process === "Modified") {
              // console.log("ele", ele);
              let keys = Object.keys(ele);
              keys.forEach((key) => {
                const capitalized = key.charAt(0).toUpperCase() + key.slice(1);
                // console.log("key", key, ele[key], ele[`new${capitalized}`]);
                if (
                  key === "businessCategory" &&
                  ele.newBusinessCategory !== null &&
                  ele.newBusinessCategory.length > 0
                ) {
                  let oldBC =
                    ele.businessCategory !== null ? ele.businessCategory : [];
                  let isSame = _.isEqual(oldBC, ele.newBusinessCategory);
                  // console.log("businessCategory isSame", isSame);
                  if (!isSame) {
                    changed.push(
                      newBusinessCategory(oldBC, ele.newBusinessCategory)
                    );
                  }
                } else if (
                  key === "filingPeriods" &&
                  ele.newFilingPeriods !== null &&
                  ele.newFilingPeriods.length > 0
                ) {
                  if (ele.filingPeriods === null) {
                    let filingPeriods = [];
                    let newFilingPeriods = ele.newFilingPeriods.sort(function (
                      a,
                      b
                    ) {
                      return new Date(b.StartDate) - new Date(a.StartDate);
                    });
                    // console.log(
                    //   "filingPeriods newFilingPeriods",
                    //   filingPeriods,
                    //   newFilingPeriods
                    // );
                    changed.push({
                      field: "Due Date",
                      old: filingPeriods.map((periods) =>
                        toLocalDate(periods.dueDate)
                      ),
                      new: newFilingPeriods.map((periods) =>
                        toLocalDate(periods.dueDate)
                      ),
                    });
                  } else {
                    let isSame = _.isEqual(
                      ele.filingPeriods,
                      ele.newFilingPeriods
                    );
                    // console.log("filingPeriods isSame", isSame);
                    if (!isSame) {
                      let filingPeriods = ele.filingPeriods.sort(function (
                        a,
                        b
                      ) {
                        return new Date(b.StartDate) - new Date(a.StartDate);
                      });
                      let newFilingPeriods = ele.newFilingPeriods.sort(
                        function (a, b) {
                          return new Date(b.StartDate) - new Date(a.StartDate);
                        }
                      );
                      // console.log(
                      //   "filingPeriods newFilingPeriods",
                      //   filingPeriods,
                      //   newFilingPeriods
                      // );
                      // let oldFP = [];
                      // let newFP = [];
                      // filingPeriods.forEach((periods, i) => {
                      //   if (
                      //     filingPeriods[i].StartDate ===
                      //       newFilingPeriods[i].StartDate &&
                      //     !_.isEqual(filingPeriods[i], newFilingPeriods[i])
                      //   ) {
                      //     oldFP.push(filingPeriods[i]);
                      //     newFP.push(newFilingPeriods[i]);
                      //   }
                      // });
                      // console.log("filingPeriods oldFP", oldFP, newFP);
                      changed.push({
                        field: "Due Date",
                        old: filingPeriods.map((periods) =>
                          toLocalDate(periods.dueDate)
                        ),
                        new: newFilingPeriods.map((periods) =>
                          toLocalDate(periods.dueDate)
                        ),
                      });
                    }
                  }
                } else if (
                  key === "filingAttachments" &&
                  (ele.filingAttachments !== null ||
                    ele.newFilingAttachments !== null)
                ) {
                  let isSame = _.isEqual(
                    ele.filingAttachments,
                    ele.newFilingAttachments
                  );
                  // console.log("filingAttachments isSame", isSame);
                  if (!isSame) {
                    let filingAttachments =
                      ele.filingAttachments !== null
                        ? ele.filingAttachments
                        : [];
                    let newFilingAttachments =
                      ele.newFilingAttachments !== null
                        ? ele.newFilingAttachments
                        : [];
                    // let oldFA = _.differenceWith(
                    //   filingAttachments,
                    //   newFilingAttachments,
                    //   _.isEqual
                    // );
                    // let newFA = _.differenceWith(
                    //   newFilingAttachments,
                    //   filingAttachments,
                    //   _.isEqual
                    // );
                    // console.log(
                    //   "filingAttachments newFA",
                    //   filingAttachments,
                    //   newFilingAttachments
                    // );

                    changed.push({
                      field: "Attachments",
                      old: filingAttachments,
                      new: newFilingAttachments,
                      draftId: ele.newDraftId,
                    });
                  }
                } else if (
                  ele[`new${capitalized}`] !== undefined &&
                  ele[key] !== ele[`new${capitalized}`] &&
                  key !== "draftId" &&
                  key !== "filingPeriods" &&
                  key !== "filingAttachments" &&
                  key !== "businessCategory"
                ) {
                  changed.push({
                    field: key,
                    old: ele[key],
                    new: ele[`new${capitalized}`],
                  });
                }
              });
            }

            if (changed.length > 0) {
              ele.changedData = changed;
              // console.log("changedData", ele.changedData);
            }
            return ele;
          });
          // console.log("getModified activity", activity);
          modified = activity;
        }
        // console.log("getModified ele", ele);
        ele.modified = modified;
        return ele;
      })
    );
    // console.log("getModified modefiedSel", modefiedSel);
    return modefiedSel;
  };
  const search = useRef();

  const applyGlobalFilter = () => {
    console.log("applyGlobalFilter", search.current.value, isClearIcon)
    let _filters = { ...filters };
    if (isClearIcon) {
      search.current.value = "";
      _filters["global"].value = "";
      setIsClearIcon(false);
      onFilterChange({ filters });
    } else {
      _filters["global"].value = search.current.value;
      setIsClearIcon(true);
      onFilterChange({ filters });
    }
    setFilters(_filters);
  };

  const approveDraft = async () => {
    // console.log("Approving Draft", selectedFiling, "Role:", roleType);
    if (selectedFiling && selectedFiling.length === 0) {
      setAlertMessage("Please Select a Filing Master Record");
      setShowAlert(true);
      // } else if (selectedFiling.length !== 1 && authUser.roleName === "Admin") {
      //   setAlertMessage("Please select a single Filing Master Record");
      //   setShowAlert(true);
    } else if (selectedFiling && selectedFiling.length !== 0) {
      let modefiedSel = await getModified();
      // console.log("Approving getModified", modefiedSel);
      setShowFilter(() => true);
    }
    // else {
    //   setAlertMessage("Please Select a Filing Master Record");
    //   setShowAlert(true);
    // }
  };

  const fetchFilingMasterAttachments = async (draftId, filingId) => {
    // console.log("fetchFilingMasterAttachments props", draftId, value);
    try {
      let attachmentList;
      if (value === "2") {
        attachmentList = await axios.get(
          `${API_BASE_URL}/FilingMaster/FilingMasterAttachments/${filingId}`
        );
      } else {
        attachmentList = await axios.get(
          `${API_BASE_URL}/FilingMaster/FilingMasterDraftAttachments/${draftId}`
        );
      }
      let list = attachmentList.data.data.map((ele) => ({ ...ele, filingId }));
      setAttachments(() => list);
      // console.log("fetchFilingMasterAttachments res", attachmentList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const closeAttachments = () => {
    // console.log("Toggling Show Filter", showAttachments);
    setShowAttachments(() => false);
  };
  const closeHistory = () => {
    // console.log("Toggling Show Filter", showHistory);
    setShowHistory(() => false);
  };

  const fetchFilingMasterHistory = async (selectedFiling) => {
    // console.log("fetchFilingMasterHistory props", "selectedFiling", selectedFiling, "filingId", selectedFiling.filingId, "workflowId", selectedFiling.workflowId);
    try {
      const history = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAudit?FilingId=${selectedFiling.filingId}`
      );
      setActivityHistory(() => history.data.data);
      // console.log("fetchFilingMasterHistory res", history);
    } catch (error) {
      console.log("@@Error:", error);
    }
    try {
      const history = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowCommentsaudit?WorkflowId=${selectedFiling.workflowId}`
      );
      setCommentActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const fetchFilingMasterPendingHistory = async (filingId) => {
    // console.log("fetchFilingMasterPendingHistory props", filingId);
    return new Promise(async (resolve, reject) => {
      try {
        const history = await axios.get(
          `${API_BASE_URL}/FilingMaster/MasterFilingPendingAudit?DraftId=${filingId}`
        );
        // console.log("fetchFilingMasterPendingHistory res", history);
        if (history.data) {
          resolve(history.data.data);
        }
        resolve([]);
      } catch (error) {
        console.log("@@Error:", error);
        reject(error);
      }
    });
  };

  const displayHistory = async () => {
    // console.log("displayHistory", selectedFiling[0]);

    if (
      selectedFiling &&
      selectedFiling.length === 1 &&
      selectedFiling[0].filingId
    ) {
      // console.log(`Fetching History for :${selectedFiling[0].filingId}`);
      // console.log("selectedFiling", selectedFiling);
      fetchFilingMasterHistory(selectedFiling[0]);
      setShowHistory(() => true);
    } else if (selectedFiling.length > 1) {
      // console.log("selectedFilingLength3", selectedFiling.length);

      setAlertMessage("Please Select a single Filing Master Record");
      setShowAlert(true);
    } else if (selectedFiling.length === 0) {
      // console.log("selectedFilingLength2", selectedFiling.length);

      setAlertMessage("Please Select a Filing Master Record");
      setShowAlert(true);
    }
  };

  const fetchFilingMasterComments = async (data) => {
    // console.log("@@Fetch FilingMasters1:", getFilingMasterWorkflowComments);
    if (getFilingMasterWorkflowComments) {
      // console.log("@@Fetch FilingMasters2:");
      try {
        let dataId =
          value === "2"
            ? { id: data.filingId, type: "filing" }
            : { id: data.workflowId, type: "workflow" };
        const commentList = await getFilingMasterWorkflowComments(dataId);
        setComments(() => commentList);
        // console.log("@@Fetch FilingMasters2 comments:", commentList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const displayAttachments = async (rowData) => {
    // console.log("##selectedFiling", selectedFiling[0]);
    // console.log("##selectedFiling-length", selectedFiling.length);
    // console.log("##RowData", rowData);
    // console.log("LengthSelectedFiling", selectedFiling.length);
    if (rowData && rowData.draftId) {
      // console.log(`##RowData-FilingId :${rowData.draftId}`);
      fetchFilingMasterAttachments(rowData.draftId, rowData.filingId);
      setShowAttachments(() => true);
      setSelectedFiling(() => []);
    } else if (
      selectedFiling &&
      selectedFiling.length === 1 &&
      selectedFiling[0].draftId
    ) {
      // console.log(`Fetching Attachment for :`, selectedFiling);
      fetchFilingMasterAttachments(
        selectedFiling[0].draftId,
        selectedFiling[0].filingId
      );
      setShowAttachments(() => true);
      setSelectedFiling(() => []);
    } else if (selectedFiling.length > 1) {
      setAlertMessage("Please Select a single Filing Master Record");
      setShowAlert(true);
    } else {
      setAlertMessage("Please Select a Filing Master Record");
      setShowAlert(true);
    }
  };

  const addComment = (rowData) => {
    console.log("Display Comments", selectedFiling);
    console.log("Display Comments rowData", rowData);
    if (rowData && rowData.workflowId) {
      fetchFilingMasterComments(rowData);
      setShowComments(() => true);
      setSelectedFiling([]);
      setSelectedRowData(rowData);
    } else if (
      selectedFiling &&
      selectedFiling.length === 1 &&
      selectedFiling[0].workflowId
    ) {
      // console.log(`Fetching Commnet for :${selectedFiling[0].workflowId}`);
      setSelectedRowData(null);

      fetchFilingMasterComments(selectedFiling[0]);

      setShowComments(() => true);
    } else {
      setAlertMessage("Please select a single Record");
      setShowAlert(true);
    }
  };

  const closeComments = () => {
    // console.log("Toggling Show Filter", showComments);
    setShowComments(() => false);
  };
  const getStateName = (stateCode) => {
    const state = states.find((s) => s.code === stateCode);
    return state ? state.state : stateCode;
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const jusrisdictionItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <span>{option}</span>
      </div>
    );
  };

  const jusrisdictionArray = ["Federal", "State", "Both"];

  const jurisdictionRowFilterTemplate = (options) => {
    if (
      !federalFilterSelectedValues.jurisdiction &&
      options?.filterModel?.value
    ) {
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <Dropdown
        value={federalFilterSelectedValues.jurisdiction || []}
        options={jusrisdictionArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, jurisdiction: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };
  const handleRefreshList = () => {

    //Virtual scroll callback
    const vScroll = value === "1"
      ? dt.current.getVirtualScroller()
      : dtComp.current.getVirtualScroller();
    let range = vScroll.getRenderedRange();
    console.log("refreshTable vScroll", vScroll, range);

    let pageInView
    let noOfPages
    if (value === "1") {
      if (pendingTableData.length > 100) {
        pageInView = recordsInView - 2
        noOfPages = 2
      } else {
        pageInView = recordsInView - 1
        noOfPages = 1
      }
    console.log("refreshTable clientSelected", pendingTableData);
    } else {
      if (completedTableData.length > 100) {
        pageInView = recordsInView - 2
        noOfPages = 2
      } else {
        pageInView = recordsInView - 1
        noOfPages = 1
      }
    }
    let clientSelected = selectedFiling
    setRecordsInView(pageInView)
    setRefreshDetails({
      viewport: range.viewport,
      pageInView,
      noOfPages,
      clientSelected
    })

    // fetchFilingMasters();
    setIsLoading(true);
    setData([]);
    setInitialData([]);
    setPendingTableData([]);
    setCompletedTableData([]);
    setIsFilterChange([]);
    setSortingData({ sortBy: "", sortOrder: "" });
    setFederalFilterSelectedValues([]);
    setRefreshRecordList(true);
  }
  const fetchFilingMasters = async () => {
    // console.log("@@Fetch FilingMasters1:", getFilingMasterApprovals);
    setIsLoading(true);
    let _filters = { ...filters };
    search && search.current && (search.current.value = "");
    _filters && _filters["global"] && (_filters["global"].value = "");
    setFilters(_filters);
    setIsClearIcon(false);

    if (getFilingMasterApprovals) {
      // console.log("@@Fetch FilingMasters2:");
      try {
        let formData;
        if (roleType.includes("Admin")) {
          formData = {
            recordType: "TrackNow",
          };
        } else {
          formData = { approverId: authUser.userId, recordType: "TrackNow" };
        }
        // if (roleType.includes("JSIPOC") || roleType.includes("JSISME")) {
        //   formData = { approverId: authUser.userId, recordType: "TrackNow" };
        // }
        // else {
        //   formData = {
        //     recordType: "TrackNow",
        //   }
        // }
        const list = await getFilingMasterApprovals(formData);
        // console.log("@@FilingMasters:", list);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };
  const fetchFilingMastersCompleted = async () => {
    let _filters = { ...filters };
    search && search.current && (search.current.value = "");
    _filters && _filters["global"] && (_filters["global"].value = "");
    setFilters(_filters);
    setIsClearIcon(false);

    // console.log("@@Fetch FilingMasters2:");
    setIsLoading(true);
    try {
      let formData;
      let filingList;

      if (roleType.includes("Admin")) {
        formData = {
          recordType: "TrackNow",
        };
      } else {
        formData = { approverId: authUser.userId, recordType: "TrackNow" };
      }
      // console.log("formDataCheck", formData);
      if (formData?.approverId) {
        let listRes = await axios.get(
          `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowList?ApproverId=${formData.approverId}&RecordType=${formData.recordType}&Year=${selectedYear}`
        );
        filingList = listRes.data.data;
        // console.log("@@FilingMastersCompList:", listRes);
      } else {
        let listRes = await axios.get(
          `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowList?&RecordType=${formData.recordType}&Year=${selectedYear}`
        );
        filingList = listRes.data.data;
        // console.log("@@FilingMastersCompList:", listRes);
      }
      const updList = filingList
        .filter((item) => item.changesInprogress)
        .map((item) => {
          // console.log(`filtered data ...`, item);
          const _stateList = getStateName(item.stateInfo);

          if (item.juristiction?.includes("Federal")) {
            const categories = _.map(
              item.businessCategory,
              "businessCategoryName"
            ).join(", ");
            // console.log(
            //   `filingMasters ${item.filingMasters} cat...`,
            //   categories
            // );
            return {
              ...item,
              federalCategories: categories,
              stateList: _stateList,
            };
          } else {
            // console.log(
            //   "stateCategories:",
            //   item.filingName,
            //   item.stateInfo,
            //   item.businessCategory
            // );
            const categoriesState = item.businessCategory.filter((ele) =>
              ele.state ? ele.state === item.stateInfo : ele
            );
            // console.log("stateCategories:", item.filingName, categoriesState);
            const categories = categoriesState
              .map((ele) => ele.businessCategoryName)
              .join(", ");

            // console.log(
            //   `filingMasters ${item.filingMasters} cat...`,
            //   categories
            // );
            return {
              ...item,
              stateCategories: categories,
              stateList: _stateList,
            };
          }
        });
      // const _jusrisdictions = updList.map((item) =>
      //   item.juristiction ? item.juristiction : ""
      // );
      // const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
      // console.log("@@Unique Jurisdiction:", _uniqJurisdictions);
      // setJurisdiction(_uniqJurisdictions);
      // setInitialData(() => updList);
      // setData(() => updList);
      // let pendingList = updList.filter(
      //   (ele) => ele.workflowStatus === "Pending"
      // );
      // console.log("filter pendingList :", pendingList, updList);
      // setPendingTableData(() => pendingList);
      let completedList = updList.filter(
        (ele) => ele.workflowStatus !== "Pending"
      );
      // console.log("filter completedList :", completedList, updList);
      // completedList = completedList.filter(
      //   (value, index, self) =>
      //     index === self.findIndex((t) => t.filingId === value.filingId)
      // );

      // Storing latest records if completedList has same filingname based on updateDate

      let latestUpdates = {};

      completedList.forEach((ele) => {
        const filingId = ele.filingId;

        // Check if the filingId is already in the latestUpdates object
        if (latestUpdates[filingId]) {
          // Compare the updateDate with the stored one
          const storedUpdateDate = latestUpdates[filingId].updateDate;
          const currentUpdateDate = ele.updateDate;

          // If the current updateDate is greater, update the stored object
          if (currentUpdateDate > storedUpdateDate) {
            latestUpdates[filingId] = ele;
          }
        } else {
          // If filingId is not in latestUpdates, add it
          latestUpdates[filingId] = ele;
        }
      });

      completedList = Object.values(latestUpdates);

      // console.log("duplicate completedList :", completedList, updList);
      // console.log("");
      setCompletedTableData(() => completedList);
      // console.log("filingList", filingList, "updList", updList, "completedList", completedList);
    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRowSelect = (e) => {
    // console.log("@@RowSelect:", e);
  };

  const getAllAttachment = async (updList) => {
    updList = await Promise.all(
      updList.map(async (ele) => {
        ele.attachment = await getFileAttachment(ele);
        return ele;
      })
    );
    setData(() => updList);
  };

  const getFileAttachment = async (ele) => {
    // console.log("getFileAttachment-->", ele);
    await axios
      .get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachmentsGetById/${ele.filingId}`
      )
      .then((res) => {
        // console.log("getFileAttachment res", ele, ele.filingId, res.data);
        if (res.data) {
          return res.data.attachmentPath;
        }
      });
  };

  useEffect(() => {
    if (filingMasterLoaded) {
      // console.log("Show Table Data", filingMasterApprovals);
      // const updList = filingMasterApprovals
      //   .filter((item) => item.changesInprogress)
      //   .map((item) => {
      //     // console.log(`filtered data ...`, item);
      //     const _stateList = getStateName(item.stateInfo);
      //     if (item.juristiction?.includes("Federal")) {
      //       const categories = _.map(
      //         item.businessCategory,
      //         "businessCategoryName"
      //       ).join(", ");
      //       // console.log(
      //       //   `filingMasters ${item.filingMasters} cat...`,
      //       //   categories
      //       // );
      //       return {
      //         ...item,
      //         federalCategories: categories,
      //         stateList: _stateList,
      //       };
      //     } else {
      //       // console.log(
      //       //   "stateCategories:",
      //       //   item.filingName,
      //       //   item.stateInfo,
      //       //   item.businessCategory
      //       // );
      //       const categoriesState = item.businessCategory.filter((ele) =>
      //         ele.state ? ele.state === item.stateInfo : ele
      //       );
      //       // console.log("stateCategories:", item.filingName, categoriesState);
      //       const categories = categoriesState
      //         .map((ele) => ele.businessCategoryName)
      //         .join(", ");
      //       // console.log(
      //       //   `filingMasters ${item.filingMasters} cat...`,
      //       //   categories
      //       // );
      //       return {
      //         ...item,
      //         stateCategories: categories,
      //         stateList: _stateList,
      //       };
      //     }
      //   });
      // const _jusrisdictions = updList.map((item) =>
      //   item.juristiction ? item.juristiction : ""
      // );
      // const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
      // // console.log("@@Unique Jurisdiction:", _uniqJurisdictions);
      // setJurisdiction(_uniqJurisdictions);
      // setInitialData(() => updList);
      // setData(() => updList);
      // let pendingList = updList.filter(
      //   (ele) => ele.workflowStatus === "Pending"
      // );
      // console.log("filter pendingList :", pendingList, updList);
      // setPendingTableData(() => pendingList);
      // let completedList = updList.filter(
      //   (ele) => ele.workflowStatus !== "Pending"
      // );
      // // console.log("filter completedList :", completedList, updList);
      // completedList = completedList.filter(
      //   (value, index, self) =>
      //     index === self.findIndex((t) => t.filingId === value.filingId)
      // );
      // // console.log("duplicate completedList :", completedList, updList);
      // setCompletedTableData(() => completedList);
      // setIsLoading(false);
    }
  }, [filingMasterLoaded]);
  const fetchConfigs = async () => {
    if (fetchConfigurations) {
      let config = await fetchConfigurations();
      let startYear = config.find(
        (ele) => ele.configItem === "StartYear"
      ).configItemValue;
      let currentYear = config.find(
        (ele) => ele.configItem === "CurrentYear"
      ).configItemValue;
      startYear = parseInt(startYear);
      currentYear = parseInt(currentYear);
      const yearsInBetween = [];
      for (let year = startYear; year <= currentYear + 1; year++) {
        yearsInBetween.push(year);
      }
      setYearsList(yearsInBetween);
      setSelectedYear(currentYear);
      // console.log(
      //   'configCheck',
      //   config,
      //   'StartYear',
      //   startYear,
      //   'currentYear',
      //   currentYear,
      //   'yearsInBetween',
      //   yearsInBetween
      // );
    }
  };
  useEffect(() => {
    fetchConfigs();
  }, []);
  useEffect(() => {
    // console.log("InitialCheck2", selectedYear, value);
    if (value === "2") {
      // console.log("InitialCheck3", selectedYear, value);
      // fetchFilingMastersCompleted()
      setRecordsInView(0);
      setIsLoading(true);
      setData([]);
      setInitialData([]);
      setPendingTableData([]);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setFederalFilterSelectedValues([]);
      setRefreshRecordList(true);
    }
  }, [selectedYear]);

  useEffect(() => {
    let list = states.map((ele) => ele.state);
    setStateArray(list);
    // fetchFilingMasters();
    setRecordsInView(0);
    setIsLoading(true);
    setData([]);
    setInitialData([]);
    setPendingTableData([]);
    setCompletedTableData([]);
    setIsFilterChange([]);
    setSortingData({ sortBy: "", sortOrder: "" });
    setFederalFilterSelectedValues([]);
    setRefreshRecordList(true);
  }, []);
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const requiredArray = ["True", "False"];

  const requiredRowFilterTemplate = (options) => {
    // console.log("requiredRowFilterTemplate:", options,masterCatFilter);
    if (!federalFilterSelectedValues.required && options?.filterModel?.value) {
      // console.log("requiredRowFilterTemplate filterModel",options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <Dropdown
        value={federalFilterSelectedValues.required || []}
        options={requiredArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, required: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const federalFilterElement = (options, field, tab) => {
    // console.log("##OptionsCheck", options, field);
    let optionsList = [];
    data
      .map((item) => item[field])
      .filter(Boolean)
      .map((item) => {
        if (item.includes(",")) {
          //console.log("spliited_item", item);
          item.split(",").map((subItem) => {
            optionsList.push(subItem && subItem.trim());
          });
        } else {
          optionsList.push(item.trim());
        }
      });
    optionsList = optionsList
      .filter((item, i) => {
        return i === optionsList.lastIndexOf(item);
      })
      .sort();

    let _dataClone =
      tab === "pending"
        ? data.filter((ele) => ele.workflowStatus === "Pending")
        : data
          .filter((ele) => ele.workflowStatus !== "Pending")
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.filingId === value.filingId)
          );
    // console.log("roleTypeCheck", roleType, authUser);
    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={federalFilterSelectedValues[field] || []}
        options={optionsList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, _dataClone);
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, [field]: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={federalFilterSelectedValues[field] || []}
        options={optionsList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, data);
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];

          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const stateRowFilterTemplate = (options) => {
    // console.log("##Options:", options);
    if (
      !federalFilterSelectedValues?.stateList &&
      options?.filterModel?.value
    ) {
      // console.log(" optionsfilterModel", options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }
    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={federalFilterSelectedValues.stateList || []}
        options={stateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##EOptions:", e);
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, stateList: e.value };
          });
          options.filterCallback(e.value, options.index);
          // options.filterApplyCallback(e.value);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "11rem", maxWidth: "11rem" }}
      />
    ) : (
      <Dropdown
        value={federalFilterSelectedValues.stateList || []}
        options={stateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##EOptions:", e);
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, stateList: e.value };
          });
          options.filterCallback(e.value, options.index);
          // options.filterApplyCallback(e.value);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    );
  };
  const displayTimeStamp = (rowData) => {
    return rowData.recentDate ? toLocalDateTime(rowData.recentDate) : ""
  }
  const CellListDisplayFederal = (rowData, field) => {
    const op = useRef(null);

    let displayFederal = Array.isArray(rowData[field])
      ? rowData[field].join(", ")
      : rowData[field];

    const categories = rowData[field]
      ? field === "federalCategories"
        ? rowData.businessCategory.map((ele) => ele.businessCategoryName)
        : rowData.businessCategory
          .filter((ele) => ele.state === rowData.stateInfo)
          .map((ele) => ele.businessCategoryName)
      : [];
    // console.log("CellListDisplayFederal categories--", categories);

    let name =
      field === "federalCategories"
        ? "Who Must File Federal"
        : "Who Must File in State";

    return (
      <div className="tableBtn" id={`${field}+${rowData.fileTrackingId}`}>
        <Button onClick={(e) => op.current.toggle(e)} text>
          <div
            style={{
              width: "12rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "black",
            }}
          >
            {displayFederal}
          </div>
        </Button>
        <OverlayPanel ref={op} showCloseIcon style={{ padding: "0rem 1rem" }}>
          {name}
          <ScrollPanel style={{ maxWidth: "25rem", maxHeight: "20rem" }}>
            <ul>
              {categories.map((ele) => (
                <li>{ele}</li>
              ))}
            </ul>
          </ScrollPanel>
        </OverlayPanel>
      </div>
    );
  };

  const cellListDisplayName = (rowData) => {
    return (
      <LightTooltip title={rowData.filingName}>
        <div
          style={{
            width: "inherit",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.filingName}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayRuleInfo = (rowData) => {
    return (
      <LightTooltip title={rowData.ruleInfo}>
        <div
          style={{
            width: "12rem",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.ruleInfo}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayNotes = (rowData) => {
    const notesUrl = rowData.notes;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (notesUrl) {
      const formattedText = notesUrl.replace(
        urlRegex,
        '<a style="color: #0000FF;" href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
      );
      return (
        <LightTooltip
          title={
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: formattedText }} />
            </React.Fragment>
          }
        >
          <div
            style={{
              width: "18rem",
              margin: "0 1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </LightTooltip>
      );
    }
    return notesUrl;
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F1F2F6",
      color: "black",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  useEffect(() => {
    // console.log("state", selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    // console.log("pendingMyRequests", pendingMyRequests);
  }, [pendingMyRequests]);

  const renderHeader = () => {
    return (
      <Grid container sx={{ my: 1, flexDirection: "row" }}>
        <Grid md={8} sx={{ margin: 1, flexGrow: 1 }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            FILING MASTER APPROVALS ({totalRecords})
          </Typography>
          {/* <InputSwitch
            checked={pendingMyRequests}
            onChange={(e) => setPendingMyRequests(e.value)}
          /> */}
        </Grid>

        <Grid md={3} sx={{ margin: 1 }}>
          <span style={{ display: "flex" }}>
            <InputText
              ref={search}
              inputRef={search}
              placeholder="Search by Filing Name"
              s
              size={20}
            />
            <PrimeButton
              icon={isClearIcon ? "pi pi-times" : "pi pi-search"}
              onClick={(e) => applyGlobalFilter(e)}
            />
          </span>
        </Grid>
      </Grid>
    );
  };

  const renderCompletedHeader = () => {
    return (
      <Grid container sx={{ my: 1, flexDirection: "row" }} spacing={4}>
        <Grid xs="auto" sx={{ margin: 1, flexGrow: 1 }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            FILING MASTER APPROVALS ({totalRecords})
          </Typography>
        </Grid>
        <Grid xs="auto">
          {value === "2" ? (
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Year</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedYear}
                label="Year"
                onChange={handleYearChange}
              >
                {yearsList.map((year) => (
                  <MenuItem value={year} key={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </Grid>
        <Grid xs="auto" sx={{ margin: 1 }}>
          <span style={{ display: "flex" }}>
            <InputText
              ref={search}
              inputRef={search}
              placeholder="Search by Filing Name"
              size={20}
            />
            <PrimeButton
              icon={isClearIcon ? "pi pi-times" : "pi pi-search"}
              onClick={(e) => applyGlobalFilter(e)}
            />
          </span>
        </Grid>
      </Grid>
    );
  };

  const updateSelectedWorkflow = (workflow) => {
    // // console.log("SELECT FILING UPDTAE", workflow);
    if (workflow) setSelectedFiling([workflow]);
    else setSelectedFiling([]);
    setRecordsInView(0);
    setIsLoading(true);
    setData([]);
    setInitialData([]);
    setPendingTableData([]);
    setCompletedTableData([]);
    setIsFilterChange([]);
    setSortingData({ sortBy: "", sortOrder: "" });
    setFederalFilterSelectedValues([]);
    setRefreshRecordList(true);
  };

  const CustomCommentButton = ({ rowData }) => {
    // console.log("rowComments", rowData.hasComments);
    // console.log("rowCustomerId", rowData.customerId);

    return (
      <IconButton color="primary" disabled={!rowData.hasComments}>
        <InsertCommentRoundedIcon onClick={() => addComment(rowData)} />
      </IconButton>
    );
  };
  const FilingAttachmentButton = ({ rowData }) => {
    return (
      <IconButton color="primary" disabled={!rowData.hasReference}>
        <SnippetFolderIcon onClick={() => displayAttachments(rowData)} />
      </IconButton>
    );
  };
  const header = renderHeader();
  const completedHeader = renderCompletedHeader();
  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );
  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleTabChange = (event, newValue) => {
    setFederalFilterSelectedValues([]);
    setSelectedFiling([]);
    setValue(newValue);
    let _filters = { ...filters };
    search.current.value = "";
    _filters["global"].value = "";
    setFilters(_filters);
    setIsClearIcon(false);
    setGlobalFilterValue("");
    if (newValue === "2") {
      // fetchFilingMastersCompleted()
      setIsDisabled(true);
      // console.log("InitialCheck1", selectedYear, newValue);
      setRecordsInView(0);
      setIsLoading(true);
      setData([]);
      setInitialData([]);
      setPendingTableData([]);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setFederalFilterSelectedValues([]);
      setRefreshRecordList(true);
    } else {
      // fetchFilingMasters()
      setIsDisabled(false);
      setRecordsInView(0);
      setIsLoading(true);
      setData([]);
      setInitialData([]);
      setCompletedTableData([]);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setFederalFilterSelectedValues([]);
      setRefreshRecordList(true);
    }
  };

  const rowExpansionTemplate = (data) => {
    console.log("rowExpansionTemplate", data.filingPeriods);
    if (data.filingPeriods.length > 0) {
      let tableData = data.filingPeriods.map((ele) => {
        let startDate = new Date(ele.startDate);
        let endDate = new Date(ele.endDate);
        const toLocalDateString = (date) => {
          const options = { month: "2-digit", day: "2-digit", year: "numeric" };
          return date
            .toLocaleDateString("en-US", options)
            .replace(
              /(\d+)\/(\d+)\/(\d+)/,
              (_, month, day, year) =>
                `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`
            );
        };

        let fp = `${toLocalDateString(startDate)} - ${toLocalDateString(
          endDate
        )}`;
        let dueDate =
          ele.dueDate !== null
            ? toLocalDateString(new Date(ele.dueDate))
            : null;
        console.log("dueDateCheck#", dueDate);
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
          dueDate: dueDate,
        };
      });
      return (
        <div className="filingPeriodTable p-3" style={{ width: "50vw" }}>
          <h5>Due Dates for {data.filingName}</h5>
          <DataTable value={tableData}>
            <Column field="filingPeriod" header="Filing Period"></Column>
            <Column field="dueDate" header="DueDate"></Column>
          </DataTable>
        </div>
      );
    } else {
      return (
        <div className="filingPeriodTable p-3">
          <h5>No Due Dates for {data.filingName}</h5>
        </div>
      );
    }
  };

  const rowExpand = (event) => {
    console.log("rowExpand", event, expandedRows);
    // const newKeys =
    //   expandedRows !== null && expandedRows !== undefined
    //     ? Object.keys(event.data).filter(
    //         (key) => !expandedRows.hasOwnProperty(key)
    //       )[0]
    //     : Object.keys(event.data)[0];
    // let newExpanded = {};
    // newExpanded[newKeys] = event.data[newKeys];
    // console.log("rowExpand", newKeys, newExpanded);
    setExpandedRows(event.data);
  };

  const onRowExpand = (event) => {
    console.log("rowExpand-->", event);
    getFilingPeriod(event.data);
  };

  const getFilingPeriod = async (data) => {
    console.log("@@Fetch getFilingPeriod", data?.filingId);
    try {
      const filingPeriod = await axios.get(
        `${API_BASE_URL}/FilingMaster/ListFilingMasterDraftPeriods/${data?.filingId}`
      );
      console.log("getFilingPeriod res", filingPeriod.data.data);
      let tableData = filingPeriod.data.data.map((ele) => {
        let fp = `${toLocalTime(ele.startDate)} - ${toLocalTime(ele.endDate)}`;
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
          dueDate: toLocalTime(ele.dueDate),
          initialDueDate: toLocalTime(ele.dueDate),
        };
      });
      console.log("getFilingPeriod tableData", tableData);
      setFilingPeriodTable(tableData);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yy");
      console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const dt = useRef(null);
  const dtComp = useRef(null);
  const pageSize = 100;

  // lazy load function
  const loadDataLazy = async (event) => {
    console.log("loadDataLazy props", recordsInView, event);

    //Virtual scroll callback

    const vScroll =
      value === "1"
        ? dt.current.getVirtualScroller()
        : dtComp.current.getVirtualScroller();

    let range = vScroll.getRenderedRange();
    let { first, last } = range.viewport;
    console.log("loadDataLazy vScroll", vScroll, range.viewport);

    let firstVal = recordsInView * pageSize;
    let tableList = value === "1" ? pendingTableData : completedTableData;
    console.log(
      "loadDataLazy totalRecords",
      firstVal,
      totalRecords,
      lazyLoading,
      tableList.length
    );

    console.log(
      "loadDataLazy",
      firstVal < totalRecords,
      first > tableList.length - 10,
      !lazyLoading
    );

    if (
      firstVal < totalRecords &&
      first > tableList.length - 10 &&
      !lazyLoading
    ) {
      //scroll down
      // setIsLoading(true);
      setLazyLoading(true);
      let lastVal = firstVal + pageSize;
      console.log("loadDataLazy d firstVal", firstVal, lastVal);

      //get next data
      let records = await loadRecordsList(firstVal, lastVal, "down");
      console.log("loadDataLazy d record", records);
      if (records) {
        console.log("loadDataLazy d scrollInView", recordsInView * 100);

        //scroll to next records
        vScroll.scrollInView(recordsInView * 100);
        setRecordsInView((old) => old + 1);
        setLazyLoading(false);
        setIsLoading(false);
      }
    } else if (last < 10 && recordsInView > 1 && !lazyLoading) {
      // scroll up
      let firstVal = (recordsInView - 3) * pageSize;
      let lastVal = firstVal + pageSize;
      if (firstVal >= 0) {
        // setIsLoading(true);
        setLazyLoading(true);
        // console.log("loadDataLazy u firstVal", firstVal, lastVal);

        //get prev data
        let records = await loadRecordsList(firstVal, lastVal, "up");
        // console.log("loadDataLazy u record", records);
        if (records) {
          // console.log("loadDataLazy u scrollInView", (recordsInView - 3) * 100);

          //scroll to prev records
          vScroll.scrollToIndex(15);
          setRecordsInView((old) => old - 1);
          setLazyLoading(false);
          setIsLoading(false);
        }
      }
    }
  };

  const loadRecordsList = async (first, last, direction) => {
    // console.log("loadRecordsList props", first, last, direction);
    setIsLoading(true);
    let url = `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowListbyPagination?StartRow=${first + 1}&EndRow=${last}&RecordType=TrackNow`;

    // console.log("loadRecordsList recordTypeVal", recordTypeVal);
    // if (recordTypeVal !== "") {
    //   url = `${url}&RecordType=${recordTypeVal}`;
    // }

    console.log("loadRecordsList selectedYear", value, selectedYear);
    if (value === "2" && selectedYear !== "") {
      url = `${url}&StatusMove=Completed&Year=${selectedYear}`;
    }

    // console.log("loadRecordsList fliter/sort", isFilterChange, sortingData);
    if (isFilterChange !== undefined && isFilterChange.length > 0) {
      // url filter parameters
      isFilterChange.forEach((ele) => {
        url = `${url}&${ele.name}=${ele.val}${ele.searchType ? `&${ele.searchType}=${ele.searchTypeVal}` : ``
          }`;
      });
    }

    if (
      sortingData &&
      typeof sortingData === "object" &&
      sortingData.sortBy !== "" &&
      sortingData.sortOrder !== ""
    ) {
      // url sort parameters
      let sortParameter = getSortData(sortingData.sortBy);
      console.log("getSortData", sortParameter);
      let order = sortingData.sortOrder === 1 ? "Ascending" : "Descending";

      url = `${url}&${sortParameter.SortByParameter}=${sortParameter.SortByVal}&${sortParameter.SortOrderParameter}=${order}`;
    }

    // console.log("loadRecordsList url", url);
    try {
      //get data
      const res = await axios.get(url);
      console.log("loadRecordsList res", res.data, res.data.data.items);

      if (res.data) {
        setIsLoading(true);
        let ogList = [];
        if (value === "2" && selectedYear !== "") {
          ogList = completedTableData.map((ele) => ele);
        } else {
          ogList = pendingTableData.map((ele) => ele);
        }
        await modifyList(res.data.data.items, direction, ogList);
        setTotalRecords(res.data.data.totalItems);

        return res.data.data.items;
      } else {
        return false;
      }
    } catch (err) {
      // console.log("loadRecordsList err", err);
    }
  };

  //maping records for lazy load
  const modifyList = (list, direction, ogList) => {
    console.log("modifyList", list, direction);
    if (list.length > 0) {
      //list has data

      const updList = list
        .map((item) => {
          // console.log(`filtered data ...`, item);
          const _stateList = getStateName(item.stateInfo);

          if (item.juristiction?.includes("Federal")) {
            const categories = _.map(
              item.businessCategory,
              "businessCategoryName"
            ).join(", ");
            // console.log(
            //   `filingMasters ${item.filingMasters} cat...`,
            //   categories
            // );
            return {
              ...item,
              federalCategories: categories,
              stateList: _stateList,
            };
          } else {
            // console.log(
            //   "stateCategories:",
            //   item.filingName,
            //   item.stateInfo,
            //   item.businessCategory
            // );
            const categoriesState = item.businessCategory.filter((ele) =>
              ele.state ? ele.state === item.stateInfo : ele
            );
            // console.log("stateCategories:", item.filingName, categoriesState);
            const categories = categoriesState
              .map((ele) => ele.businessCategoryName)
              .join(", ");

            // console.log(
            //   `filingMasters ${item.filingMasters} cat...`,
            //   categories
            // );
            return {
              ...item,
              stateCategories: categories,
              stateList: _stateList,
            };
          }
        });
      const _jusrisdictions = updList.map((item) =>
        item.juristiction ? item.juristiction : ""
      );
      const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
      // console.log("@@Unique Jurisdiction:", _uniqJurisdictions);
      setJurisdiction(_uniqJurisdictions);
      // let pendingList = updList.filter(
      //   (ele) => ele.workflowStatus === "Pending"
      // );

      let tableList = value === "1" ? pendingTableData : completedTableData;
      console.log("modifyList updCustomers", updList, tableList);

      let updFilingMastersList;
      if (direction === "down") {
        //scroll down
        if (tableList.length >= 200) {
          //list > 200

          //slice 2 half of list
          let unloadList = ogList.slice(
            ogList.length - pageSize,
            ogList.length
          );
          // console.log("modifyList dw unloadList", unloadList);

          //add new data
          updFilingMastersList = [...unloadList, ...updList];
          // console.log("modifyList dw updFilingMastersList", updFilingMastersList);

          // let list = updFilingMastersList.map((ele)=>ele)
          // setClientFiling(updFilingMastersList);
        } else {
          //list < 200

          // add new data to list
          updFilingMastersList = [...ogList, ...updList];
          // console.log("modifyList dw updFilingMastersList", updFilingMastersList);

          // setClientFiling(updFilingMastersList);
        }
      } else if (direction === "up") {
        //scroll up
        if (tableList.length >= 200) {
          //list > 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updList, ...unloadList];
          // console.log("modifyList up updFilingMastersList", updFilingMastersList);

          // setClientFiling(updFilingMastersList);
        } else {
          //list < 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updList, ...unloadList];
          // console.log("modifyList up updFilingMastersList", updFilingMastersList);

          // setClientFiling(updFilingMastersList);
        }
      }

      if (value === "2" && selectedYear !== "") {
        setCompletedTableData(() => updFilingMastersList);
        setFilingMasterApprovalsCompleted(updFilingMastersList)
      } else {
        setInitialData(() => updFilingMastersList);
        setData(() => updFilingMastersList);
        setPendingTableData(() => updFilingMastersList);
        setFilingMasterApprovalsPending(updFilingMastersList)
      }

      setRefreshRecordList(false);
      setLazyLoading(false);
      setIsLoading(false);
      return updFilingMastersList;
    } else {
      //list empty
      // console.log("modifyList empty",list)
      setRefreshRecordList(false);
      setLazyLoading(false);
      setIsLoading(false);
      setFilingMasterApprovalsCompleted([])
      setFilingMasterApprovalsPending([])
      // setClientFiling([]);
      return [];
    }
  };

  const onFilterChange = async (e) => {
    console.log("onFilterChange props", e.filters);
    let filterChange = [];

    if (e.filters.global.value !== "" && e.filters.global.value !== null) {
      //global filter
      filterChange.push({
        name: `SearchByFilingName`,
        val: e.filters.global.value,
        searchType: `FilingNameSearchType`,
        searchTypeVal: getSearchType("contains"),
      });
    } else {
      //map filter event
      Object.keys(e.filters).forEach((col) => {
        // console.log("onFilterChange col", col, e.filters[col]);
        if (
          e.filters[col].constraints &&
          e.filters[col].constraints[0].value !== null
        ) {
          if (col === "stateList") {
            Array.isArray(e.filters[col].constraints[0].value)
              ? e.filters[col].constraints[0].value.map((ele, i) => {
                let changeItem = {
                  name: `SearchByStateInfo`,
                  val: getStateCode(ele),
                };
                if (i + 1 === e.filters[col].constraints[0].value.length) {
                  changeItem.searchType = `StateInfoSearchType`;
                  changeItem.searchTypeVal = getSearchType(
                    e.filters[col].constraints[0].matchMode
                  );
                }
                filterChange.push(changeItem);
              })
              : filterChange.push({
                name: `SearchByStateInfo`,
                val: getStateCode(e.filters[col].constraints[0].value),
                searchType: `StateInfoSearchType`,
                searchTypeVal: getSearchType(
                  e.filters[col].constraints[0].matchMode
                ),
              });
          } else if (col === "jsicontactName") {
            filterChange.push({
              name: `SearchByJSIStaff`,
              val: e.filters[col].constraints[0].value,
              searchType: `JSIStaffSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "federalCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `WhoMustFileFederal`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileFederalSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "stateCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              let changeItem = {
                name: `WhoMustFileInState`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileInStateSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else {
            filterChange.push({
              name: `SearchBy${col}`,
              val: e.filters[col].constraints[0].value,
              searchType: `${col}SearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          }
        }
      });
    }
    console.log("onFilterChange filterChange", filterChange);

    //set filter
    handleSetFilterVariable(filterChange);
  };

  const formatFilterDate = (date) => {
    // console.log("formatFilterDate", date);
    let getDate = new Date(date);
    let formatDate = `${getDate.getFullYear()}-${getDate.getMonth() + 1
      }-${getDate.getDate()}`;
    // console.log("formatFilterDate formatDate", getDate.getFullYear(), formatDate);
    return formatDate;
  };

  const getStateCode = (stateName) => {
    // console.log("getStateCode", stateName, states);
    const state = states.find((s) => s.state === stateName);
    return state ? state.code : stateName;
  };

  const matchModeVal = [
    { name: "contains", val: "Contains" },
    { name: "in", val: "Contains" },
    { name: "notContains", val: "NotContains" },
    { name: "equals", val: "EqualTo" },
    { name: "dateIs", val: "DateIs" },
    { name: "dateIsNot", val: "DateIsNot" },
    { name: "dateBefore", val: "DateIsBefore" },
    { name: "dateAfter", val: "DateIsAfter" },
  ];

  const getSearchType = (matchMode) => {
    // console.log("getSearchType props", matchMode);
    return matchModeVal.find((ele) => ele.name === matchMode).val;
  };

  //set filter
  const handleSetFilterVariable = (filterChange) => {
    console.log("handleSetFilterVariable props", filterChange);
    setIsFilterChange(() => filterChange);
    setRecordsInView(0);
    setIsLoading(true);
    setInitialData([]);
    setData([]);
    setPendingTableData([]);
    setCompletedTableData([]);
  };

  const handleSort = async (e) => {
    console.log("handleSort props", e, e.sortField);
    if (e.sortField !== "") {
      setSortingData(() => ({
        sortBy: e.sortField,
        sortOrder: e.sortOrder,
      }));
      setRecordsInView(0);
      setIsLoading(true);
      setInitialData([]);
      setData([]);
      setPendingTableData([]);
      setCompletedTableData([]);
    }
  };

  const getSortData = (val) => {
    console.log("getSortData props", val);
    if (val === "federalCategories" || val === "stateCategories") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "BusinessCategory",
        SortOrderParameter: "SortOrder",
      };
    } else {
      return {
        SortByParameter: "SortBy",
        SortByVal: val.charAt(0).toUpperCase() + val.slice(1),
        SortOrderParameter: "SortOrder",
      };
    }
  };

  useEffect(() => {
    // console.log("filter/sort change", isFilterChange, sortingData, lazyLoading );
    //on filter/sort change
    if (!lazyLoading && !refreshRecordList) {
      setLazyLoading(true);
      const sortAndFilterApiCall = async () => {
        let records = await loadRecordsList(0, 100, "down");
        setRecordsInView(1);
        console.log("recordsCheck", records);
        setIsLoading(false);
      };
      sortAndFilterApiCall();
    }
  }, [isFilterChange, sortingData]);

  const refreshRecords = async () => {
    console.log("refreshRecords");

    //Virtual scroll callback
    const vScroll =
      value === "1"
        ? dt.current.getVirtualScroller()
        : dtComp.current.getVirtualScroller();
    let range = vScroll.getRenderedRange();
    console.log("refreshRecords vScroll", vScroll, range);

    let records
    console.log("refreshRecords refreshDetails", refreshDetails);
    if (refreshDetails?.pageInView || refreshDetails?.noOfPages) {
      let first = refreshDetails.pageInView * pageSize
      records = await loadRecordsList(first, first + refreshDetails?.noOfPages * pageSize, "down");
    } else {
      records = await loadRecordsList(0, pageSize, "down");
    }

    if (records) {
      console.log("refreshRecords refreshDetails", refreshDetails);
      if (refreshDetails?.viewport && refreshDetails?.noOfPages) {
        setRecordsInView((old) => old + refreshDetails?.noOfPages);
        // setRefreshDetails(null)
      } else {
        setRecordsInView((old) => old + 1);
      }
      setLazyLoading(false)
    }
  };

  useEffect(() => {
    console.log("refreshRecordList,", refreshRecordList, !lazyLoading);
    if (refreshRecordList && !lazyLoading) {
      setLazyLoading(true);
      refreshRecords();
    }
  }, [refreshRecordList]);

  useEffect(() => {
    console.log("Total Records updated:", totalRecords);
  }, [totalRecords]);

  useEffect(() => {
    console.log("recordsInView,", recordsInView);
  }, [recordsInView]);

  useEffect(() => {
    console.log("pendingTableData,", pendingTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pendingTableData,
    pendingTableData.length,
    JSON.stringify(pendingTableData),
  ]);

  useEffect(() => {
    console.log("filingMasterApprovals,", filingMasterApprovals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filingMasterApprovals]);

  useEffect(() => {
    console.log("filingMasterApprovalsCompleted,", filingMasterApprovalsCompleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filingMasterApprovalsCompleted]);

  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log("lazyLoading", lazyLoading);
  }, [lazyLoading]);

  return (
    <Fragment>
      <Grid sx={{ height: "100%", width: "93%" }}>
        <ApproveFilingMaster
          show={showFilter}
          dockAt="right"
          drafts={selectedFiling}
          handleClose={closeFilter}
          updateSelectedWorkflow={updateSelectedWorkflow}
        />

        <Grid sx={{ height: "100%" }}>
          {/* {filingMasterLoaded ? (
           
          ) : (
           <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "50px",
              }}
            >
              <div>
                <CircularProgress />
              </div>
            </Box>  */}
          <div
            className="card"
            style={{
              maxWidth: "90vw",
              padding: "1rem",
              margin: "1rem",
              background: "#FFF",
              borderRadius: "2%",
            }}
            class="masterFilingApproval"
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange}>
                  <Tab label="Pending" value="1" disabled={isLoading} />
                  <Tab label="Completed" value="2" disabled={isLoading} />
                </TabList>
              </Box>
              <TabPanel value="1">
                <DataTable
                  ref={dt}
                  key="pending"
                  className="frozen-filing-approval-pending"
                  scrollable
                  resizableColumns
                  loading={isLoading}
                  showGridlines
                  value={filingMasterApprovals}
                  selection={selectedFiling}
                  onSelectionChange={(e) => setSelectedFiling(e.value)}
                  dataKey="workflowId"
                  style={{ minHeight: "50vh" }}
                  onRowSelect={onRowSelect}
                  onRowToggle={(e) => rowExpand(e)}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={expandedRows}
                  // paginator={!viewAll}
                  // rows={viewAll ? clients.length : 10}
                  // rowsPerPageOptions={[5, 10, 25, 50]}
                  filters={filters}
                  filterMode="multiple"
                  globalFilterFields={["filingName"]}
                  header={header}
                  virtualScrollerOptions={{
                    // lazy: true,
                    onScroll: loadDataLazy,
                    itemSize: 70,
                    delay: 0,
                    scrollHeight: "550px",
                    appendOnly: true,
                    // showLoader: true,
                    // loading: lazyLoading,
                    // loadingTemplate,
                  }}
                  scrollHeight="550px"
                  onFilter={(e) => onFilterChange(e)}
                  sortField={sortingData.sortBy}
                  sortOrder={sortingData.sortOrder}
                  onSort={(e) => handleSort(e)}
                >
                  <Column expander={true} style={{ width: "5rem" }} />
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  >
                    Select
                  </Column>
                  <Column
                    headerStyle={{ width: "3rem" }}
                    header="Indicators"
                    body={(rowData) => (
                      <>
                        <Stack direction="row">
                          <CustomCommentButton rowData={rowData} />
                          <FilingAttachmentButton rowData={rowData} />
                        </Stack>
                      </>
                    )}
                  ></Column>
                  <Column
                    field="approverName"
                    header="Approver Name"
                    sortable
                    frozen
                    filter
                    filterField="approverName"
                    filterPlaceholder="Search by Approver Name"
                    showAddButton={false}
                    showFilterOperator={false}
                    style={{ minWidth: "12rem" }}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  <Column
                    field="filingName"
                    header="Filing Name"
                    sortable
                    frozen
                    className="filingNameColumn"
                    filter
                    filterField="filingName"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder="Search by Filing Name"
                    style={{ minWidth: "12rem", maxWidth: "25rem" }}
                    body={(rowData) => cellListDisplayName(rowData)}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  {/* <Column
                   field='filingDescription'
                   header='Filing Description'
                   sortable
                   filter
                   filterPlaceholder='Search by Description'
                   style={{ minWidth: "15rem" }}
                 ></Column> */}
                  <Column
                    field="recentDate"
                    header="Time Stamp"
                    sortable
                    style={{ minWidth: "15rem" }}
                    frozen

                    body={displayTimeStamp}
                  ></Column>
                  <Column
                    field="filingFrequency"
                    header="Filing Frequency"
                    filter
                    filterField="filingFrequency"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder="Search by Frequency"
                    style={{ minWidth: "15rem" }}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>

                  {/* <Column
                   field='dueDayofFrequency'
                   header='Due Date Offset'
                   filter
                   filterPlaceholder='Search by Frequency'
                   style={{ minWidth: "15rem" }}
                 ></Column> */}
                  <Column
                    field="stateList"
                    header="State"
                    filter
                    filterField="stateList"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder="Search by State"
                    filterElement={stateRowFilterTemplate}
                    onFilterClear={() => setFederalFilterSelectedValues([])}
                    style={{ minWidth: "12rem" }}
                    className="column-small"
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    onFilterMatchModeChange={() =>
                      setFederalFilterSelectedValues([])
                    }
                  ></Column>
                  <Column
                    field="ruleInfo"
                    header="Rule Info"
                    filter
                    showFilterMenu={true}
                    filterField="ruleInfo"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder="Search by Rule Info"
                    style={{ minWidth: "15rem", maxWidth: "15rem" }}
                    body={(rowData) => cellListDisplayRuleInfo(rowData)}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  <Column
                    field="required"
                    header="Required"
                    filter
                    filterField="required"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder="Search by Required"
                    showFilterMenu={true}
                    style={{ minWidth: "13rem" }}
                    className="column-small"
                    showFilterMatchModes={false}
                    filterElement={requiredRowFilterTemplate}
                    onFilterClear={() => setFederalFilterSelectedValues([])}
                  ></Column>

                  <Column
                    field="federalCategories"
                    header="Who Must File Federal"
                    // sortable
                    filter
                    filterField="federalCategories"
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMenu={true}
                    body={(rowData) =>
                      CellListDisplayFederal(rowData, "federalCategories")
                    }
                    filterElement={(options) =>
                      federalFilterElement(
                        options,
                        "federalCategories",
                        "pending"
                      )
                    }
                    filterPlaceholder="Search by Category"
                    onFilterClear={() => setFederalFilterSelectedValues([])}
                    style={{ minWidth: "20rem", maxWidth: "20rem" }}
                    // showClearButton={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  <Column
                    field="stateCategories"
                    header="Who Must File in State"
                    // sortable
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMenu={true}
                    filterField="stateCategories"
                    body={(rowData) =>
                      CellListDisplayFederal(rowData, "stateCategories")
                    }
                    filterElement={(options) =>
                      federalFilterElement(
                        options,
                        "stateCategories",
                        "pending"
                      )
                    }
                    filterPlaceholder="Search by Category"
                    onFilterClear={() => {
                      setFederalFilterSelectedValues([]);
                      setPendingTableData(
                        data.filter((ele) => ele.workflowStatus === "Pending")
                      );
                    }}
                    style={{ minWidth: "20rem", maxWidth: "20rem" }}
                    //  showClearButton={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    onFilterMatchModeChange={() => {
                      setFederalFilterSelectedValues([]);
                      setPendingTableData(
                        data.filter((ele) => ele.workflowStatus === "Pending")
                      );
                    }}
                  ></Column>
                  {/* <Column
                   field='jsidept'
                   header='JSI Dept'
                   filter
                   filterPlaceholder='Search by dept'
                   style={{ minWidth: "15rem" }}
                 ></Column> */}
                  <Column
                    field="jsicontactName"
                    header="JSI Staff"
                    filter
                    filterField="jsicontactName"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder="Search by JSI Staff"
                    style={{ minWidth: "13rem" }}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  {/* <Column
                   field='jsicontactEmail'
                   header='JSI Contact Email'
                   filter
                   filterPlaceholder='Search by contactEmail'
                   style={{ minWidth: "15rem" }}
                 ></Column> */}
                  <Column
                    field="juristiction"
                    header="Jurisdiction"
                    sortable
                    filterField="juristiction"
                    style={{ minWidth: "14rem" }}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                    filterElement={jurisdictionRowFilterTemplate}
                    onFilterMatchModeChange={() =>
                      setFederalFilterSelectedValues([])
                    }
                    onFilterClear={() => setFederalFilterSelectedValues([])}
                  ></Column>
                  <Column
                    field="notes"
                    header="Notes"
                    style={{ minWidth: "21rem", maxWidth: "21rem" }}
                    body={(rowData) => cellListDisplayNotes(rowData)}
                  ></Column>
                </DataTable>
              </TabPanel>
              <TabPanel value="2">
                <DataTable
                  ref={dtComp}
                  key="completed"
                  scrollable
                  className="frozen-filing-approval-completed"
                  resizableColumns
                  loading={isLoading}
                  showGridlines
                  value={filingMasterApprovalsCompleted}
                  selection={selectedFiling}
                  onSelectionChange={(e) => setSelectedFiling(e.value)}
                  dataKey="workflowId"
                  style={{ minHeight: "50vh" }}
                  onRowSelect={onRowSelect}
                  onRowToggle={(e) => rowExpand(e)}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={expandedRows}
                  // paginator={!viewAll}
                  // rows={viewAll ? clients.length : 10}
                  // rowsPerPageOptions={[5, 10, 25, 50]}
                  filters={filters}
                  filterMode="multiple"
                  globalFilterFields={["filingName"]}
                  header={completedHeader}
                  virtualScrollerOptions={{
                    // lazy: true,
                    onScroll: loadDataLazy,
                    itemSize: 70,
                    delay: 0,
                    scrollHeight: "550px",
                    appendOnly: true,
                    // showLoader: true,
                    // loading: lazyLoading,
                    // loadingTemplate,
                  }}
                  scrollHeight="550px"
                  onFilter={(e) => onFilterChange(e)}
                  sortField={sortingData.sortBy}
                  sortOrder={sortingData.sortOrder}
                  onSort={(e) => handleSort(e)}
                >
                  <Column expander={true} style={{ width: "5rem" }} />
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  >
                    Select
                  </Column>
                  <Column
                    headerStyle={{ width: "3rem" }}
                    header="Indicators"
                    body={(rowData) => (
                      <>
                        <Stack direction="row">
                          <CustomCommentButton rowData={rowData} />
                          <FilingAttachmentButton rowData={rowData} />
                        </Stack>
                      </>
                    )}
                  ></Column>
                  <Column
                    field="filingName"
                    header="Filing Name"
                    sortable
                    frozen
                    className="filingNameColumn"
                    filter
                    filterField="filingName"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder="Search by Filing Name"
                    style={{ minWidth: "12rem", maxWidth: "25rem" }}
                    body={(rowData) => cellListDisplayName(rowData)}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  {/* <Column
                   field='filingDescription'
                   header='Filing Description'
                   sortable
                   filter
                   filterPlaceholder='Search by Description'
                   style={{ minWidth: "15rem" }}
                 ></Column> */}
                  <Column
                    field="filingFrequency"
                    header="Filing Frequency"
                    filter
                    filterField="filingFrequency"
                    showAddButton={false}
                    showFilterOperator={false}
                    frozen
                    style={{ minWidth: "12rem" }}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  {/* <Column
                   field='dueDayofFrequency'
                   header='Due Date Offset'
                   filter
                   filterPlaceholder='Search by Frequency'
                   style={{ minWidth: "15rem" }}
                 ></Column> */}
                  <Column
                    field="recentDate"
                    header="Time Stamp"
                    sortable
                    style={{ minWidth: "15rem" }}
                    frozen
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    body={displayTimeStamp}
                  ></Column>
                  <Column
                    field="stateList"
                    header="State"
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterField="stateList"
                    filterPlaceholder="Search by State"
                    filterElement={stateRowFilterTemplate}
                    onFilterClear={() => setFederalFilterSelectedValues([])}
                    style={{ minWidth: "11rem" }}
                    className="column-small"
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    onFilterMatchModeChange={() =>
                      setFederalFilterSelectedValues([])
                    }
                  ></Column>
                  <Column
                    field="ruleInfo"
                    header="Rule Info"
                    filter
                    filterField="ruleInfo"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder="Search by Rule Info"
                    style={{ minWidth: "15rem", maxWidth: "15rem" }}
                    body={(rowData) => cellListDisplayRuleInfo(rowData)}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  <Column
                    field="required"
                    header="Required"
                    filter
                    filterField="required"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterPlaceholder="Search by Required"
                    style={{ minWidth: "13rem" }}
                    className="column-small"
                    showFilterMatchModes={false}
                    filterElement={requiredRowFilterTemplate}
                    onFilterClear={() => setFederalFilterSelectedValues([])}
                  ></Column>
                  <Column
                    field="workflowStatus"
                    header="Status"
                    filter
                    filterField="workflowStatus"
                    showAddButton={false}
                    showFilterOperator={false}
                    style={{ minWidth: "15rem" }}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  <Column
                    field="federalCategories"
                    header="Who Must File Federal"
                    // sortable
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterField="federalCategories"
                    body={(rowData) =>
                      CellListDisplayFederal(rowData, "federalCategories")
                    }
                    filterElement={(options) =>
                      federalFilterElement(
                        options,
                        "federalCategories",
                        "completed"
                      )
                    }
                    showFilterMenu={true}
                    onFilterClear={() => {
                      setFederalFilterSelectedValues([]);
                      setCompletedTableData(
                        data
                          .filter((ele) => ele.workflowStatus !== "Pending")
                          .filter(
                            (value, index, self) =>
                              index ===
                              self.findIndex(
                                (t) => t.filingId === value.filingId
                              )
                          )
                      );
                    }}
                    // showClearButton={false}
                    filterPlaceholder="Search by Category"
                    style={{ minWidth: "20rem", maxWidth: "20rem" }}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    onFilterMatchModeChange={() => {
                      setFederalFilterSelectedValues([]);
                      setCompletedTableData(
                        data
                          .filter((ele) => ele.workflowStatus !== "Pending")
                          .filter(
                            (value, index, self) =>
                              index ===
                              self.findIndex(
                                (t) => t.filingId === value.filingId
                              )
                          )
                      );
                    }}
                  ></Column>
                  <Column
                    field="stateCategories"
                    header="Who Must File in State"
                    // sortable
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    filterField="stateCategories"
                    body={(rowData) =>
                      CellListDisplayFederal(rowData, "stateCategories")
                    }
                    filterElement={(options) =>
                      federalFilterElement(
                        options,
                        "stateCategories",
                        "completed"
                      )
                    }
                    showFilterMenu={true}
                    onFilterClear={() => {
                      setFederalFilterSelectedValues([]);
                      setCompletedTableData(
                        data
                          .filter((ele) => ele.workflowStatus !== "Pending")
                          .filter(
                            (value, index, self) =>
                              index ===
                              self.findIndex(
                                (t) => t.filingId === value.filingId
                              )
                          )
                      );
                    }}
                    // showClearButton={false}
                    filterPlaceholder="Search by Category"
                    style={{ minWidth: "20rem", maxWidth: "20rem" }}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    onFilterMatchModeChange={() => {
                      setFederalFilterSelectedValues([]);
                      setCompletedTableData(
                        data
                          .filter((ele) => ele.workflowStatus !== "Pending")
                          .filter(
                            (value, index, self) =>
                              index ===
                              self.findIndex(
                                (t) => t.filingId === value.filingId
                              )
                          )
                      );
                    }}
                  ></Column>
                  {/* <Column
                   field='jsidept'
                   header='JSI Dept'
                   filter
                   filterPlaceholder='Search by dept'
                   style={{ minWidth: "15rem" }}
                 ></Column> */}
                  <Column
                    field="jsicontactName"
                    header="JSI Staff"
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMenu={true}
                    filterPlaceholder="Search by JSI Staff"
                    filterField="jsicontactName"
                    style={{ minWidth: "15rem" }}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                  ></Column>
                  {/* <Column
                   field='jsicontactEmail'
                   header='JSI Contact Email'
                   filter
                   filterPlaceholder='Search by contactEmail'
                   style={{ minWidth: "15rem" }}
                 ></Column> */}
                  <Column
                    field="juristiction"
                    header="Jurisdiction"
                    sortable
                    filterField="juristiction"
                    style={{ minWidth: "14rem" }}
                    filter
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                    filterElement={jurisdictionRowFilterTemplate}
                    onFilterMatchModeChange={() =>
                      setFederalFilterSelectedValues([])
                    }
                    onFilterClear={() => setFederalFilterSelectedValues([])}
                  ></Column>
                  <Column
                    field="notes"
                    header="Notes"
                    style={{ minWidth: "21rem", maxWidth: "21rem" }}
                    body={(rowData) => cellListDisplayNotes(rowData)}
                  ></Column>
                </DataTable>
              </TabPanel>
            </TabContext>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          height: "100%",
          width: "5%",
        }}
      >
        <div
          style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}
        >
          <Item
            sx={{
              height: "100%",
            }}
          >
            <ApproveToolbox
              isLoading={isLoading}
              addComment={addComment}
              approveDraft={approveDraft}
              isDisabled={isDisabled}
              displayAttachments={displayAttachments}
              displayHistory={displayHistory}
            />
          </Item>
        </div>
      </Grid>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {
        <FilingMasterWorkflowComments
          show={showComments}
          dockAt="right"
          workflow={selectedFiling[0]}
          rowData={selectedRowData}
          comments={comments}
          handleClose={closeComments}
          refreshList={handleRefreshList}
          value={value}
        />
      }
      {
        <FilingMasterReferenceDocument
          show={showAttachments}
          dockAt="right"
          filing={selectedFiling[0]}
          attachments={attachments}
          handleClose={closeAttachments}
        />
      }
      {
        <FilingMasterApprovalHistory
          show={showHistory}
          dockAt="right"
          tracker={selectedFiling[0]}
          activityHistory={activityHistory}
          commentActivityHistory={commentActivityHistory}
          handleClose={closeHistory}
        />
      }
    </Fragment>
  );
};
export default ApprovalList;
