import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Container } from "@mui/system";
import { useEffect } from "react";
import "./Reminder.css";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import AuthContext from "../../context/auth/AuthContext";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import usePermission from "../../usePermission";
import axios from "axios";
import useDialog from "../common/UseDialog";
import { InputSwitch } from "primereact/inputswitch";
import { CircularProgress } from "@mui/material";
import { Fragment } from "react";
import Loader from "../common/Loader";
import { Button as PrimeButton } from "primereact/button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ReminderHistory from "./ReminderHistory";
import ReminderToolbox from "./ReminderToolbox";

import {
  Chip,
  Menu,
  MenuItem,
  Paper,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";
//import {AddCircleIcon} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PersonIcon from "@mui/icons-material/Person";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function Reminder() {
  const filingMasterContext = React.useContext(ClientFilingMasterContext);
  const {
    fetchConfigurations,
    reminderConfig,
    updateReminderConfiguration,
    createReminderConfiguration,
  } = filingMasterContext;
  const authContext = React.useContext(AuthContext);
  const { authUser, roleType } = authContext;

  const navigate = useNavigate();
  const backToHome = () => {
    navigate("/");
  };
  const [mailButtonStyle, setMailButtonStyle] = useState({
    backgroundColor: "#e0e0e0",
    borderRadius: "15px",
  });

  const [formValues, setFormValues] = useState({ Notifications: true });
  const [initialValues, setInitialValues] = useState({});
  const [initialFormValues, SetInitialFormValues] = useState({});
  const [selectedEmailscombained, setSelectedEmailsCombained] = useState([])
  const [isFieldModified, setIsFieldModified] = useState(false)
  const [isCreating, setIsCreating] = useState(true);
  const [remainingChars, setRemainingChars] = useState(8000);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [configurationList, setConfigurationList] = useState([]);
  const [configurationListNew, setConfigurationListNew] = useState([]);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataTableLoading, setIsDataTableLoading] = useState(true);
  const [placeHolderList, setPlaceHolderList] = useState([]);
  const [userNameList, setUserNameList] = useState([]);
  const [userGroupList, setUserGroupList] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  //cc
  const [placeHolderListCc, setPlaceHolderListCc] = useState([]);
  const [userNameListCc, setUserNameListCc] = useState([]);
  const [userGroupListCc, setUserGroupListCc] = useState([]);
  const [userRoleListCc, setUserRoleListCc] = useState([]);
  const [emailTaskIdValue, setEmailTaskIdValue] = useState(null);
  const [emailTaskNameList, setEmailTaskNameList] = useState([]);
  const [isRecipientValidation, setIsRecipientValidation] = useState(false);
  const [env, setEnv] = useState("");
  const [selectedEmail, setSelectedEmail] = useState(null)

  const [isEqual, setIsEqual] = useState(true)

  useEffect(() => {
    if (formValues.Mail_Message) {
      const charsUsed = formValues.Mail_Message.length;
      setRemainingChars(8000 - charsUsed);
    }
  }, [formValues.Mail_Message]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [initialEmailCCandTo, setInitialEmailCCandTo] = useState([])
  const [selectedPlaceHolderList, setSelectedPlaceHolderList] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState([]);
  const [selectedUserNameInput, setSelectedUserNameInput] = useState([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [selectedRoleList, setSelectedRoleList] = useState([]);
  const [selectedPlaceHolderListCc, setSelectedPlaceHolderListCc] = useState(
    []
  );
  const [selectedUserNameCc, setSelectedUserNameCc] = useState([]);
  const [selectedUserNameInputCc, setSelectedUserNameInputCc] = useState([]);
  const [selectedUserGroupCc, setSelectedUserGroupCc] = useState([]);
  const [selectedRoleListCc, setSelectedRoleListCc] = useState([]);
  //const [selectedReminderName, setSelectedReminderName] = useState('');

  const [emailInput, setEmailInput] = useState("");
  const [emailInputCc, setEmailInputCc] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailErrorCc, setEmailErrorCc] = useState("");

  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);



  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenMenuCc = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseMenuCc = () => {
    setAnchorE2(null);
  };
  // const handleReminderName = (event) => {
  //   setSelectedReminderName(event.target.value)

  // };
  // console.log("selectedReminderName", selectedReminderName);
  const handleSelectPlaceHolderList = (value) => {
    setIsRecipientValidation(false);
    console.log("Value#", value);
    if (!selectedPlaceHolderList.includes(value)) {
      setSelectedPlaceHolderList([...selectedPlaceHolderList, value]);
      setSelectedEmailsCombained([...selectedEmailscombained, value]);
    }
  };
  const handleSelectPlaceHolderListCc = (value) => {
    console.log("Value#", value);
    if (!selectedPlaceHolderListCc.includes(value)) {
      setSelectedPlaceHolderListCc([...selectedPlaceHolderListCc, value]);
      setSelectedEmailsCombained([...selectedEmailscombained, value]);
    }
  };

  const handleSelectRoleName = (value) => {
    setIsRecipientValidation(false);
    console.log("Value#", value);
    if (!selectedRoleList.includes(value)) {
      console.log("value#@", value);
      setSelectedRoleList([...selectedRoleList, value]);
      setSelectedEmailsCombained([...selectedEmailscombained, value]);
    }
  };
  const handleSelectRoleNameCc = (value) => {
    console.log("Value#", value);
    if (!selectedRoleListCc.includes(value)) {
      console.log("value#@", value);
      setSelectedRoleListCc([...selectedRoleListCc, value]);
      setSelectedEmailsCombained([...selectedEmailscombained, value]);
    }
  };

  const handleSelectedValueUserName = (user) => {
    console.log("Value#", user);
    setIsRecipientValidation(false);
    console.log("selectedUserNameOnchange", selectedUserName);
    if (
      !selectedUserName.some(
        (selectedUser) => selectedUser.userEmailId === user.userEmailId
      )
    ) {
      const selectedUser = {
        userEmailId: user.userEmailId,
        placeHolderName: user.placeHolderName,
      };
      console.log("selectedUserVar", selectedUser);
      setSelectedUserName([...selectedUserName, selectedUser]);
      setSelectedEmailsCombained([...selectedEmailscombained, selectedUser])
    }
  };

  const handleSelectedValueUserNameCc = (user) => {
    console.log("Value#", user);
    if (
      !selectedUserNameCc.some(
        (selectedUser) => selectedUser.userEmailId === user.userEmailId
      )
    ) {
      const selectedUser = {
        userEmailId: user.userEmailId,
        placeHolderName: user.placeHolderName,
      };
      console.log("selectedUserVar", selectedUser);
      setSelectedUserNameCc([...selectedUserNameCc, selectedUser]);
      setSelectedEmailsCombained([...selectedEmailscombained, selectedUser])

    }
  };
  const handleGroupList = (value) => {
    setIsRecipientValidation(false);
    console.log("Value#", value);
    if (!selectedUserGroup.includes(value)) {
      setSelectedUserGroup([...selectedUserGroup, value]);
    }
  };
  const handleGroupListCc = (value) => {
    console.log("Value#", value);
    if (!selectedUserGroupCc.includes(value)) {
      setSelectedUserGroupCc([...selectedUserGroupCc, value]);
    }
  };
  const handleEmailInputChange = (event) => {
    const { value } = event.target;
    setEmailInput(value);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (value && !emailRegex.test(value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };
  const handleEmailInputChangeCc = (event) => {
    const { value } = event.target;
    setEmailInputCc(value);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (value && !emailRegex.test(value)) {
      setEmailErrorCc("Invalid email address");
    } else {
      setEmailErrorCc("");
    }
  };
  // const handleAddEmailChip = () => {
  //   if (emailInput.trim() !== "" && !selectedUserName.includes(emailInput)) {
  //     setSelectedUserName([...selectedUserName, emailInput]);
  //     setEmailInput("");
  //   }
  // };
  const handleAddEmailChip = () => {
    console.log("emailInput1", emailInput);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailInput.trim() !== "") {
      console.log("emailInput2", emailInput);
      setIsRecipientValidation(false);
      if (emailRegex.test(emailInput)) {
        if (!selectedUserNameInput.includes(emailInput)) {
          // setSelectedUserName([...selectedUserName, emailInput]);
          setSelectedUserNameInput([...selectedUserNameInput, emailInput]);
          setSelectedEmailsCombained([...selectedEmailscombained, emailInput]);
          setEmailInput("");
        } else {
          setEmailError("Email already in the list");
        }
      } else {
        setEmailError("Invalid email address");
      }
    }
  };
  const handleAddEmailChipCc = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailInputCc.trim() !== "") {
      if (emailRegex.test(emailInputCc)) {
        if (!selectedUserNameInputCc.includes(emailInputCc)) {
          //  setSelectedUserNameCc([...selectedUserNameCc, emailInputCc]);
          setSelectedUserNameInputCc([
            ...selectedUserNameInputCc,
            emailInputCc,
          ]);
          setSelectedEmailsCombained([
            ...selectedEmailscombained,
            emailInputCc,
          ]);
          setEmailInputCc("");
        } else {
          setEmailErrorCc("Email already in the list");
        }
      } else {
        setEmailErrorCc("Invalid email address");
      }
    }
  };
  const handleRemoveValue = (value) => {
    console.log("value$", value);
    setSelectedUserName(selectedUserName.filter((item) => item !== value));
    setSelectedEmailsCombained(
      selectedEmailscombained.filter((item) => item !== value)
    );
  };
  const handleRemoveValueInput = (value) => {
    console.log("value$", value);
    setSelectedUserNameInput(
      selectedUserNameInput.filter((item) => item !== value)
    );
    setSelectedEmailsCombained(
      selectedEmailscombained.filter((item) => item !== value)
    );
  };
  const handleRemoveUserGroup = (value) => {
    console.log("value$", value);
    setSelectedUserGroup(selectedUserGroup.filter((item) => item !== value));
  };
  const handleRemoveValuePlace = (value) => {
    console.log("value$", value);
    setSelectedPlaceHolderList(
      selectedPlaceHolderList.filter((item) => item !== value)
    );
    setSelectedEmailsCombained(
      selectedEmailscombained.filter((item) => item !== value)
    );
  };
  const handleRemoveValueRoles = (value) => {
    console.log("value$", value);
    setSelectedRoleList(selectedRoleList.filter((item) => item !== value));
    setSelectedEmailsCombained(selectedEmailscombained.filter((item) => item !== value));
  };
  const handleRemoveUserGroupCc = (value) => {
    console.log("value$", value);
    setSelectedUserGroupCc(
      selectedUserGroupCc.filter((item) => item !== value)
    );
  };
  const handleRemoveValuePlaceCc = (value) => {
    console.log("value$", value);
    setSelectedPlaceHolderListCc(
      selectedPlaceHolderListCc.filter((item) => item !== value)
    );
    setSelectedEmailsCombained(
      selectedEmailscombained.filter((item) => item !== value)
    );
  };
  const handleRemoveValueRolesCc = (value) => {
    console.log("value$", value);
    setSelectedRoleListCc(selectedRoleListCc.filter((item) => item !== value));
    setSelectedEmailsCombained(selectedEmailscombained.filter((item) => item !== value));
  };
  const handleRemoveValueCc = (value) => {
    console.log("value$", value);
    setSelectedUserNameCc(selectedUserNameCc.filter((item) => item !== value));
    setSelectedEmailsCombained(selectedEmailscombained.filter((item) => item !== value));
  };
  const handleRemoveValueInputCc = (value) => {
    console.log("value$", value);
    setSelectedUserNameInputCc(
      selectedUserNameInputCc.filter((item) => item !== value)
    );
    setSelectedEmailsCombained(
      selectedEmailscombained.filter((item) => item !== value)
    );
  };

  //   console.log("selectedValEmail##", selectedUserName);
  //   console.log("selectedUserGroup##", selectedUserGroup);
  //   console.log("selectedPlaceHolderList##", selectedPlaceHolderList);
  //   console.log("selectedRoleList##", selectedRoleList);
  //   console.log("CC-selectedValEmail##", selectedUserNameCc);
  //   console.log("CC-selectedUserGroup##", selectedUserGroupCc);
  //   console.log("CC-selectedPlaceHolderList##", selectedPlaceHolderListCc);
  //   console.log("CC-selectedRoleList##", selectedRoleListCc);
  // useEffect(async () => {

  //   const res = await axios.get(`${API_BASE_URL}/Application/ListEmails`);

  //   if (res) {
  //     setPlaceHolders(res.data.emailTaskName);

  //   }

  // }, []);

  const recordtypes = ["TrackNow", "PostAward"];

  const getPlaceHolders = async () => {
    console.log("SUC1", 1);
    try {
      let resData = await axios.get(
        `${API_BASE_URL}/Application/ListEmailMasterPlaceHolderNameValues`
      );
      console.log("resData", resData);
      let placeHolder = [
        {
          placeHolderId: 12,
          placeHolderName: "{{StaffAssigned}}",
          replaceValueField: "UserID",
          replaceValueTable: "CustomerFilingTrackerStaffAssigned",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-28T14:53:51.52",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
        {
          placeHolderId: 15,
          placeHolderName: "{{ClientFilingApprover}}",
          replaceValueField: "CurrentApproverID",
          replaceValueTable: "CustomerFilingMasterWorkflow",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-30T05:57:10.89",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
        {
          placeHolderId: 16,
          placeHolderName: "{{ClientFilingRequester}}",
          replaceValueField: "WorkflowInitiatorId",
          replaceValueTable: "CustomerFilingMasterWorkflow",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-30T05:57:10.89",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
        {
          placeHolderId: 17,
          placeHolderName: "{{FilingApprover}}",
          replaceValueField: "CurrentApproverID",
          replaceValueTable: "FilingMasterWorkflow",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-30T05:57:10.89",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
        {
          placeHolderId: 18,
          placeHolderName: "{{FilingRequester}}",
          replaceValueField: "WorkflowInitiatorId",
          replaceValueTable: "FilingMasterWorkflow",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-30T05:57:10.89",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
      ];
      let userGroup = resData.data.data.filter(
        (item) =>
          item.isReplacerName === false &&
          item.isGroupName === true &&
          item.isUsername === false &&
          item.isRoleName === false
      );
      let userName = resData.data.data.filter(
        (item) =>
          item.isReplacerName === false &&
          item.isGroupName === false &&
          item.isUsername === true &&
          item.isRoleName === false
      );
      let roleList = resData.data.data.filter(
        (item) =>
          item.isReplacerName === false &&
          item.isGroupName === false &&
          item.isUsername === false &&
          item.isRoleName === true
      );
      try {
        if (formValues.Client) {
          console.log("userNameTrue", userName);
          console.log(
            "formValues",
            formValues,
            "formValuesClient",
            formValues.Client.code
          );

          let updUserName = userName.filter((user) => {
            return (
              user &&
              user.roleName &&
              (user.roleName === "JSISME" ||
                user.roleName === "Admin" ||
                user.roleName === "JSISME-PostAward" ||
                user.roleName === "Admin-PostAward") &&
              user.customerID === null
            );
          });
          let filtCust = userName.filter(
            (cust) => cust.customerID === formValues.Client.code
          );
          const updUserList = [...updUserName, ...filtCust];
          const uniquePlaceHolderIds = new Set();
          userName = updUserList.filter((user) => {
            if (!uniquePlaceHolderIds.has(user.placeHolderId)) {
              uniquePlaceHolderIds.add(user.placeHolderId);
              return true;
            }
            return false;
          });
          console.log(
            "updUserName",
            updUserName,
            "filtCust",
            filtCust,
            "updUserList",
            updUserList,
            "uniquePlaceHolderIds",
            uniquePlaceHolderIds,
            "userName",
            userName
          );
        } else {
          console.log("userNameFalse", userName);
          console.log("formValues", formValues);
          let updUserName = userName.filter((user) => {
            return (
              user &&
              user.roleName &&
              (user.roleName === "JSISME" ||
                user.roleName === "Admin" ||
                user.roleName === "JSISME-PostAward" ||
                user.roleName === "Admin-PostAward") &&
              user.customerID === null
            );
          });
          // userName = updUserName;
          const uniquePlaceHolderIds = new Set();
          userName = updUserName.filter((user) => {
            if (!uniquePlaceHolderIds.has(user.placeHolderId)) {
              uniquePlaceHolderIds.add(user.placeHolderId);
              return true;
            }
            return false;
          });
          console.log("updUserName", updUserName, "userName", userName);
        }
      } catch (error) {
        console.log("FILTERERROR", error);
      }
      setUserNameList(userName);
      setPlaceHolderList(placeHolder);
      setUserGroupList(userGroup);
      setUserRoleList(roleList);
      setPlaceHolderListCc(placeHolder);
      setUserGroupListCc(userGroup);
      setUserNameListCc(userName);
      setUserRoleListCc(roleList);
      console.log("#Check-placeHolder", placeHolder);
      console.log("#Check-userGroup", userGroup);
      console.log("#Check-userName", userName);
      console.log("#Check-roleList", roleList);
    } catch (error) {
      console.log("FETCHERROR#", error);
    }
  };
  console.log("#Check-state-placeHolderList", placeHolderList);
  console.log("#Check-state-userGroupList", userGroupList);
  console.log("#Check-state-userNameList", userNameList);
  console.log("#Check-state-userRoleList", userRoleList);
  console.log("#Check-CC-state-placeHolderList", placeHolderListCc);
  console.log("#Check-CC-state-userGroupList", userGroupListCc);
  console.log("#Check-CC-state-userNameList", userNameListCc);
  console.log("#Check-CC-state-userRoleList", userRoleListCc);

  useEffect(() => {
    console.log("Fetch");
    getPlaceHolders();
  }, [formValues.Client]);

  // useEffect(() => {
  //   console.log("InitialValues", initialValues, "formValues", formValues);

  //   // Check each key in formValues
  //   for (let key in formValues) {
  //     if (initialValues[key] !== formValues[key]) {
  //       setIsEqual(false)
  //       break;
  //     }
  //   }
  // }, [formValues]);



  useEffect(() => {
    if (!emailTaskIdValue) {
      console.log("emailTaskIdValue", emailTaskIdValue);

      const filteredSelectedUserName = selectedUserName.filter((userName) => {
        const isEmailFormat = /\S+@\S+\.\S+/.test(userName.placeHolderName);
        const isNameInList = userNameList.some(
          (user) => user.placeHolderName === userName.placeHolderName
        );
        return isEmailFormat || isNameInList;
      });

      setSelectedUserName(filteredSelectedUserName);
      setSelectedEmailsCombained(filteredSelectedUserName);

      const filteredSelectedUserNameCc = selectedUserNameCc.filter(
        (userName) => {
          const isEmailFormat = /\S+@\S+\.\S+/.test(userName.placeHolderName);
          const isNameInList = userNameListCc.some(
            (user) => user.placeHolderName === userName.placeHolderName
          );
          return isEmailFormat || isNameInList;
        }
      );
      setSelectedUserNameCc(filteredSelectedUserNameCc);
      setSelectedEmailsCombained(filteredSelectedUserNameCc);

      console.log(
        "filteredSelectedUserName",
        filteredSelectedUserName,
        "filteredSelectedUserNameCc",
        filteredSelectedUserNameCc
      );
    }
  }, [formValues.Client, userNameList]);

  const MailFields = [
    "Mail_Subject",
    "Mail_Message",
    "Mail_ReminderBefore",
    "Mail_ReminderFrequency",
  ];
  const SMSFields = [
    "SMS_Subject",
    "SMS_Message",
    "SMS_ReminderBefore",
    "SMS_ReminderFrequency",
  ];

  const initMailForm = {
    Mail_Subject: "",
    Mail_Message: "",
    Mail_ReminderBefore: "",
    Mail_ReminderFrequency: "",
  };

  const envUrls = {
    Uat: "jsi-tracknow-cert.azurewebsites.net",
    Qa: "jsi-tracknow-test.azurewebsites.net",
    Dev: "jsi-tracknow-dev.azurewebsites.net",
    Prod: "tracknow.jsiportal.com",
  };
  const getEnvConfigs = async () => {
    try {
      const envRes = await axios.get(
        `${API_BASE_URL}/Application/ListappconfigurationEnvironment`
      );
      console.log("resenv", envRes, "data", envRes.data.data);
      const envValue = envRes.data.data[0].environment;
      console.log("envValue", envValue);
      setEnv(envValue);
      console.log("envValue", envValue, "ENVURL", envUrls);
    } catch (error) {
      console.error("EnvError", error);
    }
  };
  const getConfigs = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}/Application/ListEmails`);
      const filteredReminder = res.data.data.filter(
        (item) => item.isReminder === true
      );
      const resEmailTaskName = filteredReminder.map(
        (item) => item.emailTaskName
      );
      console.log("filteredReminder", filteredReminder);
      setEmailTaskNameList(resEmailTaskName);
      SetInitialFormValues(filteredReminder);
      setConfigurationListNew(filteredReminder);
      console.log("resData1", filteredReminder);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDataTableLoading(false);
    }
  };
  console.log("configList", configurationListNew);

  const cellNotifications = (row) => {
    console.log("cellNotifications", row.reminderStartInDays, row.isActive);
    return <InputSwitch name='Notifications' checked={row.isActive} />;
  };
  const removeNegative = (row) => {
    const removedNegative =
      row.reminderStartInDays < 0
        ? Math.abs(row.reminderStartInDays)
        : row.reminderStartInDays;
    console.log("rowFormated", row.reminderStartInDays, removedNegative);
    return removedNegative;
  };
  const dueDateFormat = (row) => {
    const dueDateFormated =
      row.reminderBasedOnField === ("FilingDueDate" || "Filing")
        ? "Filing"
        : row.reminderBasedOnField === ("DueDate" || "Internal")
          ? "Internal"
          : "Both";
    console.log("rowFormated", row.reminderBasedOnField, dueDateFormated);
    return dueDateFormated;
  };
  const cellMessage = (row) => {
    console.log("cellMessage", row.emailMessage);
    const emailMessageWithoutLinks = row.emailMessage
      .replace(/<a\b[^>]*>(.*?)<\/a>/g, "")
      .replace(/Please click on this link to Login:/g, "")
      .replace(/<hr\/?>/g, "")
      .replace(/<br\/?>/g, "");
    console.log("emailMessageWithoutLinks", emailMessageWithoutLinks);
    return (
      <div
        name='Notifications'
        dangerouslySetInnerHTML={{ __html: emailMessageWithoutLinks }}
      />
    );
  };

  const getCustomerList = async () => {
    try {
      let res = await axios.get(`${API_BASE_URL}/Customer/CustomerList`);
      let _data;
      if (
        !(roleType.includes("Admin") || roleType.includes("Admin-PostAward"))
      ) {
        _data = res.data
          .filter((ele) => authUser.clients.includes(ele.customerId))
          .map((ele) => ({
            code: ele.customerId,
            name: ele.customerName,
          }));
      } else {
        _data = res.data.map((ele) => ({
          code: ele.customerId,
          name: ele.customerName,
        }));
      }
      setCustomerList(_data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // getConfigs();
    getCustomerList();
  }, []);

  useEffect(() => {
    getEnvConfigs();
  }, []);

  useEffect(() => {
    console.log("customerList", customerList);
    if (customerList.length > 0) {
      getConfigs();
    }
  }, [customerList]);

  useEffect(() => {
    // if (formValues.RecordType === "PostAward") {
    //   setFormValues((prevValues) => ({
    //     ...prevValues,
    //     Due_Date_Type: "Internal",
    //   }));
    // }
    // else {
    //   setFormValues((prevValues) => ({
    //     ...prevValues,
    //     Due_Date_Type: "Filing",
    //   }));
    // }
  }, [formValues.RecordType]);


  useEffect(() => {
    if (initialValues?.Client?.code !== formValues?.Client?.code ||
      initialValues?.Mail_Subject !== formValues?.Mail_Subject ||
      initialValues?.Mail_ReminderBefore?.toString() !== formValues.Mail_ReminderBefore?.toString() ||
      initialValues?.RecordType !== formValues?.RecordType ||
      initialValues?.Mail_Message !== formValues?.Mail_Message ||
      initialValues?.Due_Date_Type !== formValues?.Due_Date_Type ||
      initialValues?.Notifications !== formValues?.Notifications) {
      console.log("initialValues", initialValues, "formValues", formValues);
      setIsFieldModified(true)
    }
  }, [formValues])

  useEffect(() => {

  },)

  const handleReset = () => {
    setFormValues({
      Mail_Message: "",
      Mail_Subject: "",
      Mail_ReminderBefore: "",
      Notifications: true,
    });
    setIsCreating(true);
    setSelectedPlaceHolderList([]);
    setSelectedUserGroup([]);
    setSelectedUserName([]);
    setSelectedUserNameInput([]);
    setSelectedEmailsCombained([])
    setSelectedRoleList([]);
    setSelectedPlaceHolderListCc([]);
    setSelectedUserGroupCc([]);
    setSelectedUserNameCc([]);
    setSelectedUserNameInputCc([]);
    setSelectedRoleListCc([]);
    //setSelectedReminderName('')
  };

  console.log("Form validation", formValues);
  const handleConfigItemChange = (event) => {
    console.log("##eventClient", event);
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    console.log("formValues", formValues);
  };

  const handleConfigItemChanges = (event) => {
    console.log("##eventClient", event);
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    console.log("formValues", formValues);
  };

  const changeNotifications = () => {
    console.log("handleNotificationChange", notifications);
    handleConfigItemChange(notifications);
  };

  const { AlertDialog: NotificationsDialog, showDialog: notificationsDialog } =
    useDialog({
      onSubmit: changeNotifications,
    });

  const handleNotificationChange = (e) => {
    console.log("handleNotificationChange", e);
    if (e.target.value) {
      handleConfigItemChange(e);
    } else {
      setNotifications(e);
      notificationsDialog(
        "Confirmation",
        "If Reminders is turned OFF, system will not send reminders  to the Client users. Do you want to change?",
        "confirm"
      );
    }
  };

  const createReminder = async (threeDigitReminder, paragraphMsg) => {
    let form = {};
    form[`Mail_CustomerFileTracker_Reminder_Message_${threeDigitReminder}`] =
      paragraphMsg;
    form[`Mail_CustomerFileTracker_Reminder_Subject_${threeDigitReminder}`] =
      formValues.Mail_Subject;
    form["Mail_CustomerFileTracker_Reminder_days"] =
      formValues.Mail_ReminderBefore + "";
    form[
      `Mail_CustomerFileTracker_Reminder_DueDateOption_${threeDigitReminder}`
    ] = formValues.Due_Date_Type;
    if (formValues.Notifications !== undefined) {
      let val = formValues.Notifications ? "1" : "0";
      form[`SendMail_${threeDigitReminder}`] = val;
      form[`SendNotification_${threeDigitReminder}`] = val;
    }
    let data = Object.keys(form).map((item) => {
      return {
        configItem: item,
        configItemValue: form[item],
        configId: 0,
        customerId: formValues.Client ? formValues.Client.code : null,
        createDate: new Date(Date.now()).toISOString(),
        createUser: authUser.userId + "",
      };
    });

    console.log("User created", data);
    let res = await createReminderConfiguration(data);
    console.log("res", res);
    return res;
  };

  const handlePayLoad = async (emailTaskId) => {
    console.log(
      "authUser",
      authUser,
      "authUser.userId",
      authUser.userId,
      "createUser",
      authUser.userId + ""
    );
    let userNameCc = selectedUserNameCc.filter(
      (userCc) =>
        !selectedUserName.some(
          (user) => user.userEmailId === userCc.userEmailId
        )
    );
    let userNameInputCc = selectedUserNameInputCc.filter(
      (user) => !selectedUserNameInput.includes(user)
    );
    let userGroupCc = selectedUserGroupCc.filter(
      (user) => !selectedUserGroup.includes(user)
    );
    let roleListCc = selectedRoleListCc.filter(
      (user) => !selectedRoleList.includes(user)
    );
    let placeHoldolderCc = selectedPlaceHolderListCc.filter(
      (user) => !selectedPlaceHolderList.includes(user)
    );
    console.log("userNameCc", userNameCc);
    console.log("userNameInputCc", userNameInputCc);
    console.log("userGroupCc", userGroupCc);
    console.log("roleListCc", roleListCc);
    console.log("placeHoldolderCc", placeHoldolderCc);
    let payLoads = [];
    if (selectedUserName) {
      payLoads.push(
        ...selectedUserName.map((user) => {
          const isCC = selectedUserNameCc.some(
            (userCc) => userCc.userEmailId === user.userEmailId
          );
          return {
            emailTaskId: emailTaskId,
            userName: user.userEmailId,
            userCategory: "Users",
            emailTo: true,
            emailCC: isCC ? true : false,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selectedUserNameInput) {
      payLoads.push(
        ...selectedUserNameInput.map((user) => {
          const isCC = selectedUserNameInputCc.includes(user);
          return {
            emailTaskId: emailTaskId,
            userName: user,
            //userCategory: "",
            emailTo: true,
            emailCC: isCC ? true : false,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selectedUserGroup) {
      payLoads.push(
        ...selectedUserGroup.map((group) => {
          const isCC = selectedUserGroupCc.includes(group);
          return {
            emailTaskId: emailTaskId,
            userName: group,
            userCategory: "Groups",
            emailTo: true,
            emailCC: isCC ? true : false,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selectedRoleList) {
      payLoads.push(
        ...selectedRoleList.map((role) => {
          const isCc = selectedRoleListCc.includes(role);
          return {
            emailTaskId: emailTaskId,
            userName: role,
            userCategory: "Roles",
            emailTo: true,
            emailCC: isCc ? true : false,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selectedPlaceHolderList) {
      payLoads.push(
        ...selectedPlaceHolderList.map((role) => {
          const isCc = selectedPlaceHolderListCc.includes(role);
          return {
            emailTaskId: emailTaskId,
            userName: role,
            userCategory: "PlaceHolder",
            emailTo: true,
            emailCC: isCc ? true : false,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (userNameCc) {
      payLoads.push(
        ...userNameCc.map((user) => {
          return {
            emailTaskId: emailTaskId,
            userName: user.userEmailId,
            userCategory: "Users",
            emailTo: false,
            emailCC: true,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
            // Set emailCC based on isCC
          };
        })
      );
    }
    if (userNameInputCc) {
      payLoads.push(
        ...userNameInputCc.map((user) => {
          return {
            emailTaskId: emailTaskId,
            userName: user,
            //userCategory: "",
            emailTo: false,
            emailCC: true,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
            // Set emailCC based on isCC
          };
        })
      );
    }
    if (userGroupCc) {
      payLoads.push(
        ...userGroupCc.map((group) => {
          return {
            emailTaskId: emailTaskId,
            userName: group,
            userCategory: "Groups",
            emailTo: false,
            emailCC: true,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (roleListCc) {
      payLoads.push(
        ...roleListCc.map((role) => {
          return {
            emailTaskId: emailTaskId,
            userName: role,
            userCategory: "Roles",
            emailTo: false,
            emailCC: true,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (placeHoldolderCc) {
      payLoads.push(
        ...placeHoldolderCc.map((role) => {
          return {
            emailTaskId: emailTaskId,
            userName: role,
            userCategory: "PlaceHolder",
            emailTo: false,
            emailCC: true,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }

    console.log("PayLoad##", payLoads);
    return payLoads;
  };
  const updateRecipient = async () => {
    // createDate: new Date(Date.now()).toISOString(),
    //   createUser: authUser.userId + "",
    console.log(
      "authUser",
      authUser,
      "authUser.userId",
      authUser.userId,
      "createUser",
      authUser.userId + ""
    );

    let res = await axios.get(`${API_BASE_URL}/Application/ListEmailUsers`);
    let viewEmailUserList = res.data.data;
    let filteredEmailUserList = viewEmailUserList.filter(
      (email) => email.emailTaskId === emailTaskIdValue.emailTaskId
    );
    console.log("filteredEmailUserList", filteredEmailUserList);
    let userNameList = filteredEmailUserList.filter(
      (cat) => cat.userCategory === "Users"
    );
    let userNameListInput = filteredEmailUserList.filter(
      (cat) => cat.userCategory === null
    );
    //  let userName = userNameList.map(user => user.userName);
    let userGroupList = filteredEmailUserList.filter(
      (cat) => cat.userCategory === "Groups"
    );
    //  let userGroup = userNameList.map(user => user.userName);

    let userRolesList = filteredEmailUserList.filter(
      (cat) => cat.userCategory === "Roles"
    );
    // let userRoles = userNameList.map(user => user.userName);

    let placeHolderList = filteredEmailUserList.filter(
      (cat) => cat.userCategory === "PlaceHolder"
    );
    //  let placeHolder = userNameList.map(user => user.userName);
    const delPayLoad = [];
    const delUser = userNameList.filter((user) => {
      // Check if the userEmailId is not in selectedUserName or selectedUserNameCc
      return (
        !selectedUserName.some(
          (selectedUser) => selectedUser.userEmailId === user.userName
        ) &&
        !selectedUserNameCc.some(
          (selectedUser) => selectedUser.userEmailId === user.userName
        )
      );
    });
    const delIds = delUser.map((item) => item.id);
    delPayLoad.push(...delIds);
    console.log(
      "userNameList",
      userNameList,
      "delUser",
      delUser,
      "delPayLoad",
      delPayLoad
    );
    const delUserInput = userNameListInput.filter(
      (user) =>
        !selectedUserNameInput.includes(user.userName) &&
        !selectedUserNameInputCc.includes(user.userName)
    );
    const delInputIds = delUserInput.map((item) => item.id);
    delPayLoad.push(...delInputIds);
    const delUserGroup = userGroupList.filter(
      (user) =>
        !selectedUserGroup.includes(user.userName) &&
        !selectedUserGroupCc.includes(user.userName)
    );
    const delUserGroupIds = delUserGroup.map((item) => item.id);
    delPayLoad.push(...delUserGroupIds);

    const delPlaceHolder = placeHolderList.filter(
      (user) =>
        !selectedPlaceHolderList.includes(user.userName) &&
        !selectedPlaceHolderListCc.includes(user.userName)
    );
    const delPlaceIds = delPlaceHolder.map((item) => item.id);
    delPayLoad.push(...delPlaceIds);

    const delRole = userRolesList.filter(
      (user) =>
        !selectedRoleList.includes(user.userName) &&
        !selectedRoleListCc.includes(user.userName)
    );
    const delRoleIds = delRole.map((item) => item.id);
    delPayLoad.push(...delRoleIds);

    // let userNameCc = selectedUserNameCc.filter(
    //   (user) => !selectedUserName.includes(user)
    // );
    let userNameCc = selectedUserNameCc.filter((userCc) => {
      return !selectedUserName.some(
        (user) => user.userEmailId === userCc.userEmailId
      );
    });

    let userNameInputCc = selectedUserNameInputCc.filter(
      (user) => !selectedUserNameInput.includes(user)
    );
    let userGroupCc = selectedUserGroupCc.filter(
      (user) => !selectedUserGroup.includes(user)
    );
    let roleListCc = selectedRoleListCc.filter(
      (user) => !selectedRoleList.includes(user)
    );
    let placeHoldolderCc = selectedPlaceHolderListCc.filter(
      (user) => !selectedPlaceHolderList.includes(user)
    );
    console.log("userNameCc", userNameCc);
    console.log("userNameInputCc", userNameInputCc);
    console.log("userGroupCc", userGroupCc);
    console.log("roleListCc", roleListCc);
    console.log("placeHoldolderCc", placeHoldolderCc);
    let updatePayLoads = [];
    // const userNameCcObj = userNameCc.map((name) => {
    //   const user = userNameList.find((user) => user.userName === name.userEmailId);
    //   return user
    //     ? { id: user.id, userName: user.userName }
    //     : { id: 0, userName: name.userEmailId };
    // });
    // const selUserNameobj = selectedUserName.map((name) => {
    //   const user = userNameList.find((user) => user.userName === name);
    //   return user ? { id: user.id, userName: user.userName } : { id: 0, userName: name };
    // });
    // const selUserNameCcobj = selectedUserNameCc.map((name) => {
    //   const user = userNameList.find((user) => user.userName === name);
    //   return user ? { id: user.id, userName: user.userName } : { id: 0, userName: name };
    // });

    //List email input

    const userNameCcObj = userNameCc.map((obj) => {
      const user = userNameList.find(
        (user) => user.userName === obj.userEmailId
      );
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: obj.userEmailId };
    });
    // console.log("userNameCcObj", userNameCcObj, "userNameCc", userNameCc, "userNameList", userNameList);
    const selUserNameobj = selectedUserName.map((obj) => {
      const user = userNameList.find(
        (user) => user.userName === obj.userEmailId
      );
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: obj.userEmailId };
    });

    const selUserNameCcobj = selectedUserNameCc.map((obj) => {
      const user = userNameList.find(
        (user) => user.userName === obj.userEmailId
      );
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: obj.userEmailId };
    });


    //
    const userNameInputCcObj = userNameInputCc.map((name) => {
      const user = userNameListInput.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selUserNameInputobj = selectedUserNameInput.map((name) => {
      const user = userNameListInput.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selUserNameInputCcobj = selectedUserNameInputCc.map((name) => {
      const user = userNameListInput.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });

    //List Email
    const userGroupCcObj = userGroupCc.map((name) => {
      const user = userGroupList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selUserGroupobj = selectedUserGroup.map((name) => {
      const user = userGroupList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selUserGroupCcobj = selectedUserGroupCc.map((name) => {
      const user = userGroupList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });

    //Role
    const roleListCcObj = roleListCc.map((name) => {
      const user = userRolesList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selRoleListobj = selectedRoleList.map((name) => {
      const user = userRolesList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selRoleListCcobj = selectedRoleListCc.map((name) => {
      const user = userRolesList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    //PlaceHolderList
    const placeHoldolderCcObj = placeHoldolderCc.map((name) => {
      const user = placeHolderList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selplaceHoldoldertobj = selectedPlaceHolderList.map((name) => {
      const user = placeHolderList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selplaceHoldolderCcobj = selectedPlaceHolderListCc.map((name) => {
      const user = placeHolderList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    console.log(
      "userNameCcObj",
      userNameCcObj,
      "selUserNameobj",
      selUserNameobj,
      "selUserNameCcobj",
      selUserNameCcobj
    );

    if (selUserNameobj) {
      updatePayLoads.push(
        ...selUserNameobj.map((user) => {
          const isCC = selUserNameCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          console.log("UserNameCheck ", "selUserNameobj", selUserNameobj, "user", user, "isCC", isCC);
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "Users",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selUserNameInputobj) {
      updatePayLoads.push(
        ...selUserNameInputobj.map((user) => {
          const isCC = selUserNameInputCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            // userCategory: "",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selUserGroupobj) {
      updatePayLoads.push(
        ...selUserGroupobj.map((user) => {
          const isCC = selUserGroupCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "Groups",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selRoleListobj) {
      updatePayLoads.push(
        ...selRoleListobj.map((user) => {
          const isCC = selRoleListCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "Roles",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selplaceHoldoldertobj) {
      updatePayLoads.push(
        ...selplaceHoldoldertobj.map((user) => {
          const isCC = selplaceHoldolderCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "PlaceHolder",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (userNameCcObj) {
      updatePayLoads.push(
        ...userNameCcObj.map((user) => {

          console.log("UserNameCheck CC ", "userNameCcObj", userNameCcObj, "user", user);

          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "Users",
            emailTo: false,
            emailCC: true,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (userNameInputCcObj) {
      updatePayLoads.push(
        ...userNameInputCcObj.map((user) => {
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            // userCategory: "",
            emailTo: false,
            emailCC: true,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (userGroupCcObj) {
      updatePayLoads.push(
        ...userGroupCcObj.map((group) => {
          return {
            id: group.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: group.userName,
            userCategory: "Groups",
            emailTo: false,
            emailCC: true,
            updateDate: group.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: group.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (roleListCcObj) {
      updatePayLoads.push(
        ...roleListCcObj.map((role) => {
          return {
            id: role.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: role.userName,
            userCategory: "Roles",
            emailTo: false,
            emailCC: true,
            updateDate: role.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: role.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (placeHoldolderCcObj) {
      updatePayLoads.push(
        ...placeHoldolderCcObj.map((role) => {
          return {
            id: role.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: role.userName,
            userCategory: "PlaceHolder",
            emailTo: false,
            emailCC: true,
            updateDate: role.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: role.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }

    console.log("PayLoad##", updatePayLoads, delPayLoad);
    return { updatePayLoads, delPayLoad };
  };

  console.log("FormCheckClient", formValues.client);
  const handleSubmit = async () => {
    setIsFormSubmitted(true);
    setIsLoading(true);
    let hasRecipientValidation = false;
    console.log("hasRecipientValidationbef", hasRecipientValidation);
    hasRecipientValidation =
      selectedUserName.length === 0 &&
        selectedUserGroup.length === 0 &&
        selectedPlaceHolderList.length === 0 &&
        selectedRoleList.length === 0 &&
        selectedUserNameInput.length === 0
        ? true
        : false;
    console.log("hasRecipientValidation", hasRecipientValidation);
    if (hasRecipientValidation) {
      setIsRecipientValidation(true);
    }
    console.log("handleSubmit formValues", formValues);
    console.log("isCreating", isCreating);
    console.log("initialFormValues", initialFormValues);
    const baseUrl = `${API_BASE_URL}/Application/CreateEmailUser`;
    const baseUrlUpd = `${API_BASE_URL}/Application/UpdateEmailUser`;
    try {
      let envLink = null;
      if (env === "DEV") {
        envLink = envUrls.Dev;
      } else if (env === "QA") {
        envLink = envUrls.Qa;
      } else if (env === "UAT") {
        envLink = envUrls.Uat;
      } else if (env === "Prod") {
        envLink = envUrls.Prod;
      }
      console.log("EnvState", env, "envLink", envLink, "envUrl", envUrls);
      // let paragraphMsg = formValues.html
      //   ? `<html><body>` +
      //   formValues.Mail_Message.replaceAll("\n", "<br/>") +
      //   `<a href="${envLink}">TrackNowLogin</a>` +
      //   `</body></html>`
      //   : formValues.Mail_Message.replaceAll("\n", "") + `<a href="${envLink}">TrackNowLogin</a>`;

      let paragraphMsg =
        `<html><body>` +
        `Please click on this link to Login:<br/>` +
        `<a href="${envLink}">TrackNowLogin</a>` +
        `<hr/><br/>` +
        formValues.Mail_Message.replaceAll("\n", "<br/>") +
        `<br/>` +
        `</body></html>`;

      console.log("handleSubmitFormVal Message ", formValues, paragraphMsg);
      let threeDigitReminder = ("000" + formValues.Mail_ReminderBefore).substr(
        -3
      );
      let client = formValues.Client ? formValues.Client.code : "default";
      let findClient = initialFormValues.filter((key) => key.Client === client);
      let isExist =
        findClient.length > 0 &&
        Object.keys(findClient[0].data).filter(
          (key) => key.slice(-3) === threeDigitReminder
        );
      console.log("threeDigitReminder", threeDigitReminder);
      console.log("client", client);
      console.log(
        "initialFormValues",
        initialFormValues,
        "findClient",
        findClient
      );
      console.log("isExist", isExist.length);
      let hasRequired =
        !formValues.Mail_Message ||
        !formValues.Mail_Subject ||
        !formValues.Mail_ReminderBefore ||
        formValues.Mail_ReminderBefore < 0 ||
        !formValues.Due_Date_Type ||
        Boolean(emailError) ||
        Boolean(emailError);
      console.log("hasRequired", hasRequired);
      let reminderUpdatePayLoads = null;
      let reminderPayLoads = null;
      const emailTaskName = `Reminder_${formValues.Mail_ReminderBefore}_${formValues.Client ? formValues.Client.name : "null"
        }`;
      try {
        reminderPayLoads = {
          emailTaskId: 0,
          emailSubject: formValues.Mail_Subject,
          emailMessage: paragraphMsg,
          isHTMLMessage: true,
          isReminder: true,
          emailTaskName: emailTaskName,
          reminderStartInDays: -formValues.Mail_ReminderBefore,
          reminderFrequencyInDays: 1,
          reminderBasedOnField: "FilingDueDate",
          customerId: formValues.Client ? formValues.Client.code : null,
          createDate: new Date(Date.now()).toISOString(),
          createUser: authUser.userId + "",
          recordType: formValues.RecordType,
          isActive: formValues.Notifications,
        };
        reminderUpdatePayLoads = {
          emailTaskId: emailTaskIdValue?.emailTaskId,
          emailSubject: formValues.Mail_Subject,
          emailMessage: paragraphMsg,
          isHTMLMessage: true,
          isReminder: true,
          emailTaskName: emailTaskName,
          reminderStartInDays: -formValues.Mail_ReminderBefore,
          isActive: formValues.Notifications,
          reminderFrequencyInDays: 1,
          reminderBasedOnField: "FilingDueDate",
          customerId: formValues.Client ? formValues.Client.code : null,
          createDate: new Date(Date.now()).toISOString(),
          createUser: authUser.userId + "",
          recordType: formValues.RecordType,
          updateDate: new Date(Date.now()).toISOString(),
          updateUser: authUser.userId + "",
        };
        console.log("createPayloadsreminder", reminderPayLoads);
        console.log("createPayloadsreminderUpload", reminderUpdatePayLoads);
      } catch (error) {
        console.log("error#", error);
      }
      if (!hasRequired && !hasRecipientValidation) {
        if (isCreating) {
          if (isExist.length > 0) {
            setAlertMessage("Reminder already exists");
            setShowAlert(true);
            return;
          }

          try {
            console.log("createPayLoads", reminderPayLoads);
            let res = await createEmail(reminderPayLoads);
            if (res.data.status === "Success") {
              console.log("res.data.status");
              let resRecipient = await handlePayLoad(res.data.data.emailTaskId);
              console.log("resRecipient", resRecipient);
              if (resRecipient) {
                const responseRecipent = axios.post(baseUrl, resRecipient)
                console.log("responseRecipent", responseRecipent);
                try {
                  const responses = responseRecipent.data.status === "Success" ? true : false
                  console.log("responses", responses);
                  if (responses) {
                    setAlertMessage("Sucessfully created reminder");
                    setShowAlert(true);
                  } else {
                    setAlertMessage("Error adding recipients");
                    setShowAlert(true);
                  }
                } catch (error) {
                  console.error("Error:", error);
                }
              }
            } else {
              setAlertMessage("Error creating reminder");
              setShowAlert(true);
            }
          } finally {
            setSelectedPlaceHolderList([]);
            setIsFormSubmitted(false);
            getConfigs();
            handleReset();
            setSelectedUserNameCc([]);
            setSelectedUserNameInputCc([]);
            setSelectedUserGroup([]);
            setSelectedUserName([]);
            setSelectedUserNameInput([]);
            setSelectedEmailsCombained([])
            setSelectedRoleList([]);
            setSelectedPlaceHolderListCc([]);
            setSelectedUserGroupCc([]);
            setSelectedUserNameCc([]);
            setSelectedRoleListCc([]);
            setEmailTaskIdValue(null);
            // setSelectedReminderName('')
            console.log("sucesss", 123);
          }
        } else {
          if (isExist.length > 0) {
            setAlertMessage("Reminder already exists");
            setShowAlert(true);
            handleReset();

            return;
          }

          let res = await updateEmail(reminderUpdatePayLoads);
          const { updatePayLoads, delPayLoad } = await updateRecipient();
          console.log("updatePayLoads", updatePayLoads, delPayLoad);

          if (updatePayLoads) {

            const newlyAddedUser = updatePayLoads.filter(item => item.id === 0) // newly added cc or to 
            const exsistingUser = updatePayLoads.filter(item => item.id !== 0) // exsisting cc or to modification
            const removeMatchingObjects = (initialArr, exsistingArr) => {
              return exsistingArr.filter(exsistingObj => {
                // Check if there is a matching initialEmailCcandTo object with the same id
                let matchingInitialObj = initialArr.find(initialObj => {
                  return initialObj.id === exsistingObj.id &&
                    initialObj.emailCC === exsistingObj.emailCC &&
                    initialObj.emailTo === exsistingObj.emailTo;
                });

                // If matchingInitialObj is found, filter it out from exsistingArr
                return !matchingInitialObj;
              });
            }
            let filteredExsistingUser = removeMatchingObjects(initialEmailCCandTo, exsistingUser); // removing the exsisting EmailCc and EmailTo if not edited 
            // console.log("updatePayLoads", updatePayLoads, "newlyAddedUser", newlyAddedUser, "exsistingUser", exsistingUser,);
            console.log("updatePayLoads", updatePayLoads, "initialEmailCCandTo", initialEmailCCandTo, "exsistingUser", exsistingUser, "filteredExsistingUser", filteredExsistingUser, "newlyAddedUser", newlyAddedUser);

            for (const resUpd of filteredExsistingUser) {
              const urlWithId = `${baseUrlUpd}?Id=${resUpd.id}`;
              let responseRecipentUpd = await axios.put(urlWithId, resUpd);
            }
            //Calling create API for newly added user
            const urlWithId = `${baseUrl}`;
            let responseRecipentUpd = await axios.post(urlWithId, newlyAddedUser);

          }
          if (delPayLoad) {
            console.log("updatePayLoads2", updatePayLoads, delPayLoad);

            // Assuming delUserId is the value you want to send for deletion
            try {
              const response = await axios.delete(
                `${API_BASE_URL}/Application/DeleteEmailUser?UpdateUser=${authUser.userId + ""}`, { data: delPayLoad }
              );
              // Handle the response as needed (e.g., check for success)
              //console.log(`Deleted user with ID ${delId}`);
            } catch (error) {
              // Handle any errors that may occur during the DELETE request
              //console.error(`Error deleting user with ID ${delId}:`, error);
            }
          }

          console.log("res", res);
          if (res.data.status === "Success") {
            setAlertMessage("Reminder Successfully Updated");
            setShowAlert(true);
            handleReset();
            setIsFormSubmitted(false);
            setSelectedPlaceHolderList([]);
            setSelectedEmailsCombained([])
            setSelectedUserGroup([]);
            setSelectedUserName([]);
            setSelectedRoleList([]);
            setSelectedPlaceHolderListCc([]);
            setSelectedUserGroupCc([]);
            setSelectedUserNameCc([]);
            setSelectedRoleListCc([]);
            //  setSelectedReminderName('')
            setEmailTaskIdValue(null);
            setIsFormSubmitted(false);
            getConfigs();
          } else {
            setAlertMessage("Error Updating Reminder");
            setShowAlert(true);
            handleReset();
            setIsFormSubmitted(false);
            setSelectedPlaceHolderList([]);
            setSelectedEmailsCombained([])
            setSelectedUserGroup([]);
            setSelectedUserName([]);
            setSelectedRoleList([]);
            setSelectedPlaceHolderListCc([]);
            setSelectedUserGroupCc([]);
            setSelectedUserNameCc([]);
            setSelectedRoleListCc([]);
            //  setSelectedReminderName('')
            setEmailTaskIdValue(null);
            setIsFormSubmitted(false);
            getConfigs();
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleSubmitCheck = async () => {
  //   let hasRecipientValidation = false;
  //   console.log("hasRecipientValidationbef", hasRecipientValidation);
  //   hasRecipientValidation = selectedUserName.length === 0 && selectedUserGroup.length === 0 && selectedPlaceHolderList.length === 0 && selectedRoleList.length === 0 ? true : false
  //   console.log("hasRecipientValidation", hasRecipientValidation);
  //   if (hasRecipientValidation) {
  //     setIsRecipientValidation(true)
  //   } else {
  //     setAlertMessage("Sucessfull");
  //     setShowAlert(true)
  //   }
  // }
  const createEmail = async (reminderPayLoads) => {
    const baseUrl = `${API_BASE_URL}/Application/CreateEmail`;

    let res = await axios.post(baseUrl, reminderPayLoads);
    console.log("emailRes", res);
    return res;
  };
  const updateEmail = async (reminderUpdatePayLoads) => {
    console.log("reminderUpdatePayLoads", reminderUpdatePayLoads);
    const baseUrl = `${API_BASE_URL}/Application/UpdateEmail?EmailTaskId=${reminderUpdatePayLoads.emailTaskId}`;

    let res = await axios.put(baseUrl, reminderUpdatePayLoads);
    console.log("emailRes", res);
    return res;
  };

  const getClientName = (clientId) => {
    console.log("getClientName", clientId, customerList);
    let findName = customerList.filter((ele) => ele.code === clientId);
    console.log("getClientName", findName);
    return findName.length > 0 ? findName[0].name : "";
  };

  const EditButton = ({ rowData }) => {
    return (
      <IconButton
        color='primary'
        disabled={!usePermission("setting", "edit", rowData.clientId)}
      >
        <EditIcon onClick={() => editReminder(rowData)} />
      </IconButton>
    );
  };

  const DeleteButton = ({ rowData }) => {
    return (
      <IconButton
        color='primary'
        disabled={!usePermission("setting", "edit", rowData.clientId)}
      >
        <DeleteIcon onClick={() => handleDelete(rowData)} />
      </IconButton>
    );
  };

  const deleteReminder = async (showAlert) => {
    setIsLoading(true);
    try {
      console.log("deleteUser data", selectedReminder);
      const res = await axios.get(`${API_BASE_URL}/Application/ListEmailUsers`);
      const viewEmailUserList = res.data.data;
      const filteredEmailUserList = viewEmailUserList.filter(
        (email) => email.emailTaskId === emailTaskIdValue.emailTaskId
      );
      console.log("filteredEmailUserList", filteredEmailUserList);
      const delEmailIds = filteredEmailUserList.map((item) => item.id);
      try {
        let response;
        let res = await axios.delete(
          `${API_BASE_URL}/Application/DeleteEmail/${selectedReminder.emailTaskId}`
        );
        console.log("res", res.data);

        if (res.data.status === "Success") {
          try {
            if (delEmailIds.length > 0) {
              console.log("delEmailIds", delEmailIds);

              // Assuming delUserId is the value you want to send for deletion
              try {
                response = await axios.delete(
                  `${API_BASE_URL}/Application/DeleteEmailUser?UpdateUser=${authUser.userId + ""}`, { data: delEmailIds }
                );
                // console.log(`Deleted user with ID ${delId}`);
              } catch (error) {
                // console.error(`Error deleting user with ID ${delId}:`, error);
              }
            }
          } finally {
            setAlertMessage("Reminder successfully deleted");
            setShowAlert(true);
          }
          // getConfigs();
          // handleReset()
        } else {
          setAlertMessage(res.data.errorMessage);
          setShowAlert(true);
          // getConfigs();
          // handleReset()
        }
      } catch (error) {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
      getConfigs();
      handleReset();
      setEmailTaskIdValue(null);
    }
  };

  const { AlertDialog: ConfirmDialog, showDialog } = useDialog({
    onSubmit: deleteReminder,
  });

  const handleDelete = (data) => {
    console.log("handleDelete data", data);
    setSelectedReminder(data);
    setEmailTaskIdValue(data);
    showDialog(
      "Confirmation",
      "Are you sure you want to delete this reminder?",
      "confirm"
    );
  };

  const isPermission = !usePermission("setting", "edit");

  const editReminder = async (data) => {
    try {
      console.log("editReminder", data, "client", formValues.client);
      console.log("editReminderCustId", data.customerId);
      console.log("editReminderHTML", data.isHTMLMessage);
      setIsCreating(false);
      setIsRecipientValidation(false);
      setSelectedReminder(data);
      setEmailTaskIdValue(data);
      // setSelectedReminderName(data.emailTaskName)
      let resData = await axios.get(
        `${API_BASE_URL}/Application/ListEmailMasterPlaceHolderNameValues`
      );
      let userTable = resData.data.data.filter(
        (item) =>
          item.isReplacerName === false &&
          item.isGroupName === false &&
          item.isUsername === true &&
          item.isRoleName === false
      );
      let res = await axios.get(`${API_BASE_URL}/Application/ListEmailUsers`);
      let viewEmailUserList = res.data.data;
      let filteredEmailUserList = viewEmailUserList.filter(
        (email) => email.emailTaskId === data.emailTaskId
      );
      // let userName = filteredEmailUserList.filter(
      //   (cat) => cat.userCategory === "Users"
      // );
      let filtUsers = filteredEmailUserList.filter(
        (cat) => cat.userCategory === "Users"
      );
      const userList = filtUsers.map((user) => {
        const matchingUser = userTable.find(
          (userNameObj) => userNameObj.userEmailId === user.userName
        );
        if (matchingUser) {
          console.log("matchingUser", matchingUser);
          return {
            ...user,
            placeHolderName: matchingUser.placeHolderName,
          };
        }
      });

      console.log(
        "FiltUser",
        filtUsers,
        "userList",
        userList,
        "userTable",
        userTable
      );
      let userNameInput = filteredEmailUserList.filter(
        (cat) => cat.userCategory === null
      );
      let userGroup = filteredEmailUserList.filter(
        (cat) => cat.userCategory === "Groups"
      );
      let userRoles = filteredEmailUserList.filter(
        (cat) => cat.userCategory === "Roles"
      );
      let placeHolder = filteredEmailUserList.filter(
        (cat) => cat.userCategory === "PlaceHolder"
      );
      let combainedEmailCcandTo = [...userList, ...userNameInput, ...userRoles, ...placeHolder]
      console.log("editReminder combainedEmailCcandTo", combainedEmailCcandTo);
      setInitialEmailCCandTo(combainedEmailCcandTo)



      console.log("editReminder", userList, "userNameInput", userNameInput, "userRoles", userRoles, "placeHolder", placeHolder);
      let userNameSel = [];
      let userNameCcSel = [];
      userList.forEach((email) => {
        const userEntry = {
          userEmailId: email?.userName,
          placeHolderName: email?.placeHolderName,
        };
        if (email?.emailCC && email?.emailTo) {
          userNameSel.push(userEntry);
          userNameCcSel.push(userEntry);
        } else if (email?.emailTo) {
          userNameSel.push(userEntry);
        } else if (email?.emailCC) {
          userNameCcSel.push(userEntry);
        }
      });
      console.log("userNameSel", userNameSel, "userNameCcSel", userNameCcSel);
      setSelectedUserName(userNameSel);
      setSelectedUserNameCc(userNameCcSel);
      setSelectedEmailsCombained([...userNameSel, ...userNameCcSel]);


      let userNameSelInput = [];
      let userNameCcSelInput = [];
      userNameInput.forEach((email) => {
        if (email?.emailCC && email?.emailTo) {
          userNameSelInput.push(email.userName);
          userNameCcSelInput.push(email.userName);
        } else if (email?.emailTo) {
          userNameSelInput.push(email.userName);
        } else if (email?.emailCC) {
          userNameCcSelInput.push(email.userName);
        }
      });
      setSelectedUserNameInput(userNameSelInput);
      setSelectedUserNameInputCc(userNameCcSelInput);
      setSelectedEmailsCombained([...userNameSelInput, ...userNameSelInput]);


      let userGroupSel = [];
      let userGroupCcSel = [];
      userGroup.forEach((email) => {
        if (email?.emailCC && email?.emailTo) {
          userGroupSel.push(email.userName);
          userGroupCcSel.push(email.userName);
        } else if (email?.emailTo) {
          userGroupSel.push(email.userName);
        } else if (email?.emailCC) {
          userGroupCcSel.push(email.userName);
        }
      });

      setSelectedUserGroup(userGroupSel);
      setSelectedUserGroupCc(userGroupCcSel);
      setSelectedEmailsCombained([...userGroupSel, ...userGroupCcSel]);



      let RoleListSel = [];
      let RoleListCcSel = [];
      userRoles.forEach((email) => {
        if (email?.emailCC && email?.emailTo) {
          RoleListSel.push(email.userName);
          RoleListCcSel.push(email.userName);
        } else if (email?.emailTo) {
          RoleListSel.push(email.userName);
        } else if (email?.emailCC) {
          RoleListCcSel.push(email.userName);
        }
      });
      setSelectedRoleList(RoleListSel);
      setSelectedRoleListCc(RoleListCcSel);
      setSelectedEmailsCombained([...RoleListSel, ...RoleListCcSel]);


      let PlaceHolderListSel = [];
      let PlaceHolderListCcSel = [];
      placeHolder.forEach((email) => {
        if (email?.emailCC && email?.emailTo) {
          PlaceHolderListSel.push(email.userName);
          PlaceHolderListCcSel.push(email.userName);
        } else if (email?.emailTo) {
          PlaceHolderListSel.push(email.userName);
        } else if (email?.emailCC) {
          PlaceHolderListCcSel.push(email.userName);
        }
      });
      setSelectedPlaceHolderList(PlaceHolderListSel);
      setSelectedPlaceHolderListCc(PlaceHolderListCcSel);
      setSelectedEmailsCombained([...PlaceHolderListSel, ...PlaceHolderListCcSel]);


      console.log("resList", res);
      console.log("viewEmailUserList", viewEmailUserList);
      console.log("**filteredEmailUserList", filteredEmailUserList);
      // console.log("**userList", userList);
      console.log("**userNameInput", userNameInput);
      console.log("**userGroup", userGroup);
      console.log("**userRoles", userRoles);
      let envLink = "";
      if (env === "DEV") {
        envLink = envUrls.Dev;
      } else if (env === "QA") {
        envLink = envUrls.Qa;
      } else if (env === "UAT") {
        envLink = envUrls.Uat;
      } else if (env === "Prod") {
        envLink = envUrls.Prod;
      }
      let text = data.emailMessage
        .replaceAll("<br/>", "\n")
        .replace(/<hr\/>|<br\/>|Please click on this link to Login:/gi, "")
        .replace(
          new RegExp(`<a[^>]*href="${envLink}"[^>]*>(.*?)<\/a>`, "gi"),
          ""
        )
        .slice(12, -14)
        .trim();
      console.log("editReminder message", data.message, "text", text);
      setInitialValues({
        Mail_Subject: data.emailSubject,
        Mail_ReminderBefore: Math.abs(parseInt(data.reminderStartInDays)),
        Client: data.customerId
          ? { code: data.customerId, name: getClientName(data.customerId) }
          : "",
        RecordType: data.recordType,
        //Client: data.customerId ? data.customerId : "",
        Mail_Message: text,
        Due_Date_Type: "Filing",
        Notifications: data.isActive,
        //html: data.emailMessage.charAt(0) === "<",
        //html: data.isHTMLMessage
      });
      setFormValues({
        Mail_Subject: data.emailSubject,
        Mail_ReminderBefore:
          data.reminderStartInDays < 0
            ? Math.abs(parseInt(data.reminderStartInDays))
            : parseInt(data.reminderStartInDays),
        Client: data.customerId
          ? { code: data.customerId, name: getClientName(data.customerId) }
          : "",
        RecordType: data.recordType,
        //Client: data.customerId ? data.customerId : "",
        Mail_Message: text,
        Due_Date_Type: "Filing",
        Notifications: data.isActive,
        //html: data.emailMessage.charAt(0) === "<" ? true : false,
        //  html: data.isHTMLMessage
      });
    } catch (error) {
      console.log("EditError", error);
    }
  };
  // console.log("EmailTaskIdValue", emailTaskIdValue);
  // console.log("**initialValues", initialValues);
  // console.log("**formValues", formValues);
  console.log("ValuesCheck", initialValues, "initialValues", "formValues", formValues, "InitialEmailCcTo", initialEmailCCandTo);
  console.log("Emails InitialEmailCcTo", initialEmailCCandTo, "SelectedCombained", selectedEmailscombained, "selectedEmailscombained");
  // console.log("initialEmailCcandTo", initialEmailCCandTo);

  const footerContent = (
    <div>
      <PrimeButton
        label='OK'
        icon='pi pi-check'
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const closeAlert = () => {
    setShowAlert(false);
  };
  const renderDropdownItem = (option) => {
    if (!option) {
      return null;
    }

    const { name } = option;
    if (name && name.length > 25) {
      return (
        <Tooltip title={name} key={name}>
          <span>{name.substr(0, 25)}...</span>
        </Tooltip>
      );
    }
    return <span key={name}>{name}</span>;
  };
  const selectedValueTemplate = (option) => {
    if (!option) {
      return null;
    }

    const { name } = option;
    if (name && name.length > 25) {
      return (
        <Tooltip title={name} key={name}>
          <span>{name.substr(0, 25)}...</span>
        </Tooltip>
      );
    }
    return <span key={name}>{name}</span>;
  };
  const fetchReminderHistory = async (emailTaskId) => {
    console.log("fetchReminderHistory props", emailTaskId);
    try {
      let res = await axios.get(
        `${API_BASE_URL}/Application/ReminderAudit?EmailTaskId=${emailTaskId}`
      );
      let history = res.data.data;
      // let history =
      //   [{
      //     "id": 1,
      //     "process": "Newly added",
      //     "createdate": null,
      //     "createuser": null,
      //     "updatedate": null,
      //     "updateuser": null,
      //     "historyId": 0,
      //     "new_HistoryId": 0,
      //     "emailTaskId": 52,
      //     "emailTaskName": null,
      //     "new_EmailTaskName": null,
      //     "emailSubject": null,
      //     "new_EmailSubject": null,
      //     "emailMessage": null,
      //     "new_EmailMessage": null,
      //     "isHTMLMessage": null,
      //     "new_IsHTMLMessage": null,
      //     "isReminder": null,
      //     "new_IsReminder": null,
      //     "reminderStartInDays": null,
      //     "new_ReminderStartInDays": null,
      //     "reminderFrequencyInDays": null,
      //     "new_ReminderFrequencyInDays": null,
      //     "reminderBasedOnField": null,
      //     "new_ReminderBasedOnField": null,
      //     "customerId": null,
      //     "new_CustomerId": null,
      //     "filingId": null,
      //     "new_FilingId": null,
      //     "lastReminderDate": null,
      //     "new_LastReminderDate": null,
      //     "isActive": null,
      //     "new_IsActive": null,
      //     "reminderDate": null,
      //     "new_ReminderDate": null,
      //     "recordType": null,
      //     "new_RecordType": null,
      //     "userName": null,
      //     "new_UserName": null,
      //     "userCategory": null,
      //     "new_UserCategory": null,
      //     "emailTo": null,
      //     "new_EmailTo": null,
      //     "emailCC": null,
      //     "new_EmailCC": null,
      //     "emailBCC": null,
      //     "new_EmailBCC": null
      //   },
      //   {
      //     "id": 2,
      //     "process": "Newly added",
      //     "createdate": null,
      //     "createuser": null,
      //     "updatedate": null,
      //     "updateuser": null,
      //     "historyId": 0,
      //     "new_HistoryId": 0,
      //     "emailTaskId": 52,
      //     "emailTaskName": null,
      //     "new_EmailTaskName": null,
      //     "emailSubject": null,
      //     "new_EmailSubject": null,
      //     "emailMessage": null,
      //     "new_EmailMessage": null,
      //     "isHTMLMessage": null,
      //     "new_IsHTMLMessage": null,
      //     "isReminder": null,
      //     "new_IsReminder": null,
      //     "reminderStartInDays": null,
      //     "new_ReminderStartInDays": null,
      //     "reminderFrequencyInDays": null,
      //     "new_ReminderFrequencyInDays": null,
      //     "reminderBasedOnField": null,
      //     "new_ReminderBasedOnField": null,
      //     "customerId": null,
      //     "new_CustomerId": null,
      //     "filingId": null,
      //     "new_FilingId": null,
      //     "lastReminderDate": null,
      //     "new_LastReminderDate": null,
      //     "isActive": null,
      //     "new_IsActive": null,
      //     "reminderDate": null,
      //     "new_ReminderDate": null,
      //     "recordType": null,
      //     "new_RecordType": null,
      //     "userName": null,
      //     "new_UserName": null,
      //     "userCategory": null,
      //     "new_UserCategory": null,
      //     "emailTo": null,
      //     "new_EmailTo": null,
      //     "emailCC": null,
      //     "new_EmailCC": null,
      //     "emailBCC": null,
      //     "new_EmailBCC": null
      //   },
      //   {
      //     "id": 3,
      //     "process": "Newly added",
      //     "createdate": "2024-07-02T07:55:50.62",
      //     "createuser": "184",
      //     "updatedate": null,
      //     "updateuser": null,
      //     "historyId": 0,
      //     "new_HistoryId": 0,
      //     "emailTaskId": 52,
      //     "emailTaskName": null,
      //     "new_EmailTaskName": "Reminder_2_ABC KT",
      //     "emailSubject": null,
      //     "new_EmailSubject": "Test subject",
      //     "emailMessage": null,
      //     "new_EmailMessage": "<html><body>Please click on this link to Login:<br/><a href=\"jsi-tracknow-cert.azurewebsites.net\">TrackNowLogin</a><hr/><br/>Test Message<br/></body></html>",
      //     "isHTMLMessage": null,
      //     "new_IsHTMLMessage": true,
      //     "isReminder": null,
      //     "new_IsReminder": true,
      //     "reminderStartInDays": null,
      //     "new_ReminderStartInDays": -2,
      //     "reminderFrequencyInDays": null,
      //     "new_ReminderFrequencyInDays": 1,
      //     "reminderBasedOnField": null,
      //     "new_ReminderBasedOnField": "FilingDueDate",
      //     "customerId": null,
      //     "new_CustomerId": 249,
      //     "filingId": null,
      //     "new_FilingId": null,
      //     "lastReminderDate": null,
      //     "new_LastReminderDate": null,
      //     "isActive": null,
      //     "new_IsActive": true,
      //     "reminderDate": null,
      //     "new_ReminderDate": null,
      //     "recordType": null,
      //     "new_RecordType": "PostAward",
      //     "userName": null,
      //     "new_UserName": "Colleen.Cook@jsitel.com, swapnag@infinite.com, Evette.Head@jsitel.com",
      //     "userCategory": null,
      //     "new_UserCategory": "Users, Users, Users",
      //     "emailTo": null,
      //     "new_EmailTo": "False, True, True",
      //     "emailCC": null,
      //     "new_EmailCC": "True, False, False",
      //     "emailBCC": null,
      //     "new_EmailBCC": "False, False, False"
      //   }
      //   ]
      setActivityHistory(() => history);
      console.log("fetchReminderHistory res", history);
    } catch (error) {
      console.log("@@Error:", error);
    }

  };

  const displayHomePage = () => {
    navigate("/");
  };
  const displayTrackNowFilingMasterList = () => {
    navigate("/filing-master-list")
  };
  const displayPostAwardFilingMasterList = () => {
    navigate("/postaward-filing-master-list");
  };
  const displayClientFilingTrackerList = () => {
    navigate("/client-filing-tracker-list");
  };

  const closeHistory = () => {
    // console.log("Toggling Show Filter", showHistory);
    setShowHistory(() => false);
  };
  const displayHistory = async () => {
    console.log("displayHistory", selectedEmail);
    if (selectedEmail && selectedEmail.emailTaskId) {
      console.log(`Fetching History for :${selectedEmail.emailTaskId}`);
      fetchReminderHistory(selectedEmail.emailTaskId);
      setShowHistory(() => true);
    } else {
      showAlert("Info", "Please Select a reminder", "info");
      // closeDialog();
    }
  };

  const isFormChanged =
    JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  console.log("isFormChanged", isFormChanged);
  // console.log("selectedEmail", selectedEmail);
  const handleSelect = (e) => {
    // console.log("handleSelect e", e);
    setSelectedEmail(e.value)
  }
  return (
    <React.Fragment>
      {isLoading ? (
        // Display the loading indicator
        <Loader /> // Replace with your loading component
      ) : (
        <Stack
          direction='column'
          // justifyContent='flex-start'
          // alignItems='center'
          spacing={2}
          style={{ margin: "1rem", width: "100%" }}
        >
          <AppBar position='relative' color='inherit' sx={{ width: "100%" }}>

            <Stack
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              spacing={2}
            >
              <IconButton
                className='nav-bar__logo__img'
                sx={{ display: { xs: "none", md: "flex" } }}
                onClick={backToHome}
              >
                <Box sx={{ m: 2 }}>
                  <ArrowBackIosNewIcon fontSize='medium' sx={{ mt: 1 }} />
                </Box>
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant='h6' color='inherit' sx={{ mr: 1 }}>
                  SETTINGS
                </Typography>
              </Box>
            </Stack>

          </AppBar>
          {/* <Stack width="100%" display="flex" direction="row"> */}
          <Container
            className='reminderContainer'
            sx={{
              maxWidth: "1300px",
              width: "100%",
              marginLeft: "0px",
              borderRadius: "10px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
              backgroundColor: "#ffffff",
              display: "flex",
              flexDirection: "column",
              //justifyContent: "flex-start",
            }}
          >
            <Box sx={{ padding: "30px 0.5rem" }}>
              <Stack direction='column' spacing={4}>
                <Stack direction='row' justifyContent='space-between'>
                  <div>
                    <Typography style={{ fontWeight: "bold" }}>
                      REMINDER CONFIGURATION
                    </Typography>
                  </div>
                  <div>
                    <Stack spacing={3} direction='row'>
                      <Stack>
                        <Button
                          sx={{
                            ...mailButtonStyle,
                            "&:hover": {
                              backgroundColor: mailButtonStyle.backgroundColor,
                            },
                          }}
                        >
                          E-Mail
                        </Button>
                      </Stack>
                    </Stack>
                  </div>
                </Stack>
                <Stack direction='row' justifyContent='space-between'>
                  <div style={{ width: "100%", marginRight: "1rem" }}>
                    <Stack direction='column' spacing={2}>
                      <Stack direction='row' spacing={3}>
                        <div style={{ width: "100%" }}>
                          <Typography>Client</Typography>
                          <Dropdown
                            name='Client'
                            showClear={formValues.Client !== ""}
                            value={formValues.Client}
                            onChange={handleConfigItemChange}
                            options={customerList}
                            optionLabel='name'
                            autoFocus
                            style={{
                              width: "100%",
                              marginTop: "5px",
                              height: "50px",
                            }}
                            valueTemplate={selectedValueTemplate}
                            itemTemplate={renderDropdownItem}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <Typography style={{ paddingBottom: "1rem" }}>
                            Reminders
                          </Typography>
                          <InputSwitch
                            name='Notifications'
                            checked={formValues.Notifications}
                            onChange={handleNotificationChange}
                          />
                        </div>
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={1}
                        style={{ marginTop: "10px" }}
                      >
                        <Stack direction='column'>
                          <Typography>Recipient</Typography>
                          <Stack direction='row' justifyContent='space-between'>
                            <Stack>
                              <Stack direction='row' spacing={1}>
                                <TextField
                                  sx={{ width: 300 }}
                                  id='recipientMail'
                                  label='Enter Email'
                                  variant='outlined'
                                  value={emailInput}
                                  onChange={handleEmailInputChange}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      handleAddEmailChip();
                                    }
                                  }}
                                  fullWidth
                                  error={Boolean(emailError)}
                                  helperText={emailError}
                                ></TextField>
                                <IconButton onClick={handleOpenMenu}>
                                  <AddCircleRoundedIcon color='primary' />
                                </IconButton>
                              </Stack>
                              {isRecipientValidation && (
                                <Typography
                                  style={{ fontSize: "0.8rem" }}
                                  color='error'
                                >
                                  Please Enter Recipient
                                </Typography>
                              )}
                            </Stack>
                            {/* <TextField sx={{ width: 200 }}
                              label="Reminder Name"
                              variant="outlined"
                              value={selectedReminderName}
                              disabled={!isCreating}
                              onChange={handleReminderName} /> */}
                          </Stack>
                        </Stack>
                        {/* <Paper style={{ padding: '10px' }}> */}

                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                        >
                          <MenuItem disabled>Emails:</MenuItem>
                          {userNameList.map((user, index) => (
                            <MenuItem
                              key={`email_${index}`}
                              onClick={() => handleSelectedValueUserName(user)}
                            >
                              <ListItemIcon>
                                <AddCircleIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={`${user.placeHolderName} (${user.userEmailId})`}
                              />
                              {selectedUserName.some(
                                (selectedUser) =>
                                  selectedUser.userEmailId === user.userEmailId
                              ) ? (
                                <CheckCircleIcon color='primary' />
                              ) : null}
                            </MenuItem>
                          ))}

                          {/* <MenuItem disabled>Email Groups:</MenuItem>
                          {userGroupList.map((list, index) => (
                            <MenuItem
                              key={`list${index}`}
                              onClick={() =>
                                handleGroupList(list.placeHolderName)
                              }
                            >
                              <ListItemIcon>
                                <AddCircleIcon />
                              </ListItemIcon>
                              <ListItemText primary={list.placeHolderName} />
                              {selectedUserGroup.includes(list) ? (
                                <CheckCircleIcon color='primary' />
                              ) : null}
                            </MenuItem>
                          ))} */}
                          <MenuItem disabled>Place Holders:</MenuItem>
                          {placeHolderList.map((email, index) => {
                            // Remove double curly braces from email.placeHolderName
                            const cleanedPlaceHolderName =
                              email.placeHolderName.replace(/{{|}}/g, "");

                            return (
                              <MenuItem
                                key={`email_${index}`}
                                onClick={() =>
                                  handleSelectPlaceHolderList(
                                    email.placeHolderName
                                  )
                                }
                              >
                                <ListItemIcon>
                                  <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={cleanedPlaceHolderName}
                                />
                                {selectedPlaceHolderList.includes(
                                  email.placeHolderName
                                ) ? (
                                  <CheckCircleIcon color='primary' />
                                ) : null}
                              </MenuItem>
                            );
                          })}

                          <MenuItem disabled>User Roles:</MenuItem>
                          {userRoleList &&
                            userRoleList.map((email, index) => (
                              <MenuItem
                                key={`email_${index}`}
                                onClick={() =>
                                  handleSelectRoleName(email.placeHolderName)
                                }
                              >
                                <ListItemIcon>
                                  <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={email.placeHolderName} />
                                {selectedRoleList.includes(
                                  email.placeHolderName
                                ) ? (
                                  <CheckCircleIcon color='primary' />
                                ) : null}
                              </MenuItem>
                            ))}
                        </Menu>
                      </Stack>
                      <div>
                        <div>
                          {selectedUserName.map((user, index) => (
                            <Chip
                              key={index}
                              label={user.placeHolderName}
                              onDelete={() => handleRemoveValue(user)}
                              style={{ margin: "5px" }}
                            />
                          ))}
                        </div>

                        {selectedUserNameInput.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveValueInput(value)}
                            style={{ margin: "5px" }}
                          />
                        ))}
                        {/* {selectedUserGroup.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveUserGroup(value)}
                            style={{ margin: "5px" }}
                          />
                        ))} */}
                        {selectedPlaceHolderList.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveValuePlace(value)}
                            sx={{ margin: "4px" }}
                          />
                        ))}
                        {selectedRoleList.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveValueRoles(value)}
                            sx={{ margin: "4px" }}
                          />
                        ))}
                      </div>
                      <Stack
                        direction='row'
                        spacing={1}
                        style={{ marginTop: "10px" }}
                      >
                        <Stack direction='column'>
                          <Typography>CC</Typography>
                          <Stack direction='row' justifyContent='space-between'>
                            <Stack direction='row' spacing={1}>
                              <TextField
                                sx={{ width: 300 }}
                                id='cCMail'
                                label='Enter Email'
                                variant='outlined'
                                value={emailInputCc}
                                onChange={handleEmailInputChangeCc}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    handleAddEmailChipCc();
                                  }
                                }}
                                fullWidth
                                error={Boolean(emailErrorCc)}
                                helperText={emailErrorCc}
                              ></TextField>

                              <IconButton onClick={handleOpenMenuCc}>
                                <AddCircleRoundedIcon color='primary' />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Stack>
                        {/* <Paper style={{ padding: '10px' }}> */}

                        <Menu
                          anchorEl={anchorE2}
                          open={Boolean(anchorE2)}
                          onClose={handleCloseMenuCc}
                        >
                          <MenuItem disabled>Emails:</MenuItem>
                          {userNameListCc.map((user, index) => (
                            <MenuItem
                              key={`email_${index}`}
                              onClick={() =>
                                handleSelectedValueUserNameCc(user)
                              }
                            >
                              <ListItemIcon>
                                <AddCircleIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={`${user.placeHolderName} (${user.userEmailId})`}
                              />
                              {selectedUserNameCc.some(
                                (selectedUser) =>
                                  selectedUser.userEmailId === user.userEmailId
                              ) ? (
                                <CheckCircleIcon color='primary' />
                              ) : null}
                            </MenuItem>
                          ))}
                          {/* <MenuItem disabled>Email Groups:</MenuItem>
                          {userGroupListCc.map((list, index) => (
                            <MenuItem
                              key={`list${index}`}
                              onClick={() =>
                                handleGroupListCc(list.placeHolderName)
                              }
                            >
                              <ListItemIcon>
                                <AddCircleIcon />
                              </ListItemIcon>
                              <ListItemText primary={list.placeHolderName} />
                              {selectedUserGroupCc.includes(list) ? (
                                <CheckCircleIcon color='primary' />
                              ) : null}
                            </MenuItem>
                          ))} */}
                          <MenuItem disabled>Place Holders:</MenuItem>
                          {placeHolderListCc.map((email, index) => {
                            const cleanedPlaceHolderName =
                              email.placeHolderName.replace(/{{|}}/g, "");

                            return (
                              <MenuItem
                                key={`email_${index}`}
                                onClick={() =>
                                  handleSelectPlaceHolderListCc(
                                    email.placeHolderName
                                  )
                                }
                              >
                                <ListItemIcon>
                                  <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={cleanedPlaceHolderName}
                                />
                                {selectedPlaceHolderListCc.includes(
                                  email.placeHolderName
                                ) ? (
                                  <CheckCircleIcon color='primary' />
                                ) : null}
                              </MenuItem>
                            );
                          })}
                          <MenuItem disabled>User Roles:</MenuItem>
                          {userRoleListCc &&
                            userRoleListCc.map((email, index) => (
                              <MenuItem
                                key={`email_${index}`}
                                onClick={() =>
                                  handleSelectRoleNameCc(email.placeHolderName)
                                }
                              >
                                <ListItemIcon>
                                  <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={email.placeHolderName} />
                                {selectedRoleListCc.includes(
                                  email.placeHolderName
                                ) ? (
                                  <CheckCircleIcon color='primary' />
                                ) : null}
                              </MenuItem>
                            ))}
                        </Menu>
                      </Stack>
                      <div>
                        {selectedUserNameCc.map((user, index) => (
                          <Chip
                            key={index}
                            label={user.placeHolderName}
                            onDelete={() => handleRemoveValueCc(user)}
                            style={{ margin: "5px" }}
                          />
                        ))}
                        {selectedUserNameInputCc.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveValueInputCc(value)}
                            style={{ margin: "5px" }}
                          />
                        ))}
                        {/* {selectedUserGroupCc.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveUserGroupCc(value)}
                            style={{ margin: "5px" }}
                          />
                        ))} */}
                        {selectedPlaceHolderListCc.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveValuePlaceCc(value)}
                            sx={{ margin: "4px" }}
                          />
                        ))}
                        {selectedRoleListCc.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveValueRolesCc(value)}
                            sx={{ margin: "4px" }}
                          />
                        ))}
                      </div>
                      <Stack direction='row' spacing={3}>
                        <div style={{ width: "100%" }}>
                          <Typography>Record Type</Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "40%",
                              marginTop: "1rem",
                            }}
                          >
                            <div
                              className='flex align-items-center'
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RadioButton
                                inputId='TrackNow'
                                name='RecordType'
                                value='TrackNow'
                                onChange={(e) => {
                                  console.log("RecordType", e);
                                  handleConfigItemChange(e);
                                }}
                                checked={formValues.RecordType === "TrackNow"}
                              />
                              <label
                                htmlFor='TrackNow'
                              // style={{ marginLeft: "10px" }}
                              >
                                TrackNow
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RadioButton
                                inputId='PostAward'
                                name='RecordType'
                                value='PostAward'
                                onChange={(e) => {
                                  console.log("RecordType", e.value);
                                  handleConfigItemChange(e);
                                }}
                                checked={formValues.RecordType === "PostAward"}
                              />
                              <label
                                htmlFor='PostAward'
                              // style={{ marginLeft: "10px" }}
                              >
                                PostAward
                              </label>
                            </div>
                          </div>
                        </div>
                      </Stack>
                      <Stack direction='row' spacing={3}>
                        <div>
                          <Typography> Reminder Before Filing</Typography>
                          <TextField
                            label='Enter Days'
                            type='number'
                            name='Mail_ReminderBefore'
                            value={formValues.Mail_ReminderBefore}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='filled'
                            onChange={handleConfigItemChange}
                            sx={{ width: "250px", paddingTop: "5px" }}
                            required
                            error={
                              (isFormSubmitted &&
                                !formValues.Mail_ReminderBefore) ||
                              parseInt(formValues.Mail_ReminderBefore) < 0
                            } // show error if form submitted and field is empty
                            helperText={
                              isFormSubmitted && !formValues.Mail_ReminderBefore
                                ? "Please enter Reminder before filing"
                                : parseInt(formValues.Mail_ReminderBefore) < 0
                                  ? "Please enter a non-negative value"
                                  : ""
                            }
                            inputProps={{
                              min: 1,
                            }}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <Typography>Due Date Option</Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              marginTop: "1rem",
                            }}
                          >
                            <div
                              className='flex align-items-center'
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RadioButton
                                inputId='Filing'
                                name='Due_Date_Type'
                                value='Filing'
                                onChange={(e) => {
                                  console.log("Due_Date_Type value", e);
                                  handleConfigItemChange(e);
                                }}
                                checked={formValues.Due_Date_Type === "Filing"}
                              />

                              <label
                                htmlFor='Filing'
                              // style={{ marginLeft: "10px" }}
                              >
                                Filing
                              </label>
                            </div>
                            {/* <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RadioButton
                                inputId='Internal'
                                name='Due_Date_Type'
                                value='Internal'
                                onChange={(e) => {
                                  console.log("Due_Date_Type value", e.value);
                                  handleConfigItemChange(e);
                                }}
                                checked={
                                  formValues.Due_Date_Type === "Internal"
                                }
                                disabled={formValues.RecordType === "TrackNow"}
                              />
                              <label
                                htmlFor='Internal'
                              // style={{ marginLeft: "10px" }}
                              >
                                Internal
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RadioButton
                                inputId='Both'
                                name='Due_Date_Type'
                                value='Both'
                                onChange={(e) => {
                                  console.log("Due_Date_Type value", e.value);
                                  handleConfigItemChange(e);
                                }}
                                checked={formValues.Due_Date_Type === "Both"}
                              />
                              <label
                                htmlFor='Both'
                              // style={{ marginLeft: "10px" }}
                              >
                                Both
                              </label>
                            </div> */}
                          </div>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "small",
                              marginTop: "1rem",
                              color: "red",
                              display:
                                isFormSubmitted && !formValues.Due_Date_Type
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Please enter Due Date option
                          </Typography>
                        </div>
                      </Stack>
                    </Stack>
                  </div>

                  <div>
                    <Stack></Stack>
                    <Stack>
                      <Typography>Subject</Typography>
                      {/* <TextField id="filled-basic" label="Enter Subject" variant="filled" sx={{ paddingTop: "10px" }} /> */}

                      <TextField
                        id='filled-basic'
                        label='Enter Subject'
                        name='Mail_Subject'
                        variant='filled'
                        sx={{ paddingTop: "10px" }}
                        value={formValues.Mail_Subject}
                        onChange={handleConfigItemChange}
                        required
                        error={isFormSubmitted && !formValues.Mail_Subject} // show error if form submitted and field is empty
                        helperText={
                          isFormSubmitted && !formValues.Mail_Subject
                            ? "Subject is required"
                            : ""
                        }
                      />
                    </Stack>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "space-between",
                      }}
                    >
                      Message
                    </div>
                    <TextField
                      label='Enter Message'
                      multiline
                      name='Mail_Message'
                      rows={9}
                      value={formValues.Mail_Message}
                      variant='filled'
                      sx={{ width: "530px", paddingTop: "12px" }}
                      onChange={(e) => {
                        if (e.target.value.length <= 8000) {
                          handleConfigItemChange(e);
                          setRemainingChars(8000 - e.target.value.length);
                        }
                      }}
                      required
                      error={isFormSubmitted && !formValues.Mail_Message} // show error if form submitted and field is empty
                      helperText={
                        isFormSubmitted && !formValues.Mail_Message
                          ? "Enter a message"
                          : ""
                      }
                    />

                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "small",
                        color: remainingChars === 0 ? "red" : "inherit",
                        display: remainingChars === 8000 ? "none" : "block",
                      }}
                    >
                      {remainingChars} Characters are left
                    </Typography>
                  </div>
                </Stack>

                <Stack direction='row' justifyContent='end'>
                  <IconButton disabled={isPermission}>
                    <Button
                      style={{
                        color: "blue",
                        width: "100px",
                        height: "42px",
                        fontWeight: "bold",
                      }}
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </IconButton>
                  {isCreating ? (
                    <Button
                      variant='contained'
                      style={{
                        color: "white",
                        width: "100px",
                        height: "42px",
                        marginLeft: "20px",
                      }}
                      disabled={!isFormChanged || isPermission}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      style={{
                        color: "white",
                        width: "100px",
                        height: "42px",
                        marginLeft: "20px",
                      }}
                      disabled={!isFormChanged}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  )}
                </Stack>
                {/* <Button
                  variant='contained'
                  style={{
                    color: "white",
                    width: "100px",
                    height: "42px",
                    marginLeft: "20px",
                  }}
                  disabled={!isFormChanged}
                  onClick={handleSubmitCheck}
                >
                  Check
                </Button> */}
              </Stack>
            </Box>

            <div class Name='card' style={{ padding: "0.5rem" }}>
              <DataTable
                value={configurationListNew}
                tableStyle={{ minWidth: "50rem" }}
                loading={isDataTableLoading}
                selection={selectedEmail}
                dataKey="emailTaskId"
                onSelectionChange={(e) => handleSelect(e)}
              >
                <Column
                  selectionMode='single'
                  //headerStyle={{ width: "3rem" }}
                  style={{ width: "1rem", margin: "0" }}
                >
                  Select
                </Column>
                <Column
                  field='customerId'
                  header='Client'
                  body={(rowData) => (
                    <>
                      {rowData.customerId && rowData.customerId.length > 9 ? (
                        <Tooltip
                          title={getClientName(rowData.customerId)}
                          arrow
                        >
                          <span>{rowData.customerId.substring(0, 9)}...</span>
                        </Tooltip>
                      ) : (
                        getClientName(rowData.customerId)
                      )}
                    </>
                  )}
                ></Column>
                <Column
                  field='reminderStartInDays'
                  header='Reminder Days Before'
                  body={(rowData) => removeNegative(rowData)}
                ></Column>
                <Column
                  field='reminderBasedOnField'
                  header='Due Date Option'
                  body={(rowData) => dueDateFormat(rowData)}
                ></Column>
                <Column
                  field='isActive                  '
                  header='Reminders'
                  body={(rowData) => cellNotifications(rowData)}
                ></Column>
                <Column field='recordType' header='Record Type'></Column>
                <Column
                  field='emailSubject'
                  header='Email Reminder Subject'
                ></Column>
                <Column
                  field='emailMessage                  '
                  header='Email Reminder Message'
                  body={(rowData) => cellMessage(rowData)}
                ></Column>
                <Column
                  style={{ textAlign: "center" }}
                  body={(rowData) => (
                    <>
                      <Stack direction='row'>
                        <EditButton rowData={rowData} />
                        <DeleteButton rowData={rowData} />
                      </Stack>
                    </>
                  )}
                ></Column>
              </DataTable>
            </div>
          </Container>
          {/* <Box sx={{
              // height: "%",
              width: "5%",
              margin: "5px"
            }}>
              <div style={{
                background: "#F1F2F6",
                borderRadius: "2%",
              }}>
                <Item
                  sx={{
                    height: "50%",
                  }}
                >
                  <ReminderToolbox
                    displayHomePage={displayHomePage}
                    displayTrackNowFilingMasterList={displayTrackNowFilingMasterList}
                    displayPostAwardFilingMasterList={displayPostAwardFilingMasterList}
                    displayClientFilingTrackerList={displayClientFilingTrackerList}
                    displayHistory={displayHistory}

                  />
                </Item>
              </div>
            </Box> */}
          {/* </Stack> */}

          <ConfirmDialog />
          <NotificationsDialog />
          <Dialog
            header='Info'
            visible={showAlert}
            // style={{ width: "30vw" }}
            onHide={() => closeAlert()}
            footer={footerContent}
          >
            <p className='m-0'>{alertMessage}</p>
          </Dialog>
        </Stack>
      )}
      {/* {
        <ReminderHistory
          show={showHistory}
          dockAt="right"
          selectedEmail={selectedEmail}
          customerList={customerList}
          // commentActivityHistory={commentActivityHistory}
          activityHistory={activityHistory}
          handleClose={closeHistory}
        />} */}

    </React.Fragment>
  );
}
