import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  AlertColor,
  FormHelperText,
  FormLabel,
  Icon,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MuiButton from "@mui/material/Button";
import { Button } from "primereact/button";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AuthContext from "../../context/auth/AuthContext";
import CustomerContext from "../../context/customer/CustomerContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import { Customer } from "../../types/customer";
import states from "../../data/StateLookup.json";
// import { juristictionLookup } from "../../data/constants";
import { BusinessCategory } from "../../types/FilingMaster";
import { ProgramName } from "../../types/FilingMaster";
import MultiSelect from "../common/MultiSelect";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import { ArrowDropDown, ArrowDropUp, CheckBox } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import Loader from "../common/Loader";
import Alert from "@mui/material/Alert";

// import { transform } from "lodash";
// import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
// import { Toast } from "primereact/toast";
// import AddressForm from "./AddressForm";
// import PaymentForm from "./PaymentForm";
// import Review from "./Review";
// const categories = [
// { id: 1, category: "Telecom" },
// { id: 2, category: "Brodband" },
// { id: 3, category: "Wireless" },
// { id: 4, category: "Mobile" },
// ];
// function Copyright() {

// return (
// <Typography variant='body2' color='text.secondary' align='center'>
// {"Copyright © "}
// <Link color='inherit' href='https://mui.com/'>
// Your Website
// </Link>{" "}
// {new Date().getFullYear()}
// {"."}
// </Typography>
// );
// }

// const handleClickOpenServiceArea = () => {
// console.log("##Project:", projectObj.ID);
// if (projectObj.ID) setOpenServiceArea(true);
// else {
// setShowAlertMessage({
// message: "Please create the project prior to configuring Service Areas",
// title: "Info",
// handleClose: { handleCloseAlert },
// });
// setShowAlert(true);
// }
interface IClient {
  customerId: number;
  label: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const theme = createTheme();

const validationSchema = yup.object({
  customerName: yup
    .string()
    .min(2, "Name is too Short!")
    .max(100, "Name is too Long!")
    .required("Client Name is required"),
  email: yup.string().email("Enter a valid email"),
  // jsI_POC: yup
  //   .string()
  //   .email("Enter a valid email")
  //   .required("JSI-POC Contact is required"),
  phone: yup.string(),
  zipCode: yup
    .string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Invalid postal code"),
});

export default function CustomerForm() {
  const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const customerContext = useContext(CustomerContext);
  const filingMasterContext = useContext(FilingMasterContext);
  const { authUser, roleType } = authContext;
  const {
    createCustomer,
    customers,
    getCustomers,
    customerLoaded,
    customerLoading,
    createCustomerBusinessCategories,
    createCustomerProgramNames,
    deleteCustomerBusinessCategories,
    setCurrentCustomer,
    selectedCustomer,
    updateCustomer,
    // updateCustomerPostAward,
    initiateClientFilingWorkflow,
    initiatePostClientFilingWorkflow,
  } = customerContext;
  const { getBusinessCategories, getProgramName } = filingMasterContext;
  const [alertMessage, setAlertMessage] = React.useState<string | JSX.Element>(
    ""
  );
  // const [alertMessage, setAlertMessage] = React.useState<{
  // message: string;
  // type: AlertColor;
  // }>({ message: "", type: "success" });
  // const { showAlert, showSnackbar } = alertContext;
  const [categories, setCategories] = useState<BusinessCategory[]>([]);
  const [programOptions, setProgramOptions] = useState<ProgramName[]>([]);
  const [initialProgramNames, setInitialProgramNames] = useState<any[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<any[]>([]);
  const [selectedState, setSelectedState] = useState<string[]>([]);
  const [categoriesErrorText, setCategoriesErrorText] = useState<string>("");
  const [programsErrorText, setProgramsErrorText] = useState<string>("");
  const [stateErrorText, setStateErrorText] = useState<string>("");
  const [isCategoryChanged, setIsCategoryChanged] = useState(false);
  const [isProgramsChanged, setIsProgramsChanged] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [initCategories, setInitCategories] = useState<string[]>([]);
  const [initStates, setInitStates] = useState<string[]>([]);
  const [formTitle, setFormTitle] = useState<string>("ADD NEW CLIENT");
  const [actionLabel, setActionLabel] = useState<string>("ADD");

  const [openOption, setOpenOption] = useState(false);
  const [remainingChars, setRemainingChars] = useState(2000);
  const [openSnack, setOpenSnack] = React.useState("");

  const fontStyle = {
    fontWeight: "regular",
    fontSize: "13px",

    color: "#424242",
  };
  // const toast = useRef(null);
  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  console.log("formTitle1", formTitle);

  const licenseTypes = [
    { TrackNowLicenseId: 1, TrackNowLicenseName: "Essential" },
    { TrackNowLicenseId: 2, TrackNowLicenseName: "plus" },
    { TrackNowLicenseId: 3, TrackNowLicenseName: "Premium" },
  ];

  const postAwardLicenseTypes = [
    { PostAwardLicenseId: 1, PostAwardLicenseName: "Essential" },
    { PostAwardLicenseId: 2, PostAwardLicenseName: "Premium" },
    { PostAwardLicenseId: 3, PostAwardLicenseName: "Platinum" },
  ];

  const initForm: Customer = {
    customerId: 0,
    customerName: "",
    businessCategoryId: 0,
    parentCustomerId: 0,
    jsI_POC: "",
    //licenseFullName: "",
    trackNowLicenseId: "",
    postAwardLicenseId: "",
    //liscenseShortName: "",
    state: [],
    customerState: [],
    // businessCategories:[],
    // createDate: null,
    // createUser: null,
    // updateDate: null,
    // updateUser: null,
  };
  const [showAlert, setShowAlert] = React.useState(false);
  const [customer, setCustomer] = React.useState<Customer>(initForm);
  const [clients, setClients] = React.useState<IClient[]>([]);
  const [parentClient, setParentClient] = React.useState<IClient | null>(null);
  const [selectedParentName, setSelectedParentName] =
    React.useState<string>("");
  const [initParentClient, setInitParentClient] =
    React.useState<IClient | null>(null);
  const [initParentName, setInitParentName] = React.useState<string>("");
  const [clientType, setClientType] = React.useState<string>("Parent");
  const navigate = useNavigate();
  const searchRef = useRef<HTMLInputElement>(null);
  const [stateList, setStateList] = useState([]);
  const backToHome = () => {
    navigate("/");
  };
  const onChange = async (e: React.ChangeEvent<any>) => {
    // await hasTokenExpired();
    // let value = "";
    // if (e.target.name === "groupName") {
    // getGroupId();
    // }
    console.log(`Setting [${e.target.name}]: ${e.target.value}`);
    setCustomer((cust) => ({ ...cust, [e.target.name]: e.target.value }));
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setCustomer((cust) => ({ ...cust, businessCatergoryId: parseInt(value) }));
  };

  const handleStateChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setCustomer((cust) => ({ ...cust, state: [value] }));
  };

  const addCategoriers = async (cats: string[]): Promise<boolean> => {
    console.log(`Add Business Category changes for`, cats);
    return new Promise(async (resolve, reject) => {
      if (createCustomerBusinessCategories && cats.length > 0) {
        try {
          const payloadList = cats.map((item) => ({
            customerId: customer.customerId,
            businessCategoryId: item,
          }));

          const res = await createCustomerBusinessCategories(payloadList);
          if (res) {
            resolve(true);
          }
        } catch (error) {
          console.log(
            `Error while saving Client Business Category changes`,
            error
          );
          reject(false);
        }
      } else {
        resolve(true);
      }
    });
  };
  const getRecordIdForDelete = (catId: string): number => {
    let _id;
    if (selectedCustomer && selectedCustomer.businessCategory) {
      _id = selectedCustomer?.businessCategory.filter(
        (item) => !item.state && item.businessCategoryId === parseInt(catId)
      )[0].id;
    }
    return _id ? _id : 0;
  };

  const deleteCategoriers = (cats: string[]): Promise<boolean> => {
    console.log(`Delete Business Category changes for`, cats);
    return new Promise(async (resolve, reject) => {
      if (deleteCustomerBusinessCategories && cats.length > 0) {
        let promises: Promise<boolean>[] = [];
        cats.forEach((item) => {
          promises.push(
            new Promise(async (resolve, reject) => {
              try {
                const _id = getRecordIdForDelete(item);
                const res = await deleteCustomerBusinessCategories(_id);
                console.log(`deleteCategoriers res`, res);
                if (res.data.status === "Failure") {
                  console.log(`Delete Business Category failure`, res.data);
                  // Resolve with false when "Failure"
                  setAlertMessage(res.data.errorMessage);
                  setShowAlert(true);
                  resolve(false);
                } else {
                  console.log(`Delete Business Category success`);
                  resolve(true); // Resolve with true for success
                }
              } catch (error) {
                reject(false);
              }
            })
          );
        });
        return Promise.all(promises)
          .then((results) => {
            const allSuccess = results.every((res) => res === true);
            if (allSuccess) {
              console.log(`Delete Business Category success`);
              resolve(true);
            } else {
              console.log(`Delete Business Category failure`);
              reject(false);
            }
          })
          .catch((err) => reject(false));
      } else {
        resolve(true);
      }
    });
  };

  const submitForm = async () => {
    console.log(`Submitting Add Customer: [${JSON.stringify(customer)}]`);
    const _parentCustomerId =
      parentClient && parentClient.customerId ? parentClient.customerId : 0;
    setIsLoading(true);
    try {
      if (createCustomer) {
        let _cust: any = {
          ...formik.values,
          customerId: customer.customerId,
          parentCustomerId: _parentCustomerId,
        };
        //   const _liscenseType = {
        //     trackNowLicenseId: 0,
        //     trackNowLicenseName: _cust.trackNowLicenseName,
        //     //liscenseShortName: _cust.liscenseShortName,
        //   };
        //   console.log("TRACKLISCENSETYPE", _liscenseType);

        //   const _postLiscenseType = {
        //     postAwardLicenseId: 0,
        //     postAwardLicenseName: _cust.postAwardLicenseName,
        //     //liscenseShortName: _cust.liscenseShortName,
        //   };
        //  console.log("POSTLISCENSETYPE", _postLiscenseType);

        // delete _cust.businessCatergoryId;
        console.log(`Submitting Add Customer2: ${_cust}`);
        let res: Customer;
        console.log("fcustomer", customer);
        console.log(
          `Submitting Add Customer2:"`,
          formik.values.trackNowLicenseId === 0 ||
            formik.values.trackNowLicenseId === "",
          formik.values.postAwardLicenseId === 0 ||
            formik.values.postAwardLicenseId === ""
        );
        if (
          (formik.values.trackNowLicenseId === 0 ||
            formik.values.trackNowLicenseId === "") &&
          (formik.values.postAwardLicenseId === 0 ||
            formik.values.postAwardLicenseId === "")
        ) {
          setAlertMessage(
            `Please select either TrackNow Subscription or Post Award Subscription`
          );
          setShowAlert(true);
          return null;
        }
        if (
          customer.customerId &&
          customer.customerId > 0 &&
          updateCustomer
          // && updateCustomerPostAward
        ) {
          console.log("custtt", _cust);
          // const updLiscenseType = {
          //   trackNowLicenseName: _cust.trackNowLicenseName,
          // };
          // let res5 = await axios.put(
          //   `${API_BASE_URL}/Customer/UpdateTrackNowLicense/${customer. trackNowLicenseId}`,
          //   updLiscenseType
          // );
          // if (res5) {
          //   _cust. trackNowLicenseId = customer. trackNowLicenseId;
          // }
          if (clientType == "Parent") {
            _cust.parentCustomerId = 0;
          }
          _cust.updateDate = new Date();
          _cust.updateUser = authUser?.userId + "";
          // delete _cust.businessCatergoryId;
          // delete _cust.notes;
          // delete _cust.poc;

          let updStateInfo: any[] = selectedState.map((item: any) => {
            const customerState = selectedCustomer?.customerState?.find(
              (state: any) => state.state === item
            );
            const stateId = customerState ? customerState.stateId : 0;

            return {
              stateId: stateId,
              customerId: _cust.customerId,
              state: item,
            };
          });

          let deleteState: number[] = [];

          if (selectedCustomer && selectedCustomer.customerState) {
            deleteState = selectedCustomer.customerState
              .filter((state: any) => !selectedState.includes(state.state))
              .map((state: any) => state.stateId);
          }

          if (deleteState.length > 0) {
            for (let stateId of deleteState) {
              await axios.delete(
                `${API_BASE_URL}/Customer/DeleteCustomerState/${stateId}`
              );
            }
          }

          console.log("updStateInfo", updStateInfo);
          console.log("deleteState", deleteState);

          let updState = await axios.put(
            `${API_BASE_URL}/Customer/UpdateCustomerState`,
            updStateInfo
          );
          console.log("updState", updState);

          delete _cust.state;
          delete _cust.customerState;

          let PN: any =
            selectedCustomer && selectedCustomer.programinfo
              ? selectedCustomer.programinfo
              : [];

          //Find what is changed in programnames
          console.log("programnames", selectedPrograms, initialProgramNames);
          const _delProgs = _.difference(initialProgramNames, selectedPrograms);
          let _addProgs = _.difference(selectedPrograms, initialProgramNames);
          console.log("programnames", _delProgs, _addProgs);

          if (_delProgs.length > 0) {
            console.log(
              "programnamesDEL",
              _delProgs,
              selectedCustomer && selectedCustomer.programinfo
            );
            PN = PN.filter(
              (ele: any) => !_delProgs.includes(ele.programId + "")
            );
          }
          if (_addProgs.length > 0) {
            console.log("programnamesADD", _addProgs);
            _addProgs.forEach((ele) => {
              PN.push({
                customerProgramId: 0,
                programId: parseInt(ele),
                createDate: new Date(),
                createUser: authUser?.userId + "",
              });
            });
          }
          console.log("programnames", PN);

          _cust.programName = PN.map((ele: any) => ({
            customerProgramId: ele.customerProgramId,
            customerId:
              selectedCustomer &&
              selectedCustomer.customerId &&
              selectedCustomer.customerId,
            programId: ele.programId,
            updateDate: new Date(),
            updateUser: authUser?.userId + "",
          }));

          _cust.state = updStateInfo.reduce(
            (states, item, i) =>
              i === 0 ? item.state : `${states} , ${item.state}`,
            ""
          );

          let BC: any =
            selectedCustomer && selectedCustomer.businessCategory
              ? selectedCustomer.businessCategory
              : [];

          //Find what is changed in categories
          const _delCats = _.difference(initCategories, selectedCategories);
          let _addCats = _.difference(selectedCategories, initCategories);

          if (_delCats.length > 0) {
            console.log(
              "&&& DEL CATS",
              _delCats,
              selectedCustomer && selectedCustomer.businessCategory
            );
            BC = BC.filter((ele: any) =>
              _delCats.includes(ele.businessCategoryId + "")
                ? _delCats.includes(ele.businessCategoryId + "") &&
                  ele.state !== null
                : true
            );
          }
          if (_addCats.length > 0) {
            console.log("&&& ADD CATS", _addCats);
            _addCats.forEach((ele) => {
              BC.push({
                id: 0,
                state: null,
                businessCategoryId: parseInt(ele),
                createDate: new Date(),
                createUser: authUser?.userId + "",
              });
            });
          }
          console.log("&&& CATS", BC);

          _cust.businessCategory = BC.map((ele: any) => ({
            id: ele.id,
            customerId:
              selectedCustomer &&
              selectedCustomer.customerId &&
              selectedCustomer.customerId,
            state: ele.state,
            businessCategoryId: ele.businessCategoryId,
            updateDate: new Date(),
            updateUser: authUser?.userId + "",
          }));

          _cust.state = updStateInfo.reduce(
            (states, item, i) =>
              i === 0 ? item.state : `${states} , ${item.state}`,
            ""
          );
          //  res = await updateCustomer(_cust);

          _cust.postAwardLicenseId =
            formik.values.postAwardLicenseId === 0 ||
            formik.values.postAwardLicenseId === ""
              ? null
              : formik.values.postAwardLicenseId;

          _cust.trackNowLicenseId =
            formik.values.trackNowLicenseId === 0 ||
            formik.values.trackNowLicenseId === ""
              ? null
              : formik.values.trackNowLicenseId;

          _cust.businessCategory =
            _cust.businessCategory.length > 0 ? _cust.businessCategory : null;
          _cust.programName =
            _cust.programName.length > 0 ? _cust.programName : null;

          console.log(
            "check-cat-prog",
            _cust.businessCategory,
            _cust.programName
          );
          console.log(
            "postlicensename",
            _cust.postAwardLicenseName,
            formik.values.postAwardLicenseName
          );
          let resUpd: any;
          // console.log("value-check",formik.values,formik.values.programName,formik.values.businessCategory)
          resUpd = await updateCustomer(_cust);

          // if (
          //   selectedCustomer &&
          //   selectedCustomer.businessCategory &&
          //   selectedCustomer.businessCategory.length > 0 &&
          //   selectedCustomer &&
          //   selectedCustomer.programinfo &&
          //   selectedCustomer.programinfo.length > 0
          // ) {
          //   // Call both functions
          //   resUpd = await Promise.all([
          //     updateCustomer(_cust),
          //     updateCustomerPostAward(_cust),
          //   ]);
          // } else if (selectedCustomer && selectedCustomer.programinfo && selectedCustomer.programinfo.length > 0) {
          //   resUpd = await updateCustomerPostAward(_cust);
          // } else {
          //   resUpd = await updateCustomer(_cust);
          // }

          console.log("updateCustomer", resUpd);

          if (resUpd.status !== "Failure") {
            console.log(
              "postAward--",
              selectedCustomer?.postAwardLicenseId,
              formik.values?.postAwardLicenseId
            );
            console.log(
              "TracknowCheck-selCust",
              selectedCustomer,
              "FormValues",
              formik.values
            );

            console.log("SUBSCRIPTIPN CHECK");

            if (
              (selectedCustomer?.postAwardLicenseId === 0 ||
                selectedCustomer?.postAwardLicenseId === null) &&
              formik.values?.postAwardLicenseId !== 0 &&
              formik.values?.postAwardLicenseId !== "" &&
              selectedCustomer?.postAwardLicenseId !==
                formik.values?.postAwardLicenseId
            ) {
              let result1 = await postAwardWorkFlowGenerator(
                selectedCustomer?.customerId,
                authUser?.userId,
                "add"
              );
              console.log("result1", result1);
            }
            delete _cust.selectedParentName;

            if (_delCats.length === 0 && _addCats.length === 0) {
              console.log("&&& NO Change to categories");
              console.log(
                "UPDCHECK1",
                "resUpd",
                resUpd,
                "errorMessage",
                resUpd.errorMessage
              );

              setAlertMessage(
                <Typography>
                  Successfully Updated Client:{" "}
                  <strong>{formik.values.customerName}</strong>
                </Typography>
              );
              setShowAlert(true);
            } else {
              if (
                selectedCustomer?.trackNowLicenseId !==
                  formik.values?.trackNowLicenseId ||
                _delCats.length > 0 ||
                _addCats.length > 0
              ) {
                console.log(
                  "UPDCHECK0",
                  selectedCustomer?.trackNowLicenseId !==
                    formik.values?.trackNowLicenseId
                );
                if (initiateClientFilingWorkflow) {
                  let payloadWorkflow = _addCats.map((item) => ({
                    customerId: res?.customerId,
                    businessCategoryId: item,
                    businessOperation: "add",
                  }));
                  _delCats.forEach((item) => {
                    payloadWorkflow.push({
                      customerId: res?.customerId,
                      businessCategoryId: item,
                      businessOperation: "delete",
                    });
                  });
                  let result = await initiateClientFilingWorkflow(
                    customer.customerId,
                    payloadWorkflow
                  );
                  console.log("&&& initiateClientFilingWorkflow", result);
                  if (result) {
                    console.log(
                      "UPDCHECK2",
                      "resUpd",
                      resUpd,
                      "errorMessage",
                      resUpd.errorMessage
                    );
                    if (resUpd && resUpd.errorMessage) {
                      console.log(
                        "UPDCHECK2.1",
                        "resUpd",
                        resUpd,
                        "errorMessage",
                        resUpd.errorMessage
                      );
                      setSelectedCategories(() => []);
                      setInitCategories(() => []);
                      setAlertMessage(
                        // `Successfully Updated Client:${formik.values.customerName}`
                        resUpd.errorMessage
                      );
                      setShowAlert(true);
                      setRemainingChars(2000);
                    } else {
                      console.log(
                        "UPDCHECK2.2",
                        "resUpd",
                        resUpd,
                        "errorMessage",
                        resUpd.errorMessage
                      );
                      setSelectedCategories(() => []);
                      setInitCategories(() => []);

                      setAlertMessage(
                        <Typography>
                          Successfully Updated Client:
                          <strong>{formik.values.customerName}</strong>
                        </Typography>
                      );
                      setShowAlert(true);
                      setRemainingChars(2000);
                    }
                  }
                }
              }
            }
          } else {
            setAlertMessage(`Unexpected Error occured while updating Client`);
            setShowAlert(true);
            setRemainingChars(2000);
          }
        } else {
          //   const res3 = await axios.post(
          //     `${API_BASE_URL}/Customer/CreateTrackNowLicense`,
          //     _liscenseType
          //   );
          //  console.log("res3", res3.data.data);
          //   const licenseId = res3.data.data.tracknowLicenseId
          //   console.log("licenseId", licenseId);

          const postAwardLicenseId = formik.values?.postAwardLicenseId;
          const tracknowLiscenseId = formik.values?.trackNowLicenseId;
          console.log("postawardlicensestype", postAwardLicenseId);
          console.log("PAL", postAwardLicenseId, formik.values);
          _cust.postAwardLicenseId =
            formik.values.postAwardLicenseId === 0 ||
            formik.values.postAwardLicenseId === ""
              ? null
              : formik.values.postAwardLicenseId;
          const trackNowLicenseId = formik.values.trackNowLicenseId;
          console.log("tracknowlicensestype", trackNowLicenseId);
          console.log("TNL", trackNowLicenseId, formik.values);
          // _cust.trackNowLicenseId = licenseId;
          _cust.trackNowLicenseId =
            formik.values.trackNowLicenseId === 0 ||
            formik.values.trackNowLicenseId === ""
              ? null
              : formik.values.trackNowLicenseId;
          _cust.createDate = new Date();
          _cust.createUser = authUser?.userId + "";
          console.log("CUSTLIST", _cust);
          let stateInfo: any[] = selectedState.map((state: any) => ({
            stateId: 0,
            customerId: null,
            state: state,
          }));
          delete _cust.state;
          delete _cust.customerState;
          console.log("stateInfo", stateInfo);
          _cust.state = stateInfo.reduce(
            (states, item, i) =>
              i === 0 ? item.state : `${states} , ${item.state}`,
            ""
          );
          console.log("_cust", _cust);
          console.log(
            "FormValCheck#",
            formik.values,
            "CUST",
            _cust,
            "tracknowLiscenseId",
            tracknowLiscenseId
          );

          res = await createCustomer(_cust);

          if (res) {
            if (postAwardLicenseId) {
              postAwardWorkFlowGenerator(
                res.customerId,
                authUser?.userId,
                "add"
              );
            }
            const programPayLoad = programOptions
              .filter((program) =>
                selectedPrograms.includes(String(program.programId))
              )
              .map(({ programId }) => ({
                programId,
                customerId: res.customerId,
              }));
            console.log("programPayLoad", programPayLoad);
            let res333 = null;
            if (createCustomerProgramNames) {
              res333 = await createCustomerProgramNames(programPayLoad);
            }

            // const payloadp = programOptions.map((item) => ({
            //   customerId: res.customerId,
            //   programId: item,
            // }));

            // let res11 = null;
            // if (createCustomerProgramNames) {
            //   res11 = await createCustomerProgramNames(payloadp);
            // }

            // if (res11) {
            //   if (postAwardLicenseId) {
            //     if (initiatePostClientFilingWorkflow) {
            //       const payloadWorkflows = programOptions.map((item) => ({
            //         customerId: res.customerId,
            //         programId: item,
            //         businessOperation: "add",
            //         state: null,
            //       }));
            //       await initiatePostClientFilingWorkflow(res.customerId, payloadWorkflows);
            //     }
            //   }
            //   setAlertMessage(`Successfully Added Client:${formik.values.customerName}`);
            //   setShowAlert(true);
            //   // navigate("/");
            // } else {
            //   setAlertMessage(`Unexpected Error occurred while adding Client`);
            //   setShowAlert(true);
            // }

            const payload = selectedCategories.map((item) => ({
              customerId: res.customerId,
              businessCategoryId: item,
            }));
            let res1 = null;
            if (createCustomerBusinessCategories) {
              res1 = await createCustomerBusinessCategories(payload);
            }
            if (res1) {
              if (tracknowLiscenseId) {
                if (initiateClientFilingWorkflow) {
                  const payloadWorkflow = selectedCategories.map((item) => ({
                    customerId: res.customerId,
                    businessCategoryId: item,
                    businessOperation: "add",
                    state: null,
                  }));
                  await initiateClientFilingWorkflow(
                    res.customerId,
                    payloadWorkflow
                  );
                }
              }
              setAlertMessage(
                <Typography>
                  Successfully Added Client:{" "}
                  <strong>{formik.values.customerName}</strong>
                </Typography>
              );
              setShowAlert(true);
              // navigate("/");
            } else {
              setAlertMessage(`Unexpected Error occured while adding Client`);
              setShowAlert(true);
            }

            if (stateInfo[0]?.state.length > 0) {
              stateInfo.forEach((obj) => {
                obj.customerId = res.customerId;
              });
              const resState = await axios.post(
                `${API_BASE_URL}/Customer/CreateCustomerState`,
                stateInfo
              );
              console.log("resState", resState.data.data);
            }
          } else {
            setAlertMessage(`Unexpected Error occured while adding Client`);
            setShowAlert(true);
          }
        }
      }
    } catch (error) {
      console.error("ERRR", error);
      setAlertMessage(`Unexpected Error occured while adding Client`);
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  const postAwardWorkFlowGenerator = async (
    customerId: any,
    userId: any,
    subscriptionOperation: any
  ) => {
    console.log(
      "postAwardWorkFlowGenerator",
      customerId,
      userId,
      subscriptionOperation
    );
    if (initiatePostClientFilingWorkflow) {
      try {
        let res = await initiatePostClientFilingWorkflow(
          customerId,
          userId,
          subscriptionOperation
        );

        console.log("postAwardWorkFlowGenerator", res);
        return res;
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const handleCategoryChanges = (
    values: string[],
    keys: any,
    action: string
  ) => {
    console.log("@@Changed BusinessCategories:", values);
    console.log("@@Changed BusinessCategories Action:", action);
    setSelectedCategories((old) => values);
    validateCategories(values);
    // if (values.length > 0) setCategoriesErrorText("");
    // else setCategoriesErrorText("Federal Business Category is required");
    // formik.setFieldValue("businessCategoryId", selectedCategories, false);
    // console.log("@@Changed Is Dirty:",true);
    setIsCategoryChanged(true);
    validateCategories(values, true);
  };
  const handleProgramNameChanges = (
    values: string[],
    keys: any,
    action: string
  ) => {
    console.log("@@Changed ProgramNames:", values);
    console.log("@@Changed Program Action:", action);
    setSelectedPrograms((old) => values);
    // validateProgramNames(values);
    // if (values.length > 0) setCategoriesErrorText("");
    // else setCategoriesErrorText("Federal Business Category is required");
    // formik.setFieldValue("businessCategoryId", selectedCategories, false);
    // console.log("@@Changed Is Dirty:",true);
    setIsProgramsChanged(true);
    // validateProgramNames(values, true);
  };
  const handleStateChanges = (values: string[], keys: any, action: string) => {
    console.log("@@Changed States", values);
    console.log("@@Changed State Action:", action);
    setSelectedState((old) => values);

    setIsStateChanged(true);
    // validateStates(values, true);
  };

  const fetchBusinessCategories = async () => {
    console.log("@@Fetch BusinessCategories:", getBusinessCategories);
    if (getBusinessCategories) {
      console.log("@@Fetch BusinessCategories:");
      try {
        let catList = await getBusinessCategories();
        // Sorting filteredCatList alphabetically based on businessCategoryName
        console.log("@@BusinessCategories bef:", catList);

        catList.sort((a, b) =>
          (a.businessCategoryName ?? "").localeCompare(
            b.businessCategoryName ?? ""
          )
        );
        console.log("@@BusinessCategories aft:", catList);
        setCategories(() => catList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const fetchProgramName = async () => {
    console.log("@@Fetch ProgramNames:", getProgramName);
    if (getProgramName) {
      console.log("@@Fetch ProgramNames:");
      try {
        let progList = await getProgramName();
        progList = progList.sort((a, b) =>
          (a.programName ?? "").localeCompare(b.programName ?? "")
        );
        console.log("@@ProgramNames:", progList);
        setProgramOptions(() => progList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const fetchCustomers = async () => {
    console.log("@@Fetch Customers:", getCustomers);
    if (getCustomers) {
      let custList;
      if (customers) {
        custList = customers;
      } else {
        custList = await getCustomers();
      }

      console.log("ORIG Custs:", custList);
      const _clients = custList
        .filter((cust) => cust.parentCustomer === null)
        .map((client) => ({
          customerId: client.customerId ? client.customerId : 0,
          label: client.customerName ? client.customerName : "",
        }));
      // _clients.push({ customerId: 0, label: "" });
      console.log("Parent Custs:", _clients);
      setClients(() => _clients);
    }
  };

  useEffect(() => {
    // Add new client page not allowed to show for JSIPOC and JSIPOC POSTAWARD
    const isSingleJSIPOC_JSISME =
      (Array.isArray(roleType) &&
        roleType.length === 1 &&
        (roleType[0] === "JSIPOC" || roleType[0] === "JSIPOC-PostAward")) ||
      (Array.isArray(roleType) &&
        roleType.length === 2 &&
        roleType.includes("JSIPOC") &&
        roleType.includes("JSIPOC-PostAward"));
    console.log(
      "isSingleJSIPOC_JSISME",
      isSingleJSIPOC_JSISME,
      "selectedCustomer",
      selectedCustomer
    );
    if (isSingleJSIPOC_JSISME) {
      if (!selectedCustomer) {
        //   //Navigating to home page if user has either JSIPOC or JSIPOC-POSTAWARD or both
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    console.log("programinfo", selectedCustomer?.programinfo);

    if (
      programOptions.length > 0 &&
      selectedCustomer?.programinfo &&
      selectedCustomer?.programinfo.length > 0
    ) {
      const custProgramName = selectedCustomer?.programinfo.map(
        (item) => item.programId + ""
      );
      console.log("programinfo: custProgramName ", custProgramName);
      setSelectedPrograms(custProgramName);
      console.log("programinfo:SelectedPrograms", selectedPrograms);
      setInitialProgramNames(custProgramName);
      // validateProgramNames(custProgramName, true);
    }

    console.log("programinfo:programOptions", programOptions);
    //eslint-disable-next-line
  }, [programOptions]);
  console.log("programinfo:SelectedPrograms", selectedPrograms);

  useEffect(() => {
    if (
      categories &&
      selectedCustomer?.businessCategory &&
      selectedCustomer?.businessCategory.length > 0
    ) {
      const custBusinessCatergory = selectedCustomer?.businessCategory
        .filter((custCat) => custCat.state === null || custCat.state === "")
        .map((item) => item.businessCategoryId + "") as string[];
      console.log("Business cats Custs:", custBusinessCatergory);
      setSelectedCategories(() => custBusinessCatergory);
      console.log("catm");
      setInitCategories(() => custBusinessCatergory);
      validateCategories(custBusinessCatergory, true);
    }

    console.log("@@BusinessCategories Reeeeloaded:", categories);
    //eslint-disable-next-line
  }, [categories]);
  console.log("@@selectedCustomer2:", selectedCustomer);

  useEffect(() => {
    console.log(
      "categories$selectedCustomer",
      selectedCustomer,
      "categories",
      categories
    );
    if (selectedCustomer) {
      if (
        selectedCustomer.postAwardLicenseId &&
        selectedCustomer.trackNowLicenseId &&
        (selectedCustomer.tracknowJSIPOC === null ||
          selectedCustomer.tracknowJSIPOC === "") &&
        (selectedCustomer.postawardJSIPOC === null ||
          selectedCustomer.postawardJSIPOC === "")
      ) {
        console.log("@@selectedCustomerPoc:", selectedCustomer);
        setOpenSnack(
          "TrackNow JSI POC and PostAward JSI POC is not configured. Please Configure in User Roles Page!"
        );
      } else if (
        selectedCustomer.trackNowLicenseId &&
        (selectedCustomer.tracknowJSIPOC === null ||
          selectedCustomer.tracknowJSIPOC === "")
      ) {
        console.log("@@selectedCustomerPoc:", selectedCustomer?.tracknowJSIPOC);
        setOpenSnack(
          "TrackNow JSI POC  is not configured. Please Configure in User Roles Page!"
        );
      } else if (
        selectedCustomer.postAwardLicenseId &&
        (selectedCustomer.postawardJSIPOC === null ||
          selectedCustomer.postawardJSIPOC === "")
      ) {
        console.log("@@selectedCustomerPoc:", selectedCustomer?.tracknowJSIPOC);
        setOpenSnack(
          "PostAward JSI POC is not configured. Please Configure in User Roles Page!"
        );
      }
    }
    //eslint-disable-next-line
  }, [selectedCustomer]);
  console.log("openStack", openSnack);
  useEffect(() => {
    if (
      states &&
      selectedCustomer?.customerState &&
      selectedCustomer?.customerState.length > 0
    ) {
      const stateInfo = selectedCustomer?.customerState
        //.filter((custCat) => custCat.state === null || custCat.state === "")
        .map((item) => item.state + "") as string[];
      console.log("state cats Custs:", stateInfo);

      setSelectedState(() => stateInfo);
      setInitStates(() => stateInfo);
      // validateStates(stateInfo, true);
    }

    console.log("@@State Reeeeloaded:", states);
    //eslint-disable-next-line
  }, [states]);
  // console.log("@@selectedCustomer2:", selectedCustomer);

  useEffect(() => {
    // toggleDrawer(props.dockAt, props.show);
    if (selectedCustomer && selectedCustomer !== null) {
      console.log("@@selectedCustomer:", selectedCustomer);
      selectedCustomer && setCustomer(selectedCustomer);
      console.log("customeredit", customer);
      console.log("formIk1", formik.values);

      const _cust = {
        customerId:
          selectedCustomer && selectedCustomer.customerId
            ? selectedCustomer.customerId
            : 0,
        trackNowLicenseId:
          selectedCustomer && selectedCustomer.trackNowLicenseId
            ? selectedCustomer.trackNowLicenseId
            : 0,
        postAwardLicenseId:
          selectedCustomer && selectedCustomer.postAwardLicenseId
            ? selectedCustomer.postAwardLicenseId
            : 0,
        customerName:
          selectedCustomer && selectedCustomer.customerName
            ? selectedCustomer.customerName
            : "",
        address:
          selectedCustomer && selectedCustomer.address
            ? selectedCustomer.address
            : undefined,
        city:
          selectedCustomer && selectedCustomer.city
            ? selectedCustomer.city
            : undefined,

        programName:
          selectedCustomer && selectedCustomer.programName
            ? selectedCustomer.programName
            : undefined,

        state:
          selectedCustomer && selectedCustomer.state
            ? selectedCustomer.state
            : undefined,

        zipCode:
          selectedCustomer && selectedCustomer.zipCode
            ? selectedCustomer.zipCode
            : undefined,
        taxNumber:
          selectedCustomer && selectedCustomer.taxNumber
            ? selectedCustomer.taxNumber
            : undefined,
        phone:
          selectedCustomer && selectedCustomer.phone
            ? selectedCustomer.phone
            : undefined,
        email:
          selectedCustomer && selectedCustomer.email
            ? selectedCustomer.email
            : undefined,
        notes:
          selectedCustomer && selectedCustomer.notes
            ? selectedCustomer.notes
            : undefined,
        customer_POC:
          selectedCustomer && selectedCustomer.customer_POC
            ? selectedCustomer.customer_POC
            : undefined,
        jsI_POC:
          selectedCustomer && selectedCustomer.tracknowJSIPOC
            ? selectedCustomer.tracknowJSIPOC
            : undefined,
        postAward_JsI_POC:
          selectedCustomer && selectedCustomer.postawardJSIPOC
            ? selectedCustomer.postawardJSIPOC
            : undefined,
        businessCatergoryId: 0,
        stateId: 0,
        parentCustomerId:
          selectedCustomer &&
          selectedCustomer.parentCustomer &&
          selectedCustomer.parentCustomer.customerId
            ? selectedCustomer.parentCustomer.customerId
            : 0,
      };

      // if (
      // selectedCustomer?.businessCategory &&
      // selectedCustomer?.businessCategory.length > 0
      // ) {
      // const custBusinessCatergory = selectedCustomer?.businessCategory.map(
      // (item) => item.businessCategoryId + ""
      // ) as string[];
      // console.log("Business cats Custs:", custBusinessCatergory);
      // setSelectedCategories(custBusinessCatergory);
      // }
      selectedCustomer && formik.setValues(_cust);
      console.log("formil.setValues:", formik.values, "cust:", _cust);

      setFormTitle("EDIT CLIENT PROFILE");
      setActionLabel("Save");
      console.log("Parent Custs:", selectedCustomer?.parentCustomer);
      if (selectedCustomer?.parentCustomer) {
        const label = selectedCustomer?.parentCustomer.customerName
          ? selectedCustomer?.parentCustomer.customerName
          : "";
        const customerId = selectedCustomer?.parentCustomer.customerId
          ? selectedCustomer?.parentCustomer.customerId
          : 0;
        setClientType("Child");
        setInitParentClient({ label, customerId });
        setInitParentName(label);
      }
    }

    fetchBusinessCategories();
    fetchProgramName();
    fetchCustomers();
    // if (
    // categories &&
    // selectedCustomer?.businessCategory &&
    // selectedCustomer?.businessCategory.length > 0
    // ) {
    // const custBusinessCatergory = selectedCustomer?.businessCategory.map(
    // (item) => item.businessCategoryId + ""
    // ) as string[];
    // console.log("Business cats Custs:", custBusinessCatergory);
    // setSelectedCategories(() => custBusinessCatergory);
    // }

    return () => {
      if (setCurrentCustomer) setCurrentCustomer(null);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if (clientType && clientType === "Parent") {
    if (initParentClient && initParentName) {
      if (searchRef.current) {
        console.log("SELECTED Input:", searchRef.current?.value);
        searchRef.current.value = initParentName;
      }
      console.log("Clering SELECTED Parent:", parentClient);
      setParentClient(() => initParentClient);
      setSelectedParentName(() => initParentName);
      if (clientType == "Parent") {
        setSelectedParentName("");
      }
    } else {
      if (searchRef.current) {
        console.log("SELECTED Input:", searchRef.current?.value);
        searchRef.current.value = "";
      }
      console.log("Clering SELECTED Parent:", parentClient);
      setParentClient(() => null);
      setSelectedParentName(() => "");
    }
    // }
    console.log("SELECTED Parent1:", parentClient);
    console.log("SELECTED Parent2:", selectedParentName);
    //eslint-disable-next-line
  }, [clientType]);

  const closeAlert = () => {
    setShowAlert(false);
    navigate("/");
  };

  const validateCategories = (values: any, isDirect?: boolean) => {
    let error: any = {};

    console.log(
      "catchanges",
      isCategoryChanged,
      selectedCustomer,
      isDirect,
      formik.values.trackNowLicenseId
    );

    if (
      formik.values.trackNowLicenseId &&
      formik.values.trackNowLicenseId !== ""
    ) {
      if (isCategoryChanged || selectedCustomer || isDirect) {
        if (isDirect) {
          if (values.length <= 0) {
            setCategoriesErrorText("Federal Business Category is required");
            error.businessCategoryId = "please select a category";
          } else {
            setCategoriesErrorText("");
          }
        } else {
          if (selectedCategories.length <= 0) {
            setCategoriesErrorText("Federal Business Category is required");
            error.businessCategoryId = "please select a category";
          } else {
            setCategoriesErrorText("");
          }
        }
      }
    }
    return error;
  };

  const validateProgramNames = (values: any, isDirect?: boolean) => {
    let error: any = {};

    console.log(
      "catchanges",
      isProgramsChanged,
      selectedCustomer,
      isDirect,
      formik.values.postAwardLicenseId
    );

    if (
      formik.values.postAwardLicenseId &&
      formik.values.postAwardLicenseId !== ""
    ) {
      if (isProgramsChanged || selectedCustomer || isDirect) {
        if (isDirect) {
          if (values.length <= 0) {
            setProgramsErrorText("Program Name is required");
            error.programId = "please select a program Name";
          } else {
            setProgramsErrorText("");
          }
        } else {
          if (selectedPrograms.length <= 0) {
            setProgramsErrorText("Program Name is required");
            error.programId = "please select a program Name";
          } else {
            setProgramsErrorText("");
          }
        }
      }
    }
    return error;
  };

  const validateStates = (values: any, isDirect?: boolean) => {
    let error: any = {};
    if (isStateChanged || selectedCustomer || isDirect) {
      if (isDirect) {
        if (values.length <= 0) {
          setStateErrorText("State is required");
          error.stateId = "please select a state";
        } else {
          setStateErrorText("");
        }
      } else {
        if (selectedState.length <= 0) {
          setStateErrorText("State is required");
          error.stateId = "please select a state";
        } else {
          setStateErrorText("");
        }
      }
    }
    return error;
  };

  // };

  // const formik = useFormik({
  //   initialValues: initForm,
  //   validationSchema: validationSchema,
  //   validate: validateCategories,
  //   onSubmit: (values) => {
  //     submitForm();
  //   },
  // });

  const formik = useFormik({
    initialValues: initForm,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const errors = {
        ...validateCategories(values),
        // ...validateProgramNames(values),
        // ...validateStates(values),
      };

      if (Object.keys(errors).length === 0) {
        submitForm();
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    if (formik.values.notes) {
      const charsUsed = formik.values.notes.length;
      setRemainingChars(2000 - charsUsed);
    }
  }, [formik.values.notes]);
  useEffect(() => {
    if (selectedCustomer) {
      const custState = selectedCustomer.customerState;

      if (custState) {
        let stateEdit = custState.map((state: any) => ({
          stateId: state.stateId,
          customerId: state.customerId,
          state: state.state,
        }));
        formik.setFieldValue("customerState", stateEdit);
        const stateList = custState.map((item) => item.state);
        const convertedStateList = stateList as never[];
        setStateList(convertedStateList);
        console.log("convertedStateList", convertedStateList);
        console.log("stateEdit", stateEdit);
        console.log("FormIk", formik.values);
      } else {
        formik.setFieldValue("customerState", []);
        setStateList([]);
      }
    }
  }, [selectedCustomer]);
  useEffect(() => {
    if (formik.values.customerState) {
      console.log("custstate", formik.values.customerState);
      console.log("selCust", selectedCustomer?.customerState);
    }
  }, [formik.values.customerState]);

  // useEffect(() => {
  //   console.log("zipCode", formik.values.zipCode);
  //   let zipcode = formik.values.zipCode ? parseInt(formik.values.zipCode) : 0;
  //   let findState = states.filter((s) => s.min <= zipcode && s.max >= zipcode);
  //   console.log("zipCode findState", findState);
  //   formik.values.state = findState.length > 0 ? [findState[0].code] : [];
  // }, [formik.values.zipCode]);
  // useEffect(() => {
  //   console.log("zipCode", formik.values.zipCode);
  //   let zipcode = formik.values.zipCode ? parseInt(formik.values.zipCode) : 0;
  //   let findState = states.filter((s) => s.min <= zipcode && s.max >= zipcode);
  //   console.log("zipCode findState", findState);
  //   formik.setFieldValue(
  //     "customerState",
  //     findState.length > 0 ? [findState[0].code] : []
  //   );
  //   console.log("fieldValue", formik.values.customerState);
  // }, [formik.values.zipCode]);

  // const schema = yup.object().shape({
  //   notes: yup
  //     .string()
  //     .max(250, "Maximum 250 characters allowed")
  //     .required("Notes is required"),
  // });

  const handleParentChange = (
    event: any,
    newValue: IClient | null,
    reason: string
  ) => {
    console.log("SELECTED Parent:", newValue);
    setParentClient(() => newValue);
    setSelectedParentName(newValue?.label ? newValue?.label : "");
    // setOpenParent(false);
    // setValue(newValue);
    setOpenOption(false);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    // if (value === "Parent") {
    // console.log("SELECTED Parent type:", value);
    // setParentClient(() => undefined);
    // }
    setClientType(value);
  };

  function setOpen(arg0: boolean): void {
    throw new Error("Function not implemented.");
  }
  const handleSubmitCustom = (
    e?: React.FormEvent<HTMLFormElement> | undefined
  ) => {
    console.log("@@@ Before Submit:");
    setIsCategoryChanged(true);
    setIsProgramsChanged(true);
    setIsStateChanged(true);
    // validateStates(selectedState, true);
    // validateProgramNames(selectedPrograms, true);
    validateCategories(selectedCategories, true);

    formik.handleSubmit(e);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* {showAlert && (
  <Alert message={alertMessage?.message} type={alertMessage?.type} />
  )} */}
      {/* <Toast ref={toast} />
  <ConfirmDialog /> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <CssBaseline />

      <Box sx={{ flexGrow: 1, margin: "1rem" }}>
        <Stack style={{ margin: 0 }}>
          <AppBar position="static" color="inherit" sx={{ width: "99%" }}>
            <Toolbar>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <IconButton
                  className="nav-bar__logo__img"
                  sx={{ display: { xs: "none", md: "flex" } }}
                  onClick={backToHome}
                >
                  <Box sx={{ m: 2 }}>
                    <ArrowBackIosNewIcon fontSize="medium" sx={{ mt: 1 }} />
                  </Box>
                </IconButton>
                <Box sx={{ m: 2 }}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{
                      fontWeight: "medium",
                      fontSize: "16px",
                      transform: "upperCase",
                      color: "#424242",
                    }}
                  >
                    {formTitle}
                  </Typography>
                </Box>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            component="main"
            sx={{ margin: "auto", marginTop: "20px", width: "99%" }}
          >
            <React.Fragment>
              <Grid container direction="row" spacing={3}>
                <Grid xs={12} md={4}></Grid>
                <Grid xs={12} md={4}>
                  {!isLoading && openSnack !== "" ? (
                    <Alert style={{ fontSize: "small" }} severity="warning">
                      {openSnack}
                    </Alert>
                  ) : null}
                </Grid>
                <Grid xs={12} md={4}></Grid>
              </Grid>
            </React.Fragment>
          </Container>
          <Container
            component="main"
            sx={{ margin: "auto", marginTop: "30px", width: "99%" }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <React.Fragment>
                <form onSubmit={handleSubmitCustom} noValidate>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                  >
                    <Grid xs={12} md={4}>
                      <FormControl sx={{ width: "100%" }}>
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={5}
                        >
                          <Typography sx={fontStyle}>Client Type:</Typography>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Parent"
                            name="radio-buttons-group"
                            sx={{ justifyContent: "space-between" }}
                            value={clientType}
                            onChange={handleTypeChange}
                          >
                            <FormControlLabel
                              value="Parent"
                              control={<Radio />}
                              label="Parent"
                              sx={fontStyle}
                            />
                            <FormControlLabel
                              value="Child"
                              control={<Radio />}
                              label="Child"
                              sx={fontStyle}
                            />
                          </RadioGroup>
                        </Stack>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={4}>
                      {/* <Typography sx={fontStyle}>
  Select Parent Customer
  </Typography> */}

                      <Autocomplete
                        id="parentCustomerId"
                        sx={{ minWidth: "20rem" }}
                        value={parentClient}
                        onChange={handleParentChange}
                        options={clients.map((option) => option)}
                        inputValue={selectedParentName}
                        onInputChange={(event, newValue) => {
                          setSelectedParentName(newValue);
                        }}
                        open={openOption && clientType === "Child"}
                        disabled={clientType === "Parent"}
                        freeSolo={false}
                        onBlur={() => setOpenOption(false)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onClick={() => setOpenOption(true)}
                            label="Select Parent Client"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="dropdown-icon"
                                    onClick={() => setOpenOption(true)}
                                  >
                                    {openOption ? (
                                      <ArrowDropUp />
                                    ) : (
                                      <ArrowDropDown />
                                    )}
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                            inputRef={searchRef}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} sx={{ mt: 3 }}>
                    <Grid xs={12} sm={6} lg={4}>
                      <Typography sx={fontStyle}>Client Name</Typography>
                      <TextField
                        required
                        id="customerName"
                        name="customerName"
                        label="Client Name"
                        placeholder="Enter client name"
                        fullWidth
                        autoComplete="CustomerName"
                        variant="filled"
                        value={formik.values.customerName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.customerName &&
                          Boolean(formik.errors.customerName)
                        }
                        helperText={
                          formik.touched.customerName &&
                          formik.errors.customerName
                        }
                      />
                    </Grid>

                    {roleType.includes("JSIPOC") ||
                    roleType.includes("Admin") ||
                    roleType.includes("JSISME") ? (
                      <Grid xs={12} sm={6} lg={4}>
                        <Typography sx={fontStyle}>
                          TrackNow Subscription
                        </Typography>

                        <Select
                          id=" trackNowLicenseId"
                          name="trackNowLicenseId"
                          label="TrackNow Subscription"
                          fullWidth
                          variant="filled"
                          value={
                            formik.values.trackNowLicenseId
                              ? String(formik.values.trackNowLicenseId)
                              : ""
                          }
                          onChange={formik.handleChange}
                        >
                          <MenuItem value="">None</MenuItem>
                          {licenseTypes.map((type) => (
                            <MenuItem
                              key={type.TrackNowLicenseId}
                              value={type.TrackNowLicenseId}
                            >
                              {type.TrackNowLicenseName}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : null}

                    {roleType.includes("JSIPOC-PostAward") ||
                    roleType.includes("JSISME-PostAward") ||
                    roleType.includes("Admin-PostAward") ? (
                      <Grid xs={12} sm={6} lg={4}>
                        <Typography sx={fontStyle}>
                          Post Award Subscription
                        </Typography>
                        <Select
                          id="postAwardLicenseId"
                          name="postAwardLicenseId"
                          label="Post Award Subscription"
                          fullWidth
                          variant="filled"
                          value={
                            formik.values.postAwardLicenseId
                              ? String(formik.values.postAwardLicenseId)
                              : ""
                          }
                          // value={formik.values.postAwardLicenseId}
                          onChange={formik.handleChange}
                        >
                          <MenuItem value="">None</MenuItem>
                          {postAwardLicenseTypes.map((type) => (
                            <MenuItem
                              key={type.PostAwardLicenseId}
                              value={type.PostAwardLicenseId}
                            >
                              {type.PostAwardLicenseName}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : null}

                    <Grid xs={12} sm={6} lg={4}>
                      <FormControl fullWidth variant="filled">
                        <Typography sx={fontStyle}>
                          Federal Business Category
                        </Typography>
                        <MultiSelect
                          isdisabled={!formik.values.trackNowLicenseId}
                          inputs={categories.map((item) => ({
                            name: item.businessCategoryName
                              ? item.businessCategoryName
                              : "",
                            value: item.businessCategoryId
                              ? item.businessCategoryId + ""
                              : "",
                          }))}
                          values={selectedCategories}
                          initValues={initCategories}
                          updateSelection={handleCategoryChanges}
                          // error={categoriesHasError}
                          helperText={categoriesErrorText}
                        />
                      </FormControl>
                    </Grid>

                    <Grid xs={12} sm={6} lg={4}>
                      <FormControl fullWidth variant="filled">
                        <Typography sx={fontStyle}>Program Name</Typography>
                        <MultiSelect
                          isdisabled={!formik.values.postAwardLicenseId}
                          inputs={programOptions.map((item) => ({
                            name: item.programName ? item.programName : "",
                            value: item.programId ? item.programId + "" : "",
                          }))}
                          values={selectedPrograms}
                          initValues={initialProgramNames}
                          updateSelection={handleProgramNameChanges}
                          helperText={programsErrorText}
                        />
                      </FormControl>
                    </Grid>

                    <Grid xs={12} sm={6} lg={4}>
                      <Typography sx={fontStyle}>Tax Number</Typography>

                      <TextField
                        id="taxNumber"
                        name="taxNumber"
                        label="Tax Number"
                        placeholder="Enter tax number"
                        fullWidth
                        autoComplete="TaxNumber"
                        variant="filled"
                        value={formik.values.taxNumber}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} lg={4}>
                      <Typography sx={fontStyle}>Phone Number</Typography>
                      <TextField
                        id="phone"
                        name="phone"
                        label="Phone Number"
                        placeholder="Enter Phone number"
                        fullWidth
                        autoComplete="phone"
                        variant="filled"
                        value={formik.values.phone}
                        onChange={(e) => {
                          var isDigit = e.target.value.replace(/\D/g, "");
                          var formatted = isDigit.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3"
                          );
                          console.log("e formatted", e.target.value, formatted);
                          e.target.value = formatted;
                          // if (formatted.length < 15) {
                          formik.handleChange(e);
                          // }
                        }}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} lg={4}>
                      <Typography sx={fontStyle}>Email</Typography>

                      <TextField
                        id="email"
                        name="email"
                        label="Email"
                        placeholder="Enter Client Email"
                        fullWidth
                        autoComplete="customer-email"
                        variant="filled"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <Typography sx={fontStyle}>Client Contact</Typography>
                      <TextField
                        id="customer_POC"
                        name="customer_POC"
                        label="Client Contact"
                        fullWidth
                        variant="filled"
                        value={formik.values.customer_POC}
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid xs={4}>
                      <Typography sx={fontStyle}>
                        {" "}
                        TrackNow JSI POC Email{" "}
                      </Typography>
                      <TextField
                        disabled
                        id=" TrackNow jsI_POC"
                        name="TrackNow jsI_POC"
                        label=" TrackNow JSI POC"
                        fullWidth
                        // error={
                        //   formik.touched.jsI_POC &&
                        //   Boolean(formik.errors.jsI_POC)
                        // }
                        // helperText={
                        //   formik.touched.jsI_POC && formik.errors.jsI_POC
                        // }
                        variant="filled"
                        value={formik.values.jsI_POC}
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid xs={4}>
                      <Typography sx={fontStyle}>
                        PostAward JSI POC Email
                      </Typography>
                      <TextField
                        disabled
                        id=" PostAward jsI_POC"
                        name="PostAward jsI_POC"
                        label=" PostAward JSI POC"
                        fullWidth
                        // error={
                        //   formik.touched.jsI_POC &&
                        //   Boolean(formik.errors.jsI_POC)
                        // }
                        // helperText={
                        //   formik.touched.jsI_POC && formik.errors.jsI_POC
                        // }
                        variant="filled"
                        value={formik.values.postAward_JsI_POC}
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid xs={4}>
                      <Typography sx={fontStyle}>Address</Typography>
                      <TextField
                        id="address"
                        name="address"
                        label="Address"
                        fullWidth
                        autoComplete="shipping address"
                        variant="filled"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} lg={4}>
                      <Typography sx={fontStyle}>State</Typography>
                      <FormControl
                        fullWidth
                        variant="filled"
                        error={
                          formik.touched.customerState &&
                          Boolean(formik.errors.customerState)
                        }
                      >
                        <MultiSelect
                          inputs={states.map((item) => ({
                            name: item.state ? item.state : "",
                            value: item.code ? item.code + "" : "",
                          }))}
                          values={selectedState}
                          initValues={initStates}
                          updateSelection={handleStateChanges}
                          helperText={stateErrorText}
                        />
                        {formik.touched.customerState &&
                          formik.errors.customerState && (
                            <FormHelperText>
                              {formik.errors.customerState}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>

                    <Grid xs={4}>
                      <Typography sx={fontStyle}>Zip Code</Typography>
                      <TextField
                        id="zipCode"
                        name="zipCode"
                        label="Zip / Postal code"
                        fullWidth
                        autoComplete="shipping postal-code"
                        variant="filled"
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.zipCode)
                        }
                        helperText={
                          formik.touched.email && formik.errors.zipCode
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={6} lg={4}>
                      <Typography sx={fontStyle}>City</Typography>
                      <TextField
                        id="city"
                        name="city"
                        label="City"
                        fullWidth
                        autoComplete="shipping address-city"
                        variant="filled"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid xs={12}>
                      <Typography sx={fontStyle}>Notes</Typography>
                      <TextField
                        id="notes"
                        name="notes"
                        label="Notes"
                        placeholder="Max-Length 2000 characters"
                        multiline
                        fullWidth
                        minRows={3}
                        variant="filled"
                        value={formik.values.notes}
                        onChange={(e) => {
                          if (e.target.value.length <= 2000) {
                            formik.handleChange(e);
                            setRemainingChars(2000 - e.target.value.length);
                          }
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: "11px",
                          fontWeight: "small",
                          color: remainingChars === 0 ? "red" : "inherit",
                          display: remainingChars === 2000 ? "none" : "block",
                        }}
                      >
                        {remainingChars} Characters are left
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <MuiButton
                        variant="text"
                        sx={{ margin: 1, padding: 1, color: "0000cc" }}
                        onClick={backToHome}
                      >
                        CANCEL
                      </MuiButton>
                      {/* <LoadingButton
  variant='contained'
  color='primary'
  onClick={submitForm}
  loading={customerLoading}
  type='submit'
  // disabled={!formIsValid}
  sx={{ margin: 1, padding: 1 }}
  loadingIndicator={
  <CircularProgress color='inherit' size={16} />
  }
  >
  ADD
  </LoadingButton> */}
                      <MuiButton
                        variant="contained"
                        // color="primary"
                        type="submit"
                        sx={{
                          margin: 1,
                          padding: 1,
                          backgroundColor: "0b3edf",
                        }}
                      >
                        {actionLabel}
                      </MuiButton>
                    </Grid>
                  </Grid>
                </form>
              </React.Fragment>
            )}
          </Container>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
