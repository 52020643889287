import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Anchor } from "@mui/icons-material";

type Anchor = "top" | "left" | "bottom" | "right";

export default function SlidingDrawer(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      console.log(`Drawer Mouse Key Event...`);
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
      setState({ ...state, [anchor]: open });
      if (!open) props.handleClose();
    };
  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      //toggleDrawer(props.dockAt, props.show);
      setState({ ...state, [props.dockAt]: props.show });
    }

    //eslint-disable-next-line
  }, [props.show]);
  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UED: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      // toggleDrawer(props.dockAt, props.show);
      setState({ ...state, [props.dockAt]: props.show });
    }

    //eslint-disable-next-line
  }, []);

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
        minWidth: 250,
      }}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
