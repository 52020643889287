import React, { useReducer, useEffect } from "react";
import userPermission from "../../data/userPermission.json";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import axios from "axios";
import { DateTime , Settings  } from 'luxon';
// import luxon from 'luxon';
//import data from "../../data/projects.json";

import {
  SET_AUTH_STATUS,
  SET_AUTH_USER,
  SET_AUTHORIZED,
  SET_PERMISSION,
  SET_NOTIFICATION_COUNT,
  FETCH_CUSTOMER_NOTIFICATIONS,
  FETCH_FILING_NOTIFICATIONS,
  AUTH_LOADING,
  AUTH_ERROR,
  USER_ROLES_LOADED,
  LOGIN,
  LOGOUT,
} from "../../data/constants";
import { number } from "yup";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const AuthState = (props) => {
  //const localState = window.localStorage.getItem("jsi-intelli-project");
  const initUserEntry = localStorage.getItem("authUser");
  // console.log("Init User:", initUserEntry);

  const initUser =
    typeof initUserEntry !== "undefined" &&
      initUserEntry &&
      initUserEntry.length > 0
      ? JSON.parse(localStorage.getItem("authUser"))
      : null;

  const initialState = {
    isAuthenticated: false,
    authUser: initUser,
    userRoles: [],
    // {
    //   loginId: "",
    //   roleId: 0,
    //   roleName: "",
    //   userId: 0,
    //   userName: "anonymous",
    // },
    notificationCount: 0,
    customerNotifications: [],
    filingNotifications: [],
    customerNotificationsWorkFlow: [],
    loading: false,
    error: null,
    portalUser: null,
    isPortalAuthenticated: false,
  };

  // console.log("Init State:", initialState);
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const setAuthStatus = (status, userId, roleType, authId) => {
    // console.log("Search for", search);
    const payload = {
      isAuthenticated: status,
    };
    // if (userId) payload.authUserId = userId;
    if (authId) payload.authId = authId;
    if (roleType) payload.roleType = roleType;
    dispatch({
      type: SET_AUTH_STATUS,
      payload,
    });
  };

  const setAuthUser = (user) => {
    // console.log("Settign Auth User:", user);
    dispatch({
      type: SET_AUTH_USER,
      payload: user,
    });
  };

  const setAuthorized = (flag) => {
    dispatch({
      type: SET_AUTHORIZED,
      payload: flag,
    });
  };

  const logout = () => {
    dispatch({
      type: LOGOUT,
    });
  };

  const loadUser = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get("/user-detail", config);
        // // console.log("USER STATE", res);
        // localStorage.setItem("isAuthenticated", JSON.stringify(response.data));
        dispatch({
          type: LOGIN,
          payload: { user: res.data.user, token: res.data.token },
        });
        resolve(res.data.user);
      } catch (err) {
        // // console.log("Error", err);
        reject(null);
      }
    });
  };

  const setNotificationCount = (count) => {
    dispatch({
      type: SET_NOTIFICATION_COUNT,
      payload: count,
    });
  };
  const getCustomerNotifications = async (formData) => {
    // console.log("FETCHING Customers ");
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: AUTH_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/Customer/ListCustomerFilingTrackingNotifications`
        );
        // console.log("Customers ", res);
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: FETCH_CUSTOMER_NOTIFICATIONS,
            payload: res.data.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  const getCustomerNotificationsWorkFlow = async (formData) => {
    // console.log("FETCHING Customers ");
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: AUTH_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/Customer/CustomerFilingWorkflowNotificationsList`
        );
        // console.log("Customers ", res);
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: FETCH_CUSTOMER_NOTIFICATIONS,
            payload: res.data.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };

const toLocalDateTime = (date) => {
  console.log("@toLocalDateTime: props", date , new Date(date));  
  if (date) {

    // Parse the ISO date string into a Luxon DateTime object
    const utcDateTime = DateTime.fromISO(date.toString(), { zone: 'UTC' });
    console.log("@toLocalDateTime: utcDateTime",utcDateTime);

    // Get the local time zone
    const localTimeZone = 'America/New_York'; // Example time zone, you can change it to any desired time zone

    // Convert the UTC DateTime to the local time zone
    const localDateTime = utcDateTime.setZone(localTimeZone);

    // Format the local DateTime
    const formattedDateTime = localDateTime.toFormat('dd-MMM-yyyy HH:mm:ss');
    console.log("@toLocalDateTime: formattedDateTime",formattedDateTime);

    return `${formattedDateTime} EST`;
  } else return '';
};

  const getFilingNotifications = async (formData) => {
    // console.log("FETCHING Customers ");
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: AUTH_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/FilingMaster/ListFilingMasterWorkflowNotifications`
        );
        // console.log("Customers ", res);
        if (res && res.data && res.data.status === "Success") {
          dispatch({
            type: FETCH_FILING_NOTIFICATIONS,
            payload: res.data.data,
          });
          resolve(res.data.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
        reject(err);
      }
    });
  };
  const deleteCustomerNotificationsWorkFlow = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: AUTH_LOADING,
        });
        const res = await axios.delete(
          `${API_BASE_URL}/Customer/DeleteCustomerFilingWorkflowNotifications${formData.notificationId}`,
          formData
        );
        // console.log("Customers ", res);
        dispatch({
          type: FETCH_FILING_NOTIFICATIONS,
          payload: null,
        });
        // await getCustomerNotifications();
        resolve(true);
      } catch (err) {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
        reject(false);
      }
    });
  };
  const deleteFilingNotifications = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: AUTH_LOADING,
        });
        const res = await axios.delete(
          `${API_BASE_URL}/FilingMaster/DeleteFilingMasterWorkflowNotifications${formData.notificationId}`,
          formData
        );
        // console.log("Customers ", res);
        dispatch({
          type: FETCH_FILING_NOTIFICATIONS,
          payload: null,
        });
        // await getCustomerNotifications();
        resolve(true);
      } catch (err) {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
        reject(false);
      }
    });
  };
  const deleteCustomerNotifications = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: AUTH_LOADING,
        });
        const res = await axios.delete(
          `${API_BASE_URL}/Customer/DeleteCustomerFilingTrackingNotification${formData.notificationId}`,
          formData
        );
        // console.log("Customers ", res);
        dispatch({
          type: FETCH_FILING_NOTIFICATIONS,
          payload: null,
        });
        // await getCustomerNotifications();
        resolve(true);
      } catch (err) {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
        reject(false);
      }
    });
  };

  const getUserRoles = async (formData, token) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("Payload", JSON.stringify(formData));
        dispatch({
          type: AUTH_LOADING,
        });
        const res = await axios.get(
          `${API_BASE_URL}/Application/UserRole`,
          formData
        );
        // console.log("Customers ", res);
        if (res && res.data) {
          dispatch({
            type: USER_ROLES_LOADED,
            payload: res.data,
          });
          resolve(res.data);
        }

        reject([]);
      } catch (err) {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
        reject([]);
      }
    });
  };

  const setPermission = (permissionRoles) => {
    // console.log("setPermission: porps", permissionRoles);
    let getPermission = permissionRoles.map((role) => ({
      ...role,
      permission: userPermission.filter(
        (ele) => ele.roleType === role.roleName
      )[0]?.Permission,
    }));
    // console.log("setPermission:---> getPermission", getPermission);
    // let mergePermission = {};
    // Object.keys(getPermission[0]).forEach((page) => {
    // //   console.log("setPermission: page", page);
    //   mergePermission[page] = {};
    //   Object.keys(getPermission[0][page]).forEach((actionType) => {
    // //     console.log("setPermission: actionType", actionType, getPermission);
    //     let acc;
    //     getPermission.forEach((roles, i) => {
    // //       console.log("mergePermission", roles, i);
    //       acc = acc || roles[page][actionType];
    //     });
    //     mergePermission[page][actionType] = acc;
    // //     console.log("setPermission: page", page, mergePermission[page]);
    //   });
    // });
    // // console.log("setPermission:---> mergePermission", mergePermission);
    dispatch({
      type: SET_PERMISSION,
      payload: getPermission,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        setAuthStatus,
        setAuthUser,
        setAuthorized,
        setPermission,
        setNotificationCount,
        toLocalDateTime,
        getCustomerNotifications,
        getFilingNotifications,
        getCustomerNotificationsWorkFlow,
        deleteCustomerNotificationsWorkFlow,
        deleteFilingNotifications,
        deleteCustomerNotifications,
        getUserRoles,
        loadUser,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
