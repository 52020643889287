import { createContext } from "react";
import IAuthContext from "../../types/Auth";
// import { AlertColor } from "@mui/material/Alert";

const defaultState: IAuthContext = {
  isAuthenticated: false,
  isAuthorized: false,
  roleType: "",
  authId: null,
  notificationCount: 0,
  customerNotifications: [],
  filingNotifications: [],
  customerNotificationsWorkFlow: [],
  loading: false,
  isPortalAuthenticated: false,
};

const AuthContext = createContext<IAuthContext>(defaultState);

export default AuthContext;
