import {
  CUSTOMER_LOADING,
  CUSTOMER_LOADED,
  CUSTOMER_ERROR,
  CUSTOMER_RESET,
  SET_CURRENT_CUSTOMER,
  SET_USER_LIST,
  UPDATE_CUSTOMER,
  CLEAR_CURRENT_CUSTOMER,
  CUSTOMER_COMMENTS_LOADED,
  CUSTOMER_CATEGORIES_LOADED,
  INITIATE_CLIENT_FILING_MASTER,
} from "../../data/constants";

//eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case CUSTOMER_RESET:
      return {
        ...state,
        ...action.payload,
      };
    case CUSTOMER_LOADING:
      return {
        ...state,
        customerLoading: true,
        customerLoaded: false,
        error: null,
      };
    case CUSTOMER_LOADED:
      return {
        ...state,
        customers: action.payload,
        customerLoading: false,
        customerLoaded: true,
        changedCustomers: [],
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customerLoading: false,
        customerLoaded: true,
        selectedCustomer: action.payload,
      };
    case CUSTOMER_ERROR:
      return {
        ...state,
        error: action.payload,
        customerLoading: false,
        customerLoaded: false,
      };
    case SET_CURRENT_CUSTOMER:
      localStorage.setItem("selectedCustomer", JSON.stringify(action.payload));
      return {
        ...state,
        selectedCustomer: action.payload,
        customerLoading: false,
        customerLoaded: true,
      };
    case CLEAR_CURRENT_CUSTOMER:
      localStorage.removeItem("selectedCustomer");
      return { ...state, selectedCustomer: null };
    case CUSTOMER_COMMENTS_LOADED:
      return {
        ...state,
        customerComments: action.payload,
        customerLoading: false,
        customerLoaded: true,
      };
    case CUSTOMER_CATEGORIES_LOADED:
      return {
        ...state,
        customerCategories: action.payload,
        customerLoading: false,
        customerLoaded: true,
      };
    case INITIATE_CLIENT_FILING_MASTER:
      return {
        ...state,
        customerLoading: false,
      };
    case SET_USER_LIST:
      return {
        ...state,
        UserList: action.payload,
      };
    default:
      return state;
  }
};
