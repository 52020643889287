import {
  CLIENT_FILING_MASTER_LOADING,
  CLIENT_FILING_MASTER_LOADED,
  CLIENT_FILING_MASTER_ERROR,
  CLIENT_FILING_MASTER_RESET,
  SET_CURRENT_CLIENT_FILING_MASTER,
  UPDATE_CLIENT_FILING_MASTER,
  CLEAR_CURRENT_CLIENT_FILING_MASTER,
  CLIENT_FILING_MASTER_ADD_LOADED,
  ADD_CLIENT_FILING_MASTER,
  CLIENT_FILING_MASTER_APPROVALS,
  CLIENT_MASTER_WORKFLOW_COMMENTS_LOADED,
  CLIENT_FILING_TRACKER_LOADED,
  CLIENT_FILING_TRACKER_HISTORY_LOADED,
  SET_CURRENT_CLIENT_FILING_TRACKER,
  CLIENT_TRACKER_COMMENTS_LOADED,
  FETCH_CONFIGURATIONS,
  CLIENT_FILING_TRACKER_ATTCHMENTS_LOADED,
  CLIENT_MASTER_COMMENTS_ATTACHHMENTS_LOADED,
  CLIENT_FILING_TRACKER_REFRESH_PAGE
} from "../../data/constants";

//eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case CLIENT_FILING_MASTER_RESET:
      return {
        ...state,
        ...action.payload,
      };
    case CLIENT_FILING_MASTER_LOADING:
      return {
        ...state,
        ClientFilingMasterLoading: true,
        ClientFilingMasterLoaded: false,
        error: null,
      };
    case CLIENT_FILING_MASTER_LOADED:
      return {
        ...state,
        ClientFilingMasters: action.payload,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
        ChangedClientFilingMasters: [],
      };
    case CLIENT_FILING_MASTER_APPROVALS:
      return {
        ...state,
        ClientFilingMasterApprovals: action.payload,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
      };
    case CLIENT_MASTER_WORKFLOW_COMMENTS_LOADED:
      return {
        ...state,
        ClientFilingMasterWorkflowComments: action.payload,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
      };
    case CLIENT_TRACKER_COMMENTS_LOADED:
      return {
        ...state,
        ClientFilingTrackerComments: action.payload,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
      };
    case CLIENT_FILING_MASTER_ADD_LOADED:
      return {
        ...state,
        ClientFilingMastersAdd: action.payload,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
      };
    case ADD_CLIENT_FILING_MASTER:
      return {
        ...state,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
      };
    case UPDATE_CLIENT_FILING_MASTER:
      return {
        ...state,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
        selectedClientFilingMaster: action.payload,
      };
    case CLIENT_FILING_MASTER_ERROR:
      return {
        ...state,
        error: action.payload,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
      };
    case SET_CURRENT_CLIENT_FILING_MASTER:
      localStorage.setItem(
        "selectedClientFilingMaster",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        selectedClientFilingMaster: action.payload,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
      };
    case CLEAR_CURRENT_CLIENT_FILING_MASTER:
      localStorage.removeItem("selectedClientFilingMaster");
      return { ...state, selectedClientFilingMaster: null };
    case CLIENT_FILING_TRACKER_LOADED:
      return {
        ...state,
        ClientFilingTrackers: action.payload,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
      };
    case CLIENT_FILING_TRACKER_HISTORY_LOADED:
      return {
        ...state,
        ClientFilingTrackersHistory: action.payload,
        ClientFilingMasterLoading: false,
        ClientFilingMasterLoaded: true,
      };
    case SET_CURRENT_CLIENT_FILING_TRACKER:
      localStorage.setItem(
        "selectedClientFilingTracker",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        selectedClientFilingTracker: action.payload,
      };
    case FETCH_CONFIGURATIONS:
      return {
        ...state,
        reminderConfig: action.payload,
      };
    case CLIENT_FILING_TRACKER_ATTCHMENTS_LOADED:
      return {
        ...state,
        ClientFilingTrackerAttachments: action.payload,
      };
    case CLIENT_MASTER_COMMENTS_ATTACHHMENTS_LOADED: 
      return {
        ...state,
        clientFilingMaterCommentsAttachments: action.payload
      }
    case CLIENT_FILING_TRACKER_REFRESH_PAGE:
      return {
        ...state,
        refreshPageDetails: action.payload
      }
    default:
      return state;
  }
};
