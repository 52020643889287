import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Container } from "@mui/system";
import { useEffect } from "react";
import "./Reminder.css";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import AuthContext from "../../context/auth/AuthContext";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import usePermission from "../../usePermission";
import axios from "axios";
import useDialog from "../common/UseDialog";
import { InputSwitch } from "primereact/inputswitch";
import { CircularProgress } from "@mui/material";
import { Fragment } from "react";
import Loader from "../common/Loader";
import { Button as PrimeButton } from "primereact/button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import NotificationConfigurationHistory from "./NotificationConfigurationHistory";
import NotificationConfigurationToolbox from "./NotificationConfigurationToolbox";
import { styled } from "@mui/material/styles";
import {
  Chip,
  Menu,
  MenuItem,
  Paper,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
//import {AddCircleIcon} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PersonIcon from "@mui/icons-material/Person";
import { identity } from "lodash";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function NotificationConfiguration() {
  const filingMasterContext = React.useContext(ClientFilingMasterContext);
  const {
    fetchConfigurations,
    reminderConfig,
    updateReminderConfiguration,
    createReminderConfiguration,
  } = filingMasterContext;
  const authContext = React.useContext(AuthContext);
  const { authUser, roleType } = authContext;

  const navigate = useNavigate();
  const backToHome = () => {
    navigate("/");
  };
  const [mailButtonStyle, setMailButtonStyle] = useState({
    backgroundColor: "#e0e0e0",
    borderRadius: "15px",
  });

  const [formValues, setFormValues] = useState({ Notifications: true });
  const [initialValues, setInitialValues] = useState({});
  const [initialFormValues, SetInitialFormValues] = useState({});
  const [isCreating, setIsCreating] = useState(true);
  const [remainingChars, setRemainingChars] = useState(8000);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [configurationList, setConfigurationList] = useState([]);
  const [configurationListNew, setConfigurationListNew] = useState([]);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [placeHolderList, setPlaceHolderList] = useState([]);
  const [userNameList, setUserNameList] = useState([]);
  const [userGroupList, setUserGroupList] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  //cc
  const [placeHolderListCc, setPlaceHolderListCc] = useState([]);
  const [userNameListCc, setUserNameListCc] = useState([]);
  const [userGroupListCc, setUserGroupListCc] = useState([]);
  const [userRoleListCc, setUserRoleListCc] = useState([]);
  const [emailTaskIdValue, setEmailTaskIdValue] = useState(null);
  const [emailTaskNameList, setEmailTaskNameList] = useState([]);
  //Activity history
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);

  useEffect(() => {
    if (formValues.Mail_Message) {
      const charsUsed = formValues.Mail_Message.length;
      setRemainingChars(8000 - charsUsed);
    }
  }, [formValues.Mail_Message]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [selectedPlaceHolderList, setSelectedPlaceHolderList] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState([]);
  const [selectedUserNameInput, setSelectedUserNameInput] = useState([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [selectedRoleList, setSelectedRoleList] = useState([]);
  const [selectedPlaceHolderListCc, setSelectedPlaceHolderListCc] = useState(
    []
  );
  const [selectedUserNameCc, setSelectedUserNameCc] = useState([]);
  const [selectedUserNameInputCc, setSelectedUserNameInputCc] = useState([]);
  const [selectedUserGroupCc, setSelectedUserGroupCc] = useState([]);
  const [selectedRoleListCc, setSelectedRoleListCc] = useState([]);
  //const [selectedReminderName, setSelectedReminderName] = useState('');

  const [emailInput, setEmailInput] = useState("");
  const [emailInputCc, setEmailInputCc] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailErrorCc, setEmailErrorCc] = useState("");
  const [isRecipientValidation, setIsRecipientValidation] = useState(false);
  const [initialEmailCCandTo, setInitialEmailCCandTo] = useState([]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenMenuCc = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseMenuCc = () => {
    setAnchorE2(null);
  };
  // const handleReminderName = (event) => {
  //   setSelectedReminderName(event.target.value)

  // };
  // console.log("selectedReminderName", selectedReminderName);
  const handleSelectPlaceHolderList = (value) => {
    setIsRecipientValidation(false);
    console.log("Value#", value);
    if (!selectedPlaceHolderList.includes(value)) {
      setSelectedPlaceHolderList([...selectedPlaceHolderList, value]);
    }
  };
  const handleSelectPlaceHolderListCc = (value) => {
    console.log("Value#", value);
    if (!selectedPlaceHolderListCc.includes(value)) {
      setSelectedPlaceHolderListCc([...selectedPlaceHolderListCc, value]);
    }
  };

  const handleSelectRoleName = (value) => {
    setIsRecipientValidation(false);
    console.log("Value#", value);
    if (!selectedRoleList.includes(value)) {
      console.log("value#@", value);
      setSelectedRoleList([...selectedRoleList, value]);
    }
  };
  const handleSelectRoleNameCc = (value) => {
    console.log("Value#", value);
    if (!selectedRoleListCc.includes(value)) {
      console.log("value#@", value);
      setSelectedRoleListCc([...selectedRoleListCc, value]);
    }
  };

  const handleSelectedValueUserName = (user) => {
    console.log("Value#", user);
    setIsRecipientValidation(false);
    console.log("selectedUserNameOnchange", selectedUserName);
    if (
      !selectedUserName.some(
        (selectedUser) => selectedUser.userEmailId === user.userEmailId
      )
    ) {
      const selectedUser = {
        userEmailId: user.userEmailId,
        placeHolderName: user.placeHolderName,
      };
      console.log("selectedUserVar", selectedUser);
      setSelectedUserName([...selectedUserName, selectedUser]);
    }
  };
  const handleSelectedValueUserNameCc = (user) => {
    console.log("Value#", user);
    if (
      !selectedUserNameCc.some(
        (selectedUser) => selectedUser.userEmailId === user.userEmailId
      )
    ) {
      const selectedUser = {
        userEmailId: user.userEmailId,
        placeHolderName: user.placeHolderName,
      };
      console.log("selectedUserVar", selectedUser);
      setSelectedUserNameCc([...selectedUserNameCc, selectedUser]);
    }
  };
  const handleGroupList = (value) => {
    console.log("Value#", value);
    if (!selectedUserGroup.includes(value)) {
      setSelectedUserGroup([...selectedUserGroup, value]);
    }
  };
  const handleGroupListCc = (value) => {
    console.log("Value#", value);
    if (!selectedUserGroupCc.includes(value)) {
      setSelectedUserGroupCc([...selectedUserGroupCc, value]);
    }
  };
  const handleEmailInputChange = (event) => {
    const { value } = event.target;
    console.log("valueEmail", value);
    setEmailInput(value);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (value && !emailRegex.test(value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };
  const handleEmailInputChangeCc = (event) => {
    const { value } = event.target;
    setEmailInputCc(value);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (value && !emailRegex.test(value)) {
      setEmailErrorCc("Invalid email address");
    } else {
      setEmailErrorCc("");
    }
  };
  const handleAddEmailChip = () => {
    console.log("emailInput1", emailInput);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailInput.trim() !== "") {
      console.log("emailInput2", emailInput);
      setIsRecipientValidation(false);
      if (emailRegex.test(emailInput)) {
        if (!selectedUserNameInput.includes(emailInput)) {
          // setSelectedUserName([...selectedUserName, emailInput]);
          setSelectedUserNameInput([...selectedUserNameInput, emailInput]);
          setEmailInput("");
        } else {
          setEmailError("Email already in the list");
        }
      } else {
        setEmailError("Invalid email address");
      }
    }
  };
  const handleAddEmailChipCc = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailInputCc.trim() !== "") {
      if (emailRegex.test(emailInputCc)) {
        if (!selectedUserNameInputCc.includes(emailInputCc)) {
          //  setSelectedUserNameCc([...selectedUserNameCc, emailInputCc]);
          setSelectedUserNameInputCc([
            ...selectedUserNameInputCc,
            emailInputCc,
          ]);
          setEmailInputCc("");
        } else {
          setEmailErrorCc("Email already in the list");
        }
      } else {
        setEmailErrorCc("Invalid email address");
      }
    }
  };
  const handleRemoveValue = (value) => {
    console.log("value$", value);
    setSelectedUserName(selectedUserName.filter((item) => item !== value));
  };
  const handleRemoveValueInput = (value) => {
    console.log("value$", value);
    setSelectedUserNameInput(
      selectedUserNameInput.filter((item) => item !== value)
    );
  };
  const handleRemoveUserGroup = (value) => {
    console.log("value$", value);
    setSelectedUserGroup(selectedUserGroup.filter((item) => item !== value));
  };
  const handleRemoveValuePlace = (value) => {
    console.log("value$", value);
    setSelectedPlaceHolderList(
      selectedPlaceHolderList.filter((item) => item !== value)
    );
  };
  const handleRemoveValueRoles = (value) => {
    console.log("value$", value);
    setSelectedRoleList(selectedRoleList.filter((item) => item !== value));
  };
  const handleRemoveUserGroupCc = (value) => {
    console.log("value$", value);
    setSelectedUserGroupCc(
      selectedUserGroupCc.filter((item) => item !== value)
    );
  };
  const handleRemoveValuePlaceCc = (value) => {
    console.log("value$", value);
    setSelectedPlaceHolderListCc(
      selectedPlaceHolderListCc.filter((item) => item !== value)
    );
  };
  const handleRemoveValueRolesCc = (value) => {
    console.log("value$", value);
    setSelectedRoleListCc(selectedRoleListCc.filter((item) => item !== value));
  };
  const handleRemoveValueCc = (value) => {
    console.log("value$", value);
    setSelectedUserNameCc(selectedUserNameCc.filter((item) => item !== value));
  };
  const handleRemoveValueInputCc = (value) => {
    console.log("value$", value);
    setSelectedUserNameInputCc(
      selectedUserNameInputCc.filter((item) => item !== value)
    );
  };

  console.log("selectedValEmail##", selectedUserName);
  console.log("selectedUserNameInput##", selectedUserNameInput);
  console.log("selectedUserGroup##", selectedUserGroup);
  console.log("selectedPlaceHolderList##", selectedPlaceHolderList);
  console.log("selectedRoleList##", selectedRoleList);
  console.log("CC-selectedValEmail##", selectedUserNameCc);
  console.log("CC-selectedUserNameInputCc##", selectedUserNameInputCc);
  console.log("CC-selectedUserGroup##", selectedUserGroupCc);
  console.log("CC-selectedPlaceHolderList##", selectedPlaceHolderListCc);
  console.log("CC-selectedRoleList##", selectedRoleListCc);
  // useEffect(async () => {

  //   const res = await axios.get(`${API_BASE_URL}/Application/ListEmails`);

  //   if (res) {
  //     setPlaceHolders(res.data.emailTaskName);

  //   }

  // }, []);

  const recordtypes = ["TrackNow", "PostAward"];

  const getPlaceHolders = async () => {
    console.log("SUC1", 1);
    try {
      let resData = await axios.get(
        `${API_BASE_URL}/Application/ListEmailMasterPlaceHolderNameValues`
      );
      console.log("resData", resData);
      let placeHolder = [
        {
          placeHolderId: 12,
          placeHolderName: "{{StaffAssigned}}",
          replaceValueField: "UserID",
          replaceValueTable: "CustomerFilingTrackerStaffAssigned",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-28T14:53:51.52",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
        {
          placeHolderId: 15,
          placeHolderName: "{{ClientFilingApprover}}",
          replaceValueField: "CurrentApproverID",
          replaceValueTable: "CustomerFilingMasterWorkflow",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-30T05:57:10.89",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
        {
          placeHolderId: 16,
          placeHolderName: "{{ClientFilingRequester}}",
          replaceValueField: "WorkflowInitiatorId",
          replaceValueTable: "CustomerFilingMasterWorkflow",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-30T05:57:10.89",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
        {
          placeHolderId: 17,
          placeHolderName: "{{FilingApprover}}",
          replaceValueField: "CurrentApproverID",
          replaceValueTable: "FilingMasterWorkflow",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-30T05:57:10.89",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
        {
          placeHolderId: 18,
          placeHolderName: "{{FilingRequester}}",
          replaceValueField: "WorkflowInitiatorId",
          replaceValueTable: "FilingMasterWorkflow",
          replaceValueDB: null,
          isUsername: false,
          isGroupName: false,
          isRoleName: false,
          isReplacerName: true,
          createDate: "2023-08-30T05:57:10.89",
          createUser: "1",
          updateDate: null,
          updateUser: null,
        },
      ];
      let userGroup = resData.data.data.filter(
        (item) =>
          item.isReplacerName === false &&
          item.isGroupName === true &&
          item.isUsername === false &&
          item.isRoleName === false
      );
      let userName = resData.data.data.filter(
        (item) =>
          item.isReplacerName === false &&
          item.isGroupName === false &&
          item.isUsername === true &&
          item.isRoleName === false
      );
      let roleList = resData.data.data.filter(
        (item) =>
          item.isReplacerName === false &&
          item.isGroupName === false &&
          item.isUsername === false &&
          item.isRoleName === true
      );
      try {
        if (formValues.Client) {
          console.log("userNameTrue", userName);
          console.log(
            "formValues",
            formValues,
            "formValuesClient",
            formValues.Client.code
          );

          let updUserName = userName.filter((user) => {
            return (
              user &&
              user.roleName &&
              (user.roleName === "JSISME" || user.roleName === "Admin") &&
              user.customerID === null
            );
          });
          let filtCust = userName.filter(
            (cust) => cust.customerID === formValues.Client.code
          );
          const updUserList = [...updUserName, ...filtCust];
          const uniquePlaceHolderIds = new Set();
          userName = updUserList.filter((user) => {
            if (!uniquePlaceHolderIds.has(user.placeHolderId)) {
              uniquePlaceHolderIds.add(user.placeHolderId);
              return true;
            }
            return false;
          });
          console.log(
            "updUserName",
            updUserName,
            "filtCust",
            filtCust,
            "updUserList",
            updUserList,
            "uniquePlaceHolderIds",
            uniquePlaceHolderIds,
            "userName",
            userName
          );
        } else {
          console.log("userNameFalse", userName);
          console.log("formValues", formValues);
          let updUserName = userName.filter((user) => {
            return (
              user &&
              user.roleName &&
              (user.roleName === "JSISME" || user.roleName === "Admin") &&
              user.customerID === null
            );
          });
          userName = updUserName;
          console.log("updUserName", updUserName, "userName", userName);
        }
      } catch (error) {
        console.log("FILTERERROR", error);
      }
      console.log("#Check-placeHolder", placeHolder);
      console.log("#Check-userGroup", userGroup);
      console.log("#Check-userName", userName);
      console.log("#Check-roleList", roleList);
      setPlaceHolderList(placeHolder);
      setUserGroupList(userGroup);
      setUserNameList(userName);
      setUserRoleList(roleList);
      setPlaceHolderListCc(placeHolder);
      setUserGroupListCc(userGroup);
      setUserNameListCc(userName);
      setUserRoleListCc(roleList);
    } catch (error) {
      console.log(error);
    }
  };
  console.log("#Check-state-placeHolderList", placeHolderList);
  console.log("#Check-state-userGroupList", userGroupList);
  console.log("#Check-state-userNameList", userNameList);
  console.log("#Check-state-userRoleList", userRoleList);
  console.log("#Check-CC-state-placeHolderList", placeHolderListCc);
  console.log("#Check-CC-state-userGroupList", userGroupListCc);
  console.log("#Check-CC-state-userNameList", userNameListCc);
  console.log("#Check-CC-state-userRoleList", userRoleListCc);

  useEffect(() => {
    getPlaceHolders();
  }, []);

  const MailFields = [
    "Mail_Subject",
    "Mail_Message",
    "Mail_ReminderBefore",
    "Mail_ReminderFrequency",
  ];
  const SMSFields = [
    "SMS_Subject",
    "SMS_Message",
    "SMS_ReminderBefore",
    "SMS_ReminderFrequency",
  ];

  const initMailForm = {
    Mail_Subject: "",
    Mail_Message: "",
    Mail_ReminderBefore: "",
    Mail_ReminderFrequency: "",
  };

  const getConfigs = async () => {
    setIsDataTableLoading(true);
    try {
      const res = await axios.get(`${API_BASE_URL}/Application/ListEmails`);
      console.log("getConfigs res", res);
      let emailRecipient = await axios.get(
        `${API_BASE_URL}/Application/ListEmailUsers`
      );
      console.log("getConfigs emailRecipient", emailRecipient);

      if (res && emailRecipient) {
        let reminderData = res.data.data.filter(
          (ele) => ele.isReminder === false
        );
        console.log("getConfigs reminderData", reminderData);

        reminderData = reminderData.map((reminder) => {
          let emails = emailRecipient.data.data.filter(
            (ele) => ele.emailTaskId === reminder.emailTaskId
          );
          console.log("getConfigs emails", reminder.emailTaskId, emails);
          let recipients = emails.filter((ele) => ele.emailTo === true);
          let ccs = emails.filter((ele) => ele.emailCC === true);
          return { ...reminder, recipients, ccs };
        });
        console.log("getConfigs reminderData", reminderData);

        const resEmailTaskName = reminderData.map((item) => item.emailTaskName);
        setEmailTaskNameList(resEmailTaskName);
        SetInitialFormValues(reminderData);
        setConfigurationListNew(reminderData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDataTableLoading(false);
    }
  };
  console.log("configList", configurationListNew);

  const cellNotifications = (row) => {
    console.log("cellNotifications", row.reminderStartInDays, row.isActive);
    return <InputSwitch name="Notifications" checked={row.isActive} />;
  };

  const cellMessage = (row) => {
    console.log("cellMessage", row.emailMessage);
    return (
      <div
        name="Notifications"
        dangerouslySetInnerHTML={{ __html: row.emailMessage }}
      />
    );
  };

  const cellRecipient = (row) => {
    console.log("cellRecipient", row.recipients);
    let recipients = row.recipients.reduce(
      (acc, ele) => acc + (acc === "" ? "" : ", ") + ele.userName,
      ""
    );
    console.log("cellRecipient recipients", typeof recipients, recipients);
    return <div style={{ minWidth: "4rem" }}>{recipients}</div>;
  };

  const cellCC = (row) => {
    console.log("cellCC", row.ccs);
    let cc = row.ccs.reduce(
      (acc, ele) => acc + (acc === "" ? "" : ", ") + ele.userName,
      ""
    );
    console.log("cellRecipient cc", cc);
    return <div style={{ minWidth: "4rem" }}>{cc}</div>;
  };

  const getCustomerList = async () => {
    try {
      let res = await axios.get(
        `${API_BASE_URL}/Application/ListEventsInformation`
      );
      console.log("ListEventsInformation", res.data);
      let _data;
      _data = res.data.data.map((ele) => ({
        code: ele.emailTaskId,
        name: ele.eventName,
      }));
      setCustomerList(_data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // getConfigs();
    getCustomerList();
  }, []);

  useEffect(() => {
    console.log("customerList", customerList);
    if (customerList.length > 0) {
      getConfigs();
    }
  }, [customerList]);

  const handleReset = () => {
    setEmailInput("");
    setEmailInputCc("");
    setFormValues({
      Mail_Message: "",
      Mail_Subject: "",
      // Mail_ReminderBefore: "",
      Notifications: true,
    });
    setIsCreating(true);
    setSelectedPlaceHolderList([]);
    setSelectedUserGroup([]);
    setSelectedUserName([]);
    setSelectedUserNameInput([]);
    setSelectedRoleList([]);
    setSelectedPlaceHolderListCc([]);
    setSelectedUserGroupCc([]);
    setSelectedUserNameCc([]);
    setSelectedUserNameInputCc([]);
    setSelectedRoleListCc([]);
    //setSelectedReminderName('')
  };

  console.log("Form validation", formValues);
  const handleConfigItemChange = (event) => {
    console.log("##eventClient", event);
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    console.log("formValues", formValues);
  };

  const handleConfigItemChanges = (event) => {
    console.log("##recordType", event);
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    console.log("formValues", formValues);
  };

  const changeNotifications = () => {
    console.log("handleNotificationChange", notifications);
    handleConfigItemChange(notifications);
  };

  const { AlertDialog: NotificationsDialog, showDialog: notificationsDialog } =
    useDialog({
      onSubmit: changeNotifications,
    });

  const handleNotificationChange = (e) => {
    console.log("handleNotificationChange", e);
    if (e.target.value) {
      handleConfigItemChange(e);
    } else {
      setNotifications(e);
      notificationsDialog(
        "Confirmation",
        "If Notifications is turned OFF, system will not send notifications to the Client users. Do you want to change?",
        "confirm"
      );
    }
  };

  const createReminder = async (threeDigitReminder, paragraphMsg) => {
    let form = {};
    form[`Mail_CustomerFileTracker_Reminder_Message_${threeDigitReminder}`] =
      paragraphMsg;
    form[`Mail_CustomerFileTracker_Reminder_Subject_${threeDigitReminder}`] =
      formValues.Mail_Subject;
    form["Mail_CustomerFileTracker_Reminder_days"] =
      formValues.Mail_ReminderBefore + "";
    form[
      `Mail_CustomerFileTracker_Reminder_DueDateOption_${threeDigitReminder}`
    ] = formValues.Due_Date_Type;
    if (formValues.Notifications !== undefined) {
      let val = formValues.Notifications ? "1" : "0";
      form[`SendMail_${threeDigitReminder}`] = val;
      form[`SendNotification_${threeDigitReminder}`] = val;
    }
    let data = Object.keys(form).map((item) => {
      return {
        configItem: item,
        configItemValue: form[item],
        configId: 0,
        customerId: formValues.Client ? formValues.Client.code : null,
        createDate: new Date(Date.now()).toISOString(),
        createUser: authUser.userId + "",
      };
    });

    console.log("User created", data);
    let res = await createReminderConfiguration(data);
    console.log("res", res);
    return res;
  };

  const handlePayLoad = async (emailTaskId) => {
    console.log(
      "authUser",
      authUser,
      "authUser.userId",
      authUser.userId,
      "createUser",
      authUser.userId + ""
    );
    let userNameCc = selectedUserNameCc.filter(
      (userCc) =>
        !selectedUserName.some(
          (user) => user.userEmailId === userCc.userEmailId
        )
    );
    let userGroupCc = selectedUserGroupCc.filter(
      (user) => !selectedUserGroup.includes(user)
    );
    let roleListCc = selectedRoleListCc.filter(
      (user) => !selectedRoleList.includes(user)
    );
    let placeHoldolderCc = selectedPlaceHolderListCc.filter(
      (user) => !selectedPlaceHolderList.includes(user)
    );
    console.log("userNameCc", userNameCc);
    console.log("userGroupCc", userGroupCc);
    console.log("roleListCc", roleListCc);
    console.log("placeHoldolderCc", placeHoldolderCc);
    let payLoads = [];
    if (selectedUserName) {
      payLoads.push(
        ...selectedUserName.map((user) => {
          const isCC = selectedUserNameCc.some(
            (userCc) => userCc.userEmailId === user.userEmailId
          );
          return {
            emailTaskId: emailTaskId,
            userName: user.userEmailId,
            userCategory: "Users",
            emailTo: true,
            emailCC: isCC ? true : false,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selectedUserGroup) {
      payLoads.push(
        ...selectedUserGroup.map((group) => {
          const isCC = selectedUserGroupCc.includes(group);
          return {
            emailTaskId: emailTaskId,
            userName: group,
            userCategory: "Groups",
            emailTo: true,
            emailCC: isCC ? true : false,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selectedRoleList) {
      payLoads.push(
        ...selectedRoleList.map((role) => {
          const isCc = selectedRoleListCc.includes(role);
          return {
            emailTaskId: emailTaskId,
            userName: role,
            userCategory: "Roles",
            emailTo: true,
            emailCC: isCc ? true : false,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selectedPlaceHolderList) {
      payLoads.push(
        ...selectedPlaceHolderList.map((role) => {
          const isCc = selectedPlaceHolderListCc.includes(role);
          return {
            emailTaskId: emailTaskId,
            userName: role,
            userCategory: "PlaceHolder",
            emailTo: true,
            emailCC: isCc ? true : false,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (userNameCc) {
      payLoads.push(
        ...userNameCc.map((user) => {
          return {
            emailTaskId: emailTaskId,
            userName: user.userEmailId,
            userCategory: "Users",
            emailTo: false,
            emailCC: true,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
            // Set emailCC based on isCC
          };
        })
      );
    }
    if (userGroupCc) {
      payLoads.push(
        ...userGroupCc.map((group) => {
          return {
            emailTaskId: emailTaskId,
            userName: group,
            userCategory: "Groups",
            emailTo: false,
            emailCC: true,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (roleListCc) {
      payLoads.push(
        ...roleListCc.map((role) => {
          return {
            emailTaskId: emailTaskId,
            userName: role,
            userCategory: "Roles",
            emailTo: false,
            emailCC: true,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (placeHoldolderCc) {
      payLoads.push(
        ...placeHoldolderCc.map((role) => {
          return {
            emailTaskId: emailTaskId,
            userName: role,
            userCategory: "PlaceHolder",
            emailTo: false,
            emailCC: true,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }

    console.log("PayLoad##", payLoads);
    return payLoads;
  };

  const updateRecipient = async () => {
    // createDate: new Date(Date.now()).toISOString(),
    //   createUser: authUser.userId + "",
    console.log(
      "authUser",
      authUser,
      "authUser.userId",
      authUser.userId,
      "createUser",
      authUser.userId + ""
    );

    let res = await axios.get(`${API_BASE_URL}/Application/ListEmailUsers`);
    let viewEmailUserList = res.data.data;
    let filteredEmailUserList = viewEmailUserList.filter(
      (email) => email.emailTaskId === emailTaskIdValue.emailTaskId
    );
    let userNameList = filteredEmailUserList.filter(
      (cat) => cat.userCategory === "Users"
    );
    let userNameListInput = filteredEmailUserList.filter(
      (cat) => cat.userCategory === null
    );
    //  let userName = userNameList.map(user => user.userName);
    let userGroupList = filteredEmailUserList.filter(
      (cat) => cat.userCategory === "Groups"
    );
    //  let userGroup = userNameList.map(user => user.userName);

    let userRolesList = filteredEmailUserList.filter(
      (cat) => cat.userCategory === "Roles"
    );
    // let userRoles = userNameList.map(user => user.userName);

    let placeHolderList = filteredEmailUserList.filter(
      (cat) => cat.userCategory === "PlaceHolder"
    );
    //  let placeHolder = userNameList.map(user => user.userName);
    const delPayLoad = [];

    //delete user name
    const delUser = userNameList.filter((user) => {
      // Check if the userEmailId is not in selectedUserName or selectedUserNameCc
      return (
        !selectedUserName.some(
          (selectedUser) => selectedUser.userEmailId === user.userName
        ) &&
        !selectedUserNameCc.some(
          (selectedUser) => selectedUser.userEmailId === user.userName
        )
      );
    });
    const delIds = delUser.map((item) => item.id);
    delPayLoad.push(...delIds);
    //delete email input
    const delUserInput = userNameListInput.filter(
      (user) =>
        !selectedUserNameInput.includes(user.userName) &&
        !selectedUserNameInputCc.includes(user.userName)
    );
    const delInputIds = delUserInput.map((item) => item.id);
    delPayLoad.push(...delInputIds);

    //delete user group
    const delUserGroup = userGroupList.filter(
      (user) =>
        !selectedUserGroup.includes(user.userName) &&
        !selectedUserGroupCc.includes(user.userName)
    );
    const delUserGroupIds = delUserGroup.map((item) => item.id);
    delPayLoad.push(...delUserGroupIds);

    //delete delPlaceHolder
    const delPlaceHolder = placeHolderList.filter(
      (user) =>
        !selectedPlaceHolderList.includes(user.userName) &&
        !selectedPlaceHolderListCc.includes(user.userName)
    );
    const delPlaceIds = delPlaceHolder.map((item) => item.id);
    delPayLoad.push(...delPlaceIds);

    //delete role
    const delRole = userRolesList.filter(
      (user) =>
        !selectedRoleList.includes(user.userName) &&
        !selectedRoleListCc.includes(user.userName)
    );
    const delRoleIds = delRole.map((item) => item.id);
    delPayLoad.push(...delRoleIds);

    let userNameCc = selectedUserNameCc.filter((userCc) => {
      return !selectedUserName.some(
        (user) => user.userEmailId === userCc.userEmailId
      );
    });

    let userNameInputCc = selectedUserNameInputCc.filter(
      (user) => !selectedUserNameInput.includes(user)
    );
    let userGroupCc = selectedUserGroupCc.filter(
      (user) => !selectedUserGroup.includes(user)
    );
    let roleListCc = selectedRoleListCc.filter(
      (user) => !selectedRoleList.includes(user)
    );
    let placeHoldolderCc = selectedPlaceHolderListCc.filter(
      (user) => !selectedPlaceHolderList.includes(user)
    );
    console.log("userNameCc", userNameCc);
    console.log("userNameInputCc", userNameInputCc);
    console.log("userGroupCc", userGroupCc);
    console.log("roleListCc", roleListCc);
    console.log("placeHoldolderCc", placeHoldolderCc);
    let updatePayLoads = [];
    //List User
    const userNameCcObj = userNameCc.map((obj) => {
      const user = userNameList.find(
        (user) => user.userName === obj.userEmailId
      );
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: obj.userEmailId };
    });

    const selUserNameobj = selectedUserName.map((obj) => {
      const user = userNameList.find(
        (user) => user.userName === obj.userEmailId
      );
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: obj.userEmailId };
    });

    const selUserNameCcobj = selectedUserNameCc.map((obj) => {
      const user = userNameList.find(
        (user) => user.userName === obj.userEmailId
      );
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: obj.userEmailId };
    });
    console.log(
      "userNameCcObj",
      userNameCcObj,
      "selUserNameobj",
      selUserNameobj,
      "selUserNameCcobj",
      selUserNameCcobj
    );
    //list email input
    const userNameInputCcObj = userNameInputCc.map((name) => {
      const user = userNameListInput.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selUserNameInputobj = selectedUserNameInput.map((name) => {
      const user = userNameListInput.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selUserNameInputCcobj = selectedUserNameInputCc.map((name) => {
      const user = userNameListInput.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    //List Email
    const userGroupCcObj = userGroupCc.map((name) => {
      const user = userGroupList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selUserGroupobj = selectedUserGroup.map((name) => {
      const user = userGroupList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selUserGroupCcobj = selectedUserGroupCc.map((name) => {
      const user = userGroupList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });

    //Role
    const roleListCcObj = roleListCc.map((name) => {
      const user = userRolesList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selRoleListobj = selectedRoleList.map((name) => {
      const user = userRolesList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selRoleListCcobj = selectedRoleListCc.map((name) => {
      const user = userRolesList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    //PlaceHolderList
    const placeHoldolderCcObj = placeHoldolderCc.map((name) => {
      const user = placeHolderList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selplaceHoldoldertobj = selectedPlaceHolderList.map((name) => {
      const user = placeHolderList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });
    const selplaceHoldolderCcobj = selectedPlaceHolderListCc.map((name) => {
      const user = placeHolderList.find((user) => user.userName === name);
      return user
        ? { id: user.id, userName: user.userName }
        : { id: 0, userName: name };
    });

    if (selUserNameobj) {
      updatePayLoads.push(
        ...selUserNameobj.map((user) => {
          const isCC = selUserNameCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "Users",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selUserNameInputobj) {
      updatePayLoads.push(
        ...selUserNameInputobj.map((user) => {
          const isCC = selUserNameInputCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            // userCategory: "",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selUserGroupobj) {
      updatePayLoads.push(
        ...selUserGroupobj.map((user) => {
          const isCC = selUserGroupCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "Groups",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selRoleListobj) {
      updatePayLoads.push(
        ...selRoleListobj.map((user) => {
          const isCC = selRoleListCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "Roles",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (selplaceHoldoldertobj) {
      updatePayLoads.push(
        ...selplaceHoldoldertobj.map((user) => {
          const isCC = selplaceHoldolderCcobj.some(
            (ccUser) => ccUser.userName === user.userName
          );
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "PlaceHolder",
            emailTo: true,
            emailCC: isCC ? true : false,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (userNameCcObj) {
      updatePayLoads.push(
        ...userNameCcObj.map((user) => {
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            userCategory: "Users",
            emailTo: false,
            emailCC: true,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (userNameInputCcObj) {
      updatePayLoads.push(
        ...userNameInputCcObj.map((user) => {
          return {
            id: user.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: user.userName,
            //userCategory: "",
            emailTo: false,
            emailCC: true,
            updateDate: user.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: user.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (userGroupCcObj) {
      updatePayLoads.push(
        ...userGroupCcObj.map((group) => {
          return {
            id: group.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: group.userName,
            userCategory: "Groups",
            emailTo: false,
            emailCC: true,
            updateDate:
              group.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: group.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (roleListCcObj) {
      updatePayLoads.push(
        ...roleListCcObj.map((role) => {
          return {
            id: role.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: role.userName,
            userCategory: "Roles",
            emailTo: false,
            emailCC: true,
            updateDate: role.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: role.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }
    if (placeHoldolderCcObj) {
      updatePayLoads.push(
        ...placeHoldolderCcObj.map((role) => {
          return {
            id: role.id,
            emailTaskId: emailTaskIdValue.emailTaskId,
            userName: role.userName,
            userCategory: "PlaceHolder",
            emailTo: false,
            emailCC: true,
            updateDate: role.id > 0 ? new Date(Date.now()).toISOString() : null,
            updateUser: role.id > 0 ? authUser.userId + "" : null,
            createDate: new Date(Date.now()).toISOString(),
            createUser: authUser.userId + "",
          };
        })
      );
    }

    console.log("PayLoad##", updatePayLoads, delPayLoad);
    return { updatePayLoads, delPayLoad };
  };

  console.log("FormCheckClient", formValues.client);

  const handleSubmit = async () => {
    setIsFormSubmitted(true);
    setIsLoading(true);
    console.log("handleSubmit formValues", formValues);
    const baseUrl = `${API_BASE_URL}/Application/CreateEmailUser`;
    const baseUrlUpd = `${API_BASE_URL}/Application/UpdateEmailUser`;

    try {
      let client = formValues.Client ? formValues.Client.code : "default";
      let findClient = initialFormValues.filter((key) => key.Client === client);
      console.log("client", client);

      console.log(
        "initialFormValues",
        initialFormValues,
        "findClient",
        findClient
      );
      let hasRecipientValidation = false;
      console.log("hasRecipientValidationbef", hasRecipientValidation);
      hasRecipientValidation =
        selectedUserName.length === 0 &&
          selectedPlaceHolderList.length === 0 &&
          selectedRoleList.length === 0 &&
          selectedUserNameInput.length === 0
          ? true
          : false;
      console.log("hasRecipientValidation", hasRecipientValidation);
      if (hasRecipientValidation) {
        setIsRecipientValidation(true);
      }
      let hasRequired = !formValues.Mail_Message || !formValues.Mail_Subject;
      console.log("hasRequired", hasRequired);

      if (!hasRequired && !hasRecipientValidation) {
        let reminderUpdatePayLoads = {
          emailSubject: formValues.Mail_Subject,
          emailMessage: formValues.Mail_Message,
          isReminder: false,
          isActive: formValues.Notifications,
          emailTaskName: formValues.Event.name,
          emailTaskId: emailTaskIdValue?.emailTaskId,
          isHTMLMessage: true,
          createDate: new Date(Date.now()).toISOString(),
          createUser: authUser.userId + "",
          updateDate: new Date(Date.now()).toISOString(),
          updateUser: authUser.userId + "",
          recordType: formValues.RecordType,

          // reminderStartInDays: formValues.Mail_ReminderBefore,
          // reminderFrequencyInDays: 1,
          // reminderBasedOnField: formValues.Due_Date_Type,
          // customerId: formValues.Client ? formValues.Client.code : null,
        };
        console.log("handleSubmit payload", reminderUpdatePayLoads);

        let res = await updateEmail(reminderUpdatePayLoads);
        console.log("handleSubmit res", res);

        const { updatePayLoads, delPayLoad } = await updateRecipient();
        console.log("updatePayLoads", updatePayLoads, delPayLoad);
        if (updatePayLoads) {
          const newlyAddedUser = updatePayLoads.filter((item) => item.id === 0); // newly added cc or to
          const exsistingUser = updatePayLoads.filter((item) => item.id !== 0); // exsisting cc or to modification
          const removeMatchingObjects = (initialArr, exsistingArr) => {
            return exsistingArr.filter((exsistingObj) => {
              // Check if there is a matching initialEmailCcandTo object with the same id
              let matchingInitialObj = initialArr.find((initialObj) => {
                return (
                  initialObj.id === exsistingObj.id &&
                  initialObj.emailCC === exsistingObj.emailCC &&
                  initialObj.emailTo === exsistingObj.emailTo
                );
              });

              // If matchingInitialObj is found, filter it out from exsistingArr
              return !matchingInitialObj;
            });
          };
          let filteredExsistingUser = removeMatchingObjects(
            initialEmailCCandTo,
            exsistingUser
          ); // removing the exsisting EmailCc and EmailTo if not edited
          // console.log("updatePayLoads", updatePayLoads, "newlyAddedUser", newlyAddedUser, "exsistingUser", exsistingUser,);
          // console.log("updatePayLoads", updatePayLoads, "initialEmailCCandTo", initialEmailCCandTo, "exsistingUser", exsistingUser, "filteredExsistingUser", filteredExsistingUser);

          for (const resUpd of filteredExsistingUser) {
            const urlWithId = `${baseUrlUpd}?Id=${resUpd.id}`;
            let responseRecipentUpd = await axios.put(urlWithId, resUpd);
          }
          const urlWithId = `${baseUrl}`;
          let responseRecipentUpd = await axios.post(urlWithId, newlyAddedUser);

        }

        if (delPayLoad) {
          console.log("updatePayLoads2", updatePayLoads, delPayLoad);

          // Assuming delUserId is the value you want to send for deletion
          try {
            const response = await axios.delete(
              `${API_BASE_URL}/Application/DeleteEmailUser?UpdateUser=${authUser.userId + ""
              }`,
              { data: delPayLoad }
            );
            // Handle the response as needed (e.g., check for success)
            // console.log(`Deleted user with ID ${delId}`);
          } catch (error) {
            // Handle any errors that may occur during the DELETE request
            // console.error(`Error deleting user with ID ${delId}:`, error);
          }
        }

        if (res) {
          setAlertMessage("Notification Successfully Updated");
          setShowAlert(true);
          setIsFormSubmitted(false);
          setSelectedPlaceHolderList([]);
          setSelectedUserGroup([]);
          setSelectedUserName([]);
          setSelectedRoleList([]);
          setSelectedPlaceHolderListCc([]);
          setSelectedUserGroupCc([]);
          setSelectedUserNameCc([]);
          setSelectedRoleListCc([]);
          //  setSelectedReminderName('')
          setIsFormSubmitted(false);
          getConfigs();
          handleReset();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createEmail = async (reminderPayLoads) => {
    const baseUrl = `${API_BASE_URL}/Application/CreateEmail`;

    let res = await axios.post(baseUrl, reminderPayLoads);
    console.log("emailRes", res);
    return res;
  };
  const updateEmail = async (reminderUpdatePayLoads) => {
    console.log("reminderUpdatePayLoads", reminderUpdatePayLoads);
    const baseUrl = `${API_BASE_URL}/Application/UpdateEmail?EmailTaskId=${reminderUpdatePayLoads.emailTaskId}
`;

    let res = await axios.put(baseUrl, reminderUpdatePayLoads);
    console.log("emailRes", res);
    return res;
  };

  const getClientName = (clientId) => {
    console.log("getClientName", clientId, customerList);
    let findName = customerList.filter((ele) => ele.code === clientId);
    console.log("getClientName", findName);
    return findName.length > 0 ? findName[0].name : "";
  };

  const EditButton = ({ rowData }) => {
    return (
      <IconButton
        color="primary"
        disabled={!usePermission("setting", "edit", rowData.clientId)}
      >
        <EditIcon onClick={() => editReminder(rowData)} />
      </IconButton>
    );
  };

  const DeleteButton = ({ rowData }) => {
    return (
      <IconButton
        color="primary"
        disabled={!usePermission("setting", "edit", rowData.clientId)}
      >
        <DeleteIcon onClick={() => handleDelete(rowData)} />
      </IconButton>
    );
  };

  const deleteReminder = async (showAlert) => {
    console.log("deleteUser data", selectedReminder);
    try {
      let res = await axios.delete(
        `${API_BASE_URL}/Application/DeleteEmail/${selectedReminder.emailTaskId}`
      );
      console.log("deleteReminder res", res.data);

      if (res) {
        setAlertMessage("Reminder successfully deleted");
        setShowAlert(true);
        getConfigs();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { AlertDialog: ConfirmDialog, showDialog } = useDialog({
    onSubmit: deleteReminder,
  });

  const handleDelete = (data) => {
    console.log("handleDelete data", data);
    setSelectedReminder(data);
    showDialog(
      "Confirmation",
      "Are you sure you want to delete this reminder?",
      "confirm"
    );
  };

  const isPermission = !usePermission("setting", "edit");

  const editReminder = async (data) => {
    try {
      console.log("editReminder", data);
      setIsCreating(false);
      setSelectedReminder(data);
      setIsRecipientValidation(false);
      setEmailTaskIdValue(data);
      // setSelectedReminderName(data.emailTaskName)
      let resData = await axios.get(
        `${API_BASE_URL}/Application/ListEmailMasterPlaceHolderNameValues`
      );
      let userTable = resData.data.data.filter(
        (item) =>
          item.isReplacerName === false &&
          item.isGroupName === false &&
          item.isUsername === true &&
          item.isRoleName === false
      );
      let res = await axios.get(`${API_BASE_URL}/Application/ListEmailUsers`);
      let viewEmailUserList = res.data.data;
      let filteredEmailUserList = viewEmailUserList.filter(
        (email) => email.emailTaskId === data.emailTaskId
      );
      let filtUsers = filteredEmailUserList.filter(
        (cat) => cat.userCategory === "Users"
      );
      const userList = filtUsers.map((user) => {
        const matchingUser = userTable.find(
          (userNameObj) => userNameObj.userEmailId === user.userName
        );
        if (matchingUser) {
          console.log("matchingUser", matchingUser);
          return {
            ...user,
            placeHolderName: matchingUser.placeHolderName,
          };
        }
      });
      console.log(
        "FiltUser",
        filtUsers,
        "userList",
        userList,
        "userTable",
        userTable
      );

      let userNameInput = filteredEmailUserList.filter(
        (cat) => cat.userCategory === null
      );
      let userGroup = filteredEmailUserList.filter(
        (cat) => cat.userCategory === "Groups"
      );
      let userRoles = filteredEmailUserList.filter(
        (cat) => cat.userCategory === "Roles"
      );
      let placeHolder = filteredEmailUserList.filter(
        (cat) => cat.userCategory === "PlaceHolder"
      );

      let combainedEmailCcandTo = [
        ...userList,
        ...userNameInput,
        ...userRoles,
        ...placeHolder,
      ];
      // console.log("editReminder combainedEmailCcandTo", combainedEmailCcandTo);
      setInitialEmailCCandTo(combainedEmailCcandTo);
      // console.log("editReminder", userList, "userNameInput", userNameInput, "userRoles", userRoles, "placeHolder", placeHolder);
      let userNameSel = [];
      let userNameCcSel = [];
      userList.forEach((email) => {
        const userEntry = {
          userEmailId: email.userName,
          placeHolderName: email.placeHolderName,
        };
        if (email.emailCC && email.emailTo) {
          userNameSel.push(userEntry);
          userNameCcSel.push(userEntry);
        } else if (email.emailTo) {
          userNameSel.push(userEntry);
        } else if (email.emailCC) {
          userNameCcSel.push(userEntry);
        }
      });
      console.log("userNameSel", userNameSel, "userNameCcSel", userNameCcSel);
      setSelectedUserName(userNameSel);
      setSelectedUserNameCc(userNameCcSel);
      let userNameInputSel = [];
      let userNameInputCcSel = [];
      userNameInput.forEach((email) => {
        if (email.emailCC && email.emailTo) {
          userNameInputSel.push(email.userName);
          userNameInputCcSel.push(email.userName);
        } else if (email.emailTo) {
          userNameInputSel.push(email.userName);
        } else if (email.emailCC) {
          userNameInputCcSel.push(email.userName);
        }
      });
      setSelectedUserNameInput(userNameInputSel);
      setSelectedUserNameInputCc(userNameInputCcSel);

      let userGroupSel = [];
      let userGroupCcSel = [];
      userGroup.forEach((email) => {
        if (email.emailCC && email.emailTo) {
          userGroupSel.push(email.userName);
          userGroupCcSel.push(email.userName);
        } else if (email.emailTo) {
          userGroupSel.push(email.userName);
        } else if (email.emailCC) {
          userGroupCcSel.push(email.userName);
        }
      });
      setSelectedUserGroup(userGroupSel);
      setSelectedUserGroupCc(userGroupCcSel);

      let RoleListSel = [];
      let RoleListCcSel = [];
      userRoles.forEach((email) => {
        if (email.emailCC && email.emailTo) {
          RoleListSel.push(email.userName);
          RoleListCcSel.push(email.userName);
        } else if (email.emailTo) {
          RoleListSel.push(email.userName);
        } else if (email.emailCC) {
          RoleListCcSel.push(email.userName);
        }
      });
      setSelectedRoleList(RoleListSel);
      setSelectedRoleListCc(RoleListCcSel);

      let PlaceHolderListSel = [];
      let PlaceHolderListCcSel = [];
      placeHolder.forEach((email) => {
        if (email.emailCC && email.emailTo) {
          PlaceHolderListSel.push(email.userName);
          PlaceHolderListCcSel.push(email.userName);
        } else if (email.emailTo) {
          PlaceHolderListSel.push(email.userName);
        } else if (email.emailCC) {
          PlaceHolderListCcSel.push(email.userName);
        }
      });
      setSelectedPlaceHolderList(PlaceHolderListSel);
      setSelectedPlaceHolderListCc(PlaceHolderListCcSel);

      console.log("resList", res);
      console.log("viewEmailUserList", viewEmailUserList);
      console.log("**filteredEmailUserList", filteredEmailUserList);
      console.log("**userGroup", userGroup);
      console.log("**userRoles", userRoles);

      let text =
        data.emailMessage.charAt(0) === "<"
          ? data.emailMessage.replaceAll("<br/>", "\n").slice(12, -14)
          : data.emailMessage;
      console.log("editReminder text", text);
      setInitialValues({
        Client: data.customerId
          ? { code: data.customerId, name: getClientName(data.customerId) }
          : "",
        Event: data.emailTaskId
          ? { code: data.emailTaskId, name: getClientName(data.emailTaskId) }
          : "",
        RecordType: data.recordType,
        Notifications: data.isActive === true,
        Mail_Subject: data.emailSubject,
        Mail_Message: text,
        html: data.isHTMLMessage === true,

        // Mail_ReminderBefore: parseInt(data.reminderStartInDays),
        //Client: data.customerId ? data.customerId : "",
        // Due_Date_Type: data.reminderBasedOnField,
      });
      setFormValues({
        Client: data.customerId
          ? { code: data.customerId, name: getClientName(data.customerId) }
          : "",
        Event: data.emailTaskId
          ? { code: data.emailTaskId, name: getClientName(data.emailTaskId) }
          : "",
        RecordType: data.recordType,
        Notifications: data.isActive === true,
        Mail_Subject: data.emailSubject,
        Mail_Message: text,
        html: data.isHTMLMessage === true,

        // Mail_ReminderBefore: parseInt(data.reminderStartInDays),
        //Client: data.customerId ? data.customerId : "",
        //  Due_Date_Type: data.reminderBasedOnField,
      });
    } catch (error) {
      console.log("EditError", error);
    }
  };

  console.log("**initialValues", initialValues);
  console.log("**formValues", formValues);
  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const closeAlert = () => {
    setShowAlert(false);
  };

  const renderDropdownItem = (option) => {
    if (!option) {
      return null;
    }

    const { name } = option;
    if (name && name.length > 40) {
      return (
        <Tooltip title={name} key={name}>
          <span>{name.substr(0, 40)}...</span>
        </Tooltip>
      );
    }
    return <span key={name}>{name}</span>;
  };

  const selectedValueTemplate = (option) => {
    if (!option) {
      return null;
    }

    const { name } = option;
    if (name && name.length > 40) {
      return (
        <Tooltip title={name} key={name}>
          <span>{name.substr(0, 40)}...</span>
        </Tooltip>
      );
    }
    return <span key={name}>{name}</span>;
  };

  const fetchReminderHistory = async (emailTaskId) => {
    console.log("fetchReminderHistory props", emailTaskId);
    try {
      let res = await axios.get(
        `${API_BASE_URL}/Application/NotificationAudit?EmailTaskId=${emailTaskId}`
      );
      let history = res.data.data;
      // let history = [
      //   {
      //     "id": 1,
      //     "process": "Newly added",
      //     "createdate": "2024-07-05T06:22:04.697",
      //     "createuser": "184",
      //     "updatedate": null,
      //     "updateuser": null,
      //     "historyId": 0,
      //     "new_HistoryId": 0,
      //     "emailTaskId": 58,
      //     "emailTaskName": null,
      //     "new_EmailTaskName": "Reminder_2_CHA Post Award May28",
      //     "emailSubject": null,
      //     "new_EmailSubject": "TEstReminder",
      //     "emailMessage": null,
      //     "new_EmailMessage": "Please click on this link to Login:TrackNowLoginTEstReminder",
      //     "isHTMLMessage": null,
      //     "new_IsHTMLMessage": true,
      //     "isReminder": null,
      //     "new_IsReminder": true,
      //     "reminderStartInDays": null,
      //     "new_ReminderStartInDays": -2,
      //     "reminderFrequencyInDays": null,
      //     "new_ReminderFrequencyInDays": 1,
      //     "reminderBasedOnField": null,
      //     "new_ReminderBasedOnField": "FilingDueDate",
      //     "customerId": null,
      //     "new_CustomerId": 252,
      //     "filingId": null,
      //     "new_FilingId": null,
      //     "lastReminderDate": null,
      //     "new_LastReminderDate": null,
      //     "isActive": null,
      //     "new_IsActive": true,
      //     "reminderDate": null,
      //     "new_ReminderDate": null,
      //     "recordType": null,
      //     "new_RecordType": "TrackNow",
      //     "emailUserCategorys": null,
      //     "new_EmailUserCategorys": [
      //       {
      //         "userName": "chris.ewing@jsitel.com",
      //         "userCategory": "Users",
      //         "emailTo": true,
      //         "emailCC": false,
      //         "emailBCC": false
      //       },
      //       {
      //         "userName": "Hailey.wolff@jsitel.com",
      //         "userCategory": "Users",
      //         "emailTo": false,
      //         "emailCC": true,
      //         "emailBCC": false
      //       }
      //     ]
      //   }
      // ]
      setActivityHistory(() => history);
      console.log("fetchReminderHistory res", history);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const displayHomePage = () => {
    navigate("/");
  };
  const displayTrackNowFilingMasterList = () => {
    navigate("/filing-master-list");
  };
  const displayPostAwardFilingMasterList = () => {
    navigate("/postaward-filing-master-list");
  };
  const displayClientFilingTrackerList = () => {
    navigate("/client-filing-tracker-list");
  };

  const closeHistory = () => {
    // console.log("Toggling Show Filter", showHistory);
    setShowHistory(() => false);
  };
  const displayHistory = async () => {
    console.log("displayHistory", selectedEmail);
    if (selectedEmail && selectedEmail.emailTaskId) {
      console.log(`Fetching History for :${selectedEmail.emailTaskId}`);
      fetchReminderHistory(selectedEmail.emailTaskId);
      setShowHistory(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      // closeDialog();
    }
  };

  const isFormChanged =
    JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  console.log("isFormChanged", isFormChanged);
  const handleSelect = (e) => {
    // console.log("handleSelect e", e);
    setSelectedEmail(e.value);
  };
  return (
    <React.Fragment>
      {isLoading ? (
        // Display the loading indicator
        <Loader /> // Replace with your loading component
      ) : (
        <Stack
          direction="column"
          // justifyContent='flex-start'
          // alignItems='center'
          spacing={2}
          style={{ margin: "1rem", width: "100%" }}
        >
          <AppBar position="relative" color="inherit" sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              borderRadius="10px"
            >
              <IconButton
                className="nav-bar__logo__img"
                sx={{ display: { xs: "none", md: "flex" } }}
                onClick={backToHome}
              >
                <Box sx={{ m: 2 }}>
                  <ArrowBackIosNewIcon fontSize="medium" sx={{ mt: 1 }} />
                </Box>
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="inherit" sx={{ mr: 1 }}>
                  SETTINGS
                </Typography>
              </Box>
            </Stack>
          </AppBar>
          {/* <Stack width="100%" height="100%" display="flex" direction="row"> */}
            <Container
              className="reminderContainer"
              sx={{
                // borderRadius: "2%",
                maxWidth: "1300px",
                width: "100%",
                marginLeft: "0px",
                borderRadius: "10px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                backgroundColor: "#ffffff",
                display: "flex",
                flexDirection: "column",
                //justifyContent: "flex-start",
              }}
            >
              <Box sx={{ padding: "30px 0.5rem" }}>
                <Stack direction="column" spacing={4}>
                  <Stack direction="row" justifyContent="space-between">
                    <div>
                      <Typography style={{ fontWeight: "bold" }}>
                        NOTIFICATION CONFIGURATION
                      </Typography>
                    </div>
                    <div>
                      <Stack spacing={3} direction="row">
                        <Stack>
                          <Button
                            sx={{
                              ...mailButtonStyle,
                              "&:hover": {
                                backgroundColor:
                                  mailButtonStyle.backgroundColor,
                              },
                            }}
                          >
                            E-Mail
                          </Button>
                        </Stack>
                      </Stack>
                    </div>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <div style={{ width: "100%", marginRight: "1rem" }}>
                      <Stack direction="column" spacing={2}>
                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ marginTop: "10px" }}
                        >
                          <Stack direction="column">
                            <Typography>Recipient</Typography>

                            <Stack direction="row" spacing={1}>
                              <TextField
                                sx={{ width: 400 }}
                                label="Enter Email"
                                variant="outlined"
                                disabled={isCreating}
                                value={emailInput}
                                onChange={handleEmailInputChange}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    handleAddEmailChip();
                                  }
                                }}
                                fullWidth
                                error={Boolean(emailError)}
                                helperText={emailError}
                              ></TextField>

                              <IconButton
                                onClick={handleOpenMenu}
                                disabled={isCreating}
                              >
                                <AddCircleRoundedIcon color="primary" />
                              </IconButton>
                            </Stack>
                            {isRecipientValidation && (
                              <Typography
                                style={{ fontSize: "0.8rem" }}
                                color="error"
                              >
                                Please Enter Recipient
                              </Typography>
                            )}
                          </Stack>
                          {/* <Paper style={{ padding: '10px' }}> */}

                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                          >
                            <MenuItem disabled>Emails:</MenuItem>
                            {userNameList.map((user, index) => (
                              <MenuItem
                                key={`email_${index}`}
                                onClick={() =>
                                  handleSelectedValueUserName(user)
                                }
                              >
                                <ListItemIcon>
                                  <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={`${user.placeHolderName} (${user.userEmailId})`}
                                />
                                {selectedUserName.some(
                                  (selectedUser) =>
                                    selectedUser.userEmailId ===
                                    user.userEmailId
                                ) ? (
                                  <CheckCircleIcon color="primary" />
                                ) : null}
                              </MenuItem>
                            ))}
                            {/* <MenuItem disabled>Email Groups:</MenuItem>
                          {userGroupList.map((list, index) => (
                            <MenuItem
                              key={`list${index}`}
                              onClick={() =>
                                handleGroupList(list.placeHolderName)
                              }
                            >
                              <ListItemIcon>
                                <AddCircleIcon />
                              </ListItemIcon>
                              <ListItemText primary={list.placeHolderName} />
                              {selectedUserGroup.includes(list) ? (
                                <CheckCircleIcon color='primary' />
                              ) : null}
                            </MenuItem>
                          ))} */}
                            <MenuItem disabled>Place Holders:</MenuItem>
                            {placeHolderList.map((email, index) => (
                              <MenuItem
                                key={`email_${index}`}
                                onClick={() =>
                                  handleSelectPlaceHolderList(
                                    email.placeHolderName
                                  )
                                }
                              >
                                <ListItemIcon>
                                  <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={email.placeHolderName} />
                                {selectedPlaceHolderList.includes(
                                  email.placeHolderName
                                ) ? (
                                  <CheckCircleIcon color="primary" />
                                ) : null}
                              </MenuItem>
                            ))}
                            <MenuItem disabled>User Roles:</MenuItem>
                            {userRoleList &&
                              userRoleList.map((email, index) => (
                                <MenuItem
                                  key={`email_${index}`}
                                  onClick={() =>
                                    handleSelectRoleName(email.placeHolderName)
                                  }
                                >
                                  <ListItemIcon>
                                    <AddCircleIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={email.placeHolderName}
                                  />
                                  {selectedRoleList.includes(
                                    email.placeHolderName
                                  ) ? (
                                    <CheckCircleIcon color="primary" />
                                  ) : null}
                                </MenuItem>
                              ))}
                          </Menu>
                        </Stack>
                        <div>
                          {selectedUserName.map((user, index) => (
                            <Chip
                              key={index}
                              label={user.placeHolderName}
                              onDelete={() => handleRemoveValue(user)}
                              style={{ margin: "5px" }}
                            />
                          ))}
                          {selectedUserNameInput.map((value, index) => (
                            <Chip
                              key={index}
                              label={value}
                              onDelete={() => handleRemoveValueInput(value)}
                              style={{ margin: "5px" }}
                            />
                          ))}
                          {/* {selectedUserGroup.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveUserGroup(value)}
                            style={{ margin: "5px" }}
                          />
                        ))} */}
                          {selectedPlaceHolderList.map((value, index) => (
                            <Chip
                              key={index}
                              label={value}
                              onDelete={() => handleRemoveValuePlace(value)}
                              sx={{ margin: "4px" }}
                            />
                          ))}
                          {selectedRoleList.map((value, index) => (
                            <Chip
                              key={index}
                              label={value}
                              onDelete={() => handleRemoveValueRoles(value)}
                              sx={{ margin: "4px" }}
                            />
                          ))}
                        </div>
                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ marginTop: "10px", width: "100%" }}
                        >
                          <Stack direction="column">
                            <Typography>CC</Typography>

                            <Stack direction="row" spacing={1}>
                              <TextField
                                sx={{ width: 400 }}
                                label="Enter Email"
                                variant="outlined"
                                disabled={isCreating}
                                value={emailInputCc}
                                onChange={handleEmailInputChangeCc}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    handleAddEmailChipCc();
                                  }
                                }}
                                fullWidth
                              ></TextField>

                              <IconButton
                                onClick={handleOpenMenuCc}
                                disabled={isCreating}
                              >
                                <AddCircleRoundedIcon color="primary" />
                              </IconButton>
                            </Stack>
                          </Stack>
                          {/* <Paper style={{ padding: '10px' }}> */}

                          <Menu
                            anchorEl={anchorE2}
                            open={Boolean(anchorE2)}
                            onClose={handleCloseMenuCc}
                          >
                            <MenuItem disabled>Emails:</MenuItem>
                            {userNameListCc.map((user, index) => (
                              <MenuItem
                                key={`email_${index}`}
                                onClick={() =>
                                  handleSelectedValueUserNameCc(user)
                                }
                              >
                                <ListItemIcon>
                                  <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={`${user.placeHolderName} (${user.userEmailId})`}
                                />
                                {selectedUserNameCc.some(
                                  (selectedUser) =>
                                    selectedUser.userEmailId ===
                                    user.userEmailId
                                ) ? (
                                  <CheckCircleIcon color="primary" />
                                ) : null}
                              </MenuItem>
                            ))}
                            {/* <MenuItem disabled>Email Groups:</MenuItem>
                          {userGroupListCc.map((list, index) => (
                            <MenuItem
                              key={`list${index}`}
                              onClick={() =>
                                handleGroupListCc(list.placeHolderName)
                              }
                            >
                              <ListItemIcon>
                                <AddCircleIcon />
                              </ListItemIcon>
                              <ListItemText primary={list.placeHolderName} />
                              {selectedUserGroupCc.includes(list) ? (
                                <CheckCircleIcon color='primary' />
                              ) : null}
                            </MenuItem>
                          ))} */}
                            <MenuItem disabled>Place Holders:</MenuItem>
                            {placeHolderListCc.map((email, index) => (
                              <MenuItem
                                key={`email_${index}`}
                                onClick={() =>
                                  handleSelectPlaceHolderListCc(
                                    email.placeHolderName
                                  )
                                }
                              >
                                <ListItemIcon>
                                  <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={email.placeHolderName} />
                                {selectedPlaceHolderListCc.includes(
                                  email.placeHolderName
                                ) ? (
                                  <CheckCircleIcon color="primary" />
                                ) : null}
                              </MenuItem>
                            ))}
                            <MenuItem disabled>User Roles:</MenuItem>
                            {userRoleListCc &&
                              userRoleListCc.map((email, index) => (
                                <MenuItem
                                  key={`email_${index}`}
                                  onClick={() =>
                                    handleSelectRoleNameCc(
                                      email.placeHolderName
                                    )
                                  }
                                >
                                  <ListItemIcon>
                                    <AddCircleIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={email.placeHolderName}
                                  />
                                  {selectedRoleListCc.includes(
                                    email.placeHolderName
                                  ) ? (
                                    <CheckCircleIcon color="primary" />
                                  ) : null}
                                </MenuItem>
                              ))}
                          </Menu>
                        </Stack>
                        <div>
                          {selectedUserNameCc.map((user, index) => (
                            <Chip
                              key={index}
                              label={user.placeHolderName}
                              onDelete={() => handleRemoveValueCc(user)}
                              style={{ margin: "5px" }}
                            />
                          ))}
                          {selectedUserNameInputCc.map((value, index) => (
                            <Chip
                              key={index}
                              label={value}
                              onDelete={() => handleRemoveValueInputCc(value)}
                              style={{ margin: "5px" }}
                            />
                          ))}
                          {/* {selectedUserGroupCc.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleRemoveUserGroupCc(value)}
                            style={{ margin: "5px" }}
                          />
                        ))} */}
                          {selectedPlaceHolderListCc.map((value, index) => (
                            <Chip
                              key={index}
                              label={value}
                              onDelete={() => handleRemoveValuePlaceCc(value)}
                              sx={{ margin: "4px" }}
                            />
                          ))}
                          {selectedRoleListCc.map((value, index) => (
                            <Chip
                              key={index}
                              label={value}
                              onDelete={() => handleRemoveValueRolesCc(value)}
                              sx={{ margin: "4px" }}
                            />
                          ))}
                        </div>
                        <Stack direction="row" spacing={3}>
                          <div style={{ width: "140%" }}>
                            <Typography>Event</Typography>
                            <Dropdown
                              name="Event"
                              value={formValues.Event}
                              onChange={handleConfigItemChange}
                              disabled
                              options={customerList}
                              optionLabel="name"
                              autoFocus
                              style={{
                                width: "100%",
                                marginTop: "5px",
                                height: "50px",
                              }}
                              valueTemplate={selectedValueTemplate}
                              itemTemplate={renderDropdownItem}
                            />
                          </div>

                          <div style={{ width: "50%" }}>
                            <Typography style={{ paddingBottom: "1rem" }}>
                              Notifications
                            </Typography>
                            <InputSwitch
                              name="Notifications"
                              checked={formValues.Notifications}
                              onChange={handleNotificationChange}
                              disabled={isCreating}
                            />
                          </div>
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={3}>
                        <div style={{ width: "140%", marginTop: "15px" }}>
                          <Typography>RecordType</Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "40%",
                              marginTop: "1rem",
                            }}
                          >
                            <div
                              className="flex align-items-center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RadioButton
                                inputId="TrackNow"
                                name="RecordType"
                                value="TrackNow"
                                onChange={(e) => {
                                  console.log("RecordType", e);
                                  handleConfigItemChange(e);
                                }}
                                checked={formValues.RecordType === "TrackNow"}
                              />
                              <label
                                htmlFor="TrackNow"
                              // style={{ marginLeft: "10px" }}
                              >
                                TrackNow
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RadioButton
                                inputId="PostAward"
                                name="RecordType"
                                value="PostAward"
                                onChange={(e) => {
                                  console.log("RecordType", e.value);
                                  handleConfigItemChange(e);
                                }}
                                checked={formValues.RecordType === "PostAward"}
                              />
                              <label
                                htmlFor="PostAward"
                              // style={{ marginLeft: "10px" }}
                              >
                                PostAward
                              </label>
                            </div>
                          </div>
                        </div>
                      </Stack>
                    </div>

                    <div>
                      <Stack></Stack>
                      <Stack>
                        <Typography>Subject</Typography>
                        {/* <TextField id="filled-basic" label="Enter Subject" variant="filled" sx={{ paddingTop: "10px" }} /> */}

                        <TextField
                          id="filled-basic"
                          label="Enter Subject"
                          name="Mail_Subject"
                          variant="filled"
                          sx={{ paddingTop: "10px" }}
                          disabled={isCreating}
                          value={formValues.Mail_Subject}
                          onChange={handleConfigItemChange}
                          required
                          error={isFormSubmitted && !formValues.Mail_Subject} // show error if form submitted and field is empty
                          helperText={
                            isFormSubmitted && !formValues.Mail_Subject
                              ? "Subject is required"
                              : ""
                          }
                        />
                      </Stack>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        Message
                        {/* <div style={{ display: "flex" }}>
                        <div style={{ paddingRight: "1rem" }}>HTML</div>
                        <InputSwitch
                          name='html'
                          checked={formValues.html}
                          onChange={(e) => handleConfigItemChange(e)}
                          disabled={isCreating}
                        />
                      </div> */}
                      </div>
                      <TextField
                        label="Enter Message"
                        multiline
                        name="Mail_Message"
                        rows={9}
                        disabled={isCreating}
                        value={formValues.Mail_Message}
                        variant="filled"
                        sx={{ width: "530px", paddingTop: "12px" }}
                        onChange={(e) => {
                          if (e.target.value.length <= 8000) {
                            handleConfigItemChange(e);
                            setRemainingChars(8000 - e.target.value.length);
                          }
                        }}
                        required
                        error={isFormSubmitted && !formValues.Mail_Message} // show error if form submitted and field is empty
                        helperText={
                          isFormSubmitted && !formValues.Mail_Message
                            ? "Enter a message"
                            : ""
                        }
                      />

                      <Typography
                        sx={{
                          fontSize: "11px",
                          fontWeight: "small",
                          color: remainingChars === 0 ? "red" : "inherit",
                          display: remainingChars === 8000 ? "none" : "block",
                        }}
                      >
                        {remainingChars} Characters are left
                      </Typography>
                    </div>
                  </Stack>

                  <Stack direction="row" justifyContent="end">
                    <IconButton disabled={isPermission || isCreating}>
                      <Button
                        style={{
                          color: "blue",
                          width: "100px",
                          height: "42px",
                          fontWeight: "bold",
                        }}
                        onClick={handleReset}
                        disabled={isCreating}
                      >
                        Reset
                      </Button>
                    </IconButton>
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        width: "100px",
                        height: "42px",
                        marginLeft: "20px",
                      }}
                      disabled={isCreating || !isFormChanged}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </Stack>
                </Stack>
              </Box>

              <div class Name="card" style={{ padding: "0.5rem" }}>
                <DataTable
                  value={configurationListNew}
                  tableStyle={{ minWidth: "50rem" }}
                  loading={isDataTableLoading}
                  scrollable
                  dataKey="emailTaskId"
                  selection={selectedEmail}
                  onSelectionChange={(e) => handleSelect(e)}
                >
                  <Column
                    selectionMode="single"
                    //headerStyle={{ width: "3rem" }}
                    style={{ width: "1rem", margin: "0" }}
                  >
                    Select
                  </Column>
                  <Column
                    field="emailTaskId"
                    header="Event"
                    body={(rowData) => (
                      <>
                        {rowData.emailTaskId &&
                          rowData.emailTaskId.length > 9 ? (
                          <Tooltip
                            title={getClientName(rowData.emailTaskId)}
                            arrow
                          >
                            <span>
                              {rowData.emailTaskId.substring(0, 9)}...
                            </span>
                          </Tooltip>
                        ) : (
                          getClientName(rowData.emailTaskId)
                        )}
                      </>
                    )}
                  ></Column>
                  <Column
                    field="isReminder                  "
                    header="Notifications"
                    body={(rowData) => cellNotifications(rowData)}
                  ></Column>
                  <Column
                    field="recipients"
                    header="Recipient"
                    body={(rowData) => cellRecipient(rowData)}
                  ></Column>
                  <Column
                    field="ccs"
                    header="CC"
                    body={(rowData) => cellCC(rowData)}
                  ></Column>
                  <Column
                    field="recordType"
                    header="Record Type"
                  // body={(rowData) => cellMessage(rowData)}
                  ></Column>
                  <Column
                    field="emailSubject"
                    header="Email Notification Subject"
                  ></Column>
                  <Column
                    field="emailMessage"
                    header="Email Notification Message"
                    body={(rowData) => cellMessage(rowData)}
                  ></Column>
                  <Column
                    style={{ textAlign: "center" }}
                    body={(rowData) => (
                      <>
                        <Stack direction="row">
                          <EditButton rowData={rowData} />
                        </Stack>
                      </>
                    )}
                  ></Column>
                </DataTable>
              </div>
            </Container>
            {/* <Box
              sx={{
                // height: "%",
                width: "5%",
                margin: "5px",
              }}
            >
              <div
                style={{
                  background: "#F1F2F6",
                  borderRadius: "2%",
                }}
              >
                <Item
                  sx={{
                    height: "50%",
                  }}
                >
                  <NotificationConfigurationToolbox
                    displayHomePage={displayHomePage}
                    displayTrackNowFilingMasterList={
                      displayTrackNowFilingMasterList
                    }
                    displayPostAwardFilingMasterList={
                      displayPostAwardFilingMasterList
                    }
                    displayClientFilingTrackerList={
                      displayClientFilingTrackerList
                    }
                    displayHistory={displayHistory}
                  />
                </Item>
              </div>
            </Box> */}
          {/* </Stack> */}
          <ConfirmDialog />
          <NotificationsDialog />
          <Dialog
            header="Info"
            visible={showAlert}
            // style={{ width: "30vw" }}
            onHide={() => closeAlert()}
            footer={footerContent}
          >
            <p className="m-0">{alertMessage}</p>
          </Dialog>
        </Stack>
      )}
      {/* {
        <NotificationConfigurationHistory
          show={showHistory}
          dockAt="right"
          selectedEmail={selectedEmail}
          customerList={customerList}
          // commentActivityHistory={commentActivityHistory}
          activityHistory={activityHistory}
          handleClose={closeHistory}
        />
      } */}
    </React.Fragment>
  );
}
