import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ShortTextIcon from "@mui/icons-material/ShortText";

import ListItemIcon from "@mui/material/ListItemIcon";
import CategoryIcon from "@mui/icons-material/Category";
import DeleteIcon from "@mui/icons-material/Delete";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import CustomerContext from "../../context/customer/CustomerContext";
import AuthContext from "../../context/auth/AuthContext";
import states from "../../data/StateLookup.json";
import MultiSelect from "../common/MultiSelect";
import Alert from "@mui/material/Alert";
import useDialog from "../common/UseDialog";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import _ from "lodash";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Customer,
  BusinessCategory,
  CustomerCategory,
} from "../../types/customer";
import { resolve } from "path";
import usePermission from "../../usePermission";

type Anchor = "top" | "left" | "bottom" | "right";

export default function CustomerBusinessCategory(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  cust: Customer;
  categories?: CustomerCategory[];
  categoriesLookup?: BusinessCategory[];
  refreshTable: () => void;
}) {
  const customerContext = useContext(CustomerContext);
  const {
    createCustomerBusinessCategories,
    getCustomerBusinessCategories,
    deleteCustomerBusinessCategories,
    // customerLoading,
    initiateClientFilingWorkflow,
    updateCustomer,
  } = customerContext;

  const authContext = useContext(AuthContext);
  const { authUser } = authContext;

  const fontStyle = {
    fontWeight: "regular",
    fontSize: "1rem",
    color: "#424242",
  };

  const [data, setData] = React.useState<CustomerCategory[]>([]);
  const [sourceData, setSourceData] = React.useState<CustomerCategory[]>([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedState, setSelectedState] = useState<
    string | undefined | null
  >();
  const [dataChanged, setDataChanged] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [initCategories, setInitCategories] = useState<string[]>([]);
  const [selectedIdForDelete, setSelectedIdForDelete] = useState<string>("");
  const [selectedStateForDelete, setSelectedStateForDelete] =
    useState<string>("");
  const [selectedCatIdForDelete, setSelectedCatIdForDelete] =
    useState<string>("");
  const [stateError, setStateError] = React.useState(false);
  const [bussinessCatError, setBussinessCatError] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const navigate = useNavigate();

  const closeAlert = () => {
    setSelectedState(() => "");
    setSelectedCategories(() => []);
    setInitCategories(() => []);
    setShowAlert(false);
    props.handleClose();
    // navigate("/");
  };

  const footerContent = (
    <div>
      <Button
        label='OK'
        icon='pi pi-check'
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        console.log(`Drawer Mouse Key Event...`);
        // if (
        // event.type === "keydown" &&
        // ((event as React.KeyboardEvent).key === "Tab" ||
        // (event as React.KeyboardEvent).key === "Shift")
        // ) {
        // return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };

  const handleDeleteSubmit = async () => {
    console.log("Submitting Delete....", typeof selectedIdForDelete);
    console.log("deleteBC", selectedIdForDelete, props.cust.businessCategory);
    closeDialog();
    setState({
      ...state,
      [props.dockAt ? props.dockAt : "right"]: true,
    });
    setIsLoadingList(true)
    if (props.cust.businessCategory && updateCustomer) {
      let _cust = { ...props.cust };
      let BC = props.cust.businessCategory.filter(
        (ele) => ele.id !== parseInt(selectedIdForDelete)
      );
      _cust.businessCategory = BC.map((ele) => ({
        id: ele.id ? ele.id : 0,
        customerId: props.cust?.customerId,
        state: ele.state,
        businessCategoryId: ele.businessCategoryId,
        updateDate: new Date(),
        updateUser: authUser?.userId + "",
      }));
      _cust.postAwardLicenseId = _cust.postAwardLicenseId === 0 ? null : _cust.postAwardLicenseId
      console.log("deleteBC", _cust);
      const resUpd: any = await updateCustomer(_cust);
      console.log("Submitting Delete.... res", resUpd);
      try {
        if (resUpd.status === "Success" && initiateClientFilingWorkflow) {
          console.log("WorkflowCalls");
          const payloadWorkflow = [
            {
              customerId: props.cust?.customerId,
              businessCategoryId: selectedCatIdForDelete,
              businessOperation: "delete",
              state: selectedStateForDelete,
            },
          ];
          console.log("payloadWorkflow", payloadWorkflow);

          let result = await initiateClientFilingWorkflow(
            props.cust?.customerId,
            payloadWorkflow
          );
          console.log("&&& initiateClientFilingWorkflow", result);
          if (result) {
            console.log(
              "UPDCHECK2",
              "resUpd",
              resUpd,
              "errorMessage",
              resUpd.errorMessage
            );
            if (resUpd && resUpd.errorMessage) {
              console.log(
                "UPDCHECK2.1",
                "resUpd",
                resUpd,
                "errorMessage",
                resUpd.errorMessage
              );
              setSelectedCategories(() => []);
              setInitCategories(() => []);

              if (getCustomerBusinessCategories) {
                const _catData = await getCustomerBusinessCategories(
                  props.cust?.customerId
                );
                setSourceData(() => _catData);
                props.refreshTable();
              }
              setAlertMessage(resUpd.errorMessage);
              setShowSuccess(true);
              setShowError(false);
              // closeDialog();
              // setState({
              //   ...state,
              //   [props.dockAt ? props.dockAt : "right"]: true,
              // });
            } else {
              console.log(
                "UPDCHECK2.1",
                "resUpd",
                resUpd,
                "errorMessage",
                resUpd.errorMessage
              );
              setSelectedCategories(() => []);
              setInitCategories(() => []);

              if (getCustomerBusinessCategories) {
                const _catData = await getCustomerBusinessCategories(
                  props.cust?.customerId
                );
                setSourceData(() => _catData);
                props.refreshTable();
              }
              setAlertMessage("Successfully deleted Client Business Category");
              setShowSuccess(true);
              setShowError(false);
              // closeDialog();
              // setState({
              //   ...state,
              //   [props.dockAt ? props.dockAt : "right"]: true,
              // });
            }
            setIsLoading(false);
            setBussinessCatError(false);
            setSelectedState(() => null);
            setSelectedCategories(() => []);
            setInitCategories(() => []);
          }
        } else if (resUpd.status === "Failure") {
          setAlertMessage(resUpd.errorMessage);
          setShowSuccess(false);
          setShowError(true);
          // closeDialog();
          // setState({ ...state, [props.dockAt ? props.dockAt : "right"]: true });
        } else {
          setAlertMessage("Error while deleting Client Business Category");
          setShowSuccess(false);
          setShowError(true);
          // closeDialog();
          // setState({ ...state, [props.dockAt ? props.dockAt : "right"]: true });
        }
      } catch (error) {
        console.error("ERR#", error);
        setAlertMessage("Error while deleting Client Business Category");
        setShowSuccess(false);
        setShowError(true);
        // closeDialog();
        // setState({ ...state, [props.dockAt ? props.dockAt : "right"]: true });
      } finally{
        setIsLoadingList(false)
      }
    }
  };

  const closeDeleteConfirm = () => {
    console.log("Cancelling....");
    setSelectedIdForDelete("");
    setSelectedCatIdForDelete("");
    setSelectedStateForDelete("");
    setState({ ...state, [props.dockAt ? props.dockAt : "right"]: true });
    // navigate("/");
  };

  const { AlertDialog, showDialog, closeDialog } = useDialog({
    onSubmit: handleDeleteSubmit,
    onClose: closeDeleteConfirm,
  });

  const handleCategoryChanges = (
    values: string[],
    keys: any,
    action: string
  ) => {
    if (values) {
      console.log("@@Changed BusinessCategories:", values);
      setSelectedCategories((old) => values);
      setDataChanged(() => hasDataChanged(values));
      setBussinessCatError(false);
      // formik.setFieldValue("businessCategoryId", selectedCategories, false);
    }
  };

  const hasDataChanged = (values: string[]): boolean => {
    const _delCats = _.difference(initCategories, values);

    let _addCats = _.difference(values, initCategories);
    return _delCats.length > 0 || _addCats.length > 0;
  };

  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
    }
    // setApprovalComment((old) => "");
    if (!props.show) {
      setInitCategories(() => []);
    }
    // if (getCustomerComments && props.cust && props.cust.customerId) {
    // console.log(`Fetching Commnet for :${props.cust.customerId}`);
    // fetchCustomerComments(props.cust.customerId);
    // }
    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    let _data: CustomerCategory[] = props.categories!;
    if (props.categories) {
      setSourceData(() => _data);
    }
    //eslint-disable-next-line
  }, [props.categories]);

  useEffect(() => {
    if (sourceData) {
      setIsLoadingList(true);
      try {
        const _data2 = sourceData.filter((item) => item.state !== null);
        const _data3 = _.sortBy(_data2, [
          (item) => item.state,
          (item) => item.businessCategoryName,
        ]);
        console.log("@@Ggroup Cust Bus Cats:", _data3);

        setData(() => _data3);
      } finally {
        setIsLoadingList(false);
      }
    }

    //eslint-disable-next-line
  }, [sourceData]);

  useEffect(() => {
    // if(props.cust && props.cust.createDate!==null)
    //eslint-disable-next-line
  }, [props.cust]);

  useEffect(() => {
    if (selectedState) {
      const _initCat = data
        .filter((cat) => cat.state === selectedState)
        .map((item) => item.businessCategoryId + "");
      setInitCategories(() => _initCat);
      setDataChanged(() => false);
    }
    //eslint-disable-next-line
  }, [selectedState]);

  const IsDisabled = () => {
    return !usePermission(
      "ClientProfile",
      "editBusinessClient",
      props.cust?.customerId
    );
  };

  // useEffect(() => {
  // if (getCustomerComments && props.cust && props.cust.customerId) {
  // console.log(`Fetching Comment for :${props.cust.customerId}`);
  // fetchCustomerComments(props.cust.customerId);
  // }
  // //eslint-disable-next-line
  // }, []);

  const list = (anchor: Anchor) => (
    <Fragment>
      <Dialog
        header='Info'
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className='m-0'>{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role='presentation'
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card>
            <CardHeader>
              <Typography gutterBottom variant='h5' component='div'>
                Client Business Categoriess
              </Typography>
            </CardHeader>
            <CardContent>
              <Stack direction='row' justifyContent='space-between'>
                <Typography
                  variant='h6'
                  color='text.secondary'
                  sx={{ mt: "1rem" }}
                >
                  BUSINESS CATEGORY
                </Typography>
                <IconButton>
                  <CloseIcon onClick={() => handleCancel(anchor)} />
                </IconButton>
              </Stack>
            </CardContent>
            <CardContent>
              <FormControl fullWidth variant='filled'>
                <Typography
                  sx={fontStyle}
                  color={stateError ? "error" : "inherit"}
                >
                  State*
                </Typography>
                <Select
                  id='state'
                  name='state'
                  value={selectedState ? selectedState : ""}
                  onChange={handleStateChange}
                  label='State'
                  disabled={IsDisabled()}
                >
                  {states.map((item) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.state}
                    </MenuItem>
                  ))}
                </Select>
                {stateError && (
                  <Typography variant='caption' color='error'>
                    State is required
                  </Typography>
                )}
              </FormControl>
              <FormControl fullWidth variant='filled' sx={{ mt: "2rem" }}>
                {/* <InputLabel id='customer-category-label'>
Customer Category
</InputLabel> */}
                <Typography sx={fontStyle}>Business Category*</Typography>
                <MultiSelect
                  inputs={
                    props.categoriesLookup &&
                    props.categoriesLookup.map((item) => ({
                      name: item.businessCategoryName
                        ? item.businessCategoryName
                        : "",
                      value: item.businessCategoryId
                        ? item.businessCategoryId + ""
                        : "",
                    }))
                  }
                  values={selectedCategories}
                  initValues={initCategories}
                  updateSelection={handleCategoryChanges}
                />
                {bussinessCatError && (
                  <Typography variant='caption' color='error'>
                    Business Category is required
                  </Typography>
                )}
              </FormControl>
            </CardContent>
            <CardActions
              sx={{
                mt: "1.5rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <MuiButton
                variant='contained'
                color='secondary'
                sx={{ margin: 1, padding: 1 }}
                onClick={() => handleCancel(anchor)}
              >
                Cancel
              </MuiButton>
              <MuiButton
                variant='contained'
                type='submit'
                // sx={{ margin: 1, padding: 1 }}
                sx={{
                  margin: 1,
                  padding: 1,
                  backgroundColor: "#0b3edf",
                }}
                disabled={IsDisabled() && (!dataChanged || stateError)}
                onClick={() => handleOk(anchor)}
              >
                SAVE
              </MuiButton>
            </CardActions>
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isLoading ? <CircularProgress size={40} /> : null}
              {showError && <Alert severity='error'>{alertMessage}</Alert>}
              {showSuccess && <Alert severity='success'>{alertMessage}</Alert>}
            </CardContent>
            <CardContent>
              <ListItem disablePadding>
                <ListItemIcon>{/* <CategoryIcon /> */}</ListItemIcon>
                <ListItemText primary={"State"} />
                <ListItemText primary={"CategoryName"} />
                <ListItemIcon>{/* <CategoryIcon /> */}</ListItemIcon>
              </ListItem>
              <Divider />
              <List>
                {isLoadingList ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100px",
                    }}
                  >
                    <CircularProgress size={40} />
                  </div>
                ) : (
                  data &&
                  data.map((item, index: number) => (
                    <Fragment>
                      <ListItem key={index} disablePadding>
                        <ListItemIcon>
                          <LabelImportantIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            item && item.state && item.state !== null
                              ? item.state
                              : ""
                          }
                        />
                        <ListItemText
                          primary={
                            item &&
                              item.businessCategoryName &&
                              item.businessCategoryName !== null
                              ? item.businessCategoryName
                              : ""
                          }
                        />

                        <ListItemIcon>
                          <ListItemButton
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                            onClick={() => handleDelete(item)}
                            disabled={IsDisabled()}
                          >
                            <DeleteIcon />
                          </ListItemButton>
                        </ListItemIcon>
                      </ListItem>
                    </Fragment>
                  ))
                )}
              </List>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );

  // const onCommentChange = async (e: React.ChangeEvent<any>) => {
  // setApprovalComment((old) => e.target.value);
  // };

  // const handleStateChange = (event: SelectChangeEvent) => {
  // const {
  // target: { value },
  // } = event;
  // setSelectedState((old) => value);
  // };

  const handleStateChange = (
    event: SelectChangeEvent<typeof selectedState>
  ) => {
    setSelectedState(event.target.value as typeof selectedState);
    setDataChanged(true);
    setStateError(false);
  };

  const addCategoriers = async (cats: string[]): Promise<boolean> => {
    console.log(`Add Business Category changes for`, cats);
    return new Promise(async (resolve, reject) => {
      try {
        if (updateCustomer && cats.length > 0) {
          const payloadList = cats.map((item) => ({
            customerId: props.cust?.customerId,
            state: selectedState === "" ? null : selectedState,
            businessCategoryId: item,
          }));

          let _cust = { ...props.cust };
          let BC: any[];
          console.log("addBC", payloadList, props.cust.businessCategory);
          BC =
            props.cust.businessCategory !== undefined
              ? [...props.cust.businessCategory]
              : [];
          BC.push(...payloadList);
          console.log("addBC", BC);
          _cust.businessCategory = BC.map((ele) => ({
            id: ele.id ? ele.id : 0,
            customerId: props.cust?.customerId,
            state: ele.state,
            businessCategoryId: ele.businessCategoryId,
            updateDate: new Date(),
            updateUser: authUser?.userId + "",
          }));
          _cust.updateDate = new Date();
          _cust.updateUser = authUser?.userId + "";
          console.log("addBC", _cust);

          let resp = await updateCustomer(_cust);
          if (resp) {
            resolve(true);
          }
        }
      } catch (error) {
        console.log(
          `Error while saving Client Business Category changes`,
          error
        );
        reject(false);
      }
    });
  };
  const getRecordIdForDelete = (catId: string): number => {
    const _id = data.filter(
      (item) =>
        item.state === selectedState &&
        item.businessCategoryId === parseInt(catId)
    )[0].id;
    return _id ? _id : 0;
  };

  const deleteCategoriers = (cats: string[]): Promise<boolean> => {
    console.log(`Delete Business Category changes for`, cats);
    return new Promise(async (resolve, reject) => {
      if (deleteCustomerBusinessCategories && cats.length > 0) {
        let promises: Promise<boolean>[] = [];
        cats.forEach((item) => {
          promises.push(
            new Promise(async (resolve, reject) => {
              try {
                const _id = getRecordIdForDelete(item);
                const res = await deleteCustomerBusinessCategories(_id);
                if (res) {
                  console.log(`Delete Business Category sucess1`);
                  resolve(true);
                }
              } catch (error) {
                reject(false);
              }
            })
          );
        });
        return Promise.all(promises)
          .then((result) => {
            console.log(`Delete Business Category sucess2`);
            resolve(true);
          })
          .catch((err) => reject(false));
      } else {
        resolve(true);
      }
    });
  };

  const handleOk = async (anchor: Anchor) => {
    setIsLoading(true);
    if (!selectedState || selectedCategories.length === 0) {
      setStateError(!selectedState);
      setBussinessCatError(selectedCategories.length === 0);
      setIsLoading(false);
      return;
    }
    if (selectedCategories.length > 0) {
      setBussinessCatError(false);
    }
    setShowSuccess(false);
    // navigate("/filing-master-list");
    // console.log(`Add Comment:${approvalComment}`);
    const _delCats = _.difference(initCategories, selectedCategories);
    const _addCats = _.difference(selectedCategories, initCategories);
    // deleteCategoriers(_delCats).then((result) => {
    // console.log(`Delete Business Category sucessf0`);
    // });
    let _cust = { ...props.cust };
    let BC: BusinessCategory[] = [];
    if (props.cust.businessCategory) {
      BC = props.cust.businessCategory.filter(
        (ele) => !_delCats.includes(ele.id ? ele.id + "" : "")
      );
    }

    _cust.businessCategory = BC.map((ele) => ({
      id: ele.id ? ele.id : 0,
      customerId: props.cust?.customerId,
      state: ele.state,
      businessCategoryId: ele.businessCategoryId,
      updateDate: new Date(),
      updateUser: authUser?.userId + "",
    }));
    _addCats.forEach((ele) => {
      _cust.businessCategory?.push({
        id: 0,
        customerId: props.cust?.customerId,
        state: selectedState,
        businessCategoryId: parseInt(ele),
        createDate: new Date(),
        createUser: authUser?.userId + "",
      });
    });
    _cust.postAwardLicenseId = _cust.postAwardLicenseId === 0 ? null : _cust.postAwardLicenseId
    try {
      console.log("deleteBC", _cust);
      if (updateCustomer) {
        const resUpd: any = await updateCustomer(_cust);
        if (resUpd.status === "Success" && initiateClientFilingWorkflow) {
          console.log(`Delete Business Category sucessf`);
          if (initiateClientFilingWorkflow) {
            let payloadWorkflow = _addCats.map((item) => ({
              customerId: props.cust?.customerId,
              businessCategoryId: item,
              businessOperation: "add",
              state: selectedState,
            }));
            _delCats.forEach((item) => {
              payloadWorkflow.push({
                customerId: props.cust?.customerId,
                businessCategoryId: item,
                businessOperation: "delete",
                state: selectedState,
              });
            });
            await initiateClientFilingWorkflow(
              props.cust?.customerId,
              payloadWorkflow
            );
          }
          if (getCustomerBusinessCategories) {
            const _catData = await getCustomerBusinessCategories(
              props.cust?.customerId
            );
            setSourceData(() => _catData);
            props.refreshTable();
          }
          if (resUpd && resUpd.errorMessage) {
            setAlertMessage(resUpd.errorMessage);
          } else {
            setAlertMessage("Successfully updated Client Business Categories");
          }
          setShowSuccess(true);
          setIsLoading(false);
          setShowError(false);
          setBussinessCatError(false);
          setSelectedState(() => null);
          setSelectedCategories(() => []);
          setInitCategories(() => []);
          return;
          // })
        } else if (resUpd.status === "Failure") {
          if (resUpd && resUpd.errorMessage) {
            setAlertMessage(resUpd.errorMessage);
          } else {
            setAlertMessage("Error while updating Client Business Category");
          }
          setShowSuccess(false);
          setShowError(true);
          closeDialog();
          setState({ ...state, [props.dockAt ? props.dockAt : "right"]: true });
        } else {
          setAlertMessage("Error while updating Client Business Category");
          setShowSuccess(false);
          setShowError(true);
          closeDialog();
          setState({ ...state, [props.dockAt ? props.dockAt : "right"]: true });
        }
      }
    } catch (error) {
      console.log(`Error while saving Client Business Category changes`, error);
      setAlertMessage("Error while saving Client Business Category changes");
      setShowSuccess(false);
      setShowError(true);
      closeDialog();
      setState({ ...state, [props.dockAt ? props.dockAt : "right"]: true });
    }
  };

  const handleCancel = async (anchor: Anchor) => {
    setSelectedState(() => null);
    setSelectedCategories(() => []);
    setState({ ...state, [anchor]: false });
    setShowSuccess(false);
    setShowError(false);
    setStateError(false);
    setBussinessCatError(false);
    props.handleClose();
  };

  const handleDelete = async (item: any) => {
    console.log("itemCheck", item);
    setSelectedStateForDelete(item.state);
    setSelectedIdForDelete(item.id);
    setSelectedCatIdForDelete(item.businessCategoryId);
    // props.handleClose();
    setState({ ...state, [props.dockAt ? props.dockAt : "right"]: false });
    showDialog(
      "Confirmation",
      `Are you sure to delete the Business Category ${item.businessCategoryName}?`,
      "confirm"
    );
    console.log(`Delete Client Business Category`, item);
  };
  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
// <React.Fragment key={anchor}>
<Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
          <AlertDialog />
        </React.Fragment>
      )}
    </div>
  );
}
