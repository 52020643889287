import Tooltip from '@mui/material/Tooltip';

interface TooltipProps {
  title: string;
  children: any;
}

export default function ToolTip({ title, children }: TooltipProps) {
  return (
    <Tooltip title={title} placement="left-start">
      {children}
    </Tooltip>
  );
}
