import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Anchor } from "@mui/icons-material";
// import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import AuthContext from "../../../context/auth/AuthContext";
import ClientFilingMasterContext from "../../../context/client-filing-master/ClientFilingMasterContext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { DateTime } from "luxon";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Alert,
  Avatar,
  Chip,
} from "@mui/material";
// import { DraftFilingMaster } from "../../types/FilingMaster";
import { ClientFilingMaster } from "../../../types/ClientFilingMaster";
import FilingMasterContext from "../../../context/filing-master/FilingMasterContext";
import states from "../../../data/StateLookup.json";
import Loader from "../../common/Loader";

type Anchor = "top" | "left" | "bottom" | "right";

export default function PostAwardApproveClientFilingMaster(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  drafts?: ClientFilingMaster[];
  updateSelectedWorkflow?: (workflow: any) => void;
  refreshList: () => void;
}) {
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const { approveBulkPostAwardClientFilingMaster, rejectBulkPostAwardClientFilingMaster } =
    clientFilingMasterContext;
  const filingMasterContext = useContext(FilingMasterContext);
  const { getApproverList } = filingMasterContext;
  const authContext = useContext(AuthContext);
  const {
    authUser,
    userRoles,
    getCustomerNotifications,
    getFilingNotifications,
    getCustomerNotificationsWorkFlow,
    setNotificationCount,
    toLocalDateTime
  } = authContext;
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [approvalComment, setApprovalComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [approvers, setApprovers] = useState<any[]>([]);
  // const [selectedApprover, setSelectedApprover] = useState<string>("");
  const [showApproverAlert, setShowApproverAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerNotificationCount, setCustomerNotificationCount] =
    React.useState(0);
  const [filingNotificationCount, setFilingNotificationCount] =
    React.useState(0);
  const [customerNotificationsWorkFlow, setCustomerNotificationsWorkFlow] =
    React.useState(0);
  const [clientNames, setClientNames] = useState<(string | undefined)[] | []>(
    []
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      console.log(`Drawer Mouse Key Event...`);
      // if (
      //   event.type === "keydown" &&
      //   ((event as React.KeyboardEvent).key === "Tab" ||
      //     (event as React.KeyboardEvent).key === "Shift")
      // ) {
      //   return;
      // }
      // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
      // setState({ ...state, [anchor]: open });
      // if (!open) props.handleClose();
    };

  const closeAlert = () => {
    setShowAlert(false);
    // navigate("/client-filing-master-approval-list");
    setApprovalComment("");
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const handleClose = async (anchor: Anchor) => {
    setState({ ...state, [anchor]: false });
    props.handleClose();
    // setSelectedApprover("");
    setApprovers([]);
    setApprovalComment("");
  };

  const listApprovers = async () => {
    try {
      const { drafts } = props;
      if (!getApproverList || !drafts || drafts.length === 0) {
        return;
      }
      const data = await getApproverList();
      let filteredApprovers = [];
      // const filteredApprovers = data.filter((approver: any) => {
      //   return (
      //     approver.juristiction === drafts[0].juristiction &&
      //     drafts[0]?.customerId === approver.customerId &&
      //     (drafts[0].juristiction !== "State" ||
      //       states.find((i) => i.code === drafts[0].stateInfo)?.code ===
      //         approver.state)
      //   );
      // });
      if (drafts[0].juristiction === "State") {
        filteredApprovers = data.filter((approver: any) => {
          return (
            approver.juristiction === drafts[0].juristiction &&
            approver.state === drafts[0].stateInfo &&
            approver.customerId === drafts[0]?.customerId
          );
        });
      } else {
        filteredApprovers = data.filter((approver: any) => {
          return (
            approver.juristiction === drafts[0].juristiction &&
            approver.customerId === drafts[0]?.customerId
          );
        });
      }
      console.log("Filtered Approvers", filteredApprovers);
      setApprovers(filteredApprovers);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (showApproverAlert) {
      const timer = setTimeout(() => {
        setShowApproverAlert(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showApproverAlert]);

  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
    }
    listApprovers();
    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    // if(props.draft && props.draft.createDate!==null)
    //eslint-disable-next-line
  }, [props.drafts]);
  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      console.log(
        `Toogle Drawer UED: DockAt:${props.dockAt}, Show: ${props.show}`
      );
      // toggleDrawer(props.dockAt, props.show);
      setState({ ...state, [props.dockAt]: props.show });
    }
    setApprovalComment((old) => "");
    // setSelectedApprover("");
    //eslint-disable-next-line
    // listApprovers()
  }, []);

  const toLocalTime = (date: Date): string => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const getUserName = (userId: any) => {
    // console.log("USER ROLES LKP:", userRoles);
    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]
          ?.userName
      : "";
    return user;
  };

  const list = (anchor: Anchor) => (
    <Grid container>
      <Stack
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
          minWidth: "35vw",
          display: "flex",
          alignContent: "center",
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography gutterBottom variant="h5" component="div">
                  Post Award Client Filing Master Approvals
                </Typography>
                <IconButton>
                  <CloseIcon onClick={() => handleClose(anchor)} />
                </IconButton>
              </Stack>
              {/* <Typography
              variant='h6'
              color='text.secondary'
              sx={{ mt: "0.5rem" }}
            >
              Client Filing Master Draft Approvals:
               {props.draft?.filingName} 
            </Typography> */}

              {/* <Typography variant='subtitle2' color='text.secondary'>
              {props.draft &&
              props.draft.createDate &&
              props.draft.createDate !== null
                ? // ? format(props.draft.createDate, "dd-MMM-yyyy HH:MI:SS")
                  `${toLocalTime(props.draft.createDate)}`
                : ""}
            </Typography> */}
            </CardContent>
            <Box sx={{ mx: 2 }}>
              <Typography gutterBottom variant="h5" component="div">
                Approve Workflow
              </Typography>
              <List>
                {props.drafts &&
                  props.drafts.map((item: any, index: number) => (
                    <Fragment key={index}>
                      {index !== 0 && <Divider />}
                      <Stack direction="row" justifyContent="space-between">
                        <ListItem
                          disablePadding
                          style={{ fontWeight: "bold", fontSize: "16px" }}
                        >
                          {getUserName(item.createUser)}
                        </ListItem>
                        <ListItem >
                          <ListItemText
                            secondary={
                              item &&
                              item.createDate &&
                              toLocalDateTime &&
                              `${toLocalDateTime(item.createDate)}`
                            }
                          />
                        </ListItem>
                      </Stack>
                      <Stack direction="row">
                        <ListItem disablePadding>Client Name</ListItem>
                        <ListItem >
                          {item.customerName}
                        </ListItem>
                      </Stack>
                      <Stack direction="row">
                        <ListItem disablePadding>Filing Master Draft</ListItem>
                        <ListItem >
                        {item.filingName} 
                        </ListItem>
                      </Stack>
                      <Stack direction="row">
                        <ListItem disablePadding>Submission Type</ListItem>
                        <ListItem sx={{ textAlign: "right" }}>
                          {item.businessOperation?.toUpperCase()}
                        </ListItem>
                      </Stack>
                      <Stack direction="row">
                        <ListItem disablePadding>Current Approver</ListItem>
                        <ListItem sx={{ textAlign: "right" }}>
                          {item.approverName}
                        </ListItem>
                      </Stack>
                    </Fragment>
                  ))}
              </List>
              <TextField
                id="notes"
                name="notes"
                label="Notes"
                multiline
                fullWidth
                minRows={3}
                variant="filled"
                placeholder={"Enter Comments..."}
                value={approvalComment}
                onChange={onCommentChange}
              />
            </Box>
            <CardActions
              sx={{
                mt: "3rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <MuiButton
                color="primary"
                sx={{ margin: 1, padding: 1 }}
                onClick={() => handleClose(anchor)}
              >
                Cancel
              </MuiButton>
              <MuiButton
                variant="contained"
                color="secondary"
                sx={{ margin: 1, padding: 1 }}
                onClick={() => handleCancel(anchor, false)}
              >
                Reject
              </MuiButton>
              <MuiButton
                variant="contained"
                type="submit"
                sx={{ margin: 1, padding: 1, backgroundColor: "#0b3edf" }}
                onClick={() => handleOk(anchor, true)}
              >
                Approve
              </MuiButton>
            </CardActions>
          </Card>
      </Stack>
    </Grid>
  );

  const onCommentChange = async (e: React.ChangeEvent<any>) => {
    setApprovalComment((old) => e.target.value);
    // await hasTokenExpired();
    // let value = "";
    // if (e.target.name === "groupName") {
    //   getGroupId();
    // }
    // console.log(`Setting [${e.target.name}]: ${e.target.value}`);
    // setFilingMaster((master) => ({
    //   ...master,
    //   [e.target.name]: e.target.value,
    // }));
    // console.log(filingMaster);
  };
  const fetchNotificationCount = async () => {
    console.log("CustomerNotification", getCustomerNotifications);
    console.log("filingNotifications", getFilingNotifications);

    if (
      getCustomerNotifications &&
      getFilingNotifications &&
      getCustomerNotificationsWorkFlow
    ) {
      console.log("@@Fetch Customers2:");
      try {
        let custList = await getCustomerNotifications();
        custList = custList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        console.log("@@customerNotifications:", custList);
        // setTotalNotificationCount((old) => old + custList.length);

        setCustomerNotificationCount(custList.length);
        let filingList = await getFilingNotifications();
        filingList = filingList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        console.log("@@filingNotifications:", filingList);
        // setTotalNotificationCount((old) => old + filingList.length);
        setFilingNotificationCount(filingList.length);
        let custListWorkflow = await getCustomerNotificationsWorkFlow();
        custListWorkflow = custListWorkflow
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");
        console.log("@@filingNotifications:", custListWorkflow);

        setCustomerNotificationsWorkFlow(custListWorkflow.length);
        // setTotalNotificationCount(
        //   (old) =>
        //     custList?.length + filingList?.length + custListWorkflow?.length
        // );
        const count =
          custList?.length + filingList?.length + custListWorkflow?.length;
        if (setNotificationCount) {
          setNotificationCount(count);
        }
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };
  const handleOk = async (anchor: Anchor, approvalStatus?: boolean) => {
    // navigate("/filing-master-list");
    try {
      if (props.drafts) {
        setIsLoading(true);
        const bulkPayload = props.drafts.map((draft) => {
          return {
            notes: approvalComment,
            role: authUser?.roleName,
            userid: authUser?.userId + "",
            workFlowId: draft?.workflowId,
            draftId: draft?.draftId,
          };
        });

        if (approveBulkPostAwardClientFilingMaster) {
          const res = await approveBulkPostAwardClientFilingMaster(bulkPayload);
          console.log(`Workflow approve result:`, res);
          // if (res) {
          // console.log(`Workflow has been approved: ${res.filingName}`);
          // setAlertMessage("Workflow has been approved");
          if (props.drafts && Array.isArray(props.drafts)) {
            setClientNames(props.drafts.map((item) => item.customerName));
          }
          setAlertMessage("Successfully approved the workflow(s) for ");
          setShowAlert(true);
          props.refreshList()
          props.updateSelectedWorkflow &&
            props.updateSelectedWorkflow(undefined);
          await fetchNotificationCount();
          setIsLoading(false);
          setState({ ...state, [anchor]: false });
          props.handleClose();
          return;
          // }
        }
      }
    } catch (error) {
      setState({ ...state, [anchor]: false });
      props.handleClose();
      setAlertMessage("Unexpected Error Occured during approve Workflow");
      setShowAlert(true);
    }
  };

  const handleCancel = async (anchor: Anchor, approvalStatus?: boolean) => {
    // navigate("/filing-master-list");

    try {
      if (props.drafts) {
        setIsLoading(true);
        const bulkPayload = props.drafts.map((draft) => {
          return {
            notes: approvalComment,
            role: authUser?.roleName,
            userid: authUser?.userId + "",
            workFlowId: draft?.workflowId,
            draftId: draft?.draftId,
          };
        });

        if (rejectBulkPostAwardClientFilingMaster) {
          const res = await rejectBulkPostAwardClientFilingMaster(bulkPayload);
          console.log(`Workflow rejected result:`, res);
          // if (res) {
          //setAlertMessage("Workflow has been rejected");
          if (props.drafts && Array.isArray(props.drafts)) {
            setClientNames(props.drafts.map((item) => item.customerName));
          }
          setAlertMessage("Workflow(s) has been rejected for");
          setShowAlert(true);
          props.refreshList()
          props.updateSelectedWorkflow &&
            props.updateSelectedWorkflow(undefined);
          await fetchNotificationCount();
          setIsLoading(false);
          setState({ ...state, [anchor]: false });
          props.handleClose();
          return;
          // }
        }
      }
    } catch (error) {
      setState({ ...state, [anchor]: false });
      props.handleClose();
      if (props.drafts && Array.isArray(props.drafts)) {
        setClientNames(props.drafts.map((item) => item.customerName));
      }
      setAlertMessage("Unexpected Error Occured during reject Workflow for");
      setShowAlert(true);
    }
  };
  useEffect(() => {
    if (authUser && authUser?.userId) fetchNotificationCount();
  }, [authUser]);

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <MuiButton onClick={toggleDrawer(anchor, true)}>{anchor}</MuiButton> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
        {clientNames.length > 0 && (
          <ul>
           
            {Array.from(new Set(clientNames)).map((name, index) => (
              <li key={index}><strong>{name}</strong></li>
            ))}
          </ul>
        )}
      </Dialog>
      <style>{`
    .p-dialog .p-dialog-content {
      padding-bottom: 1px;
    }
    .p-dialog .p-dialog-header {
      padding-bottom: 0px;
    }
  `}</style>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
          {isLoading && (
          <Loader />
          )}
        </React.Fragment>
      )}
    </div>
  );
}
