import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import AuthState from "./context/auth/AuthState";
import CustomerState from "./context/customer/CustomerState";
import FilingMasterState from "./context/filing-master/FilingMasterState";
import ClientFilingMasterState from "./context/client-filing-master/ClientFilingMasterState";
import "./index.css";
import App from "./App";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import reportWebVitals from "./reportWebVitals";
import Authentication from "./Authentication";

// MSAL imports
// import {
//   PublicClientApplication,
//   EventType,
//   EventMessage,
//   AuthenticationResult,
// } from "@azure/msal-browser";
// import { msalConfig } from "./authConfig";

// export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
// const accounts = msalInstance.getAllAccounts();
// if (accounts.length > 0) {
//   msalInstance.setActiveAccount(accounts[0]);
// }

// msalInstance.addEventCallback((event: EventMessage) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
//     const payload = event.payload as AuthenticationResult;
//     const account = payload.account;
//     msalInstance.setActiveAccount(account);
//   }
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React>
  <AuthState>
    <Authentication>
      <ClientFilingMasterState>
        <FilingMasterState>
          <CustomerState>
            {/* <MsalProvider pca={msalInstance}> */}
            <Router>
              <App />
            </Router>
            {/* </MsalProvider> */}
          </CustomerState>
        </FilingMasterState>
      </ClientFilingMasterState>
    </Authentication>
  </AuthState>
  // </React>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
