import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Grid,
  Stack,
  FormControl,
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "@mui/material/Input";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
// import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import { DateTime } from "luxon";
import FileDownload from "js-file-download";
import Alert from "@mui/material/Alert";
import AuthContext from "../../context/auth/AuthContext";
import CustomerContext from "../../context/customer/CustomerContext";
import CircularProgress from "@mui/material/CircularProgress";
import useDialog from "../common/UseDialog";
import { Typography } from "@mui/material";
import usePermission from "../../usePermission";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function ClientFilingTrackerAttachment(props) {
  const [selectedFile, setSelectedFile] = useState("");
  const authContext = useContext(AuthContext);
  const { authUser, userRoles, toLocalDateTime } = authContext;
  const [status, setStatus] = useState("");
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const { getClientFilingTrackerAttachments } = clientFilingMasterContext;
  const customerContext = useContext(CustomerContext);
  const { selectedCustomer } = customerContext;
  const [selectAttachment, setSelectAttachment] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [data, setData] = React.useState({});
  const [companyList, setCompanyList] = useState([]);
  const [selectedChildCompany, setSelectedChildCompany] = useState([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showStatusAlert, setShowStatusAlert] = useState({
    type: null,
    msg: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateFileUploadPath = async (attachmentId, filePath) => {
    let payload = {
      attachmentId: attachmentId,
      fileTrackingId: props.tracker.fileTrackingId,
      attachmentPath: decodeURI(filePath),
      createDate: new Date(),
      createUser: authUser.userId + "",
    };
    const res = await axios.put(
      `${API_BASE_URL}/Customer/UpdateCustomerFilingTrackingAttachments/${attachmentId}`,
      payload
    );
    if (res) {
    }
  };

  const uploadAttachmentFile = async (attachmentId) => {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append("Files", selectedFile);
      formData.append(
        "fileTrackingId",
        props.tracker.fileTrackingId ? props.tracker.fileTrackingId + "" : "0"
      );
      formData.append(
        "customerId",
        props.tracker.customerId ? props.tracker.customerId + "" : "0"
      );
      formData.append("attachmentId", attachmentId);
      const res = await axios.post(
        `${API_BASE_URL}/Application/CustomerFilingUpload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data) {
        await updateFileUploadPath(attachmentId, res.headers["x-file-url"]);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const generateAttachmentEntry = () => {
    return new Promise(async (resolve, reject) => {
      let payload = {
        fileTrackingId: props.tracker.fileTrackingId,
      };
      try {
        const res = await axios.post(
          `${API_BASE_URL}/Customer/CreateCustomerFilingTrackingAttachments`,
          payload
        );

        if (res && res.data && res.data.status === "Success") {
          resolve(res.data.data.attachmentId);
        } else {
          resolve(null);
        }
      } catch (err) {
        resolve(null);
      }
    });
  };

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (selectedFile) {
      setShowSuccess(false);
      setShowError(false);
    }
  };

  const fetchClientFilingTrackerAttachments = async (fileTrackingId) => {
    setLoading(true);
    try {
      let url = `${API_BASE_URL}/Customer/ViewParentChildCustomerFilingTrackingAttachments?fileTrackingIds=${fileTrackingId}`
      let childRecords = await getChildRecords()
      // console.log("fetchClientFilingTrackerAttachments childRecords", childRecords)
      if (selectedCustomer && selectedCustomer.bothParentAndChild && childRecords.length > 0) {
        childRecords.forEach((ele) => {
          url = `${url}&fileTrackingIds=${ele.fileTrackingId}`
        })
      }
      const res = await axios.get(url)
      // console.log("fetchClientFilingTrackerAttachments res", res.data.data)
      let formataAttachment = {}
      res.data.data.forEach((ele) => (
        formataAttachment.hasOwnProperty(ele.customerId)
          ? formataAttachment[ele.customerId].push(ele)
          : formataAttachment[ele.customerId] = [ele]
      ));
      // console.log("fetchClientFilingTrackerAttachments formataAttachment", formataAttachment)

      setData(() => formataAttachment);
      // console.log("fetchClientFilingTrackerAttachments data", data)

    } catch (error) {
      console.log("@@Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onFileUpload = async (anchor) => {
    // generate attachmentId
    try {
      const attachmentId = await generateAttachmentEntry();
      //Upload the selected file
      await uploadAttachmentFile(attachmentId);
      //update filePath to DB
      // updateFileUploadPath(attachmentId, filePath);
      //refresh attachment List
      fetchClientFilingTrackerAttachments(props.tracker.fileTrackingId);

      setState({ ...state, [anchor]: true });
      setAlertMessage("Attachment has been added");
      setShowError(false);
      setShowSuccess(true);
      return;
    } finally {
      // if (data && data.length === 0) {
      //   props.handleRefreshTable();
      // }
      const form = document.getElementById("uploadForm");
      form.reset();
    }
  };

  const getCustomerList = async () => {
    try {
      let res = await axios.get(`${API_BASE_URL}/Customer/CustomerList`);
      let _data;
      _data = res.data.map((ele) => ({
        code: ele.customerId,
        name: ele.customerName,
      }));
      setCustomerList(_data);
    } catch (error) {
      console.log(error);
    }
  };

  const getClientName = (clientId) => {
    // console.log("getClientName", clientId, customerList);
    let findName = customerList.filter((ele) => ele.code === parseInt(clientId));
    // console.log("getClientName", findName);
    return findName.length > 0 ? findName[0].name : "";
  };

  const displaySelectedCompany = (selected) => {
    // console.log("displaySelectedCompany props", selected)

    let companyNames = selected.map((ele) => {
      let company = companyList.find((item) => item.code === ele)
      return company.name
    })
    // console.log("displaySelectedCompany props", companyNames)
    return companyNames.join(", ")
  }

  const getChildRecords = async () => {
    // console.log("getChildRecords props", props.tracker);
    let payload = [{
      customerId: props.tracker.customerId,
      filingId: props.tracker.filingId,
      startDate: props.tracker.filingPeriods.startdate,
    }]
    // console.log("getChildRecords payload", payload,);
    const res = await axios.post(
      `${API_BASE_URL}/Customer/CustomerandFilingListBasedOnParentdetails`,
      payload
    )
    // console.log("getChildRecords res", res.data.data);

    if (res.data.data) {
      const childRecords = res.data.data.filter((ele) => ele.customerId !== props.tracker.customerId)
      let childList = res.data.data.map((ele) => ({ name: ele.customerName, code: ele.customerId }))
      // console.log("getChildRecords childRecords", childList);
      setCompanyList(() => childList)
      return childRecords
    }
  }

  const uploadandUpdateAttachment = async (anchor) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("Files", selectedFile);
      formData.append(
        "fileTrackingId",
        props.tracker.fileTrackingId ? props.tracker.fileTrackingId + "" : "0"
      );
      formData.append("createUser", authUser?.userId + "");

      let url = `${API_BASE_URL}/Application/UpdateChildCustomerFilingTrackingAttachments`;
      if (selectedCustomer && !selectedCustomer.bothParentAndChild) {
        url = `${url}?CustomerIds=${selectedCustomer.customerId}`
      } else if (selectedChildCompany.length > 0) {
        selectedChildCompany.forEach((ele, i) => {
          url = `${url}${i === 0 ? "?" : "&"}CustomerIds=${ele}`
        })
      }

      const res = await axios.post(url, formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data) {
        // if(data && !data.hasOwnProperty([selectedCustomer?.customerId])) {
          props.handleRefreshTable();
        // }
        fetchClientFilingTrackerAttachments(props.tracker.fileTrackingId);
        setState({ ...state, [anchor]: true });
        setSelectedChildCompany([])
        setAlertMessage("Attachment has been added");
        setShowError(false);
        setShowSuccess(true);
      }
      const form = document.getElementById("uploadForm");
      form.reset();
    } finally {
      setIsLoading(false);
    }
  };

  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
    // navigate("/client-filing-master-approval-list");
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        // if (
        //   event.type === "keydown" &&
        //   ((event as React.KeyboardEvent).key === "Tab" ||
        //     (event as React.KeyboardEvent).key === "Shift")
        // ) {
        //   return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };
  const toLocalTime = (date: Date): string => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");

      return _date;
    } else return "";
  };
  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {

      // toggleDrawer(props.dockAt, props.show);
      if (props.show) {
        setSelectedChildCompany([])
        setState({ ...state, [props.dockAt]: props.show });
        setShowStatusAlert({ type: null, msg: "" });
        getCustomerList();
        getChildRecords()
      }
    }

    // if (
    //   getClientFilingMasterWorkflowAttachments &&
    //   props.workflow &&
    //   props.workflow.workflowId
    // ) {
    //   console.log(`Fetching Commnet for :${props.workflow.workflowId}`);
    //   //   fetchFilingMasterAttachments(props.workflow.workflowId);
    // }
    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    console.log("data", data)
  }, [data]);

  useEffect(() => {
    if (props.attachments && props.tracker) {
      // setData(() => props.attachments);
      fetchClientFilingTrackerAttachments(props.tracker.fileTrackingId)
    }

    //eslint-disable-next-line
  }, [props.attachments]);

  useEffect(() => {
    if (props.tracker) {
      setStatus(props.tracker.status);
    }
  }, [props.tracker]);

  const getFilename = function (str) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };

  const downloadFile = (attachmentPath, attachmentId) => {
    const fileName = getFilename(attachmentPath);
    let fileTrackingId = props.tracker.fileTrackingId
      ? props.tracker.fileTrackingId + ""
      : "0";
    let customerId = props.tracker.customerId
      ? props.tracker.customerId + ""
      : "0";
    axios({
      url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&fileTrackingId=${fileTrackingId}&CustomerId=${customerId}`,
      method: "POST",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  };

  const saveStatus = async () => {
    let payload = {
      fileTrackingId: props.tracker.fileTrackingId,
      customerId: props.tracker.customerId,
      filingId: props.tracker.filingId,
      dueDate: props.tracker.dueDate
        ? new Date(props.tracker.dueDate)
        : props.tracker.dueDate,
      status: status,
      createDate: props.tracker.createDate,
      createUser: props.tracker.createUser,
      updateDate: new Date(),
      updateUser: authUser.userId + "",
      startdate: props.tracker.startdate
        ? new Date(props.tracker.startdate)
        : props.tracker.startdate,
      enddate: props.tracker.enddate
        ? new Date(props.tracker.enddate)
        : props.tracker.enddate,
    };
    const res = await axios.put(
      `${API_BASE_URL}/Customer/CustomerFileTracking/${props.tracker.fileTrackingId}`,
      payload
    );
    if (res.data.status === "Success") {
      setShowStatusAlert({ type: "success", msg: "Filing status saved" });
      props.refreshAttachments();
    } else {
      setShowStatusAlert({
        type: "error",
        msg: "Error while saving Filing status",
      });
    }
  };

  const getUserName = (userId) => {
    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]
        ?.userName
      : "";
    return user;
  };

  const deleteFileAttachment = async (item) => {
    await axios
      .delete(
        `${API_BASE_URL}/Customer/DeleteCustomerFilingTrackingAttachments/${item.attachmentId}`
      )
      .then((res) => {
        if (res && res.data && res.data.status === "Success") {
          setAlertMessage("Successfully deleted attachment");
          setShowSuccess(true);
          fetchClientFilingTrackerAttachments(props.tracker.fileTrackingId);
        }
      });
  };

  const deleteAttachmentBlob = async () => {
    closeDialog();

    setState({
      ...state,
      [props.dockAt ? props.dockAt : "right"]: true,
    });
    let customerId = props.tracker.customerId
      ? props.tracker.customerId + ""
      : "0";

    let fileTrackingId = props.tracker.fileTrackingId
      ? props.tracker.fileTrackingId + ""
      : "0";

    await axios
      .post(
        `${API_BASE_URL}/Application/DeleteAttachment?CustomerId=${customerId}&AttachmentId=${selectAttachment.attachmentId}&fileTrackingId=${fileTrackingId}`
      )
      .then(async (res) => {
        if (res) {
          // console.log("deleteAttachmentBlob selectAttachment",selectAttachment)
          // if(data && data.hasOwnProperty([selectedCustomer.customerId]) 
          //   && data[selectedCustomer.customerId].length === 1){
            props.handleRefreshTable()
          // }
          setAlertMessage("Successfully deleted attachment");
          setShowSuccess(true);
          fetchClientFilingTrackerAttachments(props.tracker.fileTrackingId);
        }
      });
  };

  const handleDelete = async (item) => {
    setSelectAttachment(item);
    setState({ ...state, [props.dockAt ? props.dockAt : "right"]: false });
    showDialog(
      "Confirmation",
      "Are you sure to delete the attachment?",
      "confirm"
    );
  };

  const closeConfirm = () => {
    setSelectAttachment(null);
  };

  const IsDisabled = () => {
    return !usePermission(
      "ClientFilingList",
      "addFinalAttachment",
      props.selectedClientId,
    )
  }

  // console.log("selectedCustomer",selectedCustomer)

  const IsDeleteDisabled = () => {
    return !usePermission(
      "ClientFilingList",
      "deleteFinalAttachment",
      props.selectedClientId,
    )
  }



  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: deleteAttachmentBlob,
    onClose: closeConfirm,
  });

  const AttachmentListItem = ({ item }) => {

    return (
      <div>
        <ListItem disablePadding>
          <ListItemIcon>
            <Link>
              <AttachFileIcon />
            </Link>
          </ListItemIcon>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ListItemButton
              onClick={() =>
                downloadFile(item.attachmentPath, item.attachmentId)
              }
            >
              <ListItemText
                primary={
                  item &&
                  item.attachmentPath &&
                  getFilename(item.attachmentPath)
                }
              />
            </ListItemButton>
          </div>
          <IconButton disabled={IsDeleteDisabled()}>
            <DeleteIcon onClick={() => handleDelete(item)} />
          </IconButton>
        </ListItem>
      </div>
    );
  };

  const list = (anchor: Anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div
                  style={{
                    width: "30vw",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem",
                  }}
                >
                  ATTACHMENTS
                  <IconButton>
                    <CloseIcon onClick={() => handleCancel(anchor)} />
                  </IconButton>
                </div>
                <div>
                  <Box sx={{ mx: 3 }}>
                    <form
                      method="post"
                      id="uploadForm"
                      onChange={(event) => onFileChange(event)}
                      style={{ margin: "1rem" }}
                    >
                      <label className="file-upload">
                        <Input
                          type="file"
                          name="file"
                          id="inFile"
                          variant="contained"
                          color="primary"
                          disabled={IsDisabled()}

                        />
                      </label>
                    </form>
                    {selectedCustomer && selectedCustomer.bothParentAndChild && (
                      <FormControl sx={{ minWidth: 350, maxWidth: 350, margin: "1rem" }} size="large">
                        <InputLabel id="demo-multiple-checkbox-label">Affected Companies</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedChildCompany}
                          onChange={(e) => setSelectedChildCompany(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => displaySelectedCompany(selected)}
                        >
                          {companyList.map(({ name, code }) => (
                            <MenuItem key={name} value={code}>
                              <Checkbox checked={selectedChildCompany.indexOf(code) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {/* <TextField
                      id="notes"
                      name="notes"
                      label={
                        <span style={{ font: "italic normal normal" }}>
                          Add Attachments
                        </span>
                      }
                      multiline
                      fullWidth
                      minRows={2}
                      variant="filled"
                      placeholder={"Enter Attachments..."}
                      value={approvalAttachment}
                      onChange={onAttachmentChange}
                    /> */}
                  </Box>
                </div>
                <div>
                  <CardActions
                    sx={{
                      // mt: "3rem",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      // sx={{ margin: 1, padding: 1 }}
                      disabled={IsDisabled()}
                      onClick={() => handleCancel(anchor)}
                    >
                      Cancel
                    </MuiButton>
                    <MuiButton
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#0b3edf",
                      }}
                      // sx={{ margin: 1, padding: 1 }}
                      disabled={
                        IsDisabled() 
                        || !(selectedCustomer && selectedCustomer.bothParentAndChild ? selectedChildCompany.length > 0 : true)
                      }
                      onClick={() => handleOk(anchor)}
                    >
                      Attach File
                    </MuiButton>
                    {/* <Button
              variant='contained'
              color='primary'
              sx={{
                margin: 1,
                padding: 1,
              }}
              style={{ display: "flex", justifyContent: "flex-end" }}
              onClick={generateAttachmentEntry}
            >
              Attach File
            </Button> */}
                  </CardActions>
                </div>
              </Stack>
              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? <CircularProgress size={40} /> : null}
                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <Divider />
              <List>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100px",
                    }}
                  >
                    <CircularProgress size={40} />
                  </div>
                ) : data && Object.keys(data).length === 0 ? (
                  <Typography variant="body1" color="textSecondary">
                    No Attachments
                  </Typography>
                ) : (
                  data &&
                  Object.keys(data).map((client) => (
                    <Fragment>
                      <p style={{ fontWeight: "bold", fontSize: "16px" }}>{getClientName(client)}</p>
                      {data[client].map((item, index) => (
                        <Fragment key={index}>
                          <Stack direction="row" justifyContent="space-between">
                            <ListItem
                              disablePadding
                              style={{ fontWeight: 600, fontSize: "16px" }}
                            >
                              {getUserName(item.createUser)}
                            </ListItem>
                            <ListItem sx={{ textAlign: "right" }}>
                              <ListItemText
                                secondary={
                                  item &&
                                  item.createDate &&
                                  toLocalDateTime &&
                                  `${toLocalDateTime(item.createDate)}`
                                }
                              />
                            </ListItem>
                          </Stack>
                          <AttachmentListItem item={item} />
                        </Fragment>
                      ))
                      }
                    </Fragment>
                  ))
                )}
              </List>

              {/* <div
                style={{
                  width: "30vw",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem",
                }}
              >
                STATUS
              </div> */}
              {/* <div
                component='div'
                disablePadding
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Select
                  labelId='status'
                  id='status'
                  name='status'
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem key='Pending' value='Pending'>
                    Pending
                  </MenuItem>
                  <MenuItem key='In progress' value='In progress'>
                    In progress
                  </MenuItem>
                  <MenuItem key='Completed' value='Completed'>
                    Completed
                  </MenuItem>
                </Select>
                <MuiButton variant='contained' onClick={() => saveStatus()}>
                  Save
                </MuiButton>
              </div>
              <div style={{ padding: "1rem 0" }}>
                {showStatusAlert.type !== null && (
                  <Alert severity={showStatusAlert.type}>
                    {showStatusAlert.msg}
                  </Alert>
                )}
              </div> */}
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );

  const handleOk = async (anchor: Anchor) => {
    // navigate("/filing-master-list");
    setShowError(false);
    // onFileUpload();
    if (!selectedFile) {
      setAlertMessage("Please select a file.");
      setShowError(true);
      setShowSuccess(false);
    } else {
      setShowSuccess(false);
      setShowError(false);
      try {
        uploadandUpdateAttachment(anchor);
      } catch (error) {
        console.log(`Error while adding attachment`, error);
        setAlertMessage("`Error while adding attachment");
        setShowError(true);
        props.handleClose();
      }
    }
  };

  const handleCancel = async (anchor: Anchor) => {
    setSelectedFile(() => null);
    setShowSuccess(false);
    setData(() => { });
    setShowError(false);
    setIsLoading(false);
    const form = document.getElementById("uploadForm");
    form.reset();
    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  return (
    <div className="trackerAttachment">
      <ConfirmDialog />
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
        baseZIndex={2300}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
