import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Await, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "@emotion/styled";
import AuthContext from "../../context/auth/AuthContext";
import {
  Container,
  IconButton,
  Stack,
  Button,
  Typography,
  DialogActions,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  CardActions,
  Collapse,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Dialog,
  FormLabel,
  FormControlLabel,
  Tooltip,
  Grid,
} from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { Box } from "@mui/material";
import { AppBar } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { DateTime } from "luxon";
import { CircularProgress } from "@mui/material";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  // transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: "auto",
  fontSize: "15px",
  color: "#0000CC",

  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function Notification() {
  const authContext = useContext(AuthContext);
  const {
    authUser,
    getCustomerNotifications,
    getFilingNotifications,
    getCustomerNotificationsWorkFlow,
    setNotificationCount,
    toLocalDateTime
  } = authContext;
  const [allNotifications, setAllNotifications] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedCustfNotifications, setSelectedCustfNotifications] = useState(
    []
  );
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [expanded, setExpanded] = useState({
    isOpen: false,
    notificationId: null,
    sourceType: null,
    workflowId: null,
    fileTrackingId: null,
  });

  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewDetails, setViewDetails] = useState([]);
  const [customerNotificationCount, setCustomerNotificationCount] =
    React.useState(0);
  const [filingNotificationCount, setFilingNotificationCount] =
    React.useState(0);
  const [customerNotificationsWorkFlow, setCustomerNotificationsWorkFlow] =
    React.useState(0);
  const [totalNotificationCount, setTotalNotificationCount] = React.useState(0);
  const navigate = useNavigate();
  const backToHome = () => {
    navigate("/");
  };
  const [listNotification, setListNotification] = useState([]);

  const fetchNotificationCount = async () => {
    console.log("CustomerNotification", getCustomerNotifications);
    console.log("filingNotifications", getFilingNotifications);

    if (
      getCustomerNotifications &&
      getFilingNotifications &&
      getCustomerNotificationsWorkFlow
    ) {
      console.log("@@Fetch Customers2:");
      try {
        let custList = await getCustomerNotifications();
        custList = custList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        console.log("@@customerNotifications:", custList);
        // setTotalNotificationCount((old) => old + custList.length);

        setCustomerNotificationCount(custList.length);
        let filingList = await getFilingNotifications();
        filingList = filingList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");

        console.log("@@filingNotifications:", filingList);
        // setTotalNotificationCount((old) => old + filingList.length);
        setFilingNotificationCount(filingList.length);
        let custListWorkflow = await getCustomerNotificationsWorkFlow();
        custListWorkflow = custListWorkflow
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId && !item.informationRead
          )
          .filter((item) => item.notificationType === "Notification");
        console.log("@@filingNotifications:", custListWorkflow);

        setCustomerNotificationsWorkFlow(custListWorkflow.length);
        // setTotalNotificationCount(
        //   (old) =>
        //     custList?.length + filingList?.length + custListWorkflow?.length
        // );
        const count =
          custList?.length + filingList?.length + custListWorkflow?.length;
        setNotificationCount(count);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  function handleCloseClick() {
    setListNotification([]);

    setExpanded(false);
  }

  //Delete all
  async function deleteNotifications(notifications) {
    setIsLoading(true);
    let successfulRequests = [];
    let errorRequests = [];
    console.log("notificationIDS", notifications);
    const filteredNotifications = allNotifications.filter((notification) =>
      notifications.includes(notification.notificationId)
    );
    console.log("filteredNotifications", filteredNotifications);
    await Promise.all(
      filteredNotifications.map((notification) => {
        let endpointUrl, successMessage;
        if (notification.sourceType === "CustomerTracking") {
          endpointUrl = `${API_BASE_URL}/Customer/DeleteCustomerFilingTrackingNotification/${notification.notificationId}`;
          successMessage = `Deleted customer filing tracking notification with ID ${notification.notificationId}`;
        } else if (notification.sourceType === "FilingMaster") {
          endpointUrl = `${API_BASE_URL}/FilingMaster/DeleteFilingMasterWorkflowNotifications/${notification.notificationId}`;
          successMessage = `Deleted filing master workflow notification`;
        } else if (notification.sourceType === "CustomerFilingMaster") {
          endpointUrl = `${API_BASE_URL}/Customer/DeleteCustomerFilingWorkflowNotifications/${notification.notificationId}`;
          successMessage = `Deleted Customer filing  workflow notification `;
        }

        return axios
          .delete(endpointUrl)
          .then((response) => {
            console.log(successMessage);
            successfulRequests.push(response);
          })
          .catch((error) => {
            console.error(
              `Failed to delete notification with ID ${notification.notificationId}`
            );
            errorRequests.push(error);
          });
      })
    );

    if (successfulRequests.length > 0) {
      await fetchNotifications();
      await fetchNotificationCount();
      setSelectedCustfNotifications([]);
      setDeleteSuccess(true);
      setIsLoading(false);
    }
  }

  //Confiming Delete All
  const deleteAll = () => {
    if (selectedCustfNotifications.length > 0) {
      console.log("selectedCustfNotifications", selectedCustfNotifications);
      deleteNotifications(selectedCustfNotifications);
    }
  };
  const handleDeleteAllClick = () => {
    if (selectedCustfNotifications.length > 0) {
      // const ids = selectedCustfNotifications.map(
      //   (notification) => notification.notificationId
      // );
      // console.log("**", ids);
      setOpenConfirmationDialog(true);
    } else {
      setDeleteAlert(true);
    }
  };

  //Delete Row
  async function handleDelete(id) {
    try {
      const response1 = await axios.delete(
        `${API_BASE_URL}/Customer/DeleteCustomerFilingTrackingNotification/${id}`
      );
      const response2 = await axios.delete(
        `${API_BASE_URL}/FilingMaster/DeleteFilingMasterWorkflowNotifications/${id}`
      );
      const response3 = await axios.delete(
        `${API_BASE_URL}/Customer/DeleteCustomerFilingWorkflowNotifications/${id}`
      );
      if (response1.ok || response2.ok || response3.ok) {
        await fetchNotifications();
        setDeleteSuccess(true);
        fetchNotificationCount();
      } else {
        console.log("Deletion failed");
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    handleDelete(deleteId);
    setDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteId(null);
    setDeleteDialogOpen(false);
  };

  const fetchNotifications = async () => {
    setIsLoading(true);
    console.log("CustomerNotification", getCustomerNotifications);
    console.log("filingNotifications", getFilingNotifications);

    if (
      getCustomerNotifications &&
      getFilingNotifications &&
      getCustomerNotificationsWorkFlow
    ) {
      console.log("###Fetch Customers2:");
      try {
        const custList = await getCustomerNotifications();
        const updCustomerList = custList

          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId &&
              item.notificationType === "Notification"
          )

          .map((item) => {
            item.sourceType = "CustomerTracking";

            return item;
          });
        console.log("updList", updCustomerList);
        if (updCustomerList) {
        }
        console.log("###customerNotifications:", custList);

        const filingList = await getFilingNotifications();
        const updFilingList = filingList
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId &&
              item.notificationType === "Notification"
          )
          .map((item) => {
            item.sourceType = "FilingMaster";
            return item;
          });
        console.log("###filingNotifications:", updFilingList);

        const custListWorkflow = await getCustomerNotificationsWorkFlow();
        const updCustListWorkflow = custListWorkflow
          .filter(
            (item) =>
              item.notifiedUserId === authUser?.userId &&
              item.notificationType === "Notification"
          )

          .map((item) => {
            item.sourceType = "CustomerFilingMaster";
            return item;
          });

        const allData = [
          ...updCustomerList,
          ...updFilingList,
          ...updCustListWorkflow,
        ].sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
        // .sort((a, b) => {
        //   let da = new Date(a.createDate),
        //     db = new Date(b.createDate);
        //   return da - db;
        // });
        console.log("ALLDATA", allData);
        setAllNotifications(allData);
        const count = allData.filter(
          (item) =>
            item.notifiedUserId === authUser?.userId &&
            item.notificationType === "Notification" &&
            !item.informationRead
        ).length;

        console.log("CountsUpd", count);
      } catch (error) {
        console.log("###Error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  console.log("allNot", allNotifications);
  useEffect(() => {
    fetchNotifications();
  }, []);
  // const handleMsCheckboxChange = (event, id) => {
  //   const checked = event.target.checked;
  //   if (checked) {
  //     // setSelectedNotifications([...selectedNotifications, id]);
  //     if (!selectedCustfNotifications.includes(id)) {
  //       selectedCustfNotifications.push(id);
  //       console.log("SELECTED NOTIFICATION", selectedCustfNotifications);
  //     }
  //   } else {
  //     const index = selectedCustfNotifications.indexOf(id);
  //     if (index !== -1) {
  //       selectedCustfNotifications.splice(index, 1);
  //       console.log("SELECTED NOTIFICATION", selectedCustfNotifications);
  //     }
  //   }
  // };
  const handleMsCheckboxChange = (event, id) => {
    const checked = event.target.checked;
    if (checked) {
      if (!selectedCustfNotifications.includes(id)) {
        setSelectedCustfNotifications([...selectedCustfNotifications, id]);
      }
    } else {
      const updatedNotifications = selectedCustfNotifications.filter(
        (notificationId) => notificationId !== id
      );
      setSelectedCustfNotifications(updatedNotifications);
    }
  };
  console.log("selectedNotif2", selectedCustfNotifications);

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      // Select all checkboxes
      const allNotificationIds = allNotifications.map(
        (notification) => notification.notificationId
      );
      setSelectedCustfNotifications(allNotificationIds);
    } else {
      // Deselect all checkboxes
      setSelectedCustfNotifications([]);
    }
  };

  const viewNotification = async (
    notificationId,
    sourceType,
    workflowId,
    fileTrackingId,
    notifiedUserId,
    notificationFrom,
    notificationTo,
    notificationCC,
    notificationSubject,
    notificationType,
    notificationText,
    informationRead,
    informationDeleted,
    createDate,
    createUser
  ) => {
    try {
      let response1 = null;
      let response2 = null;
      let notificationData = null;
      let notificationDetails = null;
      let updNotifications = null;
      let payloadTracking = {
        fileTrackingId: fileTrackingId,
        notifiedUserId: notifiedUserId,
        notificationFrom: notificationFrom,
        notificationTo: notificationTo,
        notificationCC: notificationCC,
        notificationSubject: notificationSubject,
        notificationType: notificationType,
        notificationText: notificationText,
        informationRead: true,
        informationDeleted: informationDeleted,
        createDate: createDate,
        createUser: createUser,
        notificationId: notificationId,
      };
      let payload = {
        workflowId: workflowId,
        notifiedUserId: notifiedUserId,
        notificationFrom: notificationFrom,
        notificationTo: notificationTo,
        notificationCC: notificationCC,
        notificationSubject: notificationSubject,
        notificationType: notificationType,
        notificationText: notificationText,
        informationRead: true,
        informationDeleted: informationDeleted,
        createDate: createDate,
        createUser: createUser,
        notificationId: notificationId,
      };

      if (sourceType === "CustomerTracking") {
        if (!informationRead) {
          const response3 = await axios.put(
            `${API_BASE_URL}/Customer/UpdateCustomerFilingTrackingNotification/${notificationId}`,
            payloadTracking
          );
          updNotifications = response3;
          console.log("updNotification", updNotifications);
        }
        response1 = await axios.get(
          `${API_BASE_URL}/Customer/ViewCustomerFilingTrackingNotification/${notificationId}`
        );
        console.log("res1", response1);

        if (response1) {
          notificationData = [response1.data.data];
          response2 = await axios.get(
            `${API_BASE_URL}/Customer/CustomerFileTracking/${fileTrackingId}`
          );
          console.log("res2", response2);
          if (response2) {
            notificationDetails = response2.data.data;
            console.log("notificationDetails", notificationDetails);
          }
        }
      } else if (sourceType === "FilingMaster") {
        if (!informationRead) {
          const response3 = await axios.put(
            `${API_BASE_URL}/FilingMaster/UpdateFilingMasterWorkflowNotifications/${notificationId}`,
            payload
          );

          updNotifications = response3;
          console.log("updNotification", updNotifications);
        }
        response1 = await axios.get(
          `${API_BASE_URL}/FilingMaster/ViewFilingMasterWorkflowNotifications/${notificationId}`
        );
        notificationData = [response1.data.data];

        if (response1) {
          response2 = await axios.get(
            `${API_BASE_URL}/FilingMaster/FilingMasterWorkflowList/${workflowId}`
          );
          notificationDetails = response2.data;
        }
      } else if (sourceType === "CustomerFilingMaster") {
        if (!informationRead) {
          const response3 = await axios.put(
            `${API_BASE_URL}/Customer/UpdateCustomerFilingWorkflowNotifications/${notificationId}`,
            payload
          );
          updNotifications = response3;
          console.log("updNotifications", updNotifications);
        }
        response1 = await axios.get(
          `${API_BASE_URL}/Customer/ViewCustomerFilingWorkflowNotifications/${notificationId}`
        );
        notificationData = response1.data.data;

        if (response1) {
          response2 = await axios.get(
            `${API_BASE_URL}/Customer/CustomerFilingMasterWorkflowbyid/${workflowId}`
          );
          notificationDetails = response2.data;
        }
      }

      if (notificationData && notificationDetails) {
        // const combainedData = [...notificationData, ...notificationDetails];
        if (updNotifications) {
          await fetchNotificationCount();
        }
        setListNotification(notificationData);
        setViewDetails(notificationDetails);
        console.log("ViewDetails", notificationDetails);

        // console.log("ListNotification", combainedData);

        setExpanded({
          isOpen: true,
          notificationId,
          sourceType,
          workflowId,
          fileTrackingId,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeAlert = () => {
    setShowAlert(() => false);
  };
  const handleDeleteSuccess = () => {
    setDeleteSuccess(() => false);
  };
  const handleDeleteAlert = () => {
    setDeleteAlert(() => false);
  };
  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");

      return _date;
    } else return "";
  };
  const toDueDateLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy");

      return _date;
    } else return "";
  };
  useEffect(() => {
    if (authUser && authUser?.userId) fetchNotificationCount();
  }, [authUser]);
  return (
    <div style={{ margin: "1rem", padding: "0", width: "100%" }}>
      <Dialog open={deleteSuccess} onClose={() => setDeleteSuccess(false)}>
        <DialogContent>
          <DialogTitle>Notifications deleted successfully!</DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSuccess}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteAlert} onClose={() => setDeleteAlert(false)}>
        <DialogContent>
          <DialogTitle>Please Select the Notifications </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteAlert}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this notification?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle>Delete Notifications</DialogTitle>
        <DialogContent>
          Are you sure you want to delete these notifications?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteAll();
              setOpenConfirmationDialog(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <Stack direction="column" spacing={2}>
          <AppBar
            position="relative"
            color="inherit"
            sx={{ borderRadius: "8px" }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <IconButton
                className="nav-bar__logo__img"
                sx={{ display: { xs: "none", md: "flex" } }}
                onClick={backToHome}
              >
                <Box sx={{ m: 2 }}>
                  <ArrowBack fontSize="medium" sx={{ mt: 1 }} />
                </Box>
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="inherit" sx={{ mr: 1 }}>
                  NOTIFICATIONS
                </Typography>
              </Box>
            </Stack>
          </AppBar>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <AppBar
              position="relative"
              color="inherit"
              sx={{ borderRadius: "8px" }}
            >
              <div
                style={{
                  display: "grid",
                  marginTop: "30px",
                }}
              >
                {allNotifications.length === 0 ? (
                  <Typography>No new notifications...</Typography>
                ) : (
                  <Container>
                    <Stack direction="row" justifyContent="space-between">
                      <div>
                        <FormControlLabel
                          sx={{
                            gridRow: "1",
                            justifyContent: "end",
                            textAlign: "right",
                          }}
                          label="Select all"
                          control={
                            <Checkbox
                              onChange={handleSelectAllChange}
                              checked={
                                selectedCustfNotifications.length ===
                                allNotifications.length
                              }
                            />
                          }
                        ></FormControlLabel>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#0b3edf",
                            alignItems: "end",
                          }}
                          onClick={handleDeleteAllClick}
                        >
                          DELETE
                        </Button>
                      </div>
                    </Stack>
                  </Container>
                )}

                <div>
                  {allNotifications.map((notification) => (
                    <div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "9% 82%",
                          marginTop: "30px",
                          gridGap: "10px",
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            gridColumn: "1",
                            justifyContent: "end",
                            textAlign: "right",
                          }}
                          control={
                            <Checkbox
                              onChange={(event) =>
                                handleMsCheckboxChange(
                                  event,
                                  notification.notificationId
                                )
                              }
                              checked={selectedCustfNotifications.includes(
                                notification.notificationId
                              )}
                            />
                          }
                        ></FormControlLabel>
                        <Card
                          sx={{
                            flexGrow: 1,
                            backgroundColor: "#F1F2F6",
                            gridColumn: "2",
                          }}
                        >
                          <Stack direction="column">
                            <CardContent>
                              {notification.customerName ? (
                                <Grid container>
                                  {/* <Typography
                                    gutterBottom
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      color: "#424242",
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {notification.notificationSubject}
                                  </Typography> */}
                                  <Grid
                                    style={{
                                      textAlign: "left",
                                    }}
                                    xs={3}
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="subtitle1"
                                      component="div"
                                      sx={{
                                        color: "#424242",
                                        fontSize: "15px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {notification.notificationSubject.length >
                                      21 ? (
                                        <Tooltip
                                          title={
                                            notification.notificationSubject
                                          }
                                        >
                                          <span>
                                            {notification.notificationSubject.slice(
                                              0,
                                              21
                                            )}
                                            ...
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        notification.notificationSubject
                                      )}{" "}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    style={{
                                      textAlign: "center",
                                    }}
                                    xs={4}
                                  >
                                    <Stack direction="column">
                                      <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                          color: "#424242",
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Filing Name
                                      </Typography>
                                      <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                          color: "#424242",
                                          fontSize: "15px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {notification.filingName.length > 36 ? (
                                          <Tooltip
                                            title={notification.filingName}
                                          >
                                            <span>
                                              {notification.filingName.slice(
                                                0,
                                                36
                                              )}
                                              ...
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          notification.filingName
                                        )}
                                      </Typography>
                                    </Stack>
                                  </Grid>

                                  <Grid
                                    style={{
                                      textAlign: "center",
                                    }}
                                    xs={3}
                                  >
                                    <Stack direction="column">
                                      <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                          color: "#424242",
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Client Name
                                      </Typography>
                                      <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                          color: "#424242",
                                          fontSize: "15px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {notification.customerName.length >
                                        25 ? (
                                          <Tooltip
                                            title={notification.customerName}
                                          >
                                            <span>
                                              {notification.customerName.slice(
                                                0,
                                                25
                                              )}
                                              ...
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          notification.customerName
                                        )}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                  <Grid
                                    style={{
                                      textAlign: "right",
                                    }}
                                    xs={2}
                                  >
                                    <Typography
                                      style={{
                                        color: "grey",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {toLocalDateTime && toLocalDateTime(notification.createDate)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid container>
                                  <Grid
                                    style={{
                                      textAlign: "left",
                                    }}
                                    xs={4}
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="subtitle1"
                                      component="div"
                                      sx={{
                                        color: "#424242",
                                        fontSize: "15px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {notification.notificationSubject.length >
                                      20 ? (
                                        <Tooltip
                                          title={
                                            notification.notificationSubject
                                          }
                                        >
                                          <span>
                                            {notification.notificationSubject.slice(
                                              0,
                                              20
                                            )}
                                            ...
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        notification.notificationSubject
                                      )}{" "}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    style={{
                                      textAlign: "center",
                                    }}
                                    xs={5}
                                  >
                                    <Stack direction="column">
                                      <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                          color: "#424242",
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Filing Name
                                      </Typography>
                                      <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                          color: "#424242",
                                          fontSize: "15px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {notification &&
                                        notification.filingName &&
                                        notification.filingName.length > 30 ? (
                                          <Tooltip
                                            title={notification.filingName}
                                          >
                                            <span>
                                              {notification.filingName.slice(
                                                0,
                                                30
                                              )}
                                              ...
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          notification &&
                                          notification.filingName
                                        )}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                  <Grid
                                    style={{
                                      textAlign: "right",
                                    }}
                                    xs={3}
                                  >
                                    <Typography
                                      style={{
                                        color: "grey",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {toLocalDateTime && toLocalDateTime(notification.createDate)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </CardContent>
                            <CardActions sx={{ paddingY: "0px" }}>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ flexGrow: 1, mr: 1 }}
                              >
                                <div>
                                  <ExpandMore
                                    expand={
                                      expanded.isOpen &&
                                      expanded.notificationId ===
                                        notification.notificationId &&
                                      expanded.sourceType ===
                                        notification.sourceType &&
                                      expanded.workflowId ===
                                        notification.workflowId
                                    }
                                    onClick={() => {
                                      if (
                                        expanded.isOpen &&
                                        expanded.notificationId ===
                                          notification.notificationId &&
                                        expanded.sourceType ===
                                          notification.sourceType &&
                                        expanded.workflowId ===
                                          notification.workflowId
                                      ) {
                                        setExpanded({
                                          isOpen: false,
                                          notificationId: null,
                                          sourceType: null,
                                          workflowId: null,
                                          fileTrackingId: null,
                                        });
                                      } else {
                                        viewNotification(
                                          notification.notificationId,
                                          notification.sourceType,
                                          notification.workflowId,
                                          notification.fileTrackingId,
                                          notification.notifiedUserId,
                                          notification.notificationFrom,
                                          notification.notificationTo,
                                          notification.notificationCC,
                                          notification.notificationSubject,
                                          notification.notificationType,
                                          notification.notificationText,
                                          notification.informationRead,
                                          notification.informationDeleted,
                                          notification.createDate,
                                          notification.createUser
                                        );
                                      }
                                    }}
                                    aria-label="show more"
                                  >
                                    View Details
                                  </ExpandMore>
                                </div>
                                <div>
                                  <IconButton>
                                    <DeleteIcon
                                      onClick={() =>
                                        handleDeleteClick(
                                          notification.notificationId
                                        )
                                      }
                                    />
                                  </IconButton>
                                  {expanded.isOpen &&
                                  expanded.notificationId ===
                                    notification.notificationId &&
                                  expanded.sourceType ===
                                    notification.sourceType ? (
                                    <IconButton
                                      sx={{
                                        height: "40px",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <CloseIcon onClick={handleCloseClick} />
                                    </IconButton>
                                  ) : null}
                                </div>
                              </Stack>
                            </CardActions>
                          </Stack>

                          <Collapse
                            in={
                              expanded.isOpen &&
                              expanded.notificationId ===
                                notification.notificationId &&
                              expanded.sourceType === notification.sourceType
                            }
                            timeout="auto"
                            unmountOnExit
                          >
                            <CardContent
                              sx={{
                                maxHeight: "25vh",
                                minHeight: 150,
                              }}
                            >
                              <TableContainer>
                                <TableHead>
                                  {notification.sourceType ===
                                  "CustomerTracking" ? (
                                    <>
                                      {" "}
                                      <TableCell
                                        style={{
                                          color: "#9E9E9E",
                                          width: "25vw",
                                        }}
                                      >
                                        Message
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          color: "#9E9E9E",
                                          width: "17vw",
                                        }}
                                      >
                                        Filing Frequency
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          color: "#9E9E9E",
                                          width: "12vw",
                                        }}
                                      >
                                        Jurisdiction
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          color: "#9E9E9E",
                                          width: "13vw",
                                        }}
                                      >
                                        Due date
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          color: "#9E9E9E",
                                          width: "55vw",
                                        }}
                                      >
                                        Notes
                                      </TableCell>
                                    </>
                                  ) : (
                                    <>
                                      <TableCell
                                        style={{
                                          color: "#9E9E9E",
                                          width: "30vw",
                                        }}
                                      >
                                        Message
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          color: "#9E9E9E",
                                          width: "17vw",
                                        }}
                                      >
                                        Filing Frequency
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          color: "#9E9E9E",
                                          width: "15vw",
                                        }}
                                      >
                                        Jurisdiction
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          color: "#9E9E9E",
                                          width: "60vw",
                                        }}
                                      >
                                        Notes
                                      </TableCell>
                                    </>
                                  )}
                                </TableHead>
                                {listNotification.map((list, index) => {
                                  const details = viewDetails.find(
                                    (detail) =>
                                      detail.workflowId === list.workflowId
                                  );
                                  const filingFrequency = details
                                    ? details.filingFrequency
                                    : null;
                                  const jurisdiction = details
                                    ? details.juristiction
                                    : null;
                                  const notes = details ? details.notes : null;
                                  const dueDate =
                                    notification.sourceType ===
                                      "CustomerTracking" && details
                                      ? toDueDateLocalTime(details.dueDate)
                                      : null;

                                  return (
                                    <TableBody key={index}>
                                      {notification.sourceType ===
                                      "CustomerTracking" ? (
                                        <>
                                          <TableCell
                                            style={{
                                              width: "25vw",
                                            }}
                                          >
                                            {list.notificationText.length >
                                            30 ? (
                                              <Tooltip
                                                title={list.notificationText}
                                                arrow
                                              >
                                                <span>
                                                  {list.notificationText.substring(
                                                    0,
                                                    30
                                                  )}
                                                  ...
                                                </span>
                                              </Tooltip>
                                            ) : (
                                              list.notificationText
                                            )}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "17vw",
                                            }}
                                          >
                                            {filingFrequency}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "12vw",
                                            }}
                                          >
                                            {jurisdiction}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "13vw",
                                            }}
                                          >
                                            {dueDate}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "55vw",
                                            }}
                                          >
                                            {notes && notes.length > 75 ? (
                                              <Tooltip title={notes} arrow>
                                                <span>
                                                  {notes.substring(0, 75)}...
                                                </span>
                                              </Tooltip>
                                            ) : (
                                              notes
                                            )}
                                          </TableCell>
                                        </>
                                      ) : (
                                        <>
                                          <TableCell
                                            style={{
                                              width: "30vw",
                                            }}
                                          >
                                            {list.notificationText.length >
                                            45 ? (
                                              <Tooltip
                                                title={list.notificationText}
                                                arrow
                                              >
                                                <span>
                                                  {list.notificationText.substring(
                                                    0,
                                                    45
                                                  )}
                                                  ...
                                                </span>
                                              </Tooltip>
                                            ) : (
                                              list.notificationText
                                            )}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "16vw",
                                            }}
                                          >
                                            {filingFrequency}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "14vw",
                                            }}
                                          >
                                            {jurisdiction}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "70vw",
                                            }}
                                          >
                                            {notes && notes.length > 107 ? (
                                              <Tooltip title={notes} arrow>
                                                <span>
                                                  {notes.substring(0, 107)}...
                                                </span>
                                              </Tooltip>
                                            ) : (
                                              notes
                                            )}
                                          </TableCell>
                                        </>
                                      )}
                                    </TableBody>
                                  );
                                })}
                              </TableContainer>
                            </CardContent>
                          </Collapse>
                        </Card>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </AppBar>
          )}
        </Stack>
      </div>
    </div>
  );
}
