import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DataTableBase from "../common/DataTableBase";
import PageNavbar from "../layout/PageNavbar";
import Toolbox from "../common/Toolbox";
import SlidingDrawer from "../common/SlidingDrawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { TextField, InputAdornment } from "@mui/material";
import {
  Search as SearchIcon,
  ClearRounded as ClearRoundedIcon,
} from "@mui/icons-material";
import _ from "lodash";
// import customers from "../../data/customers.json";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import AuthContext from "../../context/auth/AuthContext";
import CustomerContext from "../../context/customer/CustomerContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import CustomerComments from "./CustomerComments";
import ClientProfileHistory from "./ClientProfileHistory";
import axios from "axios";
import CustomerBusinessCategory from "./CustomerBusinessCategory";
import ReactLoading from "react-loading";
import usePermission from "../../usePermission";
import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
import IconButton from "@mui/material/IconButton";
import states from "../../data/StateLookup.json";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ScrollPanel } from "primereact/scrollpanel";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";

import ToolTip from "../common/ToolTip";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ClientProfiles = () => {
  const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

  const navigate = useNavigate();
  const filingMasterContext = useContext(FilingMasterContext);
  const authContext = useContext(AuthContext);
  const { roleType, authUser } = authContext;
  const customerContext = useContext(CustomerContext);
  const {
    getCustomers,
    customers,
    customerLoaded,
    setCurrentCustomer,
    deleteCustomer,
    getCustomerComments,
    getCustomerBusinessCategories,
  } = customerContext;
  const { getBusinessCategories, getProgramName } = filingMasterContext;
  // const data = customers;
  // const [visibleApps, setVisibleApps] = useState(props.projects);
  const [comments, setComments] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [categoriesLookup, setCategoriesLookup] = React.useState([]);
  const [programList, setProgramList] = React.useState([]);
  const [federalFilterSelectedValues, setFederalFilterSelectedValues] =
    React.useState([]);
  const [programNamesSelectedValues, setProgramNamesSelectedValues] =
    React.useState([]);
  const [filterState, setFilterState] = React.useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const [radioMessage, setRadioMessage] = useState("Which Defered Filing you want to open");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showRadio, setShowRadio] = useState(false);
  const [radioVal, setRadioVal] = React.useState('tracknow');
  const [isLoading, setIsLoading] = useState(true);
  const [viewAll, setViewAll] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [isClearIcon, setIsClearIcon] = useState(false);

  const [lazyLoading, setLazyLoading] = useState(false);
  const [refreshRecordList, setRefreshRecordList] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(null);
  const [recordsInView, setRecordsInView] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterChange, setIsFilterChange] = useState([]);
  const [commentActivityHistory, setCommentActivityHistory] = React.useState([]);
  const [sortingData, setSortingData] = useState({
    sortBy: "",
    sortOrder: "",
  });

  const [data, setData] = useState([]);
  const [jurisdiction, setJurisdiction] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    customerName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    juristiction: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    categories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    programLists: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    address: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    taxNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    phone: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    parentCustomerName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // 'country.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    // representative: { value: null, matchMode: FilterMatchMode.IN },
    // status: { value: null, matchMode: FilterMatchMode.EQUALS },
    // verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    trackNowLicenseName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    postAwardLicenseName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    stateList: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
  });
  const tracknowlicenseTypes = ["Essential", "Plus", "Premium"];
  const postawardlicenseTypes = ["Essential", "Premium", "Platinum"];
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);

  const displayFilter = () => {
    console.log("Toggling Show Filter", showFilter);
    setShowFilter(() => true);
  };

  const displayComments = (rowData) => {
    console.log("Display Comments", selectedClients);
    console.log("rowData", rowData);

    // setShowComments(() => false);
    if (rowData && rowData.customerId) {
      console.log(`Fetching Commnet for :${rowData.customerId}`);
      fetchCustomerComments(rowData.customerId);
      setShowComments(() => true);
      setSelectedClients(rowData);
    } else if (selectedClients && selectedClients.customerId) {
      console.log(`Fetching Commnet for :${selectedClients.customerId}`);
      fetchCustomerComments(selectedClients.customerId);
      setShowComments(() => true);
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };

  const closeComments = () => {
    setShowComments(() => false);
  };
  const handleViewAllClick = () => {
    setViewAll(true);
  };

  const handlePaginationClick = () => {
    setViewAll(false);
  };

  const displayCategories = () => {
    console.log("Display Categories", selectedClients);
    // setShowComments(() => false);
    if (selectedClients && selectedClients.customerId) {
      console.log(`Fetching Categories for :${selectedClients.customerId}`);
      fetchCustomerCategories(selectedClients.customerId);
      fetchBusinessCategories();
      console.log(`Display Categories for :${selectedClients.customerId}`);
      setShowCategories(() => true);
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };

  const closeCategories = () => {
    setShowCategories(() => false);
  };

  const closeFilter = () => {
    console.log("Toggling Show Filter", showFilter);
    setShowFilter(() => false);
  };

  const closeHistory = () => {
    console.log("Toggling Show Filter", showHistory);
    setShowHistory(() => false);
  };

  const editCustomer = () => {
    console.log("Editing Customer", selectedClients);
    if (selectedClients) {
      setCurrentCustomer(selectedClients);
      navigate("/customer");
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };

  const displayHistory = async () => {
    console.log("displayHistory", selectedClients);
    if (selectedClients) {
      console.log(`displayHistory for : ${selectedClients.customerId}`);
      fetchClientTrackingHistory(selectedClients.customerId);
      setShowHistory(() => true);
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };

  const fetchClientTrackingHistory = async (customerId) => {
    console.log("fetchClientTrackingHistory props", customerId);
    try {
      const history = await axios.get(
        `${API_BASE_URL}/Customer/Customeraudit?CustomerId=${customerId}`
      );
      console.log("fetchClientTrackingHistory res", history);
      setActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    }
    try {
      const history = await axios.get(
        `${API_BASE_URL}/Customer/CustomerCommentsAudit?CustomerId=${customerId}`
      );
      setCommentActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const displayClientFiling = (rowData) => {
    console.log("Filing for Customer", selectedClients, rowData);
    if (rowData) {
      setCurrentCustomer(rowData);
      navigate(`client-filing-tracker-list/${rowData.customerId}`);
    } else if (selectedClients) {
      setCurrentCustomer(selectedClients);
      navigate(`client-filing-tracker-list/${selectedClients.customerId}`);
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };

  const displayAddClientFiling = () => {
    console.log("Add Filing for Customer", selectedClients);
    if (selectedClients) {

      if ((selectedClients?.postAwardLicenseId === null || selectedClients?.postAwardLicenseId === "" || Number(selectedClients?.postAwardLicenseId) === 0) && (selectedClients?.trackNowLicenseId && Number(selectedClients?.trackNowLicenseId) > 0)) {
        //If client has only Tracknow subscription
        navigate(`/add-filings/${selectedClients.customerId}`);

      } else if ((selectedClients?.trackNowLicenseId === null || selectedClients?.trackNowLicenseId === "" || Number(selectedClients?.trackNowLicenseId) === 0) && (selectedClients?.postAwardLicenseId && Number(selectedClients?.postAwardLicenseId) > 0)) {
        //If client has only Postaward subscription
        navigate(`/add-filing-postaward/${selectedClients.customerId}`);
      } else if ((selectedClients?.trackNowLicenseId && Number(selectedClients?.trackNowLicenseId) > 0) && (selectedClients?.postAwardLicenseId && Number(selectedClients?.postAwardLicenseId) > 0)) {
        //If client has both Tracknow and  Postaward subscription
        setShowRadio(true);
      } else {
        setAlertMessage("Client doesn't have either tracknow or postaward subscriptions.");
        setShowAlert(true);
      }

      setCurrentCustomer(selectedClients);
      // navigate(`/add-filings/${selectedClients.customerId}`);
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };

  // const onGlobalFilterChange = (e) => {
  //   const value = e.target.value;
  //   let _filters = { ...filters };

  //   _filters["global"].value = value;
  //   console.log("_filters", _filters);
  //   setFilters(_filters);
  //   setGlobalFilterValue(value);
  // };

  const search = useRef(null);

  const applyGlobalFilter = () => {
    console.log("applyGlobalFilter", search.current.value, isClearIcon)
    let _filters = { ...filters };
    if (isClearIcon) {
      search.current.value = "";
      _filters["global"].value = "";
      setIsClearIcon(false);
      onFilterChange({ filters });
    } else {
      _filters["global"].value = search.current.value;
      setIsClearIcon(true);
      onFilterChange({ filters });
    }
    setFilters(_filters);
  };

  const jusrisdictionItemTemplate = (option) => {
    return (
      <div className='flex align-items-center'>
        <span>{option}</span>
      </div>
    );
    //   return (
    //     <div className="flex align-items-center gap-2">
    //         <img alt={option.name} src={`https://primefaces.org/cdn/primereact/images/avatar/${option.image}`} width="32" />
    //         <span>{option.name}</span>
    //     </div>
    // );
  };

  // const jurisdictionRowFilterTemplate = (options) => {
  //   console.log("##Options:", options);
  //   return (
  //     <MultiSelect
  //       value={options.value}
  //       options={jurisdiction}
  //       itemTemplate={jusrisdictionItemTemplate}
  //       onChange={(e) => {
  //         console.log("##EOptions:", e);
  //         options.filterApplyCallback(e.value);
  //       }}
  //       placeholder="Any"
  //       maxSelectedLabels={1}
  //       className="p-column-filter"
  //       style={{ minWidth: "10rem", maxWidth: "14rem" }}
  //     />
  //   );
  // };

  const getStateName = (stateCode) => {
    const state = states.find((s) => s.code === stateCode);
    return state ? state.state : stateCode;
  };

  const deleteCustomerRecord = async () => {
    setIsLoading(true);
    try {
      if (deleteCustomer) {
        try {
          const isParentOfAnotherClient = clients.some(
            (client) =>
              client.parentCustomerName === selectedClients.customerName
          );
          if (isParentOfAnotherClient) {
            setAlertMessage(
              "This client is a parent of another client and cannot be deleted."
            );
            setShowAlert(true);
          } else {
            const res = await deleteCustomer(selectedClients);
            //await axios.delete(
            // `${API_BASE_URL}/Customer/DeleteTrackNowLicense/${selectedClients.trackNowLicenseNameId}`
            //);
            if (res) {
              setSelectedClients(null);
              setRecordsInView(0)
              setIsLoading(true);
              setData([]);
              setClients([])
              setRefreshRecordList(true)
              setAlertMessage(
                <Typography>
                  Successfully deleted Client:{" "}
                  <Typography fontWeight="bold" component="span">
                    {selectedClients.customerName}
                  </Typography>
                </Typography>
              );
              setShowAlert(true);
            } else {
              setAlertMessage(
                `Unexpected Error occured while deleting  Customer`
              );
              setShowAlert(true);
            }
          }
        } catch (error) {
          console.log("@@Error:", error);
        }
      } else {
        setAlertMessage("Please Select a Client Record");
        setShowAlert(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomers = async (changeBC) => {
    console.log("@@Fetch Customers1:", getCustomers);
    if (getCustomers) {
      console.log("@@Fetch Customers2:");
      try {
        const custList = await getCustomers();
        console.log("@@Customers:", custList);
        if (changeBC) {
          let changeClient = custList.filter(
            (ele) => ele.customerId === selectedClients.customerId
          );
          setSelectedClients(changeClient[0]);
        }
        // console.log("@@Unique Jurisdiction:", _.map(custList, "jurisdiction"));
        // console.log(
        //   "@@Unique Jurisdiction:",
        //   _.unique(_.map(custList, "jurisdiction"))
        // );
        // const _jusrisdictions = custList.map((item) =>
        //   item.juristiction ? item.juristiction : ""
        // );
        // const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
        // console.log("@@Unique Jurisdiction:", _uniqJurisdictions);
        // setJurisdiction(_uniqJurisdictions);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const fetchCustomerComments = async (customerId) => {
    console.log("@@Fetch Customer comments:", getCustomerComments);
    if (getCustomerComments) {
      console.log("@@Fetch Customer comments2:");
      try {
        const commentList = await getCustomerComments(customerId);
        setComments(() => commentList);
        console.log("@@Fetch Customer2 comments:", commentList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const fetchCustomerCategories = async (customerId) => {
    console.log("@@Fetch Customer categories:", getCustomerBusinessCategories);
    if (getCustomerBusinessCategories) {
      console.log("@@Fetch Customer categories2:");
      try {
        const catList = await getCustomerBusinessCategories(customerId);
        setCategories(() => catList);
        console.log("@@Fetch Customer2 categories:", catList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const fetchBusinessCategories = async () => {
    console.log("@@Fetch BusinessCategories:", getBusinessCategories);
    if (getBusinessCategories) {
      console.log("@@Fetch BusinessCategories:");
      try {
        let resList = await getBusinessCategories();
        console.log("@@BusinessCategories: resList", resList);

        const catList = resList.sort((a, b) => (a.businessCategoryName ?? '').localeCompare(b.businessCategoryName ?? ''));

        console.log("@@BusinessCategories: catList", catList);
        setCategoriesLookup(() => catList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };
  const fetchProgramName = async () => {
    console.log("@@Fetch fetchProgramName:", fetchProgramName);
    if (fetchProgramName) {
      console.log("@@Fetch fetchProgramName:");
      try {
        let resList = await getProgramName();
        console.log("@@fetchProgramName: resList", resList);

        const programList = resList.sort((a, b) => (a.programName ?? '').localeCompare(b.programName ?? ''));

        console.log("@@fetchProgramName: programList", programList);
        setProgramList(() => programList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };
  // const onRowSelect = (e) => {
  //   console.log("@@RowSelect:", e);
  // };

  // useEffect(() => {
  //   if (customerLoaded) console.log("Show Table Data", customers);

  //   //eslint-disable-next-line
  // }, [customerLoaded]);

  useEffect(() => {
    console.log("@@DATA REFRESHH");
    // fetchCustomers();

    fetchBusinessCategories()
    fetchProgramName();
    let list = states.map((ele) => ele.state);
    setStateList(list);


    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("selectedClients data", selectedClients);
    if (!selectedClients) {
      sessionStorage.removeItem("userIdForComments");
      sessionStorage.removeItem("usernameForComments");
    }
  }, [selectedClients]);

  const CellListDisplayFederal = (rowData, field) => {
    const op = useRef(null);

    let displayFederal = Array.isArray(rowData["categories"])
      ? rowData["categories"].join(", ")
      : rowData["categories"];
    console.log("CellListDisplayFederal", field, rowData);

    const categories = rowData["categories"]
      ? rowData["categories"].split(", ")
      : [];
    console.log("CellListDisplayFederal categories--", categories);

    return (
      <div className='tableBtn' id={`${field}+${rowData.fileTrackingId}`}>
        <Button onClick={(e) => op.current.toggle(e)} text>
          <div
            style={{
              width: "17rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "black",
            }}
          >
            {displayFederal}
          </div>
        </Button>
        <OverlayPanel ref={op} showCloseIcon style={{ padding: "0rem 1rem" }}>
          Federal Business Category
          <ScrollPanel style={{ maxWidth: "25rem", maxHeight: "20rem" }}>
            <ul>
              {categories.map((ele) => (
                <li>{ele}</li>
              ))}
            </ul>
          </ScrollPanel>
        </OverlayPanel>
      </div>
    );
  };
  const CellListDisplayProgram = (rowData, field) => {
    console.log("CellListDisplayProgram", rowData.programLists);
    const op = useRef(null);

    let displayProgram = Array.isArray(rowData["programLists"])
      ? rowData["programLists"].join(", ")
      : rowData["programLists"];
    console.log("CellListDisplayProgram displayProgram  ", displayProgram);

    const programLists = rowData.programLists
      ? rowData.programLists.split(",")
      : [];
    console.log("CellListDisplayProgram programList --", programLists);




    return (
      <div className='tableBtn' id={`${field}+${rowData.postAwardLicenseId}`}>
        <Button onClick={(e) => op.current.toggle(e)} text>
          <div
            style={{
              width: "12rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "black",
            }}
          >
            {displayProgram}
          </div>
        </Button>
        <OverlayPanel ref={op} showCloseIcon style={{ padding: "0rem 1rem" }}>
          Program Name
          <ScrollPanel style={{ maxWidth: "25rem", maxHeight: "20rem" }}>
            <ul>
              {programLists.map((ele) => (
                <li>{ele}</li>
              ))}
            </ul>
          </ScrollPanel>
        </OverlayPanel>
      </div>
    );
  };



  const CellListDisplayState = (rowData, field) => {
    const op = useRef(null);

    let displayStateUpd = rowData.customerState
      .map((state) => getStateName(state.state))
      .join(", ");
    console.log("displayStateUpd", displayStateUpd);
    let displayState = Array.isArray(displayStateUpd)
      ? displayStateUpd.join(", ")
      : displayStateUpd;

    console.log("stateList", field, rowData);
    console.log("displayState", displayState);

    const stateList = rowData["stateList"]
      ? rowData["stateList"].split(", ")
      : [];
    const stateUpd = stateList.map((state) => getStateName(state));
    console.log("stateList stateList--", stateUpd);

    return (
      <div className='tableBtn' id={`${field}+${rowData.fileTrackingId}`}>
        <Button onClick={(e) => op.current.toggle(e)} text>
          <div
            style={{
              width: "12rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "black",
            }}
          >
            {displayState}
          </div>
        </Button>
        <OverlayPanel ref={op} showCloseIcon style={{ padding: "0rem 1rem" }}>
          States
          <ScrollPanel style={{ maxWidth: "25rem", maxHeight: "20rem" }}>
            <ul>
              {stateUpd.map((ele) => (
                <li>{ele}</li>
              ))}
            </ul>
          </ScrollPanel>
        </OverlayPanel>
      </div>
    );
  };

  const cellListDisplayNotes = (rowData) => {
    const notesUrl = rowData.notes;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (notesUrl) {
      const formattedText = notesUrl.replace(
        urlRegex,
        '<a  style="color: #0000FF;" href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
      );

      // return (
      //   <LightTooltip title={rowData.notes}>
      //     <div
      //       style={{
      //         width: "18rem",
      //         margin: "0 1rem",
      //         overflow: "hidden",
      //         textOverflow: "ellipsis",
      //       }}
      //     >
      //       {rowData.notes}
      //     </div>
      //   </LightTooltip>
      // );
      return (
        <LightTooltip
          title={
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: formattedText }} />
            </React.Fragment>
          }
        >
          <div
            style={{
              width: "18rem",
              margin: "0 1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </LightTooltip>
      );
    }
    return notesUrl;
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F1F2F6",
      color: "black",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  const selectRow = (data) => {
    console.log("row data", data);
    sessionStorage.setItem("userIdForComments", data.customerId);
    sessionStorage.setItem("usernameForComments", data.customerName);

    setSelectedClients(data);
  };

  const IsDisabled = (page, action) => {
    return !usePermission(page, action);
  };

  useEffect(() => {
    // if (customers) {
    //   console.log("Orig Customers...", customers);
    //   let updCustomers = customers.map((item) => {
    //     const _federalCat = item.businessCategory.filter((item) => !item.state);
    //     const categories = _.map(_federalCat, "businessCategoryName").join(
    //       ", "
    //     );
    //     const _programCat = item.programinfo;
    //     const programLists = _.map(_programCat, "programName").join(
    //       ","
    //     );
    //     const _stateInfo = item.customerState;

    //     const stateList = _.map(_stateInfo, (state) =>
    //       getStateName(state.state)
    //     ).join(", ");

    //     const clientType = item.parentCustomer ? "Child" : "Parent";
    //     const parentCustomerName =
    //       item.parentCustomer && item.parentCustomer.customerName
    //         ? item.parentCustomer.customerName
    //         : "";
    //     console.log("STATELIST-CONVRT", stateList);
    //     return {
    //       ...item,
    //       stateList,
    //       categories,
    //       programLists,
    //       clientType,
    //       parentCustomerName,
    //     };
    //   });
    //   if (!(roleType.includes("Admin") || roleType.includes("Admin-PostAward"))) {
    //     console.log("ClientAdmin authUser", authUser, updCustomers);
    //     updCustomers = updCustomers.filter((ele) =>
    //       authUser.clients.includes(ele.customerId)
    //     );
    //   }
    //   console.log("Updated Customers...", updCustomers);
    //   // setData(updCustomers);
    //   setClients(updCustomers);
    //   setIsLoading(false);
    // }
    // console.log("CLIENTS", clients);
    if (!lazyLoading) {
      setIsLoading(true);
      setRecordsInView(0)
      setRefreshRecordList(true)
    }
    //eslint-disable-next-line
  }, [customers]);
  const tracknowcellStatusEditorBody = (rowData) => {
    return rowData["trackNowLicenseName"];
  };
  const postawardcellStatusEditorBody = (rowData) => {
    return rowData["postAwardLicenseName"];
  };
  const tracknowlicenseTypeFilter = (options) => {
    console.log("##Options:", options);
    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={options.value}
        options={tracknowlicenseTypes}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          console.log("##EOptions:", e);
          options.filterCallback(e.value, options.index);
        }}
        placeholder='Any'
        maxSelectedLabels={1}
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={options.value[0]}
        options={tracknowlicenseTypes}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          let values = [];

          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder='Any'
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };
  const postawardlicenseTypeFilter = (options) => {
    console.log("##Options:", options);
    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={options.value}
        options={postawardlicenseTypes}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          console.log("##EOptions:", e);
          options.filterCallback(e.value, options.index);
        }}
        placeholder='Any'
        maxSelectedLabels={1}
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={options.value[0]}
        options={postawardlicenseTypes}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          let values = [];

          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder='Any'
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };
  const renderHeader = () => {
    return (
      <Grid container sx={{ my: 1, flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
        <Grid sx={{ margin: 1, flexGrow: 1 }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            CLIENT PROFILE LIST ({totalRecords})
          </Typography>
        </Grid>
        <Grid sx={{ margin: 1, display: "flex" }}>
          {/* <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search Clients by Name/Phone..."
              size={30}
              style={{ fontSize: "13px" }}
            /> */}
          <TextField
            id='standard-search'
            label='Search by Client Name...'
            size='small'
            inputRef={search}
            variant='standard'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={applyGlobalFilter}>
                    {isClearIcon ? <ClearRoundedIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid sx={{ margin: 1, display: "flex" }}>
          <Button
            sx={{ p: 1, mx: 0, backgroundColor: "#0b3edf" }}
            color="primary"
            variant="contained"
            onClick={() => navigate("/filing-master-list")}
            disabled={IsDisabled("MasterFilingList", "viewPage")}
          >
            MASTER FILING LIST
            <ArrowRightAltIcon sx={{ px: 1 }} />
          </Button>
        </Grid>
        <Grid sx={{ margin: 1, display: "flex" }}>
          <Button
            sx={{ p: 1, mx: 0, backgroundColor: "#0b3edf" }}
            color="primary"
            variant="contained"
            onClick={() => navigate("/postaward-filing-master-list")}
            disabled={IsDisabled("PostAwardMasterList", "viewPage")}
          >
            Post Award Master
            <ArrowRightAltIcon sx={{ px: 1 }} />
          </Button>
        </Grid>
      </Grid>
    )
  }

  const federelBusinessFilterElement = (options) => {
    console.log("options99", options);
    console.log("##Options1234:", JSON.parse(JSON.stringify(clients)));

    console.log("CategoryLocup", categoriesLookup);
    let optionCatList = categoriesLookup.map((item) => item.businessCategoryName)

    console.log("optionCatList", optionCatList);

    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (

      <MultiSelect
        value={options.value ? federalFilterSelectedValues : []}
        options={optionCatList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues(e.value);
          options.filterCallback(e.value, options.index);
        }}
        placeholder='Any'
        maxSelectedLabels={1}
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={options.value ? federalFilterSelectedValues : []}

        options={optionCatList}

        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, data);
          setFederalFilterSelectedValues(e.value);
          let values = [];

          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder='Any'
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };
  const programNameFilterElement = (options) => {
    console.log("options99", options);
    console.log("##Options1234:", JSON.parse(JSON.stringify(clients)));

    // let namesList = [];
    // clients
    //   .map((item) => item.programLists)
    //   .filter(Boolean)
    //   .map((item) => {
    //     if (item.includes(",")) {
    //       console.log("spliited_item", item);
    //       item.split(",").map((subItem) => {
    //         namesList.push(subItem);
    //       });
    //     } else {
    //       namesList.push(item);
    //     }
    //   });
    // namesList = namesList
    //   .filter((item, i) => {
    //     return i === namesList.lastIndexOf(item);
    //   })
    //   .sort();
    const optionList = programList.map((item) => item.programName)
    console.log("optionList", optionList);

    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (

      <MultiSelect
        value={options.value ? programNamesSelectedValues : []}
        options={optionList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          console.log("programNameFilterElement e", e);
          setProgramNamesSelectedValues(e.value);
          options.filterCallback(e.value, options.index);
        }}
        placeholder='Any'
        maxSelectedLabels={1}
        className='p-column-filter'
        style={{ minWidth: "12rem", maxWidth: "12em" }}
      />
    ) : (
      <Dropdown
        value={options.value ? programNamesSelectedValues : []}
        options={optionList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, data);
          setProgramNamesSelectedValues(e.value);
          let values = [];

          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder='Any'
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const filterCustomerState = (options, field) => {
    console.log("##Options1234:", JSON.parse(JSON.stringify(clients)));
    console.log("option-Check", options);
    console.log("Client-Check", clients);

    console.log("STATE#-OPTION", options);
    console.log("STATE#-FILTERSTATE", filterState);
    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={options.value ? filterState : []}
        options={stateList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFilterState(e.value);
          options.filterCallback(e.value, options.index);
        }}
        placeholder='Any'
        maxSelectedLabels={1}
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={options.value ? filterState : []}
        options={stateList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, data);
          setFilterState(e.value);
          let values = [];

          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder='Any'
        className='p-column-filter'
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const CustomCommentButton = ({ rowData }) => {
    console.log("RowDataCust", rowData.customerId);
    const isPermissionEnabled = usePermission("ClientProfile", "comments", rowData.customerId);
    return (
      // <ToolTip
      //   title={
      //     rowData.hasComments ? "Comments available" : "Comments unavailable"
      //   }
      // >
      //   <span>
      <IconButton
        color='primary'
        disabled={!rowData.hasComments || !isPermissionEnabled}
        onClick={() => displayComments(rowData)}
      >
        <InsertCommentRoundedIcon />
      </IconButton>
      //   </span>
      // </ToolTip>
    );
  };
  const handleRadioChange = (event) => {
    setRadioVal((event.target).value);
  };
  const header = renderHeader();
  const footerContent = (
    <div>
      <PrimeButton
        label='OK'
        icon='pi pi-check'
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );
  const radioFooterContent = (
    <div>
      <PrimeButton
        label='SUBMIT'
        icon='pi pi-check'
        onClick={() => radioOkButton()}
        autoFocus
      />
    </div>
  );


  const closeAlert = () => {
    setShowAlert(false);
  };
  const radioCloseAlert = () => {
    setShowRadio(false);
  };
  const radioOkButton = () => {
    if (radioVal === "tracknow") {
      navigate(`/add-filings/${selectedClients.customerId}`);
    }
    else {
      navigate(`/add-filing-postaward/${selectedClients.customerId}`);
    }
  }

  const dt = useRef(null);
  const pageSize = 100;

  // lazy load function
  const loadDataLazy = async (event) => {
    console.log("loadDataLazy props", recordsInView, event);

    //Virtual scroll callback

    const vScroll = dt.current.getVirtualScroller()

    let range = vScroll.getRenderedRange();
    let { first, last } = range.viewport;
    console.log("loadDataLazy vScroll", vScroll, range.viewport);

    let firstVal = recordsInView * pageSize;
    // let tableList = value === "2" ? clientFilingHistory : clientFiling
    let tableList = clients
    console.log(
      "loadDataLazy totalRecords",
      firstVal,
      totalRecords,
      lazyLoading,
      tableList.length,
    );

    console.log("loadDataLazy", firstVal < totalRecords, first > tableList.length - 10, !lazyLoading)

    if (firstVal < totalRecords && first > tableList.length - 10 && !lazyLoading) {
      //scroll down
      // setIsLoading(true);
      setLazyLoading(true);
      let lastVal = firstVal + pageSize;
      console.log("loadDataLazy d firstVal", firstVal, lastVal);

      //get next data
      let records = await loadRecordsList(firstVal, lastVal, "down");
      console.log("loadDataLazy d record", records);
      if (records) {
        console.log("loadDataLazy d scrollInView", recordsInView * 100);

        //scroll to next records
        vScroll.scrollInView(recordsInView * 100);
        setRecordsInView((old) => old + 1);
        setLazyLoading(false);
        setIsLoading(false);
      }
    } else if (last < 10 && recordsInView > 1 && !lazyLoading) {
      // scroll up
      let firstVal = (recordsInView - 3) * pageSize;
      let lastVal = firstVal + pageSize;
      if (firstVal >= 0) {
        // setIsLoading(true);
        setLazyLoading(true);
        // console.log("loadDataLazy u firstVal", firstVal, lastVal);

        //get prev data
        let records = await loadRecordsList(firstVal, lastVal, "up");
        // console.log("loadDataLazy u record", records);
        if (records) {
          // console.log("loadDataLazy u scrollInView", (recordsInView - 3) * 100);

          //scroll to prev records
          vScroll.scrollToIndex(15);
          setRecordsInView((old) => old - 1);
          setLazyLoading(false);
          setIsLoading(false);
        }
      }
    }
  };

  const loadRecordsList = async (first, last, direction) => {
    console.log("loadRecordsList props", first, last, direction);
    setIsLoading(true)
    let url = `${API_BASE_URL}/Customer/CustomerListByPagination?StartRow=${first + 1}&EndRow=${last}`

    //console.log("loadRecordsList fliter/sort", isFilterChange, sortingData);
    if (isFilterChange !== undefined && isFilterChange.length > 0) {
      // url filter parameters
      isFilterChange.forEach((ele) => {
        url = `${url}&${ele.name}=${ele.val}${ele.searchType ? `&${ele.searchType}=${ele.searchTypeVal}` : ``
          }`;
      });
    }

    if (
      sortingData &&
      typeof sortingData === "object" &&
      sortingData.sortBy !== "" &&
      sortingData.sortOrder !== ""
    ) {
      // url sort parameters
      let sortParameter = getSortData(sortingData.sortBy);
      console.log("getSortData", sortParameter)
      let order = sortingData.sortOrder === 1 ? "Ascending" : "Descending";

      url = `${url}&${sortParameter.SortByParameter}=${sortParameter.SortByVal}&${sortParameter.SortOrderParameter}=${order}`;
    }

    // console.log("loadRecordsList url", url);
    try {
      //get data
      const res = await axios.get(url);
      console.log("loadRecordsList res", res.data);

      if (res.data) {
        setIsLoading(true);
        let ogList = clients.map((ele) => ele)
        setTotalRecords(res.data.totalItems);
        await modifyList(res.data.items, direction, ogList);

        return res.data.items;
      } else {
        return false;
      }
    } catch (err) {
      // console.log("loadRecordsList err", err);
    }
  };

  //maping records for lazy load
  const modifyList = (list, direction, ogList) => {
    console.log("modifyList", list, direction);
    if (list.length > 0) {
      //list has data

      let updCustomers = list.map((item) => {
        const _federalCat = item.businessCategory.filter((item) => !item.state);
        const categories = _.map(_federalCat, "businessCategoryName").join(
          ", "
        );
        const _programCat = item.programinfo;
        const programLists = _.map(_programCat, "programName").join(
          ","
        );
        const _stateInfo = item.customerState;

        const stateList = _.map(_stateInfo, (state) =>
          getStateName(state.state)
        ).join(", ");

        const clientType = item.parentCustomer ? "Child" : "Parent";
        const parentCustomerName =
          item.parentCustomer && item.parentCustomer.customerName
            ? item.parentCustomer.customerName
            : "";
        console.log("STATELIST-CONVRT", stateList);
        return {
          ...item,
          stateList,
          categories,
          programLists,
          clientType,
          parentCustomerName,
        };
      });
      if (!(roleType.includes("Admin") || roleType.includes("Admin-PostAward"))) {
        console.log("ClientAdmin authUser", authUser, updCustomers);
        updCustomers = updCustomers.filter((ele) =>
          authUser.clients.includes(ele.customerId)
        );
        setTotalRecords(updCustomers.length)
      }

      // let tableList = value === "2" ? clientFilingHistory : clientFiling
      let tableList = clients
      console.log("modifyList updCustomers", updCustomers, tableList);

      let updFilingMastersList;
      if (direction === "down") {
        //scroll down
        if (tableList.length >= 200) {
          //list > 200

          //slice 2 half of list
          let unloadList = ogList.slice(
            ogList.length - pageSize,
            ogList.length
          );
          // console.log("modifyList dw unloadList", unloadList);

          //add new data
          updFilingMastersList = [...unloadList, ...updCustomers];
          // console.log("modifyList dw updFilingMastersList", updFilingMastersList);

          // let list = updFilingMastersList.map((ele)=>ele)
          // setClientFiling(updFilingMastersList);
        } else {
          //list < 200

          // add new data to list
          updFilingMastersList = [...ogList, ...updCustomers];
          // console.log("modifyList dw updFilingMastersList", updFilingMastersList);

          // setClientFiling(updFilingMastersList);
        }
      } else if (direction === "up") {
        //scroll up
        if (tableList.length >= 200) {
          //list > 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updCustomers, ...unloadList];
          // console.log("modifyList up updFilingMastersList", updFilingMastersList);

          // setClientFiling(updFilingMastersList);
        } else {
          //list < 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updCustomers, ...unloadList];
          // console.log("modifyList up updFilingMastersList", updFilingMastersList);

          // setClientFiling(updFilingMastersList);
        }
      }
      // if (value === "2" && selectedYear !== "") {
      //   setClientFiling([]);
      //   setInitialValue([]);
      //   setClientFilingHistory(updFilingMastersList)
      //   setInitialClientFilingHistory(updFilingMastersList);
      //   props.setClientFilingData(updFilingMastersList);
      //   props.resetRefreshList();
      // } else {
      //   setClientFilingHistory([])
      //   setInitialClientFilingHistory([]);
      //   setClientFiling(updFilingMastersList);
      //   setInitialValue(updFilingMastersList);
      //   props.setClientFilingData(updFilingMastersList);
      // }
      setData(() => updFilingMastersList);
      setClients(() => updFilingMastersList)

      setRefreshRecordList(false)
      setLazyLoading(false)
      setIsLoading(false);

      return updFilingMastersList
    } else {
      //list empty
      // console.log("modifyList empty",list)
      setRefreshRecordList(false)
      setLazyLoading(false)
      setIsLoading(false);
      // setClientFiling([]);
      return [];
    }
  };

  const onFilterChange = async (e) => {
    console.log("onFilterChange props", e.filters);
    let filterChange = [];

    if (e.filters.global.value !== "" && e.filters.global.value !== null) {
      //global filter
      filterChange.push({
        name: `SearchByClientName`,
        val: e.filters.global.value,
        searchType: `ClientNameSearchType`,
        searchTypeVal: getSearchType("contains"),
      });
    } else {
      //map filter event
      Object.keys(e.filters).forEach((col) => {
        // console.log("onFilterChange col", col, e.filters[col]);
        if (
          e.filters[col].constraints &&
          e.filters[col].constraints[0].value !== null
        ) {
          if (col === "stateList") {
            Array.isArray(e.filters[col].constraints[0].value)
              ? e.filters[col].constraints[0].value.map((ele, i) => {
                let changeItem = {
                  name: `SearchByStates`,
                  val: getStateCode(ele),
                };
                if (i + 1 === e.filters[col].constraints[0].value.length) {
                  changeItem.searchType = `StatesSearchType`;
                  changeItem.searchTypeVal = getSearchType(
                    e.filters[col].constraints[0].matchMode
                  );
                }
                filterChange.push(changeItem);
              })
              : filterChange.push({
                name: `SearchByStates`,
                val: getStateCode(e.filters[col].constraints[0].value),
                searchType: `StatesSearchType`,
                searchTypeVal: getSearchType(
                  e.filters[col].constraints[0].matchMode
                ),
              });
          } else if (col === "customerName") {
            filterChange.push({
              name: `SearchByClientName`,
              val: e.filters[col].constraints[0].value,
              searchType: `ClientNameSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "parentCustomerName") {
            filterChange.push({
              name: `SearchByParentClientName`,
              val: e.filters[col].constraints[0].value,
              searchType: `ParentClientNameSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "categories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `WhoMustFileFederal`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileFederalSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "programLists") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `SearchByProgramNames`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `ProgramNameSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "trackNowLicenseName") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `SearchByTracknowSubscription`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `TracknowSubscriptionSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "postAwardLicenseName") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `SearchByPostawardSubscription`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `PostawardSubscriptionSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else {
            filterChange.push({
              name: `SearchBy${col}`,
              val: e.filters[col].constraints[0].value,
              searchType: `${col}SearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          }
        }
      });
    }
    console.log("onFilterChange filterChange", filterChange);

    //set filter
    handleSetFilterVariable(filterChange);
  };

  const formatFilterDate = (date) => {
    // console.log("formatFilterDate", date);
    let getDate = new Date(date);
    let formatDate = `${getDate.getFullYear()}-${getDate.getMonth() + 1
      }-${getDate.getDate()}`;
    // console.log("formatFilterDate formatDate", getDate.getFullYear(), formatDate);
    return formatDate;
  };

  const getStateCode = (stateName) => {
    // console.log("getStateCode", stateName, states);
    const state = states.find((s) => s.state === stateName);
    return state ? state.code : stateName;
  };

  const matchModeVal = [
    { name: "contains", val: "Contains" },
    { name: "in", val: "Contains" },
    { name: "notContains", val: "NotContains" },
    { name: "equals", val: "EqualTo" },
    { name: "dateIs", val: "DateIs" },
    { name: "dateIsNot", val: "DateIsNot" },
    { name: "dateBefore", val: "DateIsBefore" },
    { name: "dateAfter", val: "DateIsAfter" },
  ];

  const getSearchType = (matchMode) => {
    // console.log("getSearchType props", matchMode);
    return matchModeVal.find((ele) => ele.name === matchMode).val;
  };

  //set filter
  const handleSetFilterVariable = (filterChange) => {
    console.log("handleSetFilterVariable props", filterChange);
    setIsFilterChange(() => filterChange);
    setRecordsInView(0);
    setIsLoading(true);
    setData([]);
    setClients([])
  };

  //table sort change
  const handleSort = async (e) => {
    console.log("handleSort props", e, e.sortField);
    if (e.sortField !== "") {
      setSortingData(() => ({
        sortBy: e.sortField,
        sortOrder: e.sortOrder,
      }));
      setRecordsInView(0);
      setIsLoading(true);
      setSelectedClients(null);
      setData([]);
      setClients([])
    }
  };

  const getSortData = (val) => {
    console.log("getSortData props", val);
    if (val === "parentCustomerName") {
      return {
        SortByParameter: "SortByParentCustomer",
        SortByVal: "ParentCustome",
        SortOrderParameter: "SortOrderParentCustomer"
      }
    } else if (val === "programLists") {
      return {
        SortByParameter: "SortByProgramname",
        SortByVal: "Programinfo",
        SortOrderParameter: "SortOrderProgramname"
      }
    } else if (val === "stateList") {
      return {
        SortByParameter: "SortByStates",
        SortByVal: "States",
        SortOrderParameter: "SortOrderStates"
      }
    } else if (val === "categories") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "WhoMustFileFederal",
        SortOrderParameter: "SortOrder"
      }
    } else {
      return {
        SortByParameter: "SortBy",
        SortByVal: val.charAt(0).toUpperCase() + val.slice(1),
        SortOrderParameter: "SortOrder"
      }
    }
  }

  useEffect(() => {
    // console.log("filter/sort change", isFilterChange, sortingData, lazyLoading );
    //on filter/sort change
    if (!lazyLoading && !refreshRecordList) {
      setLazyLoading(true)
      const sortAndFilterApiCall = async () => {
        let records = await loadRecordsList(0, 100, "down");
        setRecordsInView(1);
        console.log("recordsCheck", records);
        setIsLoading(false);
      };
      sortAndFilterApiCall();
    }
  }, [isFilterChange, sortingData]);

  const tableRefresh = () => {
    // setSelectedClients(null);
    setRecordsInView(0)
    setIsLoading(true);
    setData([]);
    setClients([])
    setRefreshRecordList(true)
    setSelectedClientId(selectedClients.customerId)
    setSelectedClients(null)
  }

  const refreshRecords = async () => {
    console.log("refreshRecords")
    const vScroll = dt.current.getVirtualScroller()
    let range = vScroll.getRenderedRange();
    console.log("refreshRecords vScroll", vScroll, range);


    let records = await loadRecordsList(0, pageSize, "down");
    if (records) {
      console.log("refreshRecords refreshDetails", refreshDetails)
      if (selectedClientId !== null && selectedClients === null) {
        let selectedId = records.find((ele) => ele.customerId === selectedClientId)
        setSelectedClients(selectedId)
        setSelectedClientId(null)
      }
      if (refreshDetails?.viewport) {
        console.log("refreshRecords scrollInView", refreshDetails.viewport.first)
        // vScroll.scrollToIndex(refreshDetails.viewport.first);
      }
      setRecordsInView(1);
    }
  }

  useEffect(() => {
    console.log("refreshRecordList,", !lazyLoading);
    if (refreshRecordList && !lazyLoading) {
      setLazyLoading(true);
      refreshRecords()
    }

  }, [refreshRecordList])


  useEffect(() => {
    console.log("Total Records updated:", totalRecords);
  }, [totalRecords]);

  useEffect(() => {
    console.log("recordsInView,", recordsInView);
  }, [recordsInView]);

  useEffect(() => {
    console.log("clients,", clients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, clients.length, JSON.stringify(clients)]);

  useEffect(() => { console.log("isLoading", isLoading) }, [isLoading])

  useEffect(() => { console.log("lazyLoading", lazyLoading) }, [lazyLoading])

  return (
    <Fragment>
      <Dialog
        header='Info'
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className='m-0'>{alertMessage}</p>
      </Dialog>
      <Dialog
        header='Confirmation'
        visible={showRadio}
        style={{ width: "30vw" }}
        onHide={() => radioCloseAlert()}
        footer={radioFooterContent}
      >
        <p className='m-0'>{radioMessage}</p>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={radioVal}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="tracknow" control={<Radio />} label="Track Now" />
            <FormControlLabel value="postaward" control={<Radio />} label="Post Award" />

          </RadioGroup>
        </FormControl>
      </Dialog>
      {
        <CustomerComments
          show={showComments}
          dockAt='right'
          cust={selectedClients}
          comments={comments}
          handleClose={closeComments}
          refreshTable={tableRefresh}
        />
      }
      {
        <CustomerBusinessCategory
          show={showCategories}
          dockAt='right'
          cust={selectedClients}
          categories={categories}
          categoriesLookup={categoriesLookup}
          handleClose={closeCategories}
          refreshTable={tableRefresh}
        />
      }
      {
        <ClientProfileHistory
          show={showHistory}
          dockAt='right'
          tracker={selectedClients}
          activityHistory={activityHistory}
          commentActivityHistory={commentActivityHistory}
          handleClose={closeHistory}
        />
      }
      <Grid sx={{ height: "100%", width: "93%" }}>
        <Grid sm={24} sx={{ height: "100%", width: "93%" }}>
          {/* {customerLoaded && (
            <DataTableBase columns={columns} data={data} selectableRows />
          )} */}
          {
            <SlidingDrawer
              show={showFilter}
              dockAt='right'
              handleClose={closeFilter}
            />
          }
          {/* {isLoading ? (
            <ReactLoading
              style={{
                position: "absolute",
                width: "10vw",
                height: "0vh",
                marginLeft: "36rem",
                marginTop: "10rem",
                color: "#0000FF",
              }}
              type='spin'
              color='blue'
            />
          ) : ( */}
          <div
            className='card'
            style={{
              maxWidth: "90vw",
              padding: "1rem",
              margin: "1rem",
              background: "#FFF",
              borderRadius: "2%",
            }}
          >
            {/* <div className='flex justify-content-center align-items-center mb-4 gap-2'>
              <InputSwitch
                inputId='input-rowclick'
                checked={rowClick}
                onChange={(e) => setRowClick(e.value)}
              />
              <label htmlFor='input-rowclick'>Row Click</label>
            </div> */}
            <DataTable
              ref={dt}
              className="frozen-client-profile"
              scrollable
              resizableColumns
              showGridlines
              value={clients}
              emptyMessage='No records found'
              selection={selectedClients}
              onSelectionChange={(e) => selectRow(e.value)}
              // onSelectionChange={(e) => {setSelectedClients(e.value);console.log('Testing user',e.value)}}
              dataKey='customerId'
              // tableStyle={{ minWidth: "70rem", height: "90vh" }}
              style={{ minHeight: "50vh" }}
              // paginator={!viewAll}
              // rows={viewAll ? clients.length : 10}
              // rowsPerPageOptions={[5, 10, 25, 50]}
              filters={filters}
              filterMode="multiple"
              globalFilterFields={["customerName", "phone"]}
              header={header}
              loading={isLoading}
              virtualScrollerOptions={{
                // lazy: true,
                onScroll: loadDataLazy,
                itemSize: 70,
                delay: 0,
                scrollHeight: "550px",
                appendOnly: true,
                // showLoader: true,
                // loading: lazyLoading,
                // loadingTemplate,
              }}
              scrollHeight='550px'
              onFilter={(e) => onFilterChange(e)}
              sortField={sortingData.sortBy}
              sortOrder={sortingData.sortOrder}
              onSort={(e) => handleSort(e)}
            >
              <Column
                selectionMode='single'
                style={{ width: "1rem", margin: "0" }}
              >
                Select
              </Column>
              <Column
                body={(rowData) => <CustomCommentButton rowData={rowData} />}
                header='Indicator'
                style={{ textAlign: "center" }}
              ></Column>

              <Column
                field='customerName'
                header='Client Name'
                sortable
                filter
                frozen
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder='Search by Client Name'
                style={{ minWidth: "11rem", maxWidth: "25rem" }}

                body={(rowData) => (
                  <a
                    href='#'
                    onClick={() => displayClientFiling(rowData)}
                    style={{
                      color: "blue",
                      textDecoration: "none",
                      // transition: "color 0.1s ease, text-decoration 0.1s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#001FA9";
                      e.target.style.textDecoration = "underline";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "blue";
                      e.target.style.textDecoration = "none";
                    }}
                  >
                    {/* {rowData.customerName} */}
                    {rowData.customerName.length > 35 ? (
                      <Tooltip title={rowData.customerName} arrow>
                        <span>{rowData.customerName.substring(0, 35)}...</span>
                      </Tooltip>
                    ) : (
                      rowData.customerName
                    )}
                  </a>
                )}
              //  filterMenuStyle={{ width: "auto" }}
              ></Column>
              <Column
                field='parentCustomerName'
                header='Parent Client Name'
                sortable
                filter
                frozen
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder='Search by Parent Client Name'
                style={{ maxWidth: "15rem" }}
              ></Column>
              <Column
                field='categories'
                header='Federal Business Category'
                filter
                frozen
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                body={CellListDisplayFederal}
                filterField='categories'
                filterElement={federelBusinessFilterElement}
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder='Search by Category'
                style={{ maxWidth: "20rem" }}
                //  filterMenuStyle={{ width: "15rem" }}
                showClearButton={true}
              ></Column>
              <Column
                field='programLists'
                header='Program Names'
                sortable
                filter
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                body={CellListDisplayProgram}
                filterField='programLists'
                filterElement={programNameFilterElement}
                filterPlaceholder='Search by Program Names'
                style={{ minWidth: "18rem", maxWidth: "20rem" }}
                showAddButton={false}
                showFilterOperator={false}
                showClearButton={true}
              ></Column>
              {roleType.includes("JSIPOC") ||
                roleType.includes("Admin") ||
                roleType.includes("JSISME") ? (
                <Column
                  field='trackNowLicenseName'
                  header='TrackNow Subscription '
                  filter
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterElement={tracknowlicenseTypeFilter}
                  body={tracknowcellStatusEditorBody}
                  showAddButton={false}
                  showFilterOperator={false}
                  filterPlaceholder='Search by Name'
                  // showClearButton={false}
                  style={{ minWidth: "15rem", cursor: "default" }}
                ></Column>
              ) : null}
              {roleType.includes("JSIPOC-PostAward") ||
                roleType.includes("Admin-PostAward") ||
                roleType.includes("JSISME-PostAward") ? (
                <Column
                  field='postAwardLicenseName'
                  header='PostAward Subscription'
                  filter
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterElement={postawardlicenseTypeFilter}
                  body={postawardcellStatusEditorBody}
                  showAddButton={false}
                  showFilterOperator={false}
                  filterPlaceholder='Search by Name'
                  filterMenuStyle={{ width: "16rem" }}
                  // showClearButton={false}
                  style={{ minWidth: "14rem", cursor: "default" }}
                ></Column>
              ) : null}

              <Column
                field='address'
                header='Address'
                filter
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder='Search by Address'
                style={{ minWidth: "14rem", cursor: "default" }}
              ></Column>
              <Column
                field='stateList'
                header='States'
                sortable
                filter
                filterField='stateList'
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder='Search by Category'
                style={{ minWidth: "15rem" }}
                //  filterElement={filterCustomerState}
                filterElement={(options) =>
                  filterCustomerState(options, "stateList")
                }
                showClearButton={true}
                body={CellListDisplayState}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
              ></Column>
              <Column
                field='taxNumber'
                header='Tax Number'
                filter
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder='Search by Tax Number'
                style={{ minWidth: "18rem", cursor: "default" }}
              ></Column>
              <Column
                field='phone'
                header='Phone'
                filter
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                showAddButton={false}
                showFilterOperator={false}
                filterPlaceholder='Search by Phone'
                style={{ minWidth: "15rem", cursor: "default" }}
              ></Column>
              {/* <Column field='mail' header='Email'></Column> */}
              {/* <Column
                field="juristiction"
                header="Jurisdiction"
                sortable
                filterField="juristiction"
                showFilterMenu={false}
                filterMenuStyle={{ width: "14rem" }}
                style={{ minWidth: "14rem" }}
                filter
                filterElement={jurisdictionRowFilterTemplate}
              ></Column> */}
              <Column
                field='notes'
                header='Notes'
                style={{ minWidth: "29rem", maxWidth: "20rem" }}
                body={(rowData) => cellListDisplayNotes(rowData)}
              ></Column>
            </DataTable>
          </div>
        </Grid>
      </Grid>

      <Grid
        sx={{
          height: "100%",
          width: "5%",
        }}
      >
        <div
          style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}
        >
          <Item
            sx={{
              height: "100%",
            }}
          >
            <Toolbox
              isLoading={isLoading}
              displayFilter={displayFilter}
              editClient={editCustomer}
              deleteClient={deleteCustomerRecord}
              displayComments={displayComments}
              displayClientFiling={displayClientFiling}
              displayAddClientFiling={displayAddClientFiling}
              displayClientCategories={displayCategories}
              displayHistory={displayHistory}
              selectedClients={selectedClients?.customerId}
              postAward={selectedClients && (selectedClients?.trackNowLicenseId === null || selectedClients?.trackNowLicenseId === 0) && (selectedClients?.postAwardLicenseId !== null || selectedClients?.postAwardLicenseId !== 0)}
            />
          </Item>
        </div>
      </Grid>
    </Fragment>
  );
};

export default ClientProfiles;
