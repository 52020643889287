import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ShortTextIcon from "@mui/icons-material/ShortText";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AuthContext from "../../context/auth/AuthContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
// import { format } from "date-fns";
import Alert from "@mui/material/Alert";
//import IconButton from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip, IconButton } from "@mui/material";
import { DateTime } from "luxon";
import Collapse from "@mui/material/Collapse";
import Input from "@mui/material/Input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownload from "js-file-download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useDialog from "../common/UseDialog";

import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FilingMaster, FilingMasterComment } from "../../types/FilingMaster";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
type Anchor = "top" | "left" | "bottom" | "right";

export default function FilingMasterComments(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  draft?: FilingMaster;
  comments?: FilingMasterComment[];
  refreshTable: () => void;
  refreshTableUpcomingDueDates: () => void;
}) {
  const filingMasterContext = useContext(FilingMasterContext);
  const { getFilingMasterComments, addFilingMasterComment } =
    filingMasterContext;
  const formRef = useRef<HTMLFormElement>(null);
  const [data, setData] = React.useState<FilingMasterComment[]>([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const authContext = useContext(AuthContext);
  const { authUser, userRoles, toLocalDateTime, roleType } = authContext;
  const [approvalComment, setApprovalComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [remainingChars, setRemainingChars] = useState(256);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedComment, setSelectedComment] = useState<any>({});
  const [actionType, setActionType] = useState<any>("Add")

  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
    //  navigate("/filing-master-list");
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        // console.log(`Drawer Mouse Key Event...`);
        // if (
        //   event.type === "keydown" &&
        //   ((event as React.KeyboardEvent).key === "Tab" ||
        //     (event as React.KeyboardEvent).key === "Shift")
        // ) {
        //   return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };

  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      // console.log(
      //   `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      // );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) {
        setState({ ...state, [props.dockAt]: props.show });
        setActionType("Add")
      }
    }
    setApprovalComment((old) => "");

    if (getFilingMasterComments && props.draft && props.draft.filingId) {
      // console.log(`Fetching Commnet for :${props.draft.filingId}`);
      fetchFilingMasterComments(props.draft.filingId);
    }
    //eslint-disable-next-line
  }, [props.show]);

  const fetchCommentsAttachments = async (data: any = null) => {
    try {
      data = data ? data : props.comments;
      const { data: attachmentsData } = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterCommentsAttachments/${props.draft?.filingId}`
      );
      let comments =
        data &&
        data.map((comment: any) => {
          const matchingAttachment = attachmentsData.data.filter(
            (attachment: any) => attachment.commentsId === comment.commentsId
          );
          if (matchingAttachment.length > 0) {
            const { attachmentId, attachmentPath } = matchingAttachment[0];
            return { ...comment, attachmentId, attachmentPath };
          }
          return comment;
        });

      // console.log("Comments with attachments", comments);
      setData(comments);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const _data: FilingMasterComment[] = props.comments!;
    if (props.comments) {
      setData(() => _data);
    }
    fetchCommentsAttachments();
    //eslint-disable-next-line
  }, [props.comments]);

  useEffect(() => {
    // if(props.draft && props.draft.createDate!==null)
    //eslint-disable-next-line
  }, [props.draft]);

  const toLocalTime = (date: Date): string => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const fetchFilingMasterComments = async (filingId: number) => {
    setLoading(true);
    // console.log("@@Fetch FilingMasters1:", getFilingMasterComments);
    if (getFilingMasterComments) {
      // console.log("@@Fetch FilingMasters2:");
      try {
        const commentList = await getFilingMasterComments(filingId);
        setData(() => commentList);
        // console.log("@@Fetch FilingMasters2 comments:", commentList);
      } catch (error) {
        console.log("@@Error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // console.log(`Fetching Commnet for 0:${props.draft}`);
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      // console.log(
      //   `Toogle Drawer UED: DockAt:${props.dockAt}, Show: ${props.show}`
      // );
      // toggleDrawer(props.dockAt, props.show);
      setState({ ...state, [props.dockAt]: props.show });
    }
    setApprovalComment((old) => "");

    if (getFilingMasterComments && props.draft && props.draft.filingId) {
      // console.log(`Fetching Commnet for :${props.draft.filingId}`);
      fetchFilingMasterComments(props.draft.filingId);
    }
    //eslint-disable-next-line
  }, []);

  const onFileChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // const getFilename = function (str: any) {
  //   return str ? str.replace(/^.*[\\\/]/, "") : "";
  // };
  const getFilenameToolip = function (str: any) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };
  const getFilename = function (str: any) {
    const MAX_FILENAME_LENGTH = 20;

    if (str) {
      let filename = str ? str.replace(/^.*[\\\/]/, "") : "";
      if (filename.length > MAX_FILENAME_LENGTH) {
        const halfLength = Math.floor((MAX_FILENAME_LENGTH - 3) / 2);
        const firstHalf = filename.substr(0, halfLength);
        const secondHalf = filename.substr(-halfLength);
        filename = `${firstHalf}...${secondHalf}`;
      }
      return filename;
    } else {
      return "";
    }
  };
  const getUserName = (userId: string) => {
    // console.log("USER ROLES LKP:", userRoles);
    // console.log("USER ROLES LKP2 :", userId);

    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]?.userName
      : "";
    return user;
  };

  const list = (anchor: Anchor) => (
    <Fragment>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "medium",
                        fontSize: "16px",
                        color: "#424242",
                      }}
                    >
                      COMMENTS
                    </Typography>
                    <IconButton>
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCancel(anchor)}
                      />
                    </IconButton>
                  </Stack>
                </div>
                <div>
                  <Box sx={{ mx: 3 }}>
                    <TextField
                      id="notes"
                      name="notes"
                      label={
                        <span style={{ font: "italic normal normal" }}>
                          Add Comments
                        </span>
                      }
                      multiline
                      fullWidth
                      minRows={2}
                      variant="filled"
                      placeholder={"Enter Comments..."}
                      value={approvalComment}
                      onChange={(e) => {
                        if (e.target.value.length <= 256) {
                          onCommentChange(e);
                          setRemainingChars(256 - e.target.value.length);
                        }
                      }}
                    />

                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "small",
                        color: remainingChars === 0 ? "red" : "inherit",
                        display: remainingChars === 256 ? "none" : "block",
                      }}
                    >
                      {remainingChars} Characters are left
                    </Typography>
                  </Box>
                </div>
                <div style={{ margin: "0 0 0 0.5rem", display: "block" }}>
                  <MuiButton
                    variant="text"
                    onClick={() => setOpenAttachment(!openAttachment)}
                    disabled={actionType === "Edit"}
                  >
                    <AttachFileIcon /> ATTACHMENT
                  </MuiButton>
                  {actionType === "Edit" && selectedComment
                    && selectedComment.attachmentPath && selectedComment.attachmentPath !== "undefined" && (
                      <MuiButton
                        variant="text"
                        onClick={() =>
                          downloadFile(
                            selectedComment.attachmentPath,
                            selectedComment.attachmentId,
                            selectedComment.commentsId
                          )
                        }
                      >
                        {selectedComment.attachmentPath && <AttachFileIcon />}
                        <Tooltip
                          title={getFilenameToolip(selectedComment.attachmentPath)}
                        >
                          <ListItemText
                            primary={
                              selectedComment &&
                              selectedComment.attachmentPath &&
                              getFilename(selectedComment.attachmentPath)
                            }
                          />
                        </Tooltip>
                      </MuiButton>
                    )}
                  <Collapse orientation="horizontal" in={openAttachment}>
                    <form
                      method="post"
                      id="uploadForm"
                      onChange={(event) => onFileChange(event)}
                      style={{ margin: "1rem" }}
                      ref={formRef}
                    >
                      <label className="file-upload">
                        <Input
                          type="file"
                          name="file"
                          id="inFile"
                          color="primary"
                        />
                      </label>
                    </form>
                  </Collapse>
                </div>
                <div>
                  <CardActions
                    sx={{
                      // mt: "3rem",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      // sx={{ margin: 1, padding: 1 }}
                      onClick={() => handleCancel(anchor)}
                    >
                      Cancel
                    </MuiButton>
                    {actionType === "Add"
                      ? <MuiButton
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: "#0b3edf" }}
                        // sx={{ margin: 1, padding: 1 }}
                        onClick={() => handleOk(anchor)}
                      >
                        Add
                      </MuiButton>
                      : <MuiButton
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: "#0b3edf" }}
                        disabled={selectedComment.commentsText.trim() === approvalComment.trim()}
                        // sx={{ margin: 1, padding: 1 }}
                        onClick={() => editComment()}
                      >
                        Edit
                      </MuiButton>
                    }
                  </CardActions>
                </div>
              </Stack>
              <CardContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLoading ? <CircularProgress size={40} /> : null}

                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <Divider />

              <List>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100px",
                    }}
                  >
                    <CircularProgress size={40} />
                  </div>
                ) : data && data.length === 0 ? (
                  <Typography variant="body1" color="textSecondary">
                    No comments
                  </Typography>
                ) : (
                  data &&
                  data
                    .slice()
                    //.reverse()
                    .map((item, index: number) => (
                      <Fragment key={index}>
                        <Stack direction="row" justifyContent="space-between">
                          <ListItem
                            disablePadding
                            style={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            {item.updateUser
                              ? getUserName(item.updateUser)
                              : item.createUser && getUserName(item.createUser)}
                          </ListItem>
                          <ListItem sx={{ textAlign: "right" }}>
                            <ListItemText
                              secondary={
                                item &&
                                  item.updateDate
                                  ? toLocalDateTime && `${toLocalDateTime(item.updateDate)}`
                                  : toLocalDateTime && `${toLocalDateTime(item.createDate)}`
                              }
                            />
                          </ListItem>
                        </Stack>

                        <ListItem
                          disablePadding
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <ListItemText
                              sx={{
                                '&:hover': {
                                  backgroundColor: '#f0f0f0',
                                  cursor: "text"
                                },
                                width: "100%",
                              }}
                              primary={
                                item &&
                                  item.commentsText &&
                                  item.commentsText !== null
                                  ? item.commentsText
                                  : ""
                              }
                            />
                            <IconButton
                              color='primary'
                              disabled={DisableEdit(item)}
                            >
                              <EditIcon
                                onClick={() => handleEditComment(item)}
                                sx={{ cursor: "pointer" }}
                              />
                            </IconButton>
                            <IconButton
                              color='primary'
                              disabled={DisableEdit(item)}
                            >
                              <DeleteIcon
                                onClick={() => handleDeleteComment(item)}
                                sx={{ cursor: "pointer", fontWeight: "400", }}
                              />
                            </IconButton>
                          </div>
                          <MuiButton
                            variant="text"
                            onClick={() =>
                              downloadFile(
                                item.attachmentPath,
                                item.attachmentId,
                                item.commentsId
                              )
                            }
                          >
                            {
                              //@ts-ignore
                              item.attachmentPath && <AttachFileIcon />
                            }
                            <Tooltip
                              title={getFilenameToolip(item.attachmentPath)}
                            >
                              <ListItemText
                                primary={
                                  item &&
                                  //@ts-ignore
                                  item.attachmentPath &&
                                  getFilename(item.attachmentPath)
                                }
                              />
                            </Tooltip>
                          </MuiButton>
                        </ListItem>
                      </Fragment>
                    ))
                )}
              </List>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <style>
        {`.css-1x51dt5-MuiInputBase-input-MuiInput-input{
        cursor : pointer;
        {

      `}
      </style>
    </Fragment >
  );

  const DisableEdit = (item: any) => {
    if (roleType.includes('Admin')) {
      return false
    } else if (authUser?.userId && authUser?.userId === parseInt(item.createUser)) {
      return false
    } else {
      return true
    }
  }

  const handleEditComment = (item: any) => {
    // console.log("handleEditComment", item , props.tracker)
    setActionType("Edit")
    setSelectedComment(item)
    setApprovalComment(item.commentsText)
  }

  const editComment = async () => {
    // console.log("editComment", selectedComment , props)
    setLoading(true);
    if (props.draft && props.draft.filingId) {
      try {
        const updateDate = new Date();
        let payload = {
          commentsId: selectedComment.commentsId,
          filingId: props.draft.filingId,
          commentsText: approvalComment,
          updateUser: authUser?.userId + "",
          updateDate
        }
        let res = await axios.put(`${API_BASE_URL}/FilingMaster/UpdatFilingMasterComment`, payload)
        // console.log("editComment res", res)
        if (res) {
          if (getFilingMasterComments) {
            const _catData = await getFilingMasterComments(
              props.draft?.filingId
            );
            setData(() => _catData);
            await fetchCommentsAttachments(_catData);
          }
          setAlertMessage("Successfully updated comment.");
          setShowError(false);
          setShowSuccess(true);
          closeDialog();
          setSelectedComment({})
          setApprovalComment("")
          setActionType("Add")
        }
      } catch (error) {
        console.log("@@Error:", error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleDeleteComment = (item: any) => {
    setActionType("Add")
    setSelectedComment(item)
    showDialog(
      "Confirmation",
      <Typography>
        Are you sure you want to delete this comment?
      </Typography>,
      "confirm"
    );
  }

  const deleteComments = async () => {
    // console.log("deleteComments", selectedComment , props.tracker)
    setLoading(true);
    if (props.draft && props.draft.filingId) {
      try {
        const updateDate = new Date();
        let payload = {
          commentsId: selectedComment.commentsId,
          filingId: props.draft.filingId,
          updateUser: authUser?.userId + "",
          updateDate
        }

        let res = await axios.put(`${API_BASE_URL}/FilingMaster/DeleteFilingMasterComment`, payload)
        // console.log("deleteComments res", res)
        if (res) {
          if (getFilingMasterComments) {
            const _catData = await getFilingMasterComments(
              props.draft?.filingId
            );
            setData(() => _catData);
            await fetchCommentsAttachments(_catData);
          }
          props.refreshTable();
          props.refreshTableUpcomingDueDates();
          setAlertMessage("Successfully deleted comment.");
          setShowError(false);
          setShowSuccess(true);
          closeDialog();
          setSelectedComment({})
        }
      } catch (error) {
        console.log("@@Error:", error);
      } finally {
        setLoading(false);
      }
    }
  }

  const closeConfirm = () => {
  };

  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: deleteComments,
    onClose: closeConfirm,
  });

  const onCommentChange = async (e: React.ChangeEvent<any>) => {
    setApprovalComment((old) => e.target.value);
    if (approvalComment) {
      setShowSuccess(false);
      setShowError(false);
    }
  };

  const handleOk = async (anchor: Anchor) => {
    // navigate("/filing-master-list");
    setIsLoading(true);
    setShowError(false);
    if (approvalComment.length === 0) {
      setAlertMessage("Please enter a comment.");
      setShowError(true);
      setIsLoading(false);
      setShowSuccess(false);
    } else {
      setShowSuccess(false);
      setShowError(false);
      // console.log(`Add Comment:${approvalComment}`);
      try {
        // const now = new Date();
        const createDate = new Date();
        const payload = {
          commentsText: approvalComment,
          filingId: props.draft?.filingId,
          // createDate: new Date(),
          createDate,
          createUser: authUser?.userId + "",
        };
        if (addFilingMasterComment) {
          const res = await addFilingMasterComment(payload);
          if (res) {
            // console.log(`Comment has been added`);
            if (selectedFile) {
              await uploadandUpdateAttachment(res.commentsId);
            }
            props.refreshTable();
            props.refreshTableUpcomingDueDates();

            if (getFilingMasterComments) {
              const _catData = await getFilingMasterComments(
                props.draft?.filingId
              );
              setData(() => _catData);
              await fetchCommentsAttachments(_catData);
            }
            setApprovalComment(() => "");
            setState({ ...state, [anchor]: true });
            setAlertMessage("Comment has been added");
            setShowError(false);
            setShowSuccess(true);
            setRemainingChars(256);
            setSelectedFile("");
            setOpenAttachment(false);
            formRef.current && formRef.current.reset();
            return;
          }
        }
      } catch (error) {
        console.log(`Error while adding comment`, error);
        setApprovalComment(() => "");
        setRemainingChars(256);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleCancel = async (anchor: Anchor) => {
    setApprovalComment(() => "");
    setData(() => []);
    setRemainingChars(256);
    setShowSuccess(false);
    setShowError(false);
    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  const getAttachmentId = async (commentsId: any) => {
    let payload = {
      commentsId: commentsId,
      createDate: new Date(),
      createUser: authUser?.userId + "",
      UpdatedUser: authUser?.userId + "",
      AttachmentPath: "",
    };
    const res = await axios.post(
      `${API_BASE_URL}/FilingMaster/CreateFilingMasterCommentsAttachments`,
      payload
    );
    // console.log("getAttachmentIt res", res.data);
    if (res.data) {
      return res.data.attachmentId;
    }
  };

  const updateFilePath = async (
    commentsId: any,
    attachmentId: any,
    filePath: any
  ) => {
    // console.log("updateFilePath", attachmentId, filePath);
    let payload = {
      attachmentId: attachmentId,
      AttachmentPath: decodeURI(filePath),
      createDate: new Date(),
      createUser: authUser?.userId + "",
      commentsId: commentsId,
      UpdatedUser: authUser?.userId + "",
    };

    const res = await axios.put(
      `${API_BASE_URL}/FilingMaster/UpdateFilingMasterCommentsAttachments/${attachmentId}`,
      payload
    );
    // console.log("updateFilePath res", res);
  };

  const onFileUpload = async (commentsId: any) => {
    let attachmentId = await getAttachmentId(commentsId);
    await fileUploadUtil(attachmentId, commentsId);
    return;
  };

  const uploadandUpdateAttachment = async (commentsId: any) => {
    // console.log("uploadandUpdateAttachment", commentsId);
    const formData = new FormData();
    formData.append("Files", selectedFile);
    formData.append("commentsId", commentsId);
    formData.append("FilingId", props.draft?.filingId?.toString() ?? "0");
    formData.append("createUser", authUser?.userId + "");
    const res = await axios.post(
      `${API_BASE_URL}/Application/UploadandUpdateAttachment`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log("uploadandUpdateAttachment res", res);
    if (res.data) {
    }
  };

  const downloadFile = (
    attachmentPath: any,
    attachmentId: any,
    commentsId: any
  ) => {
    // console.log("Downloading File from:", attachmentId);
    const fileName = getFilename(attachmentPath);
    // console.log("Downloading File:", fileName);

    axios({
      url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&CommentsId=${commentsId}&FilingId=${props.draft?.filingId}`,
      method: "POST",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, fileName);
    });
  };

  const fileUploadUtil = async (attachmentId: any, commentsId: any) => {
    // console.log("fileUploadUtil params", attachmentId, commentsId);

    const formData = new FormData();
    formData.append("Files", selectedFile);
    formData.append("commentId", commentsId);
    formData.append("AttachmentId", attachmentId);
    formData.append("FilingId", props.draft?.filingId?.toString() ?? "");
    formData.append("DraftId", props.draft?.draftId?.toString() ?? "");

    const res = await axios.post(
      `${API_BASE_URL}/Application/FilingmasterUpload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log("fileUploadUtil res", res);

    if (res.data) {
      // console.log("fileUploadUtil res header", res.headers);
      await updateFilePath(commentsId, attachmentId, res.headers["x-file-url"]);
      // setFiles([...files, selectedFile]);
    }
  };

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <ConfirmDialog />
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
            sx={{ zIndex: "11" }}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
