export const CUSTOMER_LOADING = "CUSTOMER_LOADING";
export const CUSTOMER_LOADED = "CUSTOMER_LOADED";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CUSTOMER_RESET = "CUSTOMER_RESET";
export const SET_CURRENT_CUSTOMER = "SET_CURRENT_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CLEAR_CURRENT_CUSTOMER = "CLEAR_CURRENT_CUSTOMER";
export const FILING_MASTER_LOADING = "FILING_MASTER_LOADING";
export const POST_PROGRAM_LOADING = "FILING_MASTER_LOADING";
export const FILING_MASTER_LOADED = "FILING_MASTER_LOADED";
export const POSTAWARD_FILING_MASTER_LOADED = "POSTAWARD_FILING_MASTER_LOADED";
export const FILING_MASTER_ERROR = "FILING_MASTER_ERROR";
export const POST_PROGRAM_ERROR = "POST_PROGRAM_ERROR";
export const FILING_MASTER_RESET = "FILING_MASTER_RESET";
export const SET_CURRENT_FILING_MASTER = "SET_CURRENT_FILING_MASTER";
export const UPDATE_FILING_MASTER = "UPDATE_FILING_MASTER";
export const CLEAR_CURRENT_FILING_MASTER = "CLEAR_CURRENT_FILING_MASTER";
export const CLIENT_FILING_MASTER_LOADING = "CLIENT_FILING_MASTER_LOADING";
export const POST_AWARD_CLIENT_FILING_MASTER_LOADING = "POST_AWARD_CLIENT_FILING_MASTER_LOADING";
export const CLIENT_FILING_MASTER_LOADED = "CLIENT_FILING_MASTER_LOADED";
export const POST_AWARD_CLIENT_FILING_MASTER_LOADED = "POST_AWARD_CLIENT_FILING_MASTER_LOADED";
export const CLIENT_FILING_MASTER_ERROR = "CLIENT_FILING_MASTER_ERROR";
export const CLIENT_FILING_MASTER_RESET = "CLIENT_FILING_MASTER_RESET";
export const FETCH_CONFIGURATIONS = "FETCH_CONFIGURATIONS";
export const APPROVER_LIST_LOADED = "APPROVER_LIST_LOADED";
// export const CREATE_CONFIGURATION = "CREATE_CONFIGURATION";
// export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_CURRENT_CLIENT_FILING_MASTER =
  "SET_CURRENT_CLIENT_FILING_MASTER";
export const UPDATE_CLIENT_FILING_MASTER = "UPDATE_CLIENT_FILING_MASTER";
export const CLEAR_CURRENT_CLIENT_FILING_MASTER =
  "CLEAR_CURRENT_CLIENT_FILING_MASTER";
export const juristictionLookup = [
  {
    name: "State",
    value: "State",
  },
  {
    name: "Federal",
    value: "Federal",
  },
];


export const BUSINES_CATEGORY_LOADED = "BUSINES_CATEGORY_LOADED";
export const POST_PROGRAM_LOADED = "POST_PROGRAM_LOADED";
export const PROGRAM_NAME_LOADED = "PROGRAM_NAME_LOADED";
export const FILING_MASTER_APPROVAL_LOADED = "FILING_MASTER_APPROVAL_LOADED";
export const FILING_MASTER_APPROVAL_COMPLETED_LOADED = "FILING_MASTER_APPROVAL_COMPLETED_LOADED"

export const deptLookup = [
  {
    name: "Billing and Order Management Services",
    value: "Billing and Order Management Services",
  },
  {
    name: "Continuing Property Records",
    value: "Continuing Property Records",
  },
  {
    name: "Division Management (Consulting)",
    value: "Division Management (Consulting)",
  },
  {
    name: "Financial Services",
    value: "Financial Services",
  },
  {
    name: "Numbering and Porting Services",
    value: "Numbering and Porting Services",
  },
  {
    name: "Regulatory Services",
    value: "Regulatory Services",
  },
  {
    name: "Revenue Requirements",
    value: "Revenue Requirements",
  },
];

export const filingFrequencyLookup = [
  "Weekly",
  "Monthly",
  "Quarterly",
  "Semi-Monthly",
  "Semi-Annually",
  "Annually",
  "Biennial",
  "Conditional",
];

export const UPDATE_DRAFT_FILING_MASTER = "UPDATE_DRAFT_FILING_MASTER";
export const UPDATE_FILING_MASTER_WF = "UPDATE_FILING_MASTER_WF";
export const MASTER_COMMENTS_LOADED = "MASTER_COMMENTS_LOADED";
export const MASTER_COMMENTS_ATTACHMENTS_LOADED =
  "MASTER_COMMENTS_ATTACHMENTS_LOADED";
export const CLIENT_MASTER_COMMENTS_ATTACHHMENTS_LOADED =
  "CLIENT_MASTER_COMMENTS_ATTACHHMENTS_LOADED";
export const FILING_MASTER_ADD_COMMENT = "FILING_MASTER_ADD_COMMENT";
export const CUSTOMER_COMMENTS_LOADED = "CUSTOMER_COMMENTS_LOADED";
export const CUSTOMER_CATEGORIES_LOADED = "CUSTOMER_CATEGORIES_LOADED";
export const SET_AUTH_STATUS = "SET_AUTH_STATUS";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const MASTER_WORKFLOW_COMMENTS_LOADED =
  "MASTER_WORKFLOW_COMMENTS_LOADED";
export const CLIENT_FILING_MASTER_ADD_LOADED =
  "CLIENT_FILING_MASTER_ADD_LOADED";
export const ADD_CLIENT_FILING_MASTER = "ADD_CLIENT_FILING_MASTER";
export const APPROVE_CLIENT_FILING_MASTER = "APPROVE_CLIENT_FILING_MASTER";
export const APPROVE_POST_AWARD_CLIENT_FILING_MASTER = "APPROVE_POST_AWARD_CLIENT_FILING_MASTER";
export const REJECT_CLIENT_FILING_MASTER = "REJECT_CLIENT_FILING_MASTER";
export const REJECT_POST_AWARD_CLIENT_FILING_MASTER = "REJECT_POST_AWARD_CLIENT_FILING_MASTER";
export const CLIENT_FILING_MASTER_APPROVALS = "CLIENT_FILING_MASTER_APPROVALS";
export const CLIENT_MASTER_WORKFLOW_COMMENTS_LOADED =
  "CLIENT_MASTER_WORKFLOW_COMMENTS_LOADED";
export const CLIENT_FILING_TRACKER_LOADED = "CLIENT_FILING_TRACKER_LOADED";
export const CLIENT_FILING_TRACKER_HISTORY_LOADED = "CLIENT_FILING_TRACKER_HISTORY_LOADED";
export const SET_CURRENT_CLIENT_FILING_TRACKER =
  "SET_CURRENT_CLIENT_FILING_TRACKER";
export const CLIENT_TRACKER_COMMENTS_LOADED = "CLIENT_TRACKER_COMMENTS_LOADED";
export const SET_AUTHORIZED = "SET_AUTHORIZED";
export const SET_PERMISSION = "SET_PERMISSION";
export const CLIENT_FILING_TRACKER_ATTCHMENTS_LOADED =
  "CLIENT_FILING_TRACKER_ATTCHMENTS_LOADED";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const FETCH_CUSTOMER_NOTIFICATIONS = "FETCH_CUSTOMER_NOTIFICATIONS";
export const FETCH_FILING_NOTIFICATIONS = "FETCH_FILING_NOTIFICATIONS";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_ROLES_LOADED = "USER_ROLES_LOADED";
export const INITIATE_CLIENT_FILING_MASTER = "INITIATE_CLIENT_FILING_MASTER";
export const INITIATE_POST_CLIENT_FILING_MASTER = "INITIATE_POST_CLIENT_FILING_MASTER";
export const INITIATE_POST_AWARD_CLIENT_FILING_MASTER = "INITIATE_POST_AWARD_CLIENT_FILING_MASTER";
export const CLIENT_FILING_TRACKER_REFRESH_PAGE = "CLIENT_FILING_TRACKER_REFRESH_PAGE";

