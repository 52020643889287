import React from "react";
import { Fragment } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import AuthContext from "../../context/auth/AuthContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import usePermission from "../../usePermission";
import RollOverFilingsToolBox from "./RollOverFilingsToolBox"
import { styled } from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RollOverFilingsHistory from './RollOverFilingsHistory'

const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 14,
      fontWeight: 100,
      fontStyle: "italic",
      fontFamily: "unset",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function RollOverFilings() {
  const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;
  //TRACKNOW STATE VARIABLES
  const [filingMasterSuccess, setFilingMasterSuccess] = useState(false);
  const [filingMasterError, setFilingMasterError] = useState(false);
  const [clientFilingMasterSuccess, setClientFilingMasterSuccess] =
    useState(false);
  const [clientFilingMasterError, setclientFilingMasterError] = useState(false);
  const [filingMasterErr, setFilingMasterErr] = useState("");
  const [clientFilingMasterErr, setClientFilingMasterErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<any>({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedRollOver, setSelectedRollOver] = useState<any>(null);

  //POSTAWARD STATE VARIABLES
  const [filingMasterSuccessPa, setFilingMasterSuccessPa] = useState(false);
  const [filingMasterErrorPa, setFilingMasterErrorPa] = useState(false);
  const [clientFilingMasterSuccessPa, setClientFilingMasterSuccessPa] =
    useState(false);
  const [clientFilingMasterErrorPa, setclientFilingMasterErrorPa] =
    useState(false);
  const [filingMasterErrPa, setFilingMasterErrPa] = useState("");
  const [clientFilingMasterErrPa, setClientFilingMasterErrPa] = useState("");
  //const [isLoading, setIsLoading] = useState(false);
  const [statusPa, setStatusPa] = useState<any>({});
  const [alertMessagePa, setAlertMessagePa] = React.useState("");
  const [showAlertPa, setShowAlertPa] = React.useState(false);

  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const { fetchConfigurations, updateReminderConfiguration } =
    clientFilingMasterContext;

  const authContext = React.useContext(AuthContext);
  const { roleType, authUser } = authContext;
  console.log("roleTypeCheck", roleType);

  //TRACKNOW HANDLE
  const clientFilingRollOver = async () => {
    try {
      setIsLoading(true);
      let clientFilingInc = null;
      const clientFiling = await axios.get(
        `${API_BASE_URL}/Customer/GetConfigValue?ConfigItem=CurrentYear`
      );
      if (clientFiling) {
        clientFilingInc = clientFiling.data + 1;
        if (clientFilingInc) {
          const res = await axios.put(
            `${API_BASE_URL}/Customer/CustomerFilingMasterRollover`
          );
          if (res.data.status === "Success") {
            setClientFilingMasterSuccess(true);
            checkStatus("clientFilingRollOverStatus");
            rollOverStatus();
          } else if (res.data.status === "Failure") {
            setClientFilingMasterErr(res.data.errorMessage);
            setclientFilingMasterError(true);
          }
        }
      }
    } catch (filingMasterError) {
      console.log("@@Error:", filingMasterError);
    } finally {
      setIsLoading(false);
      rollOverStatus();
    }
  };
  const filingMasterRollOver = async () => {
    try {
      setIsLoading(true);

      let masterFilingInc = null;

      const filingMaster = await axios.get(
        `${API_BASE_URL}/FilingMaster/GetConfigValue?ConfigItem=CurrentYear`
      );
      if (filingMaster) {
        masterFilingInc = filingMaster.data + 1;
        if (masterFilingInc) {
          const res = await axios.put(
            `${API_BASE_URL}/FilingMaster/FilingMasterRollover`
          );
          if (res.data.status === "Success") {
            setFilingMasterSuccess(true);
            checkStatus("masterFilingRollOverStatus");
            rollOverStatus();
          } else if (res.data.status === "Failure") {
            setFilingMasterErr(res.data.errorMessage);
            setFilingMasterError(true);
          }
        }
      }
    } catch (filingMasterError) {
      console.log("@@Error:", filingMasterError);
    } finally {
      setIsLoading(false);
      rollOverStatus();
    }
  };
  const handleFilingMasterSuccess = () => {
    setFilingMasterSuccess(() => false);
  };
  const handleFilingMasterError = () => {
    setFilingMasterError(() => false);
  };
  const handleClientFilingMasterSuccess = () => {
    setClientFilingMasterSuccess(() => false);
  };
  const handleClentFilingMasterError = () => {
    setclientFilingMasterError(() => false);
  };

  //POSTAWARD HANDLE
  const clientFilingRollOverPa = async () => {
    try {
      setIsLoading(true);
      let clientFilingInc = null;
      const clientFiling = await axios.get(
        `${API_BASE_URL}/Customer/GetConfigValue?ConfigItem=CurrentYear`
      );
      if (clientFiling) {
        clientFilingInc = clientFiling.data + 1;
        if (clientFilingInc) {
          const res = await axios.put(
            `${API_BASE_URL}/Customer/PostAwardCustomerFilingMasterRollover`
          );
          if (res.data.status === "Success") {
            setClientFilingMasterSuccessPa(true);
            checkStatusPa("clientPostAwardRollOverStatus");
            rollOverStatusPa();
          } else if (res.data.status === "Failure") {
            setClientFilingMasterErrPa(res.data.errorMessage);
            setclientFilingMasterErrorPa(true);
          }
        }
      }
    } catch (filingMasterErrorPa) {
      console.log("@@Error:", filingMasterErrorPa);
    } finally {
      setIsLoading(false);
      rollOverStatusPa();
    }
  };
  const filingMasterRollOverPa = async () => {
    try {
      setIsLoading(true);

      let masterFilingInc = null;

      const filingMaster = await axios.get(
        `${API_BASE_URL}/FilingMaster/GetConfigValue?ConfigItem=CurrentYear`
      );
      if (filingMaster) {
        masterFilingInc = filingMaster.data + 1;
        if (masterFilingInc) {
          const res = await axios.put(
            `${API_BASE_URL}/FilingMaster/PostAwardFilingMasterRollover`
          );
          if (res.data.status === "Success") {
            setFilingMasterSuccessPa(true);
            checkStatusPa("postAwardRollOverStatus");
            rollOverStatusPa();
          } else if (res.data.status === "Failure") {
            setFilingMasterErrPa(res.data.errorMessage);
            setFilingMasterErrorPa(true);
          }
        }
      }
    } catch (filingMasterErrorPa) {
      console.log("@@Error:", filingMasterErrorPa);
    } finally {
      setIsLoading(false);
      rollOverStatusPa();
    }
  };
  const handleFilingMasterSuccessPa = () => {
    setFilingMasterSuccessPa(() => false);
  };
  const handleFilingMasterErrorPa = () => {
    setFilingMasterErrorPa(() => false);
  };
  const handleClientFilingMasterSuccessPa = () => {
    setClientFilingMasterSuccessPa(() => false);
  };
  const handleClentFilingMasterErrorPa = () => {
    setclientFilingMasterErrorPa(() => false);
  };

  const navigate = useNavigate();

  const backToHome = () => {
    navigate("/");
  };

  // const completeRollOver = async () => {
  //   if (fetchConfigurations && updateReminderConfiguration) {
  //     let config = await fetchConfigurations();
  //     console.log("completeRollOver", config);
  //     let currentYear = config.filter(
  //       (ele: any) => ele.configItem === "CurrentYear"
  //     );
  //     console.log("completeRollOver", currentYear);
  //     let newYear = parseInt(currentYear[0].configItemValue) + 1;
  //     let updateConfig = {
  //       ...currentYear[0],
  //       configItemValue: newYear + "",
  //       updateDate: new Date(Date.now()).toISOString(),
  //       updateUser: authUser?.userId + "",
  //     };
  //     console.log("completeRollOver payload", updateConfig);
  //     let res = await updateReminderConfiguration([updateConfig]);
  //     console.log("completeRollOver res", res);
  //     if (res) {
  //       setAlertMessage(`Rollover successfully completed`);
  //       setShowAlert(true);
  //       rollOverStatus();
  //     }
  //   }
  // };

  //TRACKNOW completeRollOver fUNC
  const completeRollOver = async () => {
    const res = await axios.put(`${API_BASE_URL}/Application/CompleteRollover`);
    console.log("Complete-res", res, res.status);

    if (res) {
      setAlertMessage(`Rollover successfully completed`);
      setShowAlert(true);
      rollOverStatus();
      rollOverStatusPa();
    }
  };

  //POSTAWARD completeRollOver fUNC
  const completeRollOverPa = async () => {
    const res = await axios.put(`${API_BASE_URL}/Application/CompleteRollover`);
    console.log("Complete-res", res, res.status);

    if (res) {
      setAlertMessagePa(`Rollover successfully completed`);
      setShowAlertPa(true);
      rollOverStatusPa();
    }
  };

  //TRACKNOW CHECKSTATUS FUNC
  const checkStatus = (val: any) => {
    console.log("checkStatus", val);
    let statusTimeout = setTimeout(async () => {
      let res = await rollOverStatus();
      console.log("checkStatus", res);
      if (res[val] === "Completed") {
        clearTimeout(statusTimeout);
      } else {
        checkStatus(val);
      }
    }, 60000);
  };

  //POSTAWARD CHECKSTATUS FUNC
  const checkStatusPa = (val: any) => {
    console.log("checkStatus", val);
    let statusTimeout = setTimeout(async () => {
      let res = await rollOverStatusPa();
      console.log("checkStatus", res);
      if (res[val] === "Completed") {
        clearTimeout(statusTimeout);
      } else {
        checkStatusPa(val);
      }
    }, 60000);
  };

  //tracknow ROLLOVER STATUS FUNC
  const rollOverStatus = async () => {
    console.log("@@Fetch rollOverStatus");
    try {
      const res = await axios.get(`${API_BASE_URL}/Application/RollOverStatus`);
      console.log("rollOverStatus res", res.data.data);
      let status = { ...res.data.data[0] };
      if (
        res.data.data[0].masterFilingRollOverStatus === "Open" &&
        res.data.data[0].clientFilingRollOverStatus === "Open"
      ) {
        console.log("statusCheck1");
        status.masterBtn = true;
        status.masterBtnTitle = "Initiate Tracknow Filing Master Rollover";
        status.clientBtn = false;
        status.clientBtnTitle =
          "Initiate Tracknow Client Filing Master Rollover";
        status.completeBtn = false;
      } else if (
        res.data.data[0].masterFilingRollOverStatus === "In-Progress" &&
        res.data.data[0].clientFilingRollOverStatus === "Open"
      ) {
        console.log("statusCheck2");
        status.masterBtn = true;
        status.masterBtnTitle = "Complete Tracknow Filing Master Rollover";
        status.clientBtn = false;
        status.clientBtnTitle =
          "Initiate Tracknow Client Filing Master Rollover";
        status.completeBtn = false;
      } else if (
        res.data.data[0].masterFilingRollOverStatus === "Completed" &&
        res.data.data[0].clientFilingRollOverStatus === "Open"
      ) {
        console.log("statusCheck3");
        status.masterBtn = false;
        status.masterBtnTitle = "Completed Tracknow Filing Master Rollover";
        status.clientBtn = true;
        status.clientBtnTitle =
          "Initiate Tracknow Client Filing Master Rollover";
        status.completeBtn = false;
      } else if (
        res.data.data[0].masterFilingRollOverStatus === "Completed" &&
        res.data.data[0].clientFilingRollOverStatus === "In-Progress"
      ) {
        console.log("statusCheck4");
        status.masterBtn = false;
        status.masterBtnTitle = "Completed Tracknow Filing Master Rollover";
        status.clientBtn = true;
        status.clientBtnTitle =
          "Complete Tracknow Client Filing Master Rollover";
        status.completeBtn = false;
      } else if (
        res.data.data[0].masterFilingRollOverStatus === "Completed" &&
        res.data.data[0].clientFilingRollOverStatus === "Completed"
      ) {
        console.log("statusCheck5");
        status.masterBtn = false;
        status.masterBtnTitle = "Completed Tracknow Filing Master Rollover";
        status.clientBtn = false;
        status.clientBtnTitle =
          "Completed Tracknow Client Filing Master Rollover";
        status.completeBtn = true;
      }
      status.masterBtnVal = "TrackNowMasterFilingRollover"
      status.clientBtnVal = "TrackNowCustomerFilingRollover"
      setStatus(status);
      console.log("status", status);

      return status;
    } catch (error) {
      console.log("@@rollOverStatus Error:", error);
    }
  };

  //POSTAWARD ROLLOVER STATUS FUNC
  const rollOverStatusPa = async () => {
    console.log("@@Fetch rollOverStatus");
    try {
      const res = await axios.get(
        `${API_BASE_URL}/Application/PostAwardRollOverStatus`
      );
      console.log("rollOverStatus res", res.data.data);
      let status = { ...res.data.data[0] };
      if (
        res.data.data[0].postAwardRollOverStatus === "Open" &&
        res.data.data[0].clientPostAwardRollOverStatus === "Open"
      ) {
        status.masterBtn = true;
        status.masterBtnTitle = "Initiate Postaward Filing Master Rollover";
        status.clientBtn = false;
        status.clientBtnTitle =
          "Initiate Postaward Client Filing Master Rollover";
        status.completeBtn = false;
      } else if (
        res.data.data[0].postAwardRollOverStatus === "In-Progress" &&
        res.data.data[0].clientPostAwardRollOverStatus === "Open"
      ) {
        status.masterBtn = true;
        status.masterBtnTitle = "Complete Postaward Filing Master Rollover";
        status.clientBtn = false;
        status.clientBtnTitle =
          "Initiate Postaward Client Filing Master Rollover";
        status.completeBtn = false;
      } else if (
        res.data.data[0].postAwardRollOverStatus === "Completed" &&
        res.data.data[0].clientPostAwardRollOverStatus === "Open"
      ) {
        status.masterBtn = false;
        status.masterBtnTitle = "Completed Postaward Filing Master Rollover";
        status.clientBtn = true;
        status.clientBtnTitle =
          "Initiate Postaward Client Filing Master Rollover";
        status.completeBtn = false;
      } else if (
        res.data.data[0].postAwardRollOverStatus === "Completed" &&
        res.data.data[0].clientPostAwardRollOverStatus === "In-Progress"
      ) {
        status.masterBtn = false;
        status.masterBtnTitle = "Completed Postaward Filing Master Rollover";
        status.clientBtn = true;
        status.clientBtnTitle =
          "Complete Postaward Client Filing Master Rollover";
        status.completeBtn = false;
      } else if (
        res.data.data[0].postAwardRollOverStatus === "Completed" &&
        res.data.data[0].clientPostAwardRollOverStatus === "Completed"
      ) {
        status.masterBtn = false;
        status.masterBtnTitle = "Completed Postaward Filing Master Rollover";
        status.clientBtn = false;
        status.clientBtnTitle =
          "Completed Postaward Client Filing Master Rollover";
        status.completeBtn = true;
      }
      status.masterBtnVal = "PostAwardMasterFilingRollover"
      status.clientBtnVal = "PostAwardCustomerFilingRollover"
      setStatusPa(status);
      console.log("status", status);

      return status;
    } catch (error) {
      console.log("@@rollOverStatus Error:", error);
    }
  };
  useEffect(() => {
    rollOverStatus();
    rollOverStatusPa();
  }, []);
  console.log("Statuscheck", status, "statusPaCheck", statusPa);

  const isAdmin = Array.isArray(roleType) ? roleType.includes("Admin") : false;
  const isAdminPostaward = Array.isArray(roleType)
    ? roleType.includes("Admin-PostAward")
    : false;

  console.log(
    "Permission-check",
    "ADMIN",
    isAdmin,
    "isAdminPostaward",
    isAdminPostaward
  );

  
  const displayHistory = async () => {
    console.log("displayHistory", selectedRollOver)
    if(selectedRollOver){
      try {
        const history = await axios.get(
          `${API_BASE_URL}/Application/RollOversAuditlog?Status=${selectedRollOver}`
        );
        console.log(",displayHistory",history)
        setActivityHistory(() => history.data.data);
        setShowHistory(() => true);
      } catch (error) {
        console.log("@@Error:", error);
      }
    } else{
      setAlertMessage(`Please Select a Rollover`);
      setShowAlert(true);
    }
  }

  const closeHistory = () => {
    // console.log("Toggling Show Filter", showHistory);
    setShowHistory(() => false);
  };


  return (
    <Fragment>
        <div>
          <Dialog
            open={filingMasterSuccess}
            onClose={() => setFilingMasterSuccess(false)}
          >
            <DialogContent>
              <DialogTitle>
                Filing Master roll overed to the next year
              </DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFilingMasterSuccess}>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={filingMasterError}
            onClose={() => setFilingMasterError(false)}
          >
            <DialogContent>
              <DialogTitle>{filingMasterErr}</DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFilingMasterError}>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={clientFilingMasterSuccess}
            onClose={() => setClientFilingMasterSuccess(false)}
          >
            <DialogContent>
              <DialogTitle>
                Client Filing Master roll overed to the next year
              </DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClientFilingMasterSuccess}>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={clientFilingMasterError}
            onClose={() => setclientFilingMasterError(false)}
          >
            <DialogContent>
              <DialogTitle>{clientFilingMasterErr}</DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClentFilingMasterError}>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={showAlert} onClose={() => setShowAlert(false)}>
            <DialogContent>
              <DialogTitle>Info</DialogTitle>
              <p className='m-0'>{alertMessage}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowAlert(false)}>Ok</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={filingMasterSuccessPa}
            onClose={() => setFilingMasterSuccessPa(false)}
          >
            <DialogContent>
              <DialogTitle>
                Postaward Filing Master roll overed to the next year
              </DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFilingMasterSuccessPa}>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={filingMasterErrorPa}
            onClose={() => setFilingMasterErrorPa(false)}
          >
            <DialogContent>
              <DialogTitle>{filingMasterErrPa}</DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFilingMasterErrorPa}>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={clientFilingMasterSuccessPa}
            onClose={() => setClientFilingMasterSuccessPa(false)}
          >
            <DialogContent>
              <DialogTitle>
                Postaward Client Filing Master roll overed to the next year
              </DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClientFilingMasterSuccessPa}>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={clientFilingMasterErrorPa}
            onClose={() => setclientFilingMasterErrorPa(false)}
          >
            <DialogContent>
              <DialogTitle>{clientFilingMasterErrPa}</DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClentFilingMasterErrorPa}>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={showAlertPa} onClose={() => setShowAlertPa(false)}>
            <DialogContent>
              <DialogTitle>Info</DialogTitle>
              <p className='m-0'>{alertMessagePa}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowAlertPa(false)}>Ok</Button>
            </DialogActions>
          </Dialog>
        </div>
      <Grid sx={{ height: "90vh", width: "100%" , padding: "1rem"}}>
          <Stack direction='column' spacing={5}>
            <AppBar
              position='relative'
              color='inherit'
              sx={{ borderRadius: "8px" }}
            >
              <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                spacing={2}
              >
                <IconButton
                  className='nav-bar__logo__img'
                  sx={{ display: { xs: "none", md: "flex" } }}
                  onClick={backToHome}
                >
                  <Box sx={{ m: 2 }}>
                    <ArrowBack fontSize='medium' sx={{ mt: 1 }} />
                  </Box>
                </IconButton>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant='h6' color='inherit' sx={{ mr: 1 }}>
                    ROLLOVER FILINGS
                  </Typography>
                </Box>
              </Stack>
            </AppBar>
            <RadioGroup value={selectedRollOver} onChange={(e)=>setSelectedRollOver(e.target.value)}>
            {isAdmin && (
              <Stack direction='column' spacing={2}>
                <Typography variant='h6'>TrackNow Rollover Filings</Typography>
                <Stack direction='row' justifyContent='space-evenly'>
                  <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <div>
                    <FormControlLabel value={status.masterBtnVal} control={<Radio />} label="" />
                    <Button
                      onClick={filingMasterRollOver}
                      sx={{ backgroundColor: "#0b3edf" }}
                      variant='contained'
                      disabled={!status.masterBtn}
                    >
                      {status.masterBtnTitle}
                    </Button>
                    </div>
                    {status.masterBtn && (
                      <ThemeProvider theme={theme}>
                        <Typography variant='subtitle1'>
                          {" "}
                          Rollover year:{status.rollOverYear}
                        </Typography>
                      </ThemeProvider>
                    )}
                  </Stack>
                  <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <div>
                    <FormControlLabel value={status.clientBtnVal} control={<Radio />} label="" />                    
                    <Button
                      onClick={clientFilingRollOver}
                      sx={{ backgroundColor: "#0b3edf" }}
                      variant='contained'
                      disabled={!status.clientBtn}
                    >
                      {status.clientBtnTitle}
                    </Button>
                    </div>
                    {status.clientBtn && (
                      <ThemeProvider theme={theme}>
                        <Typography variant='subtitle1'>
                          {" "}
                          Rollover year:{status.rollOverYear}
                        </Typography>
                      </ThemeProvider>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}
            {isAdminPostaward && (
              <Stack direction='column' spacing={2}>
                <Typography variant='h6'>Postaward Rollover Filings</Typography>
                <Stack direction='row' justifyContent='space-evenly'>
                  <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <div>
                    <FormControlLabel value={statusPa.masterBtnVal} control={<Radio />} label="" />
                    <Button
                      onClick={filingMasterRollOverPa}
                      sx={{ backgroundColor: "#0b3edf" }}
                      variant='contained'
                      disabled={!statusPa.masterBtn}
                    >
                      {statusPa.masterBtnTitle}
                    </Button>
                    </div>
                    {statusPa.masterBtn && (
                      <ThemeProvider theme={theme}>
                        <Typography variant='subtitle1'>
                          {" "}
                          Rollover year:{statusPa.rollOverYear}
                        </Typography>
                      </ThemeProvider>
                    )}
                  </Stack>
                  <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <div>
                    <FormControlLabel value={statusPa.clientBtnVal} control={<Radio />} label="" />
                    <Button
                      onClick={clientFilingRollOverPa}
                      sx={{ backgroundColor: "#0b3edf" }}
                      variant='contained'
                      disabled={!statusPa.clientBtn}
                    >
                      {statusPa.clientBtnTitle}
                    </Button>
                    </div>
                    {statusPa.clientBtn && (
                      <ThemeProvider theme={theme}>
                        <Typography variant='subtitle1'>
                          {" "}
                          Rollover year:{statusPa.rollOverYear}
                        </Typography>
                      </ThemeProvider>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}
            </RadioGroup>
            {(isAdmin || isAdminPostaward) && (
              <Stack direction='row' justifyContent='space-evenly'>
                <div>
                  <Button
                    onClick={completeRollOver}
                    sx={{ backgroundColor: "#0b3edf" }}
                    variant='contained'
                    disabled={!status.completeBtn || !statusPa.completeBtn}
                  >
                    Complete Rollover
                  </Button>
                  {!status.completeBtn ||
                    (!statusPa.completeBtn && (
                      <ThemeProvider theme={theme}>
                        <Typography variant='subtitle1'>
                          {" "}
                          Rollover year:{status.rollOverYear}
                        </Typography>
                      </ThemeProvider>
                    ))}
                </div>
                {/* <div>
                <Button
                  onClick={completeRollOverPa}
                  sx={{ backgroundColor: "#0b3edf" }}
                  variant="contained"
                  disabled={!statusPa.completeBtn}
                >
                  PA Complete Rollover
                </Button>
              </div> */}
              </Stack>
            )}
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "20vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : null}
          </Stack>
        </Grid>
        {/* <Grid
        sx={{
          height: "100%",
          width: "5%",
        }}
      >
        <div
          style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}
        >
          <Item
            sx={{
              height: "100%",
            }}
          >
            <RollOverFilingsToolBox 
              displayHistory={displayHistory}
            />
          </Item>
        </div>
      </Grid> */}
      {/* {
        <RollOverFilingsHistory
          show={showHistory}
          dockAt="right"
          // tracker={selectedFiling[0]}
          activityHistory={activityHistory}
          handleClose={closeHistory}
        />
      } */}
    </Fragment>
  );
}
