import { createContext } from "react";

import IFilingMasterContext from "../../types/FilingMasterModel";

const defaultState: IFilingMasterContext = {
  filingMasters: [],
  postAwardFilingMasters: [],
  filingMasterApprovals: [],
  filingMasterApprovalsCompleted:[],
  error: null,
  filingMasterLoaded: false,
  filingMasterLoading: false,
};

const FilingMasterContext = createContext<IFilingMasterContext>(defaultState);

export default FilingMasterContext;
