import { createContext } from "react";

import IClientFilingMasterContext from "../../types/ClientFilingMasterModel";

const defaultState: IClientFilingMasterContext = {
  ClientFilingMasters: [],
  error: null,
  ClientFilingMasterLoaded: false,
  ClientFilingMasterLoading: false,
  refreshPageDetails: {}
};

const ClientFilingMasterContext =
  createContext<IClientFilingMasterContext>(defaultState);

export default ClientFilingMasterContext;
