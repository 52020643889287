import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ViewListIcon from "@mui/icons-material/ViewList";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import { FilingMaster } from "../../types/FilingMaster";
import ToolTip from "../common/ToolTip";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";

export default function Toolbox(props: {
  isLoading: boolean;
  approveDraft: () => void;
  addComment: () => void;
  displayAttachments: () => void;
}) {
  const navigate = useNavigate();

  const setApproveStatus = () => {
    props.approveDraft();
  };

  const setCommentStatus = () => {
    props.addComment();
  };
  const displayAttachments = () => {
    props.displayAttachments();
  };

  const showFilingMasterForm = () => {
    navigate("/filing-master");
  };
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <nav aria-label="main mailbox folders">
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton disabled={props.isLoading}>
              <ListItemIcon>
                <FilterListIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              disabled={props.isLoading}
              onClick={showFilingMasterForm}
            >
              <ToolTip title="Add Client Filing Master">
                <PostAddIcon />
              </ToolTip>
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      <Divider />
      <nav aria-label="secondary mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton
              disabled={props.isLoading}
              onClick={setCommentStatus}
            >
              <ToolTip title="Comments">
                <CommentIcon />
              </ToolTip>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              disabled={props.isLoading}
              onClick={setApproveStatus}
            >
              <ToolTip title="Approve/Reject Draft">
                <VerifiedUserIcon />
              </ToolTip>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={displayAttachments}
              disabled={props.isLoading}
            >
              <ToolTip title="Reference Documents">
                <SnippetFolderIcon />
              </ToolTip>
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton>
              <DeleteIcon />
            </ListItemButton>
          </ListItem> */}
        </List>
      </nav>
    </Box>
  );
}
