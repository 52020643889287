import React, { Fragment, useState, useEffect, useContext } from "react";
// import MyComponent from '../dev/change_client';
import { useParams, useNavigate } from "react-router-dom";
import Toolbox from "./Toolbox";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ClientFilingMasterTable from "./ClientFilingMasterTable";
import ClientLookup from "../customer/ClientLookup";
import ViewFilingComments from "./ViewFilingComments";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
// import CustomerContext from "../../context/customer/CustomerContext";
import axios from "axios";
import ClientFilingMasterAttachment from "./ClientFilingMasterAttachment";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

function ClientFilingMasterList() {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  let { clientId } = useParams();
  console.log("Page launch with param:", clientId);
  const [selectedClientId, setSelectedClientId] = useState(clientId);
  // const [selectedClientFiling, setSelectedClientFiling] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [comments, setComments] = React.useState([]);
  const [showComments, setShowComments] = useState(false);
  const [selectedClientFiling, setSelectedClientFiling] = useState(null);
  const [showAttachments, setShowAttachments] = useState(false);
  const [attachments, setAttachments] = React.useState([]);

  // const customerContext = useContext(CustomerContext);
  // const { customers, selectedCustomer, setCurrentCustomer } = customerContext;
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const {
    AddCreateCustomerFilingComments,
    getCustomerFilingComments,
    getCustomerFilingCommentsAttachments,
  } = clientFilingMasterContext;
  const displayFilter = () => {
    console.log("Toggling Show Filter", showFilter);
    setShowFilter(() => true);
  };
  const closeComments = () => {
    console.log("Toggling Show Filter", showComments);
    setComments(() => []);
    setShowComments(() => false);
  };
  const closeAttachments = () => {
    console.log("Toggling Show Filter", showAttachments);
    setShowAttachments(() => false);
  };
  const displayComments = () => {
    console.log("Display Comments", selectedClientFiling);
    console.log("Fetching Comments", selectedClientFiling);
    if (selectedClientFiling && selectedClientFiling.customerId) {
      console.log(`Fetching Commnet for :${selectedClientFiling.filingId}`);
      fetchFilingMasterComments(
        selectedClientFiling.customerId,
        selectedClientFiling.filingId
      );

      setShowComments(() => true);
    } else {
      setAlertMessage("Please Select a master Record");
      setShowAlert(true);
    }
  };

  const fetchFilingMasterAttachments = async (filingId) => {
    console.log("fetchFilingMasterAttachments props", filingId);
    try {
      const attachmentList = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachments/${filingId}`
      );
      setAttachments(() => attachmentList.data.data);
      console.log("fetchFilingMasterAttachments res", attachmentList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const displayAttachments = async () => {
    console.log("displayAttachments", selectedClientFiling);
    if (selectedClientFiling && selectedClientFiling.customerId) {
      console.log(`Fetching Attachment for :${selectedClientFiling.filingId}`);
      fetchFilingMasterAttachments(selectedClientFiling.filingId);
      setShowAttachments(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      // closeDialog();
    }
  };

  const fetchFilingMasterComments = async (customerId, filingId) => {
    console.log(
      "@@Fetch FilingMasters1:",
      getCustomerFilingCommentsAttachments
    );
    if (getCustomerFilingCommentsAttachments) {
      console.log("@@Fetch FilingMasters2:");
      try {
        const commentList = await getCustomerFilingCommentsAttachments({
          customerId,
          filingId,
        });
        setComments(() => commentList);
        console.log("@@Fetch FilingMasters2 comments:", commentList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };
  const displayAddClientFiling = () => {
    console.log("Add Filing for Customer", selectedClientId);
    if (selectedClientId) {
      // setCurrentCustomer(selectedClients);
      navigate(`/add-filings/${selectedClientId}`);
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };

  const displayClientFilingTracking = () => {
    console.log("Add Filing for Customer", selectedClientId);
    if (selectedClientId) {
      // setCurrentCustomer(selectedClients);
      navigate(`/client-filing-tracker-list/${selectedClientId}`);
    } else {
      setAlertMessage("Please Select a Client Record");
      setShowAlert(true);
    }
  };

  const footerContent = (
    <div>
      <PrimeButton
        label='OK'
        icon='pi pi-check'
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const closeAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    console.log("Loading Client Filing Master List...");
    if (clientId) setSelectedClientId(clientId);
    //eslint-disable-next-line
  }, [clientId]);

  // useEffect(() => {
  //   console.log("Loading Client Filing Master List...");
  //   if (selectedCustomer) {
  //     setSelectedClientId(() => selectedCustomer.customerId);
  //   }
  //   //eslint-disable-next-line
  // }, [selectedCustomer]);

  return (
    <Fragment>
      <Dialog
        header='Info'
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className='m-0'>{alertMessage}</p>
      </Dialog>
      <Grid sx={{ height: "100%", width: "93%", marginBottom: "2rem" }}>
        <Grid sx={{ height: "100%", width: "100%" }}>
          <div
            className='card'
            style={{ height: "100%", width: "100%", padding: " 0 1rem" }}
          >
            <ClientLookup
              setSelectedClientId={setSelectedClientId}
              parentRoute='client-filing-master-list'
            />
          </div>
        </Grid>
        <Grid sx={{ mx: 2 }}>
          <div
            className='card'
            style={{
              maxWidth: "90vw",
              padding: "1rem",
              margin: "0 1rem",
              background: "#FFF",
              borderRadius: "2%",
            }}
          >
            <ClientFilingMasterTable
              setSelectedClientFiling={setSelectedClientFiling}
              selectedClientId={selectedClientId}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          height: "100%",
          width: "5%",
        }}
      >
        <div
          style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}
        >
          <Item
            sx={{
              height: "100%",
            }}
          >
            <Toolbox
              displayFilter={displayFilter}
              displayAddClientFiling={displayAddClientFiling}
              displayClientFilingTracking={displayClientFilingTracking}
              displayComments={displayComments}
              displayAttachments={displayAttachments}
            />
          </Item>
        </div>
      </Grid>
      {
        <ViewFilingComments
          show={showComments}
          dockAt='right'
          clientMaster={selectedClientFiling}
          comments={comments}
          handleClose={closeComments}
        />
      }
      {
        <ClientFilingMasterAttachment
          show={showAttachments}
          dockAt='right'
          tracker={selectedClientFiling}
          attachments={attachments}
          handleClose={closeAttachments}
        />
      }
    </Fragment>
  );
}

export default ClientFilingMasterList;
