import React, { useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import { FormHelperText, Input, InputBase } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function MultiSelect(props: {
  inputs?: { name: string; value: string; isDisabled?: boolean }[];
  values?: string[];
  initValues?: string[];
  isdisabled?: boolean;
  keys?: any;
  label?: string;
  required?: boolean;
  helperText?: string;
  updateSelection?: (values: string[], keys: any, action: string) => void;
}) {
  const [lookup, setLookup] = React.useState<{ name: string; value: string }[]>(
    props.inputs ? props.inputs : []
  );
  const [values, setValues] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value },
    } = event;
    // console.log("&&& SEL VAL TYPE", typeof value);
    let updValues = typeof value === "string" ? value.split(",") : value;
    setValues((prevState) => {
      // console.log("&&& SEL VAL PREV", prevState);
      // console.log("&&& SEL VAL CURR", updValues);
      // console.log("&&& SEL VAL DIFF", _.difference(prevState, updValues));
      let action;
      let payload = _.difference(prevState, updValues);
      // console.log("&&& DEL VAL UPDD", payload);
      if (payload.length > 0) action = "DELETE";
      else {
        payload = _.difference(updValues, prevState);
        action = "ADD";
        // console.log("&&& ADD VAL UPDD", payload);
      }
      // if (props.updateSelection && props.keys) {
      if (props.updateSelection) {
        props.updateSelection(updValues, props.keys, action);
      }
      return updValues;
    });
  };

  const getSelectedNames = (selected: string[]): string => {
    let result: (string | undefined)[] = [];
    if (props.inputs) {
      result = props.inputs
        .filter(function (item) {
          // // console.log("###Item", item);
          // // console.log(
          //   "###Item exists",
          //   selected.includes(item.value ? item.value : "")
          // );
          return selected.includes(item.value ? item.value : "");
        })
        .map(function (item) {
          return item.name;
        });
    }

    let retValue = "";
    if (result && result.length > 1) {
      retValue = result.sort().join(", ");
    } else if (result && result.length === 1 && result[0]) {
      retValue = result[0];
    } else {
      retValue = "";
    }
    return retValue;
  };

  const valueSelected = (value: string): boolean => {
    // // console.log("###Lookup", props.inputs);
    // // console.log("###values", values);
    // // console.log("###value", value);
    let result = [];
    if (values) {
      result = values.filter((item) => item === value);
    }
    return result.length > 0;
  };

  useEffect(() => {
    // // console.log("***cat Values", values, props.inputs);
    if (props.inputs && props.inputs.length > 0) {
      setLookup((old) => props.inputs as { name: string; value: string, isDisabled?: boolean }[]);
    }
    // return () => {
    //   setValues((old) => []);
    // };
  }, [props.inputs]);

  useEffect(() => {
    const newVal = props.values ? props.values : [];
    if (typeof props.values !== "undefined")
      setValues((old) => [...old, ...newVal]);
    // // console.log("***Values", values, "Keys:",props.keys);
    return () => {
      setValues((old) => []);
    };
  }, []);

  useEffect(() => {
    // console.log("***Init Values", props.initValues);
    const newVal = props.initValues ? props.initValues : [];
    if (typeof props.initValues !== "undefined") {
      // console.log("***Init Values to ", newVal);
      setValues((old) => [...newVal]);
    }
  }, [props.initValues]);

  return (
    <div>
      <FormControl
        sx={{ minWidth: "20vw", maxWidth: "22vw" }}
        required={props.required ? true : false}
      >
        {/* <InputLabel id='multiple-checkbox-label'>
          {props.label ? props.label : ""}
        </InputLabel> */}
        <Select
          // id='multiple-checkbox-label'
          disabled={props.isdisabled}
          multiple
          variant='filled'
          value={values}
          onChange={handleChange}
          input={<Input sx={{ minHeight: "3.5rem" }} />}
          renderValue={(selected) => {
            // console.log("%% SEL1", selected);
            // console.log("%% SEL", getSelectedNames(selected));
            return getSelectedNames(selected);
          }}
          MenuProps={MenuProps}
          error={Boolean(props.helperText)}
        >
          {props.inputs &&
            props.inputs.map((item) => (
              <MenuItem key={item.name} value={item.value} disabled={item.isDisabled}>
                <Checkbox
                  checked={valueSelected(item.value ? item.value : "")}
                />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
        </Select>
        <FormHelperText sx={{ color: "red" }}>
          {props.helperText ? props.helperText : ""}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
