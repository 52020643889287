import { createContext } from "react";

import ICustomerContext from "../../types/CustomerModel";

const defaultState: ICustomerContext = {
  customers: [],
  error: null,
  customerLoaded: false,
  customerLoading: false,
};

const CustomerContext = createContext<ICustomerContext>(defaultState);
// const CustomerContext = createContext();

export default CustomerContext;
