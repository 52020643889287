import React, { useContext, useEffect, useState } from "react";
import "./PageNavbar.css";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton/IconButton";
import SearchIcon from "@mui/icons-material/Search";
// import AddIcon from "../../images/add.svg";
// import ProjectContext from "../../context/project/ProjectContext";

function PageNavbar({ pageTitle }) {
  // const projectContext = useContext(ProjectContext);
  // const { selectProject, selectedProject, projects } = projectContext;
  const topSearches = ["BA04", "UO24"];
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState("");
  const searchRef = React.useRef < HTMLInputElement > null;
  const [alignment, setAlignment] = React.useState("All");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };
  // useEffect(() => {
  // console.log("###selectProject:", selectProject);

  //eslint-disable-next-line
  // }, [selectedProject]);
  const handleSearchChange = (event: React.MouseEvent<HTMLElement>) => {
    if (searchRef.current) {
      setSearchTerm(searchRef.current.value);
      console.log("Set SEARCH Term:", searchRef.current.value);
    }
  };

  const showProjectDetail = (event) => {
    event.preventDefault();
    // navigate("/project/details/" + selectedProject.ID);
  };

  const showArea = (event) => {
    event.preventDefault();
    // navigate("/project/areas/" + selectedProject.ID);
  };

  const showUnits = (event) => {
    event.preventDefault();
    // navigate("/project/units/" + selectedProject.ID);
  };

  const showRoles = (event) => {
    event.preventDefault();
    // navigate("/project/roles/" + selectedProject.ID);
  };

  const showWorkflow = (event) => {
    event.preventDefault();
    // navigate("/project/workflow/" + selectedProject.ID);
  };

  return (
    <header>
      {/* <nav className='navbar navbar-inverse'>
        <div className='container-fluid cfl-nav'> */}
      <Grid container sx={{ my: 1, flexDirection: "row" }}>
        <Grid md={5} sx={{ margin: 1, py: 2, flexGrow: 1 }}>
          <Typography
            variant='h6'
            noWrap
            component='a'
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {pageTitle}
          </Typography>
        </Grid>

        <Grid
          md={3}
          sx={{
            margin: 2,
            px: 2,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Autocomplete
            id='search-auto-complete'
            fullWidth
            freeSolo
            options={topSearches.map((option) => option)}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Search'
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                inputRef={searchRef}
              />
            )}
          />
          <Button
            variant='contained'
            color='secondary'
            sx={{ mb: 9 }}
            onClick={handleSearchChange}
          >
            <IconButton
              aria-label='search'
              component='label'
              sx={{ backgroundColor: "primary" }}
            >
              <SearchIcon />
            </IconButton>
          </Button>
        </Grid>
        <Grid md={3} sx={{ margin: 1, py: 2 }}>
          <Button
            sx={{ p: 1, mx: 2 }}
            color='primary'
            variant='contained'
            onClick={() => navigate("/filing-master-list")}
          >
            MASTER FILING LIST
            <ArrowRightAltIcon sx={{ px: 1 }} />
          </Button>
        </Grid>
      </Grid>
      {/* </div>
      </nav> */}
    </header>
  );
}

export default PageNavbar;
