import {
  SET_AUTH_STATUS,
  SET_AUTH_USER,
  SET_AUTHORIZED,
  SET_PERMISSION,
  SET_NOTIFICATION_COUNT,
  AUTH_LOADING,
  AUTH_ERROR,
  FETCH_CUSTOMER_NOTIFICATIONS,
  FETCH_FILING_NOTIFICATIONS,
  USER_ROLES_LOADED,
  LOGIN,
  LOGOUT,
} from "../../data/constants";

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isPortalAuthenticated: true,
        portalUser: action.payload.user,
        token: action.payload.token,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isPortalAuthenticated: false,
        portalUser: null,
        token: null,
      };
    case SET_AUTH_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_AUTH_USER:
      // console.log("User Set in Localo:", action.payload);
      // console.log("User Set in Local1:", JSON.stringify(action.payload));
      localStorage.setItem("authUser", JSON.stringify(action.payload));
      // console.log("User Set in Local:", localStorage.getItem("authUser"));
      return {
        ...state,
        authUser: action.payload,
      };
    case SET_AUTHORIZED:
      return {
        ...state,
        isAuthorized: action.payload,
      };
    case SET_PERMISSION:
      return {
        ...state,
        permission: action.payload,
      };
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case FETCH_FILING_NOTIFICATIONS:
      return {
        ...state,
        filingNotifications: action.payload,
        loading: false,
      };
    case FETCH_CUSTOMER_NOTIFICATIONS:
      return {
        ...state,
        customerNotifications: action.payload,
        loading: false,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case USER_ROLES_LOADED:
      return {
        ...state,
        userRoles: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
