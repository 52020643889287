import React, { useContext } from "react";
import AuthContext from "./context/auth/AuthContext";
import userPermission from "./data/userPermission.json";

const getRolePermission = (roleType) => {
  //console.log("usePermission getRolePermission", roleType, userPermission);
  return userPermission.filter((ele) => ele.roleType === roleType)[0]
    ?.Permission;
};

const getPermission = (
  roleType,
  rolePermission,
  screen,
  actionType,
  clientId
) => {
  // console.log("usePermission props", roleType, rolePermission);
  // console.log("usePermission props", screen, actionType, clientId);
  if (rolePermission) {
    if (roleType.includes("Admin") || roleType.includes("Admin-PostAward")) {
      let acc;
      rolePermission.forEach((role) => {
        // console.log("usePermission merge", role, acc);
        acc = acc || role.permission[screen][actionType];
      });
      return acc;
    } else if (clientId) {
      let findRole = rolePermission.filter(
        (role) => role.customerId === parseInt(clientId)
      )[0];
      // console.log("usePermission merge", screen, clientId, findRole);
      return findRole !== undefined
        ? findRole.permission[screen][actionType]
        : false;
    } else {
      let acc;
      rolePermission.forEach((role) => {
        // console.log("usePermission merge", role, acc);
        acc = acc || role.permission[screen][actionType];
      });
      return acc;
    }
  } else {
    return "";
  }
};

export default function usePermission(screen, actionType, clientId) {
  const authContext = useContext(AuthContext);
  const { roleType, permission } = authContext;

  // console.log("usePermission", screen, actionType, clientId);
  // let rolePermission = getRolePermission(roleType);
  let actionPermission = getPermission(
    roleType,
    permission,
    screen,
    actionType,
    clientId
  );

  return actionPermission;
}
