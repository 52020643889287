import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import AuthContext from "../../context/auth/AuthContext";
import {
  Backdrop,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import { NestedMenuItem } from "mui-nested-menu";
import { useNavigate } from "react-router-dom";
import CustomerContext from "../../context/customer/CustomerContext";
import { useContext } from "react";
import usePermission from "../../usePermission";

export default function MainMenu(props: {
  show?: boolean;
  handleClose: () => void;
  handleLogout: () => void;
  anchRef: React.RefObject<HTMLButtonElement>;
}): JSX.Element {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //const open = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const customerContext = useContext(CustomerContext);
  const { setCurrentCustomer } = customerContext;
  const authContext = useContext(AuthContext);
  const { roleType, authUser } = authContext;
  const isAdmin = Array.isArray(roleType) ? roleType.includes("Admin") : false;
  const isAdminPostaward = Array.isArray(roleType)
    ? roleType.includes("Admin-PostAward")
    : false;

 
  const handleClose = (value: any) => {
    if (value !== "0") {
      setCurrentCustomer && setCurrentCustomer(null);
    }
    if (value === "-1") {
      props.handleLogout();
    } else if (value === "1") {
      //client profile list
      navigate("/");
    } else if (value === "2") {
      navigate("/filing-master-list");
    } else if (value == "3") {
      navigate("/postaward-filing-master-list");
    } else if (value == "3") {
      navigate("/postaward-filing-master-list");
    }
    // else if (value === "3") {
    //   navigate("/client-filing-master-list");
    //  }
    else if (value === "4") {
      navigate("/add-filings");
    } else if (value === "5") {
      navigate("/client-filing-tracker-list");
    } else if (value === "6") {
      navigate("/customer");
    } else if (value === "7") {
      navigate("/filing-master");
    } else if (value === "8") {
      navigate("/filing-master-approval-list");
    } else if (value === "9") {
      navigate("/client-filing-master-approval-list");
    } else if (value === "10") {
      navigate("/business-category");
    } else if (value === "11") {
      navigate("/ApprovalConfig");
    } else if (value === "12") {
      navigate("/notification");
    } else if (value === "13") {
      navigate("/reminder");
    } else if (value === "14") {
      navigate("/rollOverFilings");
    } else if (value === "15") {
      navigate("/user-role-setup");
    } else if (value === "16") {
      navigate("/notificationConfiguration");
    } else if (value === "17") {
      navigate("/ProgramNames");
    } else if (value === "18") {
      navigate("/PostAwardApproverConfiguration");
    } else if (value === "19") {
      navigate("/postaward-approval-list");
    } else if (value === "20") {
      navigate("/postaward-master-form");
    } else if (value === "21") {
      navigate("/add-filing-postaward");
    } else if (value === "22") {
      navigate("/PostAwardClientfilingMasterApprovalList");
    } else if (value === "23") {
      navigate("/rollOverFilings");
    }
    setOpen(false);
    props.handleClose();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    // if (props.show) {
    if (typeof props.show === "boolean") setOpen(props.show);
    // }
  }, [props.show]);

  const IsDisabled = (menuName: any) => {
    return !usePermission(menuName, "viewPage");
  };

  const showMenu = (menuName: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return usePermission(menuName, "showMenu");
  };
  const showMenuClient = (menuName: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return !usePermission(menuName, "showMenu");
  };

  return (
    <Stack
      direction="row"
      position="absolute"
      sx={{ marginTop: "40rem" }}
      zIndex="100"
    >
      <div>
        {/* <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon
          sx={{
            // mr: 2,
            color: "white",
          }}
        ></MenuIcon>
      </Button> */}
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          style={{
            backdropFilter: "blur(0.6px)", // Apply a blur filter to the backdrop
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity of the backdrop
          }}
        // onClick={handleClose}
        //onClick
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Popper
              anchorEl={props.anchRef.current}
              open={open}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <MenuList autoFocusItem={open}>
                      <MenuItem
                        sx={{
                          maxHeight: "28px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        onClick={() => handleClose("0")}
                        key="0"
                      >
                        <IconButton
                          sx={{ display: "flex", textAlign: "right" }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </MenuItem>
                      <Divider />
                      <NestedMenuItem
                        label="View Details"
                        parentMenuOpen={open}
                      >
                        <MenuItem
                          sx={{ maxHeight: "31px" }}
                          onClick={() => handleClose("1")}
                          key="1"
                          disabled={IsDisabled("ClientProfile")}
                        >
                          Home
                        </MenuItem>
                        {showMenu("MasterFilingList") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("2")}
                            key="2"
                            disabled={IsDisabled("MasterFilingList")}
                          >
                            TrackNow Master Filing List
                          </MenuItem>
                        )}{" "}
                        {showMenu("DefferedList") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("4")}
                            key="4"
                            disabled={IsDisabled("DefferedList")}
                          >
                            TrackNow Deferred Filings
                          </MenuItem>
                        )}
                        {showMenu("PostAwardMasterList") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("3")}
                            key="3"
                            disabled={IsDisabled("PostAwardMasterList")}
                          >
                            Post Award Master Filing List
                          </MenuItem>
                        )}
                        {showMenu("PostAwardDefferedList") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("21")}
                            key="21"
                            disabled={IsDisabled("PostAwardDefferedList")}
                          >
                            Post Award Deferred Filings
                          </MenuItem>
                        )}
                        <MenuItem
                          sx={{ maxHeight: "31px" }}
                          onClick={() => handleClose("5")}
                          key="5"
                          disabled={IsDisabled("ViewClientFilingTracker")}
                        >
                          Client Tracker
                        </MenuItem>
                        <MenuItem
                          sx={{ maxHeight: "31px" }}
                          onClick={() => handleClose("12")}
                          key="12"
                        >
                          Notification
                        </MenuItem>
                      </NestedMenuItem>
                      {/* <Divider /> */}
                      <NestedMenuItem
                        label="Workflows and Actions"
                        parentMenuOpen={open}
                      >
                        <MenuItem
                          sx={{ maxHeight: "31px" }}
                          onClick={() => handleClose("6")}
                          key="6"
                          disabled={showMenuClient("AddNewClient")}
                        >
                          Add New Client
                        </MenuItem>
                        {showMenu("AddFilingMaster") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("7")}
                            key="7"
                            disabled={IsDisabled("AddFilingMaster")}
                          >
                            Add New TrackNow Filing
                          </MenuItem>
                        )}
                        {showMenu("FilingMasterApproval") && (
                          <MenuItem
                            sx={{ maxHeight: "30px" }}
                            onClick={() => handleClose("8")}
                            key="8"
                            disabled={IsDisabled("FilingMasterApproval")}
                          >
                            TrackNow Filing Master Approval
                          </MenuItem>
                        )}
                        {showMenu("ClientFilingMasterApproval") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("9")}
                            key="9"
                            disabled={IsDisabled("ClientFilingMasterApproval")}
                          >
                            TrackNow Client Filing Master Approval
                          </MenuItem>
                        )}
                        {showMenu("AddPostAwardMaster") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("20")}
                            key="20"
                            disabled={IsDisabled("AddPostAwardMaster")}
                          >
                            Add New Post Award Master
                          </MenuItem>
                        )}
                        {showMenu("PostAwardMasterApproval") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("19")}
                            key="19"
                            disabled={IsDisabled("PostAwardMasterApproval")}
                          >
                            Post Award Master Approval
                          </MenuItem>
                        )}
                        {showMenu("PostAwardClientApproval") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("22")}
                            key="22"
                            disabled={IsDisabled("PostAwardClientApproval")}
                          >
                            Post Award Client Records Master Approval
                          </MenuItem>
                        )}
                      </NestedMenuItem>
                      {/* <Divider /> */}
                      <NestedMenuItem
                        label="Configurations"
                        parentMenuOpen={open}
                      >
                        {showMenu("BusinessCategory") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("10")}
                            key="10"
                            disabled={IsDisabled("BusinessCategory")}
                          >
                            TrackNow Business Category
                          </MenuItem>
                        )}
                        {showMenu("ApprovalConfiguration") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("11")}
                            key="11"
                            disabled={IsDisabled("ApprovalConfiguration")}
                          >
                            TrackNow Approval Configuration
                          </MenuItem>
                        )}
                        {showMenu("ProgramNames") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("17")}
                            key="17"
                            disabled={IsDisabled("ProgramNames")}
                          >
                            Post Award Program Configuration
                          </MenuItem>
                        )}
                        {showMenu("PostAwardApprovalConfiguration") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("18")}
                            key="18"
                            disabled={IsDisabled(
                              "PostAwardApprovalConfiguration"
                            )}
                          >
                            Post Award Approval Configuration
                          </MenuItem>
                        )}
                        <MenuItem
                          sx={{ maxHeight: "31px" }}
                          onClick={() => handleClose("16")}
                          key="16"
                        >
                          Notification Configuration
                        </MenuItem>
                        <MenuItem
                          sx={{ maxHeight: "31px" }}
                          onClick={() => handleClose("13")}
                          key="13"
                          disabled={IsDisabled("setting")}
                        >
                          Reminder Configuration
                        </MenuItem>
                        {(isAdmin || isAdminPostaward) && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("23")}
                            key="23"
                          // disabled={IsDisabled("rollOver")}
                          >
                            Rollover Filings
                          </MenuItem>
                        )}
                        {/* {showMenu("PostAwardRollOver") && (
                          <MenuItem
                            sx={{ maxHeight: "31px" }}
                            onClick={() => handleClose("23")}
                            key="23"
                            disabled={IsDisabled("PostAwardRollOver")}
                          >
                            Rollover Post Award Records
                          </MenuItem>
                        )} */}
                        <MenuItem
                          sx={{ maxHeight: "31px" }}
                          onClick={() => handleClose("15")}
                          key="15"
                          disabled={IsDisabled("userRole")}
                        >
                          User Role Setup
                        </MenuItem>
                      </NestedMenuItem>
                      {/* <Divider /> */}
                      <MenuItem
                        sx={{ maxHeight: "28px" }}
                        onClick={() => handleClose("-1")}
                        key="-1"
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ClickAwayListener>
        </Backdrop>
      </div>
    </Stack>
  );
}
