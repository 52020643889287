import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Grid,
  Stack,
  Collapse,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DateTime } from "luxon";
import FileDownload from "js-file-download";
import Alert from "@mui/material/Alert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import CircularProgress from "@mui/material/CircularProgress";
import AuthContext from "../../context/auth/AuthContext";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function PostAwardApprovalListHistory(props) {
  const [selectedFile, setSelectedFile] = useState("");

  const [data, setData] = React.useState([]);
  const [commentData, setCommentData] = React.useState([]);
  const [value, setValue] = React.useState("1");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [programList, setProgramList] = useState(false);
  const [awardingAgencyList, setAwardingAgencyList] = useState(false);

  const authContext = useContext(AuthContext);
  const { toLocalDateTime, userRoles } = authContext;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const postAwardProgram = useContext(FilingMasterContext);
  const { getPostProgram } = postAwardProgram;
  const filingMasterContext = useContext(FilingMasterContext);
  const closeAlert = () => {
    setShowAlert(false);
    props.handleClose();
  };
  const {
    getAwardingAgencies
  } = filingMasterContext;
  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const getAwardingAgencyList = async () => {
    if (getAwardingAgencies) {

      try {
        const catList = await getAwardingAgencies();
        // // console.log("getAwardingAgencyList",catList);
        setAwardingAgencyList(catList);

      } catch (error) {
        // console.log("@@Error:", error);
      }
    }

  }

  const toggleDrawer = (anchor, open) => (event) => {
    // console.log(`Drawer Mouse Key Event...`);
    // if (
    //   event.type === "keydown" &&
    //   ((event as React.KeyboardEvent).key === "Tab" ||
    //     (event as React.KeyboardEvent).key === "Shift")
    // ) {
    //   return;
    // }
    // // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
    // setState({ ...state, [anchor]: open });
    // if (!open) props.handleClose();
  };

  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const toLocalDate = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy "),
        "dd-MMM-yyyy ",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy ");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  useEffect(() => {
    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {
      // // console.log(
      //   `Toogle Drawer UEF: DockAt:${props.dockAt}, Show: ${props.show}`
      // );
      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
    }

    // if (
    //   getClientFilingMasterWorkflowAttachments &&
    //   props.workflow &&
    //   props.workflow.workflowId
    // ) {
    // //   console.log(`Fetching Commnet for :${props.workflow.workflowId}`);
    //   //   fetchFilingMasterAttachments(props.workflow.workflowId);
    // }
    //eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    if (props.commentActivityHistory) {
      setCommentData(() => props.commentActivityHistory);
    }

  }, [props.commentActivityHistory])

  // const newBusinessCategory = (oldBC, newBC) => {
  //   let BC = oldBC.map((ele) => ele.businessCategoryName);
  //   let changedBC = oldBC.map((ele) => ele.businessCategoryName);
  //   newBC.forEach((ele) => {
  //     if (ele.businessOperation === "add") {
  //       changedBC.push(ele.businessCategoryName);
  //     } else if (ele.businessOperation === "delete") {
  //       BC.push(ele.businessCategoryName);
  //     }
  //   });
  //   console.log("newBusinessCategory", BC, changedBC);
  //   return {
  //     field: "Business Category",
  //     old: BC.reduce((acc, item) => acc + (acc === "" ? "" : " , ") + item, ""),
  //     new: changedBC.reduce(
  //       (acc, item) => acc + (acc === "" ? "" : " , ") + item,
  //       ""
  //     ),
  //   };
  // };
  useEffect(() => {
    if (props.show) {
      getProgramList();
    }
  }, [props.show]);
  useEffect(() => {
    if (props.show) {
      getAwardingAgencyList()
    };
  }, [props.show]);
  const trackNowList = [
    { TrackNowLicenseId: 1, TrackNowLicenseName: "Essential" },
    { TrackNowLicenseId: 2, TrackNowLicenseName: "Plus" },
    { TrackNowLicenseId: 3, TrackNowLicenseName: "Platinum" },
  ];

  const getTracknowLisenceName = (oldLisenceId, newLisenceId) => {
    // console.log(
    //   "getTracknowisenceName ",
    //   oldLisenceId,
    //   newLisenceId,
    //   trackNowList
    // );

    const oldLisenceName =
      oldLisenceId === null
        ? ""
        : trackNowList.find((item) => item.TrackNowLicenseId === oldLisenceId);

    const newLisenceName =
      newLisenceId === null
        ? ""
        : trackNowList.find((item) => item.TrackNowLicenseId === newLisenceId);

    // console.log("getTracknowLisenceName ", oldLisenceName, newLisenceName);

    return {
      field: "trackNowLisence",
      old: oldLisenceName.TrackNowLicenseName,
      new: newLisenceName.TrackNowLicenseName,
    };
  };

  const postAwardList = [
    { PostAwardLicenseId: 1, PostAwardLicenseName: "Essential" },
    { PostAwardLicenseId: 2, PostAwardLicenseName: "Premium" },
    { PostAwardLicenseId: 3, PostAwardLicenseName: "Platinum" },
  ];

  const getPostAwardLisenceName = (oldLisenceId, newLisenceId) => {
    // console.log(
    //   "getPostAwardLisenceName ",
    //   oldLisenceId,
    //   newLisenceId,
    //   postAwardList
    // );

    const oldLisenceName =
      oldLisenceId === null
        ? ""
        : postAwardList.find(
          (item) => item.PostAwardLicenseId === oldLisenceId
        );

    const newLisenceName =
      newLisenceId === null
        ? ""
        : postAwardList.find(
          (item) => item.PostAwardLicenseId === newLisenceId
        );

    // console.log("getPostAwardLisenceName ", oldLisenceName, newLisenceName);

    return {
      field: "postAwardLisence",
      old: oldLisenceName.PostAwardLicenseName,
      new: newLisenceName.PostAwardLicenseName,
    };
  };

  const getProgramList = async () => {
    if (getPostProgram) {
      try {
        const catList = await getPostProgram();
        // console.log("getProgramList", catList);
        setProgramList(catList);
      } catch (error) {
        // console.log("@@Error:", error);
      }
    }
  };

  const getProgramNames = (oldLisenceId, newLisenceId) => {
    // console.log("getProgramNames ", oldLisenceId, newLisenceId, programList);

    const oldLisenceName =
      oldLisenceId === null
        ? ""
        : programList.find((item) => item.programId === oldLisenceId);
    const newLisenceName =
      newLisenceId === null
        ? ""
        : programList.find((item) => item.programId === newLisenceId);

    // console.log("getProgramNames ", oldLisenceName, newLisenceName);

    return {
      field: "programName",
      old: oldLisenceName === "" ? "" : oldLisenceName.programName,
      new: newLisenceName === "" ? "" : newLisenceName.programName,
    };
  };

  // const awardAgencyList = [
  //   { AwardingAgencyId: 1, AwardingAgencyName: "NTIA" },
  //   { AwardingAgencyId: 2, AwardingAgencyName: "USDA RUS" },
  //   { AwardingAgencyId: 3, AwardingAgencyName: "Treasury" },
  // ];
  const getAwardAgencyLisenceName = (oldLisenceId, newLisenceId) => {
    // console.log(
    //   "getAwardAgencyLisenceName ",
    //   oldLisenceId,
    //   newLisenceId,
    //   awardingAgencyList
    // );

    const oldLisenceName =
      oldLisenceId === null
        ? ""
        : awardingAgencyList.find(
          (item) => item.awardingAgencyId === oldLisenceId
        );
    const newLisenceName =
      newLisenceId === null
        ? ""
        : awardingAgencyList.find(
          (item) => item.awardingAgencyId === newLisenceId
        );
    // console.log("getAwardAgencyLisenceName ", oldLisenceName, newLisenceName);

    return {
      field: "AwardingAgency",
      old: oldLisenceName === "" ? "" : oldLisenceName.awardingAgencyName,
      new: newLisenceName === "" ? "" : newLisenceName.awardingAgencyName,
    };
  };

  const awardTypeList = [
    { AwardTypeId: 1, AwardTypeName: "Direct Federal Award" },
    { AwardTypeId: 2, AwardTypeName: "Direct State Award" },
    { AwardTypeId: 3, AwardTypeName: "Pass Through Award" },
  ];
  const getAwardTypeLicenseName = (oldLisenceId, newLisenceId) => {
    // console.log(
    //   "getAwardTypeLicenseName ",
    //   oldLisenceId,
    //   newLisenceId,
    //   awardTypeList
    // );

    const oldLisenceName =
      oldLisenceId === null
        ? ""
        : awardTypeList.find((item) => item.AwardTypeId === oldLisenceId);
    const newLisenceName =
      newLisenceId === null
        ? ""
        : awardTypeList.find((item) => item.AwardTypeId === newLisenceId);
    // console.log("getAwardTypeLicenseName ", oldLisenceName, newLisenceName);

    return {
      field: "awardType",
      old: oldLisenceName === "" ? "" : oldLisenceName.AwardTypeName,
      new: newLisenceName === "" ? "" : newLisenceName.AwardTypeName,
    };
  };

  useEffect(() => {
    if (props.show === true) {
      if (props.activityHistory) {
        let activity = props.activityHistory.map((ele) => {
          let changed = [];
          if (ele.process === "Modified") {
            // console.log("ele", ele);
            let keys = Object.keys(ele);
            keys.forEach((key) => {
              const capitalized = key.charAt(0).toUpperCase() + key.slice(1);
              // console.log("key", key, ele[key], ele[`new${capitalized}`]);
              if (key === "awardTypeId") {
                if (ele[key] !== ele[`new${capitalized}`]) {
                  let awardlisenceChange = getAwardTypeLicenseName(
                    ele[key],
                    ele[`new${capitalized}`]
                  );
                  changed.push(awardlisenceChange);
                }
              } else if (key === "awardingAgencyId") {
                if (ele[key] !== ele[`new${capitalized}`]) {
                  let agencylisenceChange = getAwardAgencyLisenceName(
                    ele[key],
                    ele[`new${capitalized}`]
                  );
                  changed.push(agencylisenceChange);
                }
              } else if (key === "programId") {
                if (ele[key] !== ele[`new${capitalized}`]) {
                  let programNameChange = getProgramNames(
                    ele[key],
                    ele[`new${capitalized}`]
                  );
                  changed.push(programNameChange);
                }
              }
              // else if (
              //   key === "businessCategory" &&
              //   ele.businessCategory !== null &&
              //   ele.newBusinessCategory !== null &&
              //   ele.newBusinessCategory.length > 0
              // ) {
              //   let isSame = _.isEqual(
              //     ele.businessCategory,
              //     ele.newBusinessCategory
              //   );
              // //   console.log("businessCategory isSame", isSame);
              // if (!isSame) {
              //   changed.push(
              //     newBusinessCategory(
              //       ele.businessCategory,
              //       ele.newBusinessCategory
              //     )
              //   );
              // }
              else if (
                key === "filingPeriods" &&
                ele.newFilingPeriods !== null &&
                ele.newFilingPeriods.length > 0
              ) {
                if (ele.filingPeriods === null) {
                  let filingPeriods = [];
                  let newFilingPeriods = ele.newFilingPeriods.sort(function (
                    a,
                    b
                  ) {
                    return new Date(b.StartDate) - new Date(a.StartDate);
                  });
                  // console.log(
                  //   "filingPeriods newFilingPeriods",
                  //   filingPeriods,
                  //   newFilingPeriods
                  // );
                  // changed.push({
                  //   field: "Due Date",
                  //   old: filingPeriods.map((periods) =>
                  //     toLocalDate(periods.dueDate)
                  //   ),
                  //   new: newFilingPeriods.map((periods) =>
                  //     toLocalDate(periods.dueDate)
                  //   ),
                  // });
                } else {
                  let isSame = _.isEqual(ele.filingPeriods, ele.newFilingPeriods);
                  // console.log("filingPeriods isSame", isSame);
                  if (!isSame) {
                    let filingPeriods = ele.filingPeriods.sort(function (a, b) {
                      return new Date(b.StartDate) - new Date(a.StartDate);
                    });
                    let newFilingPeriods = ele.newFilingPeriods.sort(function (
                      a,
                      b
                    ) {
                      return new Date(b.StartDate) - new Date(a.StartDate);
                    });
                    // console.log(
                    //   "filingPeriods newFilingPeriods",
                    //   filingPeriods,
                    //   newFilingPeriods
                    // );
                    // let oldFP = [];
                    // let newFP = [];
                    // filingPeriods.forEach((periods, i) => {
                    //   if (
                    //     filingPeriods[i].StartDate ===
                    //       newFilingPeriods[i].StartDate &&
                    //     !_.isEqual(filingPeriods[i], newFilingPeriods[i])
                    //   ) {
                    //     oldFP.push(filingPeriods[i]);
                    //     newFP.push(newFilingPeriods[i]);
                    //   }
                    // });
                    // console.log("filingPeriods oldFP", oldFP, newFP);
                    // changed.push({
                    //   field: "Due Date",
                    //   old: filingPeriods.map((periods) =>
                    //     toLocalDate(periods.dueDate)
                    //   ),
                    //   new: newFilingPeriods.map((periods) =>
                    //     toLocalDate(periods.dueDate)
                    //   ),
                    // });
                  }
                }
              } else if (
                key === "filingAttachments" &&
                (ele.filingAttachments !== null ||
                  ele.newFilingAttachments !== null)
              ) {
                let isSame = _.isEqual(
                  ele.filingAttachments,
                  ele.newFilingAttachments
                );
                // console.log("filingAttachments isSame", isSame);
                if (!isSame) {
                  let filingAttachments =
                    ele.filingAttachments !== null ? ele.filingAttachments : [];
                  let newFilingAttachments =
                    ele.newFilingAttachments !== null
                      ? ele.newFilingAttachments
                      : [];
                  let oldFA = _.differenceWith(
                    filingAttachments,
                    newFilingAttachments,
                    _.isEqual
                  );
                  let newFA = _.differenceWith(
                    newFilingAttachments,
                    filingAttachments,
                    _.isEqual
                  );
                  // console.log("filingAttachments newFA", oldFA, newFA);

                  changed.push({
                    field: "Attachments",
                    old: oldFA,
                    new: newFA,
                    draftId: ele.newDraftId,
                  });
                }
              } else if (
                ele[`new${capitalized}`] !== undefined &&
                ele[key] !== ele[`new${capitalized}`] &&
                key !== "draftId" &&
                key !== "filingPeriods" &&
                key !== "filingAttachments" &&
                key !== "businessCategory"
              ) {
                changed.push({
                  field: key,
                  old: ele[key],
                  new: ele[`new${capitalized}`],
                });
              }
            });
          }

          if (changed.length > 0) {
            ele.changedData = changed;
            // console.log("changedData", ele.changedData);
          }
          return ele;
        });
        // console.log("activity", activity);
        setData(() => props.activityHistory);
      }
    }
    //eslint-disable-next-line
  }, [props.activityHistory]);

  useEffect(() => {
    getProgramList();
  }, []);

  const getFilename = function (str) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };

  const downloadFile = (attachmentPath, attachmentId, draftId) => {
    // console.log("Downloading File from:", attachmentId);
    let filingId = props.tracker?.filingId ? props.tracker?.filingId + "" : "0";
    axios({
      url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&FilingId=${filingId}&DraftId=${draftId}`,
      method: "POST",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, attachmentPath);
    });
  };

  const ListElement = (item, index) => {
    //const [showChanges, setShowChanges] = useState(false);
    // console.log("ListElement", item, index);
    return (
      <Fragment key={index}>
        <Stack direction="row" justifyContent="space-between">
          <ListItem
            disablePadding
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {item.requestBy}
          </ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            <ListItemText
              secondary={
                item && item.requestDate && toLocalDateTime && `${toLocalDateTime(item.requestDate)}`
              }
            />
          </ListItem>
        </Stack>
        <ListItem disablePadding>
          <ListItemText
            primary={
              item && item.process && item.process !== null ? item.process : ""
            }
          />
        </ListItem>
        {item.changedData && item.changedData.length > 0 && (
          <TableContainer>
            <Table
              sx={{ minWidth: 250, border: "1px solid black" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Old value</TableCell>
                  <TableCell>New value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.changedData
                  .filter(
                    (ele) =>
                      ![
                        "filingDescription",
                        "dueDayofFrequency",
                        "jsidept",
                        "jsicontactEmail",
                      ].some((value) => value.includes(ele.field))
                  )
                  .map((ele) =>
                    ele.field === "Attachments" ? (
                      <TableRow>
                        <TableCell>{ele.field}</TableCell>
                        <TableCell>
                          {ele.old.map((attach) => (
                            <ListItem disablePadding>
                              <Link>
                                <AttachFileIcon />
                              </Link>
                              <ListItemButton
                                onClick={() =>
                                  downloadFile(
                                    attach.attachmentPath,
                                    attach.attachmentID,
                                    ele.draftId
                                  )
                                }
                              >
                                <ListItemText primary={attach.attachmentPath} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </TableCell>
                        <TableCell>
                          {ele.new.map((attach) => (
                            <ListItem disablePadding>
                              <Link>
                                <AttachFileIcon />
                              </Link>
                              <ListItemButton
                                onClick={() =>
                                  downloadFile(
                                    attach.attachmentPath,
                                    attach.attachmentID,
                                    ele.draftId
                                  )
                                }
                              >
                                <ListItemText primary={attach.attachmentPath} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </TableCell>
                      </TableRow>
                    )
                      // : ele.field === "Due Date" ? (
                      //   <TableRow>
                      //     <TableCell>{ele.field}</TableCell>
                      //     <TableCell>
                      //       {ele.old.map((date) => (
                      //         <p>{date}</p>
                      //       ))}
                      //     </TableCell>
                      //     <TableCell>
                      //       {ele.new.map((date) => (
                      //         <p>{date}</p>
                      //       ))}
                      //     </TableCell>
                      //   </TableRow>
                      // ) 
                      : (
                        <TableRow>
                          <TableCell>{ele.field}</TableCell>
                          <TableCell>{String(ele.old)}</TableCell>
                          <TableCell>{String(ele.new)}</TableCell>
                        </TableRow>
                      )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {/* {item.attachment.length > 0 && (
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ margin: "10px 0" }}
          >
            <ListItem disablePadding style={{ fontSize: "16px" }}>
              Attachments:
            </ListItem>
            <ListItem sx={{ textAlign: "right" }}>
              {item.attachment.map((ele) => (
                <ListItemText secondary={ele.attachmentName} />
              ))}
            </ListItem>
          </Stack>
        )} */}
        <Stack direction="row" justifyContent="space-between">
          <ListItem
            disablePadding
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {item.responsedBy}
          </ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            <ListItemText
              secondary={
                item && item.responseDate && toLocalDateTime && `${toLocalDateTime(item.responseDate)}`
              }
            />
          </ListItem>
        </Stack>
        <Stack direction="row">
          <ListItem disablePadding>Approved Status</ListItem>
          <ListItem sx={{ textAlign: "right" }}>{item.responseStatus}</ListItem>
          {/* <ListItem sx={{ textAlign: "right" }}>
      {toLocalTime(item.responseDate)}
    </ListItem> */}
        </Stack>
        <Stack direction="row">
          <ListItem disablePadding>Comment:</ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            {item.approverComments}
          </ListItem>
        </Stack>
        <Divider sx={{ borderBottomWidth: 3 }} />
      </Fragment>
    );
  };

  const getUserName = (userId) => {

    const user = userRoles
      ? userRoles?.filter((user) => user.userId === parseInt(userId))[0]
        ?.userName
      : "";
    return user;
  };

  const list = (anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent sx={{ mx: "10px", my: "10px" }}>
              <Stack direction="column" spacing={2}>
                <div
                  style={{
                    width: "30vw",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem",
                  }}
                >
                  ACTIVITY HISTORY
                  <IconButton>
                    <CloseIcon onClick={() => handleCancel(anchor)} />
                  </IconButton>
                </div>
              </Stack>
              <CardContent>
                {showError && <Alert severity="error">{alertMessage}</Alert>}
                {showSuccess && (
                  <Alert severity="success">{alertMessage}</Alert>
                )}
              </CardContent>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Filing Master Approvals" value="1" />
                    <Tab label="Comments" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {data &&
                    data
                      .slice()
                      .reverse()
                      .map((item, index) => ListElement(item, index))}
                </TabPanel>
                <TabPanel value="2">
                  {commentData &&
                    commentData
                      .slice()
                      // .reverse()
                      .map((item, index) => CommentListElement(item, index))}
                </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Fragment>
  );
  const CommentListElement = (item, index) => {
    return (
      <Fragment key={index}>
        <Stack direction="row" justifyContent="space-between">
          <ListItem
            disablePadding
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {item.updateUser !== null
              ? getUserName(item.updateUser)
              : getUserName(item.createUser)}
          </ListItem>
          <ListItem sx={{ textAlign: "right" }}>
            <ListItemText
              secondary={
                item && item.updateDate !== null
                  ? toLocalDateTime && `${toLocalDateTime(item.updateDate)}`
                  : toLocalDateTime && `${toLocalDateTime(item.createDate)}`
              }
            />
          </ListItem>
        </Stack>
        <ListItem disablePadding>
          <ListItemText
            primary={
              item && item.process && item.process !== null ? item.process : ""
            }
          />
        </ListItem>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 250 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Old value</TableCell>
                <TableCell>New value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{item.previousCommentsText}</TableCell>
                <TableCell>{item.commentsText}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  };

  const handleCancel = async (anchor) => {
    setSelectedFile(() => null);
    setShowSuccess(false);
    setShowError(false);
    setData(() => []);

    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
