import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AuthContext from "../../context/auth/AuthContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import {
  ClientFilingTracker,
} from "../../types/ClientFilingMaster";
import Alert from "@mui/material/Alert";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  FilingMasterWorkflowComment,
} from "../../types/FilingMaster";
import axios from "axios";
import Loader from '../common/Loader';
import usePermission from "../../usePermission";


type Anchor = "top" | "left" | "bottom" | "right";
const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

export default function ClientFilingBulkStatusUpdate(props: {
  dockAt?: "top" | "left" | "bottom" | "right";
  show?: boolean;
  handleClose: () => void;
  statusRecords?: ClientFilingTracker[];
  refreshList: () => void;
  handleRefreshTable: () => void;
  childList?: ClientFilingTracker[];
  commonStaffs?: [];
  value: any;
}) {

  let { clientId } = useParams();
  const filingMasterContext = useContext(FilingMasterContext);
  const {
    getFilingMasterWorkflowComments,
    addFilingMasterWorkflowComment,
    getFilingMasterWorkflowCommentsAttachments,
  } = filingMasterContext;
  const formRef = useRef<HTMLFormElement>(null);
  const [data, setData] = React.useState<FilingMasterWorkflowComment[]>([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const authContext = useContext(AuthContext);
  const { authUser, userRoles } = authContext;
  const [alertMessage, setAlertMessage] = useState("");
  const [approvalComment, setApprovalComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<ClientFilingTracker[]>([]);
  const [childList, setChildList] = useState<ClientFilingTracker[]>([])
  const [commonStaffs, setCommonStaffs] = useState([])
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [remainingChars, setRemainingChars] = useState(256);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffAssignedList, setStaffAssignedList] = useState<any[]>([]);
  const [staffAssignedVal, setStaffAssignedVal] = useState<any[]>([]);
  const [statusOptValue, setStatusOptValue] = useState("")
  const [filingName, setFilingName] = useState<(string | undefined)[] | []>(
    []
  );
  const statusDropdown = [
    { name: 'Pending', code: 'Pending' },
    { name: 'In progress', code: 'In progress' },
    { name: 'Approved', code: 'Approved' },
    { name: 'Completed', code: 'Completed' },
  ];
  const navigate = useNavigate();
  // const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  // function handleCloseClick() {

  //       setIsPopUpVisible(false);

  //     }

  useEffect(() => {
    if (props.show) {
      const statusRecordsArray = Array.isArray(props.statusRecords) ? props.statusRecords : [];
      const childListArray = Array.isArray(props.childList) ? props.childList : [];
      const commonStaffsArray = Array.isArray(props.commonStaffs) ? props.commonStaffs : [];
      console.log("Props useeffect props.childList", props.childList, "statusRecords", props.statusRecords, "props.commonStaffs", props.commonStaffs);
      console.log("Variable useeffect childListArray", childListArray, "statusRecordsArray", statusRecordsArray, "commonStaffsArray", commonStaffsArray);
      setSelectedRecords(() => statusRecordsArray)
      setChildList(() => childListArray)
      setCommonStaffs(() => commonStaffsArray)
      fetchStaffAssigned()
    };
  }, [props.statusRecords, props.show])
  console.log("check childList", childList, "selectedRecords", selectedRecords);

  const fetchStaffAssigned = () => {
    console.log("fetchStaffAssigned props", userRoles, props.statusRecords)

    let recordTypes = Array.isArray(props.statusRecords) ? props.statusRecords.map((ele) => ele.recordType) : [];

    console.log("fetchStaffAssigned recordTypes", recordTypes)

    if (userRoles !== undefined && userRoles?.length > 0) {
      const staffList = userRoles
        .filter((ele) => {
          if (ele.roleName === "Admin" || ele.roleName === "Admin-PostAward") {
            return ele;
          } else if (clientId && parseInt(clientId) === ele.customerId) {
            if (recordTypes.includes("TrackNow") && ele.roleName && !ele.roleName.includes("PostAward")) {
              return ele;
            } else if (recordTypes.includes("PostAward") && ele.roleName && ele.roleName.includes("PostAward")) {
              return ele;
            }
          }
        })
        .map((ele) => ({
          name: ele.userName !== undefined && ele.userName.charAt(0).toUpperCase() + ele?.userName.slice(1),
          code: ele.userId,
          loginId: ele.loginId

        }))
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.name === value.name)
        );
      console.log("fetchStaffAssigned staffList", staffList)

      staffList.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      let staffs = staffList.map(({ name, code }) => ({ name, code }));
      console.log("fetchStaffAssigned staffList", staffList, staffs, staffAssignedList)
      setStaffAssignedList(() => staffs);
    }
  }

  useEffect(() => {
    const exsistingUserId = commonStaffs.map((ele: any) => ele.userId)
    console.log("exsistingVal ", exsistingUserId);
    setStaffAssignedVal(exsistingUserId)
  }, [commonStaffs])

  useEffect(() => {
    console.log("fetchStaffAssigned staffAssignedVal", staffAssignedVal)
    setShowError(false)
  }, [staffAssignedVal, statusOptValue])

  const displayStaffVal = (selected: any) => {
    console.log("displayStaffVal props", selected)

    let staffNames = selected.map((ele: any) => {
      let staff = staffAssignedList.find((item) => item.code === ele)
      return staff.name
    })
    console.log("displayStaffVal props", staffNames)
    return staffNames.join(", ")
  }

  const closeAlert = () => {
    setShowAlert(false);
    // props.handleClose();
    // navigate("/filing-master-approval-list");
  };

  const onFileChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const footerContent = (
    <div>
      <Button
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );
  const handleStatausChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setStatusOptValue(event.target.value);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        // if (
        //   event.type === "keydown" &&
        //   ((event as React.KeyboardEvent).key === "Tab" ||
        //     (event as React.KeyboardEvent).key === "Shift")
        // ) {
        //   return;
        // }
        // console.log(`Toogle Drawer: DockAt:${anchor}, Show: ${open}`);
        // setState({ ...state, [anchor]: open });
        // if (!open) props.handleClose();
      };
  const toLocalTime = (date: Date): string => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yyyy hh:mm:ss");

      return _date;
    } else return "";
  };
  useEffect(() => {
    setLoading(true);

    if (
      typeof props.dockAt !== "undefined" &&
      typeof props.show !== "undefined"
    ) {

      // toggleDrawer(props.dockAt, props.show);
      if (props.show) setState({ ...state, [props.dockAt]: props.show });
    }
    setApprovalComment((old) => "");

    // if (
    //   (getFilingMasterWorkflowComments &&
    //     props.statusRecords &&
    //     props.statusRecords.workflowId) ||
    //   (getFilingMasterWorkflowComments &&
    //     props.rowData &&
    //     props.rowData.workflowId)
    // ) {
    //   console.log(`Fetching Commnet for :${props.statusRecords?.workflowId}`);
    //   console.log(`Fetching Commnet for rowdata :${props.rowData?.workflowId}`);
    //   //   fetchFilingMasterComments(props.statusRecords.workflowId);
    // }
    //eslint-disable-next-line
  }, [props.show]);





  //   useEffect(() => {
  //     // if(props.statusRecords && props.statusRecords.createDate!==null)
  //     //eslint-disable-next-line
  //   }, [props.statusRecords]);

  //   const fetchFilingMasterComments = async (filingId: number) => {
  //     console.log("@@Fetch FilingMasters1:", getFilingMasterWorkflowComments);
  //     if (getFilingMasterWorkflowComments) {
  //       console.log("@@Fetch FilingMasters2:");
  //       try {
  //         const commentList = await getFilingMasterWorkflowComments(filingId);
  //         setData(() => commentList);
  //         console.log("@@Fetch FilingMasters2 comments:", commentList);
  //       } catch (error) {
  //         console.log("@@Error:", error);
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     console.log(`Fetching Commnet for 0:${props.statusRecords}`);
  //     if (
  //       typeof props.dockAt !== "undefined" &&
  //       typeof props.show !== "undefined"
  //     ) {
  //       console.log(
  //         `Toogle Drawer UED: DockAt:${props.dockAt}, Show: ${props.show}`
  //       );
  //       // toggleDrawer(props.dockAt, props.show);
  //       setState({ ...state, [props.dockAt]: props.show });
  //     }
  //     setApprovalComment((old) => "");

  //     if (
  //       getFilingMasterWorkflowComments &&
  //       props.statusRecords &&
  //       props.statusRecords?.workflowId
  //     ) {
  //       console.log(`Fetching Commnet for :${props.statusRecords.workflowId}`);
  //       fetchFilingMasterComments(props.statusRecords?.workflowId);
  //     }
  //     //eslint-disable-next-line
  //   }, []);
  // const getFilename = function (str: any) {
  //   return str ? str.replace(/^.*[\\\/]/, "") : "";
  // };

  const getFilenameToolip = function (str: any) {
    return str ? str.replace(/^.*[\\\/]/, "") : "";
  };
  const getFilename = function (str: any) {
    const MAX_FILENAME_LENGTH = 20;

    if (str) {
      let filename = str.replace(/^.*[\\\/]/, "");
      if (filename.length > MAX_FILENAME_LENGTH) {
        const halfLength = Math.floor((MAX_FILENAME_LENGTH - 3) / 2);
        const firstHalf = filename.substr(0, halfLength);
        const secondHalf = filename.substr(-halfLength);
        filename = `${firstHalf}...${secondHalf}`;
      }
      return filename;
    } else {
      return "";
    }
  };

  const getUserName = (userId: string) => {


    const user = userRoles
      ? userRoles?.filter((user) => user.loginId === userId)[0]?.userName
      : "";
    return user;
  };

  const list = (anchor: Anchor) => (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid container>
        <Stack
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : "20vw",
            minWidth: "35vw",
            display: "flex",
            alignContent: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Card sx={{ border: "none", boxShadow: "none" }}>
              <CardContent sx={{ mx: "10px", my: "10px" }}>
                <Stack direction="column" spacing={2}>
                  <div>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          fontWeight: "500px",
                          fontSize: "22px",
                          color: "#424242",
                        }}
                      >
                        Bulk Update
                      </Typography>
                      <IconButton>
                        <CloseIcon onClick={() => handleCancel(anchor)} />
                      </IconButton>
                    </Stack>
                  </div>
                  <List>
                    {selectedRecords &&
                      selectedRecords.map((item: any, index: number) => (
                        <Fragment key={index}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                          >
                            <ListItem disablePadding

                            >
                              <ListItemText sx={{ fontSize: "1.1rem", fontWeight: "500" }} >Filing Name :</ListItemText>
                            </ListItem>
                            <ListItem disablePadding
                            >
                              {item.filingName.length > 10 ? (
                                <Tooltip title={item.filingName} arrow>
                                  <span>{item.filingName}</span>
                                </Tooltip>
                              ) : (
                                <span>{item.filingName}</span>
                              )}                            </ListItem>
                          </Stack>
                          <Stack direction="row"
                            justifyContent="space-between">
                            <ListItem disablePadding
                            >
                              <ListItemText sx={{ fontSize: "1.1rem", fontWeight: "500" }}>Filing Due Date :</ListItemText>
                            </ListItem>
                            <ListItem disablePadding
                            >
                              {item.dueDateFilingFormatted}
                            </ListItem>
                          </Stack>
                          <Stack direction="row"
                            justifyContent="space-between">
                            <ListItem disablePadding
                            >
                              <ListItemText sx={{ fontSize: "1.1rem", fontWeight: "500" }}>Status :</ListItemText>
                            </ListItem>
                            <ListItem disablePadding
                            >
                              {item.status}
                            </ListItem>
                          </Stack>
                          <Stack direction="row"
                            justifyContent="space-between">
                            <ListItem disablePadding
                            >
                              <ListItemText sx={{ fontSize: "1.1rem", fontWeight: "500" }}>Staff Assigned :</ListItemText>
                            </ListItem>
                            <ListItem disablePadding
                            >
                              {/* {item.staffAssigned.map((ele: any) => ele.userName).join(" , ")} */}
                              {commonStaffs.map((ele: any) => ele.userName).join(",")}
                            </ListItem>
                          </Stack>
                          <Divider />
                        </Fragment>
                      ))}
                  </List>
                  <div>
                    <CardActions>
                      <Stack direction="column" spacing={3}>
                        <Stack direction="row">
                          <Grid container>
                            {/* Row 1: Typography and FormControl */}
                            <Grid item style={{ height: "100px" }}>
                              <Typography sx={{ fontSize: "0.9rem" }} >Please select the status for Bulk Approval:</Typography>
                            </Grid>

                            <Grid item style={{ height: "100px" }}>
                              <Typography sx={{ fontSize: "0.9rem" }} >Please select the staff assigned for Bulk Approval:</Typography>
                            </Grid>

                            {/* <Grid item style={{ height: "100px", paddingTop: "40px" }}>
                          <MuiButton variant="contained" color="secondary" onClick={() => handleCancel(anchor)} sx={{ width: "120px", marginTop: "8px" }}>
                            Cancel
                          </MuiButton>
                        </Grid> */}
                          </Grid>
                          <Grid container>
                            <Grid item>
                              <FormControl sx={{ minWidth: 200, maxWidth: 200 }}  >
                                <InputLabel id="demo-select-small-label">Status</InputLabel>
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  value={statusOptValue}
                                  label="Options"
                                  onChange={handleStatausChange}
                                  disabled={!hasChangeStatusPermission}
                                >
                                  {statusDropdown.map((item, index) => (
                                    <MenuItem disabled={!hasChangeStatusPermission} key={index} value={item.code}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item>
                              <FormControl sx={{ minWidth: 200, maxWidth: 200 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Staff</InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={staffAssignedVal}
                                  onChange={(e) => setStaffAssignedVal(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                                  input={<OutlinedInput label="Tag" />}
                                  renderValue={(selected) => displayStaffVal(selected)}
                                  disabled={!hasStaffAssignedPermission}
                                >
                                  {staffAssignedList.map(({ name, code }) => (
                                    <MenuItem disabled={!hasStaffAssignedPermission} key={name} value={code}>
                                      <Checkbox checked={staffAssignedVal.indexOf(code) > -1} />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {/* <Grid item >
                          <MuiButton
                            variant="contained"
                            type="submit"
                            sx={{ backgroundColor: "#0b3edf", width: "120px" }}
                            onClick={() => handleOk(anchor)}
                          // disabled={!(statusOptValue || staffAssignedVal.length > 0)}
                          >
                            Update
                          </MuiButton>
                        </Grid> */}
                          </Grid>
                        </Stack>
                        {showError && <Alert severity="error">{alertMessage}</Alert>}
                        <Stack direction="row" justifyContent="space-between">
                          <MuiButton variant="contained" color="secondary" onClick={() => handleCancel(anchor)} sx={{ width: "120px", marginTop: "8px" }}>
                            Cancel
                          </MuiButton>
                          <MuiButton
                            variant="contained"
                            type="submit"
                            sx={{ backgroundColor: "#0b3edf", width: "120px" }}
                            onClick={() => handleOk(anchor)}
                          // disabled={!(statusOptValue || staffAssignedVal.length > 0)}
                          >
                            Update
                          </MuiButton>
                        </Stack>
                      </Stack>

                    </CardActions>
                  </div>
                </Stack>
              </CardContent>
            </Card>
          )}

        </Stack>
      </Grid>
    </Fragment >
  );

  const onCommentChange = async (e: React.ChangeEvent<any>) => {
    setApprovalComment((old) => e.target.value);
    if (approvalComment) {
      setShowSuccess(false);
      setShowError(false);
    }
  };

  const handleOk = async (anchor: Anchor) => {
    console.log("handleOk", childList, "selectedRecords", selectedRecords,)
    try {
      if (props.statusRecords) {
        const filteredCommonStaffs = commonStaffs.filter((commonStaff: { userId: any }) => !staffAssignedVal.includes(commonStaff.userId));
        if (statusOptValue !== "" || staffAssignedVal.length > 0 || filteredCommonStaffs.length > 0) {

          setIsLoading(true);
          let recordList = childList.length > 0 ? childList : selectedRecords
          console.log("handleOk", "recordList", recordList, "staffAssignedVal", staffAssignedVal, "commonStaffs", commonStaffs);
          console.log("handleOk filteredCommonStaffs", filteredCommonStaffs, "recordList", recordList);
          const payLoad =
            recordList
              .filter((item) => {
                console.log("handleOk item", item)
                let staffId = item?.staffAssigned ? item.staffAssigned.map((ele) => ele.userId) : []
                let newStaffId = staffAssignedVal.filter(val => !staffId.includes(val))
                console.log("handleOk newStaffId", staffId, staffAssignedVal, newStaffId)
                console.log("handleOk statusOptValue", statusOptValue, item.status)
                if ((statusOptValue !== "" && statusOptValue.toLowerCase() !== item.status?.toLowerCase())
                  || newStaffId.length > 0 || filteredCommonStaffs.length > 0) {
                  return item
                }
              })
              .map((item) => {
                const fileTrackingId = item.fileTrackingId;
                const status = statusOptValue !== "" ? statusOptValue : item.status;
                const updateDate = new Date();
                const updateUser = authUser?.userId + '';
                let customerId = clientId;
                let staffId = item?.staffAssigned ? item.staffAssigned.map((ele) => ele.userId) : []
                //   const staffAssignedId = item?.staffAssigned ? item.staffAssigned.map((ele) => ele.staffAssignedId) : []
                let newStaffId = staffAssignedVal.filter(val => !staffId.includes(val))
                console.log("handleOk newStaffId", newStaffId, "filteredCommonStaffs", filteredCommonStaffs);
                const staffAssigned = newStaffId.map((ele) => ({
                  staffAssignedId: 0,
                  fileTrackingID: item.fileTrackingId,
                  userId: ele,
                  createDate: new Date(),
                  createUser: authUser?.userId + '',
                  isDeleted: false
                }))

                const removedStaffAssigned = filteredCommonStaffs.map((ele: { userId: any }) => {
                  const matchingStaff = item.staffAssigned
                    ? item.staffAssigned.find((id) => ele.userId === id.userId)
                    : null;

                  // Extract the staffAssignedId or set to null if no match found
                  const staffAssignedId = matchingStaff ? matchingStaff.staffAssignedId : null;
                  console.log("handleOk matchingStaff ", matchingStaff, "staffAssignedId", staffAssignedId);

                  return {
                    staffAssignedId: staffAssignedId,
                    fileTrackingID: item.fileTrackingId,
                    userId: ele.userId,
                    createDate: new Date(),
                    createUser: authUser?.userId + '',
                    isDeleted: true
                  }
                })
                console.log("handleOk return", fileTrackingId, status, updateDate, updateUser, staffAssigned, removedStaffAssigned)

                return { fileTrackingId, status, updateDate, updateUser, staffAssigned: [...staffAssigned, ...removedStaffAssigned], customerId }
              })
          const combainedPayload = [...payLoad]
          console.log("handleOk combainedPayload", combainedPayload)

          const res: any = await axios.put(
            `${API_BASE_URL}/Customer/UpdateMultipleCustomerFileTracking`,
            combainedPayload
          );
          setFilingName(selectedRecords.map((item) => item.filingName));
          if (res.status) {
            setAlertMessage('Successfully updated the records for');
            setState({ ...state, [anchor]: false });
            setSelectedRecords([])
            setStatusOptValue("")
            setStaffAssignedVal([])
            props.handleClose();
            props.handleRefreshTable()
          } else {
            setAlertMessage(res.errorMessage);
          }
          setShowAlert(true);

          setShowAlert(true);
          setIsLoading(false);
        } else {
          setShowError(true)
          setAlertMessage("Please change any of the field")
        }
      }
    } catch (error) {
      props.handleClose();
      setState({ ...state, [anchor]: false });
      props.handleClose();
      setAlertMessage('Unexpected Error Occured during bulk status update');
      setShowAlert(true);

    }

  };
  const hasChangeStatusPermission = usePermission(
    "ClientFilingList",
    "changeStatus",
  );
  const hasStaffAssignedPermission = usePermission(
    "ClientFilingList",
    "changeStaff",
  );
  console.log("Global Premission check", "status", hasChangeStatusPermission, "Staffs", hasStaffAssignedPermission);

  const handleCancel = async (anchor: Anchor) => {
    setShowSuccess(false);
    setShowError(false);
    setSelectedRecords(() => []);
    setStatusOptValue("")
    setState({ ...state, [anchor]: false });
    props.handleClose();
  };

  // const getAttachmentId = async (commentsId: any) => {
  //   console.log("getAttachmentIt", props?.statusRecords);
  //   console.log("getAttachmentItRowData", props?.rowData);
  //   let payload = {
  //     commentsId: commentsId,
  //   };

  //   const res = await axios.post(
  //     `${API_BASE_URL}/FilingMaster/CreateFilingMasterWorkflowCommentsAttachments`,
  //     payload
  //   );
  //   console.log("getAttachmentIt res", res.data);
  //   if (res.data) {
  //     return res.data.attachmentId;
  //   }
  // };


  // const onFileUpload = async (commentsId: any) => {
  //   let attachmentId = await getAttachmentId(commentsId);
  //   await fileUploadUtil(attachmentId, commentsId);
  //   return;
  // };

  // const uploadandUpdateAttachment = async (commentsId: any) => {
  //   console.log("uploadandUpdateAttachment", commentsId);
  //   const formData = new FormData();
  //   formData.append("Files", selectedFile);
  //   formData.append("commentsId", commentsId);
  //   formData.append(
  //     "WorkflowId",
  //     (props.rowData?.workflowId || props.statusRecords?.workflowId)?.toString() ??
  //     ""
  //   );
  //   formData.append(
  //     "filingId",
  //     (props.rowData?.filingId || props.statusRecords?.filingId)?.toString() ?? ""
  //   );
  //   formData.append(
  //     "DraftId",
  //     (props.rowData?.draftId || props.statusRecords?.draftId)?.toString() ?? ""
  //   );
  //   formData.append("createUser", authUser?.userId + "");
  //   const res = await axios.post(
  //     `${API_BASE_URL}/Application/UploadandUpdateAttachment`,
  //     formData,
  //     {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }
  //   );
  //   console.log("uploadandUpdateAttachment res", res);
  //   if (res.data) {
  //   }
  // };

  // const downloadFile = (
  //   attachmentPath: any,
  //   attachmentId: any,
  //   commentsId: any
  // ) => {
  //   console.log("Downloading File from:", attachmentId);
  //   const fileName = getFilename(attachmentPath);
  //   console.log("Downloading File:", fileName);
  //   let filingId = props.rowData?.filingId || props.statusRecords?.filingId;
  //   let workflowId = props.rowData?.workflowId || props.statusRecords?.workflowId;
  //   let DraftId = props.rowData?.draftId || props.statusRecords?.draftId;
  //   axios({
  //     url: `${API_BASE_URL}/Application/DownloadAttachment?AttachmentId=${attachmentId}&CommentsId=${commentsId}&FilingId=${filingId}&WorkflowId=${workflowId}&DraftId=${DraftId}`,
  //     method: "POST",
  //     responseType: "blob", // Important
  //   }).then((response) => {
  //     FileDownload(response.data, fileName);
  //   });
  // };

  // const fileUploadUtil = async (attachmentId: any, commentsId: any) => {
  //   console.log("fileUploadUtil params", attachmentId, commentsId);

  //   //     -F 'FilingId=' \
  //   // -F 'CommentId=' \
  //   // -F 'AttachmentId=' \
  //   // -F 'DraftId=' \
  //   // -F 'WorkflowId='
  //   const formData = new FormData();
  //   formData.append("Files", selectedFile);
  //   formData.append("commentId", commentsId);
  //   formData.append("AttachmentId", attachmentId);
  //   formData.append(
  //     "WorkflowId",
  //     (props.rowData?.workflowId || props.statusRecords?.workflowId)?.toString() ??
  //     ""
  //   );
  //   formData.append(
  //     "DraftId",
  //     (props.rowData?.draftId || props.statusRecords?.draftId)?.toString() ?? ""
  //   ); // formData.append("FilingId", attachmentId);

  //   const res = await axios.post(
  //     `${API_BASE_URL}/Application/FilingmasterUpload`,
  //     formData,
  //     {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }
  //   );
  //   console.log("fileUploadUtil res", res);

  //   if (res.data) {
  //     console.log("fileUploadUtil res header", res.headers);
  //     await updateFilePath(commentsId, attachmentId, res.headers["x-file-url"]);
  //     // setFiles([...files, selectedFile]);
  //   }
  // };

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => (
        // <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
        {filingName.length > 0 && (
          <ul>
            {Array.from(new Set(filingName)).map((name, index) => (
              <li key={index}><strong>{name}</strong></li>
            ))}
          </ul>
        )}
      </Dialog>
      {typeof props.dockAt !== "undefined" && (
        <React.Fragment>
          <Drawer
            anchor={props.dockAt}
            open={state[props.dockAt]}
            onClose={toggleDrawer(props.dockAt, false)}
          >
            {list(props.dockAt)}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
