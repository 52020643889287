import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ViewListIcon from "@mui/icons-material/ViewList";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilingMaster } from "../../../types/FilingMaster";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import ToolTip from "../../common/ToolTip";
import usePermission from "../../../usePermission";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import DownloadIcon from "@mui/icons-material/Download";

export default function PostAwardToolbox(props: {
  isLoading: boolean;
  isTabValue: boolean;
  addComment: () => void;
  editMasterFilling: () => void;
  deleteMasterRecord: () => void;
  displayAttachments: () => void;
  displayHistory: () => void;
  displayClients: () => void;
  downloadData: () => void;
  downloadDataHistory: () => void;
  isDisabled: boolean;
  draft: any;
}) {
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const setCommentStatus = () => {
    props.addComment();
  };
  const editFilingMaster = () => {
    props.editMasterFilling();
  };

  const deleteFilingMaster = () => {
    props.deleteMasterRecord();
  };
  const displayClients = () => {
    props.displayClients();
  };
  // const downloadData = () => {
  //   props.downloadData();
  // };
  const downloadData = () => {
    props.downloadData();
  };
  const downloadDataHistory = () => {
    props.downloadDataHistory();
  };
  const displayAttachments = () => {
    props.displayAttachments();
  };

  const displayHistory = () => {
    props.displayHistory();
  };

  const showFilingMasterForm = () => {
    navigate("/postaward-master-form");
  };
  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );

  const closeAlert = () => {
    setShowAlert(false);
  };
  return (
    <React.Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>

      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <nav aria-label="main mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton disabled={props.isLoading || props.isDisabled}>
                <ListItemIcon>
                  <FilterListIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={showFilingMasterForm}
                disabled={
                  !usePermission(
                    "PostAwardMasterList",
                    "addFiling",
                    props.draft
                  ) ||
                  props.isLoading ||
                  props.isDisabled
                }
              >
                <ToolTip title="Add Post Award">
                  <PostAddIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
        <Divider />
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={setCommentStatus}
                disabled={
                  !usePermission("PostAwardMasterList", "comments", props.draft) ||
                  props.isLoading ||
                  props.isDisabled
                }
              >
                <ToolTip title="Comments">
                  <CommentIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={editFilingMaster}
                disabled={
                  !usePermission(
                    "PostAwardMasterList",
                    "editFiling",
                    props.draft
                  ) ||
                  props.isLoading ||
                  props.isDisabled
                }
              >
                <ToolTip title="Edit Post Award">
                  <EditIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={deleteFilingMaster}
                disabled={
                  !usePermission(
                    "PostAwardMasterList",
                    "deleteFiling",
                    props.draft
                  ) ||
                  props.isLoading ||
                  props.isDisabled
                }
              >
                <ToolTip title="Delete Filing Record">
                  <DeleteIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayAttachments}
                disabled={
                  !usePermission(
                    "PostAwardMasterList",
                    "referenceDocument",
                    props.draft
                  ) ||
                  props.isLoading ||
                  props.isDisabled
                }
              >
                <ToolTip title="Reference Documents">
                  <SnippetFolderIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayHistory}
                disabled={
                  !usePermission(
                    "PostAwardMasterList",
                    "activityHistory",
                    props.draft
                  ) ||
                  props.isDisabled ||
                  props.isLoading
                }
              >
                <ToolTip title="Activity History">
                  <HistoryIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
            {props.isTabValue ? (
              <ListItem disablePadding>
                <ListItemButton onClick={downloadDataHistory}>
                  <ToolTip title="Download">
                    <DownloadIcon />
                  </ToolTip>
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem disablePadding>
                <ListItemButton disabled={props.isLoading}
                  onClick={downloadData}>
                  <ToolTip title="Download">
                    <DownloadIcon />
                  </ToolTip>
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemButton
                onClick={displayClients}
                disabled={
                  !usePermission(
                    "PostAwardMasterList",
                    "associatedClient",
                    props.draft
                  ) ||
                  props.isLoading ||
                  props.isDisabled
                }
              >
                <ToolTip title="Associated Clients">
                  <PermContactCalendarIcon />
                </ToolTip>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </React.Fragment>
  );
}
