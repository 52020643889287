import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import DataTableBase from "../../common/DataTableBase";
import PageNavbar from "./../../filing-master/PageNavbar";
import PostAwardToolbox from "./PostAwardToolbox";
import Typography from "@mui/material/Typography";
// import SlidingDrawer from "../common/SlidingDrawer";

import _, { forEach } from "lodash";
// import filingMasters from "../../data/filingMasters.json";
import FilingMasterContext from "../../../context/filing-master/FilingMasterContext";
import AuthContext from "../../../context/auth/AuthContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import PostAwardFilingMasterComments from "./PostAwardFilingMasterComments";
import PostAwardAssociatedClient from "./PostAwardAssociatedClient";
import { FilingMaster, FilingMasterComment } from "../../../types/FilingMaster";
import useDialog from "../../common/UseDialog";
import ReactLoading from "react-loading";
import { CircularProgress } from "@mui/material";
import FilingMasterAttachment from "./../../filing-master/FilingMasterAttachment";
import PostAwardFilingMasterHistory from "./PostAwardFilingMasterHistory";
import axios from "axios";
import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
import IconButton from "@mui/material/IconButton";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import ToolTip from "../../common/ToolTip";
import { Stack } from "@mui/material";
import { DateTime } from "luxon";
import Button from "@mui/material/Button";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import { Calendar } from "primereact/calendar";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Dialog } from "primereact/dialog";
import { Button as PrimeButton } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { OverlayPanel } from "primereact/overlaypanel";
import states from "../../../data/StateLookup.json";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ClientFilingMasterContext from "../../../context/client-filing-master/ClientFilingMasterContext";
import usePermission from "../../../usePermission";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const PostAwardFilingMasterList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [jurisdiction, setJurisdiction] = useState([]);
  const [federalCategoriesLookup, setFederalCategoriesLookup] = useState([]);
  const [stateCategoriesLookup, setStateCategoriesLookup] = useState([]);
  const [categoriesLookup, setCategoriesLookup] = useState([]);
  const [stateLookup, setStateLookup] = useState([]);
  const [selectedmasters, setSelectedMasters] = useState(null);
  const [masters, setMasters] = useState([]);
  const [sortMaster, setSortMaster] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert1, setShowAlert] = useState(false);
  const [sortRecord, setSortRecord] = useState("Upcoming Due Dates");
  const [comments, setComments] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dataTableRef = useRef(null);
  const [showDeleteAlert, setShowdeleteAlert] = useState(false);
  const [value, setValue] = React.useState('1');
  const [selectedYear, setSelectedYear] = useState();
  const [currentYearVal, setCurrentYearVal] = useState()
  const [yearsList, setYearsList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const [isClearIcon, setIsClearIcon] = useState(false);
  const [categoryList, setCategoryList] = useState([])

  const [commentActivityHistory, setCommentActivityHistory] = React.useState([]);

  const [lazyLoading, setLazyLoading] = useState(false);
  const [refreshRecordList, setRefreshRecordList] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(null);
  const [recordsInView, setRecordsInView] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterChange, setIsFilterChange] = useState([]);
  const [sortingData, setSortingData] = useState({
    sortBy: "",
    sortOrder: "",
  });

  const {
    fetchConfigurations,
  } = clientFilingMasterContext;
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingId: { value: null, matchMode: FilterMatchMode.IN },
    // filingName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // filingFrequency: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // dueDayofFrequency: { value: null, matchMode: FilterMatchMode.IN },
    // stateInfo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // stateList: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // ruleInfo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // required: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // federalCategories: { value: null, matchMode: FilterMatchMode.IN },
    // stateCategories: { value: null, matchMode: FilterMatchMode.IN },
    jsidept: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //jsicontactName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jsicontactEmail: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // juristiction: { value: null, matchMode: FilterMatchMode.IN },
    // whereToFile: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    filingFrequency: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    stateList: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ruleInfo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    required: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    federalCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    stateCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    jsicontactName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    juristiction: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    whereToFile: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    dueDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [stateArray, setStateArray] = useState([]);
  const [postawardfederalArray, setPostAwardFederalArray] = useState([]);
  const [postawardstateArray, setPostAwardStateArray] = useState([]);

  const [showAttachments, setShowAttachments] = useState(false);
  const [attachments, setAttachments] = React.useState([]);

  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);

  const [expandedRows, setExpandedRows] = useState(null);

  const filingMasterContext = useContext(FilingMasterContext);
  const {
    setCurrentFilingMaster,
    getFilingMasters,
    setFilingMasters,
    filingMasters,
    postAwardFilingMasters,
    filingMasterLoaded,
    getFilingMasterComments,
    deleteDraftFilingMaster,
    createFilingMasterWorkflow,
    createPostAwardWorkflow,
    getBusinessCategories,
  } = filingMasterContext;

  // const [visibleApps, setVisibleApps] = useState(props.projects);
  const [showComments, setShowComments] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [masterCatFilter, setMasterCatFilter] = useState([]);
  const [upcomingCatFilter, setUpcomingCatFilter] = useState([]);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { authUser, userRoles } = authContext;
  const backToHome = () => {
    navigate("/");
  };

  const fetchFilingMasterComments = async (filingId) => {
    // console.log("@@Fetch FilingMasters1:", getFilingMasterComments);
    if (getFilingMasterComments) {
      // console.log("@@Fetch FilingMasters2:");
      try {
        const commentList = await getFilingMasterComments(filingId);
        setComments(() => commentList);
        // console.log("@@Fetch FilingMasters2 comments:", commentList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const fetchConfigs = async () => {
    if (fetchConfigurations) {
      let config = await fetchConfigurations();
      let startYear = config.find(
        (ele) => ele.configItem === 'StartYear'
      ).configItemValue;
      let currentYear = config.find(
        (ele) => ele.configItem === 'CurrentYear'
      ).configItemValue;
      startYear = parseInt(startYear);
      currentYear = parseInt(currentYear);
      const yearsInBetween = [];
      for (let year = startYear; year < currentYear; year++) {
        yearsInBetween.push(year);
      }
      setYearsList(yearsInBetween);
      setSelectedYear(currentYear - 1);
      setCurrentYearVal(currentYear)
      setLazyLoading(false)
      return
    }
  };

  const addComment = (rowData) => {
    // console.log("Display Comments", selectedmasters);
    // console.log("Fetching Comments", rowData);
    // if (filingId) {
    //   console.log(`Fetching Commnet for :${filingId}`);
    //   fetchFilingMasterComments(filingId);

    //   setShowComments(() => true);
    // }
    if (rowData && rowData.filingId) {
      // console.log(`Fetching Commnet for :${rowData.filingId}`);
      fetchFilingMasterComments(rowData.filingId);
      setShowComments(() => true);
      setSelectedMasters(rowData);
    } else if (selectedmasters && selectedmasters.filingId) {
      // console.log(`Fetching Commnet for :${selectedmasters.filingId}`);
      fetchFilingMasterComments(selectedmasters.filingId);
      setShowComments(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };
  const displayClients = () => {
    // console.log("Display clients", selectedmasters);
    if (selectedmasters && selectedmasters.filingId) {
      // console.log(`Fetching clients for :${selectedmasters.filingId}`);
      // fetchFilingMasterClients(selectedmasters.filingId);
      fetchFilingMasterClients(selectedmasters.filingId);
      setShowClient(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };
  const getStateName = (stateCode) => {
    const state = states.find((s) => s.code === stateCode);
    return state ? state.state : stateCode;
  };
  const formatDateMod = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const fetchMasterActive = async () => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/FilingMaster/PostAwardFilingMasterList?RecordType=PostAward`
      );
      // console.log("fetchMasterActive res", res);
      return res.data; // Assuming the response contains the data you need
    } catch (error) {
      console.error("Error fetching tracker data:", error);
      throw error; // Propagate the error
    }
  };
  const fetchMasterHistory = async () => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/FilingMaster/PostAwardFilingMasterList?RecordType=PostAward&Year=${selectedYear}`
      );
      // console.log("fetchMasterHistory res", res);
      return res.data; // Assuming the response contains the data you need
    } catch (error) {
      console.error("Error fetching tracker data:", error);
      throw error; // Propagate the error
    }
  };
  const exportExcel = async () => {
    const resList = await fetchMasterActive()
    // console.log("exportExcel", "resList", resList);
    if (resList.length === 0) {
      setAlertMessage("No data to download");
      setShowAlert(true);
      return;
    }
    // const data = sortRecord === "Clear" ? sortMaster : masters;
    // console.log("exportExcel", "data", data);

    const filteredData = resList.map(
      ({
        filingId,
        filingDescription,
        customer,
        jsidept,
        jsicontactEmail,
        createDate,
        createUser,
        updateDate,
        updateUser,
        changesInprogress,
        dueDayofFrequency,
        hasComments,
        hasAttachments,
        upcomingDueDates,
        hasAttachmentsupcomingDueDates,
        awardingAgency,
        businessCategories,
        programId,
        ...rest
      }) => rest
    );
    // console.log("exportExcel", "filteredData", filteredData);
    const updDataPA = filteredData.flatMap((item) => {
      if (item.formattedFilingperiods && item.formattedFilingperiods !== "") {
        const dueDatesArray = item.formattedFilingperiods.split(',');
        return dueDatesArray.map((date) => ({
          ...item,
          individualFilingPeriods: date.trim(),
        }));
      } else {
        return [{ ...item, individualFilingPeriods: "" }];
      }
    });

    // console.log("exportExcel", "updDataPA", updDataPA);


    import("xlsx").then((xlsx) => {
      const renamedData = updDataPA.map((item) => {
        const stateMod = getStateName(item.stateInfo)
        const programName =
          item.programInfo && item.programInfo.length > 0
            ? item.programInfo[0].programName
            : '';
        let whoMustFileFederalMod = '';
        let whoMustFileinStateMod = '';
        //Filing Period

        const _filingPeriods = item.filingPeriods.map(period => (
          `${formatDateMod(new Date(period.startDate))}-${formatDateMod(new Date(period.endDate))}`
        )).join(',\n');
        //Filing Due Date
        const filingDueDateMod = item.filingDueDate ? formatDateMod(item.filingDueDate) : "";
        if (item.juristiction === "Federal") {

          whoMustFileFederalMod = programName;

        } else if (item.juristiction === "State") {

          whoMustFileinStateMod = programName;
        }
        // console.log("exportExcel", "renamedData", "stateMod", stateMod, "whoMustFileFederalMod", whoMustFileFederalMod, "whoMustFileinStateMod", whoMustFileinStateMod, "_filingPeriods", _filingPeriods);

        return {
          "Filing Name": item.filingName,
          "Filing Frequency ": item.filingFrequency,
          "State ": stateMod,
          "Rule Info": item.ruleInfo,
          "Required ": item.required,
          "Filing Periods": _filingPeriods,
          "Who Must File Federal": whoMustFileFederalMod,
          "Who Must File in state": whoMustFileinStateMod,
          "JSI Staff": item.jsicontactName,
          "Jurisdiction ": item.juristiction,
          "Where to file": item.whereToFile,
          "RecordType": item.recordType,
          "Notes": item.notes,
        };
      });

      const worksheet = xlsx.utils.json_to_sheet(renamedData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "filingmaster");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const exportExcelHistory = async () => {
    const resList = await fetchMasterHistory()
    // console.log("resList", resList);
    if (resList.length === 0) {
      setAlertMessage("No data to download");
      setShowAlert(true);
      return;
    }
    // const data = sortRecord === "Clear" ? sortMaster : masters;
    const filteredData = resList.map(
      ({
        filingId,
        filingDescription,
        customer,
        jsidept,
        jsicontactEmail,
        createDate,
        createUser,
        updateDate,
        updateUser,
        changesInprogress,
        dueDayofFrequency,
        hasComments,
        hasAttachments,
        upcomingDueDates,
        hasAttachmentsupcomingDueDates,
        awardingAgency,
        businessCategories,
        programId,

        ...rest
      }) => rest
    );
    // console.log("filteredData", filteredData);
    const updDataPA = filteredData.flatMap((item) => {
      if (item.formattedFilingperiods && item.formattedFilingperiods !== "") {
        const dueDatesArray = item.formattedFilingperiods.split(',');
        return dueDatesArray.map((date) => ({
          ...item,
          individualFilingPeriods: date.trim(),
        }));
      } else {
        return [{ ...item, individualFilingPeriods: "" }];
      }
    });

    // console.log("updDataPA", updDataPA);


    import("xlsx").then((xlsx) => {
      const renamedData = updDataPA.map((item) => {
        const stateMod = getStateName(item.stateInfo)
        const programName =
          item.programInfo && item.programInfo.length > 0
            ? item.programInfo[0].programName
            : '';
        let whoMustFileFederalMod = '';
        let whoMustFileinStateMod = '';
        //Filing Period

        const _filingPeriods = item.filingPeriods.map(period => (
          `${formatDateMod(new Date(period.startDate))}-${formatDateMod(new Date(period.endDate))}`
        )).join(',\n')
        //Filing Due Date
        const filingDueDateMod = item.filingDueDate ? formatDateMod(item.filingDueDate) : "";
        if (item.juristiction === "Federal") {

          whoMustFileFederalMod = programName;

        } else if (item.juristiction === "State") {

          whoMustFileinStateMod = programName;
        }
        return {
          "Filing Name": item.filingName,
          "Filing Frequency ": item.filingFrequency,
          "State ": stateMod,
          "Rule Info": item.ruleInfo,
          "Required ": item.required,
          "Filing Periods": _filingPeriods,
          "Who Must File Federal": whoMustFileFederalMod,
          "Who Must File in state": whoMustFileinStateMod,
          "JSI Staff": item.jsicontactName,
          "Jurisdiction ": item.juristiction,
          "Where to file": item.whereToFile,
          "RecordType": item.recordType,
          "Notes": item.notes,
        };
      });
      const worksheet = xlsx.utils.json_to_sheet(renamedData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFileHistory(excelBuffer, "filingmaster");
    });
  };

  const saveAsExcelFileHistory = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };
  const fetchFilingMasterAttachments = async (filingId) => {
    // console.log("fetchFilingMasterAttachments props", filingId);
    try {
      const attachmentList = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachments/${filingId}`
      );
      setAttachments(() => attachmentList.data.data);
      // console.log("fetchFilingMasterAttachments res", attachmentList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  const fetchFilingMasterClients = async (filingId) => {
    // console.log("fetchFilingMasterClients props", filingId);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterList${filingId}`
      );
      const clientsData = response.data;
      // console.log("Customer", clientsData);
      const customerArray = clientsData.customer;
      setClients(customerArray);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  // console.log("CLIENTSDATA", clients);
  const displayAttachments = async (rowData) => {
    // console.log("displayAttachments", selectedmasters);
    // console.log("displayAttachments", rowData);
    if (rowData && rowData.filingId) {
      // console.log(`Fetching Attachment for :${rowData.filingId}`);
      fetchFilingMasterAttachments(rowData.filingId);
      setShowAttachments(() => true);
      setSelectedMasters(null);
    } else if (selectedmasters && selectedmasters.filingId) {
      // console.log(`Fetching Attachment for :${selectedmasters.filingId}`);
      fetchFilingMasterAttachments(selectedmasters.filingId);
      setShowAttachments(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };

  const fetchFilingMasterHistory = async (filingId) => {
    // console.log("fetchFilingMasterHistory props", filingId);
    try {
      let res = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAudit?FilingId=${filingId}`
      );
      let history = res.data.data;
      // console.log("fetchFilingMasterHistory props", res.data.data, history);
      setActivityHistory(() => history);
      // console.log("fetchFilingMasterHistory res", history);
    } catch (error) {
      console.log("@@Error:", error);
    }
    try {
      const history = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterCommentsAudit?FilingId=${filingId}`
      );
      setCommentActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };



  const displayHistory = async () => {
    // console.log("displayHistory", selectedmasters);
    if (selectedmasters && selectedmasters.filingId) {
      // console.log(`Fetching History for :${selectedmasters.filingId}`);
      fetchFilingMasterHistory(selectedmasters.filingId);
      setShowHistory(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };

  const closeComments = () => {
    // console.log("Toggling Show Filter", showComments);
    setShowComments(() => false);
  };
  const closeClient = () => {
    // console.log("Toggling Show Filter", showClient);
    setShowClient(() => false);
  };

  const closeAttachments = () => {
    // console.log("Toggling Show Filter", showAttachments);
    setShowAttachments(() => false);
  };

  const closeHistory = () => {
    // console.log("Toggling Show Filter", showHistory);
    setShowHistory(() => false);
  };
  const deleteFooterContent = (

    <div>

      <PrimeButton

        label="OK"

        icon="pi pi-check"

        onClick={() => closeDeleteAlert()}

        autoFocus

      />

    </div>

  );
  const closeDeleteAlert = () => {
    setShowdeleteAlert(false)
  }

  const handleSubmit = () => {
    // console.log("Submitting Delete....", selectedmasters);
    // deleteCategory();
    deleteMasterRecord();
  };

  const closeConfirm = () => {
    // console.log("Cancelling....");
    // setSelectedRowForDelete(null);
    // navigate("/");
  };

  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: handleSubmit,
    onClose: closeConfirm,
  });

  const { AlertDialog, showDialog: showAlert } = useDialog({});

  const handleDelete = () => {
    if (selectedmasters) {
      showDialog(
        "Confirmation",
        <Typography>
          Are you sure you want to delete this{" "}
          <Typography fontWeight="bold" component="span">
            {selectedmasters.filingName}
          </Typography>{" "}
          record?{" "}
        </Typography>,
        "confirm"
      );
    }
    else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    // console.log("_filters", _filters);
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const jusrisdictionItemTemplate = (option) => {
    // console.log("##TOptions:", option);
    return (
      <div className="flex align-items-center">
        <span>{option}</span>
      </div>
    );
  };


  const requiredArray = ["True", "False"];

  const requiredRowFilterTemplate = (options) => {
    // console.log("requiredRowFilterTemplate:", options,masterCatFilter);
    if (!masterCatFilter.required && options.filterModel.value) {
      // console.log("requiredRowFilterTemplate filterModel",options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }
    return (
      <Dropdown
        value={masterCatFilter.required || []}
        options={requiredArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          ;
          setMasterCatFilter((prev) => {
            return { ...prev, required: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const jusrisdictionArray = ["Federal", "State", "Both"];

  const jurisdictionRowFilterTemplate = (options) => {
    // console.log("##Options:", options);

    if (!masterCatFilter.jurisdiction && options.filterModel.value) {
      // console.log(" optionsfilterModel", options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <MultiSelect
        value={options.value ? options.value : ""}
        options={jusrisdictionArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##EOptions:", e);
          setMasterCatFilter((prev) => {
            return { ...prev, jurisdiction: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const customCategoryFilter = (value, filter) => {
    // console.log("##Filter value:", value);
    // console.log("##Filter filter:", filter);
    filter.filterApplyCallback(value);
    // return value.some((cateogory) => cateogory.includes(filter)); // check if at least one skill matches filter
  };

  // const CellListDisplayFederal = (rowData, field) => {
  //   const op = useRef(null);

  //   let displayFederal = Array.isArray(rowData[field])
  //     ? rowData[field].join(", ")
  //     : rowData[field];
  //   console.log("CellListDisplayFederal", field, rowData);

  //   const categories = rowData[field]
  //     ? field === "federalCategories"
  //       ? rowData.businessCategory.map((ele) => ele.businessCategoryName)
  //       : rowData.businessCategory 
  //         .filter((ele) => ele.state === rowData.stateInfo)
  //         .map((ele) => ele.businessCategoryName)
  //     : [];
  //   console.log("CellListDisplayFederal categories--", categories);

  //   let name =
  //     field === "federalCategories"
  //       ? "Who Must File Federal"
  //       : "Who Must File in State";

  //   return (
  //     <div
  //       className="tableBtn"
  //       id={`${field}+${rowData.fileTrackingId}`}
  //       style={{
  //         width: "inherit",
  //       }}
  //     >
  //       <PrimeButton
  //         onClick={(e) => op.current.toggle(e)}
  //         text
  //         style={{
  //           width: "100%",
  //         }}
  //       >
  //         <div
  //           style={{
  //             width: "inherit",
  //             overflow: "hidden",
  //             textOverflow: "ellipsis",
  //             color: "black",
  //           }}
  //         >
  //           {displayFederal}
  //         </div>
  //       </PrimeButton>
  //       <OverlayPanel ref={op} showCloseIcon style={{ padding: "0rem 1rem" }}>
  //         {name}
  //         <ScrollPanel style={{ maxWidth: "25rem", maxHeight: "20rem" }}>
  //           <ul>
  //             {categories.map((ele) => (
  //               <li>{ele}</li>
  //             ))}
  //           </ul>
  //         </ScrollPanel>
  //       </OverlayPanel>
  //     </div>
  //   );
  // };

  const cellListDisplayName = (rowData) => {
    return (
      <LightTooltip title={rowData.filingName}>
        <div
          style={{
            width: "inherit",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.filingName}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayRuleInfo = (rowData) => {
    return (
      <LightTooltip title={rowData.ruleInfo}>
        <div
          style={{
            width: "inherit",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.ruleInfo}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayNotes = (rowData) => {
    const notes = rowData.notes;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (notes) {
      const formattedText = notes.replace(
        urlRegex,
        '<a style="color: #0000FF;" href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
      );

      return (
        <LightTooltip
          title={
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: formattedText }} />
            </React.Fragment>
          }
        >
          <div
            style={{
              width: "inherit",
              margin: "0 1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </LightTooltip>
      );
    }
    return notes;
  };

  const cellListDisplayWhereToFile = (rowData) => {
    const whereToFileValue = rowData.whereToFile;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (whereToFileValue) {
      const formattedText = whereToFileValue.replace(
        urlRegex,
        '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
      );

      return (
        <LightTooltip
          title={
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: formattedText }} />
            </React.Fragment>
          }
        >
          <div
            style={{
              width: "inherit",
              margin: "0 1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </LightTooltip>
      );
    }

    return whereToFileValue;
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F1F2F6",
      color: "black",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  const cellListDisplayState = (rowData) => {
    return rowData["stateCategories"]?.join(", ");
  };

  const formatDate = (value) => {
    return value !== null
      ? value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      : null;
  };

  const cellListDisplayDueDate = (rowData) => {
    // console.log("cellEditorBody", rowData["dueDate"]);
    return formatDate(rowData.dueDate);
  };

  const masterCategoryFilter = (options, field) => {
    // console.log("OPTIONCHECK", options, "field", field);

    let optionsList = [];
    masters
      .map((item) => item[field])
      .filter(Boolean)
      .map((item) => {
        if (item.includes(",")) {
          // console.log("spliited_item", item);
          item.split(",").map((subItem) => {
            optionsList.push(subItem && subItem.trim());
          });
        } else {
          optionsList.push(item.trim());
        }
      });
    optionsList = optionsList
      .filter((item, i) => {
        return i === optionsList.lastIndexOf(item);
      })
      .sort();

    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter[field] || []}
        options={optionsList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];
          // console.log(
          //   "##federalFilterElement#:",
          //   options.filterModel.matchMode
          // );
          masters.forEach((item) => {
            if (e.value.some((category) => item[field]?.includes(category))) {
              values.push(item[field]);
            }
          });
          values = values.filter((item, i) => {
            return i === values.lastIndexOf(item);
          });
          // console.log(e.value, "federalFilterElement", values, options);
          options.filterApplyCallback(values);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter[field] || []}
        options={optionsList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];
          // console.log(
          //   "##federalFilterElement#:",
          //   options.filterModel.matchMode
          // );
          if (options.filterModel.matchMode === "notContains") {
            masters.forEach((item) => {
              if (item[field]?.includes(e.value)) {
                item[field] !== undefined && values.push(item[field]);
              }
            });
            // console.log("federalFilterElement notContains", values);
            values = values.filter((item, i) => {
              return i === values.lastIndexOf(item);
            });
          } else if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          }
          // console.log("federalFilterElement", e.value, values);
          options.filterApplyCallback(values);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const upcomingCategoryFilter = (options, field) => {
    // console.log("OPTIONCHECK", options, "field", field);

    let optionsList = [];
    sortMaster
      .map((item) => item[field])
      .filter(Boolean)
      .map((item) => {
        if (item.includes(",")) {
          //console.log("spliited_item", item);
          item.split(",").map((subItem) => {
            optionsList.push(subItem && subItem.trim());
          });
        } else {
          optionsList.push(item.trim());
        }
      });
    optionsList = optionsList
      .filter((item, i) => {
        return i === optionsList.lastIndexOf(item);
      })
      .sort();

    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={upcomingCatFilter[field] || []}
        options={optionsList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, sortMaster);
          setUpcomingCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];
          // console.log(
          //   "##federalFilterElement#:",
          //   options.filterModel.matchMode
          // );
          sortMaster.forEach((item) => {
            if (e.value.some((category) => item[field]?.includes(category))) {
              values.push(item[field]);
            }
          });
          values = values.filter((item, i) => {
            return i === values.lastIndexOf(item);
          });
          // console.log(e.value, "federalFilterElement", values, options);
          options.filterApplyCallback(values);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={upcomingCatFilter[field] || []}
        options={optionsList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, sortMaster);
          setUpcomingCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];
          // console.log(
          //   "##federalFilterElement#:",
          //   options.filterModel.matchMode
          // );
          if (options.filterModel.matchMode === "notContains") {
            sortMaster.forEach((item) => {
              if (item[field]?.includes(e.value)) {
                item[field] !== undefined && values.push(item[field]);
              }
            });
            // console.log("federalFilterElement notContains", values);
            values = values.filter((item, i) => {
              return i === values.lastIndexOf(item);
            });
          } else if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          }
          // console.log("federalFilterElement", e.value, values);
          options.filterApplyCallback(values);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const fetchFilingMasters = async () => {

    // console.log("@@Fetch FilingMasters1:", getFilingMasters);
    if (getFilingMasters) {

      // console.log("@@Fetch FilingMasters2:");
      try {
        let formData;
        if (value === "1") {
          formData = {
            recordType: "PostAward",
          };
        } else {
          formData = {
            recordType: "PostAward",
            year: selectedYear
          };
          // console.log("formData",formData);
        }
        const custList = await getFilingMasters(formData);
        // console.log("@@FilingMasters:", custList);
        const _jusrisdictions = custList.map((item) =>
          item.juristiction ? item.juristiction : ""
        );
        const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
        // console.log("@@Unique Jurisdiction:", _uniqJurisdictions);
        setJurisdiction(_uniqJurisdictions);
        //  setFilingMasterList(custList)
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
    // if (getBusinessCategories) {
    //   try {
    //     const catList = await getBusinessCategories();
    //     const catListNames = _.map(catList, "businessCategoryName");
    //     console.log("@@Unique Cateogry:", catListNames);
    //     setCategoriesLookup(catListNames);
    //   } catch (error) {
    //     console.log("@@Error:", error);
    //   }
    // }
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => {
          // console.log("dateFilterTemplate", e.value, options.index);
          return options.filterCallback(e.value, options.index);
        }}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const stateRowFilterTemplate = (options) => {
    // console.log("stateRowFilterTemplate:", options, stateArray);
    if (!masterCatFilter.stateList && options.filterModel.value) {
      // console.log(" optionsfilterModel", options.filterModel);
      options.filterModel.value = [];
      options.value = [];
    }
    return options.filterModel.matchMode === "contains" ? (
      <MultiSelect
        value={masterCatFilter.stateList || []}
        options={stateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("stateRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, stateList: e.value };
          });
          // options.filterApplyCallback(e.value);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter.stateList || []}
        options={stateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("stateRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, stateList: e.value };
          });
          let values = [];
          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          //options.filterApplyCallback(values);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "12rem" }}
      />
    );
  };
  const postawardFederalRowFilterTemplate = (options) => {
    // console.log(" postawardFederalRowFilterTemplate:", options, postawardfederalArray);
    let optionsListSorted = postawardfederalArray.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    if (!masterCatFilter.federalCategories && options.filterModel.value) {
      // console.log(" optionsfilterModel", options.filterModel);
      options.filterModel.value = [];
      options.value = [];
    }

    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter.federalCategories || []}
        options={postawardfederalArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardFederalRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, federalCategories: e.value };
          });
          //options.filterApplyCallback(e.value);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter.federalCategories || []}
        options={postawardfederalArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardFederalRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, federalCategories: e.value };
          });
          //options.filterApplyCallback(e.value);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    );
  };
  const postawardStateRowFilterTemplate = (options) => {
    // console.log(" postawardStateRowFilterTemplate:", options, postawardstateArray);
    if (!masterCatFilter.stateCategories && options.filterModel.value) {
      // console.log(" optionsfilterModel", options.filterModel);
      options.filterModel.value = [];
      options.value = [];
    }
    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter.stateCategories || []}
        options={postawardstateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardStateRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, stateCategories: e.value };
          });
          //options.filterApplyCallback(e.value);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter.stateCategories || []}
        options={postawardstateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardStateRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, stateCategories: e.value };
          });
          //options.filterApplyCallback(values);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    );
  };

  const cellEditor = (options) => {
    // console.log("##Editor Options:", options);
    return (
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Calendar
          value={options.value ? new Date(options.value) : options.value}
          onChange={(e) => options.editorCallback(e.value)}
          dateFormat="mm/dd/yy"
          placeholder="mm/dd/yyyy"
          mask="99/99/9999"
        />
      </Stack>
    );
  };

  useEffect(() => {
    if (filingMasterLoaded) {
      // console.log("Show Table Data", postAwardFilingMasters);
    }
    //eslint-disable-next-line
  }, [filingMasterLoaded]);

  const onRowSelect = (e) => {
    // console.log("@@RowSelect:", e);
  };

  // useEffect(() => {
  // let list = states.map((ele) => ele.state);
  // setStateArray(list);
  // fetchFilingMasters();

  //eslint-disable-next-line
  // }, []);

  const handleChange = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  useEffect(() => {
    // console.log("state", selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    // if (postAwardFilingMasters) {
    //   try {

    //     console.log("Orig postAwardFilingMasters...", postAwardFilingMasters);
    //     let allCats = [];
    //     let fedLookup = [];
    //     let stateCatLookup = [];
    //     let federalPostAwardLookup = [];
    //     let statePostAwardLookup = [];


    //     // const postAwardFilingMasters = postAwardFilingMasters && postAwardFilingMasters.filter(item => item.recordType === "PostAward");

    //     // console.log("postAwardFilingMasters",postAwardFilingMasters)

    //     const updFilingMasters = postAwardFilingMasters
    //       .filter((record) => !record.changesInprogress)
    //       .map((item) => {
    //         // console.log(`filingMasters ...`, item);

    //         const _stateList = getStateName(item.stateInfo);
    //         console.log("ProgramInfo", item.programInfo);
    //         const programName = item.programInfo && item.programInfo.length > 0
    //           ? item.programInfo[0].programName
    //           : '';


    //         console.log("ProgramName", programName);

    //         const toLocalDateString = (date) => {
    //           console.log("dateCheck", date);
    //           return date.toLocaleDateString('en-US', {
    //             month: '2-digit',
    //             day: '2-digit',
    //             year: 'numeric',
    //           });
    //         };
    //         const _filingPeriods = item.filingPeriods.map(period => (
    //           `${toLocalDateString(new Date(period.startDate))}-${toLocalDateString(new Date(period.endDate))}`
    //         )).join(', ');
    //         console.log("filingpCheck", _filingPeriods);
    //         // const categories = _categories.join(", ");
    //         // console.log(`filingMasters ${item.filingMasters} cat...`, categories);
    //         if (item.juristiction?.includes("Federal")) {
    //           // setFederalCategoriesLookup((old) => [...old, categories])

    //           // const _categories = _.map(
    //           //   item.businessCategory,
    //           //   "businessCategoryName"
    //           // ).join(", ");
    //           if (
    //             !federalPostAwardLookup.includes(programName) &&
    //             programName !== ""
    //           ) {
    //             // setPostAwardFederalArray([...postawardfederalArray,programName]);
    //             federalPostAwardLookup.push(programName);
    //           }
    //           allCats.push(programName);
    //           fedLookup.push(programName);
    //           setPostAwardFederalArray(federalPostAwardLookup);

    //           return {
    //             ...item,
    //             federalCategories: programName,
    //             stateList: _stateList,
    //             formattedFilingperiods: _filingPeriods

    //           };
    //           console.log("item.programName", programName);
    //         } else if (item.juristiction?.includes("Both")) {
    //           // setFederalCategoriesLookup((old) => [...old, categories])

    //           // const _categories = _.map(
    //           //   item.businessCategory,
    //           //   "businessCategoryName"
    //           // ).join(", ");
    //           if (
    //             !federalPostAwardLookup.includes(programName) &&
    //             programName !== ""
    //           ) {
    //             // setPostAwardFederalArray([...postawardfederalArray,programName]);
    //             federalPostAwardLookup.push(programName);
    //           }

    //           if (
    //             !statePostAwardLookup.includes(programName) &&
    //             programName !== ""
    //           ) {
    //             statePostAwardLookup.push(programName);
    //           }

    //           allCats.push(programName);
    //           fedLookup.push(programName);
    //           setPostAwardFederalArray(federalPostAwardLookup);
    //           stateCatLookup.push(programName);
    //           setStateCategoriesLookup((old) => [...old, programName]);
    //           setPostAwardStateArray(statePostAwardLookup);

    //           return {
    //             ...item,
    //             federalCategories: programName,
    //             stateCategories: programName,
    //             stateList: _stateList,
    //             formattedFilingperiods: _filingPeriods
    //           };
    //           console.log("item.programName", programName);
    //         } else {
    //           // console.log(
    //           //   "stateCategories: props",
    //           //   item.stateInfo,
    //           //   item.businessCategory
    //           // );

    //           const categoriesState = item.businessCategory.filter(
    //             (ele) => ele.state === item.stateInfo
    //           );
    //           console.log(
    //             "stateCategories: filter",
    //             item.filingName,
    //             categoriesState
    //           );
    //           if (
    //             !statePostAwardLookup.includes(programName) &&
    //             programName !== ""
    //           ) {
    //             // setPostAwardFederalArray([...postawardfederalArray,programName]);
    //             statePostAwardLookup.push(programName);
    //           }
    //           // const _categories = categoriesState
    //           //   .map((ele) => ele.businessCategoryName)
    //           //   .join(", ");
    //           // console.log("stateCategories: join", _categories);

    //           allCats.push(programName);
    //           stateCatLookup.push(programName);
    //           setStateCategoriesLookup((old) => [...old, programName]);
    //           setPostAwardStateArray(statePostAwardLookup);
    //           return {
    //             ...item,
    //             stateCategories: programName,
    //             stateList: _stateList,
    //             formattedFilingperiods: _filingPeriods

    //           };
    //         }
    //       });
    //     console.log("Updated filingMasters...", updFilingMasters);
    //     // setData(updCustomers);

    //     setFederalCategoriesLookup(Array.from(new Set(fedLookup)));
    //     setStateCategoriesLookup(Array.from(new Set(stateCatLookup)));
    //     setCategoriesLookup(Array.from(new Set(allCats)));


    //     // setStateCategoriesLookup((old) => new Set(old).values());
    //     setMasters(updFilingMasters);

    //   } finally {
    //     setIsLoading(false)
    //   }
    // }
    //eslint-disable-next-line
  }, [postAwardFilingMasters]);

  useEffect(() => {
    // upcomingDueDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masters]);

  // const upcomingDueDates = () => {
  //   console.log("upcomingDueDates", masters);
  //   let upcomingDates = masters.filter((ele) => {
  //     let currentYear = new Date().getFullYear();
  //     if (ele.filingPeriods.length > 0) {
  //       let isUpcomingDate = ele.filingPeriods
  //         .filter((d) => new Date(d.dueDate).getFullYear() === currentYear)
  //         .map((d) => d.dueDate);
  //       console.log(
  //         "upcomingDueDates isUpcomingDate",
  //         currentYear,
  //         isUpcomingDate,
  //         ele.upcomingDueDates
  //       );
  //       if (isUpcomingDate.length > 0) {
  //         ele.upcomingDueDates = isUpcomingDate;
  //         return ele;
  //       }
  //     }
  //   });
  //   console.log("upcomingDueDates", upcomingDates);

  //   console.log("LODASH", upcomingDates);
  //   let id = 0;
  //   let upcomingDatesFlat = _.flatMap(upcomingDates, (n) => {
  //     console.log("LODASH", n);
  //     return n.upcomingDueDates.map((ele) => {
  //       id++;
  //       // let date = toLocalTime2(ele);
  //       return {
  //         ...n,
  //         //  dueDate: ele !== null ? new Date(date) : null,
  //         dueDate: ele !== null ? new Date(ele) : null,
  //         rowId: id,
  //       };
  //     });
  //   });
  //   console.log("LODASH", upcomingDatesFlat);

  //   let sortedData = upcomingDatesFlat.sort(function (a, b) {
  //     var date1 = new Date(a.dueDate);
  //     var date2 = new Date(b.dueDate);
  //     console.log(
  //       "upcomingDueDates compare",
  //       a.dueDate,
  //       b.dueDate,
  //       date1 - date2
  //     );
  //     return date1 - date2;
  //   });
  //   console.log("upcomingDueDates", sortedData);
  //   setSortMaster(sortedData);
  // };

  // const sortRecords = () => {
  //   console.log("sortRecords", masters, sortMaster);
  //   if (sortRecord === "Upcoming Due Dates") {
  //     setSortRecord("Clear");
  //     upcomingDueDates();
  //     setMasterCatFilter([]);
  //   } else if (sortRecord === "Clear") {
  //     setSortMaster([]);
  //     setSortRecord("Upcoming Due Dates");
  //     setMasterCatFilter([]);
  //   }
  // };
  useEffect(() => {
    if (value) {
      // fetchFilingMasters();
      setRecordsInView(0);
      setIsLoading(true);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setRefreshRecordList(true);
    }
  }, [value]);
  useEffect(() => {
    if (value === "2") {
      // fetchFilingMasters()
      setRecordsInView(0);
      setIsLoading(true);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setRefreshRecordList(true);
    };
  }, [selectedYear]);

  const fetchBusinessCategories = async () => {
    // console.log("@@Fetch BusinessCategories:", getBusinessCategories);
    if (getBusinessCategories) {
      // console.log("@@Fetch BusinessCategories:");
      try {
        let list = []
        const resList = await getBusinessCategories();
        // console.log("@@BusinessCategories:", resList);
        list = resList.map((item) => item.businessCategoryName).sort()
        // console.log("@@BusinessCategories: list", list);
        setCategoryList(() => list);
        return
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const initialLoadPage = async () => {
    setLazyLoading(true)
    await fetchConfigs();
    let list = states.map((ele) => ele.state);
    setStateArray(list);
    fetchBusinessCategories()
    if (masters.length <= 0 && !lazyLoading) {
      setRecordsInView(0)
      setRefreshRecordList(true)
    }
  }

  useEffect(() => {
    initialLoadPage();
  }, []);

  const search = useRef()

  const applyGlobalFilter = (e) => {
    // console.log("applyGlobalFilter",e,search);
    let _filters = { ...filters };
    if (filters.global.value === null) {
      _filters["global"].value = search.current.value;
      setIsClearIcon(true);
    } else {
      _filters["global"].value = null;
      search.current.value = ""
      setIsClearIcon(false);
    }
    setFilters(_filters);
    onFilterChange({ filters });
  };

  const renderHeader = () => {
    return (
      <Grid container sx={{ my: 1, flexDirection: "row" }}>
        <Grid sx={{ margin: 1, flexGrow: 1 }}>
          <ArrowBackIosNewIcon
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={backToHome}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {value === '1'
              ? `Post Award List Active (${totalRecords})`
              :
              `Post Award List History (${totalRecords})`
            }
          </Typography>
        </Grid>
        {value === "2" && (
          <Grid md={3}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <InputLabel id='demo-select-small-label'>Year</InputLabel>
              <Select
                labelId='demo-select-small-label'
                id='demo-select-small'
                value={selectedYear}
                label='Year'
                onChange={(event) => handleYearChange(event)}
              >
                {yearsList.map((year) => (
                  <MenuItem value={year} key={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid md={3} sx={{ mt: 1, mb: 1, ml: 1, mr: 2 }}>
          <span style={{ display: "flex" }}>
            <InputText
              ref={search}
              inputRef={search}
              placeholder="Search by Filing Name"
              size={25}
            />
            <PrimeButton
              icon={isClearIcon ? "pi pi-times" : "pi pi-search"}
              onClick={applyGlobalFilter} />
          </span>
        </Grid>
      </Grid>
    );
  };

  const header = renderHeader();

  // const renderDueHeader = () => {
  //   return (
  //     <Grid container sx={{ my: 1, flexDirection: "row" }}>
  //       <Grid sx={{ margin: 1, flexGrow: 1 }}>
  //         <ArrowBackIosNewIcon
  //           style={{ marginRight: "10px", cursor: "pointer" }}
  //           onClick={sortRecords}
  //         />

  //       </Grid>
  //       <Grid md={3} sx={{ margin: 1 }}>
  //         <span className="p-input-icon-left">
  //           <i className="pi pi-search" />
  //           <InputText
  //             value={globalFilterValue}
  //             onChange={onGlobalFilterChange}
  //             placeholder="Search by Filing Name"
  //             size={25}
  //           />
  //         </span>
  //       </Grid>
  //       <Grid
  //         md={3}
  //         sx={{ margin: 1, pl: 2, display: "flex", justifyContent: "end" }}
  //       >
  //         <Button
  //           sx={{ p: 1, mx: 2, backgroundColor: "#0b3edf" }}
  //           color="primary"
  //           variant="contained"
  //           onClick={sortRecords}
  //         >
  //           CLEAR
  //         </Button>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  // const headerDue = renderDueHeader();
  const handleTabChange = (event, newValue) => {
    // console.log("YearCheck -TabChange", "newVal", newValue, "currentYear", currentYearVal, "selectedYear", selectedYear);
    setMasterCatFilter([]);
    setSelectedMasters(null)
    setMasters([]);
    setIsLoading(true);
    setGlobalFilterValue("");
    setIsClearIcon(false)
    setMasterCatFilter([])
    setFilters({
      ...filters,
      global: {
        ...filters.global,
        value: null,
      },
    });
    // console.log("tracktab",newValue);
    setValue(newValue);
    if (newValue === '2') {
      setIsDisabled(true);
    } else {
      setSelectedYear(currentYearVal - 1)
      setIsDisabled(false);
    }
  };
  const handleYearChange = (event) => {
    // console.log("YearCheck -yearChange", "event", event, "currentYear", currentYearVal, "selectedYear", selectedYear);
    setMasters([])
    setSelectedMasters(null)
    setGlobalFilterValue("")
    setIsClearIcon(false)
    setMasterCatFilter([])
    setIsLoading(true);
    setSelectedYear(event.target.value);
  };

  const editMasterFilling = () => {
    if (selectedmasters) {
      // console.log("Editing Master", selectedmasters);
      delete selectedmasters.stateList;
      setCurrentFilingMaster(selectedmasters);
      navigate("/postaward-master-form");
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };

  const deleteMasterRecord = async () => {
    setIsLoading(true);
    try {
      if (selectedmasters) {
        try {
          let payLoad = { ...selectedmasters };
          //payLoad.changesInprogress = undefined;
          payLoad.createUser = authUser.userId + "";
          payLoad.createDate = new Date();
          payLoad.recordType = "PostAward";

          // payLoad.programInfo = [];
          delete payLoad.stateList;

          // console.log("pay#", payLoad);
          const res = await deleteDraftFilingMaster(payLoad);
          // console.log("Draft Res:", res);
          if (res) {
            const wfPayload = {
              draftId: res.draftId,

              // currentApproverId: 1,
              WorkflowInitiatorId: authUser?.userId + "",
              createUser: authUser?.userId + "",
              createDate: new Date(),
            };
            let res3 = null;
            if (createPostAwardWorkflow) {
              res3 = await createPostAwardWorkflow(wfPayload);
            }
            if (res3) {
              // console.log("deleteMasterRecord", res3);
              setCurrentFilingMaster(null);
              // showAlert(
              //   "Success",
              //   `Successfully submitted delete request for ${selectedmasters.filingName}`,
              //   "success"
              // );

              // closeDialog();
              setShowdeleteAlert(true);
            }
          } else {
            setAlertMessage(
              <Typography>
                Unexpected Error occured while deleting the
                <Typography fontWeight="bold" component="span">
                  {selectedmasters.filingName},
                </Typography>{" "}
              </Typography>
            );
            closeDialog();
          }
        } catch (error) {
          console.log("@@Error:", error);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const FilingCommentButton = ({ rowData }) => {
    const isPermissionEnabled = usePermission("PostAwardMasterList", "comments", rowData?.customer[0]?.customerId);
    return (
      // <ToolTip
      //   title={
      //     rowData.hasComments ? "Comments available" : "Comments unavailable"
      //   }
      // >
      <IconButton color="primary" disabled={!rowData.hasComments || !isPermissionEnabled}>
        <InsertCommentRoundedIcon onClick={() => addComment(rowData)} />
      </IconButton>
      // </ToolTip>
    );
  };
  const FilingAttachmentButton = ({ rowData }) => {
    const isPermissionEnabled = usePermission("PostAwardMasterList", "referenceDocument", rowData?.customer[0]?.customerId);
    return (
      <IconButton color="primary" disabled={!rowData.hasAttachments || !isPermissionEnabled}>
        <SnippetFolderIcon onClick={() => displayAttachments(rowData)} />
      </IconButton>
    );
  };

  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
        "dd-MMM-yyyy hh:mm:ss",
        { zone: "UTC" }
      )
        .toLocal()
        .toFormat("dd-MMM-yy");
      //  console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const toLocalTime2 = (date) => {
    // console.log("toLocalTime", date);
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-LL-yyyy hh:mm:ss"),
        "dd-LL-yyyy hh:mm:ss",
        { zone: "UTC" }
      ).toFormat("yyyy-LL-dd");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const rowExpansionTemplate = (data) => {
    // console.log("rowExpansionTemplate", data.filingPeriods);
    if (data.filingPeriods.length > 0) {
      let tableData = data.filingPeriods.map((ele) => {
        let startDate = new Date(ele.startDate);
        let endDate = new Date(ele.endDate);
        const toLocalDateString = date => date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

        let fp = `${toLocalDateString(startDate)} - ${toLocalDateString(endDate)}`;
        let dueDate = ele.dueDate !== null ? toLocalDateString(new Date(ele.dueDate)) : null;
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
          dueDate: dueDate,
        };
      });
      return (
        <div className="filingPeriodTable p-3" style={{ width: "20vw" }}>
          {/* <h5>Filing Period for {data.filingName}</h5> */}
          <DataTable value={tableData}>
            <Column field="filingPeriod" header="Filing Period"></Column>
            {/* <Column field="dueDate" header="DueDate"></Column> */}
          </DataTable>
        </div>
      );
    } else {
      return (
        <div className="filingPeriodTable p-3">
          <h5>No Filing Period for {data.filingName}</h5>
        </div>
      );
    }
  };

  const rowExpand = (event) => {
    // console.log("rowExpand", event, expandedRows);
    setExpandedRows(event.data);
  };
  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );
  const closeAlert = () => {
    setShowAlert(false);
    setRefreshRecordList(true);
  };

  const tableRef = useRef(null);
  const historyTableRef = useRef(null);
  const pageSize = 100;

  // lazy load function
  const loadDataLazy = async (event) => {
    // console.log("loadDataLazy props", recordsInView, event);

    //Virtual scroll callback
    const vScroll = value === "1"
      ? tableRef.current.getVirtualScroller()
      : historyTableRef.current.getVirtualScroller()
    let range = vScroll.getRenderedRange();
    let { first, last } = range.viewport;
    // console.log("loadDataLazy vScroll", vScroll, range.viewport);

    let firstVal = recordsInView * pageSize;
    // console.log(
    //   "loadDataLazy totalRecords",
    //   firstVal,
    //   totalRecords,
    //   lazyLoading,
    //   masters.length,
    // );


    if (firstVal < totalRecords && first > masters.length - 10 & !lazyLoading) {
      //scroll down
      // setIsLoading(true);
      setLazyLoading(true);
      let lastVal = firstVal + pageSize;
      // console.log("loadDataLazy d firstVal", firstVal, lastVal);

      //get next data
      let records = await loadRecordsList(firstVal, lastVal, "down");
      // console.log("loadDataLazy d record", records);
      if (records) {
        // console.log("loadDataLazy d scrollInView", recordsInView * 100);

        //scroll to next records
        vScroll.scrollInView(recordsInView * 100);
        setRecordsInView((old) => old + 1);
        setLazyLoading(false);
        setIsLoading(false);
      }
    } else if (last < 10 && recordsInView > 1 && !lazyLoading) {
      // scroll up
      let firstVal = (recordsInView - 3) * pageSize;
      let lastVal = firstVal + pageSize;
      if (firstVal >= 0) {
        // setIsLoading(true);
        setLazyLoading(true);
        // console.log("loadDataLazy u firstVal", firstVal, lastVal);

        //get prev data
        let records = await loadRecordsList(firstVal, lastVal, "up");
        // console.log("loadDataLazy u record", records);
        if (records) {
          // console.log("loadDataLazy u scrollInView", (recordsInView - 3) * 100);

          //scroll to prev records
          vScroll.scrollToIndex(16);
          setRecordsInView((old) => old - 1);
          setLazyLoading(false);
          setIsLoading(false);
        }
      }
    }
  };

  // api call for lazy load
  const loadRecordsList = async (first, last, direction) => {
    // console.log("loadRecordsList props", first, last, direction);
    setIsLoading(true);
    let url = `${API_BASE_URL}/FilingMaster/PostAwardFilingMasterListByPagination?StartRow=${first + 1}&EndRow=${last}&recordType=PostAward`

    //console.log("loadRecordsList selectedYear", value, selectedYear);
    if (value === "2" && selectedYear !== "") {
      url = `${url}&Year=${selectedYear}`;
    }

    // console.log("loadRecordsList fliter/sort",isFilterChange,sortingData);
    if (isFilterChange !== undefined && isFilterChange.length > 0) {
      // url filter parameters
      isFilterChange.forEach((ele) => {
        url = `${url}&${ele.name}=${ele.val}${ele.searchType ? `&${ele.searchType}=${ele.searchTypeVal}` : ``
          }`;
      });
    }

    if (
      sortingData &&
      typeof sortingData === "object" &&
      sortingData.sortBy !== "" &&
      sortingData.sortOrder !== ""
    ) {
      // url sort parameters
      let sortParameter = getSortData(sortingData.sortBy);
      // console.log("getSortData", sortParameter)
      let order = sortingData.sortOrder === 1 ? "Ascending" : "Descending"
      url = `${url}&${sortParameter.SortByParameter}=${sortParameter.SortByVal}&${sortParameter.SortOrderParameter}=${order}`;
    }

    // console.log("loadRecordsList url", url);
    try {
      //get data
      const res = await axios.get(url);
      // console.log("loadRecordsList res", res.data);

      if (res.data) {
        let ogList = masters.map((ele) => ele)
        setMasters([]);
        await modifyList(res.data.items, direction, ogList);
        setTotalRecords(res.data.totalItems);
        // setMasters(res.data.items)
        return res.data.items;
      } else {
        return false;
      }
    } catch (err) {
      console.log("loadRecordsList err", err);
    }
  };

  //maping records for lazy load
  const modifyList = (list, direction, ogList) => {
    // console.log("modifyList", list, direction);
    if (list.length > 0) {
      //list has data

      // setOgList(og)    

      // console.log("loadDataLazy og", ogList, masters);
      // console.log("modifyList og", ogList, masters);

      let allCats = [];
      let fedLookup = [];
      let stateCatLookup = [];
      let federalPostAwardLookup = [];
      let statePostAwardLookup = [];

      //formating list
      const updFilingMasters = list
        .map((item, i) => {
          // console.log(`filingMasters ...`, item);

          const _stateList = getStateName(item.stateInfo);
          // console.log("ProgramInfo", item.programInfo);
          const programName = item.programInfo && item.programInfo.length > 0
            ? item.programInfo[0].programName
            : '';


          // console.log("ProgramName", programName);

          const toLocalDateString = (date) => {
            // console.log("dateCheck", date);
            return date.toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });
          };
          const _filingPeriods = item.filingPeriods.map(period => (
            `${toLocalDateString(new Date(period.startDate))}-${toLocalDateString(new Date(period.endDate))}`
          )).join(', ');
          // console.log("filingpCheck", _filingPeriods);
          // const categories = _categories.join(", ");
          // console.log(`filingMasters ${item.filingMasters} cat...`, categories);
          if (item.juristiction?.includes("Federal")) {
            // setFederalCategoriesLookup((old) => [...old, categories])

            // const _categories = _.map(
            //   item.businessCategory,
            //   "businessCategoryName"
            // ).join(", ");
            if (
              !federalPostAwardLookup.includes(programName) &&
              programName !== ""
            ) {
              // setPostAwardFederalArray([...postawardfederalArray,programName]);
              federalPostAwardLookup.push(programName);
            }
            allCats.push(programName);
            fedLookup.push(programName);
            setPostAwardFederalArray(federalPostAwardLookup);

            return {
              ...item,
              federalCategories: programName,
              stateList: _stateList,
              formattedFilingperiods: _filingPeriods,
              masterId: `${i}${item.filingId}`,
            };
            // console.log("item.programName", programName);
          } else if (item.juristiction?.includes("Both")) {
            // setFederalCategoriesLookup((old) => [...old, categories])

            // const _categories = _.map(
            //   item.businessCategory,
            //   "businessCategoryName"
            // ).join(", ");
            if (
              !federalPostAwardLookup.includes(programName) &&
              programName !== ""
            ) {
              // setPostAwardFederalArray([...postawardfederalArray,programName]);
              federalPostAwardLookup.push(programName);
            }

            if (
              !statePostAwardLookup.includes(programName) &&
              programName !== ""
            ) {
              statePostAwardLookup.push(programName);
            }

            allCats.push(programName);
            fedLookup.push(programName);
            setPostAwardFederalArray(federalPostAwardLookup);
            stateCatLookup.push(programName);
            setStateCategoriesLookup((old) => [...old, programName]);
            setPostAwardStateArray(statePostAwardLookup);

            return {
              ...item,
              federalCategories: programName,
              stateCategories: programName,
              stateList: _stateList,
              formattedFilingperiods: _filingPeriods,
              masterId: `${i}${item.filingId}`,
            };
            // console.log("item.programName", programName);
          } else {
            // console.log(
            //   "stateCategories: props",
            //   item.stateInfo,
            //   item.businessCategory
            // );

            const categoriesState = item.businessCategory.filter(
              (ele) => ele.state === item.stateInfo
            );
            // console.log(
            //   "stateCategories: filter",
            //   item.filingName,
            //   categoriesState
            // );
            if (
              !statePostAwardLookup.includes(programName) &&
              programName !== ""
            ) {
              // setPostAwardFederalArray([...postawardfederalArray,programName]);
              statePostAwardLookup.push(programName);
            }
            // const _categories = categoriesState
            //   .map((ele) => ele.businessCategoryName)
            //   .join(", ");
            // console.log("stateCategories: join", _categories);

            allCats.push(programName);
            stateCatLookup.push(programName);
            setStateCategoriesLookup((old) => [...old, programName]);
            setPostAwardStateArray(statePostAwardLookup);
            return {
              ...item,
              stateCategories: programName,
              stateList: _stateList,
              formattedFilingperiods: _filingPeriods,
              masterId: `${i}${item.filingId}`,
            };
          }
        });
      // console.log("modifyList updFilingMasters", updFilingMasters);


      let updFilingMastersList
      if (direction === "down") {
        //scroll down
        if (masters.length >= 200) {
          //list > 200

          //slice 2 half of list
          let unloadList = ogList.slice(
            ogList.length - pageSize,
            ogList.length
          );
          // console.log("modifyList dw unloadList", unloadList);

          //add new data
          updFilingMastersList = [...unloadList, ...updFilingMasters];
          // console.log("modifyList dw updFilingMastersList", updFilingMastersList);

          let list = updFilingMastersList.map((ele) => ele)
          setMasters(list);
          setFilingMasters(list)
          // console.log("modifyList list", list);
        } else {
          //list < 200

          // add new data to list
          updFilingMastersList = [...ogList, ...updFilingMasters];
          // console.log("modifyList dw updFilingMastersList", updFilingMastersList);
          let list = updFilingMastersList.map((ele) => ele)
          setMasters(list);
          setFilingMasters(list)
          // console.log("modifyList list", list);

        }
      } else if (direction === "up") {
        //scroll up
        if (masters.length >= 200) {
          //list > 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updFilingMasters, ...unloadList];
          // console.log("modifyList up updFilingMastersList", updFilingMastersList);

          let list = updFilingMastersList.map((ele) => ele)
          setMasters(list);
          setFilingMasters(list)
          // console.log("modifyList list", list);

        } else {
          //list < 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updFilingMasters, ...unloadList];
          // console.log("modifyList up updFilingMastersList", updFilingMastersList);
          let list = updFilingMastersList.map((ele) => ele)
          setMasters(list);
          setFilingMasters(list)
          // console.log("modifyList list", list);

        }
      }

      setFederalCategoriesLookup(Array.from(new Set(fedLookup)));
      setStateCategoriesLookup(Array.from(new Set(stateCatLookup)));
      setCategoriesLookup(Array.from(new Set(allCats)));
      setRefreshRecordList(false)
      setIsLoading(false);
      return updFilingMastersList
    } else {
      //list empty
      // console.log("modifyList empty",list)
      setIsLoading(false);
      setMasters([]);
      setFilingMasters([])
      return []

    }
  };

  //table filter change
  const onFilterChange = async (e) => {
    // console.log("onFilterChange props", e.filters);
    let filterChange = [];
    if (e.filters.global.value !== "" && e.filters.global.value !== null) {
      //global filter
      filterChange.push({
        name: `SearchByFilingName`,
        val: e.filters.global.value,
        searchType: `FilingNameSearchType`,
        searchTypeVal: getSearchType("contains"),
      });
    } else {
      //map filter event
      Object.keys(e.filters).forEach((col) => {
        // console.log("onFilterChange col", col, e.filters[col]);
        if (
          e.filters[col].constraints &&
          e.filters[col].constraints[0].value !== null
        ) {
          if (col === "stateList") {
            Array.isArray(e.filters[col].constraints[0].value)
              ? e.filters[col].constraints[0].value.map((ele, i) => {
                let changeItem = {
                  name: `SearchByState`,
                  val: getStateCode(ele),
                };
                if (i + 1 === e.filters[col].constraints[0].value.length) {
                  changeItem.searchType = `StateSearchType`;
                  changeItem.searchTypeVal = getSearchType(
                    e.filters[col].constraints[0].matchMode
                  );
                }
                filterChange.push(changeItem);
              })
              : filterChange.push({
                name: `SearchByState`,
                val: getStateCode(e.filters[col].constraints[0].value),
                searchType: `StateSearchType`,
                searchTypeVal: getSearchType(
                  e.filters[col].constraints[0].matchMode
                ),
              });
          } else if (col === "juristiction") {
            filterChange.push({
              name: `SearchByJurisdiction`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "required") {
            filterChange.push({
              name: `SearchByRequired`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "jsicontactName") {
            filterChange.push({
              name: `SearchByJSIStaff`,
              val: e.filters[col].constraints[0].value,
              searchType: `JSIStaffSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "dueDate") {
            filterChange.push({
              name: `SearchByDueDate`,
              val: formatFilterDate(e.filters[col].constraints[0].value),
              searchType: `DueDateSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "federalCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `WhoMustFileFederal`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileFederalSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "stateCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              let changeItem = {
                name: `WhoMustFileInState`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileInStateSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else {
            filterChange.push({
              name: `SearchBy${col}`,
              val: e.filters[col].constraints[0].value,
              searchType: `${col}SearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          }
        }
      });
    }

    //set filter
    // console.log("onFilterChange filterChange", filterChange);
    handleSetFilterVariable(filterChange);
  };

  const formatFilterDate = (date) => {
    // console.log("formatFilterDate", date);
    let getDate = new Date(date);
    let formatDate = `${getDate.getFullYear()}-${getDate.getMonth() + 1
      }-${getDate.getDate()}`;
    // console.log("formatFilterDate formatDate", getDate.getFullYear(), formatDate);
    return formatDate;
  };

  const getStateCode = (stateName) => {
    // console.log("getStateCode", stateName, states);
    const state = states.find((s) => s.state === stateName);
    return state ? state.code : stateName;
  };

  const matchModeVal = [
    { name: "contains", val: "Contains" },
    { name: "in", val: "Contains" },
    { name: "notContains", val: "NotContains" },
    { name: "equals", val: "EqualTo" },
    { name: "dateIs", val: "DateIs" },
    { name: "dateIsNot", val: "DateIsNot" },
    { name: "dateBefore", val: "DateIsBefore" },
    { name: "dateAfter", val: "DateIsAfter" },
  ];

  const getSearchType = (matchMode) => {
    // console.log("getSearchType props", matchMode);
    return matchModeVal.find((ele) => ele.name === matchMode).val;
  };

  const getSortData = (val) => {
    // console.log("getSortData props", val);
    if (val === "dueDate") {
      return {
        SortByParameter: "SortByDuedate",
        SortByVal: "DueDate",
        SortOrderParameter: "SortOrderDuedate"
      }
    } else if (val === "stateList") {
      return {
        SortByParameter: "SortByStateInfo",
        SortByVal: "StateInfo",
        SortOrderParameter: "SortOrderStateInfo"
      }
    } else if (val === "federalCategories" || val === "stateCategories") {
      return {
        SortByParameter: "SortByBusinessCategory",
        SortByVal: "BusinessCategory",
        SortOrderParameter: "SortOrderBusinessCategory"
      }
    } else {
      return {
        SortByParameter: `SortBy${val.charAt(0).toUpperCase() + val.slice(1)}`,
        SortByVal: val.charAt(0).toUpperCase() + val.slice(1),
        SortOrderParameter: `SortOrder${val.charAt(0).toUpperCase() + val.slice(1)}`
      }
    }
  }

  //set filter
  const handleSetFilterVariable = (filterChange) => {
    // console.log("handleSetFilterVariable props", filterChange);
    setIsFilterChange(() => filterChange);
    setRecordsInView(0);
    setIsLoading(true);
    setMasters([]);
  };

  //table sort change
  const handleSort = async (e) => {
    // console.log("handleSort props",e ,e.sortField)
    if (e.sortField !== "") {
      setSortingData(() => ({
        sortBy: e.sortField,
        sortOrder: e.sortOrder,
      }));
      setRecordsInView(0);
      setMasters([]);
      setIsLoading(true)
    }
  }


  useEffect(() => {
    // console.log("filter/sort change", isFilterChange, sortingData, !lazyLoading, !refreshRecordList);
    //on filter/sort change
    if (!lazyLoading && !refreshRecordList) {
      const sortAndFilterApiCall = async () => {
        // console.log("LOAD Check 4");
        let records = await loadRecordsList(0, 100, "down");
        // console.log("recordsCheck", records);
        setIsLoading(false);
        setRecordsInView(1)
      };
      sortAndFilterApiCall();
    }
  }, [isFilterChange, sortingData]);

  const refreshRecords = async () => {
    // console.log("refreshRecords")

    //Virtual scroll callback
    const vScroll = value === "1"
      ? tableRef.current.getVirtualScroller()
      : historyTableRef.current.getVirtualScroller()
    let range = vScroll.getRenderedRange();
    console.log("refreshRecords vScroll range", vScroll, range);

    let records
    console.log("refreshRecords refreshDetails", refreshDetails);
    if (refreshDetails?.pageInView || refreshDetails?.noOfPages) {
      let first = refreshDetails.pageInView * pageSize
      records = await loadRecordsList(first, first + refreshDetails?.noOfPages * pageSize, "down");
    } else {
      records = await loadRecordsList(0, pageSize, "down");
    }

    if (records) {
      if (refreshDetails?.viewport && refreshDetails?.noOfPages) {
        setRecordsInView((old) => old + refreshDetails?.noOfPages);
        // setRefreshDetails(null)
      } else {
        setRecordsInView((old) => old + 1);
      }
      setLazyLoading(false)
    }
  }


  useEffect(() => {
    // console.log("refreshRecordList,", refreshRecordList, !lazyLoading);
    if (refreshRecordList && !lazyLoading) {
      setLazyLoading(true);
      refreshRecords()
    }

  }, [refreshRecordList])

  const handleRrefreshList = () => {
    //Virtual scroll callback
    const vScroll = value === "1"
      ? tableRef.current.getVirtualScroller()
      : historyTableRef.current.getVirtualScroller()
    let range = vScroll.getRenderedRange();
    console.log("refreshTable vScroll", vScroll, range);

    let pageInView
    let noOfPages
    if (masters.length > 100) {
      pageInView = recordsInView - 2
      noOfPages = 2
    } else {
      pageInView = recordsInView - 1
      noOfPages = 1
    }
    let clientSelected = masters
    console.log("refreshTable clientSelected", masters, clientSelected);

    setRecordsInView(pageInView)
    setRefreshDetails({
      viewport: range.viewport,
      pageInView,
      noOfPages,
      clientSelected
    })

    setIsLoading(true);
    setIsFilterChange([]);
    setSortingData({ sortBy: "", sortOrder: "" });
    setMasters([])
    setRefreshRecordList(true)
  }

  useEffect(() => {
    // console.log("Total Records updated:", totalRecords);
  }, [totalRecords]);

  useEffect(() => {
    // console.log("recordsInView,", recordsInView);
  }, [recordsInView]);

  useEffect(() => {
    // console.log("lazyLoading", lazyLoading) 
  }, [lazyLoading])

  useEffect(() => {
    //  console.log("isLoading", isLoading) 
  }, [isLoading])

  useEffect(() => {
    // console.log("masters,", masters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masters, masters.length, JSON.stringify(masters)]);

  useEffect(() => {
    // console.log("masters,", masters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filingMasters]);

  return (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert1}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid sx={{ height: "100%", width: "93%" }}>
        <Grid sm={24} sx={{ height: "100%", width: "93%" }}>
          {/* {filingMasterLoaded && (
          <DataTableBase
            columns={columns}
            data={data}
            selectableRows
            onSelectedRowsChange={handleChange}
          />
        )} */}
          {
            <PostAwardFilingMasterComments
              show={showComments}
              dockAt="right"
              draft={selectedmasters}
              comments={comments}
              handleClose={closeComments}
              refreshTable={handleRrefreshList}
            />
          }
          {
            <PostAwardAssociatedClient
              show={showClient}
              dockAt="right"
              draft={selectedmasters}
              clients={clients}
              handleClose={closeClient}
            />
          }

          {/* <CircularProgress
          style={{
            //position: "absolute",
            //  width: "3rem",
            marginLeft: "36rem",
            marginTop: "10rem",
            color: "#0000FF",
          }}
          // type="spin"
          height="100%"
          position="center"
          place-items="center"
        /> */}

          <div
            className="card"
            style={{
              maxWidth: "90vw",
              padding: "1rem",
              margin: "1rem",
              background: "#FFF",
              borderRadius: "2%",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange}>
                  <Tab label='POSTAWARD Master Active' value='1' disabled={isLoading} />
                  <Tab label='POSTAWARD Master History' value='2' disabled={isLoading} />
                </TabList>
              </Box>
              <TabPanel value='1'>
                <DataTable
                  key="list"
                  scrollable
                  resizableColumns
                  columnResizeMode="expand"
                  showGridlines
                  className="frozen-mf-pa"
                  value={filingMasters}
                  selection={selectedmasters}
                  onSelectionChange={(e) => setSelectedMasters(e.value)}
                  dataKey='masterId'
                  onRowSelect={onRowSelect}
                  loading={isLoading}
                  onRowToggle={(e) => rowExpand(e)}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={expandedRows}
                  // paginator
                  // rows={10}
                  // rowsPerPageOptions={[5, 10, 25, 50]}
                  filters={filters}
                  // filterDisplay="row"
                  globalFilterFields={["filingName"]}
                  header={header}
                  ref={tableRef}
                  virtualScrollerOptions={{
                    onScroll: loadDataLazy,
                    itemSize: 70,
                    delay: 0,
                    scrollHeight: "550px",
                    appendOnly: true,

                  }}
                  scrollHeight="550px"
                  onFilter={(e) => onFilterChange(e)}
                  sortField={sortingData.sortBy}
                  sortOrder={sortingData.sortOrder}
                  onSort={(e) => handleSort(e)}
                >
                  <Column expander={true} style={{ width: "5rem" }} />
                  <Column
                    selectionMode="single"
                    //headerStyle={{ width: "3rem" }}
                    style={{ width: "1rem", margin: "0" }}
                  >
                    Select
                  </Column>
                  {/* <Column
                headerStyle={{ width: "2rem" }}
                // header="Attachemts"
                body={(rowData) => <FilingCommentButton rowData={rowData} />}
              ></Column>
              <Column
                headerStyle={{ width: "2rem" }}
                // header="Attachemts"
                body={(rowData) => (
                  <FilingAttachmentButton rowData={rowData} />
                )}
              ></Column> */}
                  <Column
                    header="Indicators"
                    style={{ textAlign: "center" }}
                    body={(rowData) => (
                      <>
                        <Stack direction="row">
                          <FilingCommentButton rowData={rowData} />

                          <FilingAttachmentButton rowData={rowData} />
                        </Stack>
                      </>
                    )}
                  ></Column>
                  <Column
                    field="filingName"
                    header="Filing Name"
                    sortable
                    filter
                    frozen
                    className="filingNameColumn-pa"
                    filterPlaceholder="Search by Filing Name"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "11rem", maxWidth: "25rem" }}
                    body={(rowData) => cellListDisplayName(rowData)}
                  ></Column>
                  {/* <Column
              {/* <Column
                field='filingDescription'
                header='Filing Description'
               sortable
                filter
                filterPlaceholder='Search by Description'
                style={{ minWidth: "25rem" }}
              ></Column> */}
                  <Column
                    field="filingFrequency"
                    header="Filing Frequency"
                    filter
                    frozen
                    filterPlaceholder="Search by Frequency"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "15rem" }}
                  ></Column>
                  <Column
                    field="stateList"
                    header="State"
                    sortable
                    filter
                    frozen
                    filterField="stateList"
                    filterPlaceholder="Search by State"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    filterMenuStyle={{ width: "15rem" }}
                    filterElement={stateRowFilterTemplate}
                    onFilterClear={() => setMasterCatFilter([])}
                    style={{ minWidth: "13rem" }}
                    className="column-small"
                  ></Column>
                  <Column
                    field="ruleInfo"
                    header="Rule Info"
                    filter
                    filterPlaceholder="Search by Rule Info"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "15rem", maxWidth: "15rem" }}
                    body={(rowData) => cellListDisplayRuleInfo(rowData)}
                  ></Column>
                  <Column
                    field="required"
                    header="Required?"
                    filter
                    filterField="required"
                    filterElement={requiredRowFilterTemplate}
                    onFilterClear={() => setUpcomingCatFilter([])}
                    showFilterMatchModes={false}
                    style={{ minWidth: "13rem" }}
                    className="column-small"
                    filterPlaceholder="Search by Required"
                    showAddButton={false}
                    showFilterOperator={false}
                  ></Column>
                  <Column
                    field="federalCategories"
                    header="Who Must File Federal"
                    // sortable
                    filter
                    filterField="federalCategories"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    filterElement={postawardFederalRowFilterTemplate}
                    onFilterMatchModeChange={() => setMasterCatFilter([])}
                    filterPlaceholder="Search by Cateogry"
                    //   filterElement={(options) =>
                    //     masterCategoryFilter(options, "federalCategories")
                    //   }
                    onFilterClear={() => setMasterCatFilter([])}
                    style={{ minWidth: "18rem", maxWidth: "18rem" }}
                  //showFilterMenu={false}
                  //filterMenuStyle={{ width: "14rem" }}
                  //  showClearButton={false}
                  // filterFunction={customCategoryFilter}
                  ></Column>
                  <Column
                    field="stateCategories"
                    header="Who Must File in State"
                    // sortable
                    filter
                    filterField="stateCategories"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    onFilterMatchModeChange={() => setMasterCatFilter([])}
                    filterElement={postawardStateRowFilterTemplate}
                    filterPlaceholder="Search by Cateogry"

                    onFilterClear={() => setMasterCatFilter([])}
                    style={{ minWidth: "18rem", maxWidth: "18rem" }}
                  //showFilterMenu={false}
                  //filterMenuStyle={{ width: "14rem" }}
                  //  showClearButton={false}
                  ></Column>
                  {/* <Column
                field='jsidept'
                header='JSI Dept'
                filter
                filterPlaceholder='Search by dept'
                style={{ minWidth: "20rem" }}
              ></Column> */}
                  <Column
                    field="jsicontactName"
                    header="JSI Staff"
                    filter
                    filterPlaceholder="Search by JSI Staff"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "12rem" }}
                  ></Column>
                  {/* <Column
                field='jsicontactEmail'
                header='JSI Contact Email'
                filter
                filterPlaceholder='Search by contactEmail'
                style={{ minWidth: "20rem" }}
              ></Column> */}
                  <Column
                    field="juristiction"
                    header="Jurisdiction"
                    sortable
                    filterField="juristiction"
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                    //showFilterMenu={false}
                    // filterMenuStyle={{ width: "10rem" }}
                    style={{ minWidth: "10rem" }}
                    filter
                    filterElement={jurisdictionRowFilterTemplate}
                  ></Column>
                  <Column
                    field="whereToFile"
                    header="Where to file"
                    filter
                    body={(rowData) => cellListDisplayWhereToFile(rowData)}
                    filterPlaceholder="Search by Where To File"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    filterMenuStyle={{ width: "10rem" }}
                    style={{ minWidth: "13rem", maxWidth: "13rem" }}
                  ></Column>
                  <Column
                    field="notes"
                    header="Notes"
                    headerStyle={{ minWidth: "20rem", maxWidth: "20rem" }}
                    style={{ minWidth: "45rem", maxWidth: "45rem" }}
                    body={(rowData) => cellListDisplayNotes(rowData)}
                  ></Column>
                </DataTable>
              </TabPanel>
              <TabPanel value='2'>
                <DataTable
                  key="list"
                  scrollable
                  resizableColumns
                  columnResizeMode="expand"
                  showGridlines
                  className="frozen-mf-pa"
                  value={filingMasters}
                  selection={selectedmasters}
                  onSelectionChange={(e) => setSelectedMasters(e.value)}
                  dataKey='masterId'
                  onRowSelect={onRowSelect}
                  loading={isLoading}
                  onRowToggle={(e) => rowExpand(e)}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={expandedRows}
                  // paginator
                  // rows={10}
                  // rowsPerPageOptions={[5, 10, 25, 50]}
                  filters={filters}
                  // filterDisplay="row"
                  globalFilterFields={["filingName"]}
                  header={header}
                  ref={historyTableRef}
                  virtualScrollerOptions={{
                    onScroll: loadDataLazy,
                    itemSize: 70,
                    delay: 0,
                    scrollHeight: "550px",
                    appendOnly: true,

                  }}
                  scrollHeight="550px"
                  onFilter={(e) => onFilterChange(e)}
                  sortField={sortingData.sortBy}
                  sortOrder={sortingData.sortOrder}
                  onSort={(e) => handleSort(e)}
                >
                  <Column expander={true} style={{ width: "5rem" }} />
                  <Column
                    selectionMode="single"
                    //headerStyle={{ width: "3rem" }}
                    style={{ width: "1rem", margin: "0" }}
                  >
                    Select
                  </Column>
                  {/* <Column
                headerStyle={{ width: "2rem" }}
                // header="Attachemts"
                body={(rowData) => <FilingCommentButton rowData={rowData} />}
              ></Column>
              <Column
                headerStyle={{ width: "2rem" }}
                // header="Attachemts"
                body={(rowData) => (
                  <FilingAttachmentButton rowData={rowData} />
                )}
              ></Column> */}
                  <Column
                    header="Indicators"
                    style={{ textAlign: "center" }}
                    body={(rowData) => (
                      <>
                        <Stack direction="row">
                          <FilingCommentButton rowData={rowData} />

                          <FilingAttachmentButton rowData={rowData} />
                        </Stack>
                      </>
                    )}
                  ></Column>
                  <Column
                    field="filingName"
                    header="Filing Name"
                    sortable
                    filter
                    frozen
                    className="filingNameColumn-pa"
                    filterPlaceholder="Search by Filing Name"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "11rem", maxWidth: "25rem" }}
                    body={(rowData) => cellListDisplayName(rowData)}
                  ></Column>
                  {/* <Column
              {/* <Column
                field='filingDescription'
                header='Filing Description'
               sortable
                filter
                filterPlaceholder='Search by Description'
                style={{ minWidth: "25rem" }}
              ></Column> */}
                  <Column
                    field="filingFrequency"
                    header="Filing Frequency"
                    filter
                    frozen
                    filterPlaceholder="Search by Frequency"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "15rem" }}
                  ></Column>
                  <Column
                    field="stateList"
                    header="State"
                    sortable
                    filter
                    frozen
                    filterField="stateList"
                    filterPlaceholder="Search by State"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    filterMenuStyle={{ width: "15rem" }}
                    filterElement={stateRowFilterTemplate}
                    onFilterClear={() => setMasterCatFilter([])}
                    style={{ minWidth: "13rem" }}
                    className="column-small"
                  ></Column>
                  <Column
                    field="ruleInfo"
                    header="Rule Info"
                    filter
                    filterPlaceholder="Search by Rule Info"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "15rem", maxWidth: "15rem" }}
                    body={(rowData) => cellListDisplayRuleInfo(rowData)}
                  ></Column>
                  <Column
                    field="required"
                    header="Required?"
                    filter
                    filterField="required"
                    filterElement={requiredRowFilterTemplate}
                    onFilterClear={() => setUpcomingCatFilter([])}
                    showFilterMatchModes={false}
                    style={{ minWidth: "13rem" }}
                    className="column-small"
                    filterPlaceholder="Search by Required"
                    showAddButton={false}
                    showFilterOperator={false}
                  ></Column>
                  <Column
                    field="federalCategories"
                    header="Who Must File Federal"
                    // sortable
                    filter
                    filterField="federalCategories"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    filterElement={postawardFederalRowFilterTemplate}
                    onFilterMatchModeChange={() => setMasterCatFilter([])}
                    filterPlaceholder="Search by Cateogry"
                    //   filterElement={(options) =>
                    //     masterCategoryFilter(options, "federalCategories")
                    //   }
                    onFilterClear={() => setMasterCatFilter([])}
                    style={{ minWidth: "18rem", maxWidth: "18rem" }}
                  //showFilterMenu={false}
                  //filterMenuStyle={{ width: "14rem" }}
                  //  showClearButton={false}
                  // filterFunction={customCategoryFilter}
                  ></Column>
                  <Column
                    field="stateCategories"
                    header="Who Must File in State"
                    // sortable

                    filter
                    filterField="stateCategories"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    onFilterMatchModeChange={() => setMasterCatFilter([])}
                    filterElement={postawardStateRowFilterTemplate}
                    filterPlaceholder="Search by Cateogry"

                    onFilterClear={() => setMasterCatFilter([])}
                    style={{ minWidth: "18rem", maxWidth: "18rem" }}
                  //showFilterMenu={false}
                  //filterMenuStyle={{ width: "14rem" }}
                  //  showClearButton={false}
                  ></Column>
                  {/* <Column
                field='jsidept'
                header='JSI Dept'
                filter
                filterPlaceholder='Search by dept'
                style={{ minWidth: "20rem" }}
              ></Column> */}
                  <Column
                    field="jsicontactName"
                    header="JSI Staff"
                    filter
                    filterPlaceholder="Search by JSI Staff"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "12rem" }}
                  ></Column>
                  {/* <Column
                field='jsicontactEmail'
                header='JSI Contact Email'
                filter
                filterPlaceholder='Search by contactEmail'
                style={{ minWidth: "20rem" }}
              ></Column> */}
                  <Column
                    field="juristiction"
                    header="Jurisdiction"
                    sortable
                    filterField="juristiction"
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                    //showFilterMenu={false}
                    // filterMenuStyle={{ width: "10rem" }}
                    style={{ minWidth: "10rem" }}
                    filter
                    filterElement={jurisdictionRowFilterTemplate}
                  ></Column>
                  <Column
                    field="whereToFile"
                    header="Where to file"
                    filter
                    body={(rowData) => cellListDisplayWhereToFile(rowData)}
                    filterPlaceholder="Search by Where To File"
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    filterMenuStyle={{ width: "10rem" }}
                    style={{ minWidth: "13rem", maxWidth: "13rem" }}
                  ></Column>
                  <Column
                    field="notes"
                    header="Notes"
                    headerStyle={{ minWidth: "20rem", maxWidth: "20rem" }}
                    style={{ minWidth: "45rem", maxWidth: "45rem" }}
                    body={(rowData) => cellListDisplayNotes(rowData)}
                  ></Column>
                </DataTable>
              </TabPanel>
            </TabContext>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          height: "100%",
          width: "5%",
        }}
      >
        <div
          style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}
        >
          <Item
            sx={{
              height: "100%",
            }}
          >
            <PostAwardToolbox
              isLoading={isLoading}
              addComment={addComment}
              draft={selectedmasters?.customer[0]?.customerId}
              editMasterFilling={editMasterFilling}
              deleteMasterRecord={handleDelete}
              displayAttachments={displayAttachments}
              displayHistory={displayHistory}
              displayClients={displayClients}
              downloadData={exportExcel}
              downloadDataHistory={exportExcelHistory}
              isDisabled={sortRecord === "Clear"}
              isTabValue={value === "2" ? true : false}

            />
          </Item>
        </div>
      </Grid>

      <ConfirmDialog />

      <AlertDialog />
      {
        <FilingMasterAttachment
          show={showAttachments}
          dockAt="right"
          tracker={selectedmasters}
          attachments={attachments}
          handleClose={closeAttachments}
        />
      }
      {
        <PostAwardFilingMasterHistory
          show={showHistory}
          dockAt="right"
          tracker={selectedmasters}
          commentActivityHistory={commentActivityHistory}
          activityHistory={activityHistory}
          handleClose={closeHistory}
        />
      }
      <Dialog
        header="Info"
        visible={showDeleteAlert}
        onHide={() => closeDeleteAlert()}
        footer={deleteFooterContent}
      >

        <p className="m-0">
          {`Successfully submitted delete request for `}
          <strong>{selectedmasters && selectedmasters.filingName}</strong>
        </p>

      </Dialog>

      <AlertDialog />
    </Fragment>
  );
};

export default PostAwardFilingMasterList;
