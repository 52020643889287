import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
const PageNotFound = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vh",
        display: "flex",
        justifyContent: "center",
        padding: "40px",
        paddingLeft: "20px",
        marginLeft: "70px",
      }}
    >
      <Typography variant="h6">Page Not Found</Typography>
    </Box>
  );
};

export default PageNotFound;
