import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  //useMemo,
  // useCallback,
  useRef,
} from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
// import DataTableBase from "../common/DataTableBase";
// import PageNavbar from "./PageNavbar";
import Toolbox from "./Toolbox";
import Typography from "@mui/material/Typography";
// import SlidingDrawer from "../common/SlidingDrawer";
// import ApproveFilingMaster from "./ApproveFilingMaster";
import _, { forEach } from "lodash";
// import filingMasters from "../../data/filingMasters.json";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import AuthContext from "../../context/auth/AuthContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator, FilterService } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import FilingMasterComments from "./FilingMasterComments";
import AssociatedClient from "./AssociatedClient";
// import { FilingMaster, FilingMasterComment } from "../../types/FilingMaster";
import useDialog from "../common/UseDialog";
//import ReactLoading from "react-loading";
//import { CircularProgress } from "@mui/material";
import FilingMasterAttachment from "./FilingMasterAttachment";
import FilingMasterHistory from "./FilingMasterHistory";
import axios from "axios";
import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
import IconButton from "@mui/material/IconButton";
//import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
//import ToolTip from "../common/ToolTip";
import { Stack } from "@mui/material";
import { DateTime } from "luxon";
import Button from "@mui/material/Button";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import { Calendar } from "primereact/calendar";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Dialog } from "primereact/dialog";
import { Button as PrimeButton } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { OverlayPanel } from "primereact/overlaypanel";
import states from "../../data/StateLookup.json";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import ClientFilingMasterContext from '../../context/client-filing-master/ClientFilingMasterContext';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import usePermission from "../../usePermission";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const FilingMasterList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [jurisdiction, setJurisdiction] = useState([]);
  const [federalCategoriesLookup, setFederalCategoriesLookup] = useState([]);
  const [stateCategoriesLookup, setStateCategoriesLookup] = useState([]);
  const [categoriesLookup, setCategoriesLookup] = useState([]);
  //const [stateLookup, setStateLookup] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert1, setShowAlert] = useState(false);
  const [selectedmasters, setSelectedMasters] = useState(null);
  let [masters, setMasters] = useState([]);
  const [sortMaster, setSortMaster] = useState([]);
  const [sortRecord, setSortRecord] = useState("Upcoming Due Dates");
  const [comments, setComments] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState('');
  const [currentYearVal, setCurrentYearVal] = useState("")
  const [yearsList, setYearsList] = useState([]);
  // const [filingMasterList, setFilingMasterList] = useState([]);
  const [value, setValue] = React.useState('1');
  const [isDisabled, setIsDisabled] = useState(false);
  const dataTableRef = useRef(null);
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const [isClearIcon, setIsClearIcon] = useState(false);
  const [categoryList, setCategoryList] = useState([])

  const [lazyLoading, setLazyLoading] = useState(false);
  const [refreshRecordList, setRefreshRecordList] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(null);
  const [recordsInView, setRecordsInView] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterChange, setIsFilterChange] = useState([]);
  const [sortingData, setSortingData] = useState({
    sortBy: "",
    sortOrder: "",
  });
  const [upcomingDueDateVal, setupcomingDueDateVal] = useState("");


  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingId: { value: null, matchMode: FilterMatchMode.IN },
    // filingName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // filingFrequency: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // dueDayofFrequency: { value: null, matchMode: FilterMatchMode.IN },
    // stateInfo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // stateList: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // ruleInfo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // required: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // federalCategories: { value: null, matchMode: FilterMatchMode.IN },
    // stateCategories: { value: null, matchMode: FilterMatchMode.IN },
    jsidept: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //jsicontactName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jsicontactEmail: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // juristiction: { value: null, matchMode: FilterMatchMode.IN },
    // whereToFile: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    filingFrequency: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    stateList: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ruleInfo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    required: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    federalCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    stateCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    jsicontactName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    juristiction: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    whereToFile: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    dueDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [stateArray, setStateArray] = useState([]);
  const [showAttachments, setShowAttachments] = useState(false);
  const [attachments, setAttachments] = React.useState([]);

  const [showHistory, setShowHistory] = useState(false);
  const [activityHistory, setActivityHistory] = React.useState([]);
  const [commentActivityHistory, setCommentActivityHistory] = React.useState([]);
  const [expandedRows, setExpandedRows] = useState(null);

  const filingMasterContext = useContext(FilingMasterContext);
  const {
    setCurrentFilingMaster,
    getFilingMasters,
    setFilingMasters,
    filingMasters,
    filingMasterLoaded,
    getFilingMasterComments,
    deleteDraftFilingMaster,
    createFilingMasterWorkflow,
    getBusinessCategories,
  } = filingMasterContext;
  const {
    fetchConfigurations,
  } = clientFilingMasterContext;
  // const [visibleApps, setVisibleApps] = useState(props.projects);
  const [showComments, setShowComments] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [masterCatFilter, setMasterCatFilter] = useState([]);
  const [upcomingCatFilter, setUpcomingCatFilter] = useState([]);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { authUser, userRoles } = authContext;
  const backToHome = () => {
    navigate("/");
  };

  FilterService.register("customFilter_not", (item, filterBy) => {
    // console.log("customFilter_not", item, filterBy);
    return item !== undefined && !item?.includes(filterBy);
  });

  const fetchFilingMasterComments = async (filingId) => {
    // console.log("@@Fetch FilingMasters1:", getFilingMasterComments);
    if (getFilingMasterComments) {
      // console.log("@@Fetch FilingMasters2:");
      try {
        const commentList = await getFilingMasterComments(filingId);
        setComments(() => commentList);
        // console.log("@@Fetch FilingMasters2 comments:", commentList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };
  const fetchConfigs = async () => {
    if (fetchConfigurations) {
      let config = await fetchConfigurations();
      console.log("loadRecordsList config", config)
      let startYear = config.find(
        (ele) => ele.configItem === 'StartYear'
      ).configItemValue;
      let currentYear = config.find(
        (ele) => ele.configItem === 'CurrentYear'
      ).configItemValue;
      startYear = parseInt(startYear);
      currentYear = parseInt(currentYear);
      console.log("loadRecordsList currentYear", currentYear)
      const yearsInBetween = [];
      for (let year = startYear; year < currentYear; year++) {
        yearsInBetween.push(year);
      }
      setYearsList(yearsInBetween);
      setSelectedYear(currentYear - 1);
      setCurrentYearVal(currentYear)
      setLazyLoading(false)
      return
    }
  };
  const addComment = (rowData) => {
    // console.log("Display Comments", selectedmasters);
    // console.log("Fetching Comments", rowData);
    // if (filingId) {
    //   console.log(`Fetching Commnet for :${filingId}`);
    //   fetchFilingMasterComments(filingId);

    //   setShowComments(() => true);
    // }
    if (rowData && rowData.filingId) {
      // console.log(`Fetching Commnet for :${rowData.filingId}`);
      fetchFilingMasterComments(rowData.filingId);
      setShowComments(() => true);
      setSelectedMasters(rowData);
    } else if (selectedmasters && selectedmasters.filingId) {
      // console.log(`Fetching Commnet for :${selectedmasters.filingId}`);
      fetchFilingMasterComments(selectedmasters.filingId);
      setShowComments(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };

  const displayClients = () => {
    // console.log("Display clients", selectedmasters);
    if (selectedmasters && selectedmasters.filingId) {
      // console.log(`Fetching clients for :${selectedmasters.filingId}`);
      // fetchFilingMasterClients(selectedmasters.filingId);
      fetchFilingMasterClients(selectedmasters.filingId);
      setShowClient(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };
  const getStateName = (stateCode) => {
    const state = states.find((s) => s.code === stateCode);
    const stateName = state ? state.state : stateCode;
    console.log("getStateName", "states", states, "stateCode", stateCode, "state", state, "stateName", stateName);
    return stateName;
  };
  const fetchMasterActive = async () => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterList?RecordType=TrackNow`
      );
      console.log("fetchMasterActive res", res);
      return res.data; // Assuming the response contains the data you need
    } catch (error) {
      console.error("Error fetching tracker data:", error);
      throw error; // Propagate the error
    }
  };
  const fetchMasterHistory = async () => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterList?RecordType=TrackNow&Year=${selectedYear}`
      );
      // console.log("fetchMasterHistory res", res);
      return res.data; // Assuming the response contains the data you need
    } catch (error) {
      console.error("Error fetching tracker data:", error);
      throw error; // Propagate the error
    }
  };

  const exportExcel = async () => {
    const resList = await fetchMasterActive()
    console.log("resList", resList);
    if (resList.length === 0) {
      setAlertMessage("No data to download");
      setShowAlert(true);
      return;
    }
    // const data = sortRecord === "Clear" ? sortMaster : masters;
    const filteredData = resList.map(
      ({
        filingId,
        filingDescription,
        customer,
        jsidept,
        jsicontactEmail,
        createDate,
        createUser,
        updateDate,
        updateUser,
        changesInprogress,
        dueDayofFrequency,
        hasComments,
        hasAttachments,
        upcomingDueDates,
        hasAttachmentsupcomingDueDates,
        filingPeriods,
        awardingAgency,
        businessCategories,
        programId,
        programInfo,
        ...rest
      }) => rest
    );
    // console.log("filteredData", filteredData);

    const updData = filteredData.flatMap((item) => {
      if (item.formattedDueDates && item.formattedDueDates !== "") {
        const dueDatesArray = item.formattedDueDates.split(',');
        return dueDatesArray.map((date) => ({
          ...item,
          individualDueDate: date.trim(),
        }));
      } else {
        return [{ ...item, individualDueDate: "" }];
      }
    });

    console.log("Export excel updData", updData);

    import("xlsx").then((xlsx) => {

      const renamedData = updData.map((item) => {
        const stateMod = getStateName(item.stateInfo)
        const dueDateMod = item.dueDate ? formatDateSheet(item.dueDate) : "";
        let whoMustFileFederalMod = '';
        let whoMustFileinStateMod = '';

        if (item.juristiction === "Federal") {

          whoMustFileFederalMod = item.businessCategory.map(obj => obj.businessCategoryName).join(', ');

        } else if (item.juristiction === "State") {

          const filteredCategories = item.businessCategory.filter(category => category.state === item.stateInfo);
          whoMustFileinStateMod = filteredCategories.map(obj => obj.businessCategoryName).join(', ');

        }
        console.log("renamedData", "stateMod", stateMod, "dueDateMod", dueDateMod, "whoMustFileFederalMod", whoMustFileFederalMod, "whoMustFileinStateMod", whoMustFileinStateMod);
        return {
          "Filing Name": item.filingName,
          "Filing Frequency ": item.filingFrequency,
          "State ": stateMod,
          "Rule Info": item.ruleInfo,
          "Required ": item.required,
          "Due Dates": dueDateMod,
          "Who Must File Federal": whoMustFileFederalMod,
          "Who Must File in state": whoMustFileinStateMod,
          "JSI Staff": item.jsicontactName,
          "Jurisdiction ": item.juristiction,
          "Where to file": item.whereToFile,
          "RecordType": item.recordType,
          "Notes": item.notes,

        };
      });
      const worksheet = xlsx.utils.json_to_sheet(renamedData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "filingmaster");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };
  const exportExcelHistory = async () => {
    const resList = await fetchMasterHistory()
    if (resList.length === 0) {
      setAlertMessage("No data to download");
      setShowAlert(true);
      return;
    }

    //const data = sortRecord === "Clear" ? sortMaster : masters;
    const filteredData = resList.map(
      ({
        filingId,
        filingDescription,
        customer,
        jsidept,
        jsicontactEmail,
        createDate,
        createUser,
        updateDate,
        updateUser,
        changesInprogress,
        dueDayofFrequency,
        hasComments,
        hasAttachments,
        upcomingDueDates,
        hasAttachmentsupcomingDueDates,
        filingPeriods,
        awardingAgency,
        businessCategories,
        programId,
        programInfo,
        ...rest
      }) => rest
    );
    // console.log("filteredData", filteredData);

    const updData = filteredData.flatMap((item) => {
      if (item.formattedDueDates && item.formattedDueDates !== "") {
        const dueDatesArray = item.formattedDueDates.split(',');
        return dueDatesArray.map((date) => ({
          ...item,
          individualDueDate: date.trim(),
        }));
      } else {
        return [{ ...item, individualDueDate: "" }];
      }
    });

    // console.log("updData", updData);

    import("xlsx").then((xlsx) => {
      const renamedData = updData.map((item) => {
        const stateMod = getStateName(item.stateInfo)
        const dueDateMod = item.dueDate ? formatDateSheet(item.dueDate) : "";
        let whoMustFileFederalMod = '';
        let whoMustFileinStateMod = '';

        if (item.juristiction === "Federal") {

          whoMustFileFederalMod = item.businessCategory.map(obj => obj.businessCategoryName).join(', ');

        } else if (item.juristiction === "State") {

          const filteredCategories = item.businessCategory.filter(category => category.state === item.stateInfo);
          whoMustFileinStateMod = filteredCategories.map(obj => obj.businessCategoryName).join(', ');

        }
        // console.log("renamedData", "stateMod", stateMod, "dueDateMod", dueDateMod, "whoMustFileFederalMod", whoMustFileFederalMod, "whoMustFileinStateMod", whoMustFileinStateMod);
        return {
          "Filing Name": item.filingName,
          "Filing Frequency ": item.filingFrequency,
          "State ": stateMod,
          "Rule Info": item.ruleInfo,
          "Required ": item.required,
          "Due Dates": dueDateMod,
          "Who Must File Federal": whoMustFileFederalMod,
          "Who Must File in state": whoMustFileinStateMod,
          "JSI Staff": item.jsicontactName,
          "Jurisdiction ": item.juristiction,
          "Where to file": item.whereToFile,
          "RecordType": item.recordType,
          "Notes": item.notes,
        };
      });
      const worksheet = xlsx.utils.json_to_sheet(renamedData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "filingmaster");
    });
  };

  const saveAsExcelFileHistory = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };
  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );
  const closeAlert = () => {
    setShowAlert(false);
    setRefreshRecordList(true);
  };

  const fetchFilingMasterAttachments = async (filingId) => {
    // console.log("fetchFilingMasterAttachments props", filingId);
    try {
      const attachmentList = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAttachments/${filingId}`
      );
      setAttachments(() => attachmentList.data.data);
      // console.log("fetchFilingMasterAttachments res", attachmentList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  const fetchFilingMasterClients = async (filingId) => {
    // console.log("fetchFilingMasterClients props", filingId);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterList${filingId}`
      );
      const clientsData = response.data;
      // console.log("Customer", clientsData);
      const customerArray = clientsData.customer;
      setClients(customerArray);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  // console.log("CLIENTSDATA", clients);
  const displayAttachments = async (rowData) => {
    // console.log("displayAttachments", selectedmasters);
    // console.log("displayAttachments", rowData);
    if (rowData && rowData.filingId) {
      // console.log(`Fetching Attachment for :${rowData.filingId}`);
      fetchFilingMasterAttachments(rowData.filingId);
      setShowAttachments(() => true);
      setSelectedMasters(rowData);
    } else if (selectedmasters && selectedmasters.filingId) {
      // console.log(`Fetching Attachment for :${selectedmasters.filingId}`);
      fetchFilingMasterAttachments(selectedmasters.filingId);
      setShowAttachments(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };

  const fetchFilingMasterHistory = async (filingId) => {
    // console.log("fetchFilingMasterHistory props", filingId);
    try {
      let res = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterAudit?FilingId=${filingId}`
      );
      let history = res.data.data;
      // console.log("fetchFilingMasterHistory props", res.data.data, history);
      setActivityHistory(() => history);
      // console.log("fetchFilingMasterHistory res", history);
    } catch (error) {
      console.log("@@Error:", error);
    }

    try {
      const history = await axios.get(
        `${API_BASE_URL}/FilingMaster/FilingMasterCommentsAudit?FilingId=${filingId}`
      );
      setCommentActivityHistory(() => history.data.data);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };

  const displayHistory = async () => {
    // console.log("displayHistory", selectedmasters);
    if (selectedmasters && selectedmasters.filingId) {
      // console.log(`Fetching History for :${selectedmasters.filingId}`);
      fetchFilingMasterHistory(selectedmasters.filingId);
      setShowHistory(() => true);
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };

  const closeComments = () => {
    // console.log("Toggling Show Filter", showComments);
    setShowComments(() => false);
  };
  const closeClient = () => {
    // console.log("Toggling Show Filter", showClient);
    setShowClient(() => false);
  };

  const closeAttachments = () => {
    // console.log("Toggling Show Filter", showAttachments);
    setShowAttachments(() => false);
  };

  const closeHistory = () => {
    // console.log("Toggling Show Filter", showHistory);
    setShowHistory(() => false);
  };


  const handleTabChange = (event, newValue) => {
    setMasterCatFilter([]);
    setSelectedMasters(null)
    setMasters([]);
    setIsLoading(true);
    setGlobalFilterValue('');
    setupcomingDueDateVal("")
    setFilters({
      ...filters,
      global: {
        ...filters.global,
        value: null,
      },
    });
    console.log("tracktab", newValue);
    setValue(newValue);
    if (newValue === '2') {
      setIsDisabled(true);
    } else {
      setSelectedYear(currentYearVal - 1)
      setIsDisabled(false);
    }
  };

  const handleSubmit = () => {
    // console.log("Submitting Delete....", selectedmasters);
    // deleteCategory();
    deleteMasterRecord();
  };

  const closeConfirm = () => {
    // console.log("Cancelling....");
    // setSelectedRowForDelete(null);
    // navigate("/");
  };

  const {
    AlertDialog: ConfirmDialog,
    showDialog,
    closeDialog,
  } = useDialog({
    onSubmit: handleSubmit,
    onClose: closeConfirm,
  });

  const { AlertDialog, showDialog: showAlert } = useDialog({});

  const handleDelete = () => {
    showDialog(
      "Confirmation",
      <Typography>
        Are you sure you want to delete this{" "}
        <Typography fontWeight="bold" component="span">
          {selectedmasters.filingName}
        </Typography>{" "}
        record?{" "}
      </Typography>,
      "confirm"
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    // console.log("_filters", _filters);
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const jusrisdictionItemTemplate = (option) => {
    // console.log("##TOptions:", option);
    return (
      <div className='flex align-items-center'>
        <span>{option}</span>
      </div>
    );
  };

  const requiredArray = ["True", "False"];

  const requiredRowFilterTemplate = (options) => {
    // console.log("requiredRowFilterTemplate:", options,masterCatFilter);
    if (!masterCatFilter.required && options.filterModel.value) {
      // console.log("requiredRowFilterTemplate filterModel",options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }
    return (
      <Dropdown
        value={masterCatFilter.required || []}
        options={requiredArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          ;
          setMasterCatFilter((prev) => {
            return { ...prev, required: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const upcommingRequiredRowFilterTemplate = (options) => {
    // console.log("upcommingRequiredRowFilterTemplate:", options,upcomingCatFilter);

    if (!upcomingCatFilter.required && options.filterModel.value) {
      // console.log(
      //   "upcommingRequiredRowFilterTemplate optionsfilterModel",
      //   options.filterModel
      // );
      options.filterModel.value = null;
      options.value = null;
    }
    return (
      <Dropdown
        value={upcomingCatFilter.required || []}
        options={requiredArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setUpcomingCatFilter((prev) => {
            return { ...prev, required: e.value };
          });
          options.filterCallback(e.value, options.index);
          // options.filterCallback(e.value, 'contains');
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const jusrisdictionArray = ["Federal", "State"];

  const jurisdictionRowFilterTemplate = (options) => {
    // console.log("jurisdictionRowFilterTemplate props", options,masterCatFilter);

    if (!masterCatFilter.jurisdiction && options.filterModel.value) {
      // console.log(" optionsfilterModel", options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <Dropdown
        value={masterCatFilter.jurisdiction || []}
        options={jusrisdictionArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##EOptions:", e);
          setMasterCatFilter((prev) => {
            return { ...prev, jurisdiction: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
    // options.filterModel.matchMode === 'in' ? (
    //   <MultiSelect
    //     value={masterCatFilter.jurisdiction || []}
    //     options={jurisdiction}
    //     itemTemplate={jusrisdictionItemTemplate}
    //     onChange={(e) => {
    //       console.log('##EOptions:', e);

    //       setMasterCatFilter((prev) => {
    //         return { ...prev, jurisdiction: e.value };
    //       });
    //       options.filterApplyCallback(e.value);
    //     }}
    //     placeholder='Any'
    //     maxSelectedLabels={1}
    //     className='p-column-filter'
    //     style={{ minWidth: '14rem', maxWidth: '14rem' }}
    //   />
    // ) : (

    // );
  };

  const upcomingJurisdictionRowFilterTemplate = (options) => {
    // console.log("upcomingJurisdictionRowFilterTemplate", options , upcomingCatFilter);

    if (!upcomingCatFilter.jurisdiction && options.filterModel.value) {
      // console.log(" optionsfilterModel", options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <Dropdown
        value={upcomingCatFilter.jurisdiction || []}
        options={jusrisdictionArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##EOptions:", e);
          setUpcomingCatFilter((prev) => {
            return { ...prev, jurisdiction: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
    //  options.filterModel.matchMode === 'in' ? (
    //     <MultiSelect
    //       value={upcomingCatFilter.jurisdiction || []}
    //       options={jurisdiction}
    //       itemTemplate={jusrisdictionItemTemplate}
    //       onChange={(e) => {
    //         console.log('##EOptions:', e);
    //         setUpcomingCatFilter((prev) => {
    //           return { ...prev, jurisdiction: e.value };
    //         });
    //         options.filterApplyCallback(e.value);
    //       }}
    //       placeholder='Any'
    //       maxSelectedLabels={1}
    //       className='p-column-filter'
    //       style={{ minWidth: '14rem', maxWidth: '14rem' }}
    //     />
    //   ) : (

    //   );
  };

  // const customCategoryFilter = (value, filter) => {
  //   console.log("##Filter value:", value);
  //   console.log("##Filter filter:", filter);
  //   filter.filterApplyCallback(value);
  //   // return value.some((cateogory) => cateogory.includes(filter)); // check if at least one skill matches filter
  // };

  const CellListDisplayFederal = (rowData, field) => {
    const op = useRef(null);

    let displayFederal = Array.isArray(rowData[field])
      ? rowData[field].join(", ")
      : rowData[field];
    // console.log("CellListDisplayFederal", field, rowData);

    const categories = rowData[field]
      ? field === "federalCategories"
        ? rowData.businessCategory.map((ele) => ele.businessCategoryName)
        : rowData.businessCategory
          .filter((ele) => ele.state === rowData.stateInfo)
          .map((ele) => ele.businessCategoryName)
      : [];
    // console.log("CellListDisplayFederal categories--", categories);

    let name =
      field === "federalCategories"
        ? "Who Must File Federal"
        : "Who Must File in State";

    return (
      <div
        className='tableBtn'
        id={`${field}+${rowData.fileTrackingId}`}
        style={{
          width: "inherit",
        }}
      >
        <PrimeButton
          onClick={(e) => op.current.toggle(e)}
          text
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              width: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "black",
            }}
          >
            {displayFederal}
          </div>
        </PrimeButton>
        <OverlayPanel ref={op} showCloseIcon style={{ padding: "0rem 1rem" }}>
          {name}
          <ScrollPanel style={{ maxWidth: "25rem", maxHeight: "20rem" }}>
            <ul>
              {categories.map((ele) => (
                <li>{ele}</li>
              ))}
            </ul>
          </ScrollPanel>
        </OverlayPanel>
      </div>
    );
  };

  const cellListDisplayName = (rowData) => {
    return (
      <LightTooltip title={rowData.filingName}>
        <div
          style={{
            width: "inherit",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.filingName}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayRuleInfo = (rowData) => {
    return (
      <LightTooltip title={rowData.ruleInfo}>
        <div
          style={{
            width: "inherit",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.ruleInfo}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayNotes = (rowData) => {
    const notes = rowData.notes;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (notes) {
      const formattedText = notes.replace(
        urlRegex,
        '<a style="color: #0000FF;" href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
      );

      return (
        <LightTooltip
          title={
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: formattedText }} />
            </React.Fragment>
          }
        >
          <div
            style={{
              width: "inherit",
              margin: "0 1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </LightTooltip>
      );
    }
    return notes;
  };

  const cellListDisplayWhereToFile = (rowData) => {
    const whereToFileValue = rowData.whereToFile;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (whereToFileValue) {
      const formattedText = whereToFileValue.replace(
        urlRegex,
        '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
      );

      return (
        <LightTooltip
          title={
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: formattedText }} />
            </React.Fragment>
          }
        >
          <div
            style={{
              width: "inherit",
              margin: "0 1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </LightTooltip>
      );
    }

    return whereToFileValue;
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F1F2F6",
      color: "black",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  const cellListDisplayState = (rowData) => {
    return rowData["stateCategories"]?.join(", ");
  };

  const formatDate = (value) => {
    return value !== null && value !== ''
      ? value.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      : null;
  };
  const formatDateSheet = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const cellListDisplayDueDate = (rowData) => {
    // console.log("cellEditorBody", rowData["dueDate"]);
    return formatDate(rowData.dueDate);
  };

  const masterCategoryFilter = (options, field) => {
    // console.log("OPTIONCHECK", options, "field", field);

    // let optionsList = [];
    // masters
    //   .map((item) => item?.[field])
    //   .filter(Boolean)
    //   .map((item) => {
    //     if (item.includes(",")) {
    //       // console.log("spliited_item", item);
    //       item.split(",").map((subItem) => {
    //         optionsList.push(subItem && subItem.trim());
    //       });
    //     } else {
    //       optionsList.push(item.trim());
    //     }
    //   });
    // optionsList = optionsList
    //   .filter((item, i) => {
    //     return i === optionsList.lastIndexOf(item);
    //   })
    //   .sort();

    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          // let values = [];
          // console.log(
          //   '##federalFilterElement#:',
          //   options.filterModel.matchMode
          // );
          // masters.forEach((item) => {
          //   if (e.value.some((category) => item[field]?.includes(category))) {
          //     values.push(item[field]);
          //   }
          // });
          // values = values.filter((item, i) => {
          //   return i === values.lastIndexOf(item);
          // });
          // console.log(e.value, 'federalFilterElement', values, options);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];
          // console.log(
          //   "##federalFilterElement#:",
          //   options.filterModel.matchMode
          // );
          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          // console.log("federalFilterElement", e.value, values);
          options.filterCallback(values, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const upcomingCategoryFilter = (options, field) => {
    // console.log("OPTIONCHECK", options, "field", field);

    // let optionsList = [];
    // masters
    //   .map((item) => item?.[field])
    //   .filter(Boolean)
    //   .map((item) => {
    //     if (item.includes(",")) {
    //       //console.log("spliited_item", item);
    //       item.split(",").map((subItem) => {
    //         optionsList.push(subItem && subItem.trim());
    //       });
    //     } else {
    //       optionsList.push(item.trim());
    //     }
    //   });
    // optionsList = optionsList
    //   .filter((item, i) => {
    //     return i === optionsList.lastIndexOf(item);
    //   })
    //   .sort();

    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={upcomingCatFilter[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, masters);
          setUpcomingCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          // let values = [];
          // console.log(
          //   '##federalFilterElement#:',
          //   options.filterModel.matchMode
          // );
          // masters.forEach((item) => {
          //   if (e.value.some((category) => item[field]?.includes(category))) {
          //     values.push(item[field]);
          //   }
          // });
          // values = values.filter((item, i) => {
          //   return i === values.lastIndexOf(item);
          // });
          // console.log(e.value, 'federalFilterElement', values, options);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={upcomingCatFilter[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("##federalFilterElement#:", options, e, sortMaster);
          setUpcomingCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];
          // console.log(
          //   "##federalFilterElement#:",
          //   options.filterModel.matchMode
          // );
          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          // console.log("federalFilterElement", e.value, values);
          options.filterCallback(values, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const search = useRef()

  const applyGlobalFilter = (e) => {
    // console.log("applyGlobalFilter",e,search);
    let _filters = { ...filters };
    if (filters.global.value === null) {
      _filters["global"].value = search.current.value;
      setIsClearIcon(true);
    } else {
      _filters["global"].value = null;
      search.current.value = ""
      setIsClearIcon(false);
    }
    setFilters(_filters);
    onFilterChange({ filters });
  };

  const fetchFilingMasters = async () => {
    // console.log("ValueCheck", value, "selYear", selectedYear);
    if (getFilingMasters) {
      // console.log("@@Fetch FilingMasters2:");
      try {
        let formData;
        if (value === "1") {
          formData = {
            recordType: "TrackNow",
          };
        } else {
          formData = {
            recordType: "TrackNow",
            year: selectedYear
          };
          // console.log("formData",formData);
        }
        const custList = await getFilingMasters(formData);
        // console.log("@@FilingMasters:", custList);
        const _jusrisdictions = custList.map((item) =>
          item.juristiction ? item.juristiction : ""
        );
        const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
        // console.log("@@Unique Jurisdiction:", _uniqJurisdictions);
        // setFilingMasterList(custList)
        setJurisdiction(_uniqJurisdictions);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }

  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => {
          // console.log("dateFilterTemplate", e.value, options.index);
          return options.filterCallback(e.value, options.index);
        }}
        dateFormat='mm/dd/yy'
        placeholder='mm/dd/yyyy'
        mask='99/99/9999'
      />
    );
  };

  const stateRowFilterTemplate = (options) => {
    if (!masterCatFilter.stateList && options.filterModel.value) {
      // console.log(" optionsfilterModel", options.filterModel);
      options.filterModel.value = [];
      options.value = [];
    }
    return options.filterModel.matchMode === "contains" ? (
      <MultiSelect
        value={masterCatFilter.stateList || []}
        options={stateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("stateRowFilterTemplate:", e, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, stateList: e.value };
          });
          //options.filterApplyCallback(e.value);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "12rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter.stateList || []}
        options={stateArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log("stateRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, stateList: e.value };
          });
          let values = [];
          // console.log(
          //   "##federalFilterElement#:",
          //   options.filterModel.matchMode
          // );
          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "customFilter_not") {
            values.push(e.value);
          }
          // console.log("federalFilterElement", e.value, values);
          //options.filterApplyCallback(values);
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "12rem" }}
      />
    );
  };


  const cellEditor = (options) => {
    // console.log("##Editor Options:", options);
    return (
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Calendar
          value={options.value ? new Date(options.value) : options.value}
          onChange={(e) => options.editorCallback(e.value)}
          dateFormat='mm/dd/yy'
          placeholder='mm/dd/yyyy'
          mask='99/99/9999'
        />
      </Stack>
    );
  };

  useEffect(() => {
    // if (filingMasterLoaded) 
    // console.log("Show Table Data", filingMasters);

    //eslint-disable-next-line
  }, [filingMasterLoaded]);

  const onRowSelect = (e) => {
    // console.log("@@RowSelect:", e);
  };

  // useEffect(() => {
  // let list = states.map((ele) => ele.state);
  // setStateArray(list);
  //  fetchFilingMasters();

  //eslint-disable-next-line
  // }, []);

  // const handleChange = useCallback((state) => {
  //   setSelectedRows(state.selectedRows);
  // }, []);

  useEffect(() => {
    // console.log("state", selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    // if (filingMasters) {
    // try {
    //   let allCats = [];
    //   let fedLookup = [];
    //   let stateCatLookup = [];
    //   const updFilingMasters = filingMasters
    //     .filter((record) => !record.changesInprogress)
    //     .map((item, i) => {
    //       // console.log(`filingMasters ...`, item);

    //       const _stateList = getStateName(item.stateInfo);

    //       //formatating duedates
    //       const _formattedDueDates = toLocalTime(item?.filingPeriods);
    //       let dueDate =
    //         item?.filingPeriods[0].dueDate !== null
    //           ? new Date(item?.filingPeriods[0].dueDate)
    //           : null;
    //       // console.log('dueDate', item?.filingPeriods[0].dueDate, dueDate);
    //       // const categories = _categories.join(", ");
    //       // console.log(`filingMasters ${item.filingMasters} cat...`, categories);
    //       if (item.juristiction?.includes('Federal')) {
    //         // setFederalCategoriesLookup((old) => [...old, categories]);
    //         const _categories = _.map(
    //           item.businessCategory,
    //           'businessCategoryName'
    //         ).join(', ');
    //         allCats.push(..._categories);
    //         fedLookup.push(..._categories);

    //         return {
    //           ...item,
    //           federalCategories: _categories,
    //           stateList: _stateList,
    //           formattedDueDates: _formattedDueDates,
    //           dueDate,
    //           masterId: i,
    //         };
    //       } else {
    //         // console.log(
    //         //   "stateCategories: props",
    //         //   item.stateInfo,
    //         //   item.businessCategory
    //         // );

    //         const categoriesState = item.businessCategory.filter(
    //           (ele) => ele.state === item.stateInfo
    //         );
    //         // console.log(
    //         //   "stateCategories: filter",
    //         //   item.filingName,
    //         //   categoriesState
    //         // );
    //         const _categories = categoriesState
    //           .map((ele) => ele.businessCategoryName)
    //           .join(', ');
    //         // console.log("stateCategories: join", _categories);
    //         allCats.push(..._categories);
    //         stateCatLookup.push(..._categories);
    //         // setStateCategoriesLookup((old) => [...old, _categories]);
    //         return {
    //           ...item,
    //           stateCategories: _categories,
    //           stateList: _stateList,
    //           formattedDueDates: _formattedDueDates,
    //           dueDate,
    //           masterId: i,
    //         };
    //       }
    //     });
    //   // console.log('Updated filingMasters...', updFilingMasters);
    //   // setData(updCustomers);

    //   setFederalCategoriesLookup(Array.from(new Set(fedLookup)));
    //   setStateCategoriesLookup(Array.from(new Set(stateCatLookup)));
    //   setCategoriesLookup(Array.from(new Set(allCats)));
    //   // setStateCategoriesLookup((old) => new Set(old).values());
    //   setMasters(updFilingMasters);
    // } finally {
    //   setIsLoading(false);
    // }
    // }
    //eslint-disable-next-line
  }, [filingMasters]);

  useEffect(() => {
    if (value) {
      // fetchFilingMasters();
      setRecordsInView(0);
      setIsLoading(true);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setRefreshRecordList(true);
    }
  }, [value]);
  useEffect(() => {
    if (value === "2") {
      // fetchFilingMasters()
      setRecordsInView(0);
      setIsLoading(true);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setRefreshRecordList(true);
    };
  }, [selectedYear]);

  const fetchBusinessCategories = async () => {
    console.log("@@Fetch BusinessCategories:", getBusinessCategories);
    if (getBusinessCategories) {
      console.log("@@Fetch BusinessCategories:");
      try {
        let list = []
        const resList = await getBusinessCategories();
        console.log("@@BusinessCategories:", resList);
        list = resList.map((item) => item.businessCategoryName).sort()
        console.log("@@BusinessCategories: list", list);
        setCategoryList(() => list);
        return
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };

  const initialLoadPage = async () => {
    setLazyLoading(true)
    await fetchConfigs();
    let list = states.map((ele) => ele.state);
    setStateArray(list);
    fetchBusinessCategories()
    if (masters.length <= 0 && !lazyLoading) {
      setRecordsInView(0)
      setRefreshRecordList(true)
    }
  }

  useEffect(() => {
    initialLoadPage();
  }, []);

  const upcomingDueDates = () => {
    // console.log("upcomingDueDates bef format", masters);
    let currentDate = new Date();
    let upcomingDates = masters.filter((ele) => {
      //  let currentYear = new Date().getFullYear();
      if (ele.filingPeriods.length > 0) {
        let isUpcomingDate = ele.filingPeriods
          // .filter((d) => new Date(d.dueDate).getFullYear() === currentYear)
          .filter((d) => {
            let dueDate = new Date(d.dueDate);
            return dueDate >= currentDate;
          })
          .map((d) => d.dueDate);
        if (isUpcomingDate.length > 0) {
          ele.upcomingDueDates = isUpcomingDate;
          return ele;
        }
      }
    });
    // console.log("upcomingDueDates Format", upcomingDates);

    // console.log("LODASH", upcomingDates);
    let id = 0;
    let upcomingDatesFlat = _.flatMap(upcomingDates, (n) => {
      // console.log("LODASH", n);
      return n.upcomingDueDates.map((ele) => {
        id++;
        // let date = toLocalTime2(ele);
        return {
          ...n,
          //  dueDate: ele !== null ? new Date(date) : null,
          dueDate: ele !== null ? new Date(ele) : null,
          rowId: id,
        };
      });
    });
    // console.log("LODASH", upcomingDatesFlat);

    let sortedData = upcomingDatesFlat.sort(function (a, b) {
      var date1 = new Date(a.dueDate);
      var date2 = new Date(b.dueDate);
      return date1 - date2;
    });
    // console.log("upcomingDueDates", sortedData);
    setSortMaster(sortedData);
  };
  useEffect(() => {
    // upcomingDueDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortRecord]);

  const sortRecords = async () => {
    // console.log("sortRecords", masters, sortMaster);

    if (sortRecord === "Upcoming Due Dates") {
      setSortRecord("Clear");
      // upcomingDueDates();
      let todayDate = new Date().toISOString().split('T')[0]
      console.log("upcomingDueDates todayDate", todayDate)
      setupcomingDueDateVal(todayDate)
    } else if (sortRecord === "Clear") {
      setSortMaster([]);
      setSortRecord("Upcoming Due Dates");
      setupcomingDueDateVal("")
    }
    setSelectedMasters(null);
    setRecordsInView(0);
    setIsLoading(true);
    setMasters([]);
    setMasterCatFilter([]);
    setGlobalFilterValue('');
    setIsClearIcon(false)
    setFilters({
      ...filters,
      global: {
        ...filters.global,
        value: null,
      },
    });
    setSortingData({ sortBy: "", sortOrder: "" });
    setIsFilterChange([]);

  };
  // console.log("sortMasterCheck", sortMaster);
  const handleYearChange = (event) => {
    setMasters([])
    setSelectedMasters(null)
    setIsLoading(true);
    setGlobalFilterValue([])
    setMasterCatFilter([])
    // console.log("YearChange", event);
    setSelectedYear(event.target.value);
  };

  const RenderHeader = () => {
    return (
      <Grid container sx={{ my: 1, flexDirection: "row" }}>
        <Grid sx={{ margin: 1, flexGrow: 1 }}>
          <ArrowBackIosNewIcon
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={backToHome}
          />
          <Typography
            variant='h6'
            noWrap
            component='a'
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {value === '1'
              ? `Master List Active (${totalRecords})`
              :
              `Master List History (${totalRecords})`
            }
          </Typography>
        </Grid>
        {value === "2" && (
          <Grid md={3}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <InputLabel id='demo-select-small-label'>Year</InputLabel>
              <Select
                labelId='demo-select-small-label'
                id='demo-select-small'
                value={selectedYear}
                label='Year'
                onChange={handleYearChange}
              >
                {yearsList.map((year) => (
                  <MenuItem value={year} key={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid md={4} sx={{ margin: 1, display: "flex", justifyContent: "end" }}>
          <span style={{ display: "flex" }}>
            <InputText
              ref={search}
              inputRef={search}
              placeholder='Search by Filing Name'
              size={20}
            />
            <PrimeButton
              icon={isClearIcon ? "pi pi-times" : "pi pi-search"}
              onClick={applyGlobalFilter} />
          </span>
        </Grid>
        {value === '1' && (
          <Grid
            md={3}
            sx={{ margin: 1, pl: 2, display: "flex", justifyContent: "end" }}
          >
            <Button
              sx={{ p: 1, mx: 2, backgroundColor: "#0b3edf" }}
              //  color="primary"
              variant='contained'
              onClick={sortRecords}
            >
              Upcoming Due Dates
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const header = RenderHeader();

  const RenderDueHeader = () => {
    return (
      <Grid container sx={{ my: 1, flexDirection: "row" }}>
        <Grid sx={{ margin: 1, flexGrow: 1 }}>
          <ArrowBackIosNewIcon
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={sortRecords}
          />
          <Typography
            variant='h6'
            noWrap
            component='a'
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Master Filing List ({totalRecords})
          </Typography>
        </Grid>
        <Grid md={4} sx={{ margin: 1 }}>
          <span style={{ display: "flex" }}>
            <InputText
              ref={search}
              inputRef={search}
              placeholder='Search by Filing Name' s
              size={20}
            />
            <PrimeButton
              icon={isClearIcon ? "pi pi-times" : "pi pi-search"}
              onClick={applyGlobalFilter} />
          </span>
        </Grid>
        <Grid
          md={3}
          sx={{ margin: 1, pl: 2, display: "flex", justifyContent: "end" }}
        >
          <Button
            sx={{ p: 1, mx: 2, backgroundColor: "#0b3edf" }}
            color='primary'
            variant='contained'
            onClick={sortRecords}
          >
            CLEAR
          </Button>
        </Grid>
      </Grid>
    );
  };

  const headerDue = RenderDueHeader();

  const editMasterFilling = () => {
    if (selectedmasters) {
      // console.log("Editing Master", selectedmasters);
      delete selectedmasters.stateList;
      setCurrentFilingMaster(selectedmasters);
      navigate("/filing-master");
    } else {
      showAlert("Info", "Please Select a Master Record", "info");
      closeDialog();
    }
  };

  const deleteMasterRecord = async () => {
    setIsLoading(true);
    try {
      if (selectedmasters) {
        try {
          let payLoad = { ...selectedmasters };
          //payLoad.changesInprogress = undefined;
          payLoad.createUser = authUser.userId + "";
          payLoad.createDate = new Date();
          payLoad.recordType = "TrackNow"
          delete payLoad.stateList;

          // console.log("pay#", payLoad);
          const res = await deleteDraftFilingMaster(payLoad);
          // console.log("Draft Res:", res);
          if (res) {
            const wfPayload = {
              draftId: res.draftId,
              // currentApproverId: 1,
              WorkflowInitiatorId: authUser?.userId + "",
              createUser: authUser?.userId + "",
              createDate: new Date(),
            };
            let res3 = null;
            if (createFilingMasterWorkflow) {
              res3 = await createFilingMasterWorkflow(wfPayload);
            }
            if (res3) {
              setCurrentFilingMaster(null);
              setRecordsInView(0);
              setMasters([]);
              setMasterCatFilter([]);
              setSelectedMasters(null)
              setGlobalFilterValue('');
              setFilters({
                ...filters,
                global: {
                  ...filters.global,
                  value: null,
                },
              });
              setIsFilterChange([]);
              setSortingData({ sortBy: "", sortOrder: "" });

              setAlertMessage(
                <Typography>
                  Successfully submitted delete request for {" "}
                  <Typography fontWeight="bold" component="span">
                    {selectedmasters.filingName}
                  </Typography> {" "}
                </Typography>
              );
              setShowAlert(true);
              closeDialog();
            }
          } else {
            setAlertMessage(
              <Typography>
                Unexpected Error occured while deleting the
                <Typography fontWeight="bold" component="span">
                  {selectedmasters.filingName}`,
                </Typography>{" "}
              </Typography>
            );
            setShowAlert(true);
            closeDialog();
          }
        } catch (error) {
          console.log("@@Error:", error);
        }
      } else {
        showAlert("Info", "Please Select a Master Record", "info");
        closeDialog();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const FilingCommentButton = ({ rowData }) => {
    const isPermissionEnabled = usePermission("MasterFilingList", "comments", rowData?.customer[0]?.customerId);
    return (
      // <ToolTip
      //   title={
      //     rowData.hasComments ? "Comments available" : "Comments unavailable"
      //   }
      // >
      <IconButton color='primary' disabled={!rowData.hasComments || !isPermissionEnabled}>
        <InsertCommentRoundedIcon onClick={() => addComment(rowData)} />
      </IconButton>
      // </ToolTip>
    );
  };
  const FilingAttachmentButton = ({ rowData }) => {
    const isPermissionEnabled = usePermission("MasterFilingList", "referenceDocument", rowData?.customer[0]?.customerId);
    return (
      <IconButton color='primary' disabled={!rowData.hasAttachments || !isPermissionEnabled}>
        <SnippetFolderIcon onClick={() => displayAttachments(rowData)} />
      </IconButton>
    );
  };

  // const toLocalTimeq = (date) => {
  //   if (date) {
  //     const _date = DateTime.fromFormat(
  //       DateTime.fromISO(date.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"),
  //       "dd-MMM-yyyy hh:mm:ss",
  //       { zone: "UTC" }
  //     )
  //       .toLocal()
  //       .toFormat("dd-MMM-yy");
  //     //  console.log("@@@ LocalllTime:", _date);

  //     return _date;
  //   } else return "";
  // };


  const toLocalTime = (dates) => {
    // console.log("filingPeriodscheck", dates);
    if (dates) {
      const localDates = dates.map(date => {
        return date.dueDate ? DateTime.fromFormat(DateTime.fromISO(date.dueDate.toString()).toFormat("dd-MMM-yyyy hh:mm:ss"), "dd-MMM-yyyy hh:mm:ss", { zone: "UTC" }).toLocal()
          .toFormat("M/d/yyyy") : '';
      });
      return localDates.join(', ');
    } else return "";
  };



  // const toLocalTime2 = (date) => {
  //   console.log("toLocalTime", date);
  //   if (date) {
  //     const _date = DateTime.fromFormat(
  //       DateTime.fromISO(date.toString()).toFormat("dd-LL-yyyy hh:mm:ss"),
  //       "dd-LL-yyyy hh:mm:ss",
  //       { zone: "UTC" }
  //     ).toFormat("yyyy-LL-dd");
  //     // console.log("@@@ LocalllTime:", _date);

  //     return _date;
  //   } else return "";
  // };

  const rowExpansionTemplate = (data) => {
    // console.log("rowExpansionTemplate", data.filingPeriods);
    if (data.filingPeriods.length > 0) {
      let tableData = data.filingPeriods.map((ele) => {
        let startDate = new Date(ele.startDate);
        let endDate = new Date(ele.endDate);
        const toLocalDateString = date => {
          const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
          return date.toLocaleDateString('en-US', options).replace(/(\d+)\/(\d+)\/(\d+)/, (_, month, day, year) => `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`);
        };

        let fp = `${toLocalDateString(startDate)} - ${toLocalDateString(endDate)}`;
        let dueDate = ele.dueDate !== null ? toLocalDateString(new Date(ele.dueDate)) : null;
        return {
          startDate: ele.startDate,
          endDate: ele.endDate,
          filingPeriod: fp,
          dueDate: dueDate,
        };
      });
      return (
        <div className='filingPeriodTable p-3' style={{ width: "50vw" }}>
          <h5>Due Dates for {data.filingName}</h5>
          <DataTable value={tableData}>
            <Column field='filingPeriod' header='Filing Period'></Column>
            <Column field='dueDate' header='DueDate'></Column>
          </DataTable>
        </div>
      );
    } else {
      return (
        <div className='filingPeriodTable p-3'>
          <h5>No Due Dates for {data.filingName}</h5>
        </div>
      );
    }
  };

  const rowExpand = (event) => {
    // console.log("rowExpand", event, expandedRows);
    setExpandedRows(event.data);
  };

  const tableRef = useRef(null);
  const upcomingTableRef = useRef(null);
  const historyTableRef = useRef(null);
  const pageSize = 100;

  // lazy load function
  const loadDataLazy = async (event) => {
    console.log("loadDataLazy props", recordsInView, event);

    //Virtual scroll callback
    const vScroll = value === "1"
      ? sortRecord === "Clear"
        ? upcomingTableRef.current.getVirtualScroller()
        : tableRef.current.getVirtualScroller()
      : historyTableRef.current.getVirtualScroller()
    let range = vScroll.getRenderedRange();
    let { first, last } = range.viewport;
    console.log("loadDataLazy vScroll", vScroll, range.viewport);

    let firstVal = recordsInView * pageSize;
    console.log(
      "loadDataLazy totalRecords",
      firstVal,
      totalRecords,
      lazyLoading,
      masters.length,
    );


    if (firstVal < totalRecords && first > masters.length - 10 & !lazyLoading) {
      //scroll down
      // setIsLoading(true);
      setLazyLoading(true);
      let lastVal = firstVal + pageSize;
      // console.log("loadDataLazy d firstVal", firstVal, lastVal);

      //get next data
      let records = await loadRecordsList(firstVal, lastVal, "down");
      // console.log("loadDataLazy d record", records);
      if (records) {
        // console.log("loadDataLazy d scrollInView", recordsInView * 100);

        //scroll to next records
        vScroll.scrollInView(recordsInView * 100);
        setRecordsInView((old) => old + 1);
        setLazyLoading(false);
        setIsLoading(false);
      }
    } else if (last < 10 && recordsInView > 1 && !lazyLoading) {
      // scroll up
      let firstVal = (recordsInView - 3) * pageSize;
      let lastVal = firstVal + pageSize;
      if (firstVal >= 0) {
        // setIsLoading(true);
        setLazyLoading(true);
        // console.log("loadDataLazy u firstVal", firstVal, lastVal);

        //get prev data
        let records = await loadRecordsList(firstVal, lastVal, "up");
        // console.log("loadDataLazy u record", records);
        if (records) {
          // console.log("loadDataLazy u scrollInView", (recordsInView - 3) * 100);

          //scroll to prev records
          vScroll.scrollToIndex(16);
          setRecordsInView((old) => old - 1);
          setLazyLoading(false);
          setIsLoading(false);
        }
      }
    }
  };

  // api call for lazy load
  const loadRecordsList = async (first, last, direction) => {
    console.log("loadRecordsList props", first, last, direction);
    setIsLoading(true);
    let url = `${API_BASE_URL}/FilingMaster/FilingMasterListByPagination?StartRow=${first + 1}&EndRow=${last}&recordType=TrackNow`

    console.log("loadRecordsList selectedYear", value, selectedYear);
    if (value === "2" && selectedYear !== "") {
      url = `${url}&Year=${selectedYear}`;
    }

    console.log("loadRecordsList upcomingDueDate", upcomingDueDateVal);
    if (upcomingDueDateVal !== "") {
      url = `${url}&SearchByUpcomingDuedate=${upcomingDueDateVal}`;
    }

    // console.log("loadRecordsList fliter/sort",isFilterChange,sortingData);
    if (isFilterChange !== undefined && isFilterChange.length > 0) {
      // url filter parameters
      isFilterChange.forEach((ele) => {
        url = `${url}&${ele.name}=${ele.val}${ele.searchType ? `&${ele.searchType}=${ele.searchTypeVal}` : ``
          }`;
      });
    }

    if (
      sortingData &&
      typeof sortingData === "object" &&
      sortingData.sortBy !== "" &&
      sortingData.sortOrder !== ""
    ) {
      // url sort parameters
      let sortParameter = getSortData(sortingData.sortBy);
      console.log("getSortData", sortParameter)
      let order = sortingData.sortOrder === 1 ? "Ascending" : "Descending"
      url = `${url}&${sortParameter.SortByParameter}=${sortParameter.SortByVal}&${sortParameter.SortOrderParameter}=${order}`;
    }

    // console.log("loadRecordsList url", url);
    try {
      //get data
      const res = await axios.get(url);
      console.log("loadRecordsList res", res.data);

      if (res.data) {
        let ogList = masters.map((ele) => ele)
        setMasters([]);
        await modifyList(res.data.items, direction, ogList);
        setTotalRecords(res.data.totalItems);
        // setMasters(res.data.items)
        return res.data.items;
      } else {
        return false;
      }
    } catch (err) {
      console.log("loadRecordsList err", err);
    }
  };

  //maping records for lazy load
  const modifyList = (list, direction, ogList) => {
    console.log("modifyList", list, direction);
    if (list.length > 0) {
      //list has data


      // setOgList(og)    

      // console.log("loadDataLazy og", ogList, masters);
      // console.log("modifyList og", ogList, masters);

      let allCats = [];
      let fedLookup = [];
      let stateCatLookup = [];

      //formating list
      const updFilingMasters = list
        .map((item, i) => {
          // console.log(`filingMasters ...`, item);

          const _stateList = getStateName(item.stateInfo);

          //formatating duedates
          const _formattedDueDates = toLocalTime(item?.filingPeriods);
          let dueDate =
            item?.filingPeriods[0].dueDate !== null
              ? new Date(item?.filingPeriods[0].dueDate)
              : null;

          //formatating juristiction
          if (item.juristiction?.includes('Federal')) {
            const _categories = _.map(
              item.businessCategory,
              'businessCategoryName'
            ).join(', ');
            // console.log("federalCategories: join", _categories);

            allCats.push(..._categories);
            fedLookup.push(..._categories);

            return {
              ...item,
              federalCategories: _categories,
              stateList: _stateList,
              formattedDueDates: _formattedDueDates,
              dueDate,
              masterId: `${i}${item.filingId}`,
            };
          } else {
            //get state categories 
            const categoriesState = item.businessCategory.filter(
              (ele) => ele.state === item.stateInfo
            );
            const _categories = categoriesState
              .map((ele) => ele.businessCategoryName)
              .join(', ');
            // console.log("stateCategories: join", _categories);

            allCats.push(..._categories);
            stateCatLookup.push(..._categories);

            return {
              ...item,
              stateCategories: _categories,
              stateList: _stateList,
              formattedDueDates: _formattedDueDates,
              dueDate,
              masterId: `${i}${item.filingId}`,
            };
          }
        });
      // console.log("modifyList updFilingMasters", updFilingMasters);


      let updFilingMastersList
      if (direction === "down") {
        //scroll down
        if (masters.length >= 200) {
          //list > 200

          //slice 2 half of list
          let unloadList = ogList.slice(
            ogList.length - pageSize,
            ogList.length
          );
          // console.log("modifyList dw unloadList", unloadList);

          //add new data
          updFilingMastersList = [...unloadList, ...updFilingMasters];
          // console.log("modifyList dw updFilingMastersList", updFilingMastersList);

          let list = updFilingMastersList.map((ele) => ele)
          setMasters(list);
          setFilingMasters(list)
          console.log("modifyList list", list);
        } else {
          //list < 200

          // add new data to list
          updFilingMastersList = [...ogList, ...updFilingMasters];
          // console.log("modifyList dw updFilingMastersList", updFilingMastersList);
          let list = updFilingMastersList.map((ele) => ele)
          setMasters(list);
          setFilingMasters(list)
          console.log("modifyList list", list);

        }
      } else if (direction === "up") {
        //scroll up
        if (masters.length >= 200) {
          //list > 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updFilingMasters, ...unloadList];
          console.log("modifyList up updFilingMastersList", updFilingMastersList);

          let list = updFilingMastersList.map((ele) => ele)
          setMasters(list);
          setFilingMasters(list)
          console.log("modifyList list", list);

        } else {
          //list < 200

          //slice 1 half of list
          let unloadList = ogList.slice(0, pageSize);
          // console.log("modifyList up unloadList", unloadList);

          //add new data
          updFilingMastersList = [...updFilingMasters, ...unloadList];
          // console.log("modifyList up updFilingMastersList", updFilingMastersList);
          let list = updFilingMastersList.map((ele) => ele)
          setMasters(list);
          setFilingMasters(list)
          console.log("modifyList list", list);

        }
      }

      setFederalCategoriesLookup(Array.from(new Set(fedLookup)));
      setStateCategoriesLookup(Array.from(new Set(stateCatLookup)));
      setCategoriesLookup(Array.from(new Set(allCats)));
      setRefreshRecordList(false)
      setIsLoading(false);
      return updFilingMastersList
    } else {
      //list empty
      // console.log("modifyList empty",list)
      setIsLoading(false);
      setMasters([]);
      setFilingMasters([])
      return []

    }
  };

  //table filter change
  const onFilterChange = async (e) => {
    console.log("onFilterChange props", e.filters);
    let filterChange = [];
    if (e.filters.global.value !== "" && e.filters.global.value !== null) {
      //global filter
      filterChange.push({
        name: `SearchByFilingName`,
        val: e.filters.global.value,
        searchType: `FilingNameSearchType`,
        searchTypeVal: getSearchType("contains"),
      });
    } else {
      //map filter event
      Object.keys(e.filters).forEach((col) => {
        console.log("onFilterChange col", col, e.filters[col]);
        if (
          e.filters[col].constraints &&
          e.filters[col].constraints[0].value !== null
        ) {
          if (col === "stateList") {
            Array.isArray(e.filters[col].constraints[0].value)
              ? e.filters[col].constraints[0].value.map((ele, i) => {
                let changeItem = {
                  name: `SearchByState`,
                  val: getStateCode(ele),
                };
                if (i + 1 === e.filters[col].constraints[0].value.length) {
                  changeItem.searchType = `StateSearchType`;
                  changeItem.searchTypeVal = getSearchType(
                    e.filters[col].constraints[0].matchMode
                  );
                }
                filterChange.push(changeItem);
              })
              : filterChange.push({
                name: `SearchByState`,
                val: getStateCode(e.filters[col].constraints[0].value),
                searchType: `StateSearchType`,
                searchTypeVal: getSearchType(
                  e.filters[col].constraints[0].matchMode
                ),
              });
          } else if (col === "juristiction") {
            filterChange.push({
              name: `SearchByJurisdiction`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "required") {
            filterChange.push({
              name: `SearchByRequired`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "jsicontactName") {
            filterChange.push({
              name: `SearchByJSIStaff`,
              val: e.filters[col].constraints[0].value,
              searchType: `JSIStaffSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "dueDate") {
            filterChange.push({
              name: `SearchByDueDate`,
              val: formatFilterDate(e.filters[col].constraints[0].value),
              searchType: `DueDateSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "federalCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `WhoMustFileFederal`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileFederalSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "stateCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              let changeItem = {
                name: `WhoMustFileInState`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileInStateSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else {
            filterChange.push({
              name: `SearchBy${col}`,
              val: e.filters[col].constraints[0].value,
              searchType: `${col}SearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          }
        }
      });
    }

    //set filter
    console.log("onFilterChange filterChange", filterChange);
    handleSetFilterVariable(filterChange);
  };

  const formatFilterDate = (date) => {
    // console.log("formatFilterDate", date);
    let getDate = new Date(date);
    let formatDate = `${getDate.getFullYear()}-${getDate.getMonth() + 1
      }-${getDate.getDate()}`;
    // console.log("formatFilterDate formatDate", getDate.getFullYear(), formatDate);
    return formatDate;
  };

  const getStateCode = (stateName) => {
    // console.log("getStateCode", stateName, states);
    const state = states.find((s) => s.state === stateName);
    return state ? state.code : stateName;
  };

  const matchModeVal = [
    { name: "contains", val: "Contains" },
    { name: "in", val: "Contains" },
    { name: "notContains", val: "NotContains" },
    { name: "equals", val: "EqualTo" },
    { name: "dateIs", val: "DateIs" },
    { name: "dateIsNot", val: "DateIsNot" },
    { name: "dateBefore", val: "DateIsBefore" },
    { name: "dateAfter", val: "DateIsAfter" },
  ];

  const getSearchType = (matchMode) => {
    // console.log("getSearchType props", matchMode);
    return matchModeVal.find((ele) => ele.name === matchMode).val;
  };

  const getSortData = (val) => {
    console.log("getSortData props", val);
    if (val === "dueDate") {
      return {
        SortByParameter: "SortByDuedate",
        SortByVal: "DueDate",
        SortOrderParameter: "SortOrderDuedate"
      }
    } else if (val === "stateList") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "StateInfo",
        SortOrderParameter: "SortOrder"
      }
    } else if (val === "federalCategories" || val === "stateCategories") {
      return {
        SortByParameter: "SortByBusinessCategory",
        SortByVal: "BusinessCategory",
        SortOrderParameter: "SortOrderBusinessCategory"
      }
    } else {
      return {
        // SortByParameter: `SortBy${val.charAt(0).toUpperCase() + val.slice(1)}`,
        SortByParameter: `SortBy`,
        SortByVal: val.charAt(0).toUpperCase() + val.slice(1),
        // SortOrderParameter: `SortOrder${val.charAt(0).toUpperCase() + val.slice(1)}`
        SortOrderParameter: `SortOrder`
      }
    }
  }

  //set filter
  const handleSetFilterVariable = (filterChange) => {
    console.log("handleSetFilterVariable props", filterChange);
    setIsFilterChange(() => filterChange);
    setRecordsInView(0);
    setIsLoading(true);
    setMasters([]);
  };

  //table sort change
  const handleSort = async (e) => {
    // console.log("handleSort props",e ,e.sortField)
    if (e.sortField !== "") {
      setSortingData(() => ({
        sortBy: e.sortField,
        sortOrder: e.sortOrder,
      }));
      setRecordsInView(0);
      setMasters([]);
      setIsLoading(true)
    }
  }

  useEffect(() => {
    console.log("filter/sort change", isFilterChange, sortingData, !lazyLoading, !refreshRecordList);
    //on filter/sort change
    if (!lazyLoading && !refreshRecordList) {
      const sortAndFilterApiCall = async () => {
        console.log("LOAD Check 4");
        let records = await loadRecordsList(0, 100, "down");
        // console.log("recordsCheck", records);
        setIsLoading(false);
        setRecordsInView(1)
      };
      sortAndFilterApiCall();
    }
  }, [isFilterChange, sortingData]);

  const refreshRecords = async () => {
    console.log("refreshRecords props")

    //Virtual scroll callback
    const vScroll = value === "1"
      ? sortRecord === "Clear"
        ? upcomingTableRef.current.getVirtualScroller()
        : tableRef.current.getVirtualScroller()
      : historyTableRef.current.getVirtualScroller()
    let range = vScroll.getRenderedRange();
    console.log("refreshRecords vScroll range", vScroll, range);

    let records
    console.log("refreshRecords refreshDetails", refreshDetails);
    if (refreshDetails?.pageInView || refreshDetails?.noOfPages) {
      let first = refreshDetails.pageInView * pageSize
      records = await loadRecordsList(first, first + refreshDetails?.noOfPages * pageSize, "down");
    } else {
      records = await loadRecordsList(0, pageSize, "down");
    }
    // console.log("refreshRecords records", records, props.keepSelectedRecords);

    if (records) {
      if (refreshDetails?.viewport && refreshDetails?.noOfPages) {
        setRecordsInView((old) => old + refreshDetails?.noOfPages);
        // setRefreshDetails(null)
      } else {
        setRecordsInView((old) => old + 1);
      }
      setLazyLoading(false)
    }
  }


  useEffect(() => {
    console.log("refreshRecordList,", refreshRecordList, !lazyLoading);
    if (refreshRecordList && !lazyLoading) {
      setLazyLoading(true);
      refreshRecords()
    }

  }, [refreshRecordList])

  const handleRrefreshList = () => {
    //Virtual scroll callback
    const vScroll = value === "1"
      ? sortRecord === "Clear"
        ? upcomingTableRef.current.getVirtualScroller()
        : tableRef.current.getVirtualScroller()
      : historyTableRef.current.getVirtualScroller()
    let range = vScroll.getRenderedRange();
    console.log("refreshTable vScroll", vScroll, range);

    let pageInView
    let noOfPages
    if (masters.length > 100) {
      pageInView = recordsInView - 2
      noOfPages = 2
    } else {
      pageInView = recordsInView - 1
      noOfPages = 1
    }
    let clientSelected = masters
    console.log("refreshTable clientSelected", masters, clientSelected);

    setRecordsInView(pageInView)
    setRefreshDetails({
      viewport: range.viewport,
      pageInView,
      noOfPages,
      clientSelected
    })

    setRefreshRecordList(true)
    setIsLoading(true);
    setIsFilterChange([]);
    setMasters([])
    setSortingData({ sortBy: "", sortOrder: "" });
  }
  
  useEffect(() => {
    console.log("Total Records updated:", totalRecords);
  }, [totalRecords]);

  useEffect(() => {
    console.log("recordsInView,", recordsInView);
  }, [recordsInView]);

  useEffect(() => { console.log("lazyLoading", lazyLoading) }, [lazyLoading])

  useEffect(() => { console.log("isLoading", isLoading) }, [isLoading])

  useEffect(() => {
    console.log("masters,", masters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masters, masters.length, JSON.stringify(masters)]);

  return (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert1}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      <Grid sx={{ height: "100%", width: "93%" }}>
        <Grid sm={24} sx={{ height: "100%", width: "93%" }}>
          {/* {filingMasterLoaded && (
            <DataTableBase
              columns={columns}
              data={data}
              selectableRows
              onSelectedRowsChange={handleChange}
            />
          )} */}
          {
            <FilingMasterComments
              show={showComments}
              dockAt='right'
              draft={selectedmasters}
              comments={comments}
              handleClose={closeComments}
              refreshTable={handleRrefreshList}
              refreshTableUpcomingDueDates={upcomingDueDates}
            />
          }
          {
            <AssociatedClient
              show={showClient}
              dockAt='right'
              draft={selectedmasters}
              clients={clients}
              handleClose={closeClient}
            />
          }

          {/* <CircularProgress
            style={{
              //position: "absolute",
              //  width: "3rem",
              marginLeft: "36rem",
              marginTop: "10rem",
              color: "#0000FF",
            }}
            // type="spin"
            height="100%"
            position="center"
            place-items="center"
          /> */}

          <div
            className='card'
            style={{
              maxWidth: "90vw",
              padding: "1rem",
              margin: "1rem",
              background: "#FFF",
              borderRadius: "2%",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange}>
                  <Tab label='Master Active' value='1' disabled={isLoading} />
                  <Tab label='Master History' value='2' disabled={isLoading} />
                </TabList>
              </Box>
              <TabPanel value='1'>
                {sortRecord === "Clear" ? (
                  <DataTable
                    className='frozen-mf-ud'
                    resizableColumns
                    columnResizeMode='expand'
                    showGridlines
                    key='sortedlist'
                    scrollable
                    value={filingMasters}
                    selection={selectedmasters}
                    onSelectionChange={(e) => setSelectedMasters(e.value)}
                    dataKey='masterId'
                    filters={filters}
                    loading={isLoading}
                    // filterDisplay="row"
                    globalFilterFields={["filingName"]}
                    header={headerDue}
                    ref={upcomingTableRef}
                    virtualScrollerOptions={{
                      // lazy: true,
                      onScroll: loadDataLazy,
                      itemSize: 70,
                      delay: 0,
                      scrollHeight: "550px",
                      appendOnly: true,
                      // showLoader: true,
                      // loading: lazyLoading,
                      // loadingTemplate,
                    }}
                    scrollHeight="550px"
                    onFilter={(e) => onFilterChange(e)}
                    sortField={sortingData.sortBy}
                    sortOrder={sortingData.sortOrder}
                    onSort={(e) => handleSort(e)}
                  >
                    <Column
                      selectionMode='single'
                      //headerStyle={{ width: "3rem" }}
                      style={{ width: "1rem", margin: "0" }}
                    >
                      Select
                    </Column>
                    <Column
                      header='Indicators'
                      style={{ textAlign: "center" }}
                      body={(rowData) => (
                        <>
                          <Stack direction='row'>
                            <FilingCommentButton rowData={rowData} />
                            <FilingAttachmentButton rowData={rowData} />
                          </Stack>
                        </>
                      )}
                    ></Column>
                    <Column
                      field='filingName'
                      header='Filing Name'
                      sortable
                      filter
                      className='filingNameColumn'
                      frozen
                      filterPlaceholder='Search by Filing Name'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      style={{ minWidth: "12rem", maxWidth: "25rem" }}
                      body={(rowData) => cellListDisplayName(rowData)}
                    ></Column>
                    <Column
                      field='filingFrequency'
                      header='Filing Frequency'
                      filter
                      frozen
                      filterPlaceholder='Search by Frequency'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                      field='dueDate'
                      header='Due Date'
                      dataType='date'
                      filter
                      frozen
                      filterField='dueDate'
                      filterPlaceholder='Search by Due Date'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterElement={dateFilterTemplate}
                      editor={(options) => cellEditor(options)}
                      style={{ minWidth: "12rem" }}
                      sortable
                      body={cellListDisplayDueDate}
                    ></Column>
                    <Column
                      field='stateList'
                      header='State'
                      sortable
                      filter
                      filterField='stateList'
                      filterPlaceholder='Search by State'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      filterMenuStyle={{ width: "15rem" }}
                      filterElement={stateRowFilterTemplate}
                      onFilterMatchModeChange={() => setMasterCatFilter([])}
                      onFilterClear={() => setMasterCatFilter([])}
                      style={{ minWidth: "13rem" }}
                      className='column-small'
                    ></Column>
                    <Column
                      field='ruleInfo'
                      header='Rule Info'
                      filter
                      filterPlaceholder='Search by Rule Info'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      style={{ minWidth: "15rem", maxWidth: "15rem" }}
                      body={(rowData) => cellListDisplayRuleInfo(rowData)}
                    ></Column>
                    <Column
                      field='required'
                      header='Required?'
                      filter
                      filterField="required"
                      filterElement={upcommingRequiredRowFilterTemplate}
                      onFilterClear={() => setUpcomingCatFilter([])}
                      showFilterMatchModes={false}
                      filterPlaceholder='Search by Required'
                      showAddButton={false}
                      showFilterOperator={false}
                      style={{ minWidth: "13rem" }}
                      className='column-small'
                    ></Column>
                    <Column
                      field='federalCategories'
                      header='Who Must File Federal'
                      // sortable
                      body={(rowData) =>
                        CellListDisplayFederal(rowData, "federalCategories")
                      }
                      filter
                      filterField='federalCategories'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.IN, label: "Contains" },
                        { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      onFilterMatchModeChange={() => setUpcomingCatFilter([])}
                      filterPlaceholder='Search by Cateogry'
                      filterElement={(options) =>
                        upcomingCategoryFilter(options, "federalCategories")
                      }
                      onFilterClear={() => setUpcomingCatFilter([])}
                      style={{ minWidth: "18rem", maxWidth: "18rem" }}
                    // filterFunction={customCategoryFilter}
                    // showFilterMenu={false}
                    //filterMenuStyle={{ width: "14rem" }}
                    //  showClearButton={false}
                    ></Column>
                    <Column
                      field='stateCategories'
                      header='Who Must File in State'
                      // sortable
                      body={(rowData) =>
                        CellListDisplayFederal(rowData, "stateCategories")
                      }
                      filter
                      filterField='stateCategories'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.IN, label: "Contains" },
                        { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      onFilterMatchModeChange={() => setUpcomingCatFilter([])}
                      filterPlaceholder='Search by Cateogry'
                      filterElement={(options) =>
                        upcomingCategoryFilter(options, "stateCategories")
                      }
                      onFilterClear={() => setUpcomingCatFilter([])}
                      style={{ minWidth: "18rem", maxWidth: "18rem" }}
                    //showFilterMenu={false}
                    //filterMenuStyle={{ width: "14rem" }}
                    //  showClearButton={false}
                    ></Column>
                    <Column
                      field='jsicontactName'
                      header='JSI Staff'
                      filter
                      filterPlaceholder='Search by JSI Staff'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                      field='juristiction'
                      header='Jurisdiction'
                      sortable
                      filterField='juristiction'
                      showAddButton={false}
                      showFilterOperator={false}
                      showFilterMatchModes={false}
                      //showFilterMenu={false}
                      //filterMenuStyle={{ width: "10rem" }}
                      style={{ minWidth: "10rem" }}
                      filter
                      filterElement={upcomingJurisdictionRowFilterTemplate}
                      onFilterMatchModeChange={() => setUpcomingCatFilter([])}
                      onFilterClear={() => setUpcomingCatFilter([])}
                    ></Column>
                    <Column
                      field='whereToFile'
                      header='Where to file'
                      filter
                      body={(rowData) => cellListDisplayWhereToFile(rowData)}
                      filterPlaceholder='Search by Where To File'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      showFilterMenu={true}
                      filterMenuStyle={{ width: "10rem" }}
                      style={{ minWidth: "13rem", maxWidth: "13rem" }}
                    ></Column>
                    <Column
                      field='notes'
                      header='Notes'
                      headerStyle={{
                        minWidth: "20rem",
                        maxWidth: "20rem",
                        justifyItems: "center",
                      }}
                      style={{ minWidth: "45rem", maxWidth: "45rem" }}
                      body={(rowData) => cellListDisplayNotes(rowData)}
                      headerClassName='centered-header'
                    ></Column>
                  </DataTable>
                ) : (
                  <DataTable
                    className='frozen-mf-ud'
                    key='list'
                    scrollable
                    resizableColumns
                    columnResizeMode='expand'
                    showGridlines
                    value={filingMasters}
                    selection={selectedmasters}
                    onSelectionChange={(e) => setSelectedMasters(e.value)}
                    dataKey='masterId'
                    onRowSelect={onRowSelect}
                    loading={isLoading}
                    // onRowToggle={(e) => rowExpand(e)}
                    // rowExpansionTemplate={rowExpansionTemplate}
                    // expandedRows={expandedRows}
                    // paginator
                    // rows={10}
                    // rowsPerPageOptions={[5, 10, 25, 50]}
                    filters={filters}
                    // filterDisplay="row"
                    globalFilterFields={["filingName"]}
                    header={header}
                    ref={tableRef}
                    virtualScrollerOptions={{
                      // lazy: true,
                      onScroll: loadDataLazy,
                      itemSize: 70,
                      delay: 0,
                      scrollHeight: "550px",
                      appendOnly: true,
                      // showLoader: true,
                      // loading: lazyLoading,
                      // loadingTemplate,
                    }}
                    scrollHeight="550px"
                    onFilter={(e) => onFilterChange(e)}
                    sortField={sortingData.sortBy}
                    sortOrder={sortingData.sortOrder}
                    onSort={(e) => handleSort(e)}
                  >
                    {/* <Column expander={true} style={{ width: "5rem" }} /> */}
                    <Column
                      selectionMode='single'
                      //headerStyle={{ width: "3rem" }}
                      style={{ width: "1rem", margin: "0" }}
                    >
                      Select
                    </Column>
                    {/* <Column
                  headerStyle={{ width: "2rem" }}
                  // header="Attachemts"
                  body={(rowData) => <FilingCommentButton rowData={rowData} />}
                ></Column>
                <Column
                  headerStyle={{ width: "2rem" }}
                  // header="Attachemts"
                  body={(rowData) => (
                    <FilingAttachmentButton rowData={rowData} />
                  )}
                ></Column> */}
                    <Column
                      header='Indicators'
                      style={{ textAlign: "center" }}
                      body={(rowData) => (
                        <>
                          <Stack direction='row'>
                            <FilingCommentButton rowData={rowData} />

                            <FilingAttachmentButton rowData={rowData} />
                          </Stack>
                        </>
                      )}
                    ></Column>
                    <Column
                      field='filingName'
                      header='Filing Name'
                      sortable
                      filter
                      frozen
                      className='filingNameColumn'
                      filterPlaceholder='Search by Filing Name'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      style={{ maxWidth: "25rem" }}
                      body={(rowData) => cellListDisplayName(rowData)}
                    ></Column>
                    {/* <Column
                {/* <Column
                  field='filingDescription'
                  header='Filing Description'
                 sortable
                  filter
                  filterPlaceholder='Search by Description'
                  style={{ minWidth: "25rem" }}
                ></Column> */}
                    <Column
                      field='filingFrequency'
                      header='Filing Frequency'
                      filter
                      frozen
                      filterPlaceholder='Search by Frequency'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                      field='dueDate'
                      header='Due Date'
                      dataType='date'
                      filter
                      frozen
                      filterField='dueDate'
                      filterPlaceholder='Search by Due Date'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterElement={dateFilterTemplate}
                      editor={(options) => cellEditor(options)}
                      style={{ minWidth: '12rem' }}
                      sortable
                      body={cellListDisplayDueDate}
                    ></Column>
                    <Column
                      field='stateList'
                      header='State'
                      sortable
                      filter
                      filterField='stateList'
                      filterPlaceholder='Search by State'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      filterMenuStyle={{ width: "15rem" }}
                      filterElement={stateRowFilterTemplate}
                      onFilterMatchModeChange={() => setMasterCatFilter([])}
                      onFilterClear={() => setMasterCatFilter([])}
                      style={{ minWidth: "13rem" }}
                      className='column-small'
                    ></Column>
                    <Column
                      field='ruleInfo'
                      header='Rule Info'
                      filter
                      filterPlaceholder='Search by Rule Info'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      style={{ minWidth: "15rem", maxWidth: "15rem" }}
                      body={(rowData) => cellListDisplayRuleInfo(rowData)}
                    ></Column>
                    <Column
                      field='required'
                      header='Required?'
                      filter
                      filterField="required"
                      filterElement={requiredRowFilterTemplate}
                      onFilterClear={() => setMasterCatFilter([])}
                      showFilterMatchModes={false}
                      style={{ minWidth: "13rem" }}
                      className='column-small'
                      filterPlaceholder='Search by Required'
                      showAddButton={false}
                      showFilterOperator={false}
                    ></Column>
                    <Column
                      field='federalCategories'
                      header='Who Must File Federal'
                      // sortable
                      body={(rowData) =>
                        CellListDisplayFederal(rowData, "federalCategories")
                      }
                      filter
                      filterField='federalCategories'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.IN, label: "Contains" },
                        { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      onFilterMatchModeChange={() => setMasterCatFilter([])}
                      filterPlaceholder='Search by Cateogry'
                      filterElement={(options) =>
                        masterCategoryFilter(options, "federalCategories")
                      }
                      onFilterClear={() => setMasterCatFilter([])}
                      style={{ minWidth: "18rem", maxWidth: "18rem" }}
                    //showFilterMenu={false}
                    //filterMenuStyle={{ width: "14rem" }}
                    //  showClearButton={false}
                    // filterFunction={customCategoryFilter}
                    ></Column>
                    <Column
                      field='stateCategories'
                      header='Who Must File in State'
                      // sortable
                      body={(rowData) =>
                        CellListDisplayFederal(rowData, "stateCategories")
                      }
                      filter
                      filterField='stateCategories'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.IN, label: "Contains" },
                        { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      onFilterMatchModeChange={() => setMasterCatFilter([])}
                      filterPlaceholder='Search by Cateogry'
                      filterElement={(options) =>
                        masterCategoryFilter(options, "stateCategories")
                      }
                      onFilterClear={() => setMasterCatFilter([])}
                      style={{ minWidth: "18rem", maxWidth: "18rem" }}
                    //showFilterMenu={false}
                    //filterMenuStyle={{ width: "14rem" }}
                    //  showClearButton={false}
                    ></Column>
                    {/* <Column
                  field='jsidept'
                  header='JSI Dept'
                  filter
                  filterPlaceholder='Search by dept'
                  style={{ minWidth: "20rem" }}
                ></Column> */}
                    <Column
                      field='jsicontactName'
                      header='JSI Staff'
                      filter
                      filterPlaceholder='Search by JSI Staff'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      style={{ minWidth: "12rem" }}
                    ></Column>
                    {/* <Column
                  field='jsicontactEmail'
                  header='JSI Contact Email'
                  filter
                  filterPlaceholder='Search by contactEmail'
                  style={{ minWidth: "20rem" }}
                ></Column> */}
                    <Column
                      field='juristiction'
                      header='Jurisdiction'
                      sortable
                      filterField='juristiction'
                      showAddButton={false}
                      showFilterOperator={false}
                      showFilterMatchModes={false}
                      //showFilterMenu={false}
                      // filterMenuStyle={{ width: "10rem" }}
                      style={{ minWidth: "10rem" }}
                      filter
                      filterElement={jurisdictionRowFilterTemplate}
                      onFilterClear={() => setMasterCatFilter([])}
                      onFilterMatchModeChange={() => setMasterCatFilter([])}
                    ></Column>
                    <Column
                      field='whereToFile'
                      header='Where to file'
                      filter
                      body={(rowData) => cellListDisplayWhereToFile(rowData)}
                      filterPlaceholder='Search by Where To File'
                      showAddButton={false}
                      showFilterOperator={false}
                      filterMatchModeOptions={[
                        { value: FilterMatchMode.CONTAINS, label: "Contains" },
                        {
                          value: FilterMatchMode.NOT_CONTAINS,
                          label: "Not Contains",
                        },
                        { value: FilterMatchMode.EQUALS, label: "Equal" },
                      ]}
                      filterMenuStyle={{ width: "10rem" }}
                      style={{ minWidth: "13rem", maxWidth: "13rem" }}
                    ></Column>
                    <Column
                      field='notes'
                      header='Notes'
                      headerStyle={{ minWidth: "20rem", maxWidth: "20rem" }}
                      style={{ minWidth: "45rem", maxWidth: "45rem" }}
                      body={(rowData) => cellListDisplayNotes(rowData)}
                    ></Column>
                  </DataTable>
                )}
              </TabPanel>
              <TabPanel value='2'>
                <DataTable
                  className='frozen-mf-ud'
                  key='list'
                  scrollable
                  resizableColumns
                  columnResizeMode='expand'
                  showGridlines
                  value={filingMasters}
                  selection={selectedmasters}
                  onSelectionChange={(e) => setSelectedMasters(e.value)}
                  dataKey='masterId'
                  onRowSelect={onRowSelect}
                  loading={isLoading}
                  // onRowToggle={(e) => rowExpand(e)}
                  // rowExpansionTemplate={rowExpansionTemplate}
                  // expandedRows={expandedRows}
                  // paginator
                  // rows={10}
                  // rowsPerPageOptions={[5, 10, 25, 50]}
                  filters={filters}
                  // filterDisplay="row"
                  globalFilterFields={["filingName"]}
                  header={header}
                  ref={historyTableRef}
                  virtualScrollerOptions={{
                    // lazy: true,
                    onScroll: loadDataLazy,
                    itemSize: 70,
                    delay: 0,
                    scrollHeight: "550px",
                    appendOnly: true,
                    // showLoader: true,
                    // loading: lazyLoading,
                    // loadingTemplate,
                  }}
                  scrollHeight="550px"
                  onFilter={(e) => onFilterChange(e)}
                  sortField={sortingData.sortBy}
                  sortOrder={sortingData.sortOrder}
                  onSort={(e) => handleSort(e)}
                >
                  {/* <Column expander={true} style={{ width: "5rem" }} /> */}
                  <Column
                    selectionMode='single'
                    //headerStyle={{ width: "3rem" }}
                    style={{ width: "1rem", margin: "0" }}
                  >
                    Select
                  </Column>
                  {/* <Column
                  headerStyle={{ width: "2rem" }}
                  // header="Attachemts"
                  body={(rowData) => <FilingCommentButton rowData={rowData} />}
                ></Column>
                <Column
                  headerStyle={{ width: "2rem" }}
                  // header="Attachemts"
                  body={(rowData) => (
                    <FilingAttachmentButton rowData={rowData} />
                  )}
                ></Column> */}
                  <Column
                    header='Indicators'
                    style={{ textAlign: "center" }}
                    body={(rowData) => (
                      <>
                        <Stack direction='row'>
                          <FilingCommentButton rowData={rowData} />

                          <FilingAttachmentButton rowData={rowData} />
                        </Stack>
                      </>
                    )}
                  ></Column>
                  <Column
                    field='filingName'
                    header='Filing Name'
                    sortable
                    filter
                    frozen
                    className='filingNameColumn'
                    filterPlaceholder='Search by Filing Name'
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ maxWidth: "25rem" }}
                    body={(rowData) => cellListDisplayName(rowData)}
                  ></Column>
                  {/* <Column
                {/* <Column
                  field='filingDescription'
                  header='Filing Description'
                 sortable
                  filter
                  filterPlaceholder='Search by Description'
                  style={{ minWidth: "25rem" }}
                ></Column> */}
                  <Column
                    field='filingFrequency'
                    header='Filing Frequency'
                    filter
                    frozen
                    filterPlaceholder='Search by Frequency'
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "12rem" }}
                  ></Column>
                  <Column
                    field='dueDate'
                    header='Due Date'
                    dataType='date'
                    filter
                    frozen
                    filterField='dueDate'
                    filterPlaceholder='Search by Due Date'
                    showAddButton={false}
                    showFilterOperator={false}
                    filterElement={dateFilterTemplate}
                    editor={(options) => cellEditor(options)}
                    style={{ minWidth: '12rem' }}
                    sortable
                    body={cellListDisplayDueDate}
                  ></Column>
                  <Column
                    field='stateList'
                    header='State'
                    sortable
                    filter
                    filterField='stateList'
                    filterPlaceholder='Search by State'
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    filterMenuStyle={{ width: "15rem" }}
                    filterElement={stateRowFilterTemplate}
                    onFilterMatchModeChange={() => setMasterCatFilter([])}
                    onFilterClear={() => setMasterCatFilter([])}
                    style={{ minWidth: "13rem" }}
                    className='column-small'
                  ></Column>
                  <Column
                    field='ruleInfo'
                    header='Rule Info'
                    filter
                    filterPlaceholder='Search by Rule Info'
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "15rem", maxWidth: "15rem" }}
                    body={(rowData) => cellListDisplayRuleInfo(rowData)}
                  ></Column>
                  <Column
                    field='required'
                    header='Required?'
                    filter
                    style={{ minWidth: "13rem" }}
                    className='column-small'
                    filterPlaceholder='Search by Required'
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                    filterElement={requiredRowFilterTemplate}
                    onFilterClear={() => setMasterCatFilter([])}
                  ></Column>
                  <Column
                    field='federalCategories'
                    header='Who Must File Federal'
                    // sortable
                    body={(rowData) =>
                      CellListDisplayFederal(rowData, "federalCategories")
                    }
                    filter
                    filterField='federalCategories'
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    onFilterMatchModeChange={() => setMasterCatFilter([])}
                    filterPlaceholder='Search by Cateogry'
                    filterElement={(options) =>
                      masterCategoryFilter(options, "federalCategories")
                    }
                    onFilterClear={() => setMasterCatFilter([])}
                    style={{ minWidth: "18rem", maxWidth: "18rem" }}
                  //showFilterMenu={false}
                  //filterMenuStyle={{ width: "14rem" }}
                  //  showClearButton={false}
                  // filterFunction={customCategoryFilter}
                  ></Column>
                  <Column
                    field='stateCategories'
                    header='Who Must File in State'
                    // sortable
                    body={(rowData) =>
                      CellListDisplayFederal(rowData, "stateCategories")
                    }
                    filter
                    filterField='stateCategories'
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.IN, label: "Contains" },
                      { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    onFilterMatchModeChange={() => setMasterCatFilter([])}
                    filterPlaceholder='Search by Cateogry'
                    filterElement={(options) =>
                      masterCategoryFilter(options, "stateCategories")
                    }
                    onFilterClear={() => setMasterCatFilter([])}
                    style={{ minWidth: "18rem", maxWidth: "18rem" }}
                  //showFilterMenu={false}
                  //filterMenuStyle={{ width: "14rem" }}
                  //  showClearButton={false}
                  ></Column>
                  {/* <Column
                  field='jsidept'
                  header='JSI Dept'
                  filter
                  filterPlaceholder='Search by dept'
                  style={{ minWidth: "20rem" }}
                ></Column> */}
                  <Column
                    field='jsicontactName'
                    header='JSI Staff'
                    filter
                    filterPlaceholder='Search by JSI Staff'
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    style={{ minWidth: "12rem" }}
                  ></Column>
                  {/* <Column
                  field='jsicontactEmail'
                  header='JSI Contact Email'
                  filter
                  filterPlaceholder='Search by contactEmail'
                  style={{ minWidth: "20rem" }}
                ></Column> */}
                  <Column
                    field='juristiction'
                    header='Jurisdiction'
                    sortable
                    filterField='juristiction'
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                    //showFilterMenu={false}
                    // filterMenuStyle={{ width: "10rem" }}
                    style={{ minWidth: "10rem" }}
                    filter
                    filterElement={jurisdictionRowFilterTemplate}
                    onFilterClear={() => setMasterCatFilter([])}
                    onFilterMatchModeChange={() => setMasterCatFilter([])}
                  ></Column>
                  <Column
                    field='whereToFile'
                    header='Where to file'
                    filter
                    body={(rowData) => cellListDisplayWhereToFile(rowData)}
                    filterPlaceholder='Search by Where To File'
                    showAddButton={false}
                    showFilterOperator={false}
                    filterMatchModeOptions={[
                      { value: FilterMatchMode.CONTAINS, label: "Contains" },
                      {
                        value: FilterMatchMode.NOT_CONTAINS,
                        label: "Not Contains",
                      },
                      { value: FilterMatchMode.EQUALS, label: "Equal" },
                    ]}
                    filterMenuStyle={{ width: "10rem" }}
                    style={{ minWidth: "13rem", maxWidth: "13rem" }}
                  ></Column>
                  <Column
                    field='notes'
                    header='Notes'
                    headerStyle={{ minWidth: "20rem", maxWidth: "20rem" }}
                    style={{ minWidth: "45rem", maxWidth: "45rem" }}
                    body={(rowData) => cellListDisplayNotes(rowData)}
                  ></Column>
                </DataTable>
              </TabPanel>
            </TabContext>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          height: "100%",
          width: "5%",
        }}
      >
        <div
          style={{
            height: "100%",
            background: "#F1F2F6",
            marginTop: "1rem",
            borderRadius: "2%",
          }}
        >
          <Item
            sx={{
              height: "100%",
            }}
          >
            <Toolbox
              isLoading={isLoading}
              addComment={addComment}
              draft={selectedmasters?.customer[0]?.customerId}
              editMasterFilling={editMasterFilling}
              deleteMasterRecord={handleDelete}
              displayAttachments={displayAttachments}
              displayHistory={displayHistory}
              displayClients={displayClients}
              downloadData={exportExcel}
              downloadDataHistory={exportExcelHistory}
              isTabValue={value === "2" ? true : false}
            />
          </Item>
        </div>
      </Grid>

      <ConfirmDialog />

      <AlertDialog />
      {
        <FilingMasterAttachment
          show={showAttachments}
          dockAt='right'
          tracker={selectedmasters}
          attachments={attachments}
          handleClose={closeAttachments}
        />
      }
      {
        <FilingMasterHistory
          show={showHistory}
          dockAt='right'
          tracker={selectedmasters}
          activityHistory={activityHistory}
          commentActivityHistory={commentActivityHistory}
          handleClose={closeHistory}
        />
      }
    </Fragment>
  );
};

export default FilingMasterList;