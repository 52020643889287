import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Paper,
  Typography,
  TextField,
  Stack,
  Tab,
  Box,
  IconButton,
  Popover,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  Tooltip,
  tooltipClasses,
  Button,
} from "@mui/material";
import {
  Search as SearchIcon,
  ClearRounded as ClearRoundedIcon,
  ArrowBackIosNew as ArrowBackIosNewIcon,
  SnippetFolder as SnippetFolderIcon,
  AttachmentOutlined as AttachmentOutlinedIcon,
  InsertCommentRounded as InsertCommentRoundedIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator, FilterService } from "primereact/api";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import CustomerContext from "../../context/customer/CustomerContext";
import AuthContext from "../../context/auth/AuthContext";
import FilingMasterContext from "../../context/filing-master/FilingMasterContext";
import { DateTime } from "luxon";
import axios from "axios";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import usePermission from "../../usePermission";
import { Button as PrimeButton } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { OverlayPanel } from "primereact/overlaypanel";
import states from "../../data/StateLookup.json";
import { InputSwitch } from "primereact/inputswitch";
import { log } from "util";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const statusLookup = ["Pending", "In Progress", "Approved", "Completed"];
const jurisdictionLookup = ["State", "Federal"];
const yearsOption = [
  { value: "", label: "None" },
  {
    value: "Before End of Current Month",
    label: "Before End of Current Month",
  },
  { value: "Within a Month", label: "Within a Month" },
  { value: "Within 3 Months", label: "Within 3 Months" },
  { value: "Within 6 Months", label: "Within 6 Months" },
  { value: "Before End of Current Year", label: "Before End of Current Year" },
];
const ClientFilingTrackerTable = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    refreshPage() {
      const vScroll =
        value === "1"
          ? sortRecord === "Clear"
            ? dtUpcoming.current.getVirtualScroller()
            : dt.current.getVirtualScroller()
          : dtHistory.current.getVirtualScroller();

      let range = vScroll.getRenderedRange();
      console.log("refreshPage vScroll", vScroll, range);

      let refreshPage = {};
      if (value === "1") {
        let pageInView;
        let noOfPages;
        if (clientFiling.length > 100) {
          pageInView = recordsInView - 2;
          noOfPages = 2;
        } else {
          pageInView = recordsInView - 1;
          noOfPages = 1;
        }
        let clientSelected = selectedClientFiling;
        console.log(
          "refreshPage clientSelected",
          pageInView,
          noOfPages,
          clientSelected
        );

        refreshPage.recordsInView = pageInView;
        refreshPage.refreshDetails = {
          viewport: range.viewport,
          pageInView,
          noOfPages,
          clientSelected,
        };
      } else {
        let pageInView;
        let noOfPages;
        if (clientFilingHistory.length > 100) {
          pageInView = recordsInView - 2;
          noOfPages = 2;
        } else {
          pageInView = recordsInView - 1;
          noOfPages = 1;
        }
        let clientSelected = selectedClientFiling;
        console.log(
          "refreshPage clientSelected",
          pageInView,
          noOfPages,
          clientSelected
        );

        refreshPage.recordsInView = pageInView;
        refreshPage.refreshDetails = {
          viewport: range.viewport,
          pageInView,
          noOfPages,
          clientSelected,
        };
      }
      console.log("refreshPageDetails refreshPage", refreshPage);
      setRefreshPageDetails(refreshPage);
      props.resetKeepPageDetails(false);
      return refreshPage;
    },
  }));

  const {
    displayComments,
    displayAttachments,
    // downloadData,
    displayDocuments,
    handleRefreshTableReturn,
    refreshTable,
    // downloadDataHistory,
  } = props;

  let { clientId } = useParams();
  const authContext = useContext(AuthContext);
  const { authUser, userRoles } = authContext;
  const [staffAssignedList, setstaffAssignedList] = useState([]);
  const [_clientId, setClientId] = useState(clientId);
  const [recordTypeVal, setRecordTypeVal] = useState("");
  const [isRecordTypeChange, setIsRecordTypeChange] = useState(false);
  const [detailedViewVal, setDetailedViewVal] = useState(false);
  const [trackNowVal, setTrackNowVal] = useState(false);
  const [trackNowValUpc, setTrackNowValUpc] = useState(false);
  const [postAwardVal, setPostAwardVal] = useState(false);
  const [postAwardValUpc, setPostAwardValUpc] = useState(false);
  const [showAffectedCompanies, setShowAffectedCompanies] = useState(false);
  const [showStaffAssigned, setShowStaffAssigned] = useState(false);
  const [affectedCompaniesDetails, setAffectedCompaniesDetails] = useState({
    recordName: "",
    list: [],
  });
  const [staffAssignedDetails, setStaffAssignedDetails] = useState({
    recordName: "",
    list: [],
  });
  const [checkboxdisabled, setCheckBoxDisabled] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [awardTypeList, setAwardTypeList] = useState([]);
  const [awardAgenciesList, setAwadAgenciesList] = useState([]);
  const [checkboxdisabledTrackNowUpc, setcheckboxdisabledTrackNowUpc] =
    useState(true);
  const [checkboxdisabledPostAwardUpc, setcheckboxdisabledPostAwardUpc] =
    useState(true);
  const [editingRow, setEditingRow] = useState(null);
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const {
    setClientFilingTrackers,
    setClientFilingTrackersHistory,
    getClientFilingTrackers,
    ClientFilingTrackers,
    ClientFilingTrackersHistory,
    ClientFilingMasterLoaded,
    clearClientFilingTrackers,
    fetchConfigurations,
    refreshPageDetails,
    setRefreshPageDetails,
  } = clientFilingMasterContext;
  const filingMasterContext = useContext(FilingMasterContext);
  const { getBusinessCategories, getProgramName } = filingMasterContext;

  // const [selectedValues, setSelectedValues] = useState(value || []); // State to store selected checkbox values
  const customerContext = useContext(CustomerContext);
  const { selectedCustomer, setCurrentCustomer } = customerContext;
  const [value, setValue] = React.useState("1");
  const [isDisabled, setIsDisabled] = useState(false);
  const [yearsList, setYearsList] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [masterCatFilter, setMasterCatFilter] = useState([]);
  const [postawardagencyArray, setPostAwardAgencyArray] = useState([]);
  const [postawardtypeArray, setPostAwardTypeArray] = useState([]);
  const [sortRecord, setSortRecord] = useState("Upcoming Due Dates");
  const [sortMaster, setSortMaster] = useState([]);
  const [isClearIcon, setIsClearIcon] = useState(false);
  const [isChildIncludes, setIsChildIncludes] = useState(false);
  const [isStatusColumnChange, setIsStatusColumnChange] = useState(false);
  const [isStatusChangeOk, setIsStatusChangeOk] = useState(false);
  const [isDenied, setIsDenied] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [initialOptions, setInitialOptions] = useState();

  // const data = customers;
  // const [visibleApps, setVisibleApps] = useState(props.projects);
  // const [showFilter, setShowFilter] = useState(false);
  const [selectedClientFiling, setSelectedClientFiling] = useState([]);
  const [selectedFilingRecord, setSelectedFilingRecord] = useState(null);
  const [upcomingCatFilter, setUpcomingCatFilter] = useState([]);
  const [age, setAge] = React.useState("");
  // const [filteredStateCategories, setFilteredStateCategories] = useState([]);
  // const [filteredFederalCategories, setFilteredFederalCategories] = useState(
  //   []
  // );

  // const [selectedClient, setSelectedClient] = useState(null);
  // const [rowClick, setRowClick] = useState(true);

  const [data, setData] = useState([]);
  const [jurisdiction, setJurisdiction] = useState([]);
  const [options, setOptions] = React.useState("");

  const [lazyLoading, setLazyLoading] = useState(false);
  const [refreshRecordList, setRefreshRecordList] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(null);
  const [recordsInView, setRecordsInView] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFilterChange, setIsFilterChange] = useState([]);
  const [sortingData, setSortingData] = useState({
    sortBy: "",
    sortOrder: "",
  });
  const [upcomingDueDateVal, setupcomingDueDateVal] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // filingId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // filingDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // dueDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    filingName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    dueDateFiling: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    internalDueDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    filingFrequency: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    filingStateInfo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    stateList: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    staffAssignedArray: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    staffassigned: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    customerName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ruleInfo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // formattedAffectedCompany: {
    //   operator: FilterOperator.AND,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    // },
    filingRequired: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    awardingAgencyName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    federalCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    stateCategories: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    // jsidept: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jsicontactName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // jsiContactEmail: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingJuristiction: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    GrantTermcomplete: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    whereToFile: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });

  //const [federalFilterSelectedValues, setFederalFilterSelectedValues] = useState([])
  const [federalFilterSelectedValues, setFederalFilterSelectedValues] =
    React.useState({
      federalCategories: [],
    });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [viewAll, setViewAll] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [grantTermConfrimation, setGrantTermConfrimation] = useState(false);
  const [grantTermVal, setGrantTermVal] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [federalCategoriesLookup, setFederalCategoriesLookup] = useState([]);
  // const [stateCategoriesLookup, setStateCategoriesLookup] = useState([]);
  // const [categoriesLookup, setCategoriesLookup] = useState([]);
  const [federalCatFilter, setFederalCatFilter] = useState([]);
  const [stateCatFilter, setStateCatFilter] = useState([]);

  const [dateFilter, setDateFilter] = useState(null);
  FilterService.register("customFilter_not", (item, filterBy) => {
    return item !== undefined && !item?.includes(filterBy);
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    //let _filters = { ...filters };

    // _filters["global"].value = value;
    // setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const search = useRef(null);

  // const applyGlobalFilter = () => {
  //   console.log("REF CHECK", search);
  //   let _filters = { ...filters };
  //   _filters["global"].value = search.current.value;
  //   setFilters(_filters);
  // };

  // const applyGlobalFilter = () => {
  //   let searchVal;
  //   console.log("ICON CHECK ENT", "SEARCH", search, "searchVal", searchVal);
  //   console.log();
  //   if (isClearIcon) {
  //     search.current = null
  //     console.log("ICON CHECK TRUE", "SEARCH", search, "SEARCH", search.current, "val",);
  //     searchVal = null
  //     setIsClearIcon(false)
  //   } else {
  //     searchVal = search.current.value
  //     setIsClearIcon(true)
  //     console.log("ICON CHECK FALSE", "SEARCH", search, "searchVal", searchVal);
  //   }
  //   console.log("ICON CHECK AFT", "SEARCH", search, "searchVal", searchVal);
  //   let _filters = { ...filters };
  //   _filters["global"].value = searchVal;
  //   setFilters(_filters);
  // };

  const applyGlobalFilter = () => {
    console.log("applyGlobalFilter", search.current.value, isClearIcon);
    let _filters = { ...filters };
    if (isClearIcon) {
      search.current.value = "";
      _filters["global"].value = "";
      setIsClearIcon(false);
      onFilterChange({ filters });
    } else {
      _filters["global"].value = search.current.value;
      setIsClearIcon(true);
      onFilterChange({ filters });
    }
    setFilters(_filters);
  };

  const customDateFilter = (value, filter) => {
    if (!filter.dateFrom || !filter.dateTo) {
      return true;
    }
    return (
      value.getTime() >= filter.dateFrom.getTime() &&
      value.getTime() <= filter.dateTo.getTime()
    );
  };

  const onDateFilterChange = (event) => {
    setDateFilter(event.value);
  };
  const handleChange = (event) => {
    setOptions(event.target.value);
  };
  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => {
          return options.filterCallback(e.value, options.index);
        }}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const filingPeriodFilterTemplate = (options) => {
    return (
      <div>
        <Calendar
          value={options.value}
          onChange={(e) => {
            return options.filterCallback(e.value, options.index);
          }}
          dateFormat="mm/dd/yy"
          placeholder="Start Date"
          mask="99/99/9999"
        />
        <Calendar
          value={options.value}
          onChange={(e) => {
            return options.filterCallback(e.value, options.index);
          }}
          dateFormat="mm/dd/yy"
          placeholder="End Date"
          mask="99/99/9999"
        />
      </div>
    );
  };

  const jusrisdictionItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <span>{option}</span>
      </div>
    );
  };

  const jusrisdictionArray = ["Federal", "State", "Both"];

  const jurisdictionRowFilterTemplate = (options) => {
    if (
      !federalFilterSelectedValues.jurisdiction &&
      options?.filterModel?.value
    ) {
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <Dropdown
        value={federalFilterSelectedValues.jurisdiction || []}
        options={jusrisdictionArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, jurisdiction: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
    // options.filterModel?.matchMode &&
    //   options.filterModel.matchMode === "in" ? (
    //   <MultiSelect
    //     value={federalFilterSelectedValues.jurisdiction || []}
    //     options={jurisdiction}
    //     itemTemplate={jusrisdictionItemTemplate}
    //     onChange={(e) => {
    //       setFederalFilterSelectedValues((prev) => {
    //         return { ...prev, jurisdiction: e.value };
    //       });
    //       options.filterApplyCallback(e.value);
    //     }}
    //     placeholder="Any"
    //     maxSelectedLabels={1}
    //     className="p-column-filter"
    //     style={{ minWidth: "14rem", maxWidth: "14rem" }}
    //   />
    // ) : (

    // );
  };

  const requiredArray = ["True", "False"];

  const requiredRowFilterTemplate = (options) => {
    // console.log("requiredRowFilterTemplate:", options,masterCatFilter);
    if (
      !federalFilterSelectedValues.jurisdiction &&
      options?.filterModel?.value
    ) {
      // console.log("requiredRowFilterTemplate filterModel",options.filterModel);
      options.filterModel.value = null;
      options.value = null;
    }

    return (
      <Dropdown
        value={federalFilterSelectedValues.filingRequired || []}
        options={requiredArray}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, filingRequired: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const statusRowFilterTemplate = (options) => {
    // console.log("##Options:", options, "matchmode", options.filterModel.matchMode,);
    // console.log("federalFilterSelectedValuesCheck", federalFilterSelectedValues);
    if (!federalFilterSelectedValues?.status && options?.filterModel?.value) {
      options.filterModel.value = null;
      options.value = null;
    }
    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={federalFilterSelectedValues.status || []}
        options={statusLookup}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, status: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "auto", maxWidth: "auto" }}
      />
    ) : (
      <Dropdown
        value={federalFilterSelectedValues.status || []}
        options={statusLookup}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, status: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "auto", maxWidth: "auto" }}
      />
    );
  };

  const stateRowFilterTemplate = (options) => {
    if (!federalFilterSelectedValues.stateList && options.filterModel.value) {
      options.filterModel.value = [];
      options.value = [];
    }
    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={federalFilterSelectedValues.stateList || []}
        options={stateList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, stateList: e.value };
          });
          options.filterCallback(e.value, options.index);
          //options.filterApplyCallback(e.value);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "12rem" }}
      />
    ) : (
      <Dropdown
        value={federalFilterSelectedValues.stateList || []}
        options={stateList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, stateList: e.value };
          });

          let values = [];

          if (options?.filterModel?.matchMode === "equals") {
            values.push(e.value);
          } else if (options?.filterModel?.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "12rem" }}
      />
    );
  };

  const [clientFiling, setClientFiling] = useState([]);
  const [clientFilingHistory, setClientFilingHistory] = useState([]);
  const [initialClientFilingHistory, setInitialClientFilingHistory] = useState(
    []
  );
  const [initialValue, setInitialValue] = useState([]);
  const [initialValueUpc, setInitialValueUpc] = useState([]);
  useEffect(() => {
    let list = states.map((ele) => ele.state);
    setStateList(list);
    return () => {
      clearClientFilingTrackers && clearClientFilingTrackers();
    };
  }, []);
  useEffect(() => {
    if (isDenied) {
      cellStatusEditor(initialOptions);
    }
  }, [isDenied]);
  useEffect(() => {
    // console.log(
    //   "isStatusColumnChange",
    //   isStatusColumnChange,
    //   "isStatusChangeOk",
    //   isStatusChangeOk
    // );
    if (isStatusColumnChange && !isStatusChangeOk && isChildIncludes) {
      setInfoMessage(
        "Do you want to update all affected companies status for this filing ?"
      );
      setShowInfo(true);
    }
  }, [isStatusColumnChange, isDenied]);

  const getStateName = (stateCode) => {
    const state = states.find((s) => s.code === stateCode);
    return state ? state.state : stateCode;
  };
  // useEffect(() => {
  //   if (isDenied) {
  //     cellStatusEditor(initialOptions)
  //   }
  // }, [isDenied])
  // useEffect(() => {
  //   // console.log(
  //   //   "isStatusColumnChange",
  //   //   isStatusColumnChange,
  //   //   "isStatusChangeOk",
  //   //   isStatusChangeOk
  //   // );
  //   if (isStatusColumnChange && !isStatusChangeOk && isChildIncludes) {
  //     setInfoMessage(
  //       "Do you want to update all affected companies status for this filing ?"
  //     );
  //     setShowInfo(true);
  //   }
  // }, [isStatusColumnChange, isDenied]);
  const cellListDisplayState = (rowData) => {
    return rowData.stateCategories?.join(", ");
  };

  const CellListDisplayFederal = (rowData, field) => {
    const op = useRef(null);

    let displayFederal = Array.isArray(rowData[field])
      ? rowData[field].join(", ")
      : rowData[field];

    const categories = rowData[field]
      ? field === "federalCategories"
        ? rowData.businessCategory.map((ele) => ele.businessCategoryName)
        : rowData.businessCategory
            .filter((ele) => ele.state === rowData.filingStateInfo)
            .map((ele) => ele.businessCategoryName)
      : [];

    let name =
      field === "federalCategories"
        ? "Who Must File Federal"
        : "Who Must File in State";
    return rowData.recordType === "TrackNow" ? (
      <div
        className="tableBtn"
        id={`${field}+${rowData.fileTrackingId}`}
        style={{
          width: "inherit",
        }}
      >
        <PrimeButton
          onClick={(e) => op.current.toggle(e)}
          text
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              width: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "black",
            }}
          >
            {displayFederal}
          </div>
        </PrimeButton>
        <OverlayPanel ref={op} showCloseIcon style={{ padding: "0rem 1rem" }}>
          {name}
          <ScrollPanel style={{ maxWidth: "25rem", maxHeight: "20rem" }}>
            <ul>
              {categories.map((ele) => (
                <li>{ele}</li>
              ))}
            </ul>
          </ScrollPanel>
        </OverlayPanel>
      </div>
    ) : (
      <div
        style={{
          color: "black",
          textTransform: "uppercase",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {displayFederal}
      </div>
    );
  };

  const CellAffectedCompaniesEditorBody = (rowData, field) => {
    // console.log("CellAffectedCompaniesEditorBody selectedCustomer", selectedCustomer, rowData, field);
    return detailedViewVal ||
      selectedCustomer.parentCustomer !== null ||
      selectedCustomer?.isOnlyParentFile ? (
      rowData.customerName
    ) : (
      <a
        href="#"
        onClick={(e) => viewAffectedCompanies(e, rowData, field)}
        style={{
          color: "blue",
          textDecoration: "none",
          // transition: "color 0.1s ease, text-decoration 0.1s ease",
        }}
        onMouseEnter={(e) => {
          e.target.style.color = "#001FA9";
          e.target.style.textDecoration = "underline";
        }}
        onMouseLeave={(e) => {
          e.target.style.color = "blue";
          e.target.style.textDecoration = "none";
        }}
      >
        View Affected Companies
      </a>
    );
  };

  const [anchorEl, setAnchorEl] = React.useState({});
  const [anchorE2, setAnchorE2] = React.useState({});
  const [anchorE3, setAnchorE3] = React.useState({});

  const CellListDisplayStaffAssigned = (rowData, field) => {
    // console.log("CellListDisplayStaffAssigned rowData", rowData, "field", field);

    const open = Boolean(anchorEl[rowData.fileTrackingId]);
    const id = open ? "simple-popover" : undefined;
    // const op = useRef(null);

    let displayStaffAssigned =
      Array.isArray(rowData["staffAssignedArray"]) &&
      rowData["staffAssignedArray"].length > 0
        ? rowData["staffAssignedArray"].map((ele) => ele.name).join(", ")
        : rowData["staffAssignedArray"];

    const staffassigned =
      rowData.staffAssignedArray && rowData["staffAssignedArray"].length > 0
        ? rowData.staffAssignedArray // Check if it's a string before attempting to split
        : [];
    const handleClose = () => {
      setAnchorEl((old) => ({ ...old, [rowData.fileTrackingId]: null }));
    };

    return detailedViewVal ||
      selectedCustomer.parentCustomer !== null ||
      selectedCustomer?.isOnlyParentFile ? (
      rowData.staffAssignedArray.length > 0 ? (
        <div
          className="tableBtn"
          id={`staffassigned${field}+${rowData.fileTrackingId}`}
          style={{
            width: "inherit",
          }}
        >
          <PrimeButton
            onClick={(e) =>
              setAnchorEl((old) => ({
                ...old,
                [rowData.fileTrackingId]: e.currentTarget,
              }))
            }
            text
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "inherit",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "black",
              }}
            >
              {displayStaffAssigned}
            </div>
          </PrimeButton>
          <Popover
            className="scrollbar-tracker"
            id={id}
            open={open}
            disableScrollLock
            anchorEl={anchorEl[rowData.fileTrackingId]}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "top",
            }}
            // style={{ padding: '0rem 1rem', position: "fixed" }}
          >
            {/* <div style={{ textAlign: "center", marginTop: "0.75rem", marginLeft: "-2.938rem" }}>Staff Assigned</div>
          <button style={{ position: 'absolute', top: '0rem', height: '2rem', left: "12rem", borderRadius: "50%", border: "1px", backgroundColor: "#6366f1" }}
            onClick={handleClose}>
            <CloseIcon style={{ color: "white", cursor: "pointer" }} />
          </button> */}

            <Stack
              className="affectedCompanies"
              direction="row"
              flexDirection="row"
              justifyContent="space-between"
              sx={{ margin: "20px 20px 0 20px" }}
            >
              <Typography>STAFF ASSIGNED</Typography>
              <PrimeButton onClick={handleClose}>
                <CloseIcon style={{ color: "white", cursor: "pointer" }} />
              </PrimeButton>
            </Stack>

            <ScrollPanel
              style={{
                width: "18.375rem",
                //padding: "6px 19px 28px 28px",
                height: "8.625rem",
                overflow: "hidden",
              }}
            >
              <Stack direction="column" spacing={0.5} mt={1}>
                <ul>
                  {rowData.staffAssignedArray.length > 0 &&
                    rowData.staffAssignedArray.map((ele) => (

                      <li key={ele.name}>{ele.name}</li>

                    ))}
                </ul>
              </Stack>
            </ScrollPanel>
          </Popover>
        </div>
      ) : (
        <div
          style={{
            width: "inherit",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "black",
          }}
        >
          {displayStaffAssigned}
        </div>
      )
    ) : (
      <a
        href="#"
        onClick={(e) => viewAffectedCompanies(e, rowData, field)}
        style={{
          color: "blue",
          textDecoration: "none",
          // transition: "color 0.1s ease, text-decoration 0.1s ease",
        }}
        onMouseEnter={(e) => {
          e.target.style.color = "#001FA9";
          e.target.style.textDecoration = "underline";
        }}
        onMouseLeave={(e) => {
          e.target.style.color = "blue";
          e.target.style.textDecoration = "none";
        }}
      >
        View Staff Assigned
      </a>
    );
  };

  const cellListDisplayName = (rowData) => {
    return rowData.isMasterFiling ? (
      <LightTooltip title={rowData.filingName}>
        <div
          style={{
            width: "inherit",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.filingName}
        </div>
      </LightTooltip>
    ) : (
      <LightTooltip title={rowData.filingName}>
        <div
          style={{
            width: "inherit",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          {rowData.filingName}
        </div>
      </LightTooltip>
    );
  };

  const cellListDisplayRuleInfo = (rowData) => {
    return (
      <LightTooltip title={rowData.ruleInfo}>
        <div
          style={{
            width: "inherit",
            margin: "0 1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.ruleInfo}
        </div>
      </LightTooltip>
    );
  };

  // const cellListDisplayAffectedComp = (rowData, field) => {
  //   const open = Boolean(anchorE2[rowData.fileTrackingId]);
  //   const id = open ? "simple-popover" : undefined;
  //   const handleClose = () => {
  //     setAnchorE2((old) => ({ ...old, [rowData.fileTrackingId]: null }));
  //   };

  //   let findParent;
  //   if (
  //     rowData.parentCustomer !== null &&
  //     rowData.parentCustomer !== "" &&
  //     rowData.parentCustomer !== 0
  //   ) {
  //     findParent = rowData.affectedCompanies.find(
  //       (ele) => ele.type === "Parent"
  //     );
  //   } else {
  //     findParent = rowData.affectedCompanies.find(
  //       (ele) => ele.type === "DefaultClient"
  //     );
  //   }

  //   let popupData = [...rowData.affectedCompanies];
  //   popupData.forEach(function (item, i) {
  //     if (
  //       findParent &&
  //       findParent.customerId &&
  //       item.customerId === findParent.customerId
  //     ) {
  //       popupData.splice(i, 1);
  //       popupData.unshift(item);
  //     }
  //   });

  //   return (
  //     rowData?.formattedAffectedCompany?.length > 0 && (
  //       // <div>
  //       //  {rowData?.formattedAffectedCompany?.length > 1 ? (
  //       <div
  //         className='tableBtn'
  //         id={`affectedCompanies${field}+${rowData.fileTrackingId}`}
  //         style={{
  //           width: "inherit",
  //         }}
  //       >
  //         <PrimeButton
  //           onClick={(e) =>
  //             setAnchorE2((old) => ({
  //               ...old,
  //               [rowData.fileTrackingId]: e.currentTarget,
  //             }))
  //           }
  //           text
  //           style={{
  //             width: "100%",
  //           }}
  //         >
  //           {rowData.formattedAffectedCompany.length > 0 && (
  //             <div
  //               style={{
  //                 width: "inherit",
  //                 overflow: "hidden",
  //                 textOverflow: "ellipsis",
  //                 color: "black",
  //               }}
  //             >
  //               {/* {rowData.formattedAffectedCompany.join(", ")} */}
  //               {rowData.formattedAffectedCompany.map((company, index) => (
  //                 <span key={index}>
  //                   {company.customerName}
  //                   {index < rowData.formattedAffectedCompany.length - 1
  //                     ? ", "
  //                     : ""}
  //                 </span>
  //               ))}
  //             </div>
  //           )}
  //         </PrimeButton>
  //         <Popover
  //           className='scrollbar-tracker'
  //           id={id}
  //           open={open}
  //           disableScrollLock
  //           anchorEl={anchorE2[rowData.fileTrackingId]}
  //           onClose={handleClose}
  //           anchorOrigin={{
  //             vertical: "bottom",
  //             horizontal: "top",
  //           }}
  //           style={{
  //             minWidth: "23rem", // Adjust the minimum width as needed
  //             maxHeight: "23rem", // Adjust the maximum height as needed
  //           }}
  //         //  style={{ padding: '0rem 1rem', position: "fixed" }}
  //         >
  //           <Stack
  //             className='affectedCompanies'
  //             direction='row'
  //             flexDirection='row'
  //             justifyContent='space-between'
  //             // sx={{ margin: "20px 20px 0 20px" }}
  //             style={{
  //               minWidth: "23rem", // Adjust the minimum width as needed
  //               maxHeight: "23rem", // Adjust the maximum height as needed
  //               margin: "20px 20px 0 20px",
  //             }}
  //           >
  //             <Typography>AFFECTED COMPANIES</Typography>
  //             <PrimeButton onClick={handleClose}>
  //               <CloseIcon style={{ color: "white", cursor: "pointer" }} />
  //             </PrimeButton>
  //           </Stack>

  //           <ScrollPanel
  //             style={{
  //               // width: "18.375rem",
  //               // // padding: "6px 19px 28px 28px",
  //               // // scrollbarColor: "auto",

  //               // height: "8.625rem",
  //             }}
  //           >
  //             <Stack direction='column' spacing={0.5} mt={1}>
  //               <ul>
  //                 <>
  //                   {popupData.length > 0 &&
  //                     popupData.map((ele, i) => (
  //                       <li
  //                         style={{
  //                           marginBottom: "0.5rem",
  //                           textDecoration:
  //                             ele.status === "Completed"
  //                               ? "line-through"
  //                               : "none",
  //                         }}
  //                         key={ele}
  //                       >
  //                         {ele.customerName}{" "}
  //                         {findParent &&
  //                           findParent.customerId &&
  //                           findParent.customerId === ele.customerId &&
  //                           `- (Parent)`}{" "}
  //                         {""}-{ele.status}
  //                       </li>
  //                     ))}
  //                 </>
  //               </ul>
  //             </Stack>
  //           </ScrollPanel>
  //         </Popover>
  //       </div>
  //     )
  //   );
  // };
  // return (
  //   <LightTooltip title={rowData.affectedCompanies}>
  //     <div
  //       style={{
  //         width: 'inherit',
  //         margin: '0 1rem',
  //         overflow: 'hidden',
  //         textOverflow: 'ellipsis',
  //       }}
  //     >
  //       {rowData.affectedCompanies}
  //     </div>
  //   </LightTooltip>
  // );
  // };

  const cellListDisplayNotes = (rowData, field) => {
    const open = Boolean(anchorE3[rowData.fileTrackingId]);
    const id = open ? "simple-popover" : undefined;
    const handleClose = () => {
      setAnchorE3((old) => ({ ...old, [rowData.fileTrackingId]: null }));
    };

    const notesUrl = rowData?.notes;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    //  if (notesUrl) {
    const formattedText = notesUrl?.replace(
      urlRegex,
      '<a style="color: #0000FF;" href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
    );

    //   return (
    //     <LightTooltip
    //       title={
    //         <React.Fragment>
    //           <div dangerouslySetInnerHTML={{ __html: formattedText }} />
    //         </React.Fragment>
    //       }
    //     >
    //       <div
    //         style={{
    //           width: "inherit",
    //           margin: "0 1rem",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //         }}
    //         dangerouslySetInnerHTML={{ __html: formattedText }}
    //       />
    //     </LightTooltip>
    //   );
    // }
    // return notesUrl;

    return rowData?.notes?.length > 0 ? (
      <div
        className="tableBtn"
        id={`notes${field}+${rowData.fileTrackingId}`}
        style={{
          width: "inherit",
        }}
      >
        <PrimeButton
          onClick={(e) =>
            setAnchorE3((old) => ({
              ...old,
              [rowData.fileTrackingId]: e.currentTarget,
            }))
          }
          text
          style={{
            width: "100%",
          }}
        >
          {rowData.notes.length > 0 && (
            <div
              style={{
                // width: "inherit",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "black",
              }}
              dangerouslySetInnerHTML={{ __html: formattedText }}
            ></div>
          )}
        </PrimeButton>
        <Popover
          className="scrollbar-tracker"
          id={id}
          open={open}
          disableScrollLock
          anchorEl={anchorE3[rowData.fileTrackingId]}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "top",
          }}
          style={{
            minWidth: "15rem", // Adjust the minimum width as needed
            maxWidth: "60rem", // Adjust the minimum width as needed
            maxHeight: "25rem", // Adjust the maximum height as needed
          }}
        >
          <Stack
            className="notes"
            direction="row"
            flexDirection="row"
            justifyContent="space-between"
            // sx={{ margin: "20px 20px 0 20px" }}
            style={{
              minWidth: "15rem",
              maxWidth: "60rem", // Adjust the minimum width as needed
              maxHeight: "5rem", // Adjust the maximum height as needed
              margin: "20px 20px 0 20px",
            }}
          >
            <Typography>NOTES</Typography>
            <PrimeButton onClick={handleClose}>
              <CloseIcon style={{ color: "white", cursor: "pointer" }} />
            </PrimeButton>
          </Stack>
          <ScrollPanel>
            <Stack direction="column" spacing={0.5} mt={1}>
              <div
                style={{
                  // width: "inherit",
                  margin: "0 1rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                dangerouslySetInnerHTML={{ __html: formattedText }}
              />
            </Stack>
          </ScrollPanel>
        </Popover>
      </div>
    ) : (
      <div style={{ display: "none" }}></div>
    );
  };

  const cellListDisplayWhereToFile = (rowData) => {
    const whereToFileValue = rowData.whereToFile;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (whereToFileValue) {
      const formattedText = whereToFileValue.replace(
        urlRegex,
        '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
      );

      return (
        <LightTooltip
          title={
            <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: formattedText }} />
            </React.Fragment>
          }
        >
          <div
            style={{
              width: "inherit",
              margin: "0 1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </LightTooltip>
      );
    }

    return whereToFileValue;
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F1F2F6",
      color: "black",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  // const federalCategoryRowFilterTemplate = (options) => {
  //   console.log("##Options5:", options);

  //   return (
  //     <MultiSelect
  //       value={federalCatFilter}
  //       options={federalCategoriesLookup}
  //       itemTemplate={jusrisdictionItemTemplate}
  //       onChange={(e) => {
  //         console.log("##EOptions:", e.value);
  //         setFederalCatFilter(e.value);
  //         const fedRows = clientFiling.filter(
  //           (row) => row.juristiction === "Federal"
  //         );
  //         const selectedIds = fedRows
  //           .filter((row) => {
  //             return e.value.some((category) =>
  //               row.federalCategories.includes(category)
  //             );
  //           })
  //           .map((item) => item.filingId);
  //         console.log("##selectedIds:", selectedIds);

  //         options.filterApplyCallback(selectedIds);
  //       }}
  //       placeholder="Any"
  //       maxSelectedLabels={1}
  //       className="p-column-filter"
  //       style={{ minWidth: "14rem", maxWidth: "14rem" }}
  //     />
  //   );
  // };
  // const stateCategoryRowFilterTemplate = (options) => {
  //   console.log("##Options:", options);
  //   return (
  //     <MultiSelect
  //       value={stateCatFilter}
  //       options={stateCategoriesLookup}
  //       itemTemplate={jusrisdictionItemTemplate}
  //       onChange={(e) => {
  //         console.log("##EOptions:", e.value);
  //         setStateCatFilter(e.value);
  //         const stateRows = clientFiling.filter(
  //           (row) => row.juristiction === "State"
  //         );
  //         const selectedIds = stateRows
  //           .filter((row) => {
  //             return e.value.some((category) =>
  //               row.stateCategories.includes(category)
  //             );
  //           })
  //           .map((item) => item.filingId);
  //         options.filterApplyCallback(selectedIds);
  //       }}
  //       placeholder="Any"
  //       maxSelectedLabels={1}
  //       className="p-column-filter"
  //       style={{ minWidth: "14rem", maxWidth: "14rem" }}
  //     />
  //   );
  // };
  const customCategoryFilter = (value, filter) => {
    filter.filterApplyCallback(value);
    // return value.some((cateogory) => cateogory.includes(filter)); // check if at least one skill matches filter
  };
  const filteredData = clientFiling.filter((row) => {
    if (row.jurisdiction === "Federal") {
      return federalCatFilter.some((category) =>
        row.federalCategories.includes(category)
      );
    } else if (row.jurisdiction === "State") {
      return stateCatFilter.some((category) =>
        row.stateCategories.includes(category)
      );
    }
    return true;
  });

  const footerContent = (
    <div>
      <PrimeButton
        label="OK"
        icon="pi pi-check"
        onClick={() => closeAlert()}
        autoFocus
      />
    </div>
  );
  // const footerContentInfo = (
  //   <div>
  //     <PrimeButton
  //       label='No'
  //       icon='pi pi-check'
  //       onClick={() => closeRowEditing()}
  //       autoFocus
  //     />
  //     {/* <PrimeButton
  //       label='Yes'
  //       icon='pi pi-check'
  //       onClick={() => closeInfo()}
  //       autoFocus
  //     /> */}
  //   </div>
  // );

  const closeAlert = () => {
    setShowAlert(false);
    // scrollToSelected()
  };
  // const closeInfo = () => {
  //   setIsStatusChangeOk(true);
  //   setIsDenied(false)
  //   setShowInfo(false);
  // };

  const formatDate = (value) => {
    return value !== null
      ? value?.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : null;
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.date);
  };

  const cellEditorBody = (rowData) => {
    // console.log('cellEditorBody', rowData['internalDueDate']);
    return formatDate(rowData.internalDueDate);
  };

  const cellFilingDateBody = (rowData, field) => {
    return formatDate(rowData.dueDateFiling);
  };

  const cellEditor = (options) => {
    console.log("options", options);
    return (
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Calendar
          value={options.value ? new Date(options.value) : options.value}
          disabled={
            (options.rowData.initialGrantTermComplete &&
              options.rowData.initialGrantTermComplete === "Yes") ||
            (!detailedViewVal &&
              options.rowData?.isOnlyChildFile &&
              options.rowData?.isUnderParent &&
              !options.rowData?.isOnlyParentFile)
          }
          onChange={(e) => options.editorCallback(e.value)}
          dateFormat="mm/dd/yy"
          placeholder="mm/dd/yyyy"
          mask="99/99/9999"
        />
      </Stack>
    );
  };

  const cellFilingEditor = (options, isDisable) => {
    console.log("options", options);
    return (
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Calendar
          value={options.value ? new Date(options.value) : options.value}
          disabled={!isDisable}
          onChange={(e) => options.editorCallback(e.value)}
          dateFormat="mm/dd/yy"
          placeholder="mm/dd/yyyy"
          mask="99/99/9999"
        />
      </Stack>
    );
  };

  const onRowEditComplete = async (e) => {
    // console.log('onRowEditComplete', e);
    if (
      e.data.staffAssignedArray !== e.newData.staffAssignedArray ||
      e.data["dueDateFiling"] !== e.newData["dueDateFiling"] ||
      e.data["status"] !== e.newData["status"] ||
      e.data["grantTermComplete"] !== e.newData["grantTermComplete"]
    ) {
      // console.log('val-check-grant', e);
      if (e.data["status"] !== e.newData["status"]) {
        // console.log("onRowEditComplete Mainstatus change ", "e.data", e.data["status"], e.newData["status"]);

        saveNewField(e.data, e.newData, true);
      } else {
        console.log("onRowEditComplete Mainstatus notchange");

        saveNewField(e.data, e.newData, false);
      }
    }
    // console.log("onRowEditComplete end", "e.data", e.data["status"], e.newData["status"]);

    // selectFilingRecord(e.newData);
    //   saveEditedField(e.newData);
  };

  let awardTypeArray = ["Direct Federal Award", "Pass Through Award"];

  const postawardTypeRowFilterTemplate = (options) => {
    let awardTypeOptionList = postawardtypeArray.sort();
    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter.awardTypeName || []}
        options={awardTypeOptionList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardAgencyRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, awardTypeName: e.value };
          });
          options.filterApplyCallback(e.value);
          //options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter.awardTypeName || []}
        options={awardTypeOptionList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardAgencyRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, awardTypeName: e.value };
          });
          options.filterApplyCallback(e.value);
          //options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    );
  };
  const postawardAgencyRowFilterTemplate = (options) => {
    const awardAgenciesOptions = awardAgenciesList.map(
      (item) => item.awardingAgencyName
    );
    console.log(
      "postawardAgencyRowFilterTemplate",
      "postawardagencyArray",
      postawardagencyArray,
      "awardAgenciesList",
      awardAgenciesList,
      "awardAgenciesOptions",
      awardAgenciesOptions
    );

    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={masterCatFilter.awardingAgencyName || []}
        options={awardAgenciesOptions}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardAgencyRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, awardingAgencyName: e.value };
          });
          options.filterCallback(e.value, options.index);
          //options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    ) : (
      <Dropdown
        value={masterCatFilter.awardingAgencyName || []}
        options={awardAgenciesOptions}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          // console.log(" postawardAgencyRowFilterTemplate:", e.value, masters);
          setMasterCatFilter((prev) => {
            return { ...prev, awardingAgencyName: e.value };
          });
          options.filterCallback(e.value, options.index);
          //options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "12rem", maxWidth: "13rem" }}
      />
    );
  };

  const onRowEditCancel = (e) => {
    // setIsStatusColumnChange(false);
    // setIsStatusChangeOk(false);
    // setSelectedValues([]);
    // setIsDenied(false)
  };
  // const onRowEditInit = (e) => {
  //   console.log('editStatus onRowEditInit', e, dt)

  //   if (editingRow !== null && editingRow !== e.index) {
  //     // Set the rowEditorValidator to a function that always returns true,
  //     // effectively canceling the row edit for the previous row
  //     console.log('editStatus onRowEditInit2', e,dt)
  //     dt.current.props.rowEditorValidator = () => true;
  //     // Trigger a re-render to apply the change in rowEditorValidator
  //     setEditingRow(null);
  //   }

  //   setEditingRow(e.index);
  // };

  const cellStatusEditorBody = (rowData) => {
    return rowData["status"];
  };

  const grantTermFooterContent = (
    <div>
      <PrimeButton
        label="No"
        icon="pi pi-check"
        onClick={() => grantTermCloseAlert()}
        autoFocus
      />
      <PrimeButton
        label="Yes"
        icon="pi pi-check"
        onClick={() => {
          grantTermVal.editorCallback("Yes");
          grantTermCloseAlert();
        }}
        autoFocus
      />
    </div>
  );

  const grantTermCloseAlert = () => {
    setGrantTermConfrimation(false);
  };

  const cellGrantTermcompleteEditorBody = (rowData) => {
    let val =
      rowData.recordType === "TrackNow"
        ? ""
        : rowData["grantTermComplete"] === "Yes" ||
          rowData["grantTermComplete"] === true
        ? "Yes"
        : "No";
    return val;
  };

  const fetchConsolidatedRecords = async (rowData) => {
    console.log("fetchConsolidatedRecords filingId", rowData);
    try {
      const consRecords = await axios.get(
        `${API_BASE_URL}/Customer/CustomerFileTrackingConsolidate?CustomerId=${rowData.customerId}&FilingId=${rowData.filingId}&Startdate=${rowData.startdate}`
      );

      console.log("fetchConsolidatedRecords consRecords", consRecords);

      return consRecords.data.data; // return the data received from the API
    } catch (error) {
      console.log("@@Error:", error);
      throw error; // re-throwing error to be caught by the calling function if needed
    }
  };

  const viewAffectedCompanies = async (e, rowData, field) => {
    e.preventDefault();
    console.log("viewAffectedCompanies props", rowData, field);
    const res = await fetchConsolidatedRecords(rowData);
    console.log("viewAffectedCompanies res", res);
    if (res) {
      if (field === "affectedCompanies") {
        let companyNames = res.map((ele) => ele.customerName);
        console.log("viewAffectedCompanies companyNames", companyNames);
        setAffectedCompaniesDetails({
          recordName: rowData.filingName,
          list: companyNames,
        });
        setShowAffectedCompanies(true);
      } else {
        const staffAssignedArray = res.reduce((acc, curr) => {
          // Concatenate the nested staffAssigned arrays of each object
          return acc.concat(curr.staffAssigned);
        }, []);
        console.log(
          "viewAffectedCompanies staffAssignedArray",
          staffAssignedArray
        );

        // setStaffAssignedDetails({})
        const uniqueEntries = {};
        console.log("uniqueEntries", uniqueEntries);
        // Iterate through staffAssignedArray
        const uniqueUserNames = staffAssignedArray.reduce((acc, curr) => {
          // Generate a unique key based on filingId and userId
          const key = `${curr.filingId}-${curr.userId}`;

          // If the key doesn't exist in uniqueEntries, add it and store the userName
          if (!uniqueEntries[key]) {
            uniqueEntries[key] = true; // Mark the entry as seen
            acc.push(curr.userName); // Store the userName
          }

          return acc;
        }, []);
        console.log("viewAffectedCompanies uniqueUserNames", uniqueUserNames);
        setStaffAssignedDetails({
          recordName: rowData.filingName,
          list: uniqueUserNames,
        });
        setShowStaffAssigned(true);
      }
    }
  };

  const handleGrantTermChange = async (e, options) => {
    if (e.value.code === "Yes") {
      console.log("handleGrantTermChange", "e, options", e, options);
      // Step 1: Filter Client Filing Data based on FilingId

      // const filteredData = clientFiling.filter(
      //   (record) => record.filingId === options.rowData.filingId
      // );

      const res = await axios.get(
        `${API_BASE_URL}/Customer/TrackerFilingsBasedOnFrequency/${options.rowData.customerId}/${options.rowData.filingId}`
      );
      const filteredData = res.data.data;
      // Step 2: Find Record with Lowest Start Date
      const lowestStartDateRecord = filteredData.filter(
        (ele) => new Date(ele.startdate) < new Date(options.rowData.startdate)
      );

      // Step 3: Check for 'Pending' Status
      let isPending = lowestStartDateRecord.find(
        (ele) => ele.status === "Pending"
      );
      // console.log("handleGrantTermChange", "filteredData", filteredData, "lowestStartDateRecord", lowestStartDateRecord, "isPending", isPending);

      if (isPending) {
        // Step 4: Display Alert Message
        setAlertMessage(
          "The record is in pending status for one or more previous periods. Please complete them before marking Grant Term Completed for the record."
        );
        setShowAlert(true);
      } else {
        setGrantTermVal(options);
        setGrantTermConfrimation(true);
      }
    } else {
      options.editorCallback(e.value.code);
    }
  };
  const cellGrantTermcompleteEditor = (options) => {
    const GrantTermcompleteDropdown = [
      { name: "Yes", code: "Yes" },
      { name: "No", code: "No" },
    ];

    let val = options.value === true || options.value === "Yes" ? "Yes" : "No";
    let initalGrantTermcomplete = { name: val, code: val };

    return (
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Dropdown
          value={initalGrantTermcomplete}
          disabled={
            (options.rowData.initialGrantTermComplete &&
              options.rowData.initialGrantTermComplete === "Yes") ||
            (!detailedViewVal &&
              options.rowData?.isOnlyChildFile &&
              options.rowData?.isUnderParent &&
              !options.rowData?.isOnlyParentFile)
          }
          onChange={(e) => {
            handleGrantTermChange(e, options);
          }}
          options={GrantTermcompleteDropdown}
          optionLabel="name"
          autoFocus
          style={{ position: "static" }}
        />
      </Stack>
    );
  };

  const cellStatusEditor = (options) => {
    const statusDropdown = [
      { name: "Pending", code: "Pending" },
      { name: "In Progress", code: "In Progress" },
      { name: "Approved", code: "Approved" },
      { name: "Completed", code: "Completed" },
    ];
    console.log("opt-check-status", options);
    let initalStatus = { name: options.value, code: options.value };
    console.log("init-status", initalStatus);
    // if (editingStatusCell === options.field && openStatusEditor) {
    console.log("editStatus cellStatusEditor");
    return (
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Dropdown
          value={initalStatus}
          disabled={
            (options.rowData.initialGrantTermComplete &&
              options.rowData.initialGrantTermComplete === "Yes") ||
            (
              options.rowData?.isOnlyChildFile &&
              options.rowData?.isUnderParent &&
              !options.rowData?.isOnlyParentFile && !detailedViewVal)
          }
          onChange={(e) => {
            // e.preventDefault();
            // onStatusEdit(e.value);
            options.editorCallback(e.value.code);
          }}
          options={statusDropdown}
          optionLabel="name"
          autoFocus
        />
      </Stack>
    );
  };
  const saveNewField = async (oldStaff, newStaff) => {
    // console.log('saveNewField newStaff ', newStaff, "oldStaff", oldStaff);

    const isRowEdit = true;
    // const utcDate =
    //   newStaff.internalDueDate !== null && newStaff.internalDueDate.getFullYear
    //     ? new Date(
    //       Date.UTC(
    //         newStaff.internalDueDate.getFullYear(),
    //         newStaff.internalDueDate.getMonth(),
    //         newStaff.internalDueDate.getDate()
    //       )
    //     )
    //     : newStaff.internalDueDate;
    props.resetKeepSelectedRecords([newStaff]);
    const utcFilingDate =
      newStaff.dueDateFiling !== null && newStaff.dueDateFiling.getFullYear
        ? new Date(
            Date.UTC(
              newStaff.dueDateFiling.getFullYear(),
              newStaff.dueDateFiling.getMonth(),
              newStaff.dueDateFiling.getDate()
            )
          )
        : newStaff.dueDateFiling;

    try {
      let payload = [
        {
          ...newStaff,
          // dueDate: utcDate,
          filingduedate: utcFilingDate,
          updateDate: new Date(),
          updateUser: authUser.userId + "",
          grantTermComplete:
            newStaff.grantTermComplete === "Yes" ? true : false,
        },
      ];
      delete payload[0].stateList;
      delete payload[0].staffassigned;

      //Exsisting staffs extraction
      const exUserIds = oldStaff.staffAssignedArray
        .filter((oldEle) =>
          newStaff.staffAssignedArray.some(
            (newEle) => newEle.code === oldEle.code
          )
        )
        .map((ele) => ele.code);
      const exsistingStaffsDetails = oldStaff.staffAssigned
        .filter((staff) => exUserIds.includes(staff.userId))
        .map((staff) => ({
          staffAssignedId: staff.staffAssignedId,
          userId: staff.userId,
          isDeleted: false,
        })); // Extracting and storing the exsisting staffs details
      console.log(
        "Staff-Assigned-Ass Exsisting ",
        "exUserIds",
        exUserIds,
        "exsistingStaffsDetails",
        exsistingStaffsDetails
      );

      //Removed Exsisting staffs
      const removedUserIds = oldStaff.staffAssignedArray
        .filter(
          (oldEle) =>
            !newStaff.staffAssignedArray.some(
              (newEle) => newEle.code === oldEle.code
            )
        )
        .map((ele) => ele.code);
      const removedStaffsDetails = oldStaff.staffAssigned
        .filter((staff) => removedUserIds.includes(staff.userId))
        .map((staff) => ({
          staffAssignedId: staff.staffAssignedId,
          userId: staff.userId,
          isDeleted: true,
        })); // Extracting and storing the removed staffs details

      //Newly added Staff Assigned
      const newLyAddedStaffs = newStaff.staffAssignedArray
        .filter(
          (newEle) =>
            !oldStaff.staffAssignedArray.some(
              (oldEle) => newEle.code === oldEle.code
            )
        )
        .map((ele) => ({
          staffAssignedId: 0,
          userId: ele.code,
          isDeleted: false,
        }));
      const staffAssignedPayload = [
        ...exsistingStaffsDetails,
        ...removedStaffsDetails,
        ...newLyAddedStaffs,
      ];
      payload[0].staffAssigned = staffAssignedPayload.map((ele) => ({
        staffAssignedId: ele.staffAssignedId,
        fileTrackingID: newStaff.fileTrackingId,
        userId: ele.userId,
        isDeleted: ele.isDeleted,
        createDate: new Date(),
        createUser: authUser.userId + "",
        updateDate: new Date(),
        updateUser: authUser.userId + "",
      }));
      // console.log('saveNewField payload', payload);
      const res = await axios.put(
        `${API_BASE_URL}/Customer/CustomerFileTrackingUpdate`,
        payload
      );
      // console.log('saveNewField res', res);
      setAlertMessage("Successfully updated the record");
      setShowAlert(true);
      const vScroll =
        value === "1"
          ? sortRecord === "Clear"
            ? dtUpcoming.current.getVirtualScroller()
            : dt.current.getVirtualScroller()
          : dtHistory.current.getVirtualScroller();

      let range = vScroll.getRenderedRange();
      console.log("refreshTable vScroll", vScroll, range);

      if (value === "1") {
        let pageInView;
        let noOfPages;
        if (clientFiling.length > 100) {
          pageInView = recordsInView - 2;
          noOfPages = 2;
        } else {
          pageInView = recordsInView - 1;
          noOfPages = 1;
        }
        let clientSelected = Array.isArray(newStaff)
          ? newStaff.map((ele) => ele)
          : [newStaff];
        console.log(
          "refreshTable clientSelected",
          selectedClientFiling,
          clientSelected
        );

        setRecordsInView(pageInView);
        setRefreshDetails({
          viewport: range.viewport,
          pageInView,
          noOfPages,
          clientSelected,
        });
        // fetchClientFilingTrackers(clientId, isRowEdit);
        setRecordsInView(0);
        setIsLoading(true);
        setClientFiling([]);
        setInitialValue([]);
        props.setClientFilingData([]);
        // setSelectedClientFiling([])
        // props.setSelectedFiling([])
        setRefreshRecordList(true);
      } else {
        let pageInView;
        let noOfPages;
        if (clientFilingHistory.length > 100) {
          pageInView = recordsInView - 2;
          noOfPages = 2;
        } else {
          pageInView = recordsInView - 1;
          noOfPages = 1;
        }
        let clientSelected = Array.isArray(newStaff)
          ? newStaff.map((ele) => ele)
          : [newStaff];
        console.log(
          "refreshTable clientSelected",
          selectedClientFiling,
          clientSelected
        );

        setRecordsInView(pageInView);
        setRefreshDetails({
          viewport: range.viewport,
          pageInView,
          noOfPages,
          clientSelected,
        });
        // fetchClientFilingTrackersHistory(isRowEdit);
        setRecordsInView(0);
        setIsLoading(true);
        setClientFilingHistory([]);
        setInitialClientFilingHistory([]);
        props.setClientFilingData([]);
        // setSelectedClientFiling([])
        // props.setSelectedFiling([])
        setRefreshRecordList(true);
      }
      setIsStatusChangeOk(false);
      setIsStatusColumnChange(false);
      setSelectedValues([]);
      setInitialOptions();
      setIsDenied(false);
    } catch (err) {
      console.log("saveNewField err", err);
    }
  };
  // if (data["fldAffectedCompany"] !== newStaff["fldAffectedCompany"] && !isMainStatusChange) {

  //   //Both parent and child company for status completed
  //   let addedAffectedCompany = newStaff.fldAffectedCompany.filter(newCompany => {
  //     return !data.fldAffectedCompany.some(oldCompany => oldCompany.code === newCompany.code);
  //   });

  //   //Both parent and child company for status In progress

  //   let removedAffectedCompanies = data.fldAffectedCompany.filter(oldCompany => {
  //     return !newStaff.fldAffectedCompany.some(newCompany => newCompany.code === oldCompany.code);
  //   });

  //   //parent company for status completed
  //   let addedParentConpany = addedAffectedCompany.find(company => company.code == payload.customerId);
  //   // parent company for status In progress
  //   let removedParentCompany = removedAffectedCompanies.find(company => company.code == payload.customerId);

  //   console.log("saveNewField addedAffectedCompany ", addedAffectedCompany, "removedAffectedCompanies", removedAffectedCompanies, "Payload", payload.customerId);

  //   //child company for status completed
  //   addedAffectedCompany = addedAffectedCompany.filter(company => company.code !== payload.customerId);

  //   //child company for status completed
  //   removedAffectedCompanies = removedAffectedCompanies.filter(company => company.code !== payload.customerId);

  //   console.log("saveNewField Modified Child addedAffectedCompany ", addedAffectedCompany, "removedAffectedCompanies", removedAffectedCompanies, "Payload", payload.customerId);
  //   console.log("saveNewField Modified Parent addedParentConpany ", addedParentConpany, "removedParentCompany", removedParentCompany, "Payload", payload.customerId);

  //   if (addedParentConpany) {
  //     payload.status = "Completed"
  //   }
  //   if (removedParentCompany) {
  //     payload.status = "In Progress"
  //   }

  //   addedAffectedCompany.forEach(newCompany => {
  //     if (payload.customerId === newCompany.code) {
  //       updatedChildItem = "Completed";
  //     }
  //   });

  //   // Update status based on removedAffectedCompanies for payload
  //   removedAffectedCompanies.forEach(oldCompany => {
  //     if (payload.customerId === oldCompany.code) {
  //       updatedChildItem = "In Progress";
  //     }
  //   });

  // }
  // // Add staffAssigned array from payload to each object in childList
  // if ('staffAssigned' in payload && Array.isArray(payload.staffAssigned)) {
  //   childItem.staffAssigned = payload.staffAssigned.map((staffItem) => ({
  //     staffAssignedId: staffItem.staffAssignedId,
  //     fileTrackingID: staffItem.fileTrackingID,
  //     userId: staffItem.userId,
  //     createDate: staffItem.createDate,
  //     createUser: staffItem.createUser,
  //     updateDate: staffItem.updateDate,
  //     updateUser: staffItem.updateUser,
  //   }));

  // }

  const deleteStaff = (oldStaff) => {
    try {
      oldStaff.forEach(async (ele) => {
        const res = await axios.delete(
          `${API_BASE_URL}/Customer/DeleteCustomerFilingTrackerStaffAssigned/${ele.staffAssignedId}`
        );
        if (value === "1") {
          // fetchClientFilingTrackers(clientId);
        } else {
          // fetchClientFilingTrackersHistory();
        }
      });
    } catch (err) {
      // console.log("deleteStaff err", err);
    }
  };

  const handleStaffAssigned = (oldStaff, newStaff, fileTrackingId) => {
    let newPayload = newStaff.staffAssignedArray.filter(
      (e) => !oldStaff.staffAssignedArray.includes(e)
    );
    let oldPayload = newStaff.staffAssigned.filter((objA) => {
      let matchingCode = newStaff.staffAssignedArray.find(
        (objB) => objB.name === objA.userName
      );
      return matchingCode === undefined || matchingCode.code !== objA.userId;
    });
    if (newPayload.length > 0) {
      saveNewField(newPayload, fileTrackingId);
    }
    if (oldPayload.length > 0) {
      `deleteStaff`(oldPayload);
    }
  };

  const cellStaffAssignedBody = (rowData) => {
    return rowData["staffAssignedArray"]?.reduce(
      (prevVal, val, i) => (i === 0 ? val.name : prevVal + " , " + val.name),
      ""
    );
  };

  const cellStaffAssignedEditor = (options) => {
    console.log(
      "cellStaffAssignedEditor: Options",
      options,
      options.rowData.recordType
    );
    // const selectedValues = options.value.split(' ,')
    // .map((ele)=> ele.charAt(0).toUpperCase() + ele.slice(1));
    // console.log('cellStaffAssignedEditor: selectedValues', selectedValues);

    let staffAssignedOptions = [];
    if (options.rowData.recordType === "TrackNow") {
      staffAssignedOptions = staffAssignedList
        .filter(
          (ele) =>
            ele.roleName === "Admin-PostAward" ||
            !ele.roleName.includes("PostAward")
        )
        .map((ele) => ({
          name: ele.userName.charAt(0).toUpperCase() + ele.userName.slice(1),
          code: ele.userId,
        }));
    } else {
      staffAssignedOptions = staffAssignedList
        .filter(
          (ele) =>
            ele.roleName === "Admin" || ele.roleName.includes("PostAward")
        )
        .map((ele) => ({
          name: ele.userName.charAt(0).toUpperCase() + ele.userName.slice(1),
          code: ele.userId,
        }));
    }
    console.log(
      "cellStaffAssignedEditor: staffAssignedOptions",
      staffAssignedList,
      staffAssignedOptions
    );

    return (
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        className="staffAssignedArray"
      >
        <MultiSelect
          //  value={options.value}
          value={options.value}
          disabled={
            (options.rowData.initialGrantTermComplete &&
              options.rowData.initialGrantTermComplete === "Yes") ||
            (!detailedViewVal &&
              options.rowData?.isOnlyChildFile &&
              options.rowData?.isUnderParent &&
              !options.rowData?.isOnlyParentFile)
          }
          onChange={(e) => {
            // console.log('##EOptions123:', e);
            options.editorCallback(e.value);
          }}
          options={staffAssignedOptions}
          optionLabel="name"
          panelClassName="staffassigned"
          placeholder="Select Staff"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
          style={{ position: "static" }}
        />
      </Stack>
    );
  };
  // const cellAffectedCompaniesEditor = (options) => {
  //   const { rowData } = options;
  //   const isChildIncludes = rowData.affectedCompanies.some(
  //     (company) => company.type === "Child"
  //   );
  //   setIsChildIncludes(isChildIncludes);

  //   const affectedCompaniesList = rowData.affectedCompanies.map((item) => ({
  //     name: item.customerName,
  //     code: item.customerId,
  //     status: item.status === "Completed",
  //   }));

  //   const updatedValue = options.value;
  //   // console.log(
  //   //   "cellAffectedCompaniesEditor OPTIONS",
  //   //   options,
  //   //   "updatedVals",
  //   //   updatedValue
  //   // );
  //   // const initalStatus = options.value;
  //   // customerName: company.customerName,
  //   // type: company.type, // Assuming 'type' property exists in 'company' object
  //   // customerId: company.customerId, // Assuming 'customerId' property exists in 'company' object
  //   // status: company.status,

  //   // const isChildIncludes = affectedCompaniesList.some(
  //   //   (company) => company.type === "Child"
  //   // );
  //   // console.log("isChildIncludes".isChildIncludes);
  //   // setIsChildIncludes(isChildIncludes);
  //   // const optionsWithStatus = affectedCompaniesList.map((company) => ({
  //   //   label: `${company.customerName} - ${company.status}`,
  //   //   value: company.customerId,
  //   //   checked: company.status === "Completed",
  //   //   disabled:
  //   //     !rowData.isParentCompany &&
  //   //     rowData?.parentCustomer === company.customerId,
  //   //   // defaultValue: company.status === "Completed" ? company.customerName : null
  //   // }));

  //   return (
  //     <Stack
  //       direction={"row"}
  //       justifyContent={"space-between"}
  //       className='affectedCompanies'
  //     >
  //       <MultiSelect
  //         value={updatedValue}
  //         onChange={(e) => {
  //           options.editorCallback(e.value);
  //         }}
  //         disabled={
  //           options.rowData.initialGrantTermComplete &&
  //           options.rowData.initialGrantTermComplete === "Yes"
  //         }
  //         options={affectedCompaniesList}
  //         optionLabel='name'
  //         // optionValue='customerId'
  //         //  optionDisabled='status'
  //         panelClassName='affectedCompanies'
  //         placeholder='Select affected Companies'
  //         maxSelectedLabels={3}
  //         className='w-full md:w-20rem'
  //         style={{ position: "static", maxWidth: "20rem", maxHeight: "20rem" }}
  //       />
  //     </Stack>
  //   );
  // };

  const cellAffectedCompaniesEditor = (options) => {
    const { rowData } = options;
    const isChildIncludes = rowData.affectedCompanies.some(
      (company) => company.type === "Child"
    );
    setIsChildIncludes(isChildIncludes);

    const affectedCompaniesList = rowData.affectedCompanies.map((item) => ({
      name: item.customerName,
      code: item.customerId,
      status: item.status === "Completed",
    }));

    const updatedValue = options.value;
    // console.log(
    //   "cellAffectedCompaniesEditor OPTIONS",
    //   options,
    //   "updatedVals",
    //   updatedValue
    // );
    // const initalStatus = options.value;
    // customerName: company.customerName,
    // type: company.type, // Assuming 'type' property exists in 'company' object
    // customerId: company.customerId, // Assuming 'customerId' property exists in 'company' object
    // status: company.status,

    // const isChildIncludes = affectedCompaniesList.some(
    //   (company) => company.type === "Child"
    // );
    // console.log("isChildIncludes".isChildIncludes);
    // setIsChildIncludes(isChildIncludes);
    // const optionsWithStatus = affectedCompaniesList.map((company) => ({
    //   label: `${company.customerName} - ${company.status}`,
    //   value: company.customerId,
    //   checked: company.status === "Completed",
    //   disabled:
    //     !rowData.isParentCompany &&
    //     rowData?.parentCustomer === company.customerId,
    //   // defaultValue: company.status === "Completed" ? company.customerName : null
    // }));

    return (
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        className="affectedCompanies"
      >
        <MultiSelect
          value={updatedValue}
          onChange={(e) => {
            options.editorCallback(e.value);
          }}
          disabled={
            options.rowData.initialGrantTermComplete &&
            options.rowData.initialGrantTermComplete === "Yes"
          }
          options={affectedCompaniesList}
          optionLabel="name"
          // optionValue='customerId'
          //  optionDisabled='status'
          panelClassName="affectedCompanies"
          placeholder="Select affected Companies"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
          style={{ position: "static", maxWidth: "20rem", maxHeight: "20rem" }}
        />
      </Stack>
    );
  };

  const toLocalTime = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-LL-yyyy hh:mm:ss"),
        "dd-LL-yyyy hh:mm:ss",
        { zone: "UTC" }
      ).toFormat("dd-LL-yyyy");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  const toLocalTime2 = (date) => {
    if (date) {
      const _date = DateTime.fromFormat(
        DateTime.fromISO(date.toString()).toFormat("dd-LL-yyyy hh:mm:ss"),
        "dd-LL-yyyy hh:mm:ss",
        { zone: "UTC" }
      ).toFormat("yyyy-LL-dd");
      // console.log("@@@ LocalllTime:", _date);

      return _date;
    } else return "";
  };

  // Map over the clientFiling data and modify the dueDate field for each row
  // const modifiedClientFiling = clientFiling.map((row) => {
  //   return {
  //     ...row,
  //     dueDateFormatted: toLocalTime(row.dueDate),
  //   };
  // });
  const handleTabChange = (event, newValue) => {
    setFederalFilterSelectedValues([]);
    setSelectedClientFiling([]);
    setViewAll(false);
    props.setSelectedFiling([]);
    setGlobalFilterValue("");
    setFilters({
      ...filters,
      global: {
        ...filters.global,
        value: null,
      },
    });
    setValue(newValue);
    props.setTablValue(newValue);
    let _filters = { ...filters };
    search.current.value = "";
    _filters["global"].value = "";
    setFilters(_filters);
    setIsClearIcon(false);
    setDetailedViewVal(false);
    setRecordTypeVal("");
    if (newValue === "2") {
      setIsDisabled(true);
      setClientFiling([]);
      setInitialValue([]);
    } else {
      setIsDisabled(false);
      setClientFilingHistory([]);
      setInitialClientFilingHistory([]);
    }
    if (sortRecord === "Clear") {
      setTrackNowValUpc(false);
      setPostAwardValUpc(false);
      setcheckboxdisabledTrackNowUpc(true);
      setcheckboxdisabledPostAwardUpc(true);
      setSortMaster([]);
      setSortRecord("Upcoming Due Dates");
      props.setSortRecord("Upcoming Due Date");
      setMasterCatFilter([]);
      setUpcomingCatFilter([]);
    }
  };
  const onRowSelect = (e) => {
    // console.log("@@RowSelect:", e);
  };
  const onRowEditCheck = (e) => {
    // console.log("@@onRowEditCheck:", e);
  };

  const handleViewAllClick = () => {
    setViewAll(true);
  };

  const handlePaginationClick = () => {
    setViewAll(false);
  };
  const fetchConfigs = async () => {
    if (fetchConfigurations) {
      let config = await fetchConfigurations();
      let startYear = config.find(
        (ele) => ele.configItem === "StartYear"
      ).configItemValue;
      let currentYear = config.find(
        (ele) => ele.configItem === "CurrentYear"
      ).configItemValue;
      startYear = parseInt(startYear);
      currentYear = parseInt(currentYear);
      const yearsInBetween = [];
      for (let year = startYear; year < currentYear; year++) {
        yearsInBetween.push(year);
      }
      setYearsList(yearsInBetween);
      setSelectedYear(currentYear - 1);
    }
  };
  const handletrackNowChange = () => {
    if (trackNowVal === true) {
      setTrackNowVal(false);
    } else {
      setTrackNowVal(true);
    }
  };

  const handletrackNowChangeUc = () => {
    if (trackNowValUpc === true) {
      setTrackNowValUpc(false);
    } else {
      setTrackNowValUpc(true);
    }
  };

  const handlepostAwardChange = () => {
    if (postAwardVal === true) {
      setPostAwardVal(false);
    } else {
      setPostAwardVal(true);
    }
  };

  const handlepostAwardChangeUc = () => {
    if (postAwardValUpc === true) {
      setPostAwardValUpc(false);
    } else {
      setPostAwardValUpc(true);
    }
  };

  // console.log("selectedCustomer", selectedCustomer)

  useEffect(() => {
    console.log(
      "postaward/tracknow up check prop",
      selectedCustomer,
      checkboxdisabled,
      lazyLoading,
      isRecordTypeChange
    );

    if (
      selectedCustomer &&
      !checkboxdisabled &&
      !lazyLoading &&
      isRecordTypeChange
    ) {
      console.log(
        "postaward/tracknow up check val",
        trackNowValUpc,
        postAwardValUpc
      );

      if (trackNowValUpc === true && postAwardValUpc === true) {
        setRecordTypeVal("");
      } else if (trackNowValUpc === true) {
        setRecordTypeVal("TrackNow");
      } else if (postAwardValUpc === true) {
        setRecordTypeVal("PostAward");
      } else if (trackNowValUpc === false && trackNowValUpc === false) {
        setRecordTypeVal("");
        setDetailedViewVal(false);
      }

      // setFederalFilterSelectedValues([]);
      // setGlobalFilterValue("");
      // setFilters({
      //   ...filters,
      //   global: {
      //     ...filters.global,
      //     value: null,
      //   },
      // });
      // let _filters = { ...filters };
      // search.current.value = "";
      // _filters["global"].value = "";
      // setFilters(_filters);
      // setIsClearIcon(false);
      setTotalRecords(0);
      setRecordsInView(0);
      setClientFiling([]);
      setInitialValue([]);
      setClientFilingHistory([]);
      setInitialClientFilingHistory([]);
      // setIsFilterChange([])
      // setSortingData({sortBy: "",sortOrder: ""})
      props.setClientFilingData([]);
      setSelectedClientFiling([]);
      props.setSelectedFiling([]);
      console.log(
        "postaward/tracknow up check  --?",
        trackNowValUpc,
        postAwardValUpc
      );
      if (!(trackNowValUpc === false && postAwardValUpc === false)) {
        console.log(
          "postaward/tracknow up check",
          trackNowValUpc,
          postAwardValUpc
        );
        setRefreshRecordList(true);
      }
      setIsRecordTypeChange(false);
    } else if (!selectedCustomer) {
      setcheckboxdisabledTrackNowUpc(true);
      setTrackNowValUpc(false);
      setPostAwardValUpc(false);
    }
  }, [trackNowValUpc, postAwardValUpc]);

  useEffect(() => {
    console.log(
      "postaward/tracknow check prop",
      selectedCustomer,
      checkboxdisabled,
      lazyLoading,
      isRecordTypeChange
    );

    if (
      selectedCustomer &&
      !checkboxdisabled &&
      !lazyLoading &&
      isRecordTypeChange
    ) {
      console.log("postaward/tracknow check val", trackNowVal, postAwardVal);

      if (trackNowVal === true && postAwardVal === true) {
        setRecordTypeVal("");
      } else if (trackNowVal === true) {
        setRecordTypeVal("TrackNow");
      } else if (postAwardVal === true) {
        setRecordTypeVal("PostAward");
      } else if (trackNowVal === false && postAwardVal === false) {
        setRecordTypeVal("");
        setDetailedViewVal(false);
      }

      // setFederalFilterSelectedValues([]);
      // setGlobalFilterValue("");
      // setFilters({
      //   ...filters,
      //   global: {
      //     ...filters.global,
      //     value: null,
      //   },
      // });
      // let _filters = { ...filters };
      // search.current.value = "";
      // _filters["global"].value = "";
      // setFilters(_filters);
      // setIsClearIcon(false);
      setTotalRecords(0);
      setRecordsInView(0);
      setClientFiling([]);
      setInitialValue([]);
      setClientFilingHistory([]);
      setInitialClientFilingHistory([]);
      // setIsFilterChange([])
      // setSortingData({sortBy: "",sortOrder: ""})
      props.setClientFilingData([]);
      setSelectedClientFiling([]);
      props.setSelectedFiling([]);
      console.log("postaward/tracknow check  --?", trackNowVal, postAwardVal);
      if (!(trackNowVal === false && postAwardVal === false) && !lazyLoading) {
        console.log("postaward/tracknow check", trackNowVal, postAwardVal);
        setRefreshRecordList(true);
      }
      setIsRecordTypeChange(false);
    } else if (!selectedCustomer) {
      setCheckBoxDisabled(true);
      setTrackNowVal(false);
      setPostAwardVal(false);
    }
  }, [trackNowVal, postAwardVal]);

  useEffect(() => {
    let filteredTrackNowData = [];
    if (trackNowVal === true && postAwardVal === true) {
      if (value === "2" && selectedCustomer) {
        // setClientFilingHistory(initialClientFilingHistory);
      } else {
        // setClientFiling(initialValue);
      }
    } else {
      let initalVal =
        value === "2" && selectedCustomer
          ? initialClientFilingHistory
          : initialValue;
      if (trackNowVal === true) {
        filteredTrackNowData = initalVal.filter(
          (ele) => ele.recordType === "TrackNow"
        );
      }
      if (postAwardVal === true) {
        filteredTrackNowData = initalVal.filter(
          (ele) => ele.recordType === "PostAward"
        );
      }
      if (value === "2" && selectedCustomer) {
        // setClientFilingHistory(filteredTrackNowData);
      } else {
        // setClientFiling(filteredTrackNowData);
      }
    }
  }, [initialValue, initialClientFilingHistory]);

  const handleTrackPostChange = (type, isHistoryView) => {
    console.log("handleTrackPostChange", type, trackNowVal);
    setIsRecordTypeChange(true);
    // if(type === "trackNow" && trackNowVal){
    //   setTrackNowVal(false)
    //   setRecordTypeVal("PostAward")
    // }else if(type === "trackNow" && !trackNowVal){
    //   setTrackNowVal(true)
    //   setRecordTypeVal("TrackNow")
    // }
    if (type === "trackNow") {
      setTrackNowVal(!trackNowVal);
    } else {
      setPostAwardVal(!postAwardVal);
    }
    // if (type === "trackNow") {
    //   handletrackNowChange();
    // } else {
    //   handlepostAwardChange();
    // }
  };

  const handleTrackPostChangeUc = (type) => {
    setIsRecordTypeChange(true);
    if (type === "trackNow") {
      setTrackNowValUpc(!trackNowValUpc);
    } else {
      setPostAwardValUpc(!postAwardValUpc);
    }
  };

  useEffect(() => {
    console.log(
      "detailedView check prop",
      selectedCustomer,
      lazyLoading,
      isRecordTypeChange
    );
    props.setDetailedViewVal(detailedViewVal);
    // if (selectedCustomer && !checkboxdisabled && !lazyLoading && isRecordTypeChange) {

    if (
      selectedCustomer &&
      !lazyLoading &&
      isRecordTypeChange &&
      !(trackNowVal === false && postAwardVal === false)
    ) {
      console.log("detailedView check val", detailedViewVal);

      // if (trackNowVal === true && postAwardVal === true) {
      //   setRecordTypeVal("")
      // } else if (trackNowVal === true) {
      //   setRecordTypeVal("TrackNow")
      // } else if (postAwardVal === true) {
      //   setRecordTypeVal("PostAward")
      // }
      setTotalRecords(0);
      setRecordsInView(0);
      setClientFiling([]);
      setInitialValue([]);
      setClientFilingHistory([]);
      setInitialClientFilingHistory([]);
      props.setClientFilingData([]);
      setSelectedClientFiling([]);
      props.setSelectedFiling([]);
      console.log("detailedView check", lazyLoading);
      if (!lazyLoading) {
        setRefreshRecordList(true);
      }
      setIsRecordTypeChange(false);
    } else if (!selectedCustomer) {
      setCheckBoxDisabled(true);
      setDetailedViewVal(false);
    }
  }, [detailedViewVal]);

  const handleDetailedViewChange = () => {
    setIsRecordTypeChange(true);
    setDetailedViewVal(!detailedViewVal);
    props.setUnderDetailedViewProps(!detailedViewVal);
  };

  const fetchBusinessCategories = async () => {
    console.log("@@Fetch BusinessCategories:", getBusinessCategories);
    if (getBusinessCategories) {
      console.log("@@Fetch BusinessCategories:");
      try {
        let list = [];
        const resList = await getBusinessCategories();
        console.log("@@BusinessCategories:", resList);
        list = resList.map((item) => item.businessCategoryName);
        const progList = await getProgramName();
        console.log("@@BusinessCategories:", progList);
        list.push(...progList.map((item) => item.programName));
        list.sort();
        console.log("@@BusinessCategories: list", list);
        setCategoryList(() => list);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
  };
  const fetchAwardType = async () => {
    console.log("@@Fetch fetchAwardType:");
    try {
      const resList = await axios.get(
        `${API_BASE_URL}/Application/ListAwardType`
      );
      let typeList = resList.data;
      //.map((item) => item.businessCategoryName).sort()\
      typeList = typeList.sort((a, b) =>
        (a.awardTypeName ?? "").localeCompare(b.localeCompare ?? "")
      );
      console.log("@@fetchAwardType:", resList, "typeList", typeList);
      setAwardTypeList(() => typeList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  const fetchAwardAgencies = async () => {
    console.log("@@Fetch BusinessCategories:");

    console.log("@@Fetch BusinessCategories:");
    try {
      const resList = await axios.get(
        `${API_BASE_URL}/Application/ListAwardingAgencies`
      );
      let agenciesList = resList.data;

      agenciesList = agenciesList.sort((a, b) =>
        (a.awardingAgencyName ?? "").localeCompare(b.awardingAgencyName ?? "")
      );
      console.log("@@BusinessCategories:", resList, "resList", resList);
      setAwadAgenciesList(() => agenciesList);
    } catch (error) {
      console.log("@@Error:", error);
    }
  };
  const fetchClientFilingTrackers = async (clientId, isRowEdit) => {
    // if (clientId) {
    setClientFiling([]);
    setInitialValue([]);
    let payLoad = { customerId: clientId };
    setIsLoading(true);

    try {
      if (getClientFilingTrackers) {
        if (isRowEdit === undefined || !isRowEdit) {
          setTrackNowVal(false);
          setPostAwardVal(false);
          setCheckBoxDisabled(true);
        }
        try {
          let custList = await getClientFilingTrackers(payLoad);

          const _jusrisdictions = custList.map((item) =>
            item.filingJuristiction ? item.filingJuristiction : ""
          );
          const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
          setJurisdiction(_uniqJurisdictions);
          custList = custList.map((ele) => {
            // const affectedCompaniesNames = ele.affectedCompanies.map(
            //   (company) => company.customerName
            // );
            // ele.formattedAffectedCompany = affectedCompaniesNames;
            // ele.formattedAffectedCompany = ele.affectedCompanies.map(
            //   (company) => ({
            //     customerName: company.customerName,
            //     type: company.type, // Assuming 'type' property exists in 'company' object
            //     customerId: company.customerId, // Assuming 'customerId' property exists in 'company' object
            //     status: company.status, // Assuming 'status' property exists in 'company' object
            //   })
            // );
            // ele.fldAffectedCompany = ele.affectedCompanies
            //   .filter((item) => item.status === "Completed")
            //   .map((company) => ({
            //     name: company.customerName,
            //     code: company.customerId, // Assuming 'customerId' property exists in 'company' object
            //     status: company.status === "Completed",
            //   }));
            // let findParent;
            // if (
            //   ele.parentCustomer !== null &&
            //   ele.parentCustomer !== "" &&
            //   ele.parentCustomer !== 0
            // ) {
            //   findParent = ele.affectedCompanies.find(
            //     (item) => item.type === "Parent"
            //   );
            // } else {
            //   findParent = ele.affectedCompanies.find(
            //     (item) => item.type === "DefaultClient"
            //   );
            // }

            // let popupData = [...ele.affectedCompanies];
            // popupData.forEach(function (item, i) {
            //   if (findParent && findParent.customerId && item.customerId === findParent.customerId) {
            //     popupData.splice(i, 1);
            //     popupData.unshift(item);
            //   }
            // });

            // // Add findParent property to the current element
            // ele.parentAffectedCompany = findParent;

            let date = ele.dueDate;

            // let isParent = ele.staffAssigned && ele.staffAssigned.length > 0 ?
            //   ele.staffAssigned.some((staff) => staff.company === "Parent") : false

            ele.staffAssignedArray =
              ele.staffAssigned.length > 0
                ? ele.staffAssigned.map((ele) => ({
                    name: ele.userName
                      ? ele.userName.charAt(0).toUpperCase() +
                        ele.userName.slice(1)
                      : ele.name,
                    code: ele.userId ? ele.userId : ele.code,
                  }))
                : ele.staffAssigned;

            // let internalDueDate =
            //   ele.dueDate !== null ? ele.dueDate.toISOString() : "";
            // let dueDateFiling =
            //   ele.filingDueDate !== null ? ele.filingDueDate.toISOString() : "";
            // console.log("##dueDate internal", internalDueDate, dueDateFiling);
            ele.internalDueDate =
              ele.dueDate !== null ? new Date(ele.dueDate) : null;
            ele.dueDateFiling =
              ele.filingDueDate !== null ? new Date(ele.filingDueDate) : null;
            return { ...ele, dueDate: date };
          });
          setInitialValue((old) => custList);
          if (isRowEdit === undefined || !isRowEdit) {
            setClientFiling((old) => custList);

            // const trackNowExist = custList.find(
            //   (ele) => ele.recordType === "TrackNow"
            // );
            // const postAwardExist = custList.find(
            //   (ele) => ele.recordType === "PostAward"
            // );

            // if (trackNowExist) {
            //    setTrackNowVal(true);
            // }

            // if (postAwardExist) {
            //    setPostAwardVal(true);
            // }

            // if (trackNowExist && postAwardExist) {
            //   setCheckBoxDisabled(false);
            // } else {
            //   setCheckBoxDisabled(true);
            // }
          }

          // setTrackNowList((old) => trackNowList);
          // setPostAwardList((old) => postAwardList);
          props.setClientFilingData((old) => custList);
          props.resetRefreshList();
        } catch (error) {
          console.log("error", error);
        }
      } else {
      }
    } catch (error) {
      console.error("err", error);
    } finally {
      setIsLoading(false);
    }
    // }
  };

  const fetchClientFilingTrackersHistory = async (isRowEdit) => {
    // if (clientId) {
    setClientFilingHistory([]);
    setInitialClientFilingHistory([]);
    setIsLoadingHistory(true);
    try {
      if (selectedCustomer) {
        if (isRowEdit === undefined || !isRowEdit) {
          setTrackNowVal(false);
          setPostAwardVal(false);
          setCheckBoxDisabled(true);
        }
        try {
          const resList = await axios.get(
            `${API_BASE_URL}/Customer/CustomerFileTrackingbyCustomerId?CustomerId=${selectedCustomer.customerId}&Year=${selectedYear}`
          );
          let custList = resList.data.data;
          const _jusrisdictions = custList.map((item) =>
            item.filingJuristiction ? item.filingJuristiction : ""
          );
          const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));

          setJurisdiction(_uniqJurisdictions);
          custList = custList.map((ele) => {
            // const affectedCompaniesNames = ele.affectedCompanies.map(
            //   (company) => company.customerName
            // );
            //  ele.formattedAffectedCompany = affectedCompaniesNames;

            // ele.formattedAffectedCompany = ele.affectedCompanies.map(
            //   (company) => ({
            //     customerName: company.customerName,
            //     type: company.type, // Assuming 'type' property exists in 'company' object
            //     customerId: company.customerId, // Assuming 'customerId' property exists in 'company' object
            //     status: company.status, // Assuming 'status' property exists in 'company' object
            //   })
            // );

            // ele.fldAffectedCompany = ele.affectedCompanies
            //   .filter((item) => item.status === "Completed")
            //   .map((company) => ({
            //     name: company.customerName,
            //     code: company.customerId, // Assuming 'customerId' property exists in 'company' object
            //     status: company.status === "Completed",
            //   }));
            // let findParent;
            // if (
            //   ele.parentCustomer !== null &&
            //   ele.parentCustomer !== "" &&
            //   ele.parentCustomer !== 0
            // ) {
            //   findParent = ele.affectedCompanies.find(
            //     (item) => item.type === "Parent"
            //   );
            // } else {
            //   findParent = ele.affectedCompanies.find(
            //     (item) => item.type === "DefaultClient"
            //   );
            // }

            // let popupData = [...ele.affectedCompanies];
            // popupData.forEach(function (item, i) {
            //   if (findParent && findParent.customerId && item.customerId === findParent.customerId) {
            //     popupData.splice(i, 1);
            //     popupData.unshift(item);
            //   }
            // });

            // // Add findParent property to the current element
            // ele.parentAffectedCompany = findParent;

            let date = ele.dueDate;

            // let isParent = ele.staffAssigned && ele.staffAssigned.length > 0 ?
            //   ele.staffAssigned.some((staff) => staff.company === "Parent") : false

            ele.staffAssignedArray =
              ele.staffAssigned.length > 0
                ? ele.staffAssigned.map((ele) => ({
                    name: ele.userName
                      ? ele.userName.charAt(0).toUpperCase() +
                        ele.userName.slice(1)
                      : ele.name,
                    code: ele.userId ? ele.userId : ele.code,
                  }))
                : ele.staffAssigned;

            // let internalDueDate =
            //   ele.dueDate !== null ? ele.dueDate.toISOString() : "";
            // let dueDateFiling =
            //   ele.filingDueDate !== null ? ele.filingDueDate.toISOString() : "";
            // console.log("##dueDate internal", internalDueDate, dueDateFiling);
            ele.internalDueDate =
              ele.dueDate !== null ? new Date(ele.dueDate) : null;
            ele.dueDateFiling =
              ele.filingDueDate !== null ? new Date(ele.filingDueDate) : null;

            const _stateList = getStateName(ele.filingStateInfo);
            const _staffassigned = _.map(ele.staffAssigned, "userName").join(
              ", "
            );
            const grantTermCompleteText = ele.grantTermComplete ? "Yes" : "No";
            const programName =
              ele.programInfo && ele.programInfo.length > 0
                ? ele.programInfo[0].programName
                : "";
            const awardTypeName =
              ele.awardType && ele.awardType.length > 0
                ? ele.awardType[0].awardTypeName
                : "";

            const awardingAgencyName =
              ele.awardingAgency && ele.awardingAgency.length > 0
                ? ele.awardingAgency[0].awardingAgencyName
                : "";
            const filingPeriods1 = ele.startdate ? new Date(ele.startdate) : "";
            const filingPeriods2 = ele.enddate ? new Date(ele.enddate) : "";

            const toLocalDateString = (date) => console.log("date", date);
            date.toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });

            const filingPeriod = `${toLocalDateString(
              filingPeriods1
            )} - ${toLocalDateString(filingPeriods2)}`;

            if (ele.filingJuristiction?.includes("Federal")) {
              let federalCategoriesVal;
              if (ele.recordType === "TrackNow") {
                const _categories = _.map(
                  ele.businessCategory,
                  "businessCategoryName"
                ).join(", ");

                //fedLookup.push(..._categories);
                federalCategoriesVal = _categories;
              } else {
                // fedLookup.push(...programName);
                federalCategoriesVal = programName;
              }
              return {
                ...ele,
                federalCategories: federalCategoriesVal,
                awardTypeName,
                awardingAgencyName,
                dueDate: date,
                stateList: _stateList,
                staffassigned: _staffassigned,
                filingPeriod,
                grantTermComplete: grantTermCompleteText,
                initialGrantTermComplete: grantTermCompleteText,
              };
            } else if (ele.filingJuristiction?.includes("Both")) {
              let stateCategoriesVal;
              if (ele.recordType === "TrackNow") {
                const categoriesState = ele.businessCategory.filter(
                  (ele) => ele.state === ele.filingStateInfo
                );

                const _categories = categoriesState
                  .map((ele) => ele.businessCategoryName)
                  .join(", ");

                stateCategoriesVal = _categories;
              } else {
                stateCategoriesVal = programName;
              }
              return {
                ...ele,
                stateCategories: stateCategoriesVal,
                federalCategories: stateCategoriesVal,
                awardTypeName,
                awardingAgencyName,
                dueDate: date,
                stateList: _stateList,
                staffassigned: _staffassigned,
                filingPeriod,
                grantTermComplete: grantTermCompleteText,
                initialGrantTermComplete: grantTermCompleteText,
              };
            } else {
              let stateCategoriesVal;
              if (ele.recordType === "TrackNow") {
                const categoriesState = ele.businessCategory.filter(
                  (ele) => ele.state === ele.filingStateInfo
                );

                const _categories = categoriesState
                  .map((ele) => ele.businessCategoryName)
                  .join(", ");

                stateCategoriesVal = _categories;
              } else {
                stateCategoriesVal = programName;
              }
              return {
                ...ele,
                stateCategories: stateCategoriesVal,
                awardTypeName,
                awardingAgencyName,
                dueDate: date,
                stateList: _stateList,
                staffassigned: _staffassigned,
                filingPeriod,
                grantTermComplete: grantTermCompleteText,
                initialGrantTermComplete: grantTermCompleteText,
              };
            }
          });
          if (isRowEdit === undefined || !isRowEdit) {
            setClientFilingHistory((old) => custList);
            // const trackNowExist = custList.find(
            //   (ele) => ele.recordType === "TrackNow"
            // );
            // const postAwardExist = custList.find(
            //   (ele) => ele.recordType === "PostAward"
            // );

            // if (trackNowExist) {
            //    setTrackNowVal(true);
            // }

            // if (postAwardExist) {
            //    setPostAwardVal(true);
            // }

            // if (trackNowExist && postAwardExist) {
            //   setCheckBoxDisabled(false);
            // } else {
            //   setCheckBoxDisabled(true);
            // }
          }
          setInitialClientFilingHistory((old) => custList);
          props.setClientFilingData((old) => custList);
          props.resetRefreshList();
        } catch (error) {
          console.error("fetchClientFilingTrackersHistory error", error);
        }
      } else {
      }
    } finally {
      setIsLoadingHistory(false);
    }
    // }
  };

  const upcomingDueDates = () => {
    try {
      const currentYear = new Date().getFullYear();
      const currentDate = new Date();
      //only current year
      const pendingClientFiling = initialValue.filter(
        (item) => item.status === "Pending"
      );
      let filteredDueDates = pendingClientFiling.filter((item) => {
        if (item.internalDueDate instanceof Date) {
          return item.internalDueDate.getFullYear() === currentYear;
        }
        return false;
      });

      let sortedRecords; // New variable to store the filtered records

      // Before End of Current Month
      if (options === "Before End of Current Month") {
        const lastDateOfCurrentMonth = new Date(
          currentYear,
          currentDate.getMonth() + 1,
          0
        );
        sortedRecords = filteredDueDates.filter((item) => {
          if (
            item.internalDueDate >= currentDate &&
            item.internalDueDate <= lastDateOfCurrentMonth
          ) {
            return true;
          }
          return false;
        });
      }
      // Within a Month
      else if (options === "Within a Month") {
        const next30Days = new Date(currentDate);
        next30Days.setDate(next30Days.getDate() + 30); // Get date 30 days from now
        sortedRecords = filteredDueDates.filter((item) => {
          return (
            item.internalDueDate >= currentDate &&
            item.internalDueDate <= next30Days
          );
        });
      }
      // Within 3 Months
      else if (options === "Within 3 Months") {
        const next90Days = new Date(currentDate);
        next90Days.setDate(next90Days.getDate() + 90); // Get date 90 days from now
        sortedRecords = filteredDueDates.filter((item) => {
          return (
            item.internalDueDate >= currentDate &&
            item.internalDueDate <= next90Days
          );
        });
      }
      // Within 6 Months
      else if (options === "Within 6 Months") {
        const next180Days = new Date(currentDate);
        next180Days.setDate(next180Days.getDate() + 180); // Get date 180 days from now
        sortedRecords = filteredDueDates.filter((item) => {
          return (
            item.internalDueDate >= currentDate &&
            item.internalDueDate <= next180Days
          );
        });
      } else if (options === "Before End of Current Year") {
        sortedRecords = filteredDueDates.filter((item) => {
          return item.internalDueDate >= currentDate;
        });
      }
      // Set the final result to setSortMaster

      setInitialValueUpc(sortedRecords);

      if (trackNowVal === true && postAwardVal === true) {
        // setTrackNowValUpc(true);
        // setPostAwardValUpc(true);
        setSortMaster(sortedRecords);
      } else {
        if (trackNowVal === true) {
          const trackNowExist = sortedRecords.filter(
            (ele) => ele.recordType === "TrackNow"
          );

          // setTrackNowValUpc(true);
          setSortMaster(trackNowExist);
        }
        if (postAwardVal === true) {
          const postAwardExist = sortedRecords.filter(
            (ele) => ele.recordType === "PostAward"
          );
          // setPostAwardValUpc(true);
          setSortMaster(postAwardExist);
        }
      }
    } catch (error) {
      console.error("errdue", error);
    }
  };

  const saveEditedField = async (selectedFiling) => {
    const utcDate = selectedFiling.dueDate.getFullYear
      ? new Date(
          Date.UTC(
            selectedFiling.dueDate.getFullYear(),
            selectedFiling.dueDate.getMonth(),
            selectedFiling.dueDate.getDate()
          )
        )
      : selectedFiling.dueDate;
    // utcDate.setMinutes(currTime.getMinutes());
    // utcDate.setSeconds(currTime.setSeconds());
    let payload = {
      ...selectedFiling,
      dueDate: utcDate,
      updateDate: new Date(),
      updateUser: authUser.userId + "",
    };
    // let payload = {
    //   fileTrackingId: selectedFiling.fileTrackingId,
    //   customerId: selectedFiling.customerId,
    //   filingId: selectedFiling.filingId,
    //   dueDate: utcDate,
    //   filingDueDate: selectedFiling.filingDueDate,
    //   status: selectedFiling.status,
    //   createDate: selectedFiling.createDate,
    //   createUser: selectedFiling.createUser,
    //   updateDate: new Date(),
    //   updateUser: authUser.userId + "",
    //   startdate: selectedFiling.startdate,
    //   enddate: selectedFiling.enddate,
    // };
    const res = await axios.put(
      `${API_BASE_URL}/Customer/CustomerFileTracking/${selectedFiling.fileTrackingId}`,
      payload
    );
    if (res.data.status === "Success") {
      setAlertMessage("Successfully updated the record");
      setShowAlert(true);
      // fetchClientFilingTrackers(clientId);
    } else {
      setAlertMessage("Unexpected error occurted while updating the record");
      setShowAlert(true);
    }
  };
  useEffect(() => {
    if (ClientFilingMasterLoaded) {
      // console.log("Show Table Data", ClientFilingTrackers)
    }
    //setIsLoading(true);
    //eslint-disable-next-line
  }, [ClientFilingMasterLoaded]);

  useEffect(() => {
    if (clientId) {
      //fetchClientFilingTrackers(clientId);
      let _filters = { ...filters };
      search.current.value = "";
      _filters["global"].value = "";
      setFilters(_filters);
      setIsClearIcon(false);
      const staffList = userRoles
        .filter((ele) => {
          if (ele.roleName === "Admin" || ele.roleName === "Admin-PostAward") {
            return ele;
          } else if (parseInt(clientId) === ele.customerId) {
            return ele;
          }
        })
        // .map((ele) => ({
        //   name: ele.userName.charAt(0).toUpperCase() + ele.userName.slice(1),
        //   code: ele.userId,
        //   loginId: ele.loginId

        // }))
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.userName === value.userName)
        );
      staffList.sort(function (a, b) {
        if (a.userName < b.userName) {
          return -1;
        }
        if (a.userName > b.userName) {
          return 1;
        }
        return 0;
      });
      console.log("fetchStaffAssigned staffList", staffList);
      setstaffAssignedList(staffList);
      props.setStaffList(staffList);
    }
  }, [clientId]);

  useEffect(() => {
    if (selectedCustomer) {
      console.log(
        "selectedCustomer refreshPage",
        selectedCustomer,
        value,
        refreshPageDetails
      );
      if (
        value === "1" &&
        selectedCustomer?.customerId &&
        !lazyLoading &&
        !refreshPageDetails.hasOwnProperty("recordsInView")
      ) {
        // fetchClientFilingTrackers(selectedCustomer.customerId);
        if (clientFiling.length > 0) {
          setRecordsInView(0);
          setIsLoading(true);
          setClientFiling([]);
          setInitialValue([]);
          props.setClientFilingData([]);
          setSelectedClientFiling([]);
          props.setSelectedFiling([]);
          setRefreshRecordList(true);
        } else {
          setIsLoading(true);
          setRecordsInView(0);
          setRefreshRecordList(true);
        }
      } else if (
        selectedCustomer?.customerId &&
        !lazyLoading &&
        !refreshPageDetails.hasOwnProperty("recordsInView")
      ) {
        // fetchClientFilingTrackersHistory();
        if (clientFilingHistory.length > 0) {
          setRecordsInView(0);
          setIsLoading(true);
          setClientFilingHistory([]);
          setInitialClientFilingHistory([]);
          props.setClientFilingData([]);
          setSelectedClientFiling([]);
          props.setSelectedFiling([]);
          setRefreshRecordList(true);
        } else {
          setIsLoading(true);
          setRecordsInView(0);
          setRefreshRecordList(true);
        }
      }
      fetchBusinessCategories();
      fetchAwardAgencies();
      fetchAwardType();
      console.log(
        "refreshPageDetails --@",
        refreshPageDetails,
        refreshPageDetails.hasOwnProperty("recordsInView")
      );
      if (refreshPageDetails.hasOwnProperty("recordsInView")) {
        console.log(
          "refreshPageDetails --@",
          refreshPageDetails.refreshDetails.clientSelected
        );
        props.resetKeepSelectedRecords(
          refreshPageDetails.refreshDetails.clientSelected
        );
        setRecordsInView(refreshPageDetails.recordsInView);
        setRefreshDetails(refreshPageDetails.refreshDetails);
        setRefreshRecordList(true);
        props.resetKeepPageDetails(false);
      }
    }
  }, [selectedCustomer]);

  useEffect(() => {
    fetchConfigs();
  }, []);
  useEffect(() => {
    if (value === "2") {
      // fetchClientFilingTrackersHistory()
      setRecordsInView(0);
      setIsLoading(true);
      setClientFiling([]);
      setInitialValue([]);
      setClientFilingHistory([]);
      setInitialClientFilingHistory([]);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setFederalFilterSelectedValues([]);
      props.setClientFilingData([]);
      setSelectedClientFiling([]);
      setRefreshRecordList(true);
    }
  }, [selectedYear]);

  useEffect(() => {
    if (value === "2") {
      //passing selected year props to client tracker list
      props.setSelectedYear(selectedYear);
    }
  }, [selectedYear, value]);

  useEffect(() => {
    if (selectedCustomer) {
      if (value === "1") {
        // fetchClientFilingTrackers(selectedCustomer.customerId)
        setRecordsInView(0);
        setIsLoading(true);
        setClientFiling([]);
        setIsFilterChange([]);
        setSortingData({ sortBy: "", sortOrder: "" });
        setFederalFilterSelectedValues([]);
        setInitialValue([]);
        props.setClientFilingData([]);
        setSelectedClientFiling([]);
        props.setSelectedFiling([]);
        setRefreshRecordList(true);
      } else {
        // fetchClientFilingTrackersHistory()
        setRecordsInView(0);
        setIsLoading(true);
        setClientFilingHistory([]);
        setInitialClientFilingHistory([]);
        setIsFilterChange([]);
        setSortingData({ sortBy: "", sortOrder: "" });
        props.setClientFilingData([]);
        setSelectedClientFiling([]);
        props.setSelectedFiling([]);
        setRefreshRecordList(true);
      }
    }
  }, [value]);

  useEffect(() => {
    // console.log("selectedClientId", props.selectedClientId, selectedCustomer?.customerId)
    if (props.selectedClientId) {
      setClientId(() => props.selectedClientId);
      setSelectedClientFiling([]);
      props.setSelectedFiling([]);
      setGlobalFilterValue("");
      setFilters({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        filingName: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        dueDateFiling: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        internalDueDate: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        filingFrequency: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        filingStateInfo: { value: null, matchMode: FilterMatchMode.CONTAINS },
        stateList: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        staffAssignedArray: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        staffassigned: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        customerName: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        ruleInfo: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        // affectedCompanies: {
        //   operator: FilterOperator.AND,
        //   constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        // },
        // formattedAffectedCompany: {
        //   operator: FilterOperator.AND,
        //   constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        // },
        filingPeriod: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        filingRequired: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        awardTypeName: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
        },
        awardingAgencyName: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
        },
        federalCategories: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
        },
        stateCategories: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
        },
        jsicontactName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        filingJuristiction: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        status: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        whereToFile: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      });
    }

    console.log(
      "selectedCustomer refreshPage",
      selectedCustomer,
      refreshPageDetails
    );
    if (
      props.selectedClientId &&
      selectedCustomer?.customerId &&
      !lazyLoading &&
      !refreshPageDetails.hasOwnProperty("recordsInView")
    ) {
      setRecordsInView(0);
      setClientFiling([]);
      setInitialValue([]);
      setClientFilingHistory([]);
      setInitialClientFilingHistory([]);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setFederalFilterSelectedValues([]);
      setRecordTypeVal("");
      setupcomingDueDateVal("");
      setIsLoading(true);
      props.setClientFilingData([]);
      setSelectedClientFiling([]);
      props.setSelectedFiling([]);
      setDetailedViewVal(false);
      setRefreshRecordList(true);
    } else {
      setIsLoading(false);
    }
  }, [props.selectedClientId]);
  useEffect(() => {
    if (sortRecord === "Clear") {
      setSortMaster([]);
      setSortRecord("Upcoming Due Dates");
      props.setSortRecord("Upcoming Due Date");
      setMasterCatFilter([]);
      setFederalFilterSelectedValues([]);
      setOptions("");
    } else {
      setOptions("");
    }
  }, [props.selectedClientId]);

  useEffect(() => {
    if (props.refreshList && selectedCustomer?.customerId) {
      if (value === "1") {
        // fetchClientFilingTrackers(clientId);
        setIsLoading(true);
        setClientFiling([]);
        setInitialValue([]);
        props.setClientFilingData([]);
        setSelectedClientFiling([]);
        props.setSelectedFiling([]);
        setRefreshRecordList(true);
      } else {
        // fetchClientFilingTrackersHistory();
        setIsLoading(true);
        setClientFilingHistory([]);
        setInitialClientFilingHistory([]);
        props.setClientFilingData([]);
        setSelectedClientFiling([]);
        props.setSelectedFiling([]);
        setRefreshRecordList(true);
      }
      setSelectedClientFiling([]);
      props.setSelectedFiling([]);
    }
  }, [props.refreshList]);
  useEffect(() => {
    if (refreshTable) {
      const vScroll =
        value === "1"
          ? sortRecord === "Clear"
            ? dtUpcoming.current.getVirtualScroller()
            : dt.current.getVirtualScroller()
          : dtHistory.current.getVirtualScroller();

      let range = vScroll.getRenderedRange();
      console.log("refreshTable vScroll", vScroll, range);

      if (value === "1") {
        let pageInView;
        let noOfPages;
        if (clientFiling.length > 100) {
          pageInView = recordsInView - 2;
          noOfPages = 2;
        } else {
          pageInView = recordsInView - 1;
          noOfPages = 1;
        }
        let clientSelected = selectedClientFiling;
        console.log(
          "refreshTable clientSelected",
          selectedClientFiling,
          clientSelected
        );

        setRecordsInView(pageInView);
        setRefreshDetails({
          viewport: range.viewport,
          pageInView,
          noOfPages,
          clientSelected,
        });

        // fetchClientFilingTrackers(clientId);
        setIsLoading(true);
        setClientFiling([]);
        setInitialValue([]);
        props.setClientFilingData([]);
        setRefreshRecordList(true);
      } else {
        let pageInView;
        let noOfPages;
        if (clientFilingHistory.length > 100) {
          pageInView = recordsInView - 2;
          noOfPages = 2;
        } else {
          pageInView = recordsInView - 1;
          noOfPages = 1;
        }
        let clientSelected = selectedClientFiling;
        console.log(
          "refreshTable clientSelected",
          selectedClientFiling,
          clientSelected
        );

        setRecordsInView(pageInView);
        setRefreshDetails({
          viewport: range.viewport,
          pageInView,
          noOfPages,
          clientSelected,
        });
        // fetchClientFilingTrackersHistory();
        setIsLoading(true);
        setClientFilingHistory([]);
        setInitialClientFilingHistory([]);
        props.setClientFilingData([]);
        setRefreshRecordList(true);
      }
      handleRefreshTableReturn();
      // setSelectedClientFiling([]);
      // props.setSelectedFiling([]);
      // setCheckBoxDisabled(true);
    }
  }, [refreshTable]);

  useEffect(() => {
    // console.log("refreshPageDetails --@", refreshPageDetails, refreshPageDetails.hasOwnProperty("recordsInView"));
  }, [refreshPageDetails]);

  useEffect(() => {
    //eslint-disable-next-line
  }, [ClientFilingTrackers]);

  // useEffect(() => {
  //   downloadData(clientFiling);
  // }, [clientFiling]);
  useEffect(() => {}, [ClientFilingTrackersHistory]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const renderHeader = () => {
    return (
      <Grid
        container
        sx={{ my: 1, flexDirection: "row", justifyContent: "space-between" }}
      >
        <Grid sx={{ margin: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                fontWeight: 500,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {value === "1" && selectedCustomer
                ? `TRACK NOW CLIENT FILINGS (${totalRecords})`
                : value === "2" && selectedCustomer
                ? `TRACK NOW CLIENT FILING  (${totalRecords})`
                : "TRACK NOW CLIENT FILING (0)"}
            </Typography>
          </Stack>
        </Grid>
        <Grid>
          {value === "1" &&
            selectedCustomer &&
            selectedCustomer?.parentCustomer === null &&
            !selectedCustomer?.isOnlyParentFile && (
              <FormControlLabel
                value="top"
                control={
                  <InputSwitch
                    style={{ margin: "1rem 1rem 1rem 0" }}
                    checked={detailedViewVal}
                    disabled={
                      !selectedCustomer ||
                      (trackNowVal === false && postAwardVal === false)
                    }
                    onChange={() => handleDetailedViewChange()}
                  />
                }
                label="Detailed View"
                labelPlacement="end"
              />
            )}
        </Grid>
        <Grid>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" Column>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    name="trackNow"
                    checked={trackNowVal}
                    disabled={checkboxdisabled}
                    onClick={() =>
                      handleTrackPostChange(
                        "trackNow",
                        value === "2" && selectedCustomer
                      )
                    }
                  />
                }
                label="TrackNow"
                labelPlacement="end"
              />
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    name="postAward"
                    checked={postAwardVal}
                    disabled={checkboxdisabled}
                    onClick={() =>
                      handleTrackPostChange(
                        "postAward",
                        value === "2" && selectedCustomer
                      )
                    }
                  />
                }
                label="PostAward"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        {value === "1" && (
          <Grid xs="auto" sx={{ display: "flex" }}>
            <Stack direction="column" spacing={3}>
              <Button
                size="medium"
                sx={{ backgroundColor: "#0b3edf" }}
                //  color="primary"
                variant="contained"
                onClick={sortRecords}
                disabled={!options}
              >
                Upcoming Due Dates
              </Button>

              <FormControl
                disabled={!props.selectedClientId}
                sx={{ minWidth: "14rem" }}
                size="small"
              >
                <InputLabel id="demo-select-small-label">
                  Select Filing due
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={options}
                  label="Select Filing Year"
                  onChange={handleChange}
                  sx={{
                    maxWidth: "14rem",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {yearsOption.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        )}
        <Grid>
          {value === "2" ? (
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Year</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedYear}
                label="Year"
                onChange={handleYearChange}
              >
                {yearsList.map((year) => (
                  <MenuItem value={year} key={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </Grid>
        <Grid xs="auto" sx={{ margin: 1 }}>
          {/* <span className="p-input-icon-left">
            <i className="pi pi-search" /> */}
          {/* <InputText
              type="text"
              //value={globalFilterValue}
              ref={search}
              //onChange={onGlobalFilterChange}
              placeholder="Search by Filing Name..."
            // size={20}
            /> */}
          <TextField
            id="standard-search"
            label="Search by Filing Name..."
            size="small"
            inputRef={search}
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={applyGlobalFilter}>
                    {isClearIcon ? <ClearRoundedIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* </span> */}
        </Grid>
      </Grid>
    );
  };

  const handleCommentClick = (rowData) => {
    const rowDataArr = Array.isArray(rowData) ? rowData : [rowData];
    console.log(
      "Indicator Icon Comments rowwdata",
      rowData,
      "rowDataArr",
      rowDataArr
    );
    displayComments(rowData);
    setSelectedClientFiling(rowDataArr);
    props.setSelectedFiling(rowDataArr);
  };

  const handleAttachmentsClick = (rowData) => {
    const rowDataArr = Array.isArray(rowData) ? rowData : [rowData];
    console.log(
      "Indicator Icon attachment rowwdata",
      rowData,
      "rowDataArr",
      rowDataArr
    );
    displayAttachments(rowData);
    setSelectedClientFiling(rowDataArr);
    props.setSelectedFiling(rowDataArr);
  };
  const handleRefDocClick = (rowData) => {
    const rowDataArr = Array.isArray(rowData) ? rowData : [rowData];
    console.log(
      "Indicator Icon Docs rowwdata",
      rowData,
      "rowDataArr",
      rowDataArr
    );
    displayDocuments(rowData);
    setSelectedClientFiling(rowDataArr);
    props.setSelectedFiling(rowDataArr);
  };

  const hasChangeStatusPermission = usePermission(
    "ClientFilingList",
    "changeStatus",
    clientId
  );

  const hasChangeGrantTermcompletePermission = usePermission(
    "ClientFilingList",
    "changeGrantTermcomplete",
    clientId
  );

  const hasStaffAssignedPermission = usePermission(
    "ClientFilingList",
    "changeStaff",
    clientId
  );

  const hasChangeDueDatePermission = usePermission(
    "ClientFilingList",
    "changeStatusAndDueDate",
    clientId
  );

  const TrackClientComment = ({ rowData }) => {
    const isPermissionEnabled = usePermission(
      "ClientFilingList",
      "comments",
      props.selectedClientId
    );

    return (
      <IconButton
        color="primary"
        disabled={!rowData.hasComments || !isPermissionEnabled}
      >
        <InsertCommentRoundedIcon onClick={() => handleCommentClick(rowData)} />
      </IconButton>
    );
  };
  const TrackClientAttachment = ({ rowData }) => {
    const isPermissionEnabled = usePermission(
      "ClientFilingList",
      "viewFinalAttachment",
      props.selectedClientId
    );

    return (
      <IconButton
        color="primary"
        disabled={!rowData.hasAttachments || !isPermissionEnabled}
      >
        <AttachmentOutlinedIcon
          onClick={() => handleAttachmentsClick(rowData)}
        />
      </IconButton>
    );
  };
  const TrackClientRefDocs = ({ rowData }) => {
    const isPermissionEnabled = usePermission(
      "ClientFilingList",
      "masterFilingRefDoc",
      props.selectedClientId
    );

    return (
      <IconButton
        color="primary"
        disabled={!rowData.hasReference || !isPermissionEnabled}
      >
        <SnippetFolderIcon onClick={() => handleRefDocClick(rowData)} />
      </IconButton>
    );
  };

  const upcomingOptionVal = [
    { name: "", val: "" },
    { name: "Before End of Current Month", val: "BeforeEndofCurrentMonth" },
    { name: "Within a Month", val: "WithinMonth" },
    { name: "Within 3 Months", val: "Within3Month" },
    { name: "Within 6 Months", val: "Within6Month" },
    { name: "Before End of Current Year", val: "BeforeEndofCurrentYear" },
  ];

  const getUpcomingOption = (matchMode) => {
    // console.log("getSearchType props", matchMode);
    return upcomingOptionVal.find((ele) => ele.name === matchMode).val;
  };

  const sortRecords = () => {
    if (sortRecord === "Upcoming Due Dates") {
      setSortRecord("Clear");
      setViewAll(false);
      props.setSortRecord("Clear");
      let _filters = { ...filters };
      search.current.value = "";
      _filters["global"].value = "";
      setFilters(_filters);
      setIsClearIcon(false);
      // upcomingDueDates();
      setRecordsInView(0);
      setIsLoading(true);
      setClientFiling([]);
      setInitialValue([]);
      setClientFilingHistory([]);
      setInitialClientFilingHistory([]);
      props.setClientFilingData([]);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setIsClearIcon(false);
      setMasterCatFilter([]);
      setUpcomingCatFilter([]);
      setFederalFilterSelectedValues([]);
      setSelectedClientFiling([]);
      props.setSelectedFiling([]);
      let upcomingOption = getUpcomingOption(options);
      console.log("upcomingOption", upcomingOption);
      setupcomingDueDateVal(upcomingOption);
      setRefreshRecordList(true);
      if (
        recordTypeVal !== "" &&
        ((trackNowVal && !postAwardVal) || (!trackNowVal && postAwardVal))
      ) {
        setTrackNowValUpc(trackNowVal);
        setPostAwardValUpc(postAwardVal);
      }
    } else if (sortRecord === "Clear") {
      setViewAll(false);
      let _filters = { ...filters };
      search.current.value = "";
      _filters["global"].value = "";
      setFilters(_filters);
      setIsClearIcon(false);
      if (
        recordTypeVal !== "" &&
        ((trackNowValUpc && !postAwardValUpc) ||
          (!trackNowValUpc && postAwardValUpc))
      ) {
        setTrackNowVal(trackNowValUpc);
        setPostAwardVal(postAwardValUpc);
      }
      setTrackNowValUpc(false);
      setPostAwardValUpc(false);
      setcheckboxdisabledTrackNowUpc(true);
      setcheckboxdisabledPostAwardUpc(true);
      setSortMaster([]);
      setSortRecord("Upcoming Due Dates");
      props.setSortRecord("Upcoming Due Date");
      setMasterCatFilter([]);
      setUpcomingCatFilter([]);
      setFederalFilterSelectedValues([]);
      setSelectedClientFiling([]);
      props.setSelectedFiling([]);
      setRecordsInView(0);
      setIsLoading(true);
      setClientFiling([]);
      setInitialValue([]);
      setClientFilingHistory([]);
      setInitialClientFilingHistory([]);
      props.setClientFilingData([]);
      setIsFilterChange([]);
      setSortingData({ sortBy: "", sortOrder: "" });
      setupcomingDueDateVal("");
      setRefreshRecordList(true);
    }
  };
  const header = renderHeader();
  const federalFilterElement = (options, field) => {
    console.log(
      "federalFilterElement",
      options,
      field,
      federalFilterSelectedValues
    );
    // let optionsList = [];
    // data
    //   .map((item) => item[field])
    //   .filter(Boolean)
    //   .map((item) => {
    //     if (item.includes(",")) {
    //       //console.log("spliited_item", item);
    //       item.split(",").map((subItem) => {
    //         optionsList.push(subItem && subItem.trim());
    //       });
    //     } else {
    //       optionsList.push(item.trim());
    //     }
    //   });
    // optionsList = optionsList
    //   .filter((item, i) => {
    //     return i === optionsList.lastIndexOf(item);
    //   })
    //   .sort();

    return options.filterModel?.matchMode &&
      options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={federalFilterSelectedValues[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, [field]: e.value };
          });
          // let values = [];

          // data.forEach((item) => {
          //   if (e.value.some((category) => item[field]?.includes(category))) {
          //     values.push(item[field]);
          //   }
          // });
          // values = values.filter((item, i) => {
          //   return i === values.lastIndexOf(item);
          // });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={federalFilterSelectedValues[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setFederalFilterSelectedValues((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];

          if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };

  const upcomingCategoryFilter = (options, field) => {
    return options.filterModel.matchMode === "in" ? (
      <MultiSelect
        value={upcomingCatFilter[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setUpcomingCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          options.filterCallback(e.value, options.index);
        }}
        placeholder="Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    ) : (
      <Dropdown
        value={upcomingCatFilter[field] || []}
        options={categoryList}
        itemTemplate={jusrisdictionItemTemplate}
        onChange={(e) => {
          setUpcomingCatFilter((prev) => {
            return { ...prev, [field]: e.value };
          });
          let values = [];

          if (options.filterModel.matchMode === "notContains") {
            values.push(e.value);
          } else if (options.filterModel.matchMode === "equals") {
            values.push(e.value);
          }
          options.filterCallback(values, options.index);
        }}
        placeholder="Any"
        className="p-column-filter"
        style={{ minWidth: "14rem", maxWidth: "14rem" }}
      />
    );
  };
  const renderDueHeader = () => {
    return (
      <Grid
        container
        sx={{ my: 1, flexDirection: "row", justifyContent: "space-between" }}
      >
        <Grid sx={{ margin: 1, flexGrow: 1 }}>
          <ArrowBackIosNewIcon
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={sortRecords}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            UPCOMING DUE DATES ({totalRecords})
          </Typography>
          <h5 style={{ fontWeight: "500", margin: "0.5rem 0 0 2rem" }}>
            {options}
          </h5>
        </Grid>
        <Grid>
          {selectedCustomer &&
            selectedCustomer?.parentCustomer === null &&
            !selectedCustomer?.isOnlyParentFile && (
              <FormControlLabel
                value="top"
                control={
                  <InputSwitch
                    style={{ margin: "1rem 1rem 1rem 0" }}
                    checked={detailedViewVal}
                    disabled={
                      !selectedCustomer ||
                      (trackNowValUpc === false && postAwardValUpc === false)
                    }
                    onChange={() => handleDetailedViewChange()}
                  />
                }
                label="Detailed View"
                labelPlacement="end"
              />
            )}
        </Grid>
        <Grid>
          <div style={{ marginLeft: "1rem" }}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" Column>
                <FormControlLabel
                  value="top"
                  control={
                    <Checkbox
                      name="trackNow"
                      checked={trackNowValUpc}
                      disabled={checkboxdisabled}
                      onClick={() => handleTrackPostChangeUc("trackNow")}
                    />
                  }
                  label="TrackNow"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="top"
                  control={
                    <Checkbox
                      name="postAward"
                      checked={postAwardValUpc}
                      disabled={checkboxdisabled}
                      onClick={() => handleTrackPostChangeUc("postAward")}
                    />
                  }
                  label="PostAward"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid
          md={2}
          sx={{
            margin: 1,
            pl: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{ p: 1, mx: 2, backgroundColor: "#0b3edf" }}
            size="medium"
            color="primary"
            variant="contained"
            onClick={sortRecords}
          >
            CLEAR
          </Button>
        </Grid>
        <Grid md={3} sx={{ margin: 1 }}>
          {/* <span className="p-input-icon-left">
            <i className="pi pi-search" /> */}
          {/* <InputText
              // value={globalFilterValue}
              //onChange={onGlobalFilterChange}
              placeholder="Search by Filing Name"
              size={25}
            /> */}
          <TextField
            id="standard-search"
            label="Search by Filing Name..."
            size="small"
            // type="search"
            inputRef={search}
            variant="standard"
            // value={isClearIcon ? "" : search.current?.value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={applyGlobalFilter}>
                    {isClearIcon ? <ClearRoundedIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* </span> */}
        </Grid>
      </Grid>
    );
  };

  const headerDue = renderDueHeader();
  // const selectFilingRecord = (value) => {
  //   setSelectedClientFiling(value);
  //   props.setSelectedFiling(value);
  // };
  const selectFilingRecord = (value) => {
    const selectedRows = Array.isArray(value) ? value : [value];
    setSelectedClientFiling(selectedRows);
    props.setSelectedFiling(selectedRows);
  };
  const rowClass = (data) => {
    return {
      "completed-row": data.status === "Completed",
    };
  };
  // const ct = useRef(null)
  const dt = useRef(null);

  const dtHistory = useRef(null);
  const dtUpcoming = useRef(null);
  const closeRowEditing = () => {
    // if (dt.current) {
    // console.log("dt", dt);
    // dt.current.clearState();
    // dt.current.reset();
    // console.log("closeRowEditing initialOptions", initialOptions);
    setIsDenied(true);
    //cellStatusEditor(initialOptions, true)
    setIsStatusChangeOk(false);
    setIsStatusColumnChange(false);
    setSelectedValues([]);
    setShowInfo(false);
    //}
  };

  const tableRef = useRef(null);
  const pageSize = 100;

  const scrollToSelected = () => {
    console.log("scrollToSelected vScroll", refreshDetails);
    if (
      refreshDetails?.viewport &&
      refreshDetails?.noOfPages &&
      clientFiling.length > 0
    ) {
      setSelectedClientFiling(refreshDetails.clientSelected);
      props.setSelectedFiling(refreshDetails.clientSelected);
      const vScroll =
        value === "1"
          ? sortRecord === "Clear"
            ? dtUpcoming.current.getVirtualScroller()
            : dt.current.getVirtualScroller()
          : dtHistory.current.getVirtualScroller();
      console.log("scrollToSelected vScroll", vScroll, refreshDetails);
      vScroll.scrollToIndex(refreshDetails.viewport.first);
      setRefreshDetails(null);
    }
  };

  // lazy load function
  const loadDataLazy = async (event) => {
    console.log("loadDataLazy props", recordsInView, event);

    //Virtual scroll callback

    const vScroll =
      value === "1"
        ? sortRecord === "Clear"
          ? dtUpcoming.current.getVirtualScroller()
          : dt.current.getVirtualScroller()
        : dtHistory.current.getVirtualScroller();

    let range = vScroll.getRenderedRange();
    let { first, last } = range.viewport;
    console.log("loadDataLazy vScroll", vScroll, range.viewport);

    let firstVal = recordsInView * pageSize;
    let tableList = value === "2" ? clientFilingHistory : clientFiling;
    console.log(
      "loadDataLazy totalRecords",
      firstVal,
      totalRecords,
      lazyLoading,
      tableList.length
    );

    console.log(
      "loadDataLazy",
      firstVal < totalRecords,
      first > tableList.length - 10,
      !lazyLoading
    );

    if (
      firstVal < totalRecords &&
      first > tableList.length - 10 &&
      !lazyLoading
    ) {
      //scroll down
      // setIsLoading(true);
      setLazyLoading(true);
      let lastVal = firstVal + pageSize;
      console.log("loadDataLazy d firstVal", firstVal, lastVal);

      //get next data
      let records = await loadRecordsList(firstVal, lastVal, "down");
      console.log("loadDataLazy d record", records);
      if (records) {
        console.log("loadDataLazy d scrollInView", recordsInView * 100);

        //scroll to next records
        vScroll.scrollInView(recordsInView * 100);
        setRecordsInView((old) => old + 1);
        setLazyLoading(false);
        setIsLoading(false);
      }
    } else if (last < 10 && recordsInView > 1 && !lazyLoading) {
      // scroll up
      let firstVal = (recordsInView - 3) * pageSize;
      let lastVal = firstVal + pageSize;
      if (firstVal >= 0) {
        // setIsLoading(true);
        setLazyLoading(true);
        // console.log("loadDataLazy u firstVal", firstVal, lastVal);

        //get prev data
        let records = await loadRecordsList(firstVal, lastVal, "up");
        // console.log("loadDataLazy u record", records);
        if (records) {
          // console.log("loadDataLazy u scrollInView", (recordsInView - 3) * 100);

          //scroll to prev records
          vScroll.scrollToIndex(15);
          setRecordsInView((old) => old - 1);
          setLazyLoading(false);
          setIsLoading(false);
        }
      }
    }
  };

  // api call for lazy load
  const loadRecordsList = async (first, last, direction) => {
    // console.log("loadRecordsList props", first, last, direction);
    setIsLoading(true);
    let url;
    if (detailedViewVal) {
      url = `${API_BASE_URL}/Customer/CustomerFileTrackingbyCustomerIdAndDetailedView?CustomerId=${
        selectedCustomer.customerId
      }&StartRow=${first + 1}&EndRow=${last}`;
    } else {
      url = `${API_BASE_URL}/Customer/CustomerFileTrackingbyCustomerIdAndPagination?CustomerId=${
        selectedCustomer.customerId
      }&StartRow=${first + 1}&EndRow=${last}`;
    }

    console.log("loadRecordsList recordTypeVal", recordTypeVal);
    if (recordTypeVal !== "") {
      url = `${url}&RecordType=${recordTypeVal}`;
    }

    console.log("loadRecordsList selectedYear", value, selectedYear);
    if (value === "2" && selectedYear !== "") {
      url = `${url}&Year=${selectedYear}`;
    }

    console.log("loadRecordsList upcomingDueDate", upcomingDueDateVal);
    if (upcomingDueDateVal !== "") {
      url = `${url}&UpcomingDuedateSearchType=${upcomingDueDateVal}`;
    }

    // console.log("loadRecordsList fliter/sort", isFilterChange, sortingData);
    if (isFilterChange !== undefined && isFilterChange.length > 0) {
      // url filter parameters
      isFilterChange.forEach((ele) => {
        url = `${url}&${ele.name}=${ele.val}${
          ele.searchType ? `&${ele.searchType}=${ele.searchTypeVal}` : ``
        }`;
      });
    }

    if (
      sortingData &&
      typeof sortingData === "object" &&
      sortingData.sortBy !== "" &&
      sortingData.sortOrder !== ""
    ) {
      // url sort parameters
      let sortParameter = getSortData(sortingData.sortBy);
      console.log("getSortData", sortParameter);
      let order = sortingData.sortOrder === 1 ? "Ascending" : "Descending";

      url = `${url}&${sortParameter.SortByParameter}=${sortParameter.SortByVal}&${sortParameter.SortOrderParameter}=${order}`;
    }

    // console.log("loadRecordsList url", url);
    try {
      //get data
      const res = await axios.get(url);
      // console.log("loadRecordsList res", res.data);

      if (res.data) {
        setIsLoading(true);
        let ogList;
        if (value === "2" && selectedYear !== "") {
          ogList = clientFilingHistory.map((ele) => ele);
        } else {
          ogList = clientFiling.map((ele) => ele);
        }
        let list = await modifyList(res.data.data.items, direction, ogList);
        setTotalRecords(res.data.data.totalItems);

        console.log(
          "loadRecordsList trackNow/postAward",
          res.data.data,
          recordTypeVal,
          isFilterChange,
          isFilterChange.length <= 0,
          sortingData,
          sortingData.sortBy === ""
        );

        if (
          recordTypeVal === "" &&
          isFilterChange &&
          isFilterChange.length <= 0 &&
          sortingData &&
          sortingData.sortBy === ""
        ) {
          if (sortRecord === "Clear") {
            setTrackNowValUpc(res.data.data.trackNow);
            setPostAwardValUpc(res.data.data.postAward);
            if (
              res.data.data.trackNow === true &&
              res.data.data.postAward === true
            ) {
              setCheckBoxDisabled(false);
            } else {
              setCheckBoxDisabled(true);
            }
          } else {
            setTrackNowVal(res.data.data.trackNow);
            setPostAwardVal(res.data.data.postAward);
            if (
              res.data.data.trackNow === true &&
              res.data.data.postAward === true
            ) {
              setCheckBoxDisabled(false);
            } else {
              setCheckBoxDisabled(true);
            }
          }
        }

        // setRefreshDetails(null)
        return list;
      } else {
        return false;
      }
    } catch (err) {
      // console.log("loadRecordsList err", err);
    }
  };

  //maping records for lazy load
  const modifyList = (list, direction, ogList) => {
    //console.log("modifyList", list, direction);
    try {
      if (list.length > 0) {
        //list has data

        let fedLookup = [];
        let awardAgencyLookup = [];
        let awardTypeLookup = [];
        //let stateCatLookup = [];
        // setFederalFilterSelectedValues([]);
        let updCustomers = list.map((item, i) => {
          // console.log(`Customer filing M...`, item);
          //console.log("Orig DueDate...", item.dueDate);

          const grantTermCompleteText = item.grantTermComplete ? "Yes" : "No";
          const dueDateFormatted = toLocalTime(item.dueDate);
          const dueDateFilingFormatted = toLocalTime(item.filingDueDate);
          const dueDate = item.dueDate ? item.dueDate : item.filingDueDate;
          // console.log("Local DueDate...", dueDateFormatted);
          const dueDateUTC = new Date(item.dueDate);
          // console.log("Local dueDateUTC...", dueDateUTC);

          const _stateList = getStateName(item.filingStateInfo);
          const programName =
            item.programInfo && item.programInfo.length > 0
              ? item.programInfo[0].programName
              : "";
          const programId =
            item.programInfo && item.programInfo.length > 0
              ? item.programInfo[0].programId
              : "";
          const awardTypeName =
            item.awardType && item.awardType.length > 0
              ? item.awardType[0].awardTypeName
              : "";

          const awardingAgencyName =
            item.awardingAgency && item.awardingAgency.length > 0
              ? item.awardingAgency[0].awardingAgencyName
              : "";
          const filingPeriods1 = item.startdate ? new Date(item.startdate) : "";
          const filingPeriods2 = item.enddate ? new Date(item.enddate) : "";

          const toLocalDateString = (date) =>
            date.toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });

          const filingPeriod = `${toLocalDateString(
            filingPeriods1
          )} - ${toLocalDateString(filingPeriods2)}`;

          // setPostAwardAgencyarray([...postawardagencyArray,awardingAgencyName])
          const _staffassigned = _.map(item.staffAssigned, "userName").join(
            ", "
          );
          // allCats.push(..._categories);
          //TOCHECK
          //.join(", ");

          if (
            !awardAgencyLookup.includes(awardingAgencyName) &&
            awardingAgencyName !== ""
          ) {
            awardAgencyLookup.push(awardingAgencyName);
          }
          if (
            !awardTypeLookup.includes(awardTypeName) &&
            awardTypeName !== ""
          ) {
            awardTypeLookup.push(awardTypeName);
          }

          let date = item.dueDate;
          item.internalDueDate =
            item.dueDate !== null ? new Date(item.dueDate) : null;
          item.dueDateFiling =
            item.filingDueDate !== null ? new Date(item.filingDueDate) : null;

          item.dueDate = date;

          // const affectedCompaniesNames = item.affectedCompanies.map(
          //   (company) => company.customerName
          // );
          // item.formattedAffectedCompany = affectedCompaniesNames;
          // item.formattedAffectedCompany = item.affectedCompanies.map(
          //   (company) => ({
          //     customerName: company.customerName,
          //     type: company.type, // Assuming 'type' property exists in 'company' object
          //     customerId: company.customerId, // Assuming 'customerId' property exists in 'company' object
          //     status: company.status, // Assuming 'status' property exists in 'company' object
          //   })
          // );

          // item.fldAffectedCompany = item.affectedCompanies
          //   .filter((item) => item.status === "Completed")
          //   .map((company) => ({
          //     name: company.customerName,
          //     code: company.customerId, // Assuming 'customerId' property exists in 'company' object
          //     status: company.status === "Completed",
          //   }));

          if (item.filingJuristiction?.includes("Federal")) {
            if (item.recordType === "TrackNow") {
              const _categories = _.map(
                item.businessCategory,
                "businessCategoryName"
              ).join(", ");
              fedLookup.push(..._categories);
              return {
                ...item,
                federalCategories: _categories,
                awardTypeName,
                awardingAgencyName,
                filingPeriod,
                dueDateFormatted,
                dueDateFilingFormatted,
                dueDateUTC,
                dueDate,
                stateList: _stateList,
                programId: programId,
                staffassigned: _staffassigned,
                grantTermComplete: grantTermCompleteText,
                initialGrantTermComplete: grantTermCompleteText,
                trackerListId: `${i}${item.fileTrackingId}`,
              };
            } else {
              fedLookup.push(...programName);
              return {
                ...item,
                federalCategories: programName,
                awardTypeName,
                awardingAgencyName,
                filingPeriod,
                dueDateFormatted,
                dueDateFilingFormatted,
                dueDateUTC,
                dueDate,
                stateList: _stateList,
                programId: programId,
                staffassigned: _staffassigned,
                grantTermComplete: grantTermCompleteText,
                initialGrantTermComplete: grantTermCompleteText,
                trackerListId: `${i}${item.fileTrackingId}`,
              };
            }
          } else if (item.filingJuristiction?.includes("Both")) {
            if (item.recordType === "TrackNow") {
              const categoriesState = item.businessCategory.filter(
                (ele) => ele.state === item.filingStateInfo
              );

              const _categories = categoriesState
                .map((ele) => ele.businessCategoryName)
                .join(", ");
              return {
                ...item,
                stateCategories: _categories,
                federalCategories: _categories,
                awardTypeName,
                awardingAgencyName,
                filingPeriod,
                dueDateFormatted,
                dueDateFilingFormatted,
                dueDateUTC,
                dueDate,
                stateList: _stateList,
                programId: programId,
                staffassigned: _staffassigned,
                grantTermComplete: grantTermCompleteText,
                initialGrantTermComplete: grantTermCompleteText,
                trackerListId: `${i}${item.fileTrackingId}`,
              };
            } else {
              return {
                ...item,
                stateCategories: programName,
                federalCategories: programName,
                awardTypeName,
                awardingAgencyName,
                filingPeriod,
                dueDateFormatted,
                dueDateFilingFormatted,
                dueDateUTC,
                dueDate,
                stateList: _stateList,
                programId: programId,
                staffassigned: _staffassigned,
                grantTermComplete: grantTermCompleteText,
                initialGrantTermComplete: grantTermCompleteText,
                trackerListId: `${i}${item.fileTrackingId}`,
              };
            }
          } else {
            if (item.recordType === "TrackNow") {
              const categoriesState = item.businessCategory.filter(
                (ele) => ele.state === item.filingStateInfo
              );

              const _categories = categoriesState
                .map((ele) => ele.businessCategoryName)
                .join(", ");
              return {
                ...item,
                stateCategories: _categories,
                awardTypeName,
                awardingAgencyName,
                filingPeriod,
                dueDateFormatted,
                dueDateFilingFormatted,
                dueDateUTC,
                dueDate,
                stateList: _stateList,
                programId: programId,
                staffassigned: _staffassigned,
                grantTermComplete: grantTermCompleteText,
                initialGrantTermComplete: grantTermCompleteText,
                trackerListId: `${i}${item.fileTrackingId}`,
              };
            } else {
              return {
                ...item,
                stateCategories: programName,
                awardTypeName,
                awardingAgencyName,
                filingPeriod,
                dueDateFormatted,
                dueDateFilingFormatted,
                dueDateUTC,
                dueDate,
                stateList: _stateList,
                programId: programId,
                staffassigned: _staffassigned,
                grantTermComplete: grantTermCompleteText,
                initialGrantTermComplete: grantTermCompleteText,
                trackerListId: `${i}${item.fileTrackingId}`,
              };
            }
          }
        });
        const _jusrisdictions = updCustomers.map((item) =>
          item.juristiction ? item.juristiction : ""
        );
        // const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
        // console.log("@@Unique Jurisdiction3:", _uniqJurisdictions);
        // console.log("Updated Customers filing M...", updCustomers);
        // // setData(updCustomers);
        // setJurisdiction(_uniqJurisdictions);
        setData(() => updCustomers);
        setFederalCategoriesLookup(Array.from(new Set(fedLookup)));
        setPostAwardAgencyArray(awardAgencyLookup);
        setPostAwardTypeArray(awardTypeLookup);
        updCustomers = updCustomers.map((ele) => {
          let date = ele.dueDate;
          // let isParent =
          //   ele.staffAssigned && ele.staffAssigned.length > 0
          //     ? ele.staffAssigned.some((staff) => staff.company === "Parent")
          //     : false;

          ele.staffAssignedArray =
            ele.staffAssigned.length > 0
              ? ele.staffAssigned.map((ele) => ({
                  name: ele.userName
                    ? ele.userName.charAt(0).toUpperCase() +
                      ele.userName.slice(1)
                    : ele.name,
                  code: ele.userId ? ele.userId : ele.code,
                }))
              : ele.staffAssigned;

          return { ...ele, dueDate: date };
        });

        let tableList = value === "2" ? clientFilingHistory : clientFiling;
        console.log("modifyList updCustomers", updCustomers, tableList);

        let updFilingMastersList;
        if (direction === "down") {
          //scroll down
          if (tableList.length >= 200) {
            //list > 200

            //slice 2 half of list
            let unloadList = ogList.slice(
              ogList.length - pageSize,
              ogList.length
            );
            // console.log("modifyList dw unloadList", unloadList);

            //add new data
            updFilingMastersList = [...unloadList, ...updCustomers];
            // console.log("modifyList dw updFilingMastersList", updFilingMastersList);

            // let list = updFilingMastersList.map((ele)=>ele)
            // setClientFiling(updFilingMastersList);
          } else {
            //list < 200

            // add new data to list
            updFilingMastersList = [...ogList, ...updCustomers];
            // console.log("modifyList dw updFilingMastersList", updFilingMastersList);

            // setClientFiling(updFilingMastersList);
          }
        } else if (direction === "up") {
          //scroll up
          if (tableList.length >= 200) {
            //list > 200

            //slice 1 half of list
            let unloadList = ogList.slice(0, pageSize);
            // console.log("modifyList up unloadList", unloadList);

            //add new data
            updFilingMastersList = [...updCustomers, ...unloadList];
            // console.log("modifyList up updFilingMastersList", updFilingMastersList);

            // setClientFiling(updFilingMastersList);
          } else {
            //list < 200

            //slice 1 half of list
            let unloadList = ogList.slice(0, pageSize);
            // console.log("modifyList up unloadList", unloadList);

            //add new data
            updFilingMastersList = [...updCustomers, ...unloadList];
            // console.log("modifyList up updFilingMastersList", updFilingMastersList);

            // setClientFiling(updFilingMastersList);
          }
        }
        if (value === "2" && selectedYear !== "") {
          setClientFiling([]);
          setInitialValue([]);
          setClientFilingTrackers([]);
          setClientFilingHistory(updFilingMastersList);
          setClientFilingTrackersHistory(updFilingMastersList);
          setInitialClientFilingHistory(updFilingMastersList);
          props.setClientFilingData(updFilingMastersList);
          props.resetRefreshList();
        } else {
          setClientFilingHistory([]);
          setInitialClientFilingHistory([]);
          setClientFilingTrackersHistory([]);
          setClientFiling(updFilingMastersList);
          setClientFilingTrackers(updFilingMastersList);
          setInitialValue(updFilingMastersList);
          props.setClientFilingData(updFilingMastersList);
        }
        setRefreshRecordList(false);
        setLazyLoading(false);
        setIsLoading(false);
        return updFilingMastersList;
      } else {
        //list empty
        // console.log("modifyList empty",list)
        setRefreshRecordList(false);
        setLazyLoading(false);
        setIsLoading(false);
        setClientFiling([]);
        setClientFilingTrackers([]);
        setClientFilingHistory([]);
        setClientFilingTrackersHistory([]);
        return [];
      }
    } catch (error) {
      console.error("err", error);
    }
  };

  //table filter change
  const onFilterChange = async (e) => {
    // console.log("onFilterChange props", e.filters);
    let filterChange = [];

    if (e.filters.global.value !== "" && e.filters.global.value !== null) {
      //global filter
      filterChange.push({
        name: `SearchByFilingName`,
        val: e.filters.global.value,
        searchType: `FilingNameSearchType`,
        searchTypeVal: getSearchType("contains"),
      });
    } else {
      //map filter event
      Object.keys(e.filters).forEach((col) => {
        // console.log("onFilterChange col", col, e.filters[col]);
        if (
          e.filters[col].constraints &&
          e.filters[col].constraints[0].value !== null
        ) {
          if (col === "stateList") {
            Array.isArray(e.filters[col].constraints[0].value)
              ? e.filters[col].constraints[0].value.map((ele, i) => {
                  let changeItem = {
                    name: `SearchByStateInfo`,
                    val: getStateCode(ele),
                  };
                  if (i + 1 === e.filters[col].constraints[0].value.length) {
                    changeItem.searchType = `StateInfoSearchType`;
                    changeItem.searchTypeVal = getSearchType(
                      e.filters[col].constraints[0].matchMode
                    );
                  }
                  filterChange.push(changeItem);
                })
              : filterChange.push({
                  name: `SearchByStateInfo`,
                  val: getStateCode(e.filters[col].constraints[0].value),
                  searchType: `StateInfoSearchType`,
                  searchTypeVal: getSearchType(
                    e.filters[col].constraints[0].matchMode
                  ),
                });
          } else if (col === "filingJuristiction") {
            filterChange.push({
              name: `SearchByJuristiction`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "filingRequired") {
            filterChange.push({
              name: `SearchByRequired`,
              val: e.filters[col].constraints[0].value,
            });
          } else if (col === "awardTypeName") {
            filterChange.push({
              name: `SearchByAwardType`,
              val: e.filters[col].constraints[0].value,
              searchType: `AwardTypeSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "awardingAgencyName") {
            filterChange.push({
              name: `SearchByAwardAgency`,
              val: e.filters[col].constraints[0].value,
              searchType: `AwardAgencySearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "jsicontactName") {
            filterChange.push({
              name: `SearchByJSIStaff`,
              val: e.filters[col].constraints[0].value,
              searchType: `JSIStaffSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "customerName") {
            filterChange.push({
              name: `SearchByCustomerName`,
              val: e.filters[col].constraints[0].value,
              searchType: `CustomerNameSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "federalCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              // console.log(
              //   "onFilterChange i",
              //   i + 1,
              //   e.filters[col].constraints[0].value.length
              // );
              let changeItem = {
                name: `SearchByWhoMustFileFederal`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileFederalSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "stateCategories") {
            e.filters[col].constraints[0].value.map((ele, i) => {
              let changeItem = {
                name: `SearchByWhoMustFileInState`,
                val: ele,
              };
              if (i + 1 === e.filters[col].constraints[0].value.length) {
                changeItem.searchType = `WhoMustFileInStateSearchType`;
                changeItem.searchTypeVal = getSearchType(
                  e.filters[col].constraints[0].matchMode
                );
              }
              filterChange.push(changeItem);
            });
          } else if (col === "internalDueDate") {
            filterChange.push({
              name: `SearchByInternalDueDate`,
              val: formatFilterDate(e.filters[col].constraints[0].value),
              searchType: `InternalDueDateSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "dueDateFiling") {
            filterChange.push({
              name: `SearchByFilingDueDate`,
              val: formatFilterDate(e.filters[col].constraints[0].value),
              searchType: `FilingDueDateSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else if (col === "filingPeriod") {
            filterChange.push({
              name: `SearchByFilingDueDate`,
              val: formatFilterDate(e.filters[col].constraints[0].value),
              searchType: `FilingDueDateSearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          } else {
            filterChange.push({
              name: `SearchBy${col}`,
              val: e.filters[col].constraints[0].value,
              searchType: `${col}SearchType`,
              searchTypeVal: getSearchType(
                e.filters[col].constraints[0].matchMode
              ),
            });
          }
        }
      });
    }
    console.log("onFilterChange filterChange", filterChange);

    //set filter
    handleSetFilterVariable(filterChange);
  };

  const formatFilterDate = (date) => {
    // console.log("formatFilterDate", date);
    let getDate = new Date(date);
    let formatDate = `${getDate.getFullYear()}-${
      getDate.getMonth() + 1
    }-${getDate.getDate()}`;
    // console.log("formatFilterDate formatDate", getDate.getFullYear(), formatDate);
    return formatDate;
  };

  const getStateCode = (stateName) => {
    // console.log("getStateCode", stateName, states);
    const state = states.find((s) => s.state === stateName);
    return state ? state.code : stateName;
  };

  const matchModeVal = [
    { name: "contains", val: "Contains" },
    { name: "in", val: "Contains" },
    { name: "notContains", val: "NotContains" },
    { name: "equals", val: "EqualTo" },
    { name: "dateIs", val: "DateIs" },
    { name: "dateIsNot", val: "DateIsNot" },
    { name: "dateBefore", val: "DateIsBefore" },
    { name: "dateAfter", val: "DateIsAfter" },
  ];

  const getSearchType = (matchMode) => {
    // console.log("getSearchType props", matchMode);
    return matchModeVal.find((ele) => ele.name === matchMode).val;
  };

  //set filter
  const handleSetFilterVariable = (filterChange) => {
    console.log("handleSetFilterVariable props", filterChange);
    setIsFilterChange(() => filterChange);
    setRecordsInView(0);
    setIsLoading(true);
    setClientFiling([]);
    setInitialValue([]);
    setClientFilingHistory([]);
    setInitialClientFilingHistory([]);
    props.setClientFilingData([]);
  };

  //table sort change
  const handleSort = async (e) => {
    console.log("handleSort props", e, e.sortField);
    if (e.sortField !== "") {
      setSortingData(() => ({
        sortBy: e.sortField,
        sortOrder: e.sortOrder,
      }));
      setRecordsInView(0);
      setIsLoading(true);
      setClientFiling([]);
      setInitialValue([]);
      props.setClientFilingData([]);
    }
  };

  const getSortData = (val) => {
    console.log("getSortData props", val);
    if (val === "internalDueDate") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "DueDate",
        SortOrderParameter: "SortOrder",
      };
    } else if (val === "dueDateFiling") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "FilingDueDate",
        SortOrderParameter: "SortOrder",
      };
    } else if (val === "stateList") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "FilingStateInfo",
        SortOrderParameter: "SortOrder",
      };
    } else if (val === "federalCategories" || val === "stateCategories") {
      return {
        SortByParameter: "SortBy",
        SortByVal: "BusinessCategory",
        SortOrderParameter: "SortOrder",
      };
    } else if (val === "staffAssignedArray") {
      return {
        SortByParameter: "SortByStaffAssigned",
        SortByVal: "StaffAssigned",
        SortOrderParameter: "SortOrderStaffAssigned",
      };
    } else if (val === "awardTypeName") {
      return {
        SortByParameter: "SortByAwardType",
        SortByVal: "AwardType",
        SortOrderParameter: "SortOrderAwardType",
      };
    } else if (val === "awardingAgencyName") {
      return {
        SortByParameter: "SortByAwardingAgency",
        SortByVal: "AwardingAgency",
        SortOrderParameter: "SortOrderAwardingAgency",
      };
    } else {
      return {
        SortByParameter: "SortBy",
        SortByVal: val.charAt(0).toUpperCase() + val.slice(1),
        SortOrderParameter: "SortOrder",
      };
    }
  };

  useEffect(() => {
    // console.log("filter/sort change", isFilterChange, sortingData, lazyLoading );
    //on filter/sort change
    if (
      selectedCustomer?.customerId &&
      !lazyLoading &&
      !refreshRecordList &&
      !refreshPageDetails.hasOwnProperty("recordsInView")
    ) {
      setLazyLoading(true);
      const sortAndFilterApiCall = async () => {
        let records = await loadRecordsList(0, 100, "down");
        setRecordsInView(1);
        console.log("recordsCheck", records);
        setIsLoading(false);
      };
      sortAndFilterApiCall();
    }
  }, [isFilterChange, sortingData]);

  useEffect(() => {
    console.log("Total Records updated:", totalRecords);
  }, [totalRecords]);

  const refreshRecords = async () => {
    console.log("refreshRecords props");
    const vScroll =
      value === "1"
        ? sortRecord === "Clear"
          ? dtUpcoming.current.getVirtualScroller()
          : dt.current.getVirtualScroller()
        : dtHistory.current.getVirtualScroller();
    let range = vScroll.getRenderedRange();
    console.log("refreshRecords vScroll range", vScroll, range);

    let records;
    console.log("refreshRecords refreshDetails", refreshDetails);
    if (refreshDetails?.pageInView || refreshDetails?.noOfPages) {
      let first = refreshDetails.pageInView * pageSize;
      records = await loadRecordsList(
        first,
        first + refreshDetails?.noOfPages * pageSize,
        "down"
      );
    } else {
      records = await loadRecordsList(0, pageSize, "down");
    }
    console.log("refreshRecords records", records, props.keepSelectedRecords);

    if (records) {
      if (refreshDetails?.viewport && refreshDetails?.noOfPages) {
        console.log(
          "refreshRecords scrollInView",
          refreshDetails.viewport.first,
          dt
        );
        setRecordsInView((old) => old + refreshDetails?.noOfPages);
        // scrollToSelected()
      } else {
        setRecordsInView((old) => old + 1);
      }
      if (refreshPageDetails.hasOwnProperty("recordsInView")) {
        setIsLoading(true);
        const myTimeout = setTimeout(() => {
          const vScroll =
            value === "1"
              ? sortRecord === "Clear"
                ? dtUpcoming.current.getVirtualScroller()
                : dt.current.getVirtualScroller()
              : dtHistory.current.getVirtualScroller();
          console.log("refreshRecords refreshDetails", refreshDetails);
          vScroll.scrollToIndex(refreshDetails.viewport.first);
          setIsLoading(false);
          setRefreshPageDetails({});
        }, 10000);
      }
      if (props.keepSelectedRecords.length > 0) {
        setSelectedClientFiling([]);
        props.setSelectedFiling([]);
        console.log(
          "refreshRecords keepSelectedRecords",
          props.keepSelectedRecords
        );
        let selectedRecordsIds = props.keepSelectedRecords.map(
          (ele) => ele.fileTrackingId
        );
        let getSelectedRecords = records.filter((ele) =>
          selectedRecordsIds.includes(ele.fileTrackingId)
        );
        console.log(
          "refreshRecords getSelectedRecords",
          selectedRecordsIds,
          getSelectedRecords
        );
        setSelectedClientFiling(getSelectedRecords);
        props.setSelectedFiling(getSelectedRecords);
        props.resetKeepSelectedRecords([]);
      }
    }
  };

  useEffect(() => {
    console.log(
      "refreshRecordList, refreshPageDetails ",
      refreshRecordList,
      selectedCustomer?.customerId,
      !lazyLoading,
      refreshRecordList && selectedCustomer?.customerId && !lazyLoading
    );
    if (refreshRecordList && selectedCustomer?.customerId && !lazyLoading) {
      setLazyLoading(true);
      refreshRecords();
    }
  }, [refreshRecordList]);

  useEffect(() => {
    console.log("recordsInView,", recordsInView);
  }, [recordsInView]);

  useEffect(() => {
    console.log("masters,", clientFiling);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFiling, clientFiling.length, JSON.stringify(clientFiling)]);

  useEffect(() => {
    console.log("clientFilingHistory,", clientFilingHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientFilingHistory,
    clientFilingHistory.length,
    JSON.stringify(clientFilingHistory),
  ]);

  useEffect(() => {
    console.log("lazyLoading", lazyLoading);
  }, [lazyLoading]);

  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  console.log("tracknowVal", trackNowVal, "PostawardVal", postAwardVal);

  console.log(
    "hasChangeStatusPermission",
    hasChangeStatusPermission,
    selectedCustomer
  );
  return (
    <Fragment>
      <Dialog
        header="Info"
        visible={showAlert}
        style={{ width: "30vw" }}
        onHide={() => closeAlert()}
        footer={footerContent}
      >
        <p className="m-0">{alertMessage}</p>
      </Dialog>
      {/* <Dialog
        header='Info'
        visible={showInfo}
        style={{ width: "30vw" }}
        onHide={() => closeRowEditing()}
        footer={footerContentInfo}
      >
        <p className='m-0'>{infoMessage}</p>
      </Dialog> */}
      <Dialog
        header={`Affected Companies`}
        visible={showAffectedCompanies}
        style={{ width: "30vw", minHeight: "40vh", maxHeight: "20rem" }}
        className="affectedCompaniesDialog"
        onHide={() => setShowAffectedCompanies(false)}
      >
        <p style={{ fontWeight: "bold", margin: 0 }}>
          {affectedCompaniesDetails.recordName}
        </p>
        <ul>
          {affectedCompaniesDetails.list.map((ele) => (
            <li>{ele}</li>
          ))}
        </ul>
      </Dialog>
      <Dialog
        header={`Staff Assigned`}
        visible={showStaffAssigned}
        style={{ width: "30vw", minHeight: "40vh", maxHeight: "20rem" }}
        className="affectedCompaniesDialog"
        onHide={() => setShowStaffAssigned(false)}
      >
        <p style={{ fontWeight: "bold", margin: 0 }}>
          {staffAssignedDetails.recordName}
        </p>
        {staffAssignedDetails.list.length > 0 ? (
          <ul>
            {staffAssignedDetails.list.map((ele) => (
              <li>{ele}</li>
            ))}
          </ul>
        ) : (
          <Typography sx={{ marginTop: "20px" }}>No Staff Assigned</Typography>
        )}
      </Dialog>
      <Dialog
        header="Confirmation"
        visible={grantTermConfrimation}
        style={{ width: "30vw" }}
        onHide={() => grantTermCloseAlert()}
        footer={grantTermFooterContent}
      >
        <p className="m-0">
          Marking Grant Term Completed is not reversible and all upcoming
          records will be removed. Are you sure you want to continue?
        </p>
      </Dialog>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange}>
            <Tab label="Tracker Active" value="1" disabled={isLoading} />
            <Tab label="Tracker History" value="2" disabled={isLoading} />
          </TabList>
        </Box>
        <TabPanel value="1" className="filingPeriodTable">
          {sortRecord === "Clear" ? (
            <DataTable
              ref={dtUpcoming}
              scrollable
              resizableColumns
              className="frozen-tracker-upd"
              columnResizeMode="expand"
              rowClassName={rowClass}
              showGridlines
              emptyMessage="No records found "
              selection={selectedClientFiling || []}
              // selectionMode='radiobutton'
              onSelectionChange={(e) => selectFilingRecord(e.value)}
              value={selectedCustomer ? ClientFilingTrackers : []}
              dataKey="trackerListId"
              tableStyle={{ minWidth: "50rem" }}
              // paginator={!viewAll}
              // rows={viewAll ? clientFiling.length : 200}
              // rowsPerPageOptions={[150, 200, 250, 300]}
              filters={filters}
              // sortField="dueDate"
              // sortOrder={-1}
              filterMode="multiple"
              selectionAutoFocus={false}
              autoFocus={false}
              // globalFilterFields={["filingName"]}
              //filterDisplay="row"
              header={headerDue}
              loading={isLoading}
              virtualScrollerOptions={{
                // lazy: true,
                onScroll: loadDataLazy,
                itemSize: 70,
                delay: 0,
                scrollHeight: "550px",
                appendOnly: true,
                // onScrollIndexChange: scrollToSelected,
                // showLoader: true,
                // loading: lazyLoading,
                // loadingTemplate,
              }}
              scrollHeight="550px"
              onFilter={(e) => onFilterChange(e)}
              sortField={sortingData.sortBy}
              sortOrder={sortingData.sortOrder}
              onSort={(e) => handleSort(e)}
            >
              <Column
                selectionMode="single"
                // headerStyle={{ width: "5%", minWidth: "4rem" }}
                style={{ width: "1rem", margin: "0" }}
              >
                Select
              </Column>
              <Column
                header="Indicators"
                style={{ textAlign: "center" }}
                body={(rowData) => (
                  <>
                    <Stack direction="row">
                      <TrackClientComment rowData={rowData} />
                      <TrackClientAttachment rowData={rowData} />
                      <TrackClientRefDocs rowData={rowData} />
                    </Stack>
                  </>
                )}
              ></Column>
              {/* <Column
                rowEditor
                header='Edit'
                style={{ width: '0rem', margin: '0' }}
              ></Column> */}
              <Column
                field="filingName"
                header="Filing Name"
                sortable
                className="filingNameColumn"
                frozen
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterPlaceholder="Search by Filing Name"
                style={{ minWidth: "11rem", maxWidth: "25rem" }}
                body={(rowData) => cellListDisplayName(rowData)}
              ></Column>
              {/* <Column
            field='filingDescription'
            header='Filing Description'
            sortable
            style={{ minWidth: "15rem" }}
          ></Column> */}
              {/* <Column
                header='Internal Due Date'
                filterField='internalDueDate'
                dataType='date'
                style={{ minWidth: "13rem" }}
                body={cellEditorBody}
                filter
                frozen
                showAddButton={false}
                showFilterOperator={false}
                filterElement={dateFilterTemplate}
                //className="column-small"
                field='internalDueDate'
                sortable
              editor={
                hasChangeDueDatePermission
                  ? (options) => cellEditor(options)
                  : undefined
              }
              onCellEditInit={onCellEditInit}
              onCellEditComplete={onCellEditComplete}
              ></Column> */}

              <Column
                //className="column-small"
                field="dueDateFiling"
                header="Filing Due Date"
                sortable
                style={{ minWidth: "12rem", zIndex: 1 }}
                dataType="date"
                filter
                frozen
                showAddButton={false}
                showFilterOperator={false}
                filterField="dueDateFiling"
                filterElement={dateFilterTemplate}
                body={cellFilingDateBody}
                // onCellEditInit={onCellEditInit}
                // onCellEditComplete={onCellEditComplete}
              ></Column>

              {postAwardValUpc && (
                <Column
                  //className="column-small"
                  field="filingPeriod"
                  header="Filing Period"
                  // filter
                  frozen={postAwardVal && !trackNowVal ? true : false}
                  showAddButton={false}
                  showFilterOperator={false}
                  style={{ minWidth: "15rem", maxWidth: "15rem" }}
                  // filterMatchModeOptions={[
                  //   { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  //   {
                  //     value: FilterMatchMode.NOT_CONTAINS,
                  //     label: "Not Contains",
                  //   },
                  //   { value: FilterMatchMode.EQUALS, label: "Equal" },
                  // ]}
                  // filterPlaceholder="Search by Filing Period"

                  // filterElement={dateFilterTemplate}
                  // body={cellFilingDateBody}
                  // onCellEditInit={onCellEditInit}
                  // onCellEditComplete={onCellEditComplete}
                ></Column>
              )}

              <Column
                className="column-small"
                field="status"
                header="Status"
                frozen={
                  (trackNowVal && !postAwardVal ? true : false) ||
                  ClientFilingTrackers.length === 0
                }
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterMenuStyle={{ width: "14rem" }}
                filterElement={statusRowFilterTemplate}
                onFilterMatchModeChange={() =>
                  setFederalFilterSelectedValues([])
                }
                onFilterClear={() => setFederalFilterSelectedValues([])}
                filterPlaceholder="Search by Status"
                style={{ maxWidth: "13rem" }}
                body={cellStatusEditorBody}
                editor={
                  hasChangeStatusPermission &&
                  selectedCustomer &&
                  (selectedCustomer.isOnlyChildFile ||
                    selectedCustomer.isOnlyParentFile)
                    ? (options) => cellStatusEditor(options)
                    : undefined
                }
                // onCellEditInit={onCellStatusEditInit}
                // // onCellEditComplete={onCellEditStatusComplete}
              ></Column>

              <Column
                field="staffAssignedArray"
                header="Staff Assigned"
                sortable={
                  detailedViewVal ||
                  selectedCustomer?.parentCustomer !== null ||
                  selectedCustomer?.isOnlyParentFile
                }
                showFilterMenu={
                  detailedViewVal ||
                  selectedCustomer?.parentCustomer !== null ||
                  selectedCustomer?.isOnlyParentFile
                }
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterField="staffassigned"
                filterPlaceholder="Search by Staff Assigned"
                style={{ minWidth: "12rem" }}
                body={cellStaffAssignedBody}
                // editor={
                //   hasStaffAssignedPermission
                //     ? (options) => cellStaffAssignedEditor(options)
                //     : undefined
                // }
              ></Column>

              <Column
                field="filingFrequency"
                header="Filing Frequency"
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterPlaceholder="Search by Frequency"
                style={{ minWidth: "15rem" }}
              ></Column>

              <Column
                field="stateList"
                header="State"
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterField="stateList"
                filterPlaceholder="Search by State"
                filterMenuStyle={{ width: "15rem" }}
                filterElement={stateRowFilterTemplate}
                onFilterClear={() => setFederalFilterSelectedValues([])}
                onFilterMatchModeChange={() =>
                  setFederalFilterSelectedValues([])
                }
                style={{ minWidth: "13rem" }}
                className="column-small"
              ></Column>

              {/* { <Column
                field='formattedAffectedCompany'
                header='Affected Companies'
                // filter

                showAddButton={false}
                showFilterOperator={false}
                // filterMatchModeOptions={[
                //   { value: FilterMatchMode.CONTAINS, label: "Contains" },
                //   {
                //     value: FilterMatchMode.NOT_CONTAINS,
                //     label: "Not Contains",
                //   },
                //   { value: FilterMatchMode.EQUALS, label: "Equal" },
                // ]}
                // filterPlaceholder="Search by Affected Companies"
                style={{ minWidth: "15rem", maxWidth: "15rem" }}
                body={(rowData) => cellListDisplayAffectedComp(rowData)}
              ></Column>} */}

              {trackNowValUpc && (
                <Column
                  field="ruleInfo"
                  header="Rule Info"
                  filter
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.CONTAINS, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterPlaceholder="Search by Rule Info"
                  style={{ minWidth: "15rem", maxWidth: "15rem" }}
                  body={(rowData) => cellListDisplayRuleInfo(rowData)}
                ></Column>
              )}

              <Column
                field="filingJuristiction"
                header="Jurisdiction"
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                showFilterMatchModes={false}
                filterElement={jurisdictionRowFilterTemplate}
                onFilterMatchModeChange={() =>
                  setFederalFilterSelectedValues([])
                }
                onFilterClear={() => setFederalFilterSelectedValues([])}
                filterPlaceholder="Search by Jurisdiction"
                style={{ minWidth: "14rem" }}
              ></Column>

              {trackNowValUpc && (
                <Column
                  field="filingRequired"
                  header="Required"
                  style={{ minWidth: "13rem" }}
                  className="column-small"
                  filter
                  filterElement={requiredRowFilterTemplate}
                  onFilterClear={() => setFederalFilterSelectedValues([])}
                  showAddButton={false}
                  showFilterOperator={false}
                  showFilterMatchModes={false}
                  filterPlaceholder="Search by Required"
                >
                  Select
                </Column>
              )}

              {postAwardValUpc && (
                <Column
                  field="awardTypeName"
                  header="Award Type "
                  sortable
                  filter
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterElement={postawardTypeRowFilterTemplate}
                  onFilterMatchModeChange={() => setMasterCatFilter([])}
                  onFilterClear={() => setMasterCatFilter([])}
                  filterPlaceholder="Search by Award Type"
                  style={{ minWidth: "15rem" }}
                ></Column>
              )}

              {postAwardValUpc && (
                <Column
                  field="awardingAgencyName"
                  header="Award Agency"
                  sortable
                  filter
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterElement={postawardAgencyRowFilterTemplate}
                  onFilterMatchModeChange={() => setMasterCatFilter([])}
                  onFilterClear={() => setMasterCatFilter([])}
                  filterPlaceholder="Search by Award Agency"
                  style={{ minWidth: "15rem" }}
                ></Column>
              )}

              {trackNowValUpc && (
                <Column
                  field="federalCategories"
                  header="Who Must File Federal"
                  // sortable
                  body={(rowData) =>
                    CellListDisplayFederal(rowData, "federalCategories")
                  }
                  filter
                  filterField="federalCategories"
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  onFilterMatchModeChange={() =>
                    setFederalFilterSelectedValues([])
                  }
                  filterElement={(options) =>
                    upcomingCategoryFilter(options, "federalCategories")
                  }
                  onFilterClear={() => setUpcomingCatFilter([])}
                  filterPlaceholder="Search by Cateogry"
                  style={{ minWidth: "15rem", maxWidth: "15rem" }}
                  // // showFilterMenu={false}
                  // filterMenuStyle={{ width: "14rem" }}
                  //showClearButton={false}
                  // filterElement={federalCategoryRowFilterTemplate}
                  // onFilterClear={() => setFederalCatFilter([])}
                ></Column>
              )}

              {trackNowValUpc && (
                <Column
                  field="stateCategories"
                  header="Who Must File in State"
                  // sortable
                  filter
                  filterField="stateCategories"
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  onFilterMatchModeChange={() => setUpcomingCatFilter([])}
                  filterElement={(options) =>
                    upcomingCategoryFilter(options, "stateCategories")
                  }
                  onFilterClear={() => setUpcomingCatFilter([])}
                  body={(rowData) =>
                    CellListDisplayFederal(rowData, "stateCategories")
                  }
                  // filterElement={stateCategoryRowFilterTemplate}
                  // onFilterClear={() => setStateCatFilter([])}
                  filterPlaceholder="Search by Cateogry"
                  // showFilterMenu={false}
                  // filterMenuStyle={{ width: "14rem" }}
                  //  showClearButton={false}
                  style={{ minWidth: "15rem", maxWidth: "15rem" }}
                ></Column>
              )}

              {/* <Column
            field='jsidept'
            header='JSI Dept'
            style={{ minWidth: "14rem" }}
          ></Column> */}
              {/* <Column
            field='jsiContactEmail'
            header='JSI Contact Email'
            style={{ minWidth: "14rem" }}
          ></Column> */}
              <Column
                field="whereToFile"
                header="Where to file"
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                body={(rowData) => cellListDisplayWhereToFile(rowData)}
                filterPlaceholder="Search by Where to File"
                style={{ minWidth: "13rem", maxWidth: "13rem" }}
              ></Column>
              <Column
                field="notes"
                header="Notes"
                headerStyle={{ minWidth: "20rem", maxWidth: "20rem" }}
                style={{ minWidth: "45rem", maxWidth: "45rem" }}
                body={(rowData, field) => cellListDisplayNotes(rowData, field)}
              ></Column>
            </DataTable>
          ) : (
            <DataTable
              ref={dt}
              scrollable
              resizableColumns
              className="frozen-tracker-active"
              columnResizeMode="expand"
              rowClassName={rowClass}
              showGridlines
              emptyMessage="No records found "
              value={selectedCustomer ? ClientFilingTrackers : []}
              selection={selectedClientFiling || []}
              // selectionMode='radiobutton'
              onSelectionChange={(e) => selectFilingRecord(e.value)}
              dataKey="trackerListId"
              tableStyle={{ minWidth: "50rem" }}
              onRowSelect={onRowSelect}
              // paginator={!viewAll}
              selectionAutoFocus={false}
              autoFocus={false}
              // rows={viewAll ? clientFiling.length : 200}
              // rowsPerPageOptions={[150, 200, 250, 300]}

              filters={filters}
              // sortField="dueDate"
              // sortOrder={-1}
              filterMode="multiple"
              globalFilterFields={["filingName"]}
              //filterDisplay="row"
              //  onRowEditInit={onRowEditInit}
              editMode="row"
              onRowEditComplete={onRowEditComplete}
              onRowEditCancel={onRowEditCancel}
              //onRowEditSave={onRowEditComplete}
              header={header}
              loading={isLoading}
              virtualScrollerOptions={{
                // lazy: true,
                onScroll: loadDataLazy,
                itemSize: 70,
                delay: 0,
                scrollHeight: "550px",
                appendOnly: true,
                // onScrollIndexChange: scrollToSelected,
                // showLoader: true,
                // loading: lazyLoading,
                // loadingTemplate,
              }}
              scrollHeight="550px"
              onFilter={(e) => onFilterChange(e)}
              sortField={sortingData.sortBy}
              sortOrder={sortingData.sortOrder}
              onSort={(e) => handleSort(e)}
            >
              <Column
                selectionMode="multiple"
                // headerStyle={{ width: "5%", minWidth: "4rem" }}
                style={{ width: "1rem", margin: "0" }}
              >
                Select
              </Column>
              <Column
                header="Indicators"
                style={{ textAlign: "center" }}
                body={(rowData) => (
                  <>
                    <Stack direction="row">
                      <TrackClientComment rowData={rowData} />
                      <TrackClientAttachment rowData={rowData} />
                      <TrackClientRefDocs rowData={rowData} />
                    </Stack>
                  </>
                )}
              ></Column>
              <Column
                rowEditor
                header="Edit"
                style={{ width: "0rem", margin: "0" }}
              ></Column>
              <Column
                field="filingName"
                header="Filing Name"
                sortable
                className="filingNameColumn"
                filter
                frozen
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterPlaceholder="Search by Filing Name"
                style={{ width: "20rem" }}
                body={(rowData) => cellListDisplayName(rowData)}
              ></Column>
              {/* <Column
            field='filingDescription'
            header='Filing Description'
            sortable
            style={{ minWidth: "15rem" }}
          ></Column> */}

              {/* {postAwardVal &&               
              <Column
                header='Internal Due Date'
                filterField='internalDueDate'
                dataType='date'
                frozen
                className='internalDueDate'
                style={{ width: "9rem" }}
                body={cellEditorBody}
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterElement={dateFilterTemplate}
                //className="column-small"
                field='internalDueDate'
                sortable
                editor={
                  hasChangeDueDatePermission
                    ? (options) => cellEditor(options)
                    : undefined
                }

              // onCellEditInit={onCellEditInit}
              // onCellEditComplete={onCellEditComplete}
              ></Column>
            } */}

              <Column
                //className="column-small"
                field="dueDateFiling"
                header="Filing Due Date"
                sortable
                style={{ minWidth: "12rem", zIndex: 1 }}
                dataType="date"
                frozen
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterField="dueDateFiling"
                filterElement={dateFilterTemplate}
                body={cellFilingDateBody}
                editor={(options) => {
                  const rowData = options.rowData;
                  const { recordType } = rowData;
                  console.log("enableEditor recordType", recordType);
                  const enableEditor =
                    recordType === "PostAward" && hasChangeDueDatePermission;
                  console.log("enableEditor", enableEditor);
                  return cellFilingEditor(options, enableEditor);
                }}
                // onCellEditInit={onCellEditInit}
                // onCellEditComplete={onCellEditComplete}
              ></Column>

              {postAwardVal && (
                <Column
                  //className="column-small"
                  field="filingPeriod"
                  header="Filing Period"
                  // filter
                  frozen={postAwardVal && !trackNowVal ? true : false}
                  showAddButton={false}
                  showFilterOperator={false}
                  style={{ minWidth: "15rem", maxWidth: "15rem" }}
                  //   filterMatchModeOptions={[
                  //     { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  //     {
                  //       value: FilterMatchMode.NOT_CONTAINS,
                  //       label: "Not Contains",
                  //     },
                  //     { value: FilterMatchMode.EQUALS, label: "Equal" },
                  //   ]}
                  //   filterPlaceholder="Search by Filing Period"
                  // filterElement={filingPeriodFilterTemplate}
                  // body={cellFilingDateBody}
                  // onCellEditInit={onCellEditInit}
                  // onCellEditComplete={onCellEditComplete}
                ></Column>
              )}

              <Column
                //  className='column-small'
                field="status"
                header="Status"
                sortable
                frozen={
                  (trackNowVal && !postAwardVal ? true : false) ||
                  ClientFilingTrackers.length === 0
                }
                filter
                className={
                  trackNowVal && !postAwardVal ? "status" : "status-hide"
                }
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterMenuStyle={{ width: "9rem" }}
                filterElement={statusRowFilterTemplate}
                onFilterMatchModeChange={() =>
                  setFederalFilterSelectedValues([])
                }
                onFilterClear={() => setFederalFilterSelectedValues([])}
                filterPlaceholder="Search by Status"
                style={{ width: "9rem" }}
                body={cellStatusEditorBody}
                editor={
                  hasChangeStatusPermission &&
                  selectedCustomer &&
                  (selectedCustomer.isOnlyChildFile ||
                    selectedCustomer.isOnlyParentFile ||
                    (selectedCustomer.bothParentAndChild && detailedViewVal))
                    ? (options) => cellStatusEditor(options)
                    : undefined
                }

                // onCellEditInit={onCellStatusEditInit}
                // // onCellEditComplete={onCellEditStatusComplete}
              ></Column>

              {postAwardVal && (
                <Column
                  className="column-small"
                  field="grantTermComplete"
                  header="Grant Term Complete"
                  sortable
                  filter
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterField="GrantTermcomplete"
                  filterMenuStyle={{ width: "14rem" }}
                  filterPlaceholder="Search by GrantTermcomplete"
                  style={{ maxWidth: "13rem" }}
                  body={cellGrantTermcompleteEditorBody}
                  // editor={
                  //    hasChangeGrantTermcompletePermission
                  //     ?
                  //      (options) => cellGrantTermcompleteEditor(options)
                  //     : undefined
                  // }

                  editor={(options) => {
                    const rowData = options.rowData;
                    const { recordType, status } = rowData;

                    // Check if recordType is "postaward" and status is "completed"
                    const enableEditor =
                      recordType === "PostAward" && status === "Completed";
                    return enableEditor
                      ? cellGrantTermcompleteEditor(options)
                      : null; // Return null or undefined to disable the editor
                  }}
                ></Column>
              )}

              {trackNowVal && (
                <Column
                  header="Affected Companies"
                  field="customerName"
                  style={{ minWidth: "11rem", maxWidth: "25rem" }}
                  body={(rowData) =>
                    CellAffectedCompaniesEditorBody(
                      rowData,
                      "affectedCompanies"
                    )
                  }
                  sortable={
                    detailedViewVal ||
                    selectedCustomer?.parentCustomer !== null ||
                    selectedCustomer?.isOnlyParentFile
                  }
                  filter
                  showAddButton={false}
                  showFilterOperator={false}
                  showFilterMenu={
                    detailedViewVal ||
                    selectedCustomer?.parentCustomer !== null ||
                    selectedCustomer?.isOnlyParentFile
                  }
                  show
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.CONTAINS, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterField="customerName"
                  filterPlaceholder="Search by Customer Name"
                ></Column>
              )}

              <Column
                field="staffAssignedArray"
                header="Staff Assigned"
                filter
                sortable={
                  detailedViewVal ||
                  selectedCustomer?.parentCustomer !== null ||
                  selectedCustomer?.isOnlyParentFile
                }
                showFilterMenu={
                  detailedViewVal ||
                  selectedCustomer?.parentCustomer !== null ||
                  selectedCustomer?.isOnlyParentFile
                }
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterField="staffassigned"
                filterPlaceholder="Search by Staff Assigned"
                style={{ width: "12rem" }}
                body={(rowData, field) =>
                  CellListDisplayStaffAssigned(rowData, field)
                }
                editor={
                  hasStaffAssignedPermission
                    ? (options) => cellStaffAssignedEditor(options)
                    : undefined
                }
              ></Column>

              <Column
                field="filingFrequency"
                header="Filing Frequency"
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterPlaceholder="Search by Frequency"
                style={{ minWidth: "15rem" }}
              ></Column>

              <Column
                field="stateList"
                header="State"
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterField="stateList"
                filterPlaceholder="Search by State"
                filterMenuStyle={{ width: "15rem" }}
                filterElement={stateRowFilterTemplate}
                onFilterClear={() => setFederalFilterSelectedValues([])}
                style={{ minWidth: "13rem" }}
                className="column-small"
              ></Column>

              {postAwardVal && !trackNowVal && (
                <Column
                  header="Affected Companies"
                  style={{ minWidth: "11rem", maxWidth: "25rem" }}
                  body={(rowData) =>
                    CellAffectedCompaniesEditorBody(
                      rowData,
                      "affectedCompanies"
                    )
                  }
                  sortable={
                    detailedViewVal ||
                    selectedCustomer?.parentCustomer !== null ||
                    selectedCustomer?.isOnlyParentFile
                  }
                  filter
                  showAddButton={false}
                  showFilterOperator={false}
                  showFilterMenu={
                    detailedViewVal ||
                    selectedCustomer?.parentCustomer !== null ||
                    selectedCustomer?.isOnlyParentFile
                  }
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.CONTAINS, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterField="customerName"
                  filterPlaceholder="Search by Customer Name"
                ></Column>
              )}

              {trackNowVal && (
                <Column
                  field="ruleInfo"
                  header="Rule Info"
                  filter
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.CONTAINS, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterPlaceholder="Search by Rule Info"
                  style={{ minWidth: "15rem", maxWidth: "15rem" }}
                  body={(rowData) => cellListDisplayRuleInfo(rowData)}
                ></Column>
              )}

              <Column
                field="filingJuristiction"
                header="Jurisdiction"
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                showFilterMatchModes={false}
                filterElement={jurisdictionRowFilterTemplate}
                filterPlaceholder="Search by Jurisdiction"
                style={{ minWidth: "14rem" }}
              ></Column>

              {trackNowVal && (
                <Column
                  field="filingRequired"
                  header="Required"
                  style={{ minWidth: "13rem" }}
                  className="column-small"
                  filter
                  filterElement={requiredRowFilterTemplate}
                  onFilterClear={() => setFederalFilterSelectedValues([])}
                  showAddButton={false}
                  showFilterOperator={false}
                  showFilterMatchModes={false}
                  filterPlaceholder="Search by Required"
                >
                  Select
                </Column>
              )}

              {postAwardVal && (
                <Column
                  field="awardTypeName"
                  header="Award Type "
                  sortable
                  filter
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterElement={postawardTypeRowFilterTemplate}
                  onFilterMatchModeChange={() => setMasterCatFilter([])}
                  onFilterClear={() => setMasterCatFilter([])}
                  filterPlaceholder="Search by Award Type"
                  style={{ minWidth: "15rem" }}
                ></Column>
              )}

              {postAwardVal && (
                <Column
                  field="awardingAgencyName"
                  header="Award Agency"
                  sortable
                  filter
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  filterElement={postawardAgencyRowFilterTemplate}
                  onFilterMatchModeChange={() => setMasterCatFilter([])}
                  onFilterClear={() => setMasterCatFilter([])}
                  filterPlaceholder="Search by Award Agency"
                  style={{ minWidth: "15rem" }}
                ></Column>
              )}

              {trackNowVal && (
                <Column
                  field="federalCategories"
                  header="Who Must File Federal"
                  // sortable
                  body={(rowData) =>
                    CellListDisplayFederal(rowData, "federalCategories")
                  }
                  filter
                  filterField="federalCategories"
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  onFilterMatchModeChange={() =>
                    setFederalFilterSelectedValues([])
                  }
                  filterElement={(options) =>
                    federalFilterElement(options, "federalCategories")
                  }
                  onFilterClear={() => setFederalFilterSelectedValues([])}
                  filterPlaceholder="Search by Cateogry"
                  style={{ minWidth: "15rem", maxWidth: "15rem" }}
                  // // showFilterMenu={false}
                  // filterMenuStyle={{ width: "14rem" }}
                  //showClearButton={false}
                  // filterElement={federalCategoryRowFilterTemplate}
                  // onFilterClear={() => setFederalCatFilter([])}
                ></Column>
              )}

              {trackNowVal && (
                <Column
                  field="stateCategories"
                  header="Who Must File in State"
                  // sortable
                  filter
                  filterField="stateCategories"
                  showAddButton={false}
                  showFilterOperator={false}
                  filterMatchModeOptions={[
                    { value: FilterMatchMode.IN, label: "Contains" },
                    {
                      value: FilterMatchMode.NOT_CONTAINS,
                      label: "Not Contains",
                    },
                    { value: FilterMatchMode.EQUALS, label: "Equal" },
                  ]}
                  onFilterMatchModeChange={() =>
                    setFederalFilterSelectedValues([])
                  }
                  filterElement={(options) =>
                    federalFilterElement(options, "stateCategories")
                  }
                  onFilterClear={() => setFederalFilterSelectedValues([])}
                  body={(rowData) =>
                    CellListDisplayFederal(rowData, "stateCategories")
                  }
                  // filterElement={stateCategoryRowFilterTemplate}
                  // onFilterClear={() => setStateCatFilter([])}
                  filterPlaceholder="Search by Cateogry"
                  // showFilterMenu={false}
                  // filterMenuStyle={{ width: "14rem" }}
                  //  showClearButton={false}
                  style={{ minWidth: "15rem", maxWidth: "15rem" }}
                ></Column>
              )}

              {/* <Column
            field='jsidept'
            header='JSI Dept'
            style={{ minWidth: "14rem" }}
          ></Column> */}
              {/* <Column
            field='jsiContactEmail'
            header='JSI Contact Email'
            style={{ minWidth: "14rem" }}
          ></Column> */}
              <Column
                field="whereToFile"
                header="Where to file"
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                body={(rowData) => cellListDisplayWhereToFile(rowData)}
                filterPlaceholder="Search by Where to File"
                style={{ minWidth: "13rem", maxWidth: "13rem" }}
              ></Column>
              <Column
                field="notes"
                header="Notes"
                headerStyle={{ minWidth: "20rem", maxWidth: "20rem" }}
                style={{ minWidth: "45rem", maxWidth: "45rem" }}
                body={(rowData) => cellListDisplayNotes(rowData)}
              ></Column>
            </DataTable>
          )}
        </TabPanel>

        <TabPanel value="2" className="filingPeriodTable">
          <DataTable
            ref={dtHistory}
            key="Tracker History"
            className="frozen-tracker-history"
            scrollable
            resizableColumns
            columnResizeMode="expand"
            rowClassName={rowClass}
            showGridlines
            emptyMessage="No records found "
            value={selectedCustomer ? ClientFilingTrackersHistory : []}
            selection={selectedClientFiling || []}
            // selectionMode='radiobutton'
            onSelectionChange={(e) => selectFilingRecord(e.value)}
            dataKey="trackerListId"
            tableStyle={{ minWidth: "50rem" }}
            onRowSelect={onRowSelect}
            // paginator={!viewAll}
            selectionAutoFocus={false}
            autoFocus={false}
            // rows={viewAll ? clientFilingHistory.length : 200}
            // rowsPerPageOptions={[150, 200, 250, 300]}

            filters={filters}
            filterMode="multiple"
            // sortField="dueDate"
            // sortOrder={-1}
            globalFilterFields={["filingName"]}
            //filterDisplay="row"

            editMode="row"
            onRowEditComplete={onRowEditComplete}
            onRowEditCancel={onRowEditCancel}
            header={header}
            loading={isLoading}
            virtualScrollerOptions={{
              // lazy: true,
              onScroll: loadDataLazy,
              itemSize: 70,
              delay: 0,
              scrollHeight: "550px",
              appendOnly: true,
              // onScrollIndexChange: scrollToSelected,
              // showLoader: true,
              // loading: lazyLoading,
              // loadingTemplate,
            }}
            scrollHeight="550px"
            onFilter={(e) => onFilterChange(e)}
            sortField={sortingData.sortBy}
            sortOrder={sortingData.sortOrder}
            onSort={(e) => handleSort(e)}
          >
            <Column
              selectionMode="multiple"
              // headerStyle={{ width: "5%", minWidth: "4rem" }}
              style={{ width: "1rem", margin: "0" }}
            >
              Select
            </Column>
            <Column
              header="Indicators"
              style={{ textAlign: "center" }}
              body={(rowData) => (
                <>
                  <Stack direction="row">
                    <TrackClientComment rowData={rowData} />
                    <TrackClientAttachment rowData={rowData} />
                    <TrackClientRefDocs rowData={rowData} />
                  </Stack>
                </>
              )}
            ></Column>
            <Column
              rowEditor
              header="Edit"
              style={{ width: "0rem", margin: "0" }}
            ></Column>
            <Column
              field="filingName"
              header="Filing Name"
              sortable
              className="filingNameColumn"
              frozen
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterMatchModeOptions={[
                { value: FilterMatchMode.CONTAINS, label: "Contains" },
                { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                { value: FilterMatchMode.EQUALS, label: "Equal" },
              ]}
              filterPlaceholder="Search by Filing Name"
              style={{ width: "10rem" }}
              body={(rowData) => cellListDisplayName(rowData)}
            ></Column>
            {/* <Column
            field='filingDescription'
            header='Filing Description'
            sortable
            style={{ minWidth: "15rem" }}
          ></Column> */}
            {/* {postAwardVal &&          
            <Column
              header='Internal Due Date'
              filterField='internalDueDate'
              dataType='date'
              style={{ width: "9rem" }}
              body={cellEditorBody}
              frozen
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterElement={dateFilterTemplate}
              //className="column-small"
              field='internalDueDate'
              sortable
              editor={
                hasChangeDueDatePermission
                  ? (options) => cellEditor(options)
                  : undefined
              }
            // onCellEditInit={onCellEditInit}
            // onCellEditComplete={onCellEditComplete}
            ></Column>
          } */}

            <Column
              //className="column-small"
              field="dueDateFiling"
              header="Filing Due Date"
              sortable
              style={{ minWidth: "12rem", zIndex: 1 }}
              dataType="date"
              filter
              frozen
              showAddButton={false}
              showFilterOperator={false}
              filterField="dueDateFiling"
              filterElement={dateFilterTemplate}
              body={cellFilingDateBody}
              // onCellEditInit={onCellEditInit}
              // onCellEditComplete={onCellEditComplete}
            ></Column>

            {postAwardVal && (
              <Column
                //className="column-small"
                field="filingPeriod"
                header="Filing Period"
                // filter
                frozen={postAwardVal && !trackNowVal ? true : false}
                showAddButton={false}
                showFilterOperator={false}
                style={{ minWidth: "15rem", maxWidth: "15rem" }}
                // filterMatchModeOptions={[
                //   { value: FilterMatchMode.CONTAINS, label: "Contains" },
                //   {
                //     value: FilterMatchMode.NOT_CONTAINS,
                //     label: "Not Contains",
                //   },
                //   { value: FilterMatchMode.EQUALS, label: "Equal" },
                // ]}
                // filterPlaceholder="Search by Filing Period"
                // filterElement={dateFilterTemplate}
                // body={cellFilingDateBody}
                // onCellEditInit={onCellEditInit}
                // onCellEditComplete={onCellEditComplete}
              ></Column>
            )}

            <Column
              className="column-small"
              field="status"
              header="Status"
              frozen={trackNowVal && !postAwardVal ? true : false}
              sortable
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterMatchModeOptions={[
                { value: FilterMatchMode.CONTAINS, label: "Contains" },
                { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                { value: FilterMatchMode.EQUALS, label: "Equal" },
              ]}
              filterMenuStyle={{ width: "14rem" }}
              filterElement={statusRowFilterTemplate}
              onFilterMatchModeChange={() => setFederalFilterSelectedValues([])}
              onFilterClear={() => setFederalFilterSelectedValues([])}
              filterPlaceholder="Search by Status"
              style={{ width: "9rem" }}
              body={cellStatusEditorBody}
              editor={
                hasChangeStatusPermission &&
                selectedCustomer &&
                (selectedCustomer.isOnlyChildFile ||
                  selectedCustomer.isOnlyParentFile)
                  ? (options) => cellStatusEditor(options)
                  : undefined
              }
              // onCellEditInit={onCellStatusEditInit}
              // // onCellEditComplete={onCellEditStatusComplete}
            ></Column>

            {postAwardVal && (
              <Column
                className="column-small"
                field="grantTermComplete"
                header="Grant Term Complete"
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterField="GrantTermcomplete"
                filterMenuStyle={{ width: "14rem" }}
                filterPlaceholder="Search by GrantTermcomplete"
                style={{ maxWidth: "13rem" }}
                body={cellGrantTermcompleteEditorBody}
                // editor={
                //    hasChangeGrantTermcompletePermission
                //     ?
                //      (options) => cellGrantTermcompleteEditor(options)
                //     : undefined
                // }

                editor={(options) => {
                  const rowData = options.rowData;
                  const { recordType, status } = rowData;

                  // Check if recordType is "postaward" and status is "completed"
                  const enableEditor =
                    recordType === "PostAward" && status === "Completed";
                  return enableEditor
                    ? cellGrantTermcompleteEditor(options)
                    : null; // Return null or undefined to disable the editor
                }}
              ></Column>
            )}

            <Column
              field="staffAssignedArray"
              header="Staff Assigned"
              sortable={
                detailedViewVal ||
                selectedCustomer?.parentCustomer !== null ||
                selectedCustomer?.isOnlyParentFile
              }
              showFilterMenu={
                detailedViewVal ||
                selectedCustomer?.parentCustomer !== null ||
                selectedCustomer?.isOnlyParentFile
              }
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterMatchModeOptions={[
                { value: FilterMatchMode.CONTAINS, label: "Contains" },
                { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                { value: FilterMatchMode.EQUALS, label: "Equal" },
              ]}
              filterField="staffassigned"
              filterPlaceholder="Search by Staff Assigned"
              style={{ width: "9rem" }}
              // body={cellStaffAssignedBody}
              body={(rowData, field) =>
                CellListDisplayStaffAssigned(rowData, field)
              }
              editor={
                hasStaffAssignedPermission
                  ? (options) => cellStaffAssignedEditor(options)
                  : undefined
              }
            ></Column>

            <Column
              field="filingFrequency"
              header="Filing Frequency"
              sortable
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterMatchModeOptions={[
                { value: FilterMatchMode.CONTAINS, label: "Contains" },
                { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                { value: FilterMatchMode.EQUALS, label: "Equal" },
              ]}
              filterPlaceholder="Search by Frequency"
              style={{ minWidth: "15rem" }}
            ></Column>

            <Column
              field="stateList"
              header="State"
              sortable
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterMatchModeOptions={[
                { value: FilterMatchMode.CONTAINS, label: "Contains" },
                { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                { value: FilterMatchMode.EQUALS, label: "Equal" },
              ]}
              filterField="stateList"
              filterPlaceholder="Search by State"
              filterMenuStyle={{ width: "15rem" }}
              filterElement={stateRowFilterTemplate}
              onFilterClear={() => setFederalFilterSelectedValues([])}
              onFilterMatchModeChange={() => setFederalFilterSelectedValues([])}
              style={{ minWidth: "13rem" }}
              className="column-small"
            ></Column>

            {/* <Column
              field='fldAffectedCompany'
              header='Affected Companies'
              // filter

              showAddButton={false}
              showFilterOperator={false}
              // filterMatchModeOptions={[
              //   { value: FilterMatchMode.CONTAINS, label: "Contains" },
              //   {
              //     value: FilterMatchMode.NOT_CONTAINS,
              //     label: "Not Contains",
              //   },
              //   { value: FilterMatchMode.EQUALS, label: "Equal" },
              // ]}
              // filterPlaceholder="Search by Affected Companies"
              style={{ minWidth: "15rem", maxWidth: "15rem" }}
              body={(rowData, field) =>
                cellListDisplayAffectedComp(rowData, field)
              }

              editor={
                hasChangeStatusPermission
                  ? (options) => cellAffectedCompaniesEditor(options)
                  : undefined
              }
            ></Column> */}

            {trackNowVal && (
              <Column
                field="ruleInfo"
                header="Rule Info"
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.CONTAINS, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterPlaceholder="Search by Rule Info"
                style={{ minWidth: "15rem", maxWidth: "15rem" }}
                body={(rowData) => cellListDisplayRuleInfo(rowData)}
              ></Column>
            )}

            <Column
              field="filingJuristiction"
              header="Jurisdiction"
              sortable
              filter
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
              filterElement={jurisdictionRowFilterTemplate}
              onFilterMatchModeChange={() => setFederalFilterSelectedValues([])}
              onFilterClear={() => setFederalFilterSelectedValues([])}
              filterPlaceholder="Search by Jurisdiction"
              style={{ minWidth: "14rem" }}
            ></Column>

            {trackNowVal && (
              <Column
                field="filingRequired"
                header="Required"
                style={{ minWidth: "13rem" }}
                className="column-small"
                filter
                filterElement={requiredRowFilterTemplate}
                onFilterClear={() => setFederalFilterSelectedValues([])}
                showAddButton={false}
                showFilterOperator={false}
                showFilterMatchModes={false}
                filterPlaceholder="Search by Required"
              >
                Select
              </Column>
            )}

            {postAwardVal && (
              <Column
                field="awardTypeName"
                header="Award Type "
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterElement={postawardTypeRowFilterTemplate}
                onFilterMatchModeChange={() => setMasterCatFilter([])}
                onFilterClear={() => setMasterCatFilter([])}
                filterPlaceholder="Search by Award Type"
                style={{ minWidth: "15rem" }}
              ></Column>
            )}

            {postAwardVal && (
              <Column
                field="awardingAgencyName"
                header="Award Agency"
                sortable
                filter
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                filterElement={postawardAgencyRowFilterTemplate}
                onFilterMatchModeChange={() => setMasterCatFilter([])}
                onFilterClear={() => setMasterCatFilter([])}
                filterPlaceholder="Search by Award Agency"
                style={{ minWidth: "15rem" }}
              ></Column>
            )}

            {trackNowVal && (
              <Column
                field="federalCategories"
                header="Who Must File Federal"
                // sortable
                body={(rowData) =>
                  CellListDisplayFederal(rowData, "federalCategories")
                }
                filter
                filterField="federalCategories"
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                onFilterMatchModeChange={() =>
                  setFederalFilterSelectedValues([])
                }
                filterElement={(options) =>
                  federalFilterElement(options, "federalCategories")
                }
                onFilterClear={() => setFederalFilterSelectedValues([])}
                filterPlaceholder="Search by Cateogry"
                style={{ minWidth: "15rem", maxWidth: "15rem" }}
                // // showFilterMenu={false}
                // filterMenuStyle={{ width: "14rem" }}
                //showClearButton={false}
                // filterElement={federalCategoryRowFilterTemplate}
                // onFilterClear={() => setFederalCatFilter([])}
              ></Column>
            )}

            {trackNowVal && (
              <Column
                field="stateCategories"
                header="Who Must File in State"
                // sortable
                filter
                filterField="stateCategories"
                showAddButton={false}
                showFilterOperator={false}
                filterMatchModeOptions={[
                  { value: FilterMatchMode.IN, label: "Contains" },
                  {
                    value: FilterMatchMode.NOT_CONTAINS,
                    label: "Not Contains",
                  },
                  { value: FilterMatchMode.EQUALS, label: "Equal" },
                ]}
                onFilterMatchModeChange={() =>
                  setFederalFilterSelectedValues([])
                }
                filterElement={(options) =>
                  federalFilterElement(options, "stateCategories")
                }
                onFilterClear={() => setFederalFilterSelectedValues([])}
                body={(rowData) =>
                  CellListDisplayFederal(rowData, "stateCategories")
                }
                // filterElement={stateCategoryRowFilterTemplate}
                // onFilterClear={() => setStateCatFilter([])}
                filterPlaceholder="Search by Cateogry"
                // showFilterMenu={false}
                // filterMenuStyle={{ width: "14rem" }}
                //  showClearButton={false}
                style={{ minWidth: "15rem", maxWidth: "15rem" }}
              ></Column>
            )}
            {/* <Column
            field='jsidept'
            header='JSI Dept'
            style={{ minWidth: "14rem" }}
          ></Column> */}
            {/* <Column
            field='jsiContactEmail'
            header='JSI Contact Email'
            style={{ minWidth: "14rem" }}
          ></Column> */}
            <Column
              field="whereToFile"
              header="Where to file"
              filter
              showAddButton={false}
              showFilterOperator={false}
              filterMatchModeOptions={[
                { value: FilterMatchMode.CONTAINS, label: "Contains" },
                { value: FilterMatchMode.NOT_CONTAINS, label: "Not Contains" },
                { value: FilterMatchMode.EQUALS, label: "Equal" },
              ]}
              body={(rowData) => cellListDisplayWhereToFile(rowData)}
              filterPlaceholder="Search by Where to File"
              style={{ minWidth: "13rem", maxWidth: "13rem" }}
            ></Column>
            <Column
              field="notes"
              header="Notes"
              headerStyle={{ minWidth: "20rem", maxWidth: "20rem" }}
              style={{ minWidth: "45rem", maxWidth: "45rem" }}
              body={(rowData) => cellListDisplayNotes(rowData)}
            ></Column>
          </DataTable>
        </TabPanel>
      </TabContext>
    </Fragment>
  );
});

export default ClientFilingTrackerTable;
