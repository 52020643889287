import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// import DataTableBase from "../common/DataTableBase";
// import PageNavbar from "../layout/PageNavbar";
// import SlidingDrawer from "../common/SlidingDrawer";
import _ from "lodash";
// import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import ClientFilingMasterContext from "../../context/client-filing-master/ClientFilingMasterContext";
import CustomerContext from "../../context/customer/CustomerContext";
import AuthContext from "../../context/auth/AuthContext";
// import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// const API_BASE_URL = process.env.REACT_APP_TRKNW_API_BASE_URL;

const ClientFilingMasterTable = (props) => {
  let { clientId } = useParams();
  const [_clientId, setClientId] = useState(clientId);
  const clientFilingMasterContext = useContext(ClientFilingMasterContext);
  const {
    getClientFilingMasters,
    ClientFilingMasters,
    ClientFilingMasterLoaded,
  } = clientFilingMasterContext;
  const customerContext = useContext(CustomerContext);
  const { selectedCustomer, setCurrentCustomer } = customerContext;
  const authContext = useContext(AuthContext);
  const { roleType, authUserId } = authContext;
  // const data = customers;
  // const [visibleApps, setVisibleApps] = useState(props.projects);
  // const [showFilter, setShowFilter] = useState(false);
  const [ClientFiling, setClientFiling] = useState([]);
  const [selectedClientFiling, setSelectedClientFiling] = useState(null);
  // const [selectedClient, setSelectedClient] = useState(null);
  // const [rowClick, setRowClick] = useState(true);

  // const [data, setData] = useState([]);
  const [jurisdiction, setJurisdiction] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    customerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // filingId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // filingDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
    filingFrequency: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // dueDayofFrequency: { value: null, matchMode: FilterMatchMode.IN },
    stateInfo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    ruleInfo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    required: { value: null, matchMode: FilterMatchMode.CONTAINS },
    federalCategories: { value: null, matchMode: FilterMatchMode.CONTAINS },
    stateCategories: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // jsidept: { value: null, matchMode: FilterMatchMode.CONTAINS },
    jsicontactName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // jsiContactEmail: { value: null, matchMode: FilterMatchMode.CONTAINS },
    juristiction: { value: null, matchMode: FilterMatchMode.IN },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [viewAll, setViewAll] = useState(true);
  const [data, setData] = useState([]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const jusrisdictionItemTemplate = (option) => {
    return (
      <div className='flex align-items-center'>
        <span>{option}</span>
      </div>
    );
  };
  const handleViewAllClick = () => {
    setViewAll(true);
  };

  const handlePaginationClick = () => {
    setViewAll(false);
  };

  // const jurisdictionRowFilterTemplate = (options) => {
  // console.log("##Options:", options);
  // return (
  // <MultiSelect
  // value={options.value}
  // options={jurisdiction}
  // itemTemplate={jusrisdictionItemTemplate}
  // onChange={(e) => {
  // console.log("##EOptions:", e);
  // options.filterApplyCallback(e.value);
  // }}
  // placeholder='Any'
  // maxSelectedLabels={1}
  // className='p-column-filter'
  // style={{ minWidth: "14rem", maxWidth: "14rem" }}
  // />
  // );
  // };

  const onRowSelect = (e) => {
    console.log("@@RowSelect:", e);
  };

  // const getTabledata= async () => {
  // await axios.get(API_BASE_URL+"/Customer/CustomerFilingMasterList?CustomerId=4"+4)
  // .then(response=>{
  // console.log(response.data)
  // })
  // }
  useEffect(() => {
    return () => {
      console.log("@@Resetting:");
      setCurrentCustomer && setCurrentCustomer(null);
    };
  }, []);

  // useEffect(() => {
  // if (selectedClientFiling) {
  // props.setSelectedClientFiling(selectedClientFiling);
  // }
  // return () => {
  // console.log("@@Resetting:");
  // props.setSelectedClientFiling([]);
  // setClientFiling(() => []);
  // };
  // }, [selectedClientFiling]);

  const fetchClientFilingMasters = async (clientId) => {
    console.log("@@Fetch Cust Filing Master:", clientId);
    // if (clientId) {
    let payLoad = { customerId: clientId };
    // if (selectedClient) {
    // payLoad = { customerId: selectedClient.customerId };
    // } else if (clientId) {
    // payLoad = { customerId: clientId };
    // }

    if (getClientFilingMasters) {
      console.log("@@Fetch Cust Filing Master:");
      try {
        let custList = await getClientFilingMasters(payLoad);
        console.log("@@Cust Filing Master:", custList);
        // console.log("@@Unique Jurisdiction:", _.map(custList, "jurisdiction"));
        // console.log(
        // "@@Unique Jurisdiction:",
        // _.unique(_.map(custList, "jurisdiction"))
        // );
        const _jusrisdictions = custList.map((item) =>
          item.juristiction ? item.juristiction : ""
        );
        const _uniqJurisdictions = Array.from(new Set(_jusrisdictions));
        console.log("@@Unique Jurisdiction:", _uniqJurisdictions);
        setJurisdiction(_uniqJurisdictions);
        if (roleType === "ClientAdmin"|| roleType === "JSIReadOnly" ||
        roleType === "ClientUser"||
        roleType === "JSIUser") {
          custList = custList.filter((ele) => ele.email === authUserId);
        } else if (roleType === "JSIPOC") {
          custList = custList.filter((ele) => ele.jsI_POC === authUserId);
        }
        const updCustList = custList.map((item) => {
          // console.log(`Customer filing M...`, item);
          const categories = _.map(
            item.businessCategory,
            "businessCategoryName"
          ).join(", ");
          // console.log(`Customers ${item.filingId} cat...`, categories);
          if (item.juristiction?.includes("Federal"))
            return { ...item, federalCategories: categories };
          else return { ...item, stateCategories: categories };
        });
        setClientFiling((old) => updCustList);
      } catch (error) {
        console.log("@@Error:", error);
      }
    }
    // }
  };
  useEffect(() => {
    if (ClientFilingMasterLoaded)
      console.log("Show Table Data", ClientFilingMasters);

    //eslint-disable-next-line
  }, [ClientFilingMasterLoaded]);

  useEffect(() => {
    console.log("####ClientID param", clientId);
    if (_clientId) {
      fetchClientFilingMasters(_clientId);
    }
  }, [_clientId]);

  useEffect(() => {
    if (selectedCustomer) {
      fetchClientFilingMasters(selectedCustomer.customerId);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (props.selectedClientId) {
      console.log("####Setting ClientID param", props.selectedClientId);
      setClientId(() => props.selectedClientId);
    }
  }, [props.selectedClientId]);

  useEffect(() => {
    // if (clientId) setSelectedClient((old) => clientId);
    console.log("####Final Cleint Dat", ClientFiling);
    //eslint-disable-next-line
  }, [ClientFiling]);

  // useEffect(() => {
  // // if (clientId) setSelectedClient((old) => clientId);
  // if (!clientId && !selectedClient) {
  // fetchClientFilingMasters();
  // }
  // //eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   if (ClientFilingMasters) {
  //     console.log("Orig Customers...", ClientFilingMasters);
  //     const updCustomers = ClientFilingMasters.map((item) => {
  //       // console.log(`Customer filing M...`, item);
  //       const categories = _.map(
  //         item.businessCategory,
  //         "businessCategoryName"
  //       ).join(", ");
  //       // console.log(`Customers ${item.filingId} cat...`, categories);
  //       if (item.juristiction?.includes("Federal"))
  //         return { ...item, federalCategories: categories };
  //       else return { ...item, stateCategories: categories };
  //     });
  //     console.log("Updated Customers filing M...", updCustomers);
  //     // setData(updCustomers);
  //     setClientFiling(updCustomers);
  //   }

  //   //eslint-disable-next-line
  // }, [ClientFilingMasters]);

  const renderHeader = () => {
    return (
      <Grid container sx={{ my: 1, flexDirection: "row" }}>
        <Grid md={8} sx={{ margin: 1, flexGrow: 1 }}>
          <Typography
            variant='h6'
            noWrap
            component='a'
            sx={{
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            VIEW FILINGS ({ClientFiling.length})
          </Typography>
        </Grid>
        <Grid md={3} sx={{ margin: 1 }}>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder='Search by Client Name...'
            />
          </span>
        </Grid>
      </Grid>
    );
  };

  const header = renderHeader();

  const selectClientFiling = (value) => {
    setSelectedClientFiling(value);
    props.setSelectedClientFiling(value);
  };

  return (
    <Fragment>
      <DataTable
        scrollable
        // resizableColumns
        value={ClientFiling}
        emptyMessage='No records found'
        selection={selectedClientFiling}
        onSelectionChange={(e) => selectClientFiling(e.value)}
        dataKey='filingId'
        tableStyle={{ minWidth: "70rem" }}
        onRowSelect={onRowSelect}
        paginator={!viewAll}
        rows={viewAll ? data.length : 10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        filters={filters}
        filterDisplay='row'
        globalFilterFields={["filingName"]}
        header={header}
        footer={
          <div style={{ textAlign: "right" }}>
            {!viewAll && (
              <button onClick={handleViewAllClick} style={{ fontSize: "1rem" }}>
                Back to View All
              </button>
            )}
            {viewAll && (
              <button
                onClick={handlePaginationClick}
                style={{ fontSize: "1rem" }}
              >
                View Paginated
              </button>
            )}
          </div>
        }
      >
        <Column selectionMode='single' headerStyle={{ width: "3rem" }}>
          Select
        </Column>

        {/* <Column
          field='filingId'
          header='Filing ID'
          sortable
          filter
          filterPlaceholder='Search by Filing ID'
          style={{ minWidth: "12rem" }}
        ></Column> */}
        <Column
          field='filingName'
          header='Filing Name'
          sortable
          filter
          filterPlaceholder='Search by Filing Name'
          style={{ minWidth: "12rem" }}
        ></Column>
        <Column
          field='customerName'
          header='Client Name'
          sortable
          filter
          filterPlaceholder='Search by Client Name'
          style={{ minWidth: "12rem" }}
        ></Column>
        {/* <Column
          field='filingDescription'
          header='Filing Description'
          sortable
          filter
          filterPlaceholder='Search by Filing Description'
          style={{ minWidth: "15rem" }}
        ></Column> */}
        <Column
          field='filingFrequency'
          header='Filing Frequency'
          sortable
          filter
          filterPlaceholder='Search by Frequency'
          style={{ minWidth: "15rem" }}
        ></Column>
        {/* <Column
          field='dueDayofFrequency'
          header='Due Date Offset'
          sortable
          filter
          filterPlaceholder='Search by offset'
          style={{ minWidth: "15rem" }}
        ></Column> */}
        <Column
          field='stateInfo'
          header='State Info'
          filter
          filterPlaceholder='Search by State Info'
          style={{ minWidth: "15rem" }}
        ></Column>
        <Column
          field='ruleInfo'
          header='Rule Info'
          filter
          filterPlaceholder='Search by Rule Info'
          style={{ minWidth: "15rem" }}
        ></Column>
        {/* <Column field='mail' header='Email'></Column> */}
        {/* <Column
field='Who Must File In State'
header='Who Must File In State'
style={{ minWidth: "15rem" }}
></Column>
<Column
field='Who Must File In Federal'
header='Who Must File In Federal'
style={{ minWidth: "15rem" }}
>
<input type="text" id="ip2"/>
</Column> */}
        <Column
          field='juristiction'
          header='Jurisdiction'
          sortable
          filter
          filterPlaceholder='Search by Jurisdiction'
          style={{ minWidth: "14rem" }}
        ></Column>
        <Column
          field='required'
          header='Required'
          filter
          filterPlaceholder='Search by Required'
          style={{ minWidth: "15rem" }}
        >
          Select
        </Column>
        <Column
          field='federalCategories'
          header='Who Must File Federal'
          // sortable
          filter
          filterPlaceholder='Search by Cateogry'
          style={{ minWidth: "15rem" }}
        ></Column>
        <Column
          field='stateCategories'
          header='Who Must File in State'
          // sortable
          filter
          filterPlaceholder='Search by Cateogry'
          style={{ minWidth: "15rem" }}
        ></Column>
        {/* <Column
          field='jsidept'
          header='JSI Dept'
          filter
          filterPlaceholder='Search by  Dept'
          style={{ minWidth: "14rem" }}
        ></Column> */}
        <Column
          field='jsicontactName'
          header='JSI Staff'
          filter
          filterPlaceholder='Search by  Contact Name'
          style={{ minWidth: "14rem" }}
        ></Column>
        {/* <Column
          field='jsiContactEmail'
          header='JSI Contact Email'
          filter
          filterPlaceholder='Search by Contact Email'
          style={{ minWidth: "14rem" }}
        ></Column> */}
        <Column
          field='notes'
          header='Notes'
          style={{ minWidth: "15rem" }}
        ></Column>
      </DataTable>
    </Fragment>
  );
};

export default ClientFilingMasterTable;
